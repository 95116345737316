import { useSelector } from 'react-redux'
import { RootState } from '../store'

export const useSolidusData = () => {
  const selectedEnvironment = useSelector(
    (state: RootState) => state.environments.selectedEnvironment
  )
  const solidusInfo = useSelector(
    (state: RootState) => state.stores.solidusInfo
  )

  const settings = solidusInfo[selectedEnvironment || 'test']

  if (!settings) {
    throw Error('Error getting Solidus info')
  }

  return {
    baseUrl: settings.apiHost,
    apiKey: settings.apiKey,
    storeId: settings.solidusStoreId,
  }
}
