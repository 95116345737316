import { UpdateSheetRequest } from 'types'
import { useConfigSheetsData } from 'redux/state/configSheets'
import { useIsLoading } from 'redux/utils'

export const useUpdateConfigForm = (onClose?: () => void) => {
  const { updateSheet } = useConfigSheetsData()

  const isLoading = useIsLoading('updateSheet')

  const submit = async (data: UpdateSheetRequest) => {
    if (!updateSheet) return

    await updateSheet(data)

    if (onClose) onClose()
  }

  return { submit, isLoading }
}
