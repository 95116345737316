import { hubClient } from 'api/http'
import {
  SheetsClientOptions,
  GoogleSheetsRowData,
  ConfigurationSheet,
  GoogleSheetsClientProps,
  ConfigurationSheetMetadata,
  ColumnHeader,
  IntegrityCheckResponse,
} from './types'

class GoogleSheetsClient implements GoogleSheetsClientProps {
  private tenantId: number
  private storeId: number
  private environment: string
  private baseURL: string

  constructor(options: SheetsClientOptions) {
    this.tenantId = options.tenantId
    this.storeId = options.storeId
    this.environment = options.environment
    this.baseURL = `tenants/${this.tenantId}/stores/${this.storeId}/config_sheets`
  }

  getURL(endpoint: string): string {
    return `${this.baseURL}/${endpoint}?env=${this.environment}`
  }

  async getSheetMetadata(slug: string): Promise<ConfigurationSheetMetadata> {
    const url = this.getURL('fetch_sheet_metadata')
    return hubClient.get(url, {
      params: {
        slug: slug,
      },
    })
  }

  async getSheet(
    slug: string,
    page = 1,
    perPage = 1000,
    forceRefresh = false
  ): Promise<ConfigurationSheet<GoogleSheetsRowData>> {
    const url = this.getURL('fetch_spreadsheet_values')
    return hubClient.get(url, {
      params: {
        slug: slug,
        page: page,
        per_page: perPage,
        force_refresh: forceRefresh,
      },
    })
  }

  async appendRow(data: {
    slug: string
    headers: ColumnHeader[]
    values: GoogleSheetsRowData
  }): Promise<any> {
    const url = this.getURL('append_spreadsheet_values')
    return await hubClient.put(url, {
      slug: data.slug,
      headers: data.headers,
      values: data.values,
    })
  }

  async updateRow(data: {
    slug: string
    rowIndex: number
    headers: ColumnHeader[]
    values: GoogleSheetsRowData
    page: number
    pageSize: number
  }): Promise<any> {
    const url = this.getURL('update_spreadsheet_values')
    return await hubClient.put(url, {
      slug: data.slug,
      row_index: data.rowIndex,
      headers: data.headers,
      values: data.values,
      page: data.page,
      per_page: data.pageSize,
    })
  }

  async uploadFile(data: {
    slug: string
    file: File
    headers: ColumnHeader[]
  }): Promise<any> {
    const url = this.getURL('upload_sheet')
    const formData = new FormData()
    formData.append('slug', data.slug)
    formData.append('headers', JSON.stringify(data.headers))
    formData.append('file', data.file)

    return await hubClient.put(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async clearSheet(data: { slug: string }): Promise<any> {
    const url = this.getURL('clear_sheet')
    return await hubClient.delete(url, {
      data: {
        slug: data.slug,
      },
    })
  }

  async deleteRow(data: {
    slug: string
    rowIndex: number
    page: number
    pageSize: number
  }): Promise<any> {
    const url = this.getURL('delete_spreadsheet_row')
    return await hubClient.delete(url, {
      data: {
        slug: data.slug,
        row_index: data.rowIndex,
        page: data.page,
        per_page: data.pageSize,
      },
    })
  }

  async checkIntegrity(data: {
    slug: string
    headers: ColumnHeader[]
    page: number
    pageSize: number
    signal?: AbortSignal
  }): Promise<IntegrityCheckResponse> {
    const url = this.getURL('verify_spreadsheet_integrity')
    return await hubClient.post(
      url,
      {
        slug: data.slug,
        headers: data.headers,
        page: data.page,
        per_page: data.pageSize,
      },
      {
        signal: data.signal,
      }
    )
  }
}

export default GoogleSheetsClient
