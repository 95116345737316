import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const CustomersAtRisk: React.FC<IconProps> = ({
  scale = 48,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8865 12.2659C21.7148 9.09921 26.2855 9.0992 28.1138 12.2659L29.0803 13.9398L27.7812 14.6898L26.8147 13.0159C25.5638 10.8492 22.4365 10.8492 21.1856 13.0159L11.6593 29.5159C10.4084 31.6825 11.972 34.3909 14.4739 34.3909H33.5264C36.0283 34.3909 37.5919 31.6825 36.341 29.5159L30.2812 19.02L31.5803 18.27L37.6401 28.7659C39.4683 31.9325 37.183 35.8909 33.5264 35.8909H14.4739C10.8173 35.8909 8.53198 31.9325 10.3603 28.7659L19.8865 12.2659ZM23.0545 25.7349H24.9805L25.3585 18.0409H22.6405L23.0545 25.7349ZM23.9905 27.7429C23.1265 27.7429 22.4785 28.3729 22.4785 29.2549C22.4785 30.1189 23.1265 30.7489 23.9905 30.7489C24.8725 30.7489 25.5205 30.1189 25.5205 29.2549C25.5205 28.3729 24.8725 27.7429 23.9905 27.7429Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
