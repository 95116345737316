import React from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'

import { Button } from '@chordco/component-library'
import { MRT_Row, MRT_RowData } from 'material-react-table'

export default function TableDeleteRowDialog<T extends MRT_RowData>({
  open,
  row,
  onClose,
  onDelete,
  isDeleting,
}: {
  open: boolean
  row: MRT_Row<T>
  onClose: () => void
  onDelete: (row: MRT_Row<T>) => void
  isDeleting: boolean
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Are you sure you want to delete row {row.index + 1}?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>This action cannot be undone.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} purpose="tertiary" type="button">
          Cancel
        </Button>
        <Button
          onClick={() => onDelete(row)}
          purpose="delete-primary"
          type="button"
          isLoading={isDeleting}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
