import React from 'react'
import styled from 'styled-components'
import { Button } from '@chordco/component-library'

interface Props {
  onPrevPage: () => void
  onNextPage: () => void
  showPrevPage: boolean
  showNextPage: boolean
}

export const SourcePaginator: React.FC<Props> = ({
  onPrevPage,
  onNextPage,
  showPrevPage,
  showNextPage,
}) => {
  return (
    <ButtonContainer>
      {showPrevPage && (
        <Button type="button" purpose="tertiary" onClick={onPrevPage}>
          Previous Page
        </Button>
      )}

      {showNextPage && (
        <Button type="button" purpose="tertiary" onClick={onNextPage}>
          Next Page
        </Button>
      )}
    </ButtonContainer>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  button + button {
    margin-left: 1rem;
  }
`
