import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const ExitCircle: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99982 2.4334C4.64929 2.4334 1.93315 5.14954 1.93315 8.50007C1.93315 11.8506 4.64929 14.5667 7.99982 14.5667C11.3503 14.5667 14.0665 11.8506 14.0665 8.50007C14.0665 5.14954 11.3503 2.4334 7.99982 2.4334ZM0.733154 8.50007C0.733154 4.4868 3.98655 1.2334 7.99982 1.2334C12.0131 1.2334 15.2665 4.4868 15.2665 8.50007C15.2665 12.5133 12.0131 15.7667 7.99982 15.7667C3.98655 15.7667 0.733154 12.5133 0.733154 8.50007ZM10.4243 6.07581C10.6586 6.31013 10.6586 6.69003 10.4243 6.92434L8.84855 8.50008L10.4243 10.0758C10.6586 10.3101 10.6586 10.69 10.4243 10.9243C10.19 11.1587 9.81007 11.1587 9.57576 10.9243L8.00002 9.34861L6.42429 10.9243C6.18997 11.1587 5.81007 11.1587 5.57576 10.9243C5.34144 10.69 5.34144 10.3101 5.57576 10.0758L7.1515 8.50008L5.57576 6.92434C5.34144 6.69003 5.34144 6.31013 5.57576 6.07581C5.81007 5.8415 6.18997 5.8415 6.42429 6.07581L8.00002 7.65155L9.57576 6.07581C9.81007 5.8415 10.19 5.8415 10.4243 6.07581Z"
        fill={fill || theme.ContentPrimary}
      />
      <rect x="0.5" y="1" width="15" height="15" rx="7.5" stroke={fill} />
    </svg>
  )
}
