import React, { ChangeEvent } from 'react'
import styled from 'styled-components'

const svgUrl = '/svgs/SliderChevrons.svg'

interface Props {
  value: number
  min: number
  max: number
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  label: string
  prefix?: string
  postfix?: string
}

export const Slider: React.FC<Props> = ({
  value,
  min,
  max,
  onChange,
  label,
  prefix,
  postfix,
  ...props
}) => {
  return (
    <Container>
      <Label>{label}</Label>
      <StyledSlider
        {...props}
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={onChange}
      />
      <Value>
        {prefix && prefix}
        {value}
        {postfix && postfix}
      </Value>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  height: 52px;
  width: 320px;
`

const Label = styled.label`
  position: absolute;
  top: 6px;
  left: 0;
  color: ${p => p.theme.GREY_2};
`

const Value = styled.div`
  position: absolute;
  top: 6px;
  right: 0;
  font-weight: 600;
  color: ${p => p.theme.OFF_TEXT_BASE};
`

const StyledSlider = styled.input<{ value: number }>`
  position: absolute;
  bottom: 10px;
  appearance: none;
  width: 320px;
  height: 4px;
  background: ${p =>
    `linear-gradient(to right, ${p.theme.PRIMARY_70} 0%, ${p.theme.PRIMARY_70} ${p.value}%, ${p.theme.GREY_4} ${p.value}%, ${p.theme.GREY_4} 100%)`};

  border-radius: 4px;
  outline: none;

  ::after {
    content: none;
    height: 10px;
    width: 10px;
    border: solid 1px black;
    background-color: white;
  }

  ::-webkit-slider-thumb {
    appearance: none;
    background-color: ${p => p.theme.PRIMARY_50};
    background-image: url(${svgUrl});
    background-repeat: no-repeat;
    height: 24px;
    width: 40px;
    border-radius: 16px;
    cursor: pointer;
  }

  ::-moz-range-thumb {
    background-color: ${p => p.theme.PRIMARY_50};
    background-image: url(${svgUrl});
    background-repeat: no-repeat;
    height: 24px;
    width: 40px;
    border-radius: 16px;
    cursor: pointer;
  }

  ::-moz-focus-outer {
    border: 0;
  }
`
