import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from '../controls'
import { H3 } from '../type'
import { useIsMobile } from '../../hooks'
import { Loading } from '../information'
import { Overlay } from './Overlay'
import useMixpanel from 'hooks/useMixpanel'
import { MixpanelEventType } from 'utils/mixpanel/types'

export type Image = {
  url: string
  alt?: string
}

interface Props {
  children: React.ReactNode
  title: string
  onClose: Function
  isMobile?: boolean
  isLarge?: boolean
  customWidth?: string
  isLoading?: boolean
  hideHeaderBorder?: boolean
  titleImage?: Image
  closeButtonTitle?: string
  name?: string
  location?: string
}

export const Sheet: React.FC<Props> = ({
  onClose,
  title,
  titleImage,
  isMobile: baseIsMobile,
  isLarge = false,
  isLoading = false,
  hideHeaderBorder = false,
  customWidth = '400px',
  closeButtonTitle = 'Cancel',
  name,
  location,
  children,
}) => {
  const [show, setShow] = useState(false)

  const { trackEvent } = useMixpanel()

  const isMobileWidth = useIsMobile()
  const isMobile =
    typeof baseIsMobile !== 'undefined' ? baseIsMobile : isMobileWidth

  const pageWidth = +document.body.offsetWidth
  const width =
    isMobile || pageWidth < 680 ? '100vw' : isLarge ? '680px' : customWidth

  const trackSheetOpened = () => {
    if (name && location) {
      trackEvent(MixpanelEventType.SheetOpened, {
        'Sidesheet Name': name,
        'Page Name': location,
      })
    }
  }

  const trackSheetClosed = () => {
    if (name && location) {
      trackEvent(MixpanelEventType.SheetClosed, {
        'Sidesheet Name': name,
        'Page Name': location,
      })
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      trackSheetOpened()
      setShow(true)
    }, 0)

    return () => clearTimeout(timer)
  }, [])

  const closeSheet = useCallback(() => {
    trackSheetClosed()
    setShow(false)
    onClose()
  }, [onClose])

  return (
    <Overlay show={show} onExit={closeSheet}>
      <Wrapper show={show} isLarge={isLarge} width={width} data-testid="sheet">
        {isLoading && <Loading />}
        <Header hideBorder={!!hideHeaderBorder}>
          <TitleContainer>
            {titleImage && (
              <TitleImage src={titleImage.url} alt={titleImage.alt} />
            )}
            <H3>{title}</H3>
          </TitleContainer>
          <Button
            onClick={closeSheet}
            purpose="tertiary"
            name="Cancel"
            location={name}
          >
            {closeButtonTitle}
          </Button>
        </Header>
        {children}
      </Wrapper>
    </Overlay>
  )
}

const Wrapper = styled.div<{
  show: boolean
  isLarge: boolean
  width: string
}>`
  overflow: auto;
  position: fixed;
  top: 0;
  right: ${p => (p.show ? '0' : `-${p.isLarge ? 680 : 400}px`)};
  height: 100vh;
  width: ${p => p.width};
  border-radius: 16px 0 0 16px;
  background-color: ${p => p.theme.ComponentWorkspaceBgSheet};
  border: 1px solid ${p => p.theme.ComponentWorkspaceBorderSheet};
  z-index: 2;
  transition: 300ms;
`

const Header = styled.div<{ hideBorder: boolean }>`
  height: 76px;
  padding: 20px 20px 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${p =>
    p.hideBorder ? 'none' : `solid 1px ${p.theme.BorderHairline}`};
`

export const SheetForm = ({ ...props }) => (
  <FormComponent {...props} noValidate>
    {props.children}
  </FormComponent>
)

const FormComponent = styled.form<{ insideTabBar?: boolean }>`
  box-sizing: border-box;
  position: relative;
  min-height: calc(100vh - ${p => (p.insideTabBar ? 125 : 76)}px);
  padding: 0 16px;
  overflow: auto;
`

export const SheetFormSection = styled.div`
  margin-top: 32px;
`

const TitleImage = styled.img`
  height: 48px;
  width: 48px;
  border: 1px solid ${p => p.theme.BorderHairline};
  border-radius: 24px;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  & > img {
    margin-right: 16px;
  }
`

export const SheetHr = styled.div`
  border-top: solid 1px ${p => p.theme.BorderHairline};
`
