import React from 'react'
import { H5, logos, useTheme } from '@chordco/component-library'
import styled from 'styled-components'

interface Props {
  setNavOpen: Function
}

export const MobileHeader: React.FC<Props> = ({ setNavOpen }) => {
  const theme = useTheme()

  const { ChordLogo } = logos
  return (
    <Header>
      <ChordLogo
        fill={theme.ContentPrimary}
        size="small"
        onClick={() => setNavOpen(curVal => !curVal)}
      />
      <MenuButton onClick={() => setNavOpen(curVal => !curVal)}>
        Menu
      </MenuButton>
    </Header>
  )
}

const Header = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 48px;
  padding: 12px 20px;
`

const MenuButton = styled(H5)`
  position: absolute;
  top: 12px;
  right: 20px;
  cursor: pointer;
`
