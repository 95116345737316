import React from 'react'
import styled, { css } from 'styled-components'
import { H1 } from '../type'
import { Button } from '../controls'
import { useIsMobile } from '../../hooks'

interface Props {
  children: React.ReactNode
  navOpen: boolean
  isMobile?: boolean
  isIframe?: boolean
  isFullWidth?: boolean
  noTransitions?: boolean
}

export const Page: React.FC<Props> = ({
  children,
  navOpen,
  isMobile: baseIsMobile,
  isIframe = false,
  isFullWidth = false,
  noTransitions = false,
  ...props
}) => {
  const isMobileWidth = useIsMobile()
  const isMobile =
    typeof baseIsMobile !== 'undefined' ? baseIsMobile : isMobileWidth

  return (
    <Wrapper navOpen={noTransitions ? false : navOpen} isMobile={isMobile}>
      <StyledPage
        navOpen={noTransitions ? false : navOpen}
        isMobile={isMobile}
        isIframe={isIframe}
        isFullWidth={isFullWidth}
        {...props}
      >
        {children}
      </StyledPage>
    </Wrapper>
  )
}

const Wrapper = styled.div<{
  navOpen: boolean
  isMobile: boolean
}>`
  position: relative;
  background-color: ${p => p.theme.ComponentWorkspaceBgPage};
  border-left: solid 1px ${p => p.theme.BorderOptimal};
  z-index: 0;
  overflow: hidden auto;
  transition: 300ms;

  ${p =>
    p.isMobile
      ? css`
          border-radius: ${p.theme.PageRadius} ${p.theme.PageRadius} 0 0;
        `
      : css`
          border-radius: ${p.navOpen
            ? `${p.theme.PageRadius} 0px 0px ${p.theme.PageRadius}`
            : '0px'};
        `}
`

const StyledPage = styled.div<{
  navOpen: boolean
  isMobile: boolean
  isIframe: boolean
  isFullWidth: boolean
}>`
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;

  ${p =>
    p.isMobile
      ? css`
          height: ${p.navOpen ? 0 : 'calc(100vh - 48px)'};
          padding: ${p.isIframe ? 0 : '12px 16px 0 16px'};
          max-width: ${p.isIframe ? '' : p.isFullWidth ? '' : '1300px'};
        `
      : css`
          height: 100vh;
          padding: ${p.isIframe ? 0 : '36px 64px 0 64px'};
          max-width: ${p.isIframe ? '' : p.isFullWidth ? '' : '1300px'};
        `}
`

interface PageTitleProps {
  children?: React.ReactNode
  onClick?: () => void
}

export const PageTitle: React.FC<PageTitleProps> = props => (
  <StyledPageTitle {...props} />
)

const StyledPageTitle: any = styled(H1 as any)`
  font-weight: 600;
  letter-spacing: -0.04em;
`

export const Section = styled.div`
  padding: 0 0 24px;

  :last-child {
    padding: 0;
  }
`

export const FlexSection = styled(Section)`
  flex: 2;
`

export const FlexSectionSecondary = styled(Section)`
  flex: 1;
  margin-left: 24px;
`

export const FlexContainer = styled.div`
  display: flex;

  @media (max-width: 1200px) {
    flex-direction: column;

    ${FlexSectionSecondary} {
      margin-left: 0;
    }
  }
`

export const Card = styled.div`
  box-sizing: border-box;
  border: solid 1px ${p => p.theme.BorderOptimal};
  border-radius: ${p => p.theme.CardSmallRadius};
  margin-bottom: 24px;

  :last-child {
    margin-bottom: 0;
  }
`

export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  > div:not(:last-child) {
    margin-right: 16px;
  }
`

export const HeaderButton = styled(Button)<{ isMobile: boolean }>`
  position: absolute;
  top: ${p => (p.isMobile ? 12 : 36)}px;
  right: ${p => (p.isMobile ? 16 : 64)}px;
`
