import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const NumberedList: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.59866 8.43381C4.98444 8.43381 5.24451 8.17374 5.24451 7.77929V2.72521C5.24451 2.27442 4.9671 1.99268 4.5033 1.99268C4.23456 1.99268 4.0265 2.03169 3.72308 2.23974L2.48341 3.09798C2.27968 3.24102 2.20166 3.3884 2.20166 3.58779C2.20166 3.8652 2.39671 4.05592 2.66112 4.05592C2.79549 4.05592 2.88652 4.02558 3.00789 3.93889L3.92247 3.30171H3.94848V7.77929C3.94848 8.17374 4.21289 8.43381 4.59866 8.43381Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M2.9949 17.2199H6.4582C6.79629 17.2199 7.00002 17.0075 7.00002 16.6998C7.00002 16.3834 6.79629 16.1796 6.4582 16.1796H4.10021V16.1536L5.45259 14.8533C6.47554 13.8867 6.84831 13.4099 6.84831 12.647C6.84831 11.572 5.95539 10.8265 4.62902 10.8265C3.44569 10.8265 2.66114 11.4723 2.44875 12.1659C2.41841 12.2569 2.40107 12.3479 2.40107 12.4476C2.40107 12.7727 2.60913 12.9807 2.96022 12.9807C3.24197 12.9807 3.39368 12.8594 3.54105 12.5993C3.76645 12.0965 4.11321 11.8451 4.61602 11.8451C5.17518 11.8451 5.57395 12.2135 5.57395 12.7293C5.57395 13.1801 5.37456 13.4836 4.60302 14.2248L2.73916 16.0063C2.47475 16.249 2.37939 16.4137 2.37939 16.6608C2.37939 16.9902 2.58312 17.2199 2.9949 17.2199Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25 4C9.25 3.58579 9.58579 3.25 10 3.25H17C17.4142 3.25 17.75 3.58579 17.75 4C17.75 4.41421 17.4142 4.75 17 4.75H10C9.58579 4.75 9.25 4.41421 9.25 4Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25 10C9.25 9.58579 9.58579 9.25 10 9.25H17C17.4142 9.25 17.75 9.58579 17.75 10C17.75 10.4142 17.4142 10.75 17 10.75H10C9.58579 10.75 9.25 10.4142 9.25 10Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25 16C9.25 15.5858 9.58579 15.25 10 15.25H17C17.4142 15.25 17.75 15.5858 17.75 16C17.75 16.4142 17.4142 16.75 17 16.75H10C9.58579 16.75 9.25 16.4142 9.25 16Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
