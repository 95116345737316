import styled from 'styled-components'

export const Container = styled.div<{ gridTemplateColumns: string }>`
  display: grid;
  grid-template-columns: ${p => p.gridTemplateColumns};
  grid-gap: 16px;
`

export const Metric = styled.div`
  position: relative;
  height: 144px;
  border-radius: ${p => p.theme.CardMediumRadius};
  padding: 16px;
  background-color: ${p => p.theme.ComponentWorkspaceBgPage};
  border: solid 1px ${p => p.theme.BorderOptimal};
`

export const Label = styled.div`
  font-size: 16px;
  font-weight: 500;
`

export const Value = styled.div`
  position: absolute;
  bottom: 40px;
  left: 16px;
  font-size: 24px;
  font-weight: 600;
`

export const Comparison = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  font-size: 12px;
  color: ${p => p.theme.ContentSecondary};
`

export const Sign = styled.span`
  color: ${p => p.theme.ContentPrimary};
  font-weight: 400;
`

export const UpDownContainer = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
`

export const getGridTemplateColumns = (width: number) => {
  let gridTemplateColumns = '100%'

  if (width > 500 && width < 850) {
    gridTemplateColumns = 'calc(50% - 8px) calc(50% - 8px)'
  } else if (width >= 850 && width < 1200) {
    gridTemplateColumns =
      'calc(33.33% - 10.66px) calc(33.33% - 10.66px) calc(33.33% - 10.66px);'
  } else if (width >= 1200) {
    gridTemplateColumns =
      'calc(25% - 10.66px) calc(25% - 10.66px) calc(25% - 10.66px) calc(25% - 10.66px);'
  }

  return gridTemplateColumns
}
