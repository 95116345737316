import React from 'react'
import styled, { css } from 'styled-components'
import { Checkbox, Toggle } from '../controls'
import { Caption as BaseCaption } from '../type'
import { standardIcons } from '../icons'
import { Shortcut, Avatar, FeatureIcon, FeatureIconColor } from '../information'

export interface OptionProps {
  children?: React.ReactNode
  id: string | number
  label: string
  onClick?: any
  leftIcon?: any
  avatarSrc?: string
  featureIcon?: { icon: any; color: FeatureIconColor }
  rightIcon?: any
  shortcut?: string[]
  toggleChecked?: boolean
  onToggle?: any
  checked?: boolean
  onCheck?: any
  divider?: boolean
  arrowHovered?: boolean
  selectable?: boolean
  selected?: boolean
  caption?: string
  options?: OptionProps[]
  clickable?: boolean
  color?: string
}

export type OptionPropsBasic = Pick<OptionProps, 'id' | 'label'>

export const Option: React.FC<OptionProps> = ({
  id,
  label,
  onClick,
  selected,
  selectable,
  leftIcon: LeftIcon,
  avatarSrc,
  featureIcon,
  rightIcon: RightIcon,
  shortcut,
  toggleChecked,
  onToggle,
  checked,
  divider,
  arrowHovered,
  caption,
  clickable = true,
  children,
  ...props
}) => {
  const { Check } = standardIcons

  return (
    <>
      <StyledOption
        key={id}
        onClick={(...props) => {
          clickable && onClick(...props)
        }}
        arrowHovered={arrowHovered}
        hasCaption={!!caption}
        clickable={clickable}
        selected={selected}
        {...props}
      >
        {typeof checked !== 'undefined' && (
          <CheckboxContainer hasCaption={!!caption} clickable={clickable}>
            <Checkbox
              small
              forOption
              label=""
              checked={checked}
              onChange={onClick}
            />
          </CheckboxContainer>
        )}
        {(avatarSrc || featureIcon) && (
          <AvatarContainer hasCaption={!!caption}>
            {featureIcon ? (
              <FeatureIcon
                icon={featureIcon.icon}
                color={featureIcon.color}
                size={24}
              />
            ) : (
              <Avatar src={avatarSrc} size={24} />
            )}
          </AvatarContainer>
        )}
        {LeftIcon && !avatarSrc && !featureIcon && (
          <Left hasCaption={!!caption}>
            <LeftIcon scale={16} />
          </Left>
        )}
        <Label>
          {label}
          {caption && <Caption>{caption}</Caption>}
        </Label>
        <Right
          hasCaption={!!caption}
          selectable={selectable}
          selected={!!selected}
        >
          {onToggle && (
            <div
              onClick={e => {
                e.stopPropagation()
              }}
            >
              <Toggle
                size="small"
                checked={!!toggleChecked}
                onChange={() => onToggle(!toggleChecked)}
              />
            </div>
          )}
          {shortcut?.length && <Shortcut keys={shortcut} />}
          {(RightIcon || selectable) && (
            <div>
              {selectable ? <Check scale={16} /> : <RightIcon scale={16} />}
            </div>
          )}
        </Right>
        {children}
      </StyledOption>
      {divider && <Divider />}
    </>
  )
}

const Label = styled.div<{}>`
  vertical-align: top;
`

const CheckboxContainer = styled.div<{
  hasCaption: boolean
  clickable?: boolean
}>`
  margin-top: ${p => (p.hasCaption ? 7 : 0)}px;
  margin-right: 7px;

  ${p =>
    p.clickable &&
    css`
      label {
        pointer-events: none;
      }
    `}
`

const AvatarContainer = styled.div<{ hasCaption: boolean }>`
  margin-right: 8px;
  margin-top: ${p => (p.hasCaption ? 4 : -3)}px;
`

const Left = styled.div<{ isLeftImage?: boolean; hasCaption: boolean }>`
  margin-right: 8px;
  margin-top: ${p => (p.hasCaption ? 8 : 1)}px;
`

const Right = styled.div<{
  hasCaption: boolean
  selectable?: boolean
  selected: boolean
}>`
  display: flex;
  margin-top: ${p => (p.hasCaption ? 10 : 1)}px;
  margin-left: auto;

  > div {
    margin-left: 4px;
  }

  ${p =>
    p.selectable &&
    css`
      path {
        fill-opacity: 0;
      }

      ${p.selected &&
      css`
        path {
          fill-opacity: 1;
        }
      `}
    `}
`

const Divider = styled.div`
  border-bottom: 1px solid ${p => p.theme.BorderHairline};
  padding-top: 4px;
  margin-bottom: 4px;
`

const StyledOption = styled.div<{
  arrowHovered?: boolean
  hasCaption: boolean
  clickable: boolean
  selected?: boolean
}>`
  display: flex;
  box-sizing: border-box;
  min-height: ${p => (p.hasCaption ? 48 : 32)}px;
  padding: 8px 16px;
  background-color: ${p => p.theme.ComponentWorkspaceBgPage};
  position: relative;
  transition: 100ms;

  :hover {
    cursor: ${p => (p.clickable ? 'pointer' : 'auto')};
    background-color: ${p => p.theme.BgOverlayElevation3};
  }

  :active {
    background-color: ${p => p.theme.BgOverlayElevation2};
    color: ${p => p.theme.ContentAccentPrimary};
  }

  :focus {
    outline: none;
    box-shadow: ${p => p.theme.FOCUS};
  }

  path {
    fill: ${p => p.theme.ContentPrimary};
  }

  ${p =>
    p.selected &&
    css`
      color: ${p => p.theme.ContentAccentPrimary};
      font-weight: 600;
    `}
`

const Caption = styled(BaseCaption)`
  color: ${p => p.theme.ContentSecondary};
  white-space: nowrap;
`
