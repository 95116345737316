/**
 * Formats the base URL to ensure it starts with the right protocol.
 */
export function formatBaseURL(baseURL: string, removeTrailingSlash = true) {
  if (baseURL.startsWith('http://') || baseURL.startsWith('https://')) {
    return removeTrailingSlash ? baseURL.replace(/\/+$/, '') : baseURL
  }

  if (baseURL.startsWith('localhost')) {
    return removeTrailingSlash
      ? `http://${baseURL.replace(/\/+$/, '')}`
      : `http://${baseURL}`
  }

  const formattedURL = `https://${baseURL}`
  return removeTrailingSlash ? formattedURL.replace(/\/+$/, '') : formattedURL
}
