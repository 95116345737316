import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Sheet, H2, H5 } from '@chordco/component-library'
import { useForm, useIsMobile } from 'hooks'
import { requestNew } from 'assets/integrations'
import { FormInput } from 'components/common'
import { useAuthData } from 'redux/state/auth'
import { useTenantsData } from 'redux/state/tenants'
import { valTypes } from 'validationSchemas'
import { useNotion } from 'hooks/useNotion'

type Props = {
  onClose: () => void
}

export const SourceRequestSheet: React.FC<Props> = ({ onClose }) => {
  const isMobile = useIsMobile()
  const size = isMobile ? 64 : 80

  const {
    state: { user },
  } = useAuthData()

  if (!user) throw new Error('Hub User not found!')

  const {
    state: { currentTenant },
  } = useTenantsData()

  if (!currentTenant) throw new Error('Current Tenant not found!')

  const { createDataSourceRequest } = useNotion()

  const [isLoading, setIsLoading] = useState(false)
  const [requestReceived, setRequestReceived] = useState(false)

  const submit = async fields => {
    setIsLoading(true)

    try {
      await createDataSourceRequest({
        tenantId: currentTenant.id,
        dataSourceName: fields.name,
        userName: `${user.firstName} ${user.lastName}`,
        userEmail: user.email ?? 'n/a',
      })

      setRequestReceived(true)
    } catch (error: any) {
      throw new Error(
        `An error occurred while requesting your data source: ${error.message}`
      )
    } finally {
      setIsLoading(false)
    }
  }
  const { fields, onSubmit } = useForm(
    {
      name: {
        type: valTypes.string,
        label: 'Data Source Name',
        required: true,
      },
    },
    submit
  )

  return (
    <Sheet
      title="Data Source Request"
      onClose={onClose}
      isLoading={isLoading}
      closeButtonTitle={requestReceived ? 'Close' : 'Cancel'}
    >
      <Container isMobile={isMobile}>
        <LogoContainer>
          <Logo src={requestNew} alt="data source request" size={size} />
        </LogoContainer>

        <TitleContainer>
          <Title>Request Data Source Integration</Title>
        </TitleContainer>

        {requestReceived && (
          <RequestReceivedContainer>
            <H5>Request Received!</H5>
            We&apos;re reviewing your data source suggestion and will update you
            soon. Thanks for your input!
          </RequestReceivedContainer>
        )}

        {!requestReceived && (
          <FormContainer>
            <DescriptionContainer>
              Simply enter the name of the data source you wish to be added, and
              our team will diligently review your request. We aim to expand our
              platform&apos;s capabilities to meet your needs and will get back
              to you promptly with an update. Should you have any questions or
              require further assistance, feel free to contact us at{' '}
              <Link href="mailto:help@chord.co" target="_blank">
                help@chord.co
              </Link>
              .
            </DescriptionContainer>

            <FormInput field={fields.name} customWidth="100%" maxLength={30} />

            <Button
              onClick={onSubmit}
              type="submit"
              purpose="primary"
              isLoading={isLoading}
            >
              Submit
            </Button>
          </FormContainer>
        )}
      </Container>
    </Sheet>
  )
}

const Container = styled.div<{ isMobile: boolean }>`
  min-height: calc(100vh - 76px);
  overflow: auto;
  padding: ${p =>
    `12px ${p.isMobile ? 12 : 24}px 100px ${p.isMobile ? 12 : 24}px`};
  position: relative;

  & > div {
    margin-top: 20px;
    margin-bottom: 20px;

    :last-child {
      margin-bottom: 0;
    }
  }
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

const Title = styled(H2)`
  font-style: normal;
  line-height: normal;
  font-weight: 500;
  text-align: center;
`

const DescriptionContainer = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-style: normal;
  font-weight: 400;
`

const RequestReceivedContainer = styled.div`
  padding: 12px;
  background: ${p => p.theme.BgAccentLightAADarkSuccess};
  border-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${p => p.theme.GREY_80};
`

const Link = styled.a`
  color: inherit;
`

const Logo = styled.img<{ size?: number }>`
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  border-radius: 4px;
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
`
