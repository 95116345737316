import { Button, H4, standardIcons } from '@chordco/component-library'
import React, { MouseEventHandler, ReactNode } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { InverseThemeProvider } from 'themes/ThemeSwitcherProvider'

const { Check } = standardIcons

export interface OnboardingTaskProps {
  title: string
  description: ReactNode
  button?: {
    title: string
    onClick: MouseEventHandler<HTMLButtonElement>
  }
  canCycle: boolean
  isComplete?: boolean
}

export const OnboardingTask: React.FC<OnboardingTaskProps> = ({
  title,
  description,
  button,
  canCycle,
  isComplete = false,
}) => {
  const isSmall = useMediaQuery({ query: '(max-width: 750px)' })

  return (
    <Wrapper>
      <InverseThemeProvider>
        <Container isComplete={isComplete} isSmall={isSmall}>
          <Circle isComplete={isComplete}>
            {isComplete && <Check size="large" />}
          </Circle>
          <TextContainer>
            <Title>{title}</Title>

            <Description isSmall={isSmall} hasButton={!!button}>
              {description}
            </Description>
          </TextContainer>
          {button && (
            <ButtonContainer>
              <Button onClick={button.onClick}>{title}</Button>
            </ButtonContainer>
          )}
        </Container>
      </InverseThemeProvider>

      {canCycle && (
        <>
          <SecondBehind />
          <Behind />
          <SecondBehind />
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`
const TextContainer = styled.div`
  flex: 1;
`

const Container = styled.div<{ isComplete: boolean; isSmall: boolean }>`
  position: relative;
  display: flex;
  grid-column-gap: 20px;
  align-items: center;
  min-height: 104px;
  margin-top: 16px;
  padding: ${p => (p.isSmall ? '16px 16px 16px 16px' : '20px 32px 20px 32px')};
  background-color: ${p => p.theme.BgBase1};
  color: ${p => p.theme.ContentPrimary};
  // border: 1px solid ${p => p.theme.BorderOptimal};
  border-radius: ${p => p.theme.CardMediumRadius};
`

const Title = styled(H4)`
  margin-bottom: 6px;
`

const Description = styled.div<{ isSmall: boolean; hasButton: boolean }>`
  // margin-right: ${p => (p.isSmall ? 0 : 216)}px;
  // margin-bottom: ${p => (p.isSmall && p.hasButton ? 48 : 0)}px;
`

const ButtonContainer = styled.div`
  // position: absolute;
  bottom: 16px;
  right: 36px;
  flex: none;
`

const Behind = styled.div`
  position: absolute;
  bottom: -16px;
  left: 16px;
  height: 60px;
  width: calc(100% - 32px);
  background-color: ${p => p.theme.BgStackReverseMiddle};
  border-radius: 8px;
  z-index: -1;
  border-radius: ${p => p.theme.CardMediumRadius};
`

const SecondBehind = styled(Behind)`
  bottom: -32px;
  left: 32px;
  width: calc(100% - 64px);
  background-color: ${p => p.theme.BgStackReverseBottom};
  z-index: -2;
  border-radius: ${p => p.theme.CardMediumRadius};
`

const Circle = styled.div<{ isComplete: boolean }>`
  background-color: ${p =>
    p.isComplete ? p.theme.BgAccentBrightAADarkSuccess : '#00000000'};
  height: 40px;
  width: 40px;
  border: 1px solid ${p => p.theme.BorderOptimal};
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  flex: none;

  // position: absolute;
  top: calc(50% - 20px);
  left: 16px;
  padding: 10px;

  path {
    fill: ${p => p.theme.BLACK};
  }
`
