import React from 'react'
import { Button, Label, Modal, Spinner } from '@chordco/component-library'
import { FormInput, FormTextArea } from 'components/common'
import { useForm } from 'hooks'
import styled from 'styled-components'
import { useCopyAudience } from './useCopyAudience'
import { AvailableUserAudience } from 'types'
import { useEmbedUser } from 'redux/hooks'

interface CopyAudienceModalProps {
  title: string
  description: string
  audienceId: string
  onClose: () => void
  onCopied: (audience?: AvailableUserAudience) => void
}

export const CopyAudienceModal: React.FC<CopyAudienceModalProps> = ({
  title,
  description,
  audienceId,
  onClose,
  onCopied,
}) => {
  const { isLoadingEmbedUser, embedUser } = useEmbedUser()

  const { submit, isLoading } = useCopyAudience(onCopied)

  const { fields, onSubmit } = useForm(
    {
      audienceId: {
        label: 'Audience ID',
        type: 'string',
        required: true,
        initialValue: audienceId,
      },
      audienceTitle: {
        label: 'Audience Title',
        type: 'string',
        required: true,
        initialValue: `${title} (Copy)`,
      },
      audienceDescription: {
        label: 'Audience Description',
        type: 'string',
        required: false,
        initialValue: description,
      },
      folderId: {
        label: 'Folder ID',
        type: 'string',
        required: true,
        initialValue: embedUser?.personalFolderId,
      },
    },
    submit
  )

  return (
    <Modal
      onClose={onClose}
      title="Create New Audience from Template"
      width="480px"
    >
      <FormContainer onSubmit={onSubmit} noValidate>
        {isLoadingEmbedUser && (
          <SpinnerContainer>
            <Spinner scale={30} />
          </SpinnerContainer>
        )}

        {!isLoadingEmbedUser && embedUser && (
          <>
            <Section>
              <StyledLabel>
                Selected Audience: <strong>{title}</strong>
              </StyledLabel>
              <StyledLabel>
                Enter a title for your new audience. A copy of this prebuilt
                audience will be saved to your personal folder, where you can
                customize it to fit your needs.
              </StyledLabel>
              <FormInput
                field={fields.audienceTitle}
                customWidth="100%"
                required
              />
              <FormTextArea
                field={fields.audienceDescription}
                customWidth="100%"
              />
            </Section>

            <BottomSection>
              <Button purpose="tertiary" onClick={onClose}>
                Cancel
              </Button>

              <Button
                type="submit"
                purpose="primary"
                isLoading={isLoading}
                disabled={isLoading}
              >
                Create Audience
              </Button>
            </BottomSection>
          </>
        )}
      </FormContainer>
    </Modal>
  )
}

const FormContainer = styled.form`
  overflow: auto;
`

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledLabel = styled(Label)`
  white-space: pre-wrap;
`

const BottomSection = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`
