import React, { useState } from 'react'
import styled from 'styled-components'
import { Toast, ToastProps } from './Toast'
import { v4 as generateUUID } from 'uuid'

interface Props {
  children: React.ReactNode
}

const HEIGHTS = {
  BASE: 71, // includes margin-bottom
  DESCRIPTION: 20,
  BUTTONS: 47,
}

const calculateBottoms = (toasts: any) => {
  const heights = toasts.reduce((acc: number[], toast: any) => {
    let height = HEIGHTS.BASE
    if (toast.description) height += HEIGHTS.DESCRIPTION
    if (toast.buttons) height += HEIGHTS.BUTTONS
    acc.push(height)
    return acc
  }, [])

  let total = 0
  const bottoms = [] as number[]
  bottoms[toasts.length - 1] = 0
  for (let i = toasts.length - 2; i >= 0; i--) {
    total += heights[i + 1]
    bottoms[i] = total
  }

  return bottoms
}

export const ToastContext = React.createContext({} as any)

export const ToastContainer: React.FC<Props> = ({ children }) => {
  const [toasts, setToasts] = useState([]) as any
  const [marginBottom, setMarginBottom] = useState(0)

  const createToast = ({ title, description, type, buttons }: any) =>
    setToasts((prevToasts: any) => [
      ...prevToasts,
      { title, description, type, buttons, id: generateUUID() },
    ])

  const addToast = (props: any) => {
    if (toasts.length > 0) {
      setMarginBottom(147)
      setTimeout(() => {
        createToast(props)
        setMarginBottom(0)
      }, 200)
    } else {
      createToast(props)
    }
  }

  const removeToast = (id: string | number) =>
    setToasts((currentToasts: any) =>
      currentToasts.filter((toast: any) => toast.id !== id)
    )

  const bottoms = calculateBottoms(toasts)

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <Container marginBottom={marginBottom}>
        {toasts.map(
          (
            { title, description, type, buttons, id }: ToastProps,
            index: number
          ) => (
            <Toast
              id={id}
              title={title}
              description={description}
              type={type}
              onClose={() => removeToast(id)}
              buttons={buttons}
              key={id}
              bottom={bottoms[index]}
            />
          )
        )}
      </Container>
    </ToastContext.Provider>
  )
}

const Container = styled.div<{ marginBottom: number }>`
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 3;
`
