import React from 'react'
import contentfulLogo from 'assets/ContentfulLogo.png'
import {
  Bold,
  Column,
  HowToResolvePill,
  Link,
  StatusLine,
  Value,
} from 'components/systemHealth/common'
import { Section } from 'components/systemHealth/Section'
import { ContentStoreInsights, Health, Webhook } from 'types/systemHealth'
import {
  formatPercent,
  standardIcons,
  Tooltip,
} from '@chordco/component-library'
import styled from 'styled-components'

interface Props {
  contentStoreInsights?: ContentStoreInsights
  health: Health
  isLoading: boolean
  hasFailed: boolean
  isSmall: boolean
  retryFunction: () => void
}

const { Help } = standardIcons

const getHealthyCallPercentage = (webhooks?: Webhook[]) => {
  if (!webhooks) return

  const { total, healthy } = webhooks.reduce(
    (acc, cur) => ({
      total: acc.total + (cur.calls?.total || 0),
      healthy: acc.healthy + (cur.calls?.healthy || 0),
    }),
    {
      total: 0,
      healthy: 0,
    }
  )

  if (total === 0) return

  return healthy / total
}

const getLogLink = (url?: string, webhookId?: string) => {
  if (!url || !webhookId) return

  return `${url}/settings/webhooks/${webhookId}/activity-log`
}

export const ContentManagement: React.FC<Props> = ({
  contentStoreInsights,
  health,
  isLoading,
  hasFailed,
  retryFunction,
  isSmall,
}) => {
  const healthyCallPercentage = getHealthyCallPercentage(
    contentStoreInsights?.webhooks
  )

  return (
    <Section
      title="Content Management (CMS)"
      subTitle="Contentful"
      logo={contentfulLogo}
      link={contentStoreInsights?.url}
      operatingCorrectly={health.content}
      isSmall={isSmall}
      isLoading={isLoading}
      hasFailed={hasFailed}
      retryFunction={retryFunction}
    >
      {contentStoreInsights && (
        <>
          <Column isSmall={isSmall}>
            Entries: <Value>{contentStoreInsights.entries}</Value> <br />
            Content Types: <Value>{contentStoreInsights.contentTypes}</Value>
            {typeof healthyCallPercentage !== 'undefined' && (
              <>
                <Flex>
                  <br />
                  <StatusLine
                    status={
                      healthyCallPercentage >= 0.75 ? 'enabled' : 'disabled'
                    }
                    label="Successful Sync"
                    customStatuses={{
                      enabled: `${formatPercent(healthyCallPercentage)}%`,
                      disabled: `${formatPercent(healthyCallPercentage)}%`,
                    }}
                  />
                  <StyledTooltip text="If the percentage of successful syncs is below 75%, it is considered an issue.">
                    <Help scale={16} />
                  </StyledTooltip>
                </Flex>
                {healthyCallPercentage < 0.75 && (
                  <HowToResolvePill href="https://docs.chord.co/syncing-content-with-contentful" />
                )}
              </>
            )}
          </Column>
          <Column isSmall={isSmall}>
            <Bold>Webhooks</Bold>
            <br />
            {contentStoreInsights.webhooks?.map(w => (
              <StatusLine
                key={w.name}
                label={w.name}
                status={w.active ? 'enabled' : 'disabled'}
              />
            ))}
            {contentStoreInsights.webhooks?.some(w => !w.active) && (
              <HowToResolvePill href="https://www.contentful.com/developers/docs/concepts/webhooks/#:~:text=In%20the%20top%20navigation%20bar,the%20bottom%20of%20the%20screen" />
            )}
          </Column>
          <Column isSmall={isSmall}>
            <Bold>Logs</Bold>
            <br />
            {contentStoreInsights.webhooks?.map(w => {
              const logLink = getLogLink(contentStoreInsights.url, w.id)
              return (
                <>
                  <Link key={w.id} href={logLink} target="_blank">
                    {w.name} Log
                  </Link>
                </>
              )
            })}
          </Column>
        </>
      )}
    </Section>
  )
}

const Flex = styled.div`
  display: flex;
`

const StyledTooltip = styled(Tooltip)`
  margin-left: 6px;
  padding-top: 2px;

  path {
    fill: ${p => p.theme.GREY_4};
  }
`
