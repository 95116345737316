export interface SheetsClientOptions {
  tenantId: number
  storeId: number
  environment: string
}

export interface ColumnHeader {
  name: string
  key: string
  note?: string
  required: boolean
  unique: boolean
  validationRule?: ValidationRule
}

export type GoogleSheetsRowData = Record<string, any>

export interface GoogleSheetsPagination {
  totalRecords: number
  page: number
  nextPage: number | null
  lastPage: number | null
  prevPage: number | null
}

export interface ConfigurationSheetMetadata {
  requiredColumns: string[]
  uniqueColumns: string[]
  rowCount: number
}

export interface ConfigurationSheet<T> {
  data: {
    title: string
    description: string
    provisionState: string
    headers: ColumnHeader[]
    values: T[]
    rules: ValidationRule[]
    sheetUpload: {
      processing: boolean
      completed: boolean
      failed: boolean
      message: string | null
    }
  }
  pagination: GoogleSheetsPagination
}

export enum ValidationRuleType {
  ONE_OF_LIST = 'ONE_OF_LIST',
  CUSTOM_FORMULA = 'CUSTOM_FORMULA',
  DATE_IS_VALID = 'DATE_IS_VALID',
}

export interface ValidationRule {
  ruleType: ValidationRuleType
  allowedValues: string[]
}

export interface IntegrityCheckResponse {
  updatedRows: number
}

export interface GoogleSheetsClientProps {
  getSheetMetadata: (slug: string) => Promise<ConfigurationSheetMetadata>
  getSheet: (
    slug: string,
    page?: number,
    perPage?: number,
    forceRefresh?: boolean
  ) => Promise<ConfigurationSheet<GoogleSheetsRowData>>
  appendRow: (data: {
    spreadsheetId: string
    slug: string
    headers: ColumnHeader[]
    values: GoogleSheetsRowData
  }) => Promise<any>
  updateRow: (data: {
    spreadsheetId: string
    slug: string
    rowIndex: number
    headers: ColumnHeader[]
    values: GoogleSheetsRowData
    page: number
    pageSize: number
  }) => Promise<any>
  deleteRow: (data: {
    spreadsheetId: string
    slug: string
    rowIndex: number
    page: number
    pageSize: number
  }) => Promise<any>
  checkIntegrity: (data: {
    slug: string
    headers: ColumnHeader[]
    page: number
    pageSize: number
    signal?: AbortSignal
  }) => Promise<IntegrityCheckResponse>
}
