import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'
import {
  H2,
  PageTitle,
  standardIcons,
  useTheme,
} from '@chordco/component-library'
import { Hr } from './Header'

interface BackProps {
  name: string
  onBack: MouseEventHandler
  isMobile: boolean
}

const Back: React.FC<BackProps> = ({ name, onBack, isMobile }) => {
  const theme = useTheme()

  return (
    <BackContainer onClick={onBack}>
      <ArrowLeft
        scale={16}
        fill={theme.ContentAccentPrimary}
        isMobile={isMobile}
      />
      {!isMobile && <Name>{name}</Name>}
    </BackContainer>
  )
}

const BackContainer = styled.div`
  cursor: pointer;
  color: ${p => p.theme.ContentSecondary};
  display: flex;
  column-gap: 8px;

  :hover {
    color: ${p => p.theme.ContentPrimary};
  }
`

const ArrowLeft = styled(standardIcons.ArrowLeft)<{ isMobile: boolean }>`
  top: ${p => (p.isMobile ? 8 : 1)}px;
`

const Name = styled.div``

interface Props {
  title: string
  backName: string
  onBack?: MouseEventHandler
  isMobile: boolean
}

export const ItemHeader: React.FC<Props> = ({
  title,
  backName,
  onBack,
  isMobile,
}) => {
  return (
    <>
      <Container isMobile={isMobile}>
        {onBack && <Back name={backName} onBack={onBack} isMobile={isMobile} />}
        {isMobile ? <MobileTitle>{title}</MobileTitle> : <Title>{title}</Title>}
      </Container>
      {!isMobile && <Hr isMobile={false} />}
    </>
  )
}

const Container = styled.div<{ isMobile: boolean }>`
  position: relative;
  height: ${p => (p.isMobile ? 40 : 87)}px;
`

const Title = styled(PageTitle)`
  position: absolute;
  top: 26px;
`

const MobileTitle = styled(H2)`
  margin-left: 36px;
`

export const AddPhoto = props => {
  const { PlusCircle } = standardIcons

  return (
    <CompanyPlaceHolder {...props}>
      <PlusCircle />
    </CompanyPlaceHolder>
  )
}

const CompanyPlaceHolder = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 12px;
  background-color: ${p => p.theme.BgOverlayElevation1};
  display: flex;
  align-items: center;

  svg {
    margin: 0 auto;
  }

  path {
    fill: ${p => p.theme.ContentPrimary};
  }

  :hover {
    background-color: ${p => p.theme.BgOverlayElevation2};
    cursor: pointer;

    path {
      fill: ${p => p.theme.ContentPrimary};
    }
  }
`
