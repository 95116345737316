import { hubClient } from 'api/http'
import {
  TenantConfigSheet,
  CreateSheetRequest,
  UpdateSheetRequest,
} from 'types'

export interface ConfigSheetsClient {
  getSheets: (data: {
    tenantId: number
    storeId: number
  }) => Promise<TenantConfigSheet[]>
  createSheet: (
    data: CreateSheetRequest & { tenantId: number; storeId: number }
  ) => Promise<string>
  updateSheet: (
    data: UpdateSheetRequest & { tenantId: number; storeId: number }
  ) => Promise<string>
}

export const configSheetsClient: ConfigSheetsClient = {
  getSheets: (data: { tenantId: number; storeId: number }) => {
    const { tenantId, storeId } = data
    return hubClient.get(`tenants/${tenantId}/stores/${storeId}/config_sheets`)
  },

  createSheet: (
    data: CreateSheetRequest & {
      tenantId: number
      storeId: number
    }
  ) => {
    const { tenantId, storeId, ...sheetData } = data
    return hubClient.post(
      `tenants/${tenantId}/stores/${storeId}/config_sheets/create`,
      sheetData
    )
  },

  updateSheet: (
    data: UpdateSheetRequest & {
      tenantId: number
      storeId: number
    }
  ) => {
    const { tenantId, storeId, ...sheetData } = data
    return hubClient.put(
      `tenants/${tenantId}/stores/${storeId}/config_sheets/update`,
      sheetData
    )
  },
}
