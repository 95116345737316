import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Home: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill={fill || theme.ContentPrimary}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.265 7.6845C16.2473 7.67341 16.2298 7.66196 16.2125 7.65015L10.247 3.56527C10.0939 3.47824 9.9061 3.47824 9.75299 3.56527L3.78748 7.65015C3.77023 7.66196 3.75273 7.67341 3.735 7.68449C3.58881 7.77586 3.5 7.9361 3.5 8.10849V16C3.5 16.2761 3.72386 16.5 4 16.5H7V13C7 12.1716 7.67157 11.5 8.5 11.5H11.5C12.3284 11.5 13 12.1716 13 13V16.5H16C16.2761 16.5 16.5 16.2761 16.5 16V8.10849C16.5 7.9361 16.4112 7.77586 16.265 7.6845ZM11.5 18V13H8.5V18H4C2.89543 18 2 17.1046 2 16V8.10849C2 7.41891 2.35524 6.77797 2.94 6.4125L8.94 2.304C9.58854 1.89867 10.4115 1.89867 11.06 2.304L17.06 6.4125C17.6448 6.77797 18 7.41891 18 8.10849V16C18 17.1046 17.1046 18 16 18H11.5Z"
      />

      {/*<g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5003 18C16.5003 17.9581 16.502 17.9162 16.5055 17.8744L17.3585 7.72392C17.4039 7.18361 17.1542 6.66086 16.7054 6.35664L10.8418 2.38247C10.3337 2.03804 9.66688 2.03804 9.15869 2.38248L3.29517 6.35664C2.84634 6.66086 2.59661 7.18361 2.64202 7.72392L3.495 17.8744C3.49851 17.9162 3.50027 17.9581 3.50027 18C3.50027 18.1326 3.55295 18.2598 3.64671 18.3535C3.74048 18.4473 3.86766 18.5 4.00027 18.5H6.50027V14.2C6.50027 12.8193 7.61956 11.7 9.00027 11.7H11.0003C12.381 11.7 13.5003 12.8193 13.5003 14.2V18.5H16.0003C16.1329 18.5 16.2601 18.4473 16.3538 18.3535C16.4476 18.2598 16.5003 18.1326 16.5003 18ZM2.00027 18L1.14729 7.84953C1.05648 6.76891 1.55592 5.7234 2.45359 5.11497L8.31711 1.14081C9.33348 0.451927 10.667 0.451925 11.6834 1.1408L17.5469 5.11497C18.4446 5.72339 18.9441 6.76891 18.8532 7.84953L18.0003 18C18.0003 18.5304 17.7896 19.0391 17.4145 19.4142C17.0394 19.7893 16.5307 20 16.0003 20H13.0003C12.448 20 12.0003 19.5523 12.0003 19V14.2C12.0003 13.6477 11.5526 13.2 11.0003 13.2H9.00027C8.44798 13.2 8.00027 13.6477 8.00027 14.2V19C8.00027 19.5523 7.55255 20 7.00027 20H4.00027C3.46983 20 2.96113 19.7893 2.58605 19.4142C2.21098 19.0391 2.00027 18.5304 2.00027 18Z"
          fill={fill || theme.GREY_80}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white" />
        </clipPath>
  </defs>*/}
    </svg>
  )
}
