import { hubClient } from '../http'
import { RolePermission } from 'types'

export interface RoleClient {
  getRolePermissionDescriptions: (tenantId: number) => Promise<RolePermission[]>
}

export const roleClient: RoleClient = {
  getRolePermissionDescriptions: tenantId =>
    hubClient.get(`tenants/${tenantId}/role_permission_descriptions`),
}
