import React from 'react'
import styled from 'styled-components'
import { Tenant as TenantType, User } from 'types'
import { Header } from './Header'
import { Content } from './Content'
import { Environment } from './Environment'
import { Tenant } from './Tenant'
import { InverseThemeProvider } from 'themes/ThemeSwitcherProvider'

interface Props {
  user: User
  navOpen: boolean
  tenants: TenantType[]
  currentTenant: TenantType | undefined
  selectTenant: (tenantId: number) => void
}

export const DesktopNavigation: React.FC<Props> = ({
  user,
  navOpen,
  tenants,
  currentTenant,
  selectTenant,
}) => {
  const showTenantSwitcher = !!(currentTenant && tenants?.length > 0)

  return (
    <InverseThemeProvider>
      <StandardNav navOpen={navOpen}>
        <Fixed>
          <Header user={user} />
          {showTenantSwitcher && (
            <Tenant
              tenants={tenants}
              currentTenant={currentTenant}
              selectTenant={selectTenant}
            />
          )}

          <Environment />
        </Fixed>
        <Container showTenantSwitcher={showTenantSwitcher}>
          <Content />
        </Container>
      </StandardNav>
    </InverseThemeProvider>
  )
}

const StandardNav = styled.div<{
  navOpen: boolean
}>`
  height: 100vh;
  width: 280px;
  display: flex;
  flex-direction: column;
  margin-left: ${p => (p.navOpen ? 0 : -280)}px;
  float: left;
  background-color: ${p => p.theme.ComponentNavBgMainNav};
  color: ${p => p.theme.ContentPrimary};
  transition: 300ms;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none;
`

const Fixed = styled.div`
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Container = styled.div<{ showTenantSwitcher: boolean }>`
  overflow: scroll;
  flex: 1;
  padding: 0 20px 0px;

  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none;
`
