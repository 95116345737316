import React, { useLayoutEffect } from 'react'
import {
  ChevronContainer,
  SubItem,
  SubItems,
  SubNavItemType,
  SubSubItem,
} from './Item'
import styled from 'styled-components'
import { ChevronUp } from '../icons/standardIcons/ChevronUp'
import { useExpandCollapseAnimationNav } from '../../hooks'

export const SubNavItem: React.FC<
  SubNavItemType & {
    selectedId?: number
    selectItem: Function
    secondLevel?: boolean
    adjustHeight: (delta: number) => void
    parentIsOpen: boolean
  }
> = ({
  id,
  title,
  link,
  selectedId,
  beta,
  selectItem,
  subItems,
  secondLevel = false,
  adjustHeight,
  parentIsOpen,
}) => {
  const { isOpen, setIsOpen, height, maxHeight, ref } =
    useExpandCollapseAnimationNav()

  useLayoutEffect(() => {
    if (!subItems) return

    setIsOpen(
      selectedId ? subItems?.some(item => item.id === selectedId) : false
    )
  }, [selectedId])

  useLayoutEffect(() => {
    if (maxHeight !== undefined) {
      adjustHeight(isOpen ? maxHeight : -maxHeight)
    }
  }, [isOpen])

  const hasSubItems = !!subItems?.length

  const onSelect = () => {
    setIsOpen && setIsOpen((curVal: boolean) => !curVal)

    if (subItems) return

    selectItem(id, link)
  }

  const Container = secondLevel ? SubSubItem : SubItem

  const heightCalc = isOpen && parentIsOpen ? `${height}px` : '0px'

  return (
    <>
      <Container key={id} selected={id === selectedId} onClick={onSelect}>
        {secondLevel ? <StyledTitle>{title}</StyledTitle> : title}

        {beta && <BetaChip>Beta</BetaChip>}

        {hasSubItems && (
          <ChevronContainer open={isOpen}>
            <ChevronUp />
          </ChevronContainer>
        )}
      </Container>

      <SubItems ref={ref} height={heightCalc}>
        {subItems?.map(({ id, title, link, subItems, slug }) => (
          <SubNavItem
            key={id}
            id={id}
            title={title}
            link={link}
            subItems={subItems}
            selectItem={selectItem}
            selectedId={selectedId}
            secondLevel
            adjustHeight={adjustHeight}
            parentIsOpen={isOpen && parentIsOpen}
            slug={slug}
          />
        ))}
      </SubItems>
    </>
  )
}

const StyledTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`

const BetaChip = styled.span`
  font-size: 12px;
  color: ${p => p.theme.ContentPrimary};
  line-height: 16px;
  padding: 4px 8px;
  margin-left: 8px;
  border: 1px solid ${p => p.theme.BorderAccentTertiary};
  border-radius: 8px;
`
