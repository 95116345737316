import React, { useRef, useLayoutEffect, useState } from 'react'
import styled, { css } from 'styled-components'

type TabType = {
  icon: any
  label?: string
}

interface Props {
  leftSelected: boolean
  onChange: Function
  leftTab: TabType
  rightTab: TabType
  small?: boolean
}

export const ToggleTab: React.FC<Props> = ({
  leftSelected,
  onChange,
  leftTab: { icon: LeftIcon, label: leftLabel },
  rightTab: { icon: RightIcon, label: rightLabel },
  small = false,
}) => {
  const [leftWidth, setLeftWidth] = useState(0)
  const [rightWidth, setRightWidth] = useState(0)
  const leftRef = useRef() as any
  const rightRef = useRef() as any

  useLayoutEffect(() => {
    setLeftWidth(leftRef.current?.getBoundingClientRect().width || 0)
    setRightWidth(rightRef.current?.getBoundingClientRect().width || 0)
  }, [])

  return (
    <Container small={small}>
      {!!leftWidth && !!rightWidth && (
        <Slider
          width={leftSelected ? leftWidth : rightWidth}
          left={leftSelected ? 0 : leftWidth}
          small={small}
        />
      )}

      <Tab
        ref={leftRef}
        onClick={() => onChange(true)}
        selected={leftSelected}
        small={small}
      >
        <LeftIcon scale={16} selected={leftSelected} />
        {leftLabel && <Label small={small}>{leftLabel}</Label>}
      </Tab>
      <Tab
        ref={rightRef}
        onClick={() => onChange(false)}
        selected={!leftSelected}
        small={small}
      >
        <RightIcon scale={16} selected={!leftSelected} />
        {rightLabel && <Label small={small}>{rightLabel}</Label>}
      </Tab>
    </Container>
  )
}

const Container = styled.div<{ small: boolean }>`
  position: relative;
  display: flex;
  box-sizing: border-box;
  border-radius: 8px;
  height: ${p => (p.small ? '32px' : '40px')};
  width: fit-content;
  background-color: ${p => p.theme.GREY_20};
`

const Slider = styled.div<{ width: number; left: number; small: boolean }>`
  position: absolute;
  left: ${p => p.left}px;
  height: 100%;
  width: ${p => p.width}px;
  box-sizing: border-box;
  border: solid 1px ${p => p.theme.GREY_30};
  border-radius: 8px;
  background-color: ${p => p.theme.WHITE};
  transition: 300ms;
`

const Tab = styled.div<{ selected: boolean; small: boolean }>`
  position: relative;
  padding: ${p => (p.small ? '8px' : '12px')};
  color: ${p => (p.selected ? p.theme.PRIMARY_50 : p.theme.GREY_50)};
  cursor: ${p => (p.selected ? 'auto' : 'pointer')};
  transition: 300ms;

  path {
    transition: fill 100ms;
    fill: ${p => (p.selected ? p.theme.PRIMARY_50 : p.theme.GREY_50)};
  }

  ${p =>
    !p.selected &&
    css`
      transition: color 100ms;

      :hover {
        color: ${p.theme.GREY_80};

        path {
          fill: ${p.theme.GREY_80};
        }
      }
    `}
`

const Label = styled.span<{ small: boolean }>`
  display: inline-block;
  vertical-align: top;
  margin-left: 8px;
  margin-top: ${p => (p.small ? '2px' : '1px')};
  font-size: ${p => (p.small ? '12px' : '14px')};
`
