import React from 'react'
import styled from 'styled-components'
import { SimpleBar } from '@chordco/component-library'
import { Col, TableHeader } from 'components/common/table'
import { ConnectedSource } from 'api/fivetran/interfaces'
import { SourceRow } from './SourceRow'
import { SourcePaginator } from './SourcePaginator'
import { ChordDataConnector } from 'types'

type Props = {
  sources: ConnectedSource[]
  allConnectors: ChordDataConnector[]
  onSelectSource: (connector: ConnectedSource) => void
  onPrevPage: () => void
  onNextPage: () => void
  showNextPageButton: boolean
  showPreviousPageButton: boolean
}

export const ConnectedSources: React.FC<Props> = ({
  sources,
  allConnectors,
  onSelectSource,
  onPrevPage,
  onNextPage,
  showNextPageButton,
  showPreviousPageButton,
}) => {
  const paginatorProps = {
    onPrevPage: onPrevPage,
    onNextPage: onNextPage,
    showNextPage: showNextPageButton,
    showPrevPage: showPreviousPageButton,
  }

  return (
    <Container>
      <TableWrapper>
        <StyledTableHeader>
          <Col flex={1}>Source</Col>
          <Col flex={1}>Data</Col>
          <Col flex={1}>Setup</Col>
          <Col flex={1}>Sync</Col>
          <Col>Last Synced</Col>
        </StyledTableHeader>

        <TableContent>
          {sources.map((source, index) => {
            const isModeled = allConnectors.find(
              c => c.slug === source.metadata.id
            )?.modeled

            return (
              <SourceRow
                key={`${source.metadata.id}_${index}`}
                source={source}
                onSelectSource={onSelectSource}
                isModeled={isModeled ?? false}
              />
            )
          })}
        </TableContent>
      </TableWrapper>

      <SourcePaginator {...paginatorProps} />
    </Container>
  )
}

const Container = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`

const TableWrapper = styled.div`
  border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
  border-radius: ${p => p.theme.CardMediumRadius};
  margin-bottom: 16px;
`

const StyledTableHeader = styled(TableHeader)`
  border-top-right-radius: ${p => p.theme.CardMediumRadius};
  border-top-left-radius: ${p => p.theme.CardMediumRadius};
  overflow: visible;
`

const TableContent = styled(SimpleBar)`
  border-bottom-right-radius: ${p => p.theme.CardMediumRadius};
  border-bottom-left-radius: ${p => p.theme.CardMediumRadius};
`
