import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const ArrowUp: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.41667C10.4142 3.41667 10.75 3.75246 10.75 4.16667V15.8333C10.75 16.2476 10.4142 16.5833 10 16.5833C9.58579 16.5833 9.25 16.2476 9.25 15.8333V4.16667C9.25 3.75246 9.58579 3.41667 10 3.41667Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.46951 3.63634C9.7624 3.34345 10.2373 3.34345 10.5302 3.63634L16.3635 9.46968C16.6564 9.76257 16.6564 10.2374 16.3635 10.5303C16.0706 10.8232 15.5957 10.8232 15.3028 10.5303L9.99984 5.22733L4.69683 10.5303C4.40394 10.8232 3.92907 10.8232 3.63617 10.5303C3.34328 10.2374 3.34328 9.76257 3.63617 9.46968L9.46951 3.63634Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
