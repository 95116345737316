import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Edit: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9469 2.61365C16.1378 2.42278 16.3967 2.31555 16.6666 2.31555C16.9365 2.31555 17.1954 2.42278 17.3863 2.61365C17.5771 2.80452 17.6844 3.06339 17.6844 3.33332C17.6844 3.60325 17.5771 3.86212 17.3863 4.05299L9.6165 11.8228L7.69739 12.3025L8.17717 10.3834L15.9469 2.61365ZM16.6666 0.815552C15.9989 0.815552 15.3585 1.08082 14.8863 1.55299L6.96961 9.46966C6.87349 9.56577 6.8053 9.68621 6.77234 9.81808L5.939 13.1514C5.87511 13.407 5.94999 13.6774 6.13628 13.8636C6.32256 14.0499 6.59293 14.1248 6.84851 14.0609L10.1818 13.2276C10.3137 13.1946 10.4342 13.1264 10.5303 13.0303L18.4469 5.11365C18.9191 4.64148 19.1844 4.00107 19.1844 3.33332C19.1844 2.66556 18.9191 2.02516 18.4469 1.55299C17.9748 1.08082 17.3344 0.815552 16.6666 0.815552ZM2.75 8C2.75 6.20511 4.20518 4.75 6.00014 4.75H9.4375C9.85171 4.75 10.1875 4.41422 10.1875 4C10.1875 3.58579 9.85171 3.25 9.4375 3.25H6.00014C3.37682 3.25 1.25 5.37662 1.25 8V14C1.25 16.6234 3.37665 18.75 6 18.75H12C14.6234 18.75 16.75 16.6234 16.75 14V10.5625C16.75 10.1483 16.4142 9.8125 16 9.8125C15.5858 9.8125 15.25 10.1483 15.25 10.5625V14C15.25 15.7949 13.7949 17.25 12 17.25H6C4.20507 17.25 2.75 15.7949 2.75 14V8Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
