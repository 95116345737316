import { FormEventHandler, useEffect, useState, useMemo } from 'react'
import * as yup from 'yup'
import { generateYupChain, ValSchemaField, ValueType } from 'validationSchemas'
import { usePrevious } from './index'

type Errors = { [key: string]: string }

type ValidateFn = (values: {}, schema: yup.ObjectSchema<any>) => Errors

const checkIsDirty = (initialFields: any, fields: any) => {
  let isDirty = false
  Object.keys(initialFields).forEach(key => {
    if (initialFields[key] !== fields[key]) {
      isDirty = true
    }
  })

  return isDirty
}

export const validateSchema: ValidateFn = (values, schema) => {
  try {
    schema.validateSync(values, { abortEarly: false })
    return []
  } catch (errors) {
    return (
      // @ts-ignore
      errors.inner?.reduce(
        (
          acc: Errors,
          { path, message }: { path: string; message: string }
        ) => ({
          ...acc,
          [path]: message,
        }),
        {}
      ) || {}
    )
  }
}

export type FieldValue = string | boolean | number | Array<any>

export type FormField<FieldValueType = FieldValue> = {
  name: string
  type: ValueType
  label?: string
  subLabel?: string
  hint?: string
  value: FieldValueType
  setValue: <SetValueType = FieldValue>(val: SetValueType) => void
  errorMessage?: string
  isRequired?: boolean
}

const getDefaultValue = (type: ValueType) => {
  switch (type) {
    case 'boolean':
      return false

    case 'number':
      return undefined

    case 'array':
      return []

    default:
      return ''
  }
}

export const useForm = <T extends { [key: string]: ValSchemaField }>(
  schema: T,
  submit: Function
): {
  fields: { [key in keyof T]: FormField }
  setFields: (v: { [key in keyof T]?: FieldValue }) => void
  resetField: (field: keyof T, value?: FieldValue) => void
  onSubmit: FormEventHandler
  isDirty: boolean
  validateValues: () => Errors
} => {
  const yupChain = generateYupChain(schema)

  const initialValues = useMemo(
    () =>
      Object.keys(schema).reduce((acc, key) => {
        return {
          ...acc,
          [key]: schema[key]?.initialValue || getDefaultValue(schema[key].type),
        }
      }, {}),
    [schema]
  )

  const [errors, setErrors] = useState<Errors>({})
  const [fieldValues, setFieldValues] = useState(initialValues)
  const [isDirty, setIsDirty] = useState(false)

  const prevInitialValues = usePrevious(initialValues)
  useEffect(() => {
    const currentKeys = Object.keys(initialValues)
    const prevKeys = Object.keys(prevInitialValues || {})
    const shouldUpdate =
      currentKeys.some(
        key =>
          JSON.stringify(initialValues[key]) !==
          JSON.stringify(prevInitialValues?.[key])
      ) || currentKeys.length !== prevKeys.length
    if (shouldUpdate) setFieldValues(initialValues)
  }, [initialValues, prevInitialValues])

  const setField =
    (name: string) =>
    <SetFieldValue = FieldValue>(val: SetFieldValue) => {
      let currentlyDirty = isDirty
      setFieldValues(currentFields => {
        const updatedFields = { ...currentFields, [name]: val }
        currentlyDirty = checkIsDirty(initialValues, updatedFields)

        return updatedFields
      })
      setErrors({ ...errors, [name]: '' })
      if (currentlyDirty !== isDirty) setIsDirty(currentlyDirty)
    }

  const resetField = <K extends keyof T>(
    field: K,
    value: T[K]['initialValue']
  ) => {
    setFieldValues(currentFields => ({
      ...currentFields,
      [field]: value,
    }))
    setErrors(currentErrors => ({
      ...currentErrors,
      [field]: '',
    }))
  }

  const fields = {} as { [key in keyof T]: FormField }
  Object.keys(schema).forEach(key => {
    fields[key as keyof T] = {
      name: key,
      type: schema[key].type,
      value: fieldValues[key],
      label: schema[key].label,
      subLabel: schema[key].subLabel,
      hint: schema[key].hint,
      setValue: setField(key),
      errorMessage: errors[key],
      isRequired: schema[key].required,
    }
  })

  const setFields = (updates: object) => {
    setFieldValues({
      ...fieldValues,
      ...updates,
    })
  }

  const validateValues = () => {
    const errors = validateSchema(fieldValues, yupChain)
    setErrors(errors)
    return errors
  }

  const onSubmit: FormEventHandler = async e => {
    e?.preventDefault()

    Object.keys(schema)
      .filter(key => schema[key].type === 'string')
      .forEach(key => setField(key)(fieldValues?.[key]?.trim?.()))

    const errors = validateSchema(fieldValues, yupChain)
    const noErrors = !Object.keys(errors).length

    if (noErrors) {
      await submit(fieldValues)
      setIsDirty(false)
    }

    setErrors(errors)

    return noErrors
  }

  return {
    fields,
    setFields,
    resetField,
    onSubmit,
    isDirty,
    validateValues,
  }
}
