import { useState } from 'react'
import { useLookerData } from 'redux/state/looker'
import {
  AvailableUserReport,
  ImportReportResponse,
  LookerReportType,
} from 'types'

interface ImportReportData {
  reportSlug: string
  reportType: LookerReportType
  folderId: string
}

export const useImportReport = (
  onImported: (report?: AvailableUserReport) => void
) => {
  const { importReport, getAvailableUserReports } = useLookerData()

  const [isLoading, setIsLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const [hasSucceeded, setHasSucceeded] = useState(false)

  const submit = async ({
    reportSlug,
    reportType,
    folderId,
  }: ImportReportData) => {
    if (!importReport) return

    setIsLoading(true)
    setHasSucceeded(false)
    setHasFailed(false)

    try {
      const response = (await importReport(
        reportSlug,
        folderId,
        reportType
      )) as ImportReportResponse

      if (!response.error) {
        await getAvailableUserReports()
        setHasSucceeded(true)
        onImported()
      } else {
        setHasFailed(true)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error: ', error)
      setHasFailed(true)
    } finally {
      setIsLoading(false)
    }
  }

  return { submit, isLoading, hasSucceeded, hasFailed }
}
