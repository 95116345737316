import React from 'react'
import { standardIcons, AlertModal } from '@chordco/component-library'
import styled from 'styled-components'
import { Col, TableRow } from 'components/common'

const { CheckCircle } = standardIcons

type Props = {
  updatedRows: number
  onClose: () => void
}

export const IntegrityCheckResultsModal: React.FC<Props> = ({
  updatedRows,
  onClose,
}) => {
  return (
    <AlertModal
      onClose={onClose}
      icon={CheckCircle}
      title="Integrity Check Results"
      content="The integrity check has been completed."
      centered
      buttons={[
        {
          id: 1,
          text: 'Close',
          onClick: onClose,
          purpose: 'tertiary',
          disabled: false,
        },
      ]}
    >
      <TableRow>
        <Col flex={10}>
          <Text>Updated Rows (missing keys): {updatedRows}</Text>
        </Col>
      </TableRow>
    </AlertModal>
  )
}

const Text = styled.div`
  margin: 0 0 0 12px;
`
