import React, { InputHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import TextareaAutosize from 'react-autosize-textarea'

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  hint?: string
  errorMessage?: string
  width?: string
}

export const TextArea: React.FC<Props> = ({
  errorMessage,
  width,
  ...props
}) => {
  const { label, hint } = props

  return (
    <Container width={width} hint={hint} errorMessage={errorMessage}>
      <Label>{label}</Label>

      <StyledTextArea
        {...props}
        width={width}
        type="text"
        onKeyDown={(e: any) => e.stopPropagation()}
      />

      {hint && !errorMessage && <Hint>{hint}</Hint>}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  )
}

const Container = styled.label<{
  width?: string
  hint?: string
  errorMessage?: string
}>`
  display: block;
  position: relative;
  margin-bottom: ${({ hint, errorMessage }) =>
    hint || errorMessage ? '6px' : '0'};
  width: ${p => p.width || 'fit-content'};
  color: ${p => p.theme.ContentPrimary};
`

const Label = styled.div`
  margin-bottom: 4px;
  color: ${p => p.theme.ContentSecondary};
  white-space: pre;
`

const StyledTextArea = styled(TextareaAutosize) <Props>`
  resize: none;
  box-sizing: border-box;
  cursor: text;
  width: ${p => p.width || '280px'};
  min-height: 80px;
  max-height: 120px; // Increase this to allow for auto-resizing
  overflow: auto;
  padding: 10px 12px;
  background-color: ${p => p.theme.ComponentFieldBackgroundDefault};
  color: ${p => p.theme.ContentPrimary};
  border: solid 1px ${p => p.theme.ComponentFieldBorderDefault};
  border-radius: 8px;
  font: inherit;
  font-size: 14px;

  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }

  ::placeholder {
    color: ${p => p.theme.ContentTertiary};
  }

  :hover {
    border-color: ${p => p.theme.ComponentFieldBorderHover};
  }

  :focus {
    background-color: ${p => p.theme.ComponentFieldBackgroundActive};
    border: solid 1px
      ${p =>
    p.errorMessage
      ? p.theme.ComponentFieldBorderError
      : p.theme.ComponentFieldBorderActive};
    outline: none;
  }

  :disabled {
    border-color: ${p => p.theme.BorderDisabled};
    color: ${p => p.theme.ContentDisabled};
    box-shadow: none;
  }

  ${({ errorMessage, theme }) =>
    errorMessage &&
    css`
      border-color: ${theme.ComponentFieldBorderError};

      :hover,
      :focus {
        border-color: ${theme.ComponentFieldBorderError};
      }
    `}
`

const Hint = styled.div`
  position: absolute;
  bottom: -16px;
  left: 0;
  white-space: nowrap;
  font-size: 12px;
  color: ${p => p.theme.ContentSecondary};
`

const ErrorMessage = styled(Hint)`
  color: ${p => p.theme.ContentAccentError};
`
