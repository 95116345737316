import React, { cloneElement, useRef, useState } from 'react'
import moment from 'moment'
import { BaseContainer } from './common'
import { DayPickerSingleDateController } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import styled from 'styled-components'
import { commonProps } from './commonProps'
import { DateContainer, DateInputContainer } from './DateRangePicker'
import { useOutsideClickMultiple } from '../hooks'
import { Input } from '../inputs'

interface Props {
  date: moment.Moment | null
  onChange: any
  control: any
  hasInput?: boolean
  placeholder?: string
  left?: boolean
  isDayBlocked?: (day: moment.Moment) => boolean
}

export const DatePicker: React.FC<Props> = ({
  date,
  onChange,
  control,
  hasInput = true,
  isDayBlocked,
  left = false,
}) => {
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const datePickerRef = useRef() as any
  const controlRef = useRef() as any
  const inputRef = useRef() as any
  const mobileDatePickerRef = useRef() as any

  useOutsideClickMultiple(
    open,
    [datePickerRef, controlRef, inputRef, mobileDatePickerRef],
    () => setOpen(false)
  )

  return (
    <SingleContainer data-testid="DatePicker">
      <div ref={controlRef}>
        {cloneElement(control, {
          onClick: () => setOpen(true),
          selected: open,
        })}
      </div>
      {open && (
        <DateContainer left={left} ref={datePickerRef}>
          {hasInput && (
            <DateInputContainer ref={inputRef}>
              <StyledInput
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                customWidth="287px"
                placeholder='Try "Last quarter"'
              />
            </DateInputContainer>
          )}
          <DayPickerSingleDateController
            date={date}
            onDateChange={onChange}
            numberOfMonths={1}
            isDayBlocked={isDayBlocked}
            focused
            initialVisibleMonth={date ? () => date : undefined}
            {...commonProps}
          />
        </DateContainer>
      )}
    </SingleContainer>
  )
}

const SingleContainer = styled(BaseContainer as any)`
  .SingleDatePickerInput__withBorder {
    border: none;
    box-sizing: border-box;
    height: 40px;
    width: 280px;
  }

  .DateInput,
  .DateInput_input {
    width: 100%;
    height: 100%;
  }

  .DateInput_input {
    border-radius: 4px;
    border: solid 1px ${p => p.theme.BorderHairline};
    font-size: 14px;
    font-family: Inter, serif;
    background-color: ${p => p.theme.BgBase2};
    color: ${p => p.theme.ContentPrimary};
  }

  .DateInput_input::placeholder {
    color: ${p => p.theme.ContentTertiary};
  }

  .DateInput_input__focused {
    border: solid 1px ${p => p.theme.BorderAccentPrimary};
    border-radius: 4px;
  }
`

const StyledInput = styled(Input)``
