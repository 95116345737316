import { valTypes } from './index'
import { EnvVarConfig } from 'utils/envVarConfig'

const signUpCode = EnvVarConfig.REACT_APP_SIGN_UP_CODE

export const loginSchema = {
  email: {
    label: 'Email',
    type: valTypes.email,
    required: true,
  },
}

export const acceptSchema = {
  firstName: {
    label: 'First Name',
    type: valTypes.string,
    required: true,
  },
  lastName: {
    label: 'Last Name',
    type: valTypes.string,
    required: true,
  },
}

export const signUpSchema = {
  firstName: {
    label: 'First Name',
    type: valTypes.string,
    required: true,
  },
  lastName: {
    label: 'Last Name',
    type: valTypes.string,
    required: true,
  },
  email: { label: 'Email', type: valTypes.email, required: true },
  role: { label: 'Role', type: valTypes.string, required: true },
  tenantName: { label: 'Store Name', type: valTypes.string, required: true },
  githubHandle: {
    label: 'Github handle',
    type: valTypes.string,
    required: true,
    hint: 'We need your Github handle to grant access to Chord’s SDK starter code. Please ensure this is accurate.',
  },
  code: {
    label: 'Sign-up code',
    type: valTypes.string,
    required: true,
    customVal: fields =>
      fields.code !== signUpCode ? 'The code is incorrect' : undefined,
  },
  acceptTerms: {
    label:
      'I acknowledge this is a trial environment for evaluation purposes, and agree to the Privacy Policy and Terms of Service.',
    type: valTypes.boolean,
    required: true,
  },
}

export const forgotPasswordSchema = {
  email: {
    label: 'Email',
    type: valTypes.email,
    required: true,
  },
}
