import GoogleSheetsClient from 'api/google_sheets/client'
import { SheetsClientOptions } from 'api/google_sheets/types'
import React, { createContext, useContext, ReactNode, useMemo } from 'react'

interface SheetsProviderProps {
  clientOptions: SheetsClientOptions
  children: ReactNode
}

type GoogleSheetsContextValue = {
  googleSheetsClient: GoogleSheetsClient | null
}

const GoogleSheetsContext = createContext<GoogleSheetsContextValue | null>(null)

export const useGoogleSheets = () => {
  const contextValue = useContext(GoogleSheetsContext)
  if (!contextValue) {
    throw new Error('useSheets must be used within a SheetsProvider')
  }
  return contextValue
}

export const GoogleSheetsProvider: React.FC<SheetsProviderProps> = ({
  clientOptions,
  children,
}) => {
  const googleSheetsClient = new GoogleSheetsClient(clientOptions)

  const contextValue: GoogleSheetsContextValue = useMemo(() => {
    return {
      googleSheetsClient,
    }
  }, [googleSheetsClient])

  return (
    <GoogleSheetsContext.Provider value={contextValue}>
      {children}
    </GoogleSheetsContext.Provider>
  )
}
