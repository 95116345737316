import React from 'react'
import styled from 'styled-components'
import { Icon } from '../controls'
import { standardIcons } from '../icons'
import { ForceThemeProvider } from 'themes/ThemeSwitcherProvider'

export type BannerType = 'info' | 'success' | 'caution' | 'warning'

type Props = {
  type: BannerType
  onClose: () => void
  children: React.ReactNode
}

const { Exit } = standardIcons

export const Banner: React.FC<Props> = ({ type, onClose, children }) => {
  return (
    <ForceThemeProvider theme="light">
      <Container type={type}>
        <div>{children}</div>
        <Close icon={Exit} purpose="darkGhost" onClick={onClose} size="tiny" />
      </Container>
    </ForceThemeProvider>
  )
}

const Container = styled.div<{ type: BannerType }>`
  width: 100vw;
  padding: 14px 20px;
  color: ${p => p.theme.BLACK};
  display: flex;
  justify-content: center;
  position: relative;

  :not(:first-child) {
    margin-top: 5px;
  }

  background-color: ${p =>
    ({
      info: p.theme.BgAccentLightAADarkInfo,
      success: p.theme.BgAccentLightAADarkSuccess,
      caution: p.theme.BgAccentLightAADarkWarning,
      warning: p.theme.BgAccentLightAADarkError,
    }[p.type])};
`

const Close = styled(Icon)`
  position: absolute;
  top: 11px;
  right: 22px;
`
