import CensusHTTPClient, { CensusClientOptions } from 'api/census/client'
import React, { createContext, useContext, ReactNode, useMemo } from 'react'

interface CensusProviderProps {
  clientOptions: CensusClientOptions
  children: ReactNode
}

type CensusContextValue = {
  censusClient: CensusHTTPClient | null
}

const CensusContext = createContext<CensusContextValue | null>(null)

export const useCensus = () => {
  const contextValue = useContext(CensusContext)
  if (!contextValue) {
    throw new Error('useCensus must be used within a CensusProvider')
  }
  return contextValue
}

export const CensusProvider: React.FC<CensusProviderProps> = ({
  clientOptions,
  children,
}) => {
  const censusClient = new CensusHTTPClient(clientOptions)

  const contextValue: CensusContextValue = useMemo(() => {
    return {
      censusClient,
    }
  }, [censusClient])

  return (
    <CensusContext.Provider value={contextValue}>
      {children}
    </CensusContext.Provider>
  )
}
