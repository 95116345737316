import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Checkmark: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.8797 15L6.59943 14.7144L6.44978 14.5662L3.17572 11.2899L3.17774 11.2878L0 8.10786L3.70759 4.3978L6.88157 7.57393L14.4504 0L18.1579 3.71009L10.5892 11.2839L10.1615 11.7161L6.8797 15ZM4.88693 11.2822L6.87944 13.2761L6.88324 13.2723L6.88529 13.2743L8.87217 11.2861L9.29981 10.854L16.4391 3.71009L14.4504 1.72008L6.88157 9.29393L3.70759 6.11781L1.71888 8.10786L4.88902 11.2801L4.88693 11.2822Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
