import React from 'react'
import { IconProps, useTheme } from '@chordco/component-library'

export const Rocket: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1964_21473)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.73201 4.50459C8.98667 1.97192 12.5487 0.339256 15.672 0.395256C15.85 0.399923 15.9933 0.543256 15.998 0.72059C16.0707 3.83992 14.4247 7.40059 11.8893 9.65992C11.8487 11.8146 10.9653 13.9126 9.44201 15.4359C9.37534 15.5019 9.27867 15.5433 9.18401 15.5433C9.15134 15.5433 9.11934 15.5386 9.08867 15.5286C8.96934 15.4886 8.87067 15.3966 8.85267 15.2713L8.48467 12.7019L8.12934 13.0193C8.06601 13.0759 7.98667 13.1039 7.90734 13.1039C7.82134 13.1039 7.73601 13.0713 7.67134 13.0066L3.38667 8.72192C3.26134 8.59659 3.25534 8.39592 3.37334 8.26392L3.69067 7.90859L1.12134 7.54059C0.996006 7.52259 0.892006 7.43526 0.852006 7.31459C0.812673 7.19459 0.844673 7.06192 0.934673 6.97326C2.47934 5.42859 4.57734 4.54526 6.73201 4.50459ZM11.4213 6.63726C11.8487 6.63726 12.2753 6.47459 12.6 6.14992C13.25 5.50059 13.25 4.44259 12.6 3.79259C11.95 3.14259 10.8927 3.14259 10.2427 3.79259C9.59334 4.44259 9.59334 5.49992 10.2427 6.14992C10.5673 6.47459 10.994 6.63726 11.4213 6.63726ZM0.0145265 15.9639C0.12386 15.5992 1.10319 12.3766 1.81653 11.6632C2.61986 10.8606 3.92653 10.8599 4.73053 11.6632C5.53386 12.4666 5.53386 13.7739 4.73053 14.5772C4.01719 15.2906 0.794527 16.2699 0.42986 16.3793C0.39786 16.3886 0.36586 16.3933 0.33386 16.3933C0.246527 16.3933 0.16186 16.3593 0.0978599 16.2953C0.0118599 16.2086 -0.0208068 16.0813 0.0145265 15.9639Z"
          fill={fill || theme.WHITE}
        />
      </g>
      <defs>
        <clipPath id="clip0_1964_21473">
          <rect
            {...props}
            width={scale}
            height={scale}
            transform="translate(0 0.39325)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
