import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Past: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.03033 1.53033C6.32322 1.23744 6.32322 0.762563 6.03033 0.46967C5.73744 0.176777 5.26256 0.176777 4.96967 0.46967L2.96967 2.46967C2.67678 2.76256 2.67678 3.23744 2.96967 3.53033L4.96967 5.53033C5.26256 5.82322 5.73744 5.82322 6.03033 5.53033C6.32322 5.23744 6.32322 4.76256 6.03033 4.46967L5.31066 3.75H10C14.0041 3.75 17.25 6.99594 17.25 11C17.25 15.0041 14.0041 18.25 10 18.25C5.99594 18.25 2.75 15.0041 2.75 11C2.75 10.5858 2.41421 10.25 2 10.25C1.58579 10.25 1.25 10.5858 1.25 11C1.25 15.8325 5.16751 19.75 10 19.75C14.8325 19.75 18.75 15.8325 18.75 11C18.75 6.16751 14.8325 2.25 10 2.25H5.31066L6.03033 1.53033ZM10.7499 6.5C10.7499 6.08578 10.4141 5.75 9.99993 5.75C9.58572 5.75 9.24993 6.08578 9.24993 6.5V11C9.24993 11.4142 9.58572 11.75 9.99993 11.75H12.9999C13.4141 11.75 13.7499 11.4142 13.7499 11C13.7499 10.5858 13.4141 10.25 12.9999 10.25H10.7499V6.5Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
