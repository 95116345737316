import React from 'react'
import shippoLogo from 'assets/ShippoLogo.png'
import { useForm } from 'hooks'
import { FormSecureInput } from 'components/common'
import { ValSchemaGeneric } from 'validationSchemas'
import { Fields } from '../common'
import { Integration, ShippoSettings } from 'types'
import { useIntegrationForm } from 'redux/hooks'
import { getFulfillmentConfirmationMessage } from 'utils/confirmationMessages'
import { MultipleProviderForm } from '../MultipleProviderForm'

type Props = {
  onChangeProvider: () => void
  onClose: () => void
  integration?: Integration<ShippoSettings>
}

const SHIPPO_LOGIN_LINK = 'https://goshippo.com/login/'
const getSchema = (settings?: ShippoSettings): ValSchemaGeneric<['key']> => ({
  key: {
    label: 'Key',
    type: 'string',
    initialValue: settings?.key,
  },
})
export const ShippoForm: React.FC<Props> = ({
  onChangeProvider,
  onClose,
  integration,
}) => {
  const { submit, isLoading } = useIntegrationForm(
    'shippo',
    integration,
    onClose
  )

  const schema = getSchema(integration?.settings)
  const { fields, onSubmit } = useForm(schema, submit)

  const integrationTitle = 'Shippo'
  const confirmationMessage =
    getFulfillmentConfirmationMessage(integrationTitle)

  return (
    <MultipleProviderForm
      integrationType="shippo"
      integrationTitle={integrationTitle}
      logo={shippoLogo}
      loginLink={SHIPPO_LOGIN_LINK}
      confirmationMessage={confirmationMessage}
      onChangeProvider={onChangeProvider}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <Fields>
        <FormSecureInput field={fields.key} customWidth="100%" />
      </Fields>
    </MultipleProviderForm>
  )
}
