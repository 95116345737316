import React from 'react'
import styled from 'styled-components'
import { Container } from '@chordco/component-library'
import { RolePermission } from 'types'

interface RolePermissionsProps {
  roles: RolePermission[]
  selectedRole: string
}

export const RolePermissions: React.FC<RolePermissionsProps> = ({
  roles,
  selectedRole,
}) => (
  <Container>
    <RolePermissionHeading>Role Permissions:</RolePermissionHeading>
    <RolePermissionContent>
      {roles
        .filter(role => role.role === selectedRole)
        .map(role =>
          role.permission.map(permission => (
            <RolePermissionDescription key={permission}>
              <StyledList>
                <li>{permission}</li>
              </StyledList>
            </RolePermissionDescription>
          ))
        )}
    </RolePermissionContent>
  </Container>
)

const RolePermissionContent = styled.div`
  margin-top: 5px;
`

const RolePermissionHeading = styled.div`
  font-weight: 600;
  color: ${p => p.theme.ContentSecondary};
  margin-top: 12px;
`
const RolePermissionDescription = styled.div`
  color: ${p => p.theme.ContentSecondary};
`

const StyledList = styled.ul`
  padding: 0;
  margin-top: 5px;
  margin-left: 15px;
`
