export enum ActionTypes {
  FETCH_START = 'FETCH_START',
  FETCH_SUCCESS = 'FETCH_SUCCESS',
  FETCH_ERROR = 'FETCH_ERROR',
  REFRESH = 'REFRESH',
}

export interface FetchStartAction {
  type: ActionTypes.FETCH_START
}

export interface FetchSuccessAction<T> {
  type: ActionTypes.FETCH_SUCCESS
  payload: {
    items: T[] | undefined
    nextCursor: string | undefined
    prevCursor: string | undefined
  }
}

export interface FetchErrorAction {
  type: ActionTypes.FETCH_ERROR
  payload: Error | undefined
}

export interface RefreshAction {
  type: ActionTypes.REFRESH
}

export type Action<T> =
  | FetchStartAction
  | FetchSuccessAction<T>
  | FetchErrorAction
  | RefreshAction
