import { useLookerData } from 'redux/state/looker'
import { useState } from 'react'

interface UnpublishDashboardData {
  dashboardSlug: string
}

interface UnpublishDashboardResponse {
  error?: string
  payload: any
}

export const useUnpublishDashboard = (onUnpublish: () => void) => {
  const { unpublishDashboard } = useLookerData()

  const [isLoading, setIsLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const [hasSucceeded, setHasSucceeded] = useState(false)

  const unpublish = async ({ dashboardSlug }: UnpublishDashboardData) => {
    if (!unpublishDashboard) return

    setIsLoading(true)
    setHasSucceeded(false)
    setHasFailed(false)

    try {
      const response = await unpublishDashboard(dashboardSlug)
      const unpublishDashboardResponse = response as UnpublishDashboardResponse

      if (!unpublishDashboardResponse.error) {
        setHasSucceeded(true)
        onUnpublish()
      } else {
        setHasFailed(true)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error: ', error)
      setHasFailed(true)
    } finally {
      setIsLoading(false)
    }
  }

  return { unpublish, isLoading, hasSucceeded, hasFailed }
}
