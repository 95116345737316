import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const MetricBuilder: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 4C1.25 2.48122 2.48122 1.25 4 1.25H16C17.5188 1.25 18.75 2.48122 18.75 4V16C18.75 17.5188 17.5188 18.75 16 18.75H4C2.48122 18.75 1.25 17.5188 1.25 16V4ZM4 2.75C3.30964 2.75 2.75 3.30964 2.75 4V9.25H9.25V2.75H4ZM16 2.75H10.75V9.25H17.25V4C17.25 3.30964 16.6904 2.75 16 2.75ZM4 17.25H9.25V10.75H2.75V16C2.75 16.6904 3.30964 17.25 4 17.25ZM13 11.75C12.5858 11.75 12.25 12.0858 12.25 12.5C12.25 12.9142 12.5858 13.25 13 13.25H15C15.4142 13.25 15.75 12.9142 15.75 12.5C15.75 12.0858 15.4142 11.75 15 11.75H13ZM12.25 15C12.25 14.5858 12.5858 14.25 13 14.25H15C15.4142 14.25 15.75 14.5858 15.75 15C15.75 15.4142 15.4142 15.75 15 15.75H13C12.5858 15.75 12.25 15.4142 12.25 15ZM6.75 5C6.75 4.58579 6.41421 4.25 6 4.25C5.58579 4.25 5.25 4.58579 5.25 5V5.25H5C4.58579 5.25 4.25 5.58579 4.25 6C4.25 6.41421 4.58579 6.75 5 6.75H5.25V7C5.25 7.41421 5.58579 7.75 6 7.75C6.41421 7.75 6.75 7.41421 6.75 7V6.75H7C7.41421 6.75 7.75 6.41421 7.75 6C7.75 5.58579 7.41421 5.25 7 5.25H6.75V5ZM5.82341 12.7626C5.53052 12.4697 5.05565 12.4697 4.76275 12.7626C4.46986 13.0555 4.46986 13.5303 4.76275 13.8232L4.93951 14L4.76275 14.1768C4.46986 14.4697 4.46986 14.9445 4.76275 15.2374C5.05565 15.5303 5.53052 15.5303 5.82341 15.2374L6.00017 15.0607L6.17697 15.2375C6.46986 15.5303 6.94473 15.5303 7.23763 15.2375C7.53052 14.9446 7.53052 14.4697 7.23763 14.1768L7.06083 14L7.23763 13.8232C7.53052 13.5303 7.53052 13.0554 7.23763 12.7625C6.94473 12.4697 6.46986 12.4697 6.17697 12.7625L6.00017 12.9393L5.82341 12.7626ZM12.25 6C12.25 5.58579 12.5858 5.25 13 5.25H15C15.4142 5.25 15.75 5.58579 15.75 6C15.75 6.41421 15.4142 6.75 15 6.75H13C12.5858 6.75 12.25 6.41421 12.25 6Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
