import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Plus: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="plus-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.93355 3.33499C10.3478 3.33499 10.6836 3.67078 10.6836 4.08499V15.7517C10.6836 16.1659 10.3478 16.5017 9.93355 16.5017C9.51934 16.5017 9.18355 16.1659 9.18355 15.7517V4.08499C9.18355 3.67078 9.51934 3.33499 9.93355 3.33499Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.35022 9.91833C3.35022 9.50411 3.68601 9.16833 4.10022 9.16833H15.7669C16.1811 9.16833 16.5169 9.50411 16.5169 9.91833C16.5169 10.3325 16.1811 10.6683 15.7669 10.6683H4.10022C3.68601 10.6683 3.35022 10.3325 3.35022 9.91833Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
