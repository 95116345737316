import React from 'react'
import CapacityLlcLogo from 'assets/CapacityLlcLogo.png'
import EasyPostLogo from 'assets/EasyPostLogo.png'
import NarvarLogo from 'assets/NarvarLogo.png'
import OrderDeskLogo from 'assets/OrderDeskLogo.png'
import ShipHeroLogo from 'assets/ShipHeroLogo.png'
import ShipstationLogo from 'assets/ShipstationLogo.png'
import ShippoLogo from 'assets/ShippoLogo.png'
import { SelectProviderForm } from './SelectProviderForm'
import { CapacityLlcForm } from './fulfillment/CapacityLlcForm'
import { NarvarForm } from './fulfillment/NarvarForm'
import { OrderDeskForm } from './fulfillment/OrderDeskForm'
import { ShipheroForm } from './fulfillment/ShipheroForm'
import { ShipstationForm } from './fulfillment/ShipstationForm'
import { ShippoForm } from './fulfillment/ShippoForm'
import { EasyPostForm } from './fulfillment/EasyPostForm'
import {
  Integration,
  PossibleFulfillmentIntegrationSettings,
  ProviderOption,
} from 'types'

type Props = {
  onClose: () => void
  integration?: Integration<PossibleFulfillmentIntegrationSettings>
}

export const fulfillmentProviders: ProviderOption[] = [
  {
    key: 'Hub::Capacity',
    name: 'Capacity LLC',
    logo: CapacityLlcLogo,
    form: CapacityLlcForm,
  },
  {
    key: 'Hub::Easypost',
    name: 'EasyPost',
    logo: EasyPostLogo,
    form: EasyPostForm,
  },
  {
    key: 'Hub::Narvar',
    name: 'Narvar',
    logo: NarvarLogo,
    form: NarvarForm,
  },
  {
    key: 'Hub::OrderDesk',
    name: 'Order Desk',
    logo: OrderDeskLogo,
    form: OrderDeskForm,
  },
  {
    key: 'Hub::ShipHero',
    name: 'Shiphero',
    logo: ShipHeroLogo,
    form: ShipheroForm,
  },
  {
    key: 'Hub::Shipstation',
    name: 'Shipstation',
    logo: ShipstationLogo,
    form: ShipstationForm,
  },
  {
    key: 'Hub::Shippo',
    name: 'Shippo',
    logo: ShippoLogo,
    form: ShippoForm,
  },
]

export const FulfillmentProviderForm: React.FC<Props> = ({
  onClose,
  integration,
}) => {
  return (
    <SelectProviderForm
      name={'Fulfillment'}
      providers={fulfillmentProviders}
      integration={integration}
      onClose={onClose}
    />
  )
}
