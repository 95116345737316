import React from 'react'
import styled from 'styled-components'
import { useIsMobile, useExperiments } from 'hooks'
import {
  Avatar,
  AvatarSize,
  Chip,
  H4,
  standardIcons,
} from '@chordco/component-library'
import { useHistory } from 'react-router-dom'
import { TenantConfigSheet } from 'types'
import { TableRow } from 'components/common'
import { configurations } from 'assets/integrations'
import { configSheetState } from 'hooks/useModelAmplificationsActivationHelper'

interface ConfigSheetCardProps {
  sheet: TenantConfigSheet
}
const { ChevronRight } = standardIcons

export const ConfigSheetCard: React.FC<ConfigSheetCardProps> = ({ sheet }) => {
  const isMobile = useIsMobile()
  const size: AvatarSize = isMobile ? 32 : 48
  const history = useHistory()
  const configSheetStateDetails = configSheetState(sheet.provisionState)
  const tenantPocFeatureFlag = useExperiments('hub_chord_poc_tenant')
  const modelAmplificationsGA = useExperiments('hub_model_amplifications_ga')

  const handleRowClick = () => {
    if (tenantPocFeatureFlag?.enabled) return

    history.push(`/model-amplifications/${sheet.slug}`, {
      label: sheet.label,
    })
  }

  return (
    <Row isMobile={isMobile} onClick={handleRowClick}>
      <StyledAvatar src={configurations} size={size} />
      <TitleWrapper>
        <Title>{sheet.label}</Title>
        <Subtitle>{sheet.description}</Subtitle>
      </TitleWrapper>
      {modelAmplificationsGA?.enabled && (
        <Chip
          text={configSheetStateDetails['text']}
          color={configSheetStateDetails['color']}
          variant={configSheetStateDetails['variant']}
        />
      )}
      <ChevronContainer isMobile={isMobile}>
        <ChevronRight />
      </ChevronContainer>
    </Row>
  )
}

const Row = styled(TableRow)<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 16px;
  min-height: ${p => (p.isMobile ? 60 : 72)}px;
  margin-bottom: ${p => (p.isMobile ? 12 : 16)}px;
  position: relative;
  border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
  border-radius: ${p => p.theme.CardMediumRadius};

  :last-child {
    margin-bottom: 0;
  }

  :hover {
    border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
    background-color: ${p => p.theme.ComponentCardBgElevation1};
  }
  :active {
    border: 1px solid ${p => p.theme.BorderAccentPrimary};
  }
`

const TitleWrapper = styled.div`
  flex: 1;
  flex-direction: column;
`

const Title = styled(H4)`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`

const Subtitle = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${p => p.theme.ContentSecondary};
`

const StyledAvatar = styled(Avatar)`
  top: 12px;
  left: 16px;
`

const ChevronContainer = styled.div<{ isMobile: boolean }>`
  top: ${p => (p.isMobile ? 20 : 26)}px;
  right: ${p => (p.isMobile ? 14 : 28)}px;

  path {
    fill: ${p => p.theme.ContentPrimary};
  }
`