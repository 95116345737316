import { hubClient } from '../http'
import { DateRange, Store, StoreEnvironment, StoreOverview } from 'types'

export interface StoreClient {
  getStoreOverview: (dateRange: DateRange) => Promise<StoreOverview>
  getStores: (data: {
    tenantId: number
    environment?: StoreEnvironment
  }) => Promise<Store[]>
}

export const storeClient: StoreClient = {
  getStoreOverview: ({ start, end }) =>
    hubClient.get(`storeOverview?start=${start}&end=${end}`),
  getStores: ({ tenantId }) => hubClient.get(`tenants/${tenantId}/stores`),
}
