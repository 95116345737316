import type { Middleware } from '@reduxjs/toolkit'
import { v4 as generateUUID } from 'uuid'
import { Notification } from 'types'

export const notificationsMiddleware: Middleware = store => next => action => {
  const clearNotificationAfterDelay = (id: string, delay = 8000) => {
    setTimeout(
      () =>
        store.dispatch({
          type: 'notifications/clearNotification',
          payload: { id },
        }),
      delay
    )
  }

  if (action?.payload?.notification) {
    const id = generateUUID() as string
    const { message, type, undoAction } = action.payload.notification

    store.dispatch({
      type: 'notifications/addNotification',
      payload: { id, message, type, undoAction },
    })

    clearNotificationAfterDelay(id)
  }

  if (action?.type === 'notifications/addNotification') {
    const { id, type } = action.payload as Notification
    if (type === 'success') {
      clearNotificationAfterDelay(id)
    }
  }

  return next(action)
}
