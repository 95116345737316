import { Chip, H4, H5 } from '@chordco/component-library'
import { FormField } from 'hooks'
import React from 'react'
import styled from 'styled-components'
import { ChordDataConnector, TenantPlanEnum } from 'types'
import { obfuscateString } from 'utils/strings'

type Props = {
  fields: Record<string, FormField>
  selectedPlan: TenantPlanEnum
  dataConnectors: ChordDataConnector[]
  selectedConnectors: string[]
  selectedModels: string[]
  selectedEnvironment: string
}

export const CreateTenantConfirmation: React.FC<Props> = ({
  fields,
  selectedPlan,
  dataConnectors,
  selectedConnectors,
  selectedModels,
  selectedEnvironment,
}) => {
  const obfuscateValue = (value: string) => {
    const maxVisibleChars = 4
    const maxLength = 20
    return obfuscateString(value, maxVisibleChars, maxLength)
  }

  return (
    <Wrapper>
      <H4>Please confirm the following information</H4>
      <Row>
        <Label>Tenant Name:</Label>
        <Label>{fields.name.value}</Label>
      </Row>
      <Row>
        <Label>UUID:</Label>
        <Label>{fields.uuid.value}</Label>
      </Row>
      <Row>
        <Label>Team Admins</Label>
        <Label>{fields.adminEmails.value ?? 'None'}</Label>
      </Row>
      <Row>
        <Label>Plan:</Label>
        <Label>
          {Object.keys(TenantPlanEnum).find(
            plan => TenantPlanEnum[plan as TenantPlanEnum] === selectedPlan
          )}
        </Label>
      </Row>
      <Row>
        <Label>Environment:</Label>
        <Label>{selectedEnvironment.toUpperCase()}</Label>
      </Row>

      {selectedPlan === TenantPlanEnum.Autonomy && (
        <>
          <Hr />
          <H5>Chord OMS</H5>
          <Row>
            <Label>API Key:</Label>
            <Label>
              {obfuscateValue(fields.solidusApiKey.value as string)}
            </Label>
          </Row>
          <Row>
            <Label>Store ID:</Label>
            <Label>{fields.solidusStoreId.value}</Label>
          </Row>
          <Row>
            <Label>Tenant ID:</Label>
            <Label>{fields.solidusTenantId.value}</Label>
          </Row>
        </>
      )}

      {fields.fivetranGroupId.value && (
        <>
          <Hr />
          <H5>Data Sources</H5>
          <Row>
            <Label>Destination Group ID:</Label>
            <Label>{fields.fivetranGroupId.value}</Label>
          </Row>
          <Row>
            <Label>Connectors:</Label>
            <Label>
              <ChipContainer>
                {dataConnectors.length > 0 && selectedConnectors.length > 0 ? (
                  selectedConnectors.map((connector, i) => {
                    const name = dataConnectors.find(
                      c => c.slug === connector
                    )?.name
                    return <Chip key={i} text={name} variant="solid" />
                  })
                ) : (
                  <span>None</span>
                )}
              </ChipContainer>
            </Label>
          </Row>
        </>
      )}

      {fields.censusApiKey.value && (
        <>
          <Hr />
          <H5>Data Activations</H5>
          <Row>
            <Label>Census API Key:</Label>
            <Label>{obfuscateValue(fields.censusApiKey.value as string)}</Label>
          </Row>
        </>
      )}

      {fields.lookerClientId.value &&
        fields.lookerClientSecret.value &&
        fields.lookerGroupIds.value &&
        fields.lookerSharedFolderId.value && (
          <>
            <Hr />
            <H5>Looker</H5>
            <Row>
              <Label>Client ID:</Label>
              <Label>{fields.lookerClientId.value}</Label>
            </Row>
            <Row>
              <Label>Client Secret:</Label>
              <Label>
                {obfuscateValue(fields.lookerClientSecret.value as string)}
              </Label>
            </Row>
            <Row>
              <Label>Group IDs:</Label>
              <Label>{fields.lookerGroupIds.value}</Label>
            </Row>
            <Row>
              <Label>Shared Folder ID:</Label>
              <Label>{fields.lookerSharedFolderId.value}</Label>
            </Row>
            <Row>
              <Label>Models:</Label>
              <Label>
                <ChipContainer>
                  {selectedModels.length > 0 ? (
                    selectedModels.map((model, i) => {
                      return <Chip key={i} text={model} variant="solid" />
                    })
                  ) : (
                    <span>None</span>
                  )}
                </ChipContainer>
              </Label>
            </Row>
          </>
        )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  > * {
    flex: 1;
  }
`

const Label = styled.div`
  margin-bottom: 4px;
  color: ${p => p.theme.ContentSecondary};
  white-space: pre-wrap;
`

const Hr = styled.div<{
  bottom?: boolean
}>`
  border-top: solid 1px ${p => p.theme.BorderHairline};
  margin: 0;
`

const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`
