import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const NewCustomers90: React.FC<IconProps> = ({
  scale = 48,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.25 14.6409V16.8909H21V18.3909H23.25V20.6409H24.75V18.3909H27V16.8909H24.75V14.6409H23.25Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.25 17.6409C16.25 13.3607 19.7198 9.89087 24 9.89087C28.2802 9.89087 31.75 13.3607 31.75 17.6409C31.75 21.9211 28.2802 25.3909 24 25.3909C22.0023 25.3909 20.1812 24.6351 18.807 23.3938C15.4445 23.4958 12.75 26.2537 12.75 29.6409V36.6409H11.25V29.6409C11.25 25.8378 13.9893 22.6745 17.6025 22.0165C16.7492 20.7713 16.25 19.2644 16.25 17.6409ZM24 11.3909C20.5482 11.3909 17.75 14.1891 17.75 17.6409C17.75 21.0926 20.5482 23.8909 24 23.8909C27.4518 23.8909 30.25 21.0926 30.25 17.6409C30.25 14.1891 27.4518 11.3909 24 11.3909Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8791 34.2769C23.0711 35.8249 24.1871 36.7129 25.9151 36.7129C28.1471 36.7129 29.3831 35.1289 29.3831 32.3449C29.3831 31.1329 29.0711 30.1129 28.5311 29.3929C27.9431 28.6129 27.0671 28.1689 25.9391 28.1689C24.0671 28.1689 22.8071 29.3569 22.8071 31.0249C22.8071 32.7409 23.9591 33.8329 25.6631 33.8329C26.4551 33.8329 27.1271 33.5209 27.5351 32.9569L27.7151 32.6929C27.7271 32.7769 27.7271 32.8489 27.7271 32.9089C27.7271 34.4209 27.1271 35.3209 26.0111 35.3209C25.2431 35.3209 24.7271 34.9369 24.5831 34.2769H22.8791ZM27.3791 31.0009C27.3791 31.9009 26.7791 32.5009 25.9271 32.5009C25.0751 32.5009 24.5351 31.8889 24.5351 31.0009C24.5351 30.1249 25.0991 29.5249 25.9511 29.5249C26.8031 29.5249 27.3791 30.1129 27.3791 31.0009Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.2148 32.2489V32.6329C30.2148 35.1889 31.6428 36.7129 33.6708 36.7129C35.7108 36.7129 37.1388 35.1889 37.1388 32.6329V32.2489C37.1388 29.7409 35.7108 28.1689 33.6708 28.1689C31.6428 28.1689 30.2148 29.7409 30.2148 32.2489ZM35.4108 32.3089V32.5729C35.4108 34.3129 34.7028 35.2489 33.6708 35.2489C32.6508 35.2489 31.9428 34.3129 31.9428 32.5729V32.3089C31.9428 30.5809 32.6508 29.6329 33.6708 29.6329C34.7028 29.6329 35.4108 30.5809 35.4108 32.3089Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
