import moment from 'moment'
import { DATE_FORMAT } from './Constants'

const validateRequired = (value: string) => !!value.length

const validateNumber = (value: string) =>
  !!value.trim() && !isNaN(Number(value))

const validateDateTime = (value: string) =>
  !!value && moment(value, DATE_FORMAT, true).isValid()

export { validateRequired, validateNumber, validateDateTime }
