import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const ThickCheck: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      height={scale}
      width={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8916 2.79371C17.5578 3.28612 17.6987 4.22536 17.2063 4.89156L8.70628 16.3916C8.43635 16.7568 8.0155 16.98 7.56174 16.9987C7.10799 17.0174 6.67021 16.8295 6.37116 16.4877L2.87116 12.4877C2.32563 11.8643 2.38881 10.9166 3.01226 10.3711C3.63572 9.82559 4.58336 9.88876 5.12889 10.5122L7.40157 13.1096L14.7938 3.10839C15.2862 2.44219 16.2254 2.3013 16.8916 2.79371Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
