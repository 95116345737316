import React, { useCallback, useContext } from 'react'
import {
  ComponentLibraryProvider,
  ChordThemeProvider,
} from '@chordco/component-library'
import { lightTheme, darkTheme } from '@chordco/component-library/theme'
import { useLocalStorageValue } from '@react-hookz/web'
import { useExperiments } from 'hooks'

const THEME_KEY = 'SELECTED_THEME'

const themeMap = {
  light: lightTheme,
  dark: darkTheme,
}

const ThemeKeyContext = React.createContext<'light' | 'dark'>('light')
ThemeKeyContext.displayName = 'ThemeKeyContext'

export const useSelectedTheme = () => {
  const themeToggleTreatment = useExperiments('hub_theme_toggle')

  // Return the light theme by default if theme toggle is disabled via our split.io feature flag
  if (!themeToggleTreatment?.enabled) {
    return {
      key: 'light' as const,
      toggleTheme: () => undefined,
      selected: themeMap.light,
      inverse: themeMap.dark,
    }
  }

  const { value: themeKey = 'light', set: setThemeKey } = useLocalStorageValue(
    THEME_KEY,
    {
      defaultValue: window.matchMedia('(prefers-color-scheme: dark)').matches
        ? ('dark' as const)
        : ('light' as const),
    }
  )

  const toggleTheme = useCallback(
    () => setThemeKey(curr => (curr === 'dark' ? 'light' : 'dark')),
    [setThemeKey]
  )

  return {
    key: themeKey,
    toggleTheme,
    selected: themeKey === 'dark' ? themeMap.dark : themeMap.light,
    inverse: themeKey === 'dark' ? themeMap.light : themeMap.dark,
  }
}

export const useParentThemeKey = () => useContext(ThemeKeyContext)

export const ThemeSwitcherProvider: React.FC<{
  children: React.ReactNode
}> = props => {
  const theme = useSelectedTheme()

  return (
    <ComponentLibraryProvider theme={theme.selected}>
      <ThemeKeyContext.Provider value={theme.key ?? 'light'}>
        {props.children}
      </ThemeKeyContext.Provider>
    </ComponentLibraryProvider>
  )
}

export const InverseThemeProvider: React.FC<{
  children: React.ReactNode
}> = props => {
  const parentThemeKey = useParentThemeKey()
  const inverseThemeKey = parentThemeKey === 'light' ? 'dark' : 'light'

  return (
    <ChordThemeProvider theme={themeMap[inverseThemeKey]}>
      <ThemeKeyContext.Provider value={inverseThemeKey}>
        {props.children}
      </ThemeKeyContext.Provider>
    </ChordThemeProvider>
  )
}
export const ForceThemeProvider: React.FC<{
  theme?: 'light' | 'dark'
  children: React.ReactNode
}> = props => {
  const currentThemeKey = useParentThemeKey()
  const forcedThemeKey = props.theme ?? currentThemeKey

  return (
    <ChordThemeProvider theme={themeMap[forcedThemeKey]}>
      <ThemeKeyContext.Provider value={forcedThemeKey}>
        {props.children}
      </ThemeKeyContext.Provider>
    </ChordThemeProvider>
  )
}
