import React from 'react'
import { standardIcons, AlertModal } from '@chordco/component-library'

const { CheckCircle } = standardIcons

type Props = {
  onClose: () => void
}

export const IntegrityCheckCancelModal: React.FC<Props> = ({ onClose }) => {
  return (
    <AlertModal
      onClose={onClose}
      icon={CheckCircle}
      title="Integrity Check"
      content="The integrity check has been cancelled."
      subContent="Partial results may have been saved."
      centered
      buttons={[
        {
          id: 1,
          text: 'Close',
          onClick: onClose,
          purpose: 'primary',
          disabled: false,
        },
      ]}
    />
  )
}
