import React, { ComponentProps } from 'react'
import styled from 'styled-components'
import { Input } from '../inputs'

interface Props extends ComponentProps<'input'> {
  value: any
  onChange: any
  inputRef?: React.RefObject<HTMLInputElement>
}

export const Search: React.FC<Props> = ({ ...props }) => {
  return (
    <Container>
      <Input {...props} placeholder="Search" />
    </Container>
  )
}

const Container = styled.div`
  padding: 16px;
  border-bottom: solid 1px ${p => p.theme.BorderHairline};
  border-radius: 7px 7px 0 0;

  input {
    width: 206px;
  }
`
