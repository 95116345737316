import {
  FetchStartAction,
  FetchSuccessAction,
  FetchErrorAction,
  RefreshAction,
  ActionTypes,
} from './types'

export const actions = {
  fetchStart: (): FetchStartAction => ({
    type: ActionTypes.FETCH_START,
  }),

  fetchSuccess: <T>(
    items: T[],
    currentPage: number,
    nextPage: number | null,
    previousPage: number | null
  ): FetchSuccessAction<T> => ({
    type: ActionTypes.FETCH_SUCCESS,
    payload: {
      items,
      currentPage,
      nextPage,
      previousPage,
    },
  }),

  fetchError: (error: Error | undefined): FetchErrorAction => ({
    type: ActionTypes.FETCH_ERROR,
    payload: error,
  }),

  refresh: (): RefreshAction => ({
    type: ActionTypes.REFRESH,
  }),
}
