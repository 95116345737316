import React from 'react'
import styled from 'styled-components'
import { SecureField } from 'components/developers/SecureField'

interface Props {
  name: string
  value: string
  isSmall: boolean
}

export const HiddenVariable: React.FC<Props> = ({ name, value, isSmall }) => {
  return (
    <Container isSmall={isSmall}>
      <Name>{name}</Name>
      <SecureField value={value} isSmall={isSmall} />
    </Container>
  )
}

const Container = styled.div<{ isSmall: boolean }>`
  position: relative;
  display: ${p => (p.isSmall ? 'block' : 'flex')};
  justify-content: space-between;
  margin-bottom: 24px;
  padding-bottom: 8px;
  border-bottom: solid 1px ${p => p.theme.GREY_3};
`

const Name = styled.div`
  margin-top: 24px;
  font-size: 16px;
  font-weight: 600;
`
