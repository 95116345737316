import { hubClient } from '../http'
import { Role, User } from 'types'

export type SendEmailRequest = {
  user: {
    email: string
  }
  callback: string
}

export type LoginRequest = {
  token: string,
  stytchTokenType: string
}

export type InviteUserRequest = {
  email: string
  invitationUrl: string
  role: Role
  tenants?: number[] // array of tenant IDs
}

export type InviteUserResponse = {
  id: number
  email: string
  invitationAcceptedAt: string
  invitationSentAt: string
  invitationUrl: string
  displayName: string
}

export type AcceptRequest = {
  firstName: string
  lastName: string
}

export type LoginResponse = {
  token: string
  refreshToken: string
  id: number
  firstName: string
  lastName: string
  role: Role
  sessionToken: string
  b2b: boolean
}

export interface AuthClient {
  sendEmail: (data: SendEmailRequest) => Promise<void>
  getAuthenticatedUser: () => Promise<User>
  login: (data: LoginRequest) => Promise<LoginResponse>
  logout: () => Promise<void>
  inviteUser: (data: InviteUserRequest) => Promise<InviteUserResponse>
  accept: (data: AcceptRequest) => Promise<User>
}

export const authClient: AuthClient = {
  sendEmail: data => hubClient.put('stytch/send_email', data),
  getAuthenticatedUser: () => hubClient.get('users/me'),
  login: data =>
    hubClient.get('stytch/login', {
      params: { token: data.token, stytchTokenType: data.stytchTokenType },
    }),
  logout: () => hubClient.post('stytch/logout'),
  inviteUser: data =>
    hubClient.post('stytch', {
      user: { ...data, firstName: '', lastName: '' },
    }),
  accept: data => hubClient.put('stytch/accept', { user: { ...data } }),
}
