import { useEffect } from 'react'
import { useLookerData } from 'redux/state/looker'
import { useParentThemeKey } from 'themes/ThemeSwitcherProvider'
import { LookerReportType } from 'types'
import { useIsLoading } from 'redux/utils'

interface EmbedAudienceUrls {
  [key: string]: string
}

interface UseEmbedAudienceUrlResult {
  embedAudienceUrls: EmbedAudienceUrls
  isLoading: boolean
}

const useEmbedAudienceUrl = (
  slug: string,
  type: LookerReportType
): UseEmbedAudienceUrlResult => {
  const currentThemeKey = useParentThemeKey()

  const {
    state: { embedAudienceUrls },
    getEmbedAudienceUrl,
  } = useLookerData()

  const isLoading = useIsLoading('getEmbedAudienceUrl')

  useEffect(() => {
    if (getEmbedAudienceUrl) {
      getEmbedAudienceUrl(
        slug,
        type,
        currentThemeKey === 'dark'
          ? 'ChordDark2Audiences'
          : 'ChordLight2Audiences' // uses a special theme for audiences that hides some header elements
      )
    }
  }, [slug, type, currentThemeKey])

  return { embedAudienceUrls, isLoading }
}

export default useEmbedAudienceUrl
