import React, { useRef } from 'react'
import { useResponsiveWidth } from '../../hooks'
import { MetricType, StoreMetric } from '../../types'
import {
  Container,
  Metric,
  Label,
  Value,
  Comparison,
  Sign,
  UpDownContainer,
  getGridTemplateColumns,
} from './styles'
import {
  formatCurrency,
  formatPercent,
  UpDown,
} from '@chordco/component-library'

interface Props {
  metrics: StoreMetric[]
}

export const formatValue = (value: number, type: MetricType) => {
  switch (type) {
    case 'currency': {
      if (value === 0) return '0'

      return formatCurrency(value).substring(1)
    }
    case 'percent':
      return formatPercent(value, 2)
    case 'number':
    default:
      return value?.toLocaleString() || 0
  }
}

const getComparison = (value, previous, type) => {
  if (value === 0 && previous === 0) return '–'

  const isGreater = value > previous

  return `${isGreater ? 'Up' : 'Down'} from ${
    type === 'currency' ? '$' : ''
  }${formatValue(previous, type)}${type === 'percent' ? '%' : ''}`
}

export const Metrics: React.FC<Props> = ({ metrics }) => {
  const ref = useRef() as any
  const width = useResponsiveWidth(ref)
  const gridTemplateColumns = getGridTemplateColumns(width)

  return (
    <Container ref={ref} gridTemplateColumns={gridTemplateColumns}>
      {metrics.map(({ id, label, value, change, previous, type }) => (
        <Metric key={id}>
          <Label>{label}</Label>
          <Value>
            {type === 'currency' && <Sign>$ </Sign>}
            {formatValue(value, type)}
            {type === 'percent' && <Sign> %</Sign>}
          </Value>
          <Comparison>{getComparison(value, previous, type)}</Comparison>
          <UpDownContainer>
            <UpDown value={change} />
          </UpDownContainer>
        </Metric>
      ))}
    </Container>
  )
}
