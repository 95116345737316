import React, { useEffect, useState } from 'react'
import { useTenantsData } from 'redux/state/tenants'
import { useEnvironmentsData } from 'redux/state/environments'
import { useConfigSheetsData } from 'redux/state/configSheets'
import { Spinner, SimpleBar, Button } from '@chordco/component-library'
import { useIsLoading } from 'redux/utils'
import { Header, Section } from '../common'
import { ConfigSheetCard } from './SheetCard'
import { useIsMobile } from 'hooks'
import EmptySheets from './EmptySheets'
import { useAuthData } from 'redux/state/auth'
import { Plus } from '@chordco/component-library/components/icons/standardIcons/Plus'
import { UpdateConfigForm } from 'components/provisioning/UpdateConfigForm'
import { useProvisioningData } from 'redux/state/provisioning'
import styled from 'styled-components'

const ConfigSheets: React.FC = () => {
  const isMobile = useIsMobile()

  const { state } = useAuthData()

  const isCurrentUserSuperUser = state.user?.role === 'superuser'

  const [showConfigUiForm, setShowConfigUiForm] = useState(false)

  const {
    state: { currentTenant, currentStore },
  } = useTenantsData()

  const {
    state: { selectedEnvironment },
  } = useEnvironmentsData()

  if (!currentTenant) throw new Error('No current tenant')
  if (!currentStore) throw new Error('currentStore is undefined')
  if (!selectedEnvironment) throw new Error('No selected environment')

  const {
    state: { activeSheets },
    getSheets,
  } = useConfigSheetsData()

  const {
    state: { configSheets },
    getConfigSheets,
  } = useProvisioningData()

  useEffect(() => {
    getSheets && getSheets()

    if (
      isCurrentUserSuperUser &&
      !configSheets.length &&
      !isLoadingConfigSheets
    ) {
      getConfigSheets()
    }
  }, [])

  const loading = useIsLoading('getSheets')
  const isLoadingConfigSheets = useIsLoading('getConfigSheets')

  return loading ? (
    <Spinner scale={30} />
  ) : (
    <>
      <Header
        title="Model Amplifications"
        subtitle="Manage all your model amplifications in one place"
      >
        {configSheets.length && isCurrentUserSuperUser && (
          <StyledButton
            icon={Plus}
            variant="tertiary"
            onClick={() => setShowConfigUiForm(true)}
          >
            Configure
          </StyledButton>
        )}
      </Header>
      <SimpleBar>
        <Section isMobile={isMobile} hasBorder={false}>
          {loading && <Spinner scale={30} />}

          {!loading && activeSheets.length === 0 && <EmptySheets />}

          {!loading &&
            activeSheets.length > 0 &&
            activeSheets.map(sheet => (
              <ConfigSheetCard key={sheet.slug} sheet={sheet} />
            ))}
        </Section>
      </SimpleBar>

      {showConfigUiForm && configSheets && (
        <UpdateConfigForm
          onClose={() => setShowConfigUiForm(false)}
          activeSheets={activeSheets}
          configSheets={configSheets}
        />
      )}
    </>
  )
}

const StyledButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
`

export default ConfigSheets
