import React, { useEffect } from 'react'
import { AuthWrapper, Form, SubHeading } from './AuthWrapper'
import { useForm, useIsMobile } from 'hooks'
import { Button, H1, H2, Spinner } from '@chordco/component-library'
import { FormInputUnstyled as Input, LoadingPage } from '../common'
import { acceptSchema } from 'validationSchemas/auth'
import { useAuthData } from 'redux/state/auth'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { useEverySucceeded, useIsLoading } from 'redux/utils'
import useMixpanel from 'hooks/useMixpanel'

export const Accept: React.FC = () => {
  const { state, getAuthenticatedUser, accept } = useAuthData()
  useEffect(() => {
    if (!state.user) getAuthenticatedUser()
  }, [state.user])

  const { fields, onSubmit } = useForm(acceptSchema, accept)

  const history = useHistory()
  const { identify } = useMixpanel()

  const isMobile = useIsMobile()
  const Heading = isMobile ? H2 : H1

  const isAcceptLoading = useIsLoading('accept')
  const hasAcceptSucceeded = useEverySucceeded('accept')

  if (!state.user) return <LoadingPage />

  if (hasAcceptSucceeded || (state.user.firstName && state.user.lastName)) {
    identify(state.user)
    history.push('/')
  }

  return (
    <AuthWrapper>
      <Form isMobile={isMobile} onSubmit={onSubmit}>
        <>
          <Heading>Welcome to Chord!</Heading>
          <SubHeading isMobile={isMobile}>Please enter your name</SubHeading>
          {isAcceptLoading ? (
            <Loading>
              <Spinner scale={30} />
            </Loading>
          ) : (
            <>
              <Input field={fields.firstName} customWidth="100%" />
              <Input field={fields.lastName} customWidth="100%" />
              <Button onClick={onSubmit}>Submit</Button>
            </>
          )}
        </>
      </Form>
    </AuthWrapper>
  )
}

const Loading = styled.div`
  position: relative;
`
