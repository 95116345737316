import { useEffect } from 'react'
import { useLookerData } from 'redux/state/looker'
import { useParentThemeKey } from 'themes/ThemeSwitcherProvider'
import { LookerReportType } from 'types'
import { useIsLoading } from 'redux/utils'

interface EmbedUrls {
  [key: string]: string
}

interface UseEmbedUrlResult {
  embedUrls: EmbedUrls
  isLoading: boolean
}

const useEmbedUrl = (
  slug: string,
  type: LookerReportType
): UseEmbedUrlResult => {
  const currentThemeKey = useParentThemeKey()

  const {
    state: { embedUrls },
    getEmbedUrl,
  } = useLookerData()

  const isLoading = useIsLoading('getEmbedUrl')

  useEffect(() => {
    if (getEmbedUrl) {
      getEmbedUrl(
        slug,
        type,
        currentThemeKey === 'dark' ? 'ChordDark' : 'ChordLight2'
      )
    }
  }, [slug, type, currentThemeKey])

  return { embedUrls, isLoading }
}

export default useEmbedUrl
