import React from 'react'
import { standardIcons, AlertModal } from '@chordco/component-library'
import { ConnectedSource } from 'api/fivetran/interfaces'

const { Refresh } = standardIcons

type Props = {
  source: ConnectedSource
  onToggleSync: () => void
  onClose: () => void
}

export const ToggleSyncModal: React.FC<Props> = ({
  source,
  onToggleSync,
  onClose,
}) => {
  const isPaused = source.status.syncState === 'paused'
  const action = isPaused ? 'resume sync' : 'pause sync'

  const subContent = isPaused
    ? 'Connector is paused. Enabling it immediately starts a sync.'
    : ''

  return (
    <AlertModal
      onClose={onClose}
      icon={Refresh}
      content={`Are you sure you want to ${action} for ${source.metadata.name}?`}
      subContent={subContent}
      centered
      buttons={[
        {
          id: 1,
          text: 'No',
          onClick: onClose,
          purpose: 'tertiary',
          disabled: false,
        },
        {
          id: 2,
          text: `Yes, ${action}`,
          onClick: onToggleSync,
          purpose: 'primary',
          isLoading: false,
          disabled: false,
        },
      ]}
    />
  )
}
