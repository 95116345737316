import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Stop: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 4.5C4.61193 4.5 4.5 4.61193 4.5 4.75V15.25C4.5 15.3881 4.61193 15.5 4.75 15.5H15.25C15.3881 15.5 15.5 15.3881 15.5 15.25V4.75C15.5 4.61193 15.3881 4.5 15.25 4.5H4.75ZM3 4.75C3 3.7835 3.7835 3 4.75 3H15.25C16.2165 3 17 3.7835 17 4.75V15.25C17 16.2165 16.2165 17 15.25 17H4.75C3.7835 17 3 16.2165 3 15.25V4.75Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
