import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Import: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.75843 8.83333C3.17729 8.83333 3.51685 9.16912 3.51685 9.58333V15.4167C3.51685 15.6598 3.61452 15.8929 3.78836 16.0648C3.9622 16.2368 4.19797 16.3333 4.44382 16.3333L15.5562 16.3333C15.802 16.3333 16.0378 16.2368 16.2116 16.0648C16.3855 15.8929 16.4831 15.6598 16.4831 15.4167V9.58333C16.4831 9.16912 16.8227 8.83333 17.2416 8.83333C17.6604 8.83333 18 9.16912 18 9.58333V15.4167C18 16.0576 17.7425 16.6723 17.2842 17.1255C16.8259 17.5787 16.2043 17.8333 15.5562 17.8333H4.44382C3.79568 17.8333 3.17408 17.5787 2.71578 17.1255C2.25747 16.6723 2 16.0576 2 15.4167L2 9.58333C2 9.16912 2.33956 8.83333 2.75843 8.83333Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M9.45839 12.7753C9.47653 12.7936 9.49561 12.8109 9.51556 12.8273C9.56842 12.8708 9.62586 12.906 9.68614 12.9331C9.78171 12.9761 9.8879 13 9.99974 13C10.0021 13 10.0044 13 10.0068 13C10.096 12.9992 10.1852 12.9829 10.2697 12.9511C10.3668 12.9146 10.4579 12.8577 10.5361 12.7803L13.9069 9.447C14.2031 9.1541 14.2031 8.67923 13.9069 8.38634C13.6107 8.09344 13.1305 8.09344 12.8343 8.38634L10.7582 10.4394L10.7582 2.75C10.7582 2.33579 10.4186 2 9.99974 2C9.58088 2 9.24132 2.33579 9.24132 2.75L9.24132 10.4393L7.16529 8.38633C6.86911 8.09344 6.3889 8.09344 6.09272 8.38633C5.79653 8.67923 5.79653 9.1541 6.09272 9.447L9.45839 12.7753Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
