import React, { useState } from 'react'
import styled from 'styled-components'
import { useIsMobile } from 'hooks'
import {
  H4,
  Avatar,
  AvatarSize,
  Chip,
  ChipColor,
  Spinner,
  standardIcons,
} from '@chordco/component-library'
import { Integration, IntegrationStatus } from 'types'
import { TableRow } from '../../common'

interface Props {
  name: string
  photo: string
  integration?: Integration
  form: React.FC<{ onClose: () => void; integration?: Integration }>
  loading: boolean
  openSheetOnLoad?: boolean
}

export const getChipColor: (status: IntegrationStatus) => ChipColor = (
  status: IntegrationStatus
) => {
  let color: ChipColor = 'neutral'
  if (status === 'required') color = 'error'
  if (status === 'enabled') color = 'success'

  return color
}

const { ChevronRight } = standardIcons

export const IntegrationCard: React.FC<Props> = ({
  name,
  photo,
  integration,
  form,
  loading = false,
  openSheetOnLoad = false,
}) => {
  const isMobile = useIsMobile()
  const size: AvatarSize = isMobile ? 32 : 48

  const [sheetOpen, setSheetOpen] = useState(openSheetOnLoad)

  const IntegrationForm = form
  const status = integration ? 'enabled' : 'required'

  return (
    <>
      <Row
        isMobile={isMobile}
        onClick={() => setSheetOpen(true)}
        isLoading={loading}
      >
        <StyledAvatar src={photo} size={size} />
        <TitleWrapper>
          <Title isMobile={isMobile}>{name}</Title>
        </TitleWrapper>
        {loading ? (
          <Right isMobile={isMobile}>
            <Spinner />
          </Right>
        ) : (
          <StyledChip
            text={status}
            color={getChipColor(status)}
            variant="solid-secondary"
            isMobile={isMobile}
          />
        )}
        <ChevronContainer isMobile={isMobile}>
          <ChevronRight />
        </ChevronContainer>
      </Row>

      {sheetOpen && (
        <IntegrationForm
          onClose={() => setSheetOpen(false)}
          integration={integration}
        />
      )}
    </>
  )
}

const Row = styled(TableRow)<{ isMobile: boolean; isLoading: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 16px;
  min-height: ${p => (p.isMobile ? 60 : 72)}px;
  margin-bottom: ${p => (p.isMobile ? 12 : 16)}px;
  position: relative;
  opacity: ${p => (p.isLoading ? 0.7 : 1)};
  pointer-events: ${p => (p.isLoading ? 'none' : 'all')};
  border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
  border-radius: ${p => p.theme.CardMediumRadius};

  :last-child {
    margin-bottom: 0;
  }

  :hover {
    border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
    background-color: ${p => p.theme.ComponentCardBgElevation1};
  }
  :active {
    border: 1px solid ${p => p.theme.BorderAccentPrimary};
  }
`

const TitleWrapper = styled.div`
  flex: 1;
`

const Title = styled(H4)<{ isMobile: boolean }>`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`

const StyledAvatar = styled(Avatar)`
  top: 12px;
  left: 16px;
`

const Right = styled.div<{ isMobile: boolean }>`
  right: ${p => (p.isMobile ? 48 : 100)}px;
`

const StyledChip = styled(Chip)<{ isMobile: boolean }>`
  top: ${p => (p.isMobile ? 17 : 24)}px;
  right: ${p => (p.isMobile ? 40 : 71)}px;
`

const ChevronContainer = styled.div<{ isMobile: boolean }>`
  top: ${p => (p.isMobile ? 20 : 26)}px;
  right: ${p => (p.isMobile ? 14 : 28)}px;

  path {
    fill: ${p => p.theme.ContentPrimary};
  }
`
