import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Sync: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="sync-icon"
    >
      <path
        d="M5.6957 1.02958C5.41676 0.750638 4.96451 0.750638 4.68556 1.02958L0.876077 4.83907C0.597134 5.11801 0.597134 5.57026 0.876077 5.84921C1.15502 6.12815 1.60728 6.12815 1.88622 5.84921L4.47628 3.25915V12.0107C4.47628 12.4052 4.79607 12.725 5.19056 12.725C5.58504 12.725 5.90483 12.4052 5.90483 12.0107V3.259L8.49505 5.84921C8.77399 6.12815 9.22625 6.12815 9.50519 5.84921C9.78413 5.57026 9.78413 5.11801 9.50519 4.83907L5.6957 1.02958Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M12.3043 15.8491L8.49481 12.0396C8.21587 11.7607 8.21587 11.3084 8.49481 11.0295C8.77375 10.7506 9.22601 10.7506 9.50495 11.0295L12.0952 13.6197L12.0952 4.86799C12.0952 4.47351 12.415 4.15371 12.8094 4.15371C13.2039 4.15371 13.5237 4.47351 13.5237 4.86799L13.5237 13.6196L16.1138 11.0295C16.3927 10.7506 16.845 10.7506 17.1239 11.0295C17.4029 11.3084 17.4029 11.7607 17.1239 12.0396L13.3144 15.8491C13.0355 16.1281 12.5832 16.1281 12.3043 15.8491Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
