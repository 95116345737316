import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Tag: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2663 6.13314C10.6345 6.13314 10.9329 5.83466 10.9329 5.46647C10.9329 5.09828 10.6345 4.7998 10.2663 4.7998C9.89809 4.7998 9.59961 5.09828 9.59961 5.46647C9.59961 5.83466 9.89809 6.13314 10.2663 6.13314Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.51057 1.79728C7.64221 1.67076 7.81771 1.6001 8.00029 1.6001H13.6929C14.0832 1.6001 14.3996 1.91644 14.3996 2.30668V7.99857C14.3996 8.18644 14.3248 8.36657 14.1917 8.49916L8.93363 13.7363C8.02386 14.6425 6.54512 14.6177 5.66629 13.6815L2.21633 10.006C1.36298 9.09686 1.39968 7.67057 2.29867 6.80654L7.51057 1.79728ZM8.28486 3.01326L3.27812 7.82534C2.93712 8.15307 2.9232 8.69406 3.24688 9.03888L6.69681 12.7143C7.03016 13.0695 7.59105 13.0789 7.93617 12.7352L12.9863 7.7051V3.01326H8.28486Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
