import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { standardIcons } from '../icons'
import { Badge } from '../information'

interface Props {
  text: string
  icon?: any
  badgeNumber?: number
  onClear?: Function
  open?: boolean
  hasFilter?: boolean
}

export const FilterButton: React.FC<Props> = ({
  text,
  icon: Icon,
  badgeNumber = 0,
  onClear,
  open = false,
  hasFilter = false,
  ...props
}) => {
  const [tabbed, setTabbed] = useState(false)
  const onClick = (e: any) => {
    setTabbed(false)
    // @ts-ignore
    props.onClick(e)
  }

  const onKeyDown = () => {
    setTabbed(true)
  }

  const { Exit } = standardIcons
  return (
    <Button
      {...props}
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabbed={tabbed}
      open={open}
      hasFilter={badgeNumber > 0 || hasFilter}
    >
      {badgeNumber > 0 && (
        <BadgeContainer>
          <Badge number={badgeNumber} theme="pink" shade="dark" />
        </BadgeContainer>
      )}
      {Icon && !badgeNumber && (
        <IconContainer>
          <Icon scale={16} />
        </IconContainer>
      )}
      <Text>{text}</Text>
      {(badgeNumber > 0 || hasFilter) && (
        <ClearContainer
          onClick={(e: any) => {
            e.stopPropagation()
            onClear && onClear(e.target.value)
          }}
        >
          <Exit scale={12} />
        </ClearContainer>
      )}
    </Button>
  )
}

const Button = styled.button<{
  open: boolean
  tabbed: boolean
  hasFilter: boolean
}>`
  display: flex;
  box-sizing: border-box;
  height: 32px;
  border: none;
  padding: 4px 8px;
  font-size: 12px;
  color: ${p => p.theme.GREY_60};
  background-color: inherit;
  border-radius: 8px;
  cursor: pointer;

  :hover {
    color: ${p => p.theme.GREY_80};
    background-color: ${p => p.theme.TRANSPARENT_04};

    path {
      fill: ${p => p.theme.GREY_80};
    }
  }

  :focus {
    box-shadow: ${({ tabbed, theme }) => (tabbed ? theme.FOCUS : 'none')};
    outline: 0;
  }

  :active {
    color: ${p => p.theme.GREY_80};
    background-color: ${p => p.theme.GREY_30};
  }

  ${p =>
    p.open &&
    css`
      color: ${p => p.theme.GREY_80};
      background-color: ${p => p.theme.GREY_30};
    `}

  ${p =>
    p.hasFilter &&
    css`
      color: ${p => p.theme.PRIMARY_50};
      background-color: ${p => p.theme.WHITE};

      :hover {
        color: ${p => p.theme.PRIMARY_50};
        background-color: ${p => p.theme.WHITE};
      }

      :active {
        color: ${p => p.theme.PRIMARY_50};
        background-color: ${p => p.theme.WHITE};
      }
    `}
`

const IconContainer = styled.span`
  margin: 4px 6px 0 0;

  path {
    fill: ${p => p.theme.GREY_60};
  }
`

const Text = styled.span`
  margin-top: 6px;
  font-weight: 500;
`

export const ClearContainer = styled.span`
  height: 24px;
  width: 24px;
  box-sizing: border-box;
  padding: 6px;
  margin: 0 -4px 0 6px;
  border-radius: 8px;

  :hover {
    background-color: ${p => p.theme.GREY_80}14;
  }

  :active {
    background-color: ${p => p.theme.GREY_80}28;
  }
`
const BadgeContainer = styled.div`
  margin: 5px 6px 0 0;
`
