import { useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

export const useIsMobile = (): boolean =>
  useMediaQuery({ query: '(max-width: 580px)' })

export const useResponsiveWidth = ref => {
  const [width, setWidth] = useState<number>(0)

  const observer = new ResizeObserver(entries => {
    const { width: currentWidth } = entries[0].contentRect

    setWidth(currentWidth)
  })

  useEffect(() => {
    const element = ref.current
    if (!element) return

    observer.observe(element)

    return () => {
      observer.unobserve(element)
    }
  }, [ref])

  return width
}

export const useResponsiveHeight = ref => {
  const [height, setHeight] = useState<number>(0)

  const observer = useRef(
    new ResizeObserver(entries => {
      const { height: currentHeight } = entries[0].contentRect

      setHeight(currentHeight)
    })
  )

  useEffect(() => {
    const element = ref.current
    if (!element) return

    observer.current.observe(element)

    return () => {
      observer.current.unobserve(element)
    }
  }, [ref])

  return height
}
