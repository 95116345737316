import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import {
  AvailableReport,
  AvailableUserReport,
  GetAvailableReportsResponse,
  GetAvailableUserReportsResponse,
  GetSharedQuickstartResponse,
} from 'types'

export type MetricSlugData = {
  slug: string
  isMyFolder: boolean
  isDashboards: boolean
  isExplores: boolean
  isQuickstarts: boolean
}

const getSlugFromReports = (
  reports: { slug: string }[],
  pathSlug: string,
  fallbackSlug?: string
) => {
  const foundSlug = reports.find(({ slug }) => slug === pathSlug)?.slug
  return foundSlug || fallbackSlug || ''
}

const useMetricSlug = (
  availableReports: GetAvailableReportsResponse,
  availableUserReports: GetAvailableUserReportsResponse,
  availableQuickstarts: GetSharedQuickstartResponse
): MetricSlugData => {
  const history = useHistory()

  const { slug, isMyFolder, isDashboards, isExplores, isQuickstarts } =
    useMemo(() => {
      const path = history.location.pathname

      const isMyFolder = path.includes('my_folder')
      const isDashboards = path.includes('reports')
      const isExplores = path.includes('explores')
      const isQuickstarts = path.includes('quickstarts')

      const pathSegments = path.split('/')
      const pathSlug = pathSegments[pathSegments.length - 1]

      const allQuickstarts = [
        ...availableQuickstarts.dashboards,
        ...availableQuickstarts.looks,
      ]
      const allUserReports = [
        ...availableUserReports.userDashboards,
        ...availableUserReports.userLooks,
      ]

      let reports: AvailableReport[] | AvailableUserReport[] = []
      let fallbackSlug: string | undefined

      if (isMyFolder) {
        reports = allUserReports
        fallbackSlug =
          availableUserReports.userDashboards[0]?.slug ||
          availableUserReports.userLooks[0]?.slug
      } else if (isDashboards) {
        reports = availableReports.dashboards
      } else if (isExplores) {
        reports = availableReports.explores
        fallbackSlug = availableReports.explores[0]?.slug
      } else if (isQuickstarts) {
        reports = allQuickstarts
        fallbackSlug = allQuickstarts[0]?.slug
      }

      const slug = getSlugFromReports(reports, pathSlug, fallbackSlug)

      return { slug, isMyFolder, isDashboards, isExplores, isQuickstarts }
    }, [
      history.location.pathname,
      availableReports,
      availableUserReports,
      availableQuickstarts,
    ])

  return { slug, isMyFolder, isDashboards, isExplores, isQuickstarts }
}

export default useMetricSlug
