import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@chordco/component-library'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  color?: string
}

export const Dot: React.FC<Props> = ({ color, ...props }) => {
  const theme = useTheme()

  return (
    <StyledDot
      {...props}
      color={color || theme.ContentAccentPrimary}
      data-testid="dot"
    />
  )
}

const StyledDot = styled.div<{ color: string }>`
  height: 6px;
  width: 6px;
  border-radius: 6px;
  background-color: ${p => p.color};
`
