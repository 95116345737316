import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { getRangeDisplayValue, Period } from './utils'
import { standardIcons } from '../icons'

type Range = {
  startDate: moment.Moment
  endDate: moment.Moment
}

export type RangeOption = {
  label: string
  range: Range
  period: Period
}

interface Props {
  options: RangeOption[]
  onDatesChange: ({ startDate, endDate }: Range) => void
  clearInputValue: () => void
}

export const DateRangeOptions: React.FC<Props> = ({
  options,
  onDatesChange,
  clearInputValue,
}) => {
  const { Layers, ChevronRight } = standardIcons
  return (
    <Container>
      {options.map(option => (
        <Option
          key={option.label}
          onClick={() => {
            onDatesChange(option.range)
            clearInputValue()
          }}
        >
          <LayersContainer>
            <Layers scale={16} />
          </LayersContainer>
          <Label>{option.label}</Label>
          <RangeText>
            {getRangeDisplayValue(option.range.startDate, option.range.endDate)}
          </RangeText>
          <ChevronContainer>
            <ChevronRight scale={16} />
          </ChevronContainer>
        </Option>
      ))}
    </Container>
  )
}

const Container = styled.div`
  padding: 12px 0;
`

const Option = styled.div`
  position: relative;
  height: 32px;
  padding: 8px 16px 8px 43px;
  cursor: pointer;

  :hover {
    background-color: ${p => p.theme.GREY_5};
  }
`

const LayersContainer = styled.div`
  position: absolute;
  left: 16px;

  path {
    fill: ${p => p.theme.ContentPrimary};
  }
`

const Label = styled.div`
  :first-letter {
    text-transform: capitalize;
  }
`

const RangeText = styled.div`
  position: absolute;
  right: 28px;
  top: 10px;
  color: ${p => p.theme.GREY_3};
  font-size: 12px;
`

const ChevronContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;

  path {
    fill: ${p => p.theme.PRIMARY_50};
  }
`
