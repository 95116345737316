import { composerClient } from 'api/composerHttp'
import {
  ContentStoreInsights,
  DataStoreInsights,
  BuildStatus,
  OnlineStoreInsights,
  OrderStoreInsights,
  DeliveryMetrics,
  EventVolume,
  StripeWebhooks,
} from 'types/systemHealth'
import moment from 'moment'
import { hubClient } from 'api/http'

export interface GetStripeWebhooksRequest {
  tenantId: number
  tenantName: string
}

export type SystemHealthClient = {
  getContentStoreInsights: (stackId: number) => Promise<ContentStoreInsights>
  getDataStoreInsights: (stackId: number) => Promise<DataStoreInsights>
  getOnlineStoreInsights: (stackId: number) => Promise<OnlineStoreInsights>
  getOrderStoreInsights: (stackId: number) => Promise<OrderStoreInsights>
  getBuildStatus: (stackId: number) => Promise<BuildStatus>
  getDeliveryMetrics: (
    stackId: number
  ) => Promise<{ deliveryMetrics: DeliveryMetrics }>
  getEventVolume: (stackId: number) => Promise<{ eventVolume: EventVolume }>
  getStripeWebhooks: (
    request: GetStripeWebhooksRequest
  ) => Promise<StripeWebhooks>
}

const getLastMonth = () => ({
  from: moment().add('days', -30).valueOf(),
  to: moment().valueOf(),
  timezone: `-${new Date().getTimezoneOffset() / 60}`,
})

const getLast24Hours = () => {
  const from = moment().add('hours', -24)
  const to = moment()

  return {
    from: `${from.format('YYYY-MM-DD')}T${from.format('HH:mm:ss')}Z`,
    to: `${to.format('YYYY-MM-DD')}T${to.format('HH:mm:ss')}Z`,
  }
}

export const systemHealthClient: SystemHealthClient = {
  // we need to update this on the composer side to accept hubTenantId or omsTenantId/omsStackId
  getContentStoreInsights: stackId =>
    composerClient.get(`stacks/${stackId}/insights/content-store`),
  getDataStoreInsights: stackId =>
    composerClient.get(`stacks/${stackId}/insights/data-store`),
  getOnlineStoreInsights: stackId => {
    const { from, to, timezone } = getLastMonth()
    return composerClient.get(
      `stacks/${stackId}/insights/online-store?from=${from}&to=${to}&timezone=${timezone}`
    )
  },
  getOrderStoreInsights: stackId =>
    composerClient.get(`stacks/${stackId}/insights/order-store`),
  getBuildStatus: stackId =>
    composerClient.get(`stacks/${stackId}/insights/online-store/build-status`),
  getDeliveryMetrics: stackId => {
    const { from, to } = getLast24Hours()
    return composerClient.get(
      `stacks/${stackId}/insights/data-store/delivery-metrics?startTime=${from}&endTime=${to}&granularity=HOUR`
    )
  },
  getEventVolume: stackId => {
    const { from, to } = getLast24Hours()
    return composerClient.get(
      `stacks/${stackId}/insights/data-store/event-volume?startTime=${from}&endTime=${to}&granularity=HOUR&limit=200&groupBy=sourceId`
    )
  },
  getStripeWebhooks: ({ tenantId, tenantName }) => {
    return hubClient
      .get(`tenants/${tenantId}/stripe_webhooks`)
      .then(({ data }) =>
        (data as StripeWebhooks)?.filter(w => w.url.includes(tenantName))
      )
  },
}
