import React from 'react'
import {
  Chip,
  Tooltip,
  TooltipDirection,
  standardIcons,
  useTheme,
} from '@chordco/component-library'

type Props = {
  modeled: boolean
  tooltipWidth?: string
  tooltipDirection?: TooltipDirection
}

export const ModeledChip: React.FC<Props> = ({
  modeled,
  tooltipWidth,
  tooltipDirection,
}) => {
  const theme = useTheme()

  if (!modeled) return null

  return (
    <Tooltip
      text="Modeling data involves refining raw inputs into structured, actionable insights."
      customWidth={tooltipWidth}
      direction={tooltipDirection}
    >
      <Chip
        leftIcon={standardIcons.Metrics}
        leftIconFill={theme.ContentPrimary}
        leftIconSize={12}
        rightIcon={standardIcons.Help}
        rightIconFill={theme.ContentTertiary}
        rightIconSize={12}
        text="Modeled"
        color="primary"
        variant="solid-secondary"
      />
    </Tooltip>
  )
}

