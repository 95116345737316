import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Range: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 10H13"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 10H2"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="9"
        cy="10"
        r="3.75"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="18.75"
        y1="5.75"
        x2="18.75"
        y2="14.25"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="1.75"
        y1="5.75"
        x2="1.75"
        y2="14.25"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 5.75C2.5 5.33579 2.16421 5 1.75 5C1.33579 5 1 5.33579 1 5.75V14.25C1 14.6642 1.33579 15 1.75 15C2.16421 15 2.5 14.6642 2.5 14.25V10.75H4.56222C4.91928 12.8783 6.77025 14.5 9 14.5C11.2297 14.5 13.0807 12.8783 13.4378 10.75H18V14.25C18 14.6642 18.3358 15 18.75 15C19.1642 15 19.5 14.6642 19.5 14.25V5.75C19.5 5.33579 19.1642 5 18.75 5C18.3358 5 18 5.33579 18 5.75V9.25H13.4378C13.0807 7.12171 11.2297 5.5 9 5.5C6.77025 5.5 4.91928 7.12171 4.56222 9.25H2.5V5.75ZM12 10C12 11.6569 10.6569 13 9 13C7.34315 13 6 11.6569 6 10C6 8.34315 7.34315 7 9 7C10.6569 7 12 8.34315 12 10Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
