import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAuthData } from 'redux/state/auth'
import {
  H1,
  H2,
  H3,
  Spinner,
  standardIcons,
  Button,
} from '@chordco/component-library'
import { useProvisioningData } from 'redux/state/provisioning'
import { useIntegrationsByEnvironment } from 'redux/hooks'
import { useIsLoading } from 'redux/utils'
import { useHistory } from 'react-router'
import { Trial } from 'components/home/Trial'
import { OnboardingTasks } from 'components/home/OnboardingTasks'
import { useMediaQuery } from 'react-responsive'

const { OpenLink } = standardIcons
import moment from 'moment'

const TOTAL_TRIAL_DAYS = 30

const getTrialDaysRemaining = (date?: Date) => {
  if (!date) return

  const expirationDate = moment(date)
  const today = moment()

  const daysRemaining = expirationDate.diff(today, 'days')

  return daysRemaining > 0 ? daysRemaining : 0
}

interface Props {
  isMobile: boolean
}

export const Onboarding: React.FC<Props> = ({ isMobile }) => {
  const {
    state: { user },
  } = useAuthData()

  const isSmall = useMediaQuery({ query: '(max-width: 750px)' })

  const history = useHistory()

  const { integrations } = useIntegrationsByEnvironment()
  const composerIntegration = integrations.find(i => i.name === 'Composer')
  const stackId = composerIntegration?.settings?.stackId

  const [displayAllTasks, setDisplayAllTasks] = useState(false)

  const {
    state: { onboardingStatus, trialExpiration },
    getOnboardingStatus,
    getTrialExpiration,
  } = useProvisioningData()

  useEffect(() => {
    if (!onboardingStatus && stackId) getOnboardingStatus(stackId)
  }, [stackId])

  useEffect(() => {
    if (!trialExpiration && stackId) getTrialExpiration(stackId)
  }, [stackId])

  useEffect(() => {
    if (!onboardingStatus) return

    const areAnyStepsComplete =
      onboardingStatus?.hasAcceptedGithubInvitation ||
      onboardingStatus?.hasCommittedCode ||
      onboardingStatus?.hasCompletedOrder ||
      onboardingStatus?.hasAcceptedContentfulInvitation

    setDisplayAllTasks(!areAnyStepsComplete)
  }, [onboardingStatus])

  const isLoading = useIsLoading('getOnboardingStatus')

  const onboardingTasks = [
    {
      title: 'Access your repo',
      isComplete: onboardingStatus?.hasAcceptedGithubInvitation,
      description: (
        <div>
          <Bold>Check your email for a separate invitation from GitHub.</Bold>{' '}
          Your trial comes with access to Chord’s SDK starters for Next.JS
          you’ll find instructions on how to get started in the README of your
          repository.
        </div>
      ),
    },
    {
      title: 'Commit some code',
      isComplete: onboardingStatus?.hasCommittedCode,
      description:
        'Once you have the starter code up and running, commit some code to the repository.',
      button: {
        title: 'Access your repo on Github',
        onClick: () => window.open(onboardingStatus?.githubRepoLink),
      },
    },
    {
      title: 'Access your environment variables',
      description:
        'You will need these environment variables to get your Chord starter repository running locally.',
      button: {
        title: 'Access env vars',
        onClick: () => history.push('/environment-variables'),
      },
    },
    {
      title: 'Complete a trial order',
      isComplete: onboardingStatus?.hasCompletedOrder,
      description: (
        <div>
          Get the front-end up and running and complete checkout with{' '}
          <Link href="https://stripe.com/docs/testing" target="_blank">
            test credentials
          </Link>
          .
        </div>
      ),
    },
    {
      title: "Access your store's content",
      isComplete: onboardingStatus?.hasAcceptedContentfulInvitation,
      description: (
        <div>
          <Bold>Check your email for a separate invitation to Contentful</Bold>,
          the headless CMS Chord uses for product and content management on your
          store.{' '}
          <Link href="https://docs.chord.co/RqXu-cms" target="_blank">
            Learn more about content management on Chord
          </Link>{' '}
          in the docs.{' '}
        </div>
      ),
      button: {
        title: 'Log in to Contentful',
        onClick: () => window.open('https://be.contentful.com/login'),
      },
    },
    {
      title: 'Read the docs',
      description:
        'Learn about how to use Chord through the docs we’ve written for developers and store operators.',
      button: {
        title: 'View the documentation',
        onClick: () => window.open('https://docs.chord.co/'),
      },
    },
    {
      title: 'Get a demo',
      description:
        'Want to understand all of Chord’s features and benefits? Our team would be happy to take you through a quick demo of everything under the hood.',
      button: {
        title: 'Request a demo',
        onClick: () => window.open('https://chord.co/?openDemoModal=true'),
      },
    },
    {
      title: 'Get some help',
      description: (
        <div>
          Reach out to the Customer Experience team at{' '}
          <Link href="mailto:support@chord.co" target="_blank">
            support@chord.co
          </Link>{' '}
          with any questions.
        </div>
      ),
    },
  ]

  const trialDaysRemaining = getTrialDaysRemaining(
    trialExpiration?.expirationDate
  )

  const isTrialExpirationLoading = useIsLoading('getTrialExpiration')

  const Head = isMobile ? H2 : H1

  return (
    <Container>
      {onboardingStatus?.storeUrl && (
        <ButtonContainer>
          <RightButton
            purpose="ghost"
            size="small"
            icon={OpenLink}
            onClick={() =>
              window.open(
                `https://${onboardingStatus?.storeUrl}.chordcommerce.dev`
              )
            }
          >
            Your trial store
          </RightButton>
        </ButtonContainer>
      )}

      <WelcomeContainer isSmall={isSmall}>
        <Welcome isSmall={isSmall}>
          <Head>
            👋 Welcome to Chord{user?.firstName && `, ${user.firstName}`}
          </Head>
          <Message>
            Chord is a Commerce Platform-as-a-Service which brings together
            everything you need to build bespoke, best-in-class e-commerce
            experiences. For an overview of how Chord works, see the{' '}
            <Link href="https://docs.chord.co/" target="_blank" pink>
              Getting Started
            </Link>{' '}
            page in the docs.
          </Message>
        </Welcome>
        <Demo>
          <Trial
            total={TOTAL_TRIAL_DAYS}
            remaining={trialDaysRemaining}
            isLoading={isTrialExpirationLoading}
          />

          <Note>
            Note that this is a trial account with limited functionality. To
            upgrade to a production-ready environment, get in touch at{' '}
            <Link href="mailto:sales@chord.co" target="_blank">
              sales@chord.co
            </Link>
            .
          </Note>
        </Demo>
      </WelcomeContainer>

      <Relative>
        <H3>
          Quick start guide
          {isLoading && (
            <SpinnerContainer>
              <Spinner scale={24} />
            </SpinnerContainer>
          )}
        </H3>

        <ShowAllContainer>
          <Button
            purpose="tertiary"
            size="small"
            onClick={() => setDisplayAllTasks(cur => !cur)}
          >
            {displayAllTasks ? 'Hide' : 'Show'} All
          </Button>
        </ShowAllContainer>

        <OnboardingTasks displayAll={displayAllTasks} tasks={onboardingTasks} />
      </Relative>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 32px;
`

const WelcomeContainer = styled.div<{ isSmall: boolean }>`
  display: ${p => (p.isSmall ? 'block' : 'flex')};
  margin: 32px 0;
`

const Welcome = styled.div<{ isSmall: boolean }>`
  flex: 2;
  margin-right: 16px;
  margin-bottom: ${p => (p.isSmall ? 16 : 0)}px;
`

const Demo = styled.div`
  flex: 1;
`

const Message = styled.div`
  margin-top: 8px;
`

const SpinnerContainer = styled.span`
  position: absolute;
  top: 12px;
  left: 295px;
`

const Link = styled.a<{ pink?: boolean }>`
  color: ${p => (p.pink ? p.theme.ContentAccentPrimary : 'inherit')};
`

const Bold = styled.span`
  font-weight: 600;
`

const Note = styled.div`
  margin-top: 16px;
  font-style: italic;
  color: ${p => p.theme.ContentTertiary};
`

const ShowAllContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`

const Relative = styled.div`
  position: relative;
`

const ButtonContainer = styled.div`
  height: 32px;
`

const RightButton = styled(Button)`
  float: right;
`
