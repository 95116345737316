import React from 'react'
import styled, { css } from 'styled-components'
import { useTheme } from '@chordco/component-library'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  number: number
  theme?: 'pink' | 'white' | 'grey'
  shade?: 'dark' | 'light'
}

export const Badge: React.FC<Props> = ({
  number,
  shade = 'dark',
  theme = 'pink',
  ...props
}) => {
  const chordTheme = useTheme()

  const colors = {
    pink: chordTheme.PRIMARY_50,
    white: chordTheme.OFF_TEXT_BASE,
    grey: chordTheme.GREY_3,
  }

  const color = colors[theme]

  return (
    <Container {...props} shade={shade} color={color}>
      {number > 99 ? '99+' : number}
    </Container>
  )
}

const Container = styled.span<{
  shade: string
  color: string
}>`
  box-sizing: border-box;
  height: 16px;
  line-height: normal;
  width: fit-content;
  min-width: 16px;
  padding: 2px 5px;
  border-radius: 100px;
  font-size: 10px;
  font-weight: 500;
  text-align: center;

  ${p =>
    p.shade === 'dark'
      ? css`
          background-color: ${p.color};
          color: ${p => p.theme.BLACK};
        `
      : css`
          border: 1px solid ${p.color};
          color: ${p => p.theme.WHITE};
        `}
`
