import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Strike: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.89456 4.63955C6.12806 5.1761 5.75 5.84541 5.75 6.5C5.75 7.10547 6.07281 7.60017 6.85189 8.07019C7.65315 8.55359 8.80711 8.92389 10.1843 9.27299C10.5858 9.37478 10.8288 9.78278 10.727 10.1843C10.6252 10.5858 10.2172 10.8288 9.81571 10.727C8.43146 10.3761 7.08543 9.96291 6.07704 9.35456C5.04648 8.73282 4.25 7.82752 4.25 6.5C4.25 5.2216 4.99123 4.1409 6.03437 3.4107C7.07988 2.67885 8.48428 2.25 10 2.25C11.3637 2.25 12.6345 2.59691 13.6355 3.19678C14.6312 3.79348 15.4078 4.67357 15.6627 5.75224C15.7579 6.15536 15.5083 6.55935 15.1052 6.65459C14.7021 6.74982 14.2981 6.50024 14.2029 6.09712C14.0657 5.51651 13.6167 4.93427 12.8644 4.48344C12.1174 4.03579 11.1178 3.75 10 3.75C8.7543 3.75 7.6587 4.10465 6.89456 4.63955Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 12.5C15.4142 12.5 15.75 12.8358 15.75 13.25C15.75 13.5744 15.7064 13.8911 15.6239 14.1959C15.0419 16.3462 12.6262 17.75 10 17.75C7.37384 17.75 4.95805 16.3462 4.37607 14.1959C4.26785 13.7961 4.50425 13.3843 4.90408 13.276C5.30391 13.1678 5.71576 13.4042 5.82397 13.8041C6.16853 15.0771 7.78825 16.25 10 16.25C12.2118 16.25 13.8315 15.0771 14.176 13.8041C14.2247 13.6243 14.25 13.4393 14.25 13.25C14.25 12.8358 14.5858 12.5 15 12.5Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 10C2.25 9.58579 2.58579 9.25 3 9.25H17C17.4142 9.25 17.75 9.58579 17.75 10C17.75 10.4142 17.4142 10.75 17 10.75H3C2.58579 10.75 2.25 10.4142 2.25 10Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
