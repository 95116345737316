import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const ListViewIcon: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 4C1.25 3.0335 2.0335 2.25 3 2.25H17C17.9665 2.25 18.75 3.0335 18.75 4V7V11V14C18.75 14.9665 17.9665 15.75 17 15.75H3C2.0335 15.75 1.25 14.9665 1.25 14V11V7V4ZM2.75 10.25H17.25V7.75H2.75V10.25ZM2.75 11.75V14C2.75 14.1381 2.86193 14.25 3 14.25H17C17.1381 14.25 17.25 14.1381 17.25 14V11.75H2.75ZM17.25 4V6.25H2.75V4C2.75 3.86193 2.86193 3.75 3 3.75H17C17.1381 3.75 17.25 3.86193 17.25 4Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
