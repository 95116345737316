import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const AddUser: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.49991 5.83333C4.49991 4.4066 5.65651 3.25 7.08324 3.25C8.50998 3.25 9.66658 4.4066 9.66658 5.83333C9.66658 7.26007 8.50998 8.41667 7.08324 8.41667C5.65651 8.41667 4.49991 7.26007 4.49991 5.83333ZM7.08324 1.75C4.82808 1.75 2.99991 3.57817 2.99991 5.83333C2.99991 8.0885 4.82808 9.91667 7.08324 9.91667C9.33841 9.91667 11.1666 8.0885 11.1666 5.83333C11.1666 3.57817 9.33841 1.75 7.08324 1.75ZM4.16683 11.75C3.08386 11.75 2.04525 12.1802 1.27948 12.946C0.513703 13.7118 0.0834961 14.7504 0.0834961 15.8333V17.5C0.0834961 17.9142 0.419282 18.25 0.833496 18.25C1.24771 18.25 1.5835 17.9142 1.5835 17.5V15.8333C1.5835 15.1482 1.85567 14.4911 2.34014 14.0066C2.82461 13.5222 3.48169 13.25 4.16683 13.25H10.0002C10.6853 13.25 11.3424 13.5222 11.8269 14.0066C12.3113 14.4911 12.5835 15.1482 12.5835 15.8333V17.5C12.5835 17.9142 12.9193 18.25 13.3335 18.25C13.7477 18.25 14.0835 17.9142 14.0835 17.5V15.8333C14.0835 14.7504 13.6533 13.7118 12.8875 12.946C12.1217 12.1802 11.0831 11.75 10.0002 11.75H4.16683ZM16.6665 5.91668C17.0807 5.91668 17.4165 6.25247 17.4165 6.66668V8.41669H19.1665C19.5807 8.41669 19.9165 8.75247 19.9165 9.16668C19.9165 9.5809 19.5807 9.91668 19.1665 9.91668H17.4165V11.6667C17.4165 12.0809 17.0807 12.4167 16.6665 12.4167C16.2523 12.4167 15.9165 12.0809 15.9165 11.6667V9.91668H14.1665C13.7523 9.91668 13.4165 9.5809 13.4165 9.16668C13.4165 8.75247 13.7523 8.41669 14.1665 8.41669H15.9165V6.66668C15.9165 6.25247 16.2523 5.91668 16.6665 5.91668Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
