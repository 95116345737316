import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../store'
import { BaseState } from 'types'

export * from './defaultMatchers'
export * from './thunkHelpers'
export * from './messageHelpers'

export type Method = keyof RootState['methods']

export const useMethodStatus = (method: Method) =>
  useSelector((state: RootState) => state.methods[method])?.status

const useHasStatus = (status: BaseState['status'], ...methods: Method[]) => {
  const methodsState = useSelector((state: RootState) => state.methods)
  return methods.some(method => methodsState[method]?.status === status)
}

export const useIsIdle = (...methods: Method[]) =>
  useHasStatus('idle', ...methods)

export const useIsLoading = (...methods: Method[]) =>
  useHasStatus('loading', ...methods)

export const useHasFailed = (...methods: Method[]) =>
  useHasStatus('failed', ...methods)

export const useEverySucceeded = (...methods: Method[]) => {
  const methodsState = useSelector((state: RootState) => state.methods)
  return methods.every(method => methodsState[method]?.status === 'succeeded')
}

export const useSomeSucceeded = (...methods: Method[]) => {
  const methodsState = useSelector((state: RootState) => state.methods)
  return methods.some(method => methodsState[method]?.status === 'succeeded')
}

export const useStateSliceAndDispatch = <Key extends keyof RootState>(
  sliceName: Key
) => {
  const dispatch = useDispatch<AppDispatch>()
  const state: RootState[Key] = useSelector<RootState, RootState[Key]>(
    state => state[sliceName]
  )

  return { dispatch, state }
}
