import { useHistory, useLocation } from 'react-router'
import { useEffect, useRef } from 'react'

export * from './form'
export * from './mobile'
export * from './shortcuts'
export * from './useExpandCollapseAnimation'
export * from './useExperiments'
export * from './useAnalyticsTabsHelper'
export * from './useHeightAndWidthHelpers'

export const useHistoryPush = () => {
  const location = useLocation()
  const history = useHistory()
  const path = location.pathname.replace(/\/$/, '')

  return id => () => history.push(`${path}/${id}`)
}

export const usePrevious: <T>(value: T) => T | undefined = value => {
  const ref = useRef() as any
  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

export const useInterval = (callback: Function, delay: number) => {
  useEffect(() => {
    const id = setInterval(callback, delay)
    return () => clearInterval(id)
  }, [callback, delay])
}
