import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Bold: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 3.33334C4.25 2.91912 4.58579 2.58334 5 2.58334H11.6667C12.7496 2.58334 13.7882 3.01354 14.554 3.77932C15.3198 4.54509 15.75 5.5837 15.75 6.66667C15.75 7.74964 15.3198 8.78825 14.554 9.55402C13.7882 10.3198 12.7496 10.75 11.6667 10.75H5C4.58579 10.75 4.25 10.4142 4.25 10V3.33334ZM5.75 4.08334V9.25H11.6667C12.3518 9.25 13.0089 8.97783 13.4934 8.49336C13.9778 8.00889 14.25 7.35181 14.25 6.66667C14.25 5.98153 13.9778 5.32445 13.4934 4.83998C13.0089 4.35551 12.3518 4.08334 11.6667 4.08334H5.75Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 10C4.25 9.58579 4.58579 9.25 5 9.25H12.5C13.583 9.25 14.6216 9.68021 15.3874 10.446C16.1531 11.2118 16.5833 12.2504 16.5833 13.3333C16.5833 14.4163 16.1531 15.4549 15.3874 16.2207C14.6216 16.9865 13.583 17.4167 12.5 17.4167H5C4.58579 17.4167 4.25 17.0809 4.25 16.6667V10ZM5.75 10.75V15.9167H12.5C13.1851 15.9167 13.8422 15.6445 14.3267 15.16C14.8112 14.6756 15.0833 14.0185 15.0833 13.3333C15.0833 12.6482 14.8112 11.9911 14.3267 11.5066C13.8422 11.0222 13.1851 10.75 12.5 10.75H5.75Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
