import React from 'react'
import styled from 'styled-components'
import { SimpleBar } from '@chordco/component-library'
import { Col, TableHeader } from 'components/common/table'
import { CensusDestination } from 'api/census/interfaces'
import { DestinationRow } from './DestinationRow'
import { Paginator } from './Paginator'

type Props = {
  destinations: CensusDestination[]
  onSelectDestination: (connector: CensusDestination) => void
  onPrevPage: () => void
  onNextPage: () => void
  showNextPageButton: boolean
  showPreviousPageButton: boolean
}

export const ConnectedDestinations: React.FC<Props> = ({
  destinations,
  onSelectDestination,
  onPrevPage,
  onNextPage,
  showNextPageButton,
  showPreviousPageButton,
}) => {
  const paginatorProps = {
    onPrevPage: onPrevPage,
    onNextPage: onNextPage,
    showNextPage: showNextPageButton,
    showPrevPage: showPreviousPageButton,
  }

  return (
    <>
      <TableWrapper>
        <StyledTableHeader>
          <Col flex={4}>Destination</Col>
          <Col>Created At</Col>
          <Col>Status</Col>
          <Col>Last Tested At</Col>
        </StyledTableHeader>

        <TableContent>
          {destinations
            .filter(d => d.type !== 'dynamo_entity_cache')
            .map(d => (
              <DestinationRow
                key={d.id}
                destination={d}
                onSelectDestination={onSelectDestination}
              />
            ))}
        </TableContent>
      </TableWrapper>

      <Paginator {...paginatorProps} />
    </>
  )
}

const TableWrapper = styled.div`
  border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
  border-radius: ${p => p.theme.CardMediumRadius};
`

const StyledTableHeader = styled(TableHeader)`
  border-top-right-radius: ${p => p.theme.CardMediumRadius};
  border-top-left-radius: ${p => p.theme.CardMediumRadius};
  overflow: hidden;
`

const TableContent = styled(SimpleBar)`
  border-bottom-right-radius: ${p => p.theme.CardMediumRadius};
  border-bottom-left-radius: ${p => p.theme.CardMediumRadius};
`
