import { hubClient } from '../http'
import { User } from 'types'

export interface UserClient {
  getUsers: (tenantId: number) => Promise<User[]>
  updateUser: (data: User) => Promise<void>
  deleteUser: (id: number) => Promise<void>
}

export const userClient: UserClient = {
  getUsers: tenantId => hubClient.get(`tenants/${tenantId}/users`),
  updateUser: ({ id, ...user }) => hubClient.put(`users/${id}`, { user }),
  deleteUser: id => hubClient.delete(`users/${id}`),
}
