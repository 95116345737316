import React, { ReactNode, useMemo } from 'react'
import { NotionContext } from 'hooks/useNotion'
import {
  createDataSourceRequest,
  createFeatureAccessRequest,
  fetchFeatureAccessRequests,
} from 'utils/notion/service'

interface NotionProviderProps {
  children: ReactNode
}

export const NotionProvider: React.FC<NotionProviderProps> = ({ children }) => {
  const contextValue = useMemo(() => {
    return {
      createDataSourceRequest,
      createFeatureAccessRequest,
      fetchFeatureAccessRequests,
    }
  }, [])

  return (
    <NotionContext.Provider value={contextValue}>
      {children}
    </NotionContext.Provider>
  )
}
