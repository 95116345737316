import React, { useMemo } from 'react'
import { useTheme as useChordTheme } from '@chordco/component-library'

import {
  ThemeProvider,
  createTheme,
  useTheme as useMuiTheme,
} from '@mui/material'

type MuiThemeProviderProps = {
  children: React.ReactNode
}

const MuiThemeProvider: React.FC<MuiThemeProviderProps> = ({ children }) => {
  const chordTheme = useChordTheme()
  const muiTheme = useMuiTheme()

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'light',
          primary: {
            main: chordTheme.PRIMARY_60,
          },
          info: {
            main: chordTheme.PRIMARY_60, // modal buttons icon color
          },
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                disableRipple: true,
              },
            },
          },
          MuiDialog: {
            styleOverrides: {
              paper: {
                borderRadius: '16px',
                backgroundColor: chordTheme.WHITE,
              },
            },
          },
          MuiDialogTitle: {
            styleOverrides: {
              root: {
                padding: '20px 20px 16px 20px',
              },
            },
          },
          MuiDialogActions: {
            styleOverrides: {
              root: {
                padding: '16px',
              },
            },
          },
          MuiInputLabel: {
            styleOverrides: {
              asterisk: ({ theme }) => ({
                visibility: 'hidden',
                '&::before': {
                  content: '"* (required)"',
                  visibility: 'visible',
                  color: theme.palette.primary.main,
                  textTransform: 'lowercase',
                },
              }),
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: ({ theme }) => ({
                borderRadius: '8px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.primary.main,
                  borderWidth: 1,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: chordTheme.BorderStrong,
                  borderWidth: 1,
                },
              }),
            },
          },
          MuiSelect: {
            styleOverrides: {
              select: {
                borderRadius: '8px',
              },
            },
          },
        },
      }),
    [muiTheme, chordTheme]
  )

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default MuiThemeProvider
