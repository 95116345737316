import { BusinessError, BusinessErrorEvent } from 'types/businessError'
import { hubClient } from 'api/http'

type EventsArg = {
  tenantId: number
  issueId: number
}

type ErrorsArg = {
  tenantId: number
  environment: string
}

export interface BusinessErrorClient {
  getBusinessErrors: (data: ErrorsArg) => Promise<BusinessError[]>
  getBusinessErrorEvents: (data: EventsArg) => Promise<BusinessErrorEvent[]>
}

export const businessErrorClient: BusinessErrorClient = {
  getBusinessErrors: ({ tenantId, environment }) =>
    hubClient.get(
      `tenants/${tenantId}/business_errors?project_env=${environment}`
    ),

  getBusinessErrorEvents: ({ tenantId, issueId }) =>
    hubClient.get(`tenants/${tenantId}/business_errors/${issueId}`),
}
