import React, { Dispatch, SetStateAction, useState } from 'react'
import {
  DayPickerRangeController,
  DayPickerSingleDateController,
} from 'react-dates'
import styled from 'styled-components'

import { commonProps } from './commonProps'
import moment from 'moment'
import { BaseContainer } from './common'

type DateRangeProps = {
  type?: 'range'
  startDate?: moment.Moment
  endDate?: moment.Moment
  setStartDate: Dispatch<SetStateAction<moment.Moment | undefined>>
  setEndDate: Dispatch<SetStateAction<moment.Moment | undefined>>
  date?: moment.Moment
  onChange?: (date: moment.Moment) => void
  isDayBlocked?: (date: moment.Moment) => boolean
}

type SingleDateProps = {
  type?: 'single_date'
  startDate?: moment.Moment
  endDate?: moment.Moment
  setStartDate?: Dispatch<SetStateAction<moment.Moment | undefined>>
  setEndDate?: Dispatch<SetStateAction<moment.Moment | undefined>>
  date?: moment.Moment
  onChange: (date: moment.Moment) => void
  isDayBlocked?: (date: moment.Moment) => boolean
}

type Props = DateRangeProps | SingleDateProps

const START_DATE = 'startDate'
const END_DATE = 'endDate'

export const Calendar: React.FC<Props> = ({
  setEndDate,
  setStartDate,
  endDate,
  startDate,
  date,
  onChange,
  type = 'range',
  ...props
}) => {
  const [focusedRangeInput, setFocusedInput] = useState(START_DATE)

  const onDatesChange = ({ startDate, endDate }) => {
    setStartDate && setStartDate(startDate)
    setEndDate && setEndDate(endDate)
  }

  const onFocusRangeChange = focusedInput => {
    setFocusedInput(!focusedInput ? START_DATE : END_DATE)
  }

  return (
    <CalendarContainer>
      {type === 'range' ? (
        <DayPickerRangeController
          startDate={startDate || null}
          endDate={endDate || null}
          onDatesChange={onDatesChange}
          focusedInput={focusedRangeInput}
          onFocusChange={onFocusRangeChange}
          initialVisibleMonth={null}
          noBorder
          {...commonProps}
          {...props}
        />
      ) : (
        <DayPickerSingleDateController
          date={date}
          onDateChange={onChange}
          numberOfMonths={1}
          focused
          initialVisibleMonth={date ? () => date : undefined}
          {...commonProps}
          {...props}
        />
      )}
    </CalendarContainer>
  )
}

const CalendarContainer = styled(BaseContainer as any)`
  display: flex;
  justify-content: center;
  background-color: tranparent;
  color: ${p => p.theme.ContentPrimary};

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span {
    background-color: ${p => p.theme.BgOverlayPrimaryAccentelEvation1};
    color: ${p => p.theme.ContentPrimary};
    border-radius: ${p => p.theme.ButtonRadius};

    :hover {
      background-color: ${p => p.theme.BgOverlayPrimaryAccentelEvation2};
      color: ${p => p.theme.ContentPrimary};
    }

    :after {
      background-color: ${({ theme }) => theme.WHITE};
    }
  }
`
