import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const AlertTriangle: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.81453 1.9752C9.17646 1.77143 9.58479 1.66438 10.0001 1.66438C10.4155 1.66438 10.8238 1.77143 11.1858 1.9752C11.5477 2.17897 11.851 2.47258 12.0664 2.8277L12.0686 2.83126L19.1331 14.6249C19.3441 14.9903 19.4556 15.4047 19.4568 15.8267C19.458 16.2486 19.3487 16.6636 19.1397 17.0302C18.9307 17.3968 18.6294 17.7023 18.2657 17.9163C17.9021 18.1303 17.4887 18.2453 17.0667 18.25L17.0585 18.25L2.93356 18.25C2.51161 18.2454 2.09823 18.1303 1.73454 17.9163C1.37086 17.7023 1.06954 17.3968 0.860579 17.0302C0.651615 16.6636 0.542285 16.2486 0.543467 15.8267C0.544648 15.4047 0.6563 14.9904 0.867313 14.625L0.873323 14.6145L7.93174 2.83127L8.57514 3.21667L7.93389 2.8277C8.1493 2.47258 8.4526 2.17897 8.81453 1.9752ZM9.21731 3.60413L9.21639 3.60564L2.16374 15.3795C2.08536 15.517 2.0439 15.6725 2.04346 15.8309C2.04301 15.9909 2.08448 16.1483 2.16374 16.2874C2.24301 16.4264 2.3573 16.5423 2.49525 16.6235C2.6322 16.7041 2.78773 16.7477 2.94657 16.75H17.0537C17.2125 16.7477 17.3681 16.7041 17.505 16.6235C17.643 16.5423 17.7573 16.4264 17.8365 16.2874C17.9158 16.1483 17.9573 15.9909 17.9568 15.8309C17.9564 15.6725 17.9149 15.517 17.8365 15.3795L10.7839 3.60564L10.783 3.60413C10.7013 3.47011 10.5866 3.35928 10.4499 3.28228C10.3126 3.20499 10.1577 3.16438 10.0001 3.16438C9.8426 3.16438 9.68771 3.20499 9.55043 3.28228C9.41366 3.35928 9.29896 3.47011 9.21731 3.60413Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6.75C10.4142 6.75 10.75 7.08579 10.75 7.5V10.8333C10.75 11.2475 10.4142 11.5833 10 11.5833C9.58579 11.5833 9.25 11.2475 9.25 10.8333V7.5C9.25 7.08579 9.58579 6.75 10 6.75Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25 14.1667C9.25 13.7525 9.58579 13.4167 10 13.4167H10.0083C10.4225 13.4167 10.7583 13.7525 10.7583 14.1667C10.7583 14.5809 10.4225 14.9167 10.0083 14.9167H10C9.58579 14.9167 9.25 14.5809 9.25 14.1667Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
