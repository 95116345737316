import React, { useCallback, useEffect, useState } from 'react'
import { Chip, Spinner } from '@chordco/component-library'
import { useCensus } from '../CensusContext'
import { CensusDestination } from 'api/census/interfaces'

type DestinationChipProps = {
  destinationId: number
}

export const DestinationChip: React.FC<DestinationChipProps> = ({
  destinationId,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [destination, setDestination] = useState<CensusDestination | undefined>(
    undefined
  )
  const { censusClient } = useCensus()

  const fetchDestination = useCallback(async () => {
    setIsLoading(true)

    const response = await censusClient?.getDestination(destinationId)

    if (response) {
      const { data } = response
      setDestination(data)
    }
    setIsLoading(false)
  }, [censusClient, destinationId])

  useEffect(() => {
    fetchDestination()
  }, [])

  return isLoading ? (
    <Spinner scale={30} />
  ) : (
    <Chip text={destination?.name} color="info" variant="solid-secondary" />
  )
}
