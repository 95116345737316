import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import { OptionProps } from '../dropdown'
import { Tag } from '../information'
import { useTheme } from '@chordco/component-library'
import { Chip, ChipColor } from '../controls'

export interface SelectableInputProps {
  options: OptionProps[]
  selectedIds: (string | number)[]
  setSelectedIds: Function
  query: string
  setQuery: (query: string) => void
  startOpen?: boolean
  chipColor?: ChipColor
  useTags?: boolean
  placeholder?: string
}

export const SelectableInput: React.FC<SelectableInputProps> = ({
  options,
  selectedIds,
  setSelectedIds,
  query,
  setQuery,
  startOpen,
  chipColor,
  useTags,
  placeholder,
  ...props
}) => {
  const theme = useTheme()

  const inputRef = useRef() as any
  useLayoutEffect(() => {
    startOpen ? inputRef.current?.focus() : null
  }, [startOpen])

  const selectedOptions: OptionProps[] = options.filter((option: OptionProps) =>
    selectedIds.includes(option.id)
  )

  return (
    <Container {...props}>
      <Chips>
        {selectedOptions.map(
          ({ id, label, color, leftIcon, avatarSrc, featureIcon }) =>
            useTags ? (
              <Tag
                key={id}
                text={label}
                color={color || theme.PRIMARY_50}
                onClose={() =>
                  setSelectedIds(selectedIds.filter(cur => cur !== id))
                }
              />
            ) : (
              <Chip
                key={id}
                text={label}
                leftIcon={leftIcon || featureIcon?.icon}
                avatarSrc={avatarSrc}
                color={chipColor}
                onClose={() =>
                  setSelectedIds(selectedIds.filter(cur => cur !== id))
                }
              />
            )
        )}
        <Input
          ref={inputRef}
          value={query}
          onChange={(e: any) => setQuery(e.target.value)}
          width={selectedOptions.length === 0 ? '100%' : 'unset'}
          onKeyDown={e => e.stopPropagation()}
          placeholder={selectedIds.length ? '' : placeholder}
        />
      </Chips>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  min-height: 40px;
  width: 100%;
  background-color: ${p => p.theme.ComponentFieldBackgroundDefault};
  border: solid 1px ${p => p.theme.ComponentFieldBorderDefault};
  color: ${p => p.theme.ContentPrimary};
  border-radius: 8px;
  padding: 4px 6px;
  transition: 100ms;

  :hover {
    border-color: ${p => p.theme.ComponentFieldBorderHover};
  }

  :focus-within {
    border: solid 1px ${p => p.theme.PRIMARY_50};
  }
`

const Chips = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  button:not(:last-child),
  > div {
    margin-right: 8px;
  }
`

const Input = styled.input`
  float: left;
  border: none;
  box-sizing: border-box;
  height: 30px;
  background: inherit;
  border-radius: 8px;
  padding: 0;
  width: 24px;
  flex: 1 0 24px;

  ::placeholder {
    color: ${p => p.theme.ContentTertiary};
  }

  :focus {
    outline: none;
  }
`
