import { createSlice } from '@reduxjs/toolkit'
import { useStateSliceAndDispatch } from '../utils'
import { ConfigSheetState, CreateSheetRequest, UpdateSheetRequest } from 'types'
import { useTenantsData } from 'redux/state/tenants'
import { reduxApiClient } from 'redux/api'

const SLICE_NAME = 'configSheets'

const initialState: ConfigSheetState = {
  activeSheets: [],
}

const { getSheets, createSheet, updateSheet } = reduxApiClient

export const configSheetsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: { resetConfigSheets: () => initialState },
  extraReducers: builder => {
    builder.addCase(getSheets.fulfilled, (state, action) => {
      state.activeSheets = action.payload.data
    })
  },
})

export default configSheetsSlice.reducer

export const useConfigSheetsData = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)

  const {
    state: { currentTenant, currentStore },
  } = useTenantsData()

  const tenantId = currentTenant?.id
  const storeId = currentStore?.id

  if (!tenantId || !storeId) {
    return { state }
  }

  return {
    state,
    getSheets: () => dispatch(getSheets({ tenantId, storeId })),
    createSheet: (data: CreateSheetRequest) =>
      dispatch(createSheet({ tenantId, storeId, ...data })).then(() => {
        dispatch(getSheets({ tenantId, storeId }))
      }),
    updateSheet: (data: UpdateSheetRequest) =>
      dispatch(updateSheet({ tenantId, storeId, ...data })).then(() => {
        dispatch(getSheets({ tenantId, storeId }))
      }),
  }
}
