import React from 'react'
import { useIsMobile, useKeyboardShortcuts } from 'hooks'
import { MobileNavigation } from './MobileNavigation'
import { DesktopNavigation } from './DesktopNavigation'
import { User } from 'types'
import { useTenantsData } from 'redux/state/tenants'

interface Props {
  children?: React.ReactNode
  navOpen: boolean
  setNavOpen: Function
  onHide: {
    (value: ((prevState: boolean) => boolean) | boolean): void
    (value: ((prevState: undefined) => undefined) | undefined): void
  }
  user: User
}

export const Navigation: React.FC<Props> = ({
  navOpen,
  setNavOpen,
  onHide,
  user,
}) => {
  const defaultShortcuts = [
    {
      keys: ['Control', '/'],
      callback: onHide,
    },
  ]

  useKeyboardShortcuts(defaultShortcuts)

  const isMobile = useIsMobile()

  const {
    state: { tenants, currentTenant },
    selectTenant,
  } = useTenantsData()

  return isMobile ? (
    <MobileNavigation
      user={user}
      navOpen={navOpen}
      setNavOpen={setNavOpen}
      tenants={tenants}
      currentTenant={currentTenant}
      selectTenant={selectTenant}
    />
  ) : (
    <DesktopNavigation
      user={user}
      navOpen={navOpen}
      tenants={tenants}
      currentTenant={currentTenant}
      selectTenant={selectTenant}
    />
  )
}
