import { hubClient } from '../http'
import { UserNotification, UpdateUserNotificationPayload } from 'types'

type StoreIdentifier = {
  tenantId: number
  storeId: number
}

export interface UserNotificationsClient {
  getUserNotifications: ({
    tenantId,
    storeId,
  }: StoreIdentifier) => Promise<UserNotification[]>
  updateUserNotification: ({
    tenantId,
    storeId,
    notificationId,
    payload,
  }: StoreIdentifier & {
    notificationId: number
    payload: UpdateUserNotificationPayload
  }) => Promise<UserNotification>
}

export const userNotificationsClient: UserNotificationsClient = {
  getUserNotifications: ({ tenantId, storeId }) =>
    hubClient.get(`tenants/${tenantId}/stores/${storeId}/user_notifications`),
  updateUserNotification: ({ tenantId, storeId, notificationId, payload }) =>
    hubClient.patch(
      `tenants/${tenantId}/stores/${storeId}/user_notifications/${notificationId}`,
      payload
    ),
}
