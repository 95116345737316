import React from 'react'
import magicLogo from 'assets/MagicLogo.png'
import { useForm } from 'hooks'
import { FormInput, FormSecureInput } from '../../../../common'
import { ValSchemaGeneric } from 'validationSchemas'
import { Fields } from '../common'
import { Integration, MagicSettings } from 'types'
import { useIntegrationForm } from 'redux/hooks'
import { MultipleProviderForm } from '../MultipleProviderForm'
import { getAuthenticationConfirmationMessage } from 'utils/confirmationMessages'

type Props = {
  onChangeProvider: () => void
  onClose: () => void
  integration?: Integration<MagicSettings>
}

const MAGIC_LOGIN_LINK = 'https://dashboard.magic.link/login'

const getSchema = (
  settings?: MagicSettings
): ValSchemaGeneric<['apiKey', 'pkKey']> => ({
  apiKey: {
    label: 'API Key',
    type: 'string',
    initialValue: settings?.apiKey,
  },
  pkKey: {
    label: 'Public Key',
    type: 'string',
    initialValue: settings?.pkKey,
  },
})

export const MagicForm: React.FC<Props> = ({
  onChangeProvider,
  onClose,
  integration,
}) => {
  const { submit, isLoading } = useIntegrationForm(
    'magic',
    integration,
    onClose
  )

  const schema = getSchema(integration?.settings)
  const { fields, onSubmit } = useForm(schema, submit)

  const integrationTitle = 'Magic'
  const confirmationMessage = getAuthenticationConfirmationMessage(integrationTitle)

  return (
    <MultipleProviderForm
      integrationType={'magic'}
      integrationTitle={integrationTitle}
      logo={magicLogo}
      loginLink={MAGIC_LOGIN_LINK}
      confirmationMessage={confirmationMessage}
      onChangeProvider={onChangeProvider}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <Fields>
        <FormSecureInput field={fields.apiKey} customWidth="100%" />
        <FormInput field={fields.pkKey} customWidth="100%" />
      </Fields>
    </MultipleProviderForm>
  )
}
