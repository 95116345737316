import { Banner } from './Banner'
import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'
import { Notification } from 'types'

interface Props {
  notifications: Notification[]
  onClear: Function
  onUndo: MouseEventHandler
}

export const BannerContainer: React.FC<Props> = ({
  notifications,
  onClear,
  onUndo,
}) => {
  return (
    <Container>
      {notifications.map(({ id, message, undoAction, type }) => (
        <Banner type={type} key={id} onClose={() => onClear(id)}>
          {message + ' '}
          {undoAction && <Undo onClick={onUndo}>Undo</Undo>}
        </Banner>
      ))}
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
`

const Undo = styled.span`
  text-decoration: underline;
  cursor: pointer;
`
