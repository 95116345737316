import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Template: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2.75C3.30964 2.75 2.75 3.30964 2.75 4V16C2.75 16.6904 3.30964 17.25 4 17.25H6.25V2.75H4ZM7.75 2.75V7.25H17.25V4C17.25 3.30964 16.6904 2.75 16 2.75H7.75ZM17.25 8.75H7.75V17.25H16C16.6904 17.25 17.25 16.6904 17.25 16V8.75ZM1.25 4C1.25 2.48122 2.48122 1.25 4 1.25H16C17.5188 1.25 18.75 2.48122 18.75 4V16C18.75 17.5188 17.5188 18.75 16 18.75H4C2.48122 18.75 1.25 17.5188 1.25 16V4Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
