import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Loading, standardIcons } from '@chordco/component-library'
import { Circle } from 'components/common'
import { useAuthData } from 'redux/state/auth'
import { Plus } from '@chordco/component-library/components/icons/standardIcons/Plus'
import { CreateConfigForm } from 'components/provisioning/CreateConfigForm'
import { useProvisioningData } from 'redux/state/provisioning'
import { useIsLoading } from 'redux/utils'

const { Plug } = standardIcons

export default function EmptySheets() {
  const { state } = useAuthData()

  const isCurrentUserSuperUser = state.user?.role === 'superuser'

  const [showConfigUiForm, setShowConfigUiForm] = useState(false)

  const toggleConfigUiForm = () => setShowConfigUiForm(!showConfigUiForm)

  const {
    state: { configSheets },
    getConfigSheets,
  } = useProvisioningData()

  const isLoadingConfigSheets = useIsLoading('getConfigSheets')

  useEffect(() => {
    if (!isCurrentUserSuperUser) return

    if (!configSheets.length && !isLoadingConfigSheets) {
      getConfigSheets()
    }
  }, [isCurrentUserSuperUser, configSheets, getConfigSheets])

  return (
    <Container>
      <BannerContainer>
        <Text>
          We are working hard to set up your model amplifications! Check back
          soon!
        </Text>

        {isCurrentUserSuperUser && (
          <>
            {isLoadingConfigSheets && <Loading />}
            {!isLoadingConfigSheets && (
              <Button
                icon={Plus}
                variant="tertiary"
                onClick={toggleConfigUiForm}
              >
                Configure
              </Button>
            )}

            {showConfigUiForm && configSheets && (
              <CreateConfigForm
                onClose={toggleConfigUiForm}
                configSheets={configSheets}
              />
            )}
          </>
        )}
      </BannerContainer>

      <IconContainer>
        <Circle innerCircleScale={286} outerCircleScale={414}>
          <Plug />
        </Circle>
      </IconContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BannerContainer = styled.div`
  width: 100%;
  background: ${p => p.theme.BgAccentLightAADarkInfo};
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Text = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${p => p.theme.BLACK};
`

const IconContainer = styled.div`
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
`
