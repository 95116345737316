import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const CustomersToBe: React.FC<IconProps> = ({
  scale = 48,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.25 34.6409V28.6409H29.75V34.6409H28.25Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M32.25 28.6409V34.6409H33.75V28.6409H32.25Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.75 19.6409V15.3909H23.9998V13.8909H20.75V12.6409C20.75 10.8459 22.2051 9.39087 24 9.39087C25.7949 9.39087 27.25 10.8459 27.25 12.6409V19.6409H28.75V15.3909H30.3469C32.0279 15.3909 33.4314 16.6726 33.5836 18.3466L34.1463 24.5363C33.183 24.1211 32.1178 23.8909 31 23.8909C26.7448 23.8909 23.25 27.228 23.25 31.3995C23.25 35.5041 26.6787 39.3909 31 39.3909C35.3213 39.3909 38.75 35.5041 38.75 31.3995C38.75 28.971 37.5655 26.8252 35.7361 25.4561L35.0774 18.2108C34.855 15.7642 32.8037 13.8909 30.3469 13.8909H28.75V12.6409C28.75 10.0175 26.6234 7.89087 24 7.89087C21.3766 7.89087 19.25 10.0175 19.25 12.6409V13.8909H17.637C15.1881 13.8909 13.1405 15.7527 12.9084 18.1905L11.6703 31.1905C11.4047 33.9792 13.5976 36.3909 16.3989 36.3909H19.9998V34.8909H16.3989C14.4822 34.8909 12.9818 33.2408 13.1635 31.3327L14.4016 18.3327C14.5605 16.6647 15.9614 15.3909 17.637 15.3909H19.25V19.6409H20.75ZM24.75 31.3995C24.75 28.1056 27.5232 25.3909 31 25.3909C34.4768 25.3909 37.25 28.1056 37.25 31.3995C37.25 34.7603 34.4107 37.8909 31 37.8909C27.5893 37.8909 24.75 34.7603 24.75 31.3995Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
