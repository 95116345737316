import styled from 'styled-components'

export const StyledPill = styled.button<{ tabbed?: boolean }>`
  display: flex;
  box-sizing: border-box;
  height: 32px;
  border: none;
  background-color: inherit;
  color: ${p => p.theme.OFF_TEXT_BASE};
  border-radius: 15px;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  outline: 0;
  transition: 100ms;

  path {
    fill: ${p => p.theme.OFF_TEXT_BASE};
  }

  :active {
    transform: scale(0.98);
  }

  :focus {
    box-shadow: ${({ tabbed, theme }) => (tabbed ? theme.FOCUS : 'none')};
  }
`

export const WhitePill = styled(StyledPill)`
  border: solid 1px ${p => p.theme.GREY_40};
  padding: 7px 11px;
`

export const GreyPill = styled(StyledPill)`
  background-color: ${p => p.theme.GREY_5};
  padding: 8px 12px;
`

export const PinkPill = styled(StyledPill)`
  border: solid 1px ${p => p.theme.PRIMARY_50};
  padding: 7px 11px;
`

export const PinkFillPill = styled(StyledPill)`
  background-color: ${p => p.theme.PRIMARY_50};
  padding: 8px 12px;
`

export const PillText = styled.div`
  margin-top: 1px;
`

export const LeftContainer = styled.div`
  margin-right: 4px;
`

export const AvatarContainer = styled.div`
  margin: -4px 4px 0px -7px;
`

export const RightContainer = styled.div`
  margin-left: 4px;
`

export const BadgeContainer = styled.div`
  margin: -1px 0 0 4px;
`
