import React from 'react'
import styled from 'styled-components'
import { Overlay } from './Overlay'

interface Props {
  children: React.ReactNode
  onClose: Function
  absolutePosition?: {
    top?: string
    right?: string
    bottom?: string
    left?: string
  }
  width?: string
  padding?: string
}

export const FloatingModal: React.FC<Props> = ({
  absolutePosition = {},
  onClose,
  children,
  width,
  padding,
}) => {
  return (
    <Overlay show onExit={onClose}>
      <Wrapper
        absolutePosition={absolutePosition}
        width={width}
        padding={padding}
      >
        {children}
      </Wrapper>
    </Overlay>
  )
}

const Wrapper = styled.div<{
  absolutePosition: {
    top?: string
    right?: string
    bottom?: string
    left?: string
  }
  width?: string
  padding?: string
}>`
  position: absolute;
  box-sizing: border-box;
  width: ${p => p.width || '336px'};
  padding: ${p => p.padding || '16px'};
  border-radius: 16px;
  background-color: ${p => p.theme.ComponentWorkspaceBgPage};
  top: ${p => p.absolutePosition.top || 'auto'};
  right: ${p => p.absolutePosition.right || 'auto'};
  bottom: ${p => p.absolutePosition.bottom || 'auto'};
  left: ${p => p.absolutePosition.left || 'auto'};
  z-index: 1;
`
