import moment from 'moment'

export const formatDateForDisplay = (
  date?: Date | string | moment.Moment | number
) => {
  if (!date) return '-'
  const momentDate = moment(date).utc()
  if (momentDate.year() !== moment().year()) {
    return momentDate.format('MMM DD, YYYY')
  }

  return momentDate.format('MMM DD')
}

export const getName = ({ firstName, lastName }) => `${firstName} ${lastName}`
