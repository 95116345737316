import { useEffect } from 'react'
import { useLookerData } from 'redux/state/looker'
import { useParentThemeKey } from 'themes/ThemeSwitcherProvider'
import { LookerReportType } from 'types'
import { useIsLoading } from 'redux/utils'

interface EmbedUserUrls {
  [key: string]: string
}

interface UseEmbedUserUrlResult {
  embedUserUrls: EmbedUserUrls
  isLoading: boolean
}

const useEmbedUserUrl = (
  slug: string,
  type: LookerReportType
): UseEmbedUserUrlResult => {
  const currentThemeKey = useParentThemeKey()

  const {
    state: { embedUserUrls },
    getEmbedUserUrl,
  } = useLookerData()

  const isLoading = useIsLoading('getEmbedUserUrl')

  useEffect(() => {
    if (getEmbedUserUrl) {
      getEmbedUserUrl(
        slug,
        type,
        currentThemeKey === 'dark' ? 'ChordDark' : 'ChordLight2'
      )
    }
  }, [slug, type, currentThemeKey])

  return { embedUserUrls, isLoading }
}

export default useEmbedUserUrl
