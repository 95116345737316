import React from 'react'
import { Button, Label, Modal } from '@chordco/component-library'
import { FormInput } from 'components/common'
import { useForm } from 'hooks'
import styled from 'styled-components'
import { useImportReport } from './useImportReport'
import { AvailableUserReport, LookerReportType } from 'types'

interface ImportReportModalProps {
  folderId: string
  onClose: () => void
  onImported: (report?: AvailableUserReport) => void
}

export const ImportReportModal: React.FC<ImportReportModalProps> = ({
  folderId,
  onClose,
  onImported,
}) => {
  const { submit, isLoading } = useImportReport(onImported)

  const { fields, onSubmit } = useForm(
    {
      reportUrl: {
        label: 'Report URL',
        type: 'string',
        required: true,
      },
      folderId: {
        label: 'Folder ID',
        type: 'string',
        required: true,
        initialValue: folderId,
      },
    },
    async ({ reportUrl, folderId }) => {
      let reportType: LookerReportType

      if (reportUrl.endsWith('?type=dashboard')) {
        reportType = 'dashboard'
      } else if (reportUrl.endsWith('?type=look')) {
        reportType = 'look'
      } else {
        throw new Error('Invalid report URL')
      }

      // Extract the slug from the URL, excluding the query string
      const reportSlug = reportUrl.split('/').pop()?.split('?')[0]

      await submit({ reportSlug, reportType, folderId })
    }
  )

  return (
    <Modal onClose={onClose} title="Import Report" width="480px">
      <FormContainer onSubmit={onSubmit} noValidate>
        <Section>
          <StyledLabel>
            Paste the URL of the report you want to import, then click{' '}
            <strong>Import Report</strong>. A copy of the report will be created
            in your personal folder.
          </StyledLabel>
          <FormInput field={fields.reportUrl} customWidth="100%" required />
        </Section>

        <BottomSection>
          <Button purpose="tertiary" onClick={onClose}>
            Cancel
          </Button>

          <Button
            type="submit"
            purpose="primary"
            isLoading={isLoading}
            disabled={isLoading}
          >
            Import Report
          </Button>
        </BottomSection>
      </FormContainer>
    </Modal>
  )
}

export const FormContainer = styled.form`
  overflow: auto;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledLabel = styled(Label)`
  white-space: pre-wrap;
`

const BottomSection = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`
