import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { Body, H1 } from '@chordco/component-library'
import { CreateDashboardModal } from './CreateDashboardModal'
import { AvailableUserReport } from 'types'
import { AddNewReportButton } from './AddNewReportButton'
import {
  QuickstartCategory,
  QuickstartType,
  useQuickstartsFilter,
} from 'redux/state/quickstartsFilter'
import { CreateLookAlert } from './CreateLookAlert'
import { EmbedUserAutoCreate } from './EmbedUserAutoCreate'

export const MyEmptyFolder: React.FC = () => {
  const history = useHistory()

  const [showCreateDashboard, setShowCreateDashboard] = useState(false)
  const [showCreateLook, setShowCreateLook] = useState(false)

  const { setFilters } = useQuickstartsFilter()

  const handleCreateLook = () => {
    // There is no way to create a completely "blank" look without defining some query upfront.
    // For that reason, we are redirecting users to our Quickstarts page to create a new look from
    // an existing template.
    setFilters({
      selectedType: QuickstartType.LOOKS,
      selectedCategory: QuickstartCategory.ALL_CATEGORIES,
      searchTerm: '',
    })

    history.push('/analytics/quickstarts')
  }

  const handleCreateDashboard = (dashboard?: AvailableUserReport) => {
    setShowCreateDashboard(false)

    if (dashboard) {
      history.push(`/analytics/my_folder/${dashboard.slug}`)
    }
  }

  return (
    <Wrapper>
      <CenteredContent>
        <>
          <StyledH1>What is My Folder?</StyledH1>
          <StyleBody>
            My Folder is your personal space for creating and storing reports
            that only you can access. You can also publish and share your
            reports with your team once you create a report.
          </StyleBody>

          <AddNewReportButton
            onCreateNewDashboard={() => setShowCreateDashboard(true)}
            onCreateNewLook={() => setShowCreateLook(true)}
          />

          <EmbedUserAutoCreate />
        </>

        {showCreateDashboard && (
          <CreateDashboardModal
            onClose={() => setShowCreateDashboard(false)}
            onCreateDashboard={handleCreateDashboard}
          />
        )}

        {showCreateLook && (
          <CreateLookAlert
            onClose={() => setShowCreateLook(false)}
            onCreateLook={handleCreateLook}
          />
        )}
      </CenteredContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: -100px;
`

const StyledH1 = styled(H1)`
  text-align: center;
`

const StyleBody = styled(Body)`
  text-align: center;
  line-height: 1.75;
  max-width: 640px;
`
