import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Calendar: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16667 4.08334C3.66041 4.08334 3.25 4.49374 3.25 5V16.6667C3.25 17.1729 3.66041 17.5833 4.16667 17.5833H15.8333C16.3396 17.5833 16.75 17.1729 16.75 16.6667V5C16.75 4.49374 16.3396 4.08334 15.8333 4.08334H4.16667ZM1.75 5C1.75 3.66532 2.83198 2.58334 4.16667 2.58334H15.8333C17.168 2.58334 18.25 3.66532 18.25 5V16.6667C18.25 18.0014 17.168 19.0833 15.8333 19.0833H4.16667C2.83198 19.0833 1.75 18.0014 1.75 16.6667V5Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3333 0.916672C13.7475 0.916672 14.0833 1.25246 14.0833 1.66667V5C14.0833 5.41422 13.7475 5.75 13.3333 5.75C12.9191 5.75 12.5833 5.41422 12.5833 5V1.66667C12.5833 1.25246 12.9191 0.916672 13.3333 0.916672Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66667 0.916672C7.08088 0.916672 7.41667 1.25246 7.41667 1.66667V5C7.41667 5.41422 7.08088 5.75 6.66667 5.75C6.25245 5.75 5.91667 5.41422 5.91667 5V1.66667C5.91667 1.25246 6.25245 0.916672 6.66667 0.916672Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75 8.33334C1.75 7.91912 2.08579 7.58334 2.5 7.58334H17.5C17.9142 7.58334 18.25 7.91912 18.25 8.33334C18.25 8.74755 17.9142 9.08334 17.5 9.08334H2.5C2.08579 9.08334 1.75 8.74755 1.75 8.33334Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
