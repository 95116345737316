import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import {
  GetAvailableAudiencesResponse,
  GetAvailableUserAudiencesResponse,
} from 'types'

const useAudienceSlug = (
  isMyAudience: boolean,
  availableAudiences: GetAvailableAudiencesResponse,
  availableUserAudiences: GetAvailableUserAudiencesResponse
): string => {
  const history = useHistory()

  const slug = useMemo(() => {
    const pathSegments = history.location.pathname.split('/')
    const pathSlug = pathSegments[pathSegments.length - 1]

    const audiences = isMyAudience
      ? availableUserAudiences?.userAudiences
      : availableAudiences?.audiences

    const foundSlug = audiences?.find(({ slug }) => slug === pathSlug)?.slug

    // We have a slug in the path, return it
    if (foundSlug) {
      return foundSlug
    }

    // If the current My Audience path has no slug, default to the first available User Audience
    if (isMyAudience && availableUserAudiences?.userAudiences?.length > 0) {
      return availableUserAudiences.userAudiences[0].slug
    }

    // If we have no available audiences, return an empty string
    return ''
  }, [
    history.location.pathname,
    isMyAudience,
    availableAudiences,
    availableUserAudiences,
  ])

  return slug
}

export default useAudienceSlug
