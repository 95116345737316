import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Sort: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.69558 2.70921C6.41664 2.43026 5.96438 2.43026 5.68544 2.70921L1.87596 6.51869C1.59701 6.79764 1.59701 7.24989 1.87596 7.52883C2.1549 7.80778 2.60715 7.80778 2.8861 7.52883L5.47616 4.93878V13.6903C5.47616 14.0848 5.79595 14.4046 6.19043 14.4046C6.58492 14.4046 6.90471 14.0848 6.90471 13.6903V4.93862L9.49493 7.52883C9.77387 7.80778 10.2261 7.80778 10.5051 7.52883C10.784 7.24989 10.784 6.79764 10.5051 6.51869L6.69558 2.70921Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M13.3042 17.5288L9.49469 13.7193C9.21575 13.4403 9.21575 12.9881 9.49469 12.7091C9.77363 12.4302 10.2259 12.4302 10.5048 12.7091L13.095 15.2993L13.095 6.54762C13.095 6.15313 13.4148 5.83334 13.8093 5.83334C14.2038 5.83334 14.5236 6.15313 14.5236 6.54762L14.5236 15.2992L17.1137 12.7091C17.3926 12.4302 17.8449 12.4302 18.1238 12.7091C18.4027 12.9881 18.4027 13.4403 18.1238 13.7193L14.3143 17.5288C14.0354 17.8077 13.5831 17.8077 13.3042 17.5288Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
