import React from 'react'
import segmentLogo from 'assets/SegmentLogo.png'
import { useForm } from 'hooks'
import { FormSecureInput } from '../../../common'
import { ValSchemaGeneric } from 'validationSchemas'
import { Fields } from './common'
import { Integration, SegmentSettings } from 'types'
import { IntegrationForm } from './IntegrationForm'
import { useIntegrationForm } from 'redux/hooks'

type Props = {
  onClose: () => void
  integration?: Integration<SegmentSettings>
}

const SEGMENT_LOGIN_LINK = 'https://app.segment.com/login'

const getSchema = (
  settings?: SegmentSettings
): ValSchemaGeneric<['writeKey']> => ({
  writeKey: {
    label: 'Write Key',
    type: 'string',
    initialValue: settings?.writeKey,
  },
})

export const SegmentForm: React.FC<Props> = ({ onClose, integration }) => {
  const { submit, isLoading, isEdit } = useIntegrationForm(
    'segment',
    integration,
    onClose
  )

  const schema = getSchema(integration?.settings)
  const { fields, onSubmit } = useForm(schema, submit)

  return (
    <IntegrationForm
      title="Data"
      name="Segment"
      logo={segmentLogo}
      loginLink={SEGMENT_LOGIN_LINK}
      isLoading={isLoading}
      isEdit={isEdit}
      submit={onSubmit}
      onClose={onClose}
    >
      <Fields>
        <FormSecureInput field={fields.writeKey} customWidth="100%" />
      </Fields>
    </IntegrationForm>
  )
}
