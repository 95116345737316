import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Users } from 'components/users/Users'
import { Home } from 'components/home/Home'
import { AuthenticatedRoutes } from 'components/AuthenticatedRoutes'
import { Accept, Authenticate, Login } from 'components/auth'
import { Notifications } from 'components/Notifications'
import AnalyticsRoutes from 'components/navigation/AnalyticsRoutes'
import { AnalyticsFeatureFlags } from 'components/analytics/Analytics'
import { EnvironmentVariables } from 'components/developers/EnvironmentVariables'
import { SignUpStatus, SignUp } from 'components/provisioning'
import { BusinessErrors } from 'components/developers/BusinessErrors'
import { SystemHealth } from 'components/systemHealth/SystemHealth'
import { EnvVarConfig } from 'utils/envVarConfig'
import { useExperiments } from 'hooks'
import { useTenantsData } from 'redux/state/tenants'
import { SettingsRoutes } from 'components/navigation/SettingsRoutes'
import { OmsRoutes } from 'components/navigation/OmsRoutes'
import { Integrations } from 'components/settings/integrations/Integrations'
import IframeRouterListener from 'IframeRouterListener'
import { MixpanelProvider } from 'utils/mixpanel/provider'
import PageViewTracker from 'components/mixpanel/PageViewTracker'
import { NotionProvider } from 'components/notion/NotionProvider'
import ConfigSheets from 'components/config_sheets/Sheets'
import ConfigSheetContainer from 'components/config_sheets/SheetContainer'
import { DataSources } from 'components/settings/integrations/DataSources'
import { DataActivations } from 'components/settings/integrations/DataActivations'
import MuiThemeProvider from 'themes/muiThemeProvider'
import AudiencesRoutes from 'components/navigation/AudiencesRoutes'

const enableOnboarding = EnvVarConfig.REACT_APP_ENABLE_ONBOARDING === 'true'

function App() {
  const systemHealthTreatment = useExperiments('system_health')
  const showSystemHealth = systemHealthTreatment?.variation === 'on'

  const myFolderTreatment = useExperiments('hub_my_folder_nav_link')
  const myAudiencesTreatment = useExperiments('hub_my_audiences_nav_link')
  const lookerTreatment = useExperiments('hub_looker_status')

  const analyticsFeatureFlags: AnalyticsFeatureFlags = {
    showMyFolder: myFolderTreatment?.variation === 'on',
    showLookerUnderReview: lookerTreatment?.variation === 'under_review',
    showLookerIsSample: lookerTreatment?.variation === 'sample',
    showLookerUnconfigured: lookerTreatment?.variation === 'off',
  }

  const audienceFeatureFlags = {
    showMyAudiences: myAudiencesTreatment?.variation === 'on',
  }

  const {
    state: { currentStoreIsAutonomy },
  } = useTenantsData()

  return (
    <MuiThemeProvider>
      <MixpanelProvider>
        <PageViewTracker>
          <NotionProvider>
            <IframeRouterListener>
              <Notifications />
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/authenticate" component={Authenticate} />
                <Route path="/accept" component={Accept} />
                {enableOnboarding && (
                  <Route path="/sign-up" component={SignUp} />
                )}
                {enableOnboarding && (
                  <Route path="/sign-up-status" component={SignUpStatus} />
                )}
                <AuthenticatedRoutes>
                  <Route
                    exact
                    path="/"
                    render={() => (
                      <Home
                        enableOnboarding={enableOnboarding}
                        enableSystemHealth={showSystemHealth}
                      />
                    )}
                  />

                  <Route path="/data-sources" component={DataSources} />
                  <Route path="/data-activations" component={DataActivations} />

                  <Route exact path="/integrations" component={Integrations} />
                  <Route path="/integrations/:id" component={Integrations} />

                  <Route path="/users" component={Users} />
                  <Route path="/settings">
                    <SettingsRoutes />
                  </Route>

                  <Route
                    path="/analytics"
                    render={props => (
                      <AnalyticsRoutes
                        {...props}
                        featureFlags={analyticsFeatureFlags}
                      />
                    )}
                  />

                  {showSystemHealth && (
                    <Route path="/system-health" component={SystemHealth} />
                  )}

                  <Route
                    path="/environment-variables"
                    component={EnvironmentVariables}
                  />
                  <Route path="/test" component={() => <div>Test</div>} />
                  <Route path="/business-errors" component={BusinessErrors} />

                  <Route
                    exact
                    path="/model-amplifications"
                    component={ConfigSheets}
                  />

                  <Route
                    path="/model-amplifications/:slug"
                    component={ConfigSheetContainer}
                  />

                  <Route
                    path="/audiences"
                    render={props => (
                      <AudiencesRoutes
                        {...props}
                        featureFlags={audienceFeatureFlags}
                      />
                    )}
                  />

                  {currentStoreIsAutonomy && <OmsRoutes />}

                  <Route path="/documentation">
                    Looks like you&apos;re on you&apos;re own
                  </Route>
                </AuthenticatedRoutes>
                <Redirect to="/" />
              </Switch>
            </IframeRouterListener>
          </NotionProvider>
        </PageViewTracker>
      </MixpanelProvider>
    </MuiThemeProvider>
  )
}

export default App
