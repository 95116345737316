import React from 'react'
import { Header, Section } from '../../common'
import { IntegrationCard } from './IntegrationCard'
import { SimpleBar, Spinner } from '@chordco/component-library'
import { useIsMobile } from 'hooks'
import { Integration, IntegrationStatus, Plan, ProviderOption } from 'types'
import * as integrationIcons from 'assets/integrations'
import { ContentfulForm } from './forms/ContentfulForm'
import { fulfillmentProviders } from './forms/FulfillmentProviderForm'
import { authenticationProviders } from './forms/AuthenticationProviderForm'
import { SegmentForm } from './forms/SegmentForm'
import { NetlifyForm } from './forms/NetlifyForm'
import { useTenantsData } from 'redux/state/tenants'
import { useIntegrationsByEnvironment } from 'redux/hooks'
import { useIsLoading } from 'redux/utils'
import { FivetranForm } from './forms/FivetranForm'
import EmptyIntegrations from './EmptyIntegrations'
import { CensusForm } from './forms/CensusForm'
import { useAuthData } from 'redux/state/auth'

export type IntegrationWithForm = Integration & {
  status: IntegrationStatus
  photo: string
  form: React.FC<{
    onClose: () => void
    integration?: Integration<any>
  }>
  showForPlans: Plan[]
}

const integrationCards: Pick<
  IntegrationWithForm,
  'type' | 'name' | 'photo' | 'form' | 'category' | 'showForPlans'
>[] = [
  {
    type: 'Hub::Contentful',
    category: 'CMS',
    name: 'Content Management (CMS)',
    photo: integrationIcons.CMS,
    form: ContentfulForm,
    showForPlans: [Plan.Performance],
  },
  {
    type: 'Hub::Segment',
    category: 'Data',
    name: 'Customer Data (CDP)',
    photo: integrationIcons.sources,
    form: SegmentForm,
    showForPlans: [Plan.Performance],
  },
  {
    type: 'Hub::Fivetran',
    category: 'Data',
    name: 'Data Sources',
    photo: integrationIcons.sources,
    form: FivetranForm,
    showForPlans: [Plan.Autonomy, Plan.Performance, Plan.DataOnly],
  },
  {
    type: 'Hub::Census',
    category: 'Data',
    name: 'Data Activations',
    photo: integrationIcons.destinations,
    form: CensusForm,
    showForPlans: [Plan.Autonomy, Plan.Performance, Plan.DataOnly],
  },
  {
    type: 'Hub::Netlify',
    category: 'Front_end_host',
    name: 'Hosting',
    photo: integrationIcons.hosting,
    form: NetlifyForm,
    showForPlans: [Plan.Performance],
  },
]

export const Integrations = () => {
  const isMobile = useIsMobile()
  const { integrations } = useIntegrationsByEnvironment()

  const {
    state: { currentStore },
  } = useTenantsData()

  if (!currentStore) {
    throw new Error('currentStore is undefined')
  }

  const {
    state: { user },
  } = useAuthData()

  if (!user) throw new Error('Hub User not found!')

  // Filter Integration cards by store plan
  let cards = integrationCards.filter(i =>
    i.showForPlans.includes(currentStore.plan)
  )

  // Ensure Fivetran or Census are not showing up in case users are not superadmins
  if (user.role !== 'superuser') {
    cards = cards.filter(
      i => i.type !== 'Hub::Fivetran' && i.type !== 'Hub::Census'
    )
  }

  const selectProviderCard = (
    type: string,
    category: string,
    providers: ProviderOption[]
  ) => {
    const integration = integrations.find(
      i => i.category === category && i.active
    )

    if (integration) {
      const info = providers.find(p => p.key === integration.type)
      if (info) {
        cards = cards.map(c =>
          c.type === type
            ? {
                ...c,
                type: integration.type,
                photo: info.logo,
              }
            : c
        )
      }
    }
  }

  selectProviderCard('Hub::Fulfillment', 'Fulfillment', fulfillmentProviders)
  selectProviderCard(
    'Hub::Authentication',
    'Authentication',
    authenticationProviders
  )

  const loading = useIsLoading('getStoreSetup')

  return (
    <>
      <Header
        title="Integrations"
        subtitle="Connect to third party solutions"
      />
      <SimpleBar>
        <Section isMobile={isMobile} hasBorder={false}>
          {loading && <Spinner scale={30} />}

          {!loading && cards.length === 0 && <EmptyIntegrations />}

          {!loading &&
            cards.length > 0 &&
            cards.map(i => (
              <IntegrationCard
                key={i.type}
                name={i.name}
                photo={i.photo}
                integration={integrations.find(ri => ri.type === i.type)}
                form={i.form}
                loading={loading}
                openSheetOnLoad={false}
              />
            ))}
        </Section>
      </SimpleBar>
    </>
  )
}
