import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Metrics: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.6665 1.44446C8.08072 1.44446 8.4165 1.78024 8.4165 2.19446V17.75C8.4165 18.1642 8.08072 18.5 7.6665 18.5C7.25229 18.5 6.9165 18.1642 6.9165 17.75V2.19446C6.9165 1.78024 7.25229 1.44446 7.6665 1.44446ZM17.75 5.30554C17.75 4.89133 17.4142 4.55554 17 4.55554C16.5858 4.55554 16.25 4.89133 16.25 5.30554V18.1389C16.25 18.5531 16.5858 18.8889 17 18.8889C17.4142 18.8889 17.75 18.5531 17.75 18.1389V5.30554ZM13.0835 8.0278C13.0835 7.61359 12.7477 7.2778 12.3335 7.2778C11.9193 7.2778 11.5835 7.61359 11.5835 8.0278V17.75C11.5835 18.1642 11.9193 18.5 12.3335 18.5C12.7477 18.5 13.0835 18.1642 13.0835 17.75V8.0278ZM3.75 11.9167C3.75 11.5024 3.41421 11.1667 3 11.1667C2.58579 11.1667 2.25 11.5024 2.25 11.9167V17.75C2.25 18.1642 2.58579 18.5 3 18.5C3.41421 18.5 3.75 18.1642 3.75 17.75V11.9167Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
