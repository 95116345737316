import { EnvVarConfig } from './envVarConfig'

// Default ping interval: 1 minute in milliseconds
const defaultPingInterval: number = 60 * 1000

// Retrieve the ping interval from the environment variable, or use the default if not defined
const pingInterval: number =
  parseInt(EnvVarConfig.REACT_APP_OMS_SESSION_KEEP_ALIVE_MS) ||
  defaultPingInterval

/**
 * Pings an app at a given URL. This is currently being used to keep the OMS user sessions
 * alive, when we embed OMS pages in the Hub.
 */
function pingApp(pingUrl: string) {
  fetch(pingUrl, { method: 'GET' })
    .then(response => {
      if (!response.ok) {
        // eslint-disable-next-line no-console
        console.error(
          `Ping to ${pingUrl} failed with status: ${response.status}`
        )
      }
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error(`Ping to ${pingUrl} failed with error: ${error}`)
    })
}

function startPingInterval(pingUrl: string) {
  return window.setInterval(() => pingApp(pingUrl), pingInterval)
}

function stopPingInterval(intervalId: number) {
  window.clearInterval(intervalId)
}

export { startPingInterval, stopPingInterval }
