import { Body, Caption, H1 } from '@chordco/component-library'
import styled from 'styled-components'

export const StyledH1 = styled(H1)`
  text-align: center;
`

export const StyleBody = styled(Body)`
  text-align: center;
  line-height: 1.75;
`

export const ImageContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 8px;
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin-left: 1rem;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`

export const CenteredContent = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${p => p.gap || 24}px;
  margin: 0 auto;
  max-width: 640px;
`

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 56px;
  background-color: ${p => p.theme.BgAccentLightAADarkSuccess};
  border-radius: 8px;
`

export const ConfirmationContent = styled.div`
  padding: 16px;
`

export const StyledCaption = styled(Caption)`
  margin-top: 4px;
  color: ${p => p.theme.BLACK};
`

export const LoadingContainer = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
`
