import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Mail: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.03125 5.6263V14.6875C2.03125 15.0345 2.38337 15.4688 3 15.4688H17C17.6166 15.4688 17.9688 15.0345 17.9688 14.6875V5.62634L10.5002 11.8502C10.2104 12.0916 9.7896 12.0916 9.49988 11.8502L2.03125 5.6263ZM16.8422 4.53125H3.15789L10 10.233L16.8422 4.53125ZM3 2.96875C1.69163 2.96875 0.46875 3.94075 0.46875 5.3125V14.6875C0.46875 16.0592 1.69163 17.0312 3 17.0312H17C18.3084 17.0312 19.5312 16.0592 19.5312 14.6875V5.3125C19.5312 3.94075 18.3084 2.96875 17 2.96875H3Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
