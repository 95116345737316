import React from 'react'
import styled from 'styled-components'

interface Props {
  percent: number
  style?: {}
  className?: string
}

const convertPercentageToDegrees = (percent: number) => percent * 3.6

export const PercentTicker: React.FC<Props> = ({
  percent,
  style,
  className,
}) => {
  let safePercent = percent
  if (percent < 0) safePercent = 0
  if (percent > 100) safePercent = 100

  return (
    <CircleBorder
      degrees={convertPercentageToDegrees(safePercent)}
      style={style}
      className={className}
    >
      <Circle>{percent}%</Circle>
    </CircleBorder>
  )
}

// The two linear gradients are used to make sure the appropriate amount of
// edges appear pink
//
// The gradient defined second never changes position or color, it is a pink
// semicircle that covers the right half of the circle
//
// The gradient defined first changes both color and position depending on the
// percentage. For sub-50%, it is gray and 'covers up' sections of the other
// gradient. For over-50% it is pink and is used to extend the pink as far as
// it needs to go
//
// To more easily understand this visually, comment out the Circle component
// and change the background-color of the CircleBorder component, then adjust
// the degrees

const CircleBorder = styled.div<{ degrees: number }>`
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${p => p.theme.OFF_TEXT_BASE};
  background-image: linear-gradient(
      calc(90deg + ${p => (p.degrees > 180 ? p.degrees - 180 : p.degrees)}deg),
      transparent 50%,
      ${p => (p.degrees <= 180 ? p.theme.OFF_TEXT_BASE : p.theme.PRIMARY_50)}
        50%
    ),
    linear-gradient(90deg, transparent 50%, ${p => p.theme.PRIMARY_50} 50%);
`

const Circle = styled.div`
  position: absolute;
  box-sizing: border-box;
  top: 2px;
  left: 2px;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  background-color: ${p => p.theme.OFF_BLACK};
  color: ${p => p.theme.OFF_TEXT_BASE};
  text-align: center;
  padding-top: 12px;
  font-size: 12px;
`
