import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const CouponEntrants: React.FC<IconProps> = ({
  scale = 48,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 12.8909H38V14.3909L14.75 14.3909V31.6409C14.75 33.4358 16.2051 34.8909 18 34.8909H30C31.7949 34.8909 33.25 33.4358 33.25 31.6409V18.1409H34.75V31.6409C34.75 34.2642 32.6234 36.3909 30 36.3909H18C15.3766 36.3909 13.25 34.2642 13.25 31.6409V14.3909H10V12.8909Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M20.3291 30.3055L26.3291 18.3055L27.6707 18.9763L21.6707 30.9763L20.3291 30.3055Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.75 18.6409C18.2312 18.6409 17 19.8721 17 21.3909C17 22.9097 18.2312 24.1409 19.75 24.1409C21.2688 24.1409 22.5 22.9097 22.5 21.3909C22.5 19.8721 21.2688 18.6409 19.75 18.6409ZM18.5 21.3909C18.5 20.7005 19.0596 20.1409 19.75 20.1409C20.4404 20.1409 21 20.7005 21 21.3909C21 22.0812 20.4404 22.6409 19.75 22.6409C19.0596 22.6409 18.5 22.0812 18.5 21.3909Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.75 25.6409C26.2312 25.6409 25 26.8721 25 28.3909C25 29.9097 26.2312 31.1409 27.75 31.1409C29.2688 31.1409 30.5 29.9097 30.5 28.3909C30.5 26.8721 29.2688 25.6409 27.75 25.6409ZM26.5 28.3909C26.5 27.7005 27.0596 27.1409 27.75 27.1409C28.4404 27.1409 29 27.7005 29 28.3909C29 29.0812 28.4404 29.6409 27.75 29.6409C27.0596 29.6409 26.5 29.0812 26.5 28.3909Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
