import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const DataActivations: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5303 6.46967L18.5303 9.46967C18.8232 9.76256 18.8232 10.2374 18.5303 10.5303L15.5303 13.5303C15.2374 13.8232 14.7626 13.8232 14.4697 13.5303C14.1768 13.2374 14.1768 12.7626 14.4697 12.4697L16.1893 10.75H8.75C8.33579 10.75 8 10.4142 8 10C8 9.58579 8.33579 9.25 8.75 9.25L16.1893 9.25L14.4697 7.53033C14.1768 7.23744 14.1768 6.76256 14.4697 6.46967C14.7626 6.17678 15.2374 6.17678 15.5303 6.46967Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.75 3C10.75 2.58579 10.4142 2.25 10 2.25C5.71979 2.25 2.25 5.71979 2.25 10C2.25 14.2802 5.71979 17.75 10 17.75C10.4142 17.75 10.75 17.4142 10.75 17C10.75 16.5858 10.4142 16.25 10 16.25C6.54822 16.25 3.75 13.4518 3.75 10C3.75 6.54822 6.54822 3.75 10 3.75C10.4142 3.75 10.75 3.41421 10.75 3Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
