import { MRT_TableOptions, MRT_RowData } from 'material-react-table'

interface EventProps<TData extends MRT_RowData> {
  onCreatingRowSave?: MRT_TableOptions<TData>['onCreatingRowSave']
  onCreatingRowCancel?: MRT_TableOptions<TData>['onCreatingRowCancel']
  onEditingRowSave?: MRT_TableOptions<TData>['onEditingRowSave']
  onEditingRowCancel?: MRT_TableOptions<TData>['onEditingRowCancel']
}

export function getEventProps<TData extends MRT_RowData>({
  onCreatingRowSave,
  onCreatingRowCancel,
  onEditingRowSave,
  onEditingRowCancel,
}: EventProps<TData>): Partial<MRT_TableOptions<TData>> {
  return {
    onCreatingRowSave,
    onCreatingRowCancel,
    onEditingRowSave,
    onEditingRowCancel,
  }
}
