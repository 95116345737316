import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const LineChart: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill={fill || theme.ContentPrimary}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.37143 2.68571C3.37143 2.307 3.06442 2 2.68571 2C2.307 2 2 2.307 2 2.68571V16.4C2 17.2837 2.71634 18 3.6 18H17.3143C17.693 18 18 17.693 18 17.3143C18 16.9356 17.693 16.6286 17.3143 16.6286H3.6C3.47376 16.6286 3.37143 16.5262 3.37143 16.4V2.68571Z" />
      <path d="M17.0653 3.76631C17.1571 3.39891 16.9338 3.02661 16.5664 2.93476C16.1989 2.84291 15.8266 3.06629 15.7348 3.43369C15.2798 5.25373 14.6913 6.97537 13.9483 8.04C13.5786 8.56959 13.221 8.86287 12.894 8.97547C12.602 9.07599 12.2341 9.06735 11.7277 8.75945C11.0748 8.3625 10.404 7.99641 9.75149 7.80046C9.08824 7.60128 8.37236 7.55708 7.69361 7.90137C7.02716 8.23942 6.52452 8.88797 6.1358 9.79191C5.74613 10.6981 5.4394 11.9329 5.20693 13.5602C5.15338 13.9351 5.41388 14.2824 5.78878 14.336C6.16369 14.3895 6.51102 14.129 6.56458 13.7541C6.78926 12.1813 7.07426 11.0811 7.39568 10.3337C7.71805 9.58403 8.04586 9.26047 8.314 9.12445C8.56983 8.99469 8.89267 8.97449 9.35705 9.11394C9.83216 9.25662 10.3779 9.54379 11.0152 9.93129C11.7991 10.4079 12.5905 10.5304 13.3405 10.2722C14.0554 10.026 14.6222 9.47063 15.0729 8.82491C15.9704 7.53892 16.606 5.60341 17.0653 3.76631Z" />
      {/*<path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 2C2.75 1.58579 2.41421 1.25 2 1.25C1.58579 1.25 1.25 1.58579 1.25 2V17C1.25 17.9665 2.0335 18.75 3 18.75H18C18.4142 18.75 18.75 18.4142 18.75 18C18.75 17.5858 18.4142 17.25 18 17.25H3C2.86193 17.25 2.75 17.1381 2.75 17V2ZM17.7162 3.22316C17.8395 2.82771 17.6188 2.40722 17.2234 2.28396C16.8279 2.1607 16.4074 2.38136 16.2842 2.77681C15.4823 5.34953 14.6667 6.47399 13.9512 6.99543C13.2526 7.50454 12.5415 7.53069 11.5608 7.56289C10.6087 7.59415 9.42472 7.63601 8.27651 8.52386C7.15637 9.39 6.17653 10.9722 5.28551 13.7726C5.15992 14.1673 5.37808 14.5891 5.7728 14.7147C6.16751 14.8403 6.5893 14.6221 6.7149 14.2274C7.57388 11.5277 8.43494 10.2975 9.19406 9.71049C9.92512 9.14521 10.6588 9.09332 11.61 9.06208L11.7196 9.05859C12.6249 9.03014 13.7548 8.99463 14.8347 8.20767C15.9416 7.40098 16.8817 5.90045 17.7162 3.22316Z"
        fill={fill || theme.GREY_80}
      />*/}
    </svg>
  )
}
