import React from 'react'
import { Spinner } from './Spinner'
import styled from 'styled-components'

interface Props {
  hideMask?: boolean
}

export const Loading: React.FC<Props> = ({ hideMask = false }) => (
  <Mask hideMask={hideMask}>
    <Spinner scale={30} />
  </Mask>
)

const Mask = styled.div<{ hideMask: boolean }>`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: ${p => (p.hideMask ? 'none' : p.theme.GREY_5)};
  opacity: 0.5;
  z-index: 2;
  display: flex;
`
