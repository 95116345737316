import React from 'react'
import styled from 'styled-components'

export const NoResults: React.FC = () => {
  return (
    <NoResultsContainer>
      <NoResultsText>
        There are no results that match your search criteria.
      </NoResultsText>
    </NoResultsContainer>
  )
}

const NoResultsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  padding: 16px;
`

const NoResultsText = styled.p`
  font-size: 18px;
  color: ${p => p.theme.ContentSecondary};
  font-weight: 500;
`
