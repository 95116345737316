import React from 'react'
import styled from 'styled-components'
import { Loading } from 'components/common'
import { DashboardEmbed } from './DashboardEmbed'
import useEmbedUrl from './useEmbedUrl'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { NavigationContext } from 'components/AuthenticatedRoutes'
import { useIsMobile } from 'hooks'
import {
  AlertModal,
  Button,
  H1,
  H2,
  standardIcons,
} from '@chordco/component-library'
import { useUnpublishDashboard } from './useUnpublishDashboard'
import { useAuthData } from 'redux/state/auth'
import { LookerReportType, ReportCreator } from 'types'
import { useLookerData } from 'redux/state/looker'
import { LookEmbed } from './LookEmbed'
import { useUnpublishLook } from './useUnpublishLook'

interface RouteParams {
  slug: string
}

interface LocationState {
  title: string
  createdBy: ReportCreator
  type: LookerReportType
}

export const Report: React.FC = () => {
  const { slug } = useParams<RouteParams>()
  const location = useLocation<LocationState>()

  // If the title is not passed in the location state, use the slug as the title,
  // that's better than nothing...
  const { title, createdBy, type } = location.state || {
    title: slug,
    createdBy: null,
    type: 'dashboard',
  }

  const { embedUrls, isLoading } = useEmbedUrl(slug, type)

  const isMobile = useIsMobile()
  const Heading = isMobile ? H2 : H1

  const history = useHistory()

  const { getAvailableReports } = useLookerData()

  const {
    state: { user },
  } = useAuthData()

  const canUnpublish =
    (user?.role === 'admin' || user?.role === 'superuser') && createdBy !== null

  const [showUnpublishAlert, setShowUnpublishAlert] = React.useState(false)

  const refreshReportsAndRedirect = () => {
    if (getAvailableReports) {
      getAvailableReports().then(() => {
        history.push('/analytics/reports')
      })
    }
  }

  const { unpublish: unpublishDashboard, isLoading: unpublishingDashboard } =
    useUnpublishDashboard(refreshReportsAndRedirect)

  const { unpublish: unpublishLook, isLoading: unpublishingLook } =
    useUnpublishLook(refreshReportsAndRedirect)

  const isUnpublishing = unpublishingDashboard || unpublishingLook

  const handleUnpublish = () => {
    if (type === 'dashboard') {
      unpublishDashboard({ dashboardSlug: slug })
    } else if (type === 'look') {
      unpublishLook({ lookSlug: slug })
    }
  }

  return (
    <NavigationContext.Consumer>
      {({ navOpen }) => (
        <Wrapper navOpen={navOpen}>
          <Header>
            <Heading>{title}</Heading>
            <ButtonsContainer>
              {canUnpublish && (
                <Button
                  purpose="tertiary"
                  icon={standardIcons.Unpublish}
                  onClick={() => setShowUnpublishAlert(true)}
                >
                  Unpublish
                </Button>
              )}
              <Button
                purpose="secondary"
                icon={standardIcons.ArrowLeft}
                onClick={() => history.push('/analytics/reports')}
              >
                Back to Reports
              </Button>
            </ButtonsContainer>
          </Header>

          <Container>
            {isLoading && <Loading />}
            {!isLoading && type === 'dashboard' && (
              <DashboardEmbed url={embedUrls[slug]} />
            )}
            {!isLoading && type === 'look' && (
              <LookContainer>
                <LookEmbed url={embedUrls[slug]} />
              </LookContainer>
            )}
          </Container>

          {showUnpublishAlert && canUnpublish && (
            <AlertModal
              onClose={() => setShowUnpublishAlert(false)}
              icon={standardIcons.Unpublish}
              content={`Please confirm that you want to unpublish the report "${title}".`}
              centered
              buttons={[
                {
                  id: 1,
                  text: 'No',
                  onClick: () => setShowUnpublishAlert(false),
                  purpose: 'secondary',
                  disabled: isUnpublishing,
                },
                {
                  id: 2,
                  text: 'Yes, unpublish it',
                  onClick: handleUnpublish,
                  purpose: 'primary',
                  isLoading: isUnpublishing,
                  disabled: isUnpublishing,
                },
              ]}
            />
          )}
        </Wrapper>
      )}
    </NavigationContext.Consumer>
  )
}

const Wrapper = styled.div<{ navOpen: boolean }>`
  color: ${p => p.theme.ContentPrimary};
  height: 100vh;
  width: ${p => (p.navOpen ? 'calc(100vw - 280px)' : '100vw')};
  position: fixed;
  top: 0;
  right: 0;
`

const Header = styled.div`
  padding: 24px 32px 0px 64px;
  display: flex;
  justify-content: space-between;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`

const LookContainer = styled.div`
  padding: 48px 64px 0 64px;
`
