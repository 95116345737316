import React, { useEffect } from 'react'
import { useAuthData } from 'redux/state/auth'
import { useNotifications } from 'redux/state/notifications'
import { useHistory } from 'react-router'
import { LoadingPage } from '../common'
import { v4 as generateUUID } from 'uuid'
import { useIsIdle } from 'redux/utils'
import { getQueryStringValues } from 'utils'
import useMixpanel from 'hooks/useMixpanel'

export const Authenticate: React.FC = () => {
  const queryStringValues = getQueryStringValues(location.search, true)
  const { login, state } = useAuthData()
  const { addNotification } = useNotifications()
  const isLoginIdle = useIsIdle('login')

  const history = useHistory()
  const { identify } = useMixpanel()

  useEffect(() => {
    const handleAuthenticatedUser = () => {
      if (isLoginIdle) {
        // invite/login link clicked when another user is already logged in
        const id = generateUUID() as string
        addNotification({
          id,
          type: 'caution',
          message:
            'A user is already logged in. Please, log out and try again.',
        })

        history.push('')
      } else if (!state.user?.firstName || !state.user?.lastName) {
        // invited user hasn't entered their name
        history.push('accept')
      } else {
        identify(state.user)

        // an authenticated user hit the /authenticate page
        history.push('')
      }
    }

    if (state.isAuthenticated) {
      handleAuthenticatedUser()
    } else if (isLoginIdle) {
      // an unauthenticated user is logging in
      const { token, stytchTokenType = '' } = queryStringValues
      login({ token, stytchTokenType })
    }
  }, [state.isAuthenticated, isLoginIdle])

  return <LoadingPage />
}
