import React from 'react'
import styled from 'styled-components'
import {
  Avatar,
  AvatarSize,
  standardIcons,
  Button,
} from '@chordco/component-library'

type LoginLinkProps = {
  name: string
  logo: string
  logoSize: AvatarSize
  loginLink: string
  onChangeProvider?: () => void
}

export const LoginLink: React.FC<LoginLinkProps> = ({
  name,
  logo,
  logoSize,
  loginLink,
  onChangeProvider,
}) => {
  const onLoginLinkClick = () => {
    const handle = window.open(loginLink, '_blank')
    handle?.blur()
    window.focus()
  }

  return (
    <LoginLinkContainer>
      <FlexGroup onClick={onLoginLinkClick}>
        <Avatar src={logo} size={logoSize} />
        <div>{name}</div>
        <Button
          icon={standardIcons.OpenLink}
          variant="ghost"
          size="small"
        ></Button>
      </FlexGroup>
      {onChangeProvider && (
        <Button variant="ghost" onClick={onChangeProvider}>
          Change
        </Button>
      )}
    </LoginLinkContainer>
  )
}

export const LoginLinkContainer = styled.div`
  background: ${p => p.theme.ComponentCardBgElevation0};
  padding: 12px 16px;
  height: 72px;
  background-color: ${p => p.theme.ComponentCardBgElevation1};
  border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
  border-radius: ${p => p.theme.CardMediumRadius};
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    margin-right: 16px;
  }

  div {
    margin-right: 8px;
  }
`
export const FlexGroup = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: 16px;
  }

  div {
    margin-right: 8px;
  }
`

export const Change = styled.div`
  cursor: pointer;
  color: ${p => p.theme.ContentAccentPrimary};
`

export const Form = styled.form`
  min-height: calc(100vh - 184px);
`

export const Fields = styled.div`
  & > div {
    margin-top: 16px;
  }
`

export const Bottom = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 72px;
  border-top: 1px solid ${p => p.theme.BorderHairline};
  padding: 16px;

  button {
    width: 100%;
  }
`
