import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const DataSources: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 2.25C9.58579 2.25 9.25 2.58579 9.25 3C9.25 3.41421 9.58579 3.75 10 3.75C13.4518 3.75 16.25 6.54822 16.25 10C16.25 13.4518 13.4518 16.25 10 16.25C9.58579 16.25 9.25 16.5858 9.25 17C9.25 17.4142 9.58579 17.75 10 17.75C14.2802 17.75 17.75 14.2802 17.75 10C17.75 5.71979 14.2802 2.25 10 2.25Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M11.5303 9.46967L8.53033 6.46967C8.23744 6.17678 7.76256 6.17678 7.46967 6.46967C7.17678 6.76256 7.17678 7.23744 7.46967 7.53033L9.18934 9.25L1.75 9.25C1.33579 9.25 1 9.58579 1 10C1 10.4142 1.33579 10.75 1.75 10.75H9.18934L7.46967 12.4697C7.17678 12.7626 7.17678 13.2374 7.46967 13.5303C7.76256 13.8232 8.23744 13.8232 8.53033 13.5303L11.5303 10.5303C11.8232 10.2374 11.8232 9.76256 11.5303 9.46967Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
