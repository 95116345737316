import { useLookerData } from 'redux/state/looker'
import { AvailableUserReport } from 'types'
import { useState } from 'react'

interface CopyDashboardData {
  id: string
  title: string
  description: string
  folderId: string
}

interface CopyDashboardResponse {
  error?: string
  payload: {
    data: {
      dashboard: AvailableUserReport | undefined
    }
  }
}

export const useCopyDashboard = (
  onCopied: (dashboard?: AvailableUserReport) => void
) => {
  const { copyDashboard, getAvailableUserReports } = useLookerData()

  const [isLoading, setIsLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const [hasSucceeded, setHasSucceeded] = useState(false)

  const copy = async ({
    id,
    title,
    description,
    folderId,
  }: CopyDashboardData) => {
    if (!copyDashboard) return
    if (!getAvailableUserReports) return

    setIsLoading(true)
    setHasSucceeded(false)
    setHasFailed(false)

    try {
      const response = (await copyDashboard(
        id,
        title,
        description,
        folderId
      )) as unknown as CopyDashboardResponse

      if (!response.error) {
        await getAvailableUserReports()
        setHasSucceeded(true)
        onCopied(response.payload.data.dashboard)
      } else {
        setHasFailed(true)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error: ', error)
      setHasFailed(true)
    } finally {
      setIsLoading(false)
    }
  }

  return { copy, isLoading, hasSucceeded, hasFailed }
}
