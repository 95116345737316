import React, { useEffect } from 'react'
import fivetranLogo from 'assets/FivetranLogo.png'
import { useForm } from 'hooks'
import { FormInput, FormCheckbox, Hr, Loading } from '../../../common'
import { ValSchemaGeneric } from 'validationSchemas'
import { Fields } from './common'
import { ChordDataConnector, FivetranSettings, Integration } from 'types'
import { IntegrationForm } from './IntegrationForm'
import { useIntegrationForm } from 'redux/hooks'
import { Label, SubLabel, standardIcons } from '@chordco/component-library'
import styled, { useTheme } from 'styled-components'
import { useProvisioningData } from 'redux/state/provisioning'
import { useIsLoading } from 'redux/utils'

const { AlertInfo } = standardIcons

type Props = {
  onClose: () => void
  integration?: Integration<FivetranSettings>
}

const FIVETRAN_LOGIN_LINK = 'https://fivetran.com/login'

const getSchema = (
  settings?: FivetranSettings
): ValSchemaGeneric<['destinationGroupId', 'filterConnectors']> => ({
  destinationGroupId: {
    label: 'Destination Group ID',
    type: 'string',
    initialValue: settings?.destinationGroupId,
    required: true,
  },
  filterConnectors: {
    label: 'Filter Connectors',
    type: 'string',
    initialValue: settings?.filterConnectors,
  },
})

export const FivetranForm: React.FC<Props> = ({ onClose, integration }) => {
  const theme = useTheme()

  const [selectedConnectors, setSelectedConnectors] = React.useState<
    ChordDataConnector[]
  >([])

  const {
    state: { dataConnectors },
    getDataConnectors,
  } = useProvisioningData()

  const { submit, isLoading, isEdit } = useIntegrationForm(
    'fivetran',
    integration,
    onClose
  )

  const schema = getSchema(integration?.settings)
  const { fields, onSubmit } = useForm(schema, submit)

  const isLoadingConnectors = useIsLoading('getDataConnectors')

  useEffect(() => {
    // If we don't have the data connectors, we need to fetch them
    if (!dataConnectors.length && !isLoadingConnectors) {
      getDataConnectors()
    }

    // If we are editing an existing integration, we can determine which connectors are activated.
    // This is coming from the backend as a comma-separated string of connector slugs.
    const enabledConnectorSlugs = integration?.settings?.filterConnectors

    // If we have the data connectors and the activated connectors, we can set the selected
    // connectors in the form/checkboxes
    if (dataConnectors.length && enabledConnectorSlugs) {
      setSelectedConnectors(
        dataConnectors.filter(c =>
          enabledConnectorSlugs.split(',')?.includes(c.slug)
        )
      )
    }
  }, [dataConnectors, isLoadingConnectors])

  return (
    <IntegrationForm
      title="Data Sources Settings"
      name="Fivetran"
      logo={fivetranLogo}
      loginLink={FIVETRAN_LOGIN_LINK}
      isLoading={isLoading}
      isEdit={isEdit}
      submit={onSubmit}
      onClose={onClose}
    >
      <Fields>
        <FormInput field={fields.destinationGroupId} customWidth="100%" />
        <Hr isMobile={false} />

        {isLoadingConnectors && <Loading />}
        {!isLoadingConnectors && (
          <>
            <Label>Available Connectors</Label>
            <ConnectorList>
              {/* Create a shallow copy to avoid mutations to our read-only properties/frozen array */}
              {[...dataConnectors]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(connector => (
                  <StyledCheckbox
                    key={connector.slug}
                    field={{
                      name: 'Connector',
                      label: connector.name,
                      type: 'boolean',
                      value:
                        selectedConnectors.filter(
                          c => c.slug === connector.slug
                        ).length > 0,
                      setValue: val => {
                        let updatedConnectors

                        if (val) {
                          // Add the selected connector to the list
                          updatedConnectors = [...selectedConnectors, connector]
                        } else {
                          // Remove the selected connector from the list
                          updatedConnectors = selectedConnectors.filter(
                            c => c.slug !== connector.slug
                          )
                        }

                        // Keep track of the selected connectors in state for our UI purposes
                        setSelectedConnectors(updatedConnectors)

                        // Update the form field with the selected connector slugs
                        // so we can save them to the database as a comma-separated string
                        fields.filterConnectors.setValue(
                          updatedConnectors.map(c => c.slug).join(',')
                        )
                      },
                    }}
                  />
                ))}
            </ConnectorList>

            <AlertInfoContainer>
              <AlertInfo fill={theme.PRIMARY_50} scale={16} />
              <StyledSubLabel>
                Deactivating a connector will remove it from the list of
                available data sources but will not affect any connectors
                already in use.
              </StyledSubLabel>
            </AlertInfoContainer>
          </>
        )}
      </Fields>
    </IntegrationForm>
  )
}

const ConnectorList = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 10px;
`

const StyledCheckbox = styled(FormCheckbox)`
  width: 100%;
  flex-basis: 50%;
`

const StyledSubLabel = styled(SubLabel)`
  font-size: 12px;
`

const AlertInfoContainer = styled.div`
  display: flex;
  align-items: top;
  gap: 8px;
  padding: 10px;
  margin-top: 10px;
`
