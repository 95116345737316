import { valTypes } from 'validationSchemas'

const createConfigSchema = () => ({
  spreadsheetId: {
    label: 'Spreadsheet ID',
    type: valTypes.string,
    initialValue: '',
    required: true,
  },
  sheets: {
    label: 'Sheets',
    type: valTypes.array,
    initialValue: [],
    required: true,
  },
})

export default createConfigSchema
