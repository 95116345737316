import { useEffect } from 'react'
import { useLookerData } from 'redux/state/looker'
import { useParentThemeKey } from 'themes/ThemeSwitcherProvider'
import { LookerReportType } from 'types'
import { useIsLoading } from 'redux/utils'

interface EmbedUserAudienceUrls {
  [key: string]: string
}

interface UseEmbedUserAudienceUrlResult {
  embedUserAudienceUrls: EmbedUserAudienceUrls
  isLoading: boolean
}

const useEmbedUserAudienceUrl = (
  slug: string,
  type: LookerReportType
): UseEmbedUserAudienceUrlResult => {
  const currentThemeKey = useParentThemeKey()

  const {
    state: { embedUserAudienceUrls },
    getEmbedUserAudienceUrl,
  } = useLookerData()

  const isLoading = useIsLoading('getEmbedUserAudienceUrl')

  useEffect(() => {
    if (getEmbedUserAudienceUrl) {
      getEmbedUserAudienceUrl(
        slug,
        currentThemeKey === 'dark'
          ? 'ChordDark2Audiences'
          : 'ChordLight2Audiences' // uses a special theme for audiences that hides some header elements
      )
    }
  }, [slug, type, currentThemeKey])

  return { embedUserAudienceUrls, isLoading }
}

export default useEmbedUserAudienceUrl
