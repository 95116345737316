import { Button } from '@chordco/component-library'
import { CensusTestResults } from 'api/census/interfaces'
import { Nope, Yep } from 'components/common'
import React from 'react'
import styled from 'styled-components'
import { formatRelativeTime } from 'utils/dateTime'

interface LastTestResultsProps {
  lastTestedAt: string
  lastTestSucceeded: boolean
  results: CensusTestResults
  canFinishSetup: boolean
  isLoading: boolean
  handleFinishSetup: () => void
}

export const LastTestResults: React.FC<LastTestResultsProps> = ({
  lastTestedAt,
  lastTestSucceeded,
  results,
  canFinishSetup,
  isLoading,
  handleFinishSetup,
}) => {
  const hasServiceConnectivityIssues = results.steps.some(
    step =>
      step.status === 'failed' && step.step === 'Verify service connectivity'
  )

  return (
    <Wrapper>
      <Property>
        <Label>Last Test Succeeded:</Label>
        {lastTestSucceeded ? 'Yes' : '-'}
      </Property>

      <Property>
        <Label>Last Tested At:</Label>
        {lastTestedAt ? formatRelativeTime(lastTestedAt) : '-'}
      </Property>

      {results && results.steps && results.steps.length > 0 && (
        <Steps>
          <Label>Last Test Results:</Label>

          {results.steps.map(step => (
            <Property key={step.step}>
              {step.status === 'success' ? <Yep /> : <Nope />}
              <OutcomeLabel success={step.status === 'success'}>
                {step.step}
              </OutcomeLabel>
            </Property>
          ))}
        </Steps>
      )}

      {canFinishSetup && hasServiceConnectivityIssues && (
        <Button
          onClick={handleFinishSetup}
          purpose="primary"
          type="button"
          disabled={isLoading}
        >
          Edit Service
        </Button>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 24px;
  padding-bottom: 24px;
`

const Property = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
  margin-bottom: 6px;
`

const Steps = styled.div`
  margin-top: 6px;
  margin-bottom: 6px;
`

const Label = styled.span`
  font-weight: 600;
  margin-right: 8px;
`

const OutcomeLabel = styled.span<{ success: boolean }>`
  font-weight: 400;
  margin-left: 8px;
  height: 20px;
  color: ${p =>
    p.success ? p.theme.ContentAccentSuccess : p.theme.ContentAccentError};
`
