// @ts-ignore
import React from 'react'
import styled from 'styled-components'

interface Props {
  keys: string[]
  light?: boolean
}

export const Shortcut: React.FC<Props> = ({ keys, light = true }) => (
  <Flex>
    {keys.map(key => (
      <Key key={key} light={light}>
        {key}
      </Key>
    ))}
  </Flex>
)

const Flex = styled.div`
  display: flex;
`

const Key = styled.div<{ light: boolean }>`
  display: inline-block;
  box-sizing: border-box;
  height: 16px;
  padding: 2px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  color: ${p => (p.light ? p.theme.GREY_2 : p.theme.BLACK)};
  background-color: ${p =>
    p.light ? 'rgba(255, 255, 255, 0.15)' : 'rgba(26, 26, 26, 0.25)'};

  :not(:last-child) {
    margin-right: 4px;
  }
`
