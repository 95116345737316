import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const ChevronRight: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.96967 16.0303C6.67678 15.7374 6.67678 15.2626 6.96967 14.9697L11.4393 10.5L6.96967 6.03033C6.67678 5.73744 6.67678 5.26256 6.96967 4.96967C7.26256 4.67678 7.73744 4.67678 8.03033 4.96967L13.0303 9.96967C13.3232 10.2626 13.3232 10.7374 13.0303 11.0303L8.03033 16.0303C7.73744 16.3232 7.26256 16.3232 6.96967 16.0303Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
