import React from 'react'
import { Button, Label, Modal, Spinner } from '@chordco/component-library'
import { FormInput, FormTextArea } from 'components/common'
import { useForm } from 'hooks'
import styled from 'styled-components'
import { useCopyLook } from './useCopyLook'
import { AvailableUserReport } from 'types'
import { useEmbedUser } from 'redux/hooks'

interface CopyLookModalProps {
  id: string
  title: string
  description: string
  onClose: () => void
  onCopied: (look?: AvailableUserReport) => void
}

export const CopyLookModal: React.FC<CopyLookModalProps> = ({
  id,
  title,
  description,
  onClose,
  onCopied,
}) => {
  const { isLoadingEmbedUser, embedUser } = useEmbedUser()

  const { copy, isLoading } = useCopyLook(onCopied)

  const { fields, onSubmit } = useForm(
    {
      id: {
        label: 'Look ID',
        type: 'string',
        required: true,
        initialValue: id,
      },
      title: {
        label: 'Look Title',
        type: 'string',
        required: true,
        initialValue: `${title} (Copy)`,
      },
      description: {
        label: 'Look Description',
        type: 'string',
        required: false,
        initialValue: description,
      },
      folderId: {
        label: 'Folder ID',
        type: 'string',
        required: true,
        initialValue: embedUser?.personalFolderId,
      },
    },
    copy
  )

  return (
    <Modal
      onClose={onClose}
      title="Create New Look from Quickstart"
      width="480px"
    >
      <FormContainer onSubmit={onSubmit} noValidate>
        {isLoadingEmbedUser && (
          <SpinnerContainer>
            <Spinner scale={30} />
          </SpinnerContainer>
        )}

        {!isLoadingEmbedUser && embedUser && (
          <>
            <Section>
              <StyledLabel>
                Selected Quickstart: <strong>{title}</strong>
              </StyledLabel>
              <StyledLabel>
                Enter a title for your new look. A copy of this quickstart will
                be saved to your personal folder, where you can customize it to
                fit your needs.
              </StyledLabel>
              <FormInput field={fields.title} customWidth="100%" required />
              <FormTextArea field={fields.description} customWidth="100%" />
            </Section>

            <BottomSection>
              <Button purpose="tertiary" onClick={onClose}>
                Cancel
              </Button>

              <Button
                type="submit"
                purpose="primary"
                isLoading={isLoading}
                disabled={isLoading}
              >
                Create Look
              </Button>
            </BottomSection>
          </>
        )}
      </FormContainer>
    </Modal>
  )
}

const FormContainer = styled.form`
  overflow: auto;
`

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledLabel = styled(Label)`
  white-space: pre-wrap;
`

const BottomSection = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`
