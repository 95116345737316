import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const KeyResult: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5283 5.46765C18.5582 5.49727 18.5855 5.52938 18.6101 5.56364C18.6389 5.60387 18.6633 5.64623 18.6832 5.69005C18.7261 5.78453 18.75 5.88947 18.75 6V10C18.75 10.4142 18.4142 10.75 18 10.75C17.5858 10.75 17.25 10.4142 17.25 10V7.70491L11.5977 12.8862C11.311 13.149 10.8709 13.149 10.5841 12.8862L7.45455 10.0174L2.5068 14.5529C2.20146 14.8328 1.72703 14.8121 1.44714 14.5068C1.16724 14.2015 1.18787 13.727 1.49321 13.4471L6.94776 8.44714C7.2345 8.18429 7.6746 8.18429 7.96134 8.44714L11.0909 11.3159L16.0719 6.75H14C13.5858 6.75 13.25 6.41421 13.25 6C13.25 5.58579 13.5858 5.25 14 5.25H17.9997C17.9998 5.25 17.9998 5.25 17.9999 5.25H18C18.1113 5.25 18.217 5.27425 18.312 5.31775C18.3905 5.35361 18.4639 5.40363 18.5283 5.46765Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
