import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const MotivatedCustomers: React.FC<IconProps> = ({
  scale = 48,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="15" y="13.6409" width="13" height="19" rx="2" fill="#ffffff0" />
      <path
        d="M13.75 13.6409C13.75 12.3982 14.7574 11.3909 16 11.3909V9.89087C13.9289 9.89087 12.25 11.5698 12.25 13.6409V32.6409C12.25 34.7119 13.9289 36.3909 16 36.3909H21.5V34.8909H16C14.7574 34.8909 13.75 33.8835 13.75 32.6409V13.6409Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M27 9.89087H17.75V32.6409H19.25V11.3909H27C28.2426 11.3909 29.25 12.3982 29.25 13.6409V26.612L28.6582 26.1893C28.4554 26.0444 28.2339 25.9278 27.9996 25.8426L25.2467 24.8415C23.4781 24.1984 21.5184 25.0754 20.8195 26.8226C20.1047 28.6098 20.974 30.6382 22.7612 31.353L24.9463 32.2271C25.0603 32.2727 25.167 32.3349 25.2629 32.4116L26.9769 33.7828L26.6334 34.2637C25.4085 35.9786 24.75 38.0334 24.75 40.1408H26.25C26.25 38.346 26.8108 36.596 27.854 35.1355L29.0231 33.4988L26.2 31.2403C25.989 31.0715 25.7543 30.9347 25.5034 30.8344L23.3182 29.9603C22.3002 29.5531 21.805 28.3977 22.2123 27.3797C22.6104 26.3844 23.7266 25.8849 24.734 26.2512L27.487 27.2523C27.5935 27.291 27.6942 27.344 27.7864 27.4099L31.0641 29.7511L31.936 28.5305L30.75 27.6834V13.6409C30.75 11.5698 29.0711 9.89087 27 9.89087Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M35.75 27.7464C35.75 25.2431 34.4989 22.9054 32.416 21.5168L31.584 22.7649C33.2496 23.8753 34.25 25.7447 34.25 27.7464V40.1409H35.75V27.7464Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6513 16.4371C22.7829 15.5687 22.7829 14.1607 23.6513 13.2922C24.5198 12.4238 25.9278 12.4238 26.7963 13.2922C27.6647 14.1607 27.6647 15.5687 26.7963 16.4371C25.9278 17.3056 24.5198 17.3056 23.6513 16.4371ZM24.509 14.1499C24.1143 14.5447 24.1143 15.1847 24.509 15.5794C24.9038 15.9742 25.5438 15.9742 25.9386 15.5794C26.3333 15.1847 26.3333 14.5447 25.9386 14.1499C25.5438 13.7552 24.9038 13.7552 24.509 14.1499Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
