import React from 'react'
import { Button, H6, standardIcons } from '@chordco/component-library'
import { StyledCaption } from 'components/settings/integrations/requests/Common'
import styled from 'styled-components'
const { AlertTriangle, CheckCircle } = standardIcons

interface ModelAmplificationsActivationNoticeProps {
  activationButtonDisabled: boolean
  onActivationRequested: () => void
}

export function ModelAmplificationsActivationNotice({
  onActivationRequested,
  activationButtonDisabled,
}: ModelAmplificationsActivationNoticeProps) {
  return (
    <ModelAmplificationsNoticeContainer>
      <IconContainer>
        <AlertTriangle />
      </IconContainer>
      <ModelAmplificationsContent>
        <H6>Actions Required</H6>
        <StyledCaption>Fill out your data and request Activation</StyledCaption>
      </ModelAmplificationsContent>
      <ButtonContainer>
        <Button
          variant="outlined"
          purpose="tertiary"
          size="small"
          onClick={onActivationRequested}
          disabled={activationButtonDisabled}
        >
          <H6>Request Activation</H6>
        </Button>
      </ButtonContainer>
    </ModelAmplificationsNoticeContainer>
  )
}

export function ModelAmplificationsActivationConfirmation() {
  return (
    <ModelAmplificationsConfirmationContainer>
      <IconContainer>
        <CheckCircle />
      </IconContainer>
      <ModelAmplificationsContent>
        <H6>Activation request received</H6>
        <StyledCaption>
          The Chord data team is on it and will activate this configuration
          within 48hours.
        </StyledCaption>
      </ModelAmplificationsContent>
    </ModelAmplificationsConfirmationContainer>
  )
}

const ModelAmplificationsNoticeContainer = styled.div`
  position: relative;
  top: 12px;
  left: 20px;
  display: flex;
  width: 98%;
  height: 56px;
  background-color: ${p => p.theme.BgAccentLightAADarkWarning};
  border-radius: 8px;
`
const ModelAmplificationsConfirmationContainer = styled.div`
  position: relative;
  top: 12px;
  left: 20px;
  display: flex;
  width: 98%;
  height: 56px;
  background-color: ${p => p.theme.BgAccentLightAADarkSuccess};
  border-radius: 8px;
`

const ModelAmplificationsContent = styled.div`
  padding: 12px;
`

const IconContainer = styled.div`
  padding: 20px 2px 20px 15px;
`

const ButtonContainer = styled.div`
  padding: 10px;
  position: absolute;
  right: 10px;
`
