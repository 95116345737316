import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Sheet, H1 } from '@chordco/component-library'
import { useForm, useIsMobile } from 'hooks'
import { useTenantsData } from 'redux/state/tenants'
import { useAuthData } from 'redux/state/auth'
import { useCensus } from '../CensusContext'
import { CensusConnectLink, CensusConnector } from 'api/census/interfaces'
import { EnvVarConfig } from 'utils/envVarConfig'
import useCensusSlackEvent from 'hooks/useCensusSlackEvent'

type Props = {
  connector: CensusConnector
  onClose: () => void
  onConnectSuccess: (connectLink: CensusConnectLink) => void
  onConnectError: (message: string) => void
}

export const ConnectDestinationSheet: React.FC<Props> = ({
  connector,
  onClose,
  onConnectSuccess,
  onConnectError,
}) => {
  const {
    state: { user },
  } = useAuthData()

  if (!user) throw new Error('Hub User not found!')

  const {
    state: { currentTenant },
  } = useTenantsData()

  if (!currentTenant) throw new Error('Current Tenant not found!')

  const isMobile = useIsMobile()
  const size = isMobile ? 48 : 64

  const { censusClient } = useCensus()
  const { sendSlackEvent } = useCensusSlackEvent()

  const [isLoading, setIsLoading] = useState(false)

  const submit = async fields => {
    setIsLoading(true)

    try {
      const response = await censusClient?.createDestinationConnectLink(fields)

      const { status, data } = response ?? {}

      if (status === 'success' && data) {
        await sendSlackEvent('DESTINATION_CREATED', {
          tenantName: currentTenant.name,
          destinationName: connector.label,
          hubUser: user,
        })

        onConnectSuccess(data)
      } else {
        throw new Error(
          'An error occurred while creating your destination. Please try again.'
        )
      }
    } catch (error: any) {
      onConnectError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const { onSubmit } = useForm(
    {
      type: {
        type: 'string',
        label: 'Connector Type',
        required: true,
        initialValue: connector.serviceName,
      },
      redirectUri: {
        type: 'string',
        label: 'Redirect URI',
        required: true,
        initialValue: EnvVarConfig.REACT_APP_CENSUS_CONNECT_CARD_REDIRECT_URI,
      },
    },
    submit
  )

  return (
    <Sheet
      title={connector.label}
      onClose={onClose}
      isLoading={isLoading}
      isLarge
    >
      <Container isMobile={isMobile}>
        <Logo src={connector.logoSmall} alt={connector.logoSmall} size={size} />
        <H1>{connector.label}</H1>
        <Link
          href={`https://docs.getcensus.com/destinations/${connector.documentationSlug}`}
          target="_blank"
        >
          Learn more about {connector.label}
        </Link>
        <Form noValidate>
          <Button
            onClick={onSubmit}
            purpose="primary"
            type="submit"
            isLoading={isLoading}
          >
            Add {connector.label}
          </Button>
        </Form>
      </Container>
    </Sheet>
  )
}

const Container = styled.div<{ isMobile: boolean }>`
  min-height: calc(100vh - 76px);
  overflow: auto;
  padding: ${p =>
    `24px ${p.isMobile ? 12 : 24}px 100px ${p.isMobile ? 12 : 24}px`};
  position: relative;

  & > div {
    margin-top: 16px;
    margin-bottom: 16px;

    :last-child {
      margin-bottom: 0;
    }
  }
`

const Link = styled.a<{ pink?: boolean }>`
  color: ${p => (p.pink ? p.theme.ContentAccentPrimary : 'inherit')};
`

const Logo = styled.img<{ size?: number }>`
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  border-radius: 4px;
`

export const Form = styled.form`
  padding-top: 24px;
`
