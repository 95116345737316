import { useEffect } from 'react'
import { useLookerData } from 'redux/state/looker'
import { useIsLoading } from 'redux/utils'
import { LookerEmbedUser } from 'types'

export const useEmbedUser = () => {
  const {
    state: { embedUser },
    getEmbedUser,
    createEmbedUser,
  } = useLookerData()

  const isLoadingEmbedUser = useIsLoading('getEmbedUser')
  const isCreatingEmbedUser = useIsLoading('createEmbedUser')

  useEffect(() => {
    const fetchOrCreateEmbedUser = async () => {
      if (!embedUser && getEmbedUser) {
        const response = await getEmbedUser()

        const data = (response.payload as any).data as LookerEmbedUser

        // Only call createEmbedUser if no data is returned from getEmbedUser
        if (!data && createEmbedUser) {
          await createEmbedUser()
        }
      }
    }

    // Fetch or create only if embedUser is not already present
    if (!embedUser) {
      fetchOrCreateEmbedUser()
    }
  }, [embedUser])

  return {
    isLoadingEmbedUser,
    isCreatingEmbedUser,
    embedUser,
  }
}
