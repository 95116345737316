import React from 'react'
import { SectionContainer } from 'components/common'
import styled from 'styled-components'
import { formatDate, formatDateTime } from '@chordco/component-library'
import moment from 'moment'

interface Props {
  isSmall: boolean
  width?: number
  showTime?: boolean
  timeline: TimelineItem[]
}

type TimelineItem =
  | {
      id: number
      label: string
      date: number
      dateIso?: string
    }
  | {
      id: number
      label: string
      date?: number
      dateIso: string
    }

export const Timeline: React.FC<Props> = ({
  timeline,
  isSmall,
  width,
  showTime = false,
}) => {
  const dateFormatter = showTime ? formatDateTime : formatDate

  const sortedTimeline = [...timeline].sort((a, b) => {
    const aDate = moment(a.date || a.dateIso)
    const bDate = moment(b.date || b.dateIso)
    if (aDate.isBefore(bDate)) return 1
    if (aDate.isAfter(bDate)) return -1
    return 0
  })

  return (
    <>
      {sortedTimeline.map(t => (
        <Container key={t.id} isSmall={isSmall} width={width}>
          <Label
            dangerouslySetInnerHTML={{
              __html: t.label,
            }}
          />
          <Date dateTime={t.dateIso}>
            {t.date
              ? dateFormatter(moment(t.date * 1000))
              : dateFormatter(t.dateIso as string)}
          </Date>
        </Container>
      ))}
    </>
  )
}

const Container = styled(SectionContainer)<{ width?: number }>`
  height: auto;
  padding: 12px 16px;
  width: ${p => (p.width ? p.width + 'px' : 'auto')};
`

const Label = styled.div`
  max-width: calc(100% - 175px);
  white-space: pre-wrap;
`

const Date = styled.time`
  position: absolute;
  top: 12px;
  right: 16px;
  color: ${p => p.theme.ContentTertiary};
`
