import React, { useMemo } from 'react'
import moment from 'moment'
import { EnvVarConfig } from 'utils/envVarConfig'
import { Chip, standardIcons } from '@chordco/component-library'
import { AvailableUserReport } from 'types'
import {
  Card,
  CardContent,
  Header,
  StyledIcon,
  TextContainer,
  TitleBadgesContainer,
  Title,
  ChipContainer,
  Description,
} from 'components/analytics/reports/common'

type QuickstartCardProps = {
  report: AvailableUserReport
  onSelectReport: (report: AvailableUserReport) => void
}

const IS_NEW_THRESHOLD =
  parseInt(EnvVarConfig.REACT_APP_NEW_DASHBOARD_DAYS_THRESHOLD) || 3

export const QuickstartCard: React.FC<QuickstartCardProps> = ({
  report,
  onSelectReport,
}) => {
  const isNew = useMemo(
    () => moment().diff(moment(report.createdAt), 'days') <= IS_NEW_THRESHOLD,
    [report.createdAt]
  )

  return (
    <Card onClick={() => onSelectReport(report)}>
      <CardContent>
        <Header>
          <StyledIcon
            icon={
              report.metricType === 'dashboard'
                ? standardIcons.Reports
                : standardIcons.Metrics
            }
            purpose="ghost"
            size="large"
          />
          <TextContainer>
            <TitleBadgesContainer>
              <Title>{report.label}</Title>
              <ChipContainer>
                <Chip
                  text={report.folderName}
                  color="neutral"
                  variant="solid-secondary"
                />
                {isNew && <Chip text="New" color="limeGreen" variant="solid" />}
              </ChipContainer>
            </TitleBadgesContainer>

            {report.description && (
              <Description>{report.description}</Description>
            )}
          </TextContainer>
        </Header>
      </CardContent>
    </Card>
  )
}
