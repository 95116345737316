import React from 'react'
import styled from 'styled-components'
import logo from './SegmentLogo2.png'

const Icon = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border ${p => `1px solid ${p.theme.BorderAbsolute}`};
  background-color: ${p => p.theme.ReverseBackgroundBase1};
  border-radius: 100%;
`

export const SegmentNavIcon: React.FC = () => (
  <Icon src={logo} alt="Segment logo" />
)
