import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Underline: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1.75C5.41421 1.75 5.75 2.08579 5.75 2.5V8.33333C5.75 9.4605 6.19777 10.5415 6.9948 11.3385C7.79183 12.1356 8.87283 12.5833 10 12.5833C11.1272 12.5833 12.2082 12.1356 13.0052 11.3385C13.8022 10.5415 14.25 9.4605 14.25 8.33333V2.5C14.25 2.08579 14.5858 1.75 15 1.75C15.4142 1.75 15.75 2.08579 15.75 2.5V8.33333C15.75 9.85833 15.1442 11.3209 14.0659 12.3992C12.9875 13.4775 11.525 14.0833 10 14.0833C8.47501 14.0833 7.01247 13.4775 5.93414 12.3992C4.8558 11.3209 4.25 9.85833 4.25 8.33333V2.5C4.25 2.08579 4.58579 1.75 5 1.75Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5835 17.5C2.5835 17.0858 2.91928 16.75 3.3335 16.75H16.6668C17.081 16.75 17.4168 17.0858 17.4168 17.5C17.4168 17.9142 17.081 18.25 16.6668 18.25H3.3335C2.91928 18.25 2.5835 17.9142 2.5835 17.5Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
