import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Clipboard: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2.75C6.86193 2.75 6.75 2.86193 6.75 3V5C6.75 5.13807 6.86193 5.25 7 5.25H13C13.1381 5.25 13.25 5.13807 13.25 5V3C13.25 2.86193 13.1381 2.75 13 2.75H7ZM4.75 4.41669H5.25V5C5.25 5.9665 6.0335 6.75 7 6.75H13C13.9665 6.75 14.75 5.9665 14.75 5V4.41669H15.25C15.5267 4.41669 15.7852 4.52171 15.9702 4.69795C16.154 4.87299 16.25 5.10237 16.25 5.33335V17C16.25 17.231 16.154 17.4604 15.9702 17.6354C15.7852 17.8117 15.5267 17.9167 15.25 17.9167H4.75C4.4733 17.9167 4.21485 17.8117 4.0298 17.6354C3.84601 17.4604 3.75 17.231 3.75 17V5.33335C3.75 5.10237 3.84601 4.87299 4.0298 4.69795C4.21485 4.52172 4.4733 4.41669 4.75 4.41669ZM13 1.25C13.9386 1.25 14.7046 1.98887 14.7481 2.91669H15.25C15.9016 2.91669 16.5333 3.16285 17.0047 3.61174C17.4773 4.06181 17.75 4.68028 17.75 5.33335V17C17.75 17.6531 17.4773 18.2716 17.0047 18.7216C16.5333 19.1705 15.9016 19.4167 15.25 19.4167H4.75C4.09844 19.4167 3.46666 19.1705 2.99532 18.7216C2.52274 18.2716 2.25 17.6531 2.25 17V5.33335C2.25 4.68028 2.52274 4.06181 2.99532 3.61174C3.46666 3.16285 4.09845 2.91669 4.75 2.91669H5.25195C5.29543 1.98887 6.06143 1.25 7 1.25H13ZM6.75 8.5C6.33579 8.5 6 8.83579 6 9.25C6 9.66421 6.33579 10 6.75 10H13.25C13.6642 10 14 9.66421 14 9.25C14 8.83579 13.6642 8.5 13.25 8.5H6.75ZM6 12.25C6 11.8358 6.33579 11.5 6.75 11.5H13.25C13.6642 11.5 14 11.8358 14 12.25C14 12.6642 13.6642 13 13.25 13H6.75C6.33579 13 6 12.6642 6 12.25ZM6.75 14.5C6.33579 14.5 6 14.8358 6 15.25C6 15.6642 6.33579 16 6.75 16H13.25C13.6642 16 14 15.6642 14 15.25C14 14.8358 13.6642 14.5 13.25 14.5H6.75Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
