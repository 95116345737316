import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Todo: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1.25C2.92893 1.25 1.25 2.92893 1.25 5V15C1.25 17.0711 2.92893 18.75 5 18.75H15C17.0711 18.75 18.75 17.0711 18.75 15V5C18.75 2.92893 17.0711 1.25 15 1.25H5ZM2.75 5C2.75 3.75736 3.75736 2.75 5 2.75H15C16.2426 2.75 17.25 3.75736 17.25 5V15C17.25 16.2426 16.2426 17.25 15 17.25H5C3.75736 17.25 2.75 16.2426 2.75 15V5ZM14.5404 8.0201C14.8276 7.72166 14.8185 7.24688 14.5201 6.95963C14.2217 6.67239 13.7469 6.68146 13.4596 6.97989L9.1875 11.4185L7.54037 9.70717C7.25312 9.40873 6.77833 9.39966 6.4799 9.6869C6.18146 9.97415 6.17239 10.4489 6.45964 10.7474L8.64714 13.0201C8.78852 13.167 8.98362 13.25 9.1875 13.25C9.39138 13.25 9.58648 13.167 9.72787 13.0201L14.5404 8.0201Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
