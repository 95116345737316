import { createSlice } from '@reduxjs/toolkit'
import { reduxApiClient } from 'redux/api'
import {
  environmentsSlice,
  useEnvironmentsData,
} from 'redux/state/environments'
import { useTenantsData } from 'redux/state/tenants'
import {
  StoreSetupState,
  CreateIntegrationRequest,
  UpdateIntegrationRequest,
  UpdateStoreSetupRequest,
} from 'types'
import { useStateSliceAndDispatch } from '../utils'

const SLICE_NAME = 'storeSetup'

const initialState: StoreSetupState = {
  integrations: [],
  fulfillmentServices: [],
}

const {
  getStoreSetup,
  updateStoreSetup,
  deleteStoreSetup,
  createIntegration,
  updateIntegration,
  deleteIntegration,
  getFulfillmentServices,
} = reduxApiClient

export const storeSetupSlice = createSlice({
  name: 'storeSetup',
  initialState,
  reducers: {
    resetStoreSetup: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(environmentsSlice.actions.selectEnvironment, state => {
      state.integrations = initialState.integrations
      state.store = initialState.store
    })

    builder.addCase(getStoreSetup.fulfilled, (state, action) => {
      state.integrations = action.payload.data.integrations
      state.store = action.payload.data
    })

    builder.addCase(updateStoreSetup.fulfilled, (state, action) => {
      state.store = action.payload.data
    })

    builder.addCase(updateIntegration.fulfilled, (state, action) => {
      const integration = action.payload.data

      state.integrations = state.integrations.map(i => {
        if (i.id === integration.id) return { ...integration, active: true }
        if (i.category !== integration.category) return i

        return { ...i, active: false }
      })
    })

    builder.addCase(createIntegration.fulfilled, (state, action) => {
      state.integrations = state.integrations.concat(action.payload.data)
    })

    builder.addCase(getFulfillmentServices.fulfilled, (state, action) => {
      state.fulfillmentServices = action.payload.data.fulfillmentServices
    })
  },
})

export default storeSetupSlice.reducer

export const useStoreSetupData = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)

  const {
    state: { currentTenant, currentStore },
  } = useTenantsData()

  const {
    state: { selectedEnvironment },
  } = useEnvironmentsData()

  if (!currentTenant || !currentStore || !selectedEnvironment) {
    return {
      state,
      getFulfillmentServices: () => dispatch(getFulfillmentServices()),
    }
  }

  const tenantId = currentTenant.id
  const storeId = currentStore.id
  const environment = selectedEnvironment

  return {
    state,
    getStoreSetup: () =>
      dispatch(
        getStoreSetup({
          tenantId,
          storeId,
          environment,
        })
      ),
    updateStoreSetup: (
      data: Omit<UpdateStoreSetupRequest, 'environment' | 'tenantId' | 'id'>
    ) =>
      dispatch(
        updateStoreSetup({
          ...data,
          id: storeId,
          environment,
          tenantId,
        })
      ),
    deleteStoreSetup: () => dispatch(deleteStoreSetup(storeId)),
    createIntegration: (
      data: Omit<
        CreateIntegrationRequest,
        'tenantId' | 'storeId' | 'environment'
      >
    ) =>
      dispatch(createIntegration({ ...data, tenantId, storeId, environment })),
    updateIntegration: (
      data: Omit<
        UpdateIntegrationRequest,
        'tenantId' | 'storeId' | 'environment'
      >
    ) =>
      dispatch(updateIntegration({ ...data, tenantId, storeId, environment })),

    deleteIntegration: (id: number) => dispatch(deleteIntegration(id)),
    getFulfillmentServices: () => dispatch(getFulfillmentServices()),
  }
}
