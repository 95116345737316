import React, { ComponentProps, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { standardIcons } from '../icons'
import { useTheme } from '@chordco/component-library'

interface Props extends ComponentProps<'input'> {
  label?: string
  dash?: boolean
  small?: boolean
  forOption?: boolean
}

export const Checkbox: React.FC<Props> = ({
  label,
  dash = false,
  small = false,
  forOption = false,
  ...props
}) => {
  const [tabbed, setTabbed] = useState(false)
  const ref = useRef() as any

  const theme = useTheme()

  const onChange = (e: any) => {
    setTabbed(false)
    props.onChange?.call(props, e)
  }

  const onKeyDown = () => {
    setTabbed(true)
  }

  const { ThickCheck } = standardIcons

  return (
    <Label
      disabled={props.disabled}
      small={small}
      forOption={forOption}
      checked={props.checked}
    >
      <Input
        {...props}
        type="checkbox"
        dash={dash}
        ref={ref}
        onChange={onChange}
        onKeyDown={onKeyDown}
        tabbed={tabbed}
        small={small}
        forOption={forOption}
      />
      <Container small={small}>
        <CheckContainer>
          <ThickCheck scale={small ? 12 : 16} fill={theme.WHITE} />
        </CheckContainer>
      </Container>
      <div>{label}</div>
    </Label>
  )
}

const Label = styled.label<{
  disabled?: boolean
  small: boolean
  forOption: boolean
  checked?: boolean
}>`
  display: flex;
  align-items: center;
  width: ${p => (p.small ? 16 : 24)}px;
  height: ${p => (p.small ? 16 : 24)}px;
  position: relative;
  color: ${p =>
    p.disabled || !p.checked
      ? p.theme.ContentSecondary
      : p.theme.ContentPrimary};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  white-space: pre;
`

const CheckContainer = styled.div`
  opacity: 0;
  margin-top: -2px;
`

const Container = styled.span<{ small: boolean }>`
  box-sizing: border-box;
  position: absolute;
  width: ${p => (p.small ? 16 : 24)}px;
  height: ${p => (p.small ? 16 : 24)}px;
  border: 1px solid ${p => p.theme.BorderAbsolute};
  border-radius: 4px;
  padding: ${p => (p.small ? '1px' : '4px')};
  transition: 100ms;
`

const Input = styled.input<{
  dash?: boolean
  tabbed?: boolean
  small: boolean
  forOption: boolean
}>`
  opacity: 0;
  margin-right: ${p => (p.small ? 8 : 16)}px;
  outline: none;

  svg {
    display: none;
  }

  :focus + ${Container}, :hover + ${Container} {
    border-color: ${p => p.theme.BorderAccentPrimary};
  }

  :focus + ${Container} {
    outline-color: ${p => p.theme.BgAccentDarkAALightPrimary};
    outline-style: solid;
    outline-offset: 3px;
  }

  :checked + ${Container} {
    border-color: ${p => p.theme.BgAccentDarkAALightPrimary};
    background-color: ${p => p.theme.BgAccentDarkAALightPrimary};

    svg {
      display: ${p => (p.dash ? 'none' : 'initial')};
    }

    ${CheckContainer} {
      opacity: 1;
    }

    ${p =>
      p.dash &&
      css`
        ::before {
          position: absolute;
          top: 10.5px;
          left: 4px;
          height: 2px;
          width: 14px;
          background-color: ${p => p.theme.WHITE};
          content: '';
          border-radius: 2px;

          ${p.small &&
          css`
            width: 10px;
            height: 2px;
            border-radius: 1.5px;
            top: 6px;
            left: 1.25px;
          `}
        }
      `}
  }

  :disabled + ${Container} {
    border-color: ${p => p.theme.ContentDisabled};
  }

  ${p =>
    p.forOption &&
    css`
      margin-right: 12px;
    `}
`
