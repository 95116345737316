import React, { useRef, useState } from 'react'
import { standardIcons } from '../icons'
import { Button, Icon } from '../controls'
import { Input } from './Input'
import styled from 'styled-components'

interface Props {
  value: string
  onChange: Function
  placeholder?: string
}

export const SearchInput: React.FC<Props> = ({
  value,
  onChange,
  placeholder,
  ...props
}) => {
  const [open, setOpen]: [boolean, any] = useState(false)
  const [show, setShow]: [boolean, any] = useState(false)
  const inputRef = useRef(null)

  const openInput = () => {
    setOpen(true)
    setTimeout(() => setShow(true), 0)
  }

  const closeInput = () => {
    onChange('')
    setShow(false)
    setTimeout(() => setOpen(false), 100)
  }

  const onBlur = () => {
    if (!value?.length) {
      closeInput()
    }
  }

  return (
    <>
      {open ? (
        <InputWrapper>
          <StyledInput
            {...props}
            inputRef={inputRef}
            leftIcon={standardIcons.Search}
            value={value}
            onChange={(e: any) => onChange(e.target.value)}
            placeholder={placeholder || 'Type to search'}
            show={show}
            onBlur={onBlur}
            onKeyDown={e => e.stopPropagation()}
            customWidth="auto"
            focus
          />
          {value !== '' && (
            <ClearIcon
              icon={standardIcons.Exit}
              purpose="ghost"
              size="tiny"
              onClick={closeInput}
            />
          )}
        </InputWrapper>
      ) : (
        <Button
          onClick={openInput}
          icon={standardIcons.Search}
          purpose="ghost"
          size="small"
        >
          Search
        </Button>
      )}
    </>
  )
}

const InputWrapper = styled.div`
  position: relative;

  path {
    fill: ${p => p.theme.ComponentFieldBorderDefault};
  }
`

const StyledInput = styled(Input)<{ show: boolean }>`
  transition: 100ms;
  height: 32px;
  width: ${p => (p.show ? '184px' : '87px')};
  border: none;
  background-color: ${p => p.theme.ComponentFieldBackgroundDefault};
`

const ClearIcon = styled(Icon)`
  position: absolute;
  top: 4px;
  right: 4px;

  path {
    fill: ${p => p.theme.ContentPrimary};
  }
`
