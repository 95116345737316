import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

interface Props extends IconProps {
  size?: 'small' | 'medium' | 'large'
}

const sizes = {
  small: {
    width: 21,
    height: 24,
  },
  medium: {
    width: 29,
    height: 43,
  },
  large: {
    width: 33,
    height: 50,
  },
}

export const ChordLogo: React.FC<Props> = ({
  fill,
  size = 'large',
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 60 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0862 17.6878C10.0783 25.5927 10.0743 38.4073 18.0862 46.3082C26.0981 54.2092 39.0862 54.2132 47.0941 46.3082M7.17594 28.4524C-0.835989 36.3573 -0.835989 49.1719 7.17594 57.0728C15.1879 64.9737 28.1759 64.9777 36.1838 57.0728L58.0003 35.5476C49.9884 43.4526 37.0003 43.4526 28.9925 35.5476C22.9128 29.5461 21.4465 20.7147 24.599 13.3521C32.0611 10.2413 41.0116 11.6865 47.0941 17.6878L58.0003 6.9272C49.9924 -0.977726 37.0044 -0.973738 28.9925 6.9272L7.17594 28.4524Z"
        stroke={fill || theme.OFF_TEXT_BASE}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
