import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Upload: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()
  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5412 7.22473C10.5231 7.20645 10.504 7.18908 10.4841 7.1727C10.4312 7.12924 10.3738 7.09398 10.3135 7.06692C10.2179 7.02394 10.1117 7 9.99987 7C9.99753 7 9.99518 7.00002 9.99284 7.00004C9.90358 7.00084 9.81443 7.01714 9.72987 7.04892C9.63278 7.08542 9.54174 7.14234 9.46353 7.21968L6.09274 10.553C5.79656 10.8459 5.79656 11.3208 6.09274 11.6137C6.38893 11.9066 6.86914 11.9066 7.16532 11.6137L9.24144 9.56062V17.25C9.24144 17.6642 9.581 18 9.99987 18C10.4187 18 10.7583 17.6642 10.7583 17.25V9.56071L12.8343 11.6137C13.1305 11.9066 13.6107 11.9066 13.9069 11.6137C14.2031 11.3208 14.2031 10.8459 13.9069 10.553L10.5412 7.22473Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M16.4831 10.25C16.4831 10.6642 16.8227 11 17.2416 11C17.6604 11 18 10.6642 18 10.25V4.41667C18 3.77573 17.7425 3.16104 17.2842 2.70783C16.8259 2.25461 16.2043 2 15.5562 2L4.44382 2C3.79568 2 3.17408 2.25461 2.71578 2.70783C2.25747 3.16104 2 3.77573 2 4.41667L2 10.25C2 10.6642 2.33956 11 2.75843 11C3.17729 11 3.51685 10.6642 3.51685 10.25L3.51685 4.41667C3.51685 4.17356 3.61452 3.9404 3.78836 3.76849C3.9622 3.59658 4.19797 3.5 4.44382 3.5H15.5562C15.802 3.5 16.0378 3.59658 16.2116 3.76849C16.3855 3.9404 16.4831 4.17356 16.4831 4.41667L16.4831 10.25Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
