import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { useIsMobile } from 'hooks'
import {
  Button,
  H4,
  Spinner,
  standardIcons,
  useTheme,
} from '@chordco/component-library'
import { useNotifications } from 'redux/state/notifications'
import { v4 as generateUUID } from 'uuid'
import { useCensus } from '../CensusContext'

import { AddDestinationSheet } from '../sheets'
import { ConnectedDestinations } from '../components'

import { actions, reducer, initialState } from '../store'
import { useAuthData } from 'redux/state/auth'
import {
  CensusConnectLink,
  CensusDestination,
  CensusSync,
} from 'api/census/interfaces'
import { EditDestinationSheet } from '../sheets/EditDestinationSheet'
import { Syncs, SyncsRef } from './Syncs'
import SyncEditSchedule from './SyncEditSchedule'

const { CloseX } = standardIcons

export const MyDestinations: React.FC = () => {
  const {
    state: { user },
  } = useAuthData()

  const theme = useTheme()
  const { censusClient } = useCensus()

  const [state, dispatch] = useReducer(reducer, initialState)

  const isMobile = useIsMobile()

  const { addNotification } = useNotifications()

  const [showAddDestinations, setShowAddDestinations] = useState(false)
  const [showSyncs, setShowSyncs] = useState(false)
  const [editDestination, setEditDestination] = useState<CensusDestination>()
  const [editSchedule, setEditSchedule] = useState<CensusSync | null>(null)

  const fetchDestinations = useCallback(
    async (page: number) => {
      dispatch(actions.fetchStart())

      try {
        const response = await censusClient?.getDestinations(page)

        if (response) {
          const { data, pagination } = response
          const connectedLinks = data ?? []

          dispatch(
            actions.fetchSuccess(
              connectedLinks,
              pagination.page,
              pagination.nextPage,
              pagination.prevPage
            )
          )
        } else {
          dispatch(actions.fetchError(new Error('No data returned')))
        }
      } catch (error: any) {
        dispatch(actions.fetchError(error))
      }
    },
    [censusClient]
  )

  const refreshAndFetch = useCallback(() => {
    const fetchData = async () => {
      try {
        dispatch(actions.refresh())
        await fetchDestinations(1)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error in refreshAndFetch:', error)
      }
    }

    fetchData()
  }, [dispatch, actions.refresh, fetchDestinations])

  const toggleDestinationBrowser = () =>
    setShowAddDestinations(!showAddDestinations)

  const toggleCreateSyncs = () => setShowSyncs(!showSyncs)

  const handleDeleteOrSyncSuccess = (message: string) => {
    addNotification({
      id: generateUUID(),
      type: 'success',
      message,
    })

    setEditDestination(undefined)

    setTimeout(() => refreshAndFetch(), 2500)
  }

  const handleModalError = (message: string) => {
    addNotification({
      id: generateUUID(),
      type: 'warning',
      message: message,
    })
  }

  const handleFinishSetupSuccess = (connectLink: CensusConnectLink) => {
    // Start the Connect with Census external flow
    window.open(connectLink.uri, '_blank', 'noreferrer')
  }

  const handleUpdateSchedule = async (sync: CensusSync) => {
    setEditSchedule(sync)
  }

  const handleEditedSchedule = (_sync: CensusSync) => {
    setEditSchedule(null)

    if (syncsRef.current) {
      syncsRef.current.refreshAndFetch()
    }

    addNotification({
      id: generateUUID(),
      type: 'success',
      message: 'Sync schedule has been successfully updated',
    })
  }

  const handleEditScheduleError = (message: string) => {
    setEditSchedule(null)

    addNotification({
      id: generateUUID(),
      type: 'warning',
      message: message,
    })
  }

  /**
   * Fetch initial page of connected destinations links on mount
   */
  useEffect(() => {
    fetchDestinations(1)
  }, [])

  /**
   * Fetch destinations for the next page
   */
  const handleNextPage = useCallback(() => {
    if (state.nextPage) {
      fetchDestinations(state.nextPage)
    }
  }, [state.nextPage, fetchDestinations])

  /**
   * Fetch destinations for the previous page
   */
  const handlePrevPage = useCallback(() => {
    if (state.previousPage) {
      fetchDestinations(state.previousPage)
    }
  }, [state.previousPage, fetchDestinations])

  const showAddDestinationsButton =
    user?.role === 'admin' || user?.role === 'superuser'

  const syncsRef = useRef<SyncsRef>(null)

  return (
    <Container isMobile={isMobile}>
      {showAddDestinations && (
        <AddDestinationSheet onClose={toggleDestinationBrowser} />
      )}

      {showSyncs && (
        <Syncs
          ref={syncsRef}
          onClose={toggleCreateSyncs}
          onEditSchedule={handleUpdateSchedule}
        />
      )}

      {editDestination && (
        <EditDestinationSheet
          destination={editDestination}
          onClose={() => setEditDestination(undefined)}
          onDeleteSuccess={handleDeleteOrSyncSuccess}
          onFinishSetupSuccess={handleFinishSetupSuccess}
          onError={handleModalError}
        />
      )}

      {editSchedule && (
        <SyncEditSchedule
          sync={editSchedule}
          onScheduleEdited={handleEditedSchedule}
          onScheduleError={handleEditScheduleError}
          onClose={() => setEditSchedule(null)}
        />
      )}

      <Header>
        <MySourcesContainer>
          <H4>My Data Destinations</H4>
          <Button
            type="button"
            purpose="ghost"
            onClick={refreshAndFetch}
            icon={standardIcons.Refresh}
          >
            Refresh
          </Button>
        </MySourcesContainer>

        <ButtonsContainer>
          <Button
            type="button"
            onClick={toggleCreateSyncs}
            icon={standardIcons.Sync}
            variant="outlined"
          >
            Manage Syncs
          </Button>

          {showAddDestinationsButton && (
            <Button
              type="button"
              onClick={toggleDestinationBrowser}
              icon={standardIcons.Plus}
            >
              Add Destination
            </Button>
          )}
        </ButtonsContainer>
      </Header>

      {state.loading && (
        <LoaderContainer>
          <Spinner scale={30} />
        </LoaderContainer>
      )}

      {!state.loading && (
        <>
          {state.items && state.items.length > 0 ? (
            <ConnectedDestinations
              destinations={state.items as CensusDestination[]}
              onSelectDestination={setEditDestination}
              onPrevPage={handlePrevPage}
              onNextPage={handleNextPage}
              showNextPageButton={state.nextPage !== null}
              showPreviousPageButton={state.previousPage !== null}
            />
          ) : (
            <NoSources>No destinations found</NoSources>
          )}
        </>
      )}

      {state.error && (
        <ErrorContainer>
          <div>An error occurred while fetching your destinations.</div>
          <span onClick={() => dispatch(actions.fetchError(undefined))}>
            <CloseX fill={theme.GREY_80} />
          </span>
        </ErrorContainer>
      )}
    </Container>
  )
}

const Container = styled.div<{ isMobile: boolean }>`
  padding: ${p => (p.isMobile ? 12 : 24)}px;
  overflow: auto;
  padding: 0;
  position: relative;

  & > div {
    margin-bottom: 16px;

    :last-child {
      margin-bottom: 0;
    }
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
`

const MySourcesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin-left: 1rem;
  }
`

const LoaderContainer = styled.div`
  position: relative;
  height: 200px;
  width: 100%;
`

const ErrorContainer = styled.div`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${p => p.theme.RED_30};
  border-radius: 8px;
  color: ${p => p.theme.GREY_80};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 12px;

  & > span {
    cursor: pointer;
  }
`

const NoSources = styled.div`
  width: 100%;
  border-radius: 8px;
  background: ${p => p.theme.GREY_30};
  padding: 12px;
  font-size: 12px;
  color: ${p => p.theme.GREY_80};
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`
