import React from 'react'
import { Chip, ChipColor, standardIcons } from '@chordco/component-library'
import styled from 'styled-components'

type Props = {
  healthy: boolean
  iconOnly?: boolean
}

export const HealthChip: React.FC<Props> = ({
  healthy = false,
  iconOnly = false,
}) => {
  const getChipSetupText = (healthy: boolean): string => {
    return healthy ? 'Healthy' : 'Broken'
  }

  const getChipSetupColor = (healthy: boolean): ChipColor => {
    return healthy ? 'success' : 'error'
  }

  const getChipIcon = (healthy: boolean) => {
    return healthy ? standardIcons.Check : standardIcons.CloseX
  }

  return (
    <ChipContainer>
      <Chip
        leftIcon={getChipIcon(healthy)}
        leftIconSize={12}
        text={iconOnly ? '' : getChipSetupText(healthy)}
        color={getChipSetupColor(healthy)}
        variant="solid-secondary"
      />
    </ChipContainer>
  )
}

const ChipContainer = styled.div`
  svg {
    path {
      fill: ${p => p.theme.BLACK};
    }
  }
`
