import humps from 'humps'
import { useEffect, useState } from 'react'
import moment from 'moment'

export const getFirstPathSegment = () => window.location.pathname.split('/')[1]

export const getQueryStringValues = (
  search: string,
  camelizeKeys?: boolean
) => {
  let values: { [key: string]: string } = {}
  decodeURIComponent(search)
    .substring(1)
    .split('&')
    .forEach(keyValue => {
      const [key, value] = keyValue.split('=')
      values[key] = value
    })

  if (camelizeKeys) {
    values = humps.camelizeKeys(values)
  }
  return values
}

// this function is used to map over a series of actions when they need to be executed sequentially
export const mapSeries = async (iterable, action) => {
  for (const x of iterable) {
    await action(x)
  }
}

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    // update value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    // cancel timeout if value or delay change
    return () => clearTimeout(handler)
  }, [value, delay])

  return debouncedValue
}

export const removeTrailingSlash = (s: string) =>
  s.endsWith('/') ? s.slice(0, -1) : s

export const windowOpen = (url: string, specs: string) => {
  if (!url.match(/^https?:\/\//i)) {
    url = 'https://' + url
  }
  return window.open(url, specs)
}

export const as = <O>(object: object): O => object as unknown as O

type SortFn<SortType> = <T>(
  items: T[],
  accessor: (item: T) => SortType,
  isAscending: boolean
) => T[]

export const sortStrings: SortFn<string> = (items, accessor, isAscending) =>
  items.slice().sort((aItem, bItem) => {
    const a = accessor(aItem)
    const b = accessor(bItem)

    if (a.toLowerCase() < b.toLowerCase()) return isAscending ? -1 : 1
    if (a.toLowerCase() > b.toLowerCase()) return isAscending ? 1 : -1
    return 0
  })

export const sortDates: SortFn<moment.Moment | null> = (
  items,
  accessor,
  isAscending
) =>
  items.slice().sort((aItem, bItem) => {
    const a = accessor(aItem)
    const b = accessor(bItem)

    if (!a && !b) return 0
    if (!a) return isAscending ? -1 : 1
    if (!b) return isAscending ? 1 : -1

    if (a.isBefore(b)) return isAscending ? -1 : 1
    if (a.isAfter(b)) return isAscending ? 1 : -1
    return 0
  })

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const sortBooleans: SortFn<boolean> = (items, accessor, isAscending) =>
  items.slice().sort((aItem, bItem) => {
    const a = accessor(aItem)
    const b = accessor(bItem)

    if (a === b) return 0
    if (a) return isAscending ? 1 : -1
    return isAscending ? -1 : 1
  })

export const findStringOverlap = (a: string, b: string) => {
  if (b.length === 0) {
    return ''
  }

  if (a.endsWith(b)) {
    return b
  }

  if (a.indexOf(b) >= 0) {
    return b
  }

  return findStringOverlap(a, b.substring(0, b.length - 1))
}
