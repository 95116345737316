import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Minus: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.5"
        y="9.25"
        width="11"
        height="1.5"
        rx="0.75"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
