import React, { useRef } from 'react'
import { MobileHeader } from './MobileHeader'
import styled from 'styled-components'
import { Content } from './Content'
import { Avatar, Button, Icon, standardIcons } from '@chordco/component-library'
import { AddPhoto } from '../common'
import { useAuthData } from 'redux/state/auth'
import { Tenant as TenantType, User } from 'types'
import { Environment } from './Environment'
import { Tenant } from './Tenant'
import { InverseThemeProvider } from 'themes/ThemeSwitcherProvider'

interface Props {
  user: User
  navOpen: boolean
  setNavOpen: Function
  tenants: TenantType[]
  currentTenant: TenantType | undefined
  selectTenant: (tenantId: number) => void
}

export const MobileNavigation: React.FC<Props> = ({
  user,
  navOpen,
  setNavOpen,
  tenants,
  currentTenant,
  selectTenant,
}) => {
  const ref = useRef() as any
  const { logout } = useAuthData()

  const { Gear, LogOut } = standardIcons

  const closeNav = () => {
    setNavOpen(false)
    ref.current?.scrollTo(0, 0)
  }

  const showTenantSwitcher = !!(currentTenant && tenants?.length > 0)

  return (
    <InverseThemeProvider>
      <MobileNav navOpen={navOpen} ref={ref}>
        <MobileHeader setNavOpen={setNavOpen} />
        {showTenantSwitcher && (
          <Tenant
            tenants={tenants}
            currentTenant={currentTenant}
            selectTenant={selectTenant}
          />
        )}
        <Environment />
        <Content isMobile closeNav={closeNav} />
        <Container>
          {user.company?.photo ? (
            <Avatar logo src={user.company?.photo} size={40} />
          ) : (
            <AddPhoto />
          )}
          <CompanyName>{user.company?.name}</CompanyName>
        </Container>
        <Container>
          <div>
            {user.firstName} {user.lastName}
          </div>
          <Email>{user.email}</Email>
          <SettingsButton icon={Gear} purpose="ghost" />
        </Container>
        <Container>
          <LogOutContainer>
            <Button purpose="ghost" icon={LogOut} onClick={logout}>
              Log out
            </Button>
          </LogOutContainer>
        </Container>
      </MobileNav>
    </InverseThemeProvider>
  )
}

const MobileNav = styled.div<{ navOpen: boolean }>`
  height: ${p => (p.navOpen ? '100vh' : '48px')};
  background-color: ${p => p.theme.ComponentNavBgMainNav};
  color: ${p => p.theme.ContentPrimary};
  overflow: ${p => (p.navOpen ? 'auto' : 'hidden')};
  transition: 300ms;
`

const Container = styled.div`
  position: relative;
  padding: 12px 16px;
  border-top: solid 1px ${p => p.theme.BorderMinimal};
`

const CompanyName = styled.div`
  position: absolute;
  top: 24px;
  left: 74px;
  font-weight: 600;
`

const Email = styled.div`
  margin-top: 4px;
  font-size: 12px;
  color: ${p => p.theme.GREY_3};
`

const SettingsButton = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 16px;
`

const LogOutContainer = styled.div`
  button {
    width: calc(100vw - 32px);
    text-align: left;
  }
`
