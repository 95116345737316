import { useLookerData } from 'redux/state/looker'
import { AvailableUserReport } from 'types'
import { useState } from 'react'

interface CopyLookData {
  id: string
  title: string
  description: string
  folderId: string
}

interface CopyLookResponse {
  error?: string
  payload: {
    data: {
      explore: AvailableUserReport | undefined
    }
  }
}

export const useCopyLook = (
  onCopied: (explore?: AvailableUserReport) => void
) => {
  const { copyLook, getAvailableUserReports } = useLookerData()

  const [isLoading, setIsLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const [hasSucceeded, setHasSucceeded] = useState(false)

  const copy = async ({ id, title, description, folderId }: CopyLookData) => {
    if (!copyLook) return
    if (!getAvailableUserReports) return

    setIsLoading(true)
    setHasSucceeded(false)
    setHasFailed(false)

    try {
      const response = (await copyLook(
        id,
        title,
        description,
        folderId
      )) as unknown as CopyLookResponse

      if (!response.error) {
        await getAvailableUserReports()
        setHasSucceeded(true)
        onCopied(response.payload.data.explore)
      } else {
        setHasFailed(true)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error: ', error)
      setHasFailed(true)
    } finally {
      setIsLoading(false)
    }
  }

  return { copy, isLoading, hasSucceeded, hasFailed }
}
