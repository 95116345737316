import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { reduxApiClient } from 'redux/api'
import { useStateSliceAndDispatch } from 'redux/utils'
import type { Health, SystemHealthState } from 'types/systemHealth'

const SLICE_NAME = 'systemHealth'

const initialState: SystemHealthState = {
  contentStoreInsights: undefined,
  dataStoreInsights: undefined,
  onlineStoreInsights: undefined,
  orderStoreInsights: undefined,
  buildStatus: undefined,
  deliveryMetrics: undefined,
  eventVolume: undefined,
  stripeWebhooks: undefined,
  health: {
    content: true,
    data: true,
    webHost: true,
    payment: true,
    buildStatus: true,
  },
}

const {
  getContentStoreInsights,
  getDataStoreInsights,
  getOnlineStoreInsights,
  getOrderStoreInsights,
  getBuildStatus,
  getDeliveryMetrics,
  getEventVolume,
  getStripeWebhooks,
} = reduxApiClient

const systemHealthSlice = createSlice({
  name: 'systemHealth',
  initialState,
  reducers: {
    updateHealth: (state, action: PayloadAction<Partial<Health>>) => {
      const health = action.payload
      state.health = { ...state.health, ...health }
    },
  },
  extraReducers: builder => {
    builder.addCase(getContentStoreInsights.fulfilled, (state, action) => {
      state.contentStoreInsights = action.payload.data

      if (!action.payload.data.webhooks) return

      const allWebhooksActive = action.payload.data.webhooks.every(
        w => w.active
      )
      const { total, healthy } = action.payload.data.webhooks.reduce(
        (acc, cur) => ({
          total: acc.total + (cur.calls?.total || 0),
          healthy: acc.healthy + (cur.calls?.healthy || 0),
        }),
        {
          total: 0,
          healthy: 0,
        }
      )
      const healthyCallPercentage = total === 0 || healthy / total > 0.75

      state.health.content = allWebhooksActive && healthyCallPercentage
    })

    builder.addCase(getDataStoreInsights.fulfilled, (state, action) => {
      state.dataStoreInsights = action.payload.data

      if (Object.keys(action.payload.data).length) {
        const allSourcesEnabled = action.payload.data.sources.every(
          s => s.enabled
        )
        if (!allSourcesEnabled) state.health.data = false
      }
    })

    builder.addCase(getOrderStoreInsights.fulfilled, (state, action) => {
      state.orderStoreInsights = action.payload.data
    })

    builder.addCase(getOnlineStoreInsights.fulfilled, (state, action) => {
      state.onlineStoreInsights = action.payload.data
    })

    builder.addCase(getBuildStatus.fulfilled, (state, action) => {
      state.buildStatus = action.payload.data
      state.health.buildStatus = action.payload.data.state !== 'error'
    })

    builder.addCase(getDeliveryMetrics.fulfilled, (state, action) => {
      state.deliveryMetrics = action.payload.data.deliveryMetrics

      const eventDeliveryFailures = action.payload.data.deliveryMetrics?.reduce(
        (acc, cur) => acc + cur.undeliveredEventCount,
        0
      )
      if (eventDeliveryFailures) state.health.data = false
    })

    builder.addCase(getEventVolume.fulfilled, (state, action) => {
      state.eventVolume = action.payload.data.eventVolume

      const hasResultWithNoEvents =
        action.payload.data.eventVolume?.results.some(r => r.total === 0)
      if (hasResultWithNoEvents) state.health.data = false
    })

    builder.addCase(getStripeWebhooks.fulfilled, (state, action) => {
      state.stripeWebhooks = action.payload.data

      const allWebhooksEnabled = action.payload.data?.every(
        w => w.status === 'enabled'
      )
      if (!allWebhooksEnabled) state.health.payment = false
    })
  },
})

export default systemHealthSlice.reducer

export const useSystemHealthData = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)

  return {
    state,
    getContentStoreInsights: (stackId: number) =>
      dispatch(getContentStoreInsights(stackId)),
    getDataStoreInsights: (stackId: number) =>
      dispatch(getDataStoreInsights(stackId)),
    getOnlineStoreInsights: (stackId: number) =>
      dispatch(getOnlineStoreInsights(stackId)),
    getOrderStoreInsights: (stackId: number) =>
      dispatch(getOrderStoreInsights(stackId)),
    getBuildStatus: (stackId: number) => dispatch(getBuildStatus(stackId)),
    getDeliveryMetrics: (stackId: number) =>
      dispatch(getDeliveryMetrics(stackId)),
    getEventVolume: (stackId: number) => dispatch(getEventVolume(stackId)),
    getStripeWebhooks: (tenantId: number, tenantName: string) =>
      dispatch(getStripeWebhooks({ tenantId, tenantName })),
    updateHealth: (health: Partial<Health>) =>
      dispatch({ type: 'systemHealth/updateHealth', payload: health }),
  }
}
