import React, { useRef } from 'react'
import styled from 'styled-components'
import { useResponsiveWidth } from 'hooks'
import { FivetranConnector } from 'api/fivetran/interfaces'
import { SourcePaginator } from './SourcePaginator'
import { SourceCard } from './SourceCard'
import { getGridTemplateColumns } from 'components/home/styles'
import { useFivetran } from '../FivetranContext'

type Props = {
  sources: FivetranConnector[]
  onSelectSource: (connector: FivetranConnector) => void
  onPrevPage: () => void
  onNextPage: () => void
  showNextPageButton: boolean
  showPreviousPageButton: boolean
}

export const SourceBrowser: React.FC<Props> = ({
  sources,
  onSelectSource,
  onPrevPage,
  onNextPage,
  showNextPageButton,
  showPreviousPageButton,
}) => {
  const ref = useRef() as any
  const width = useResponsiveWidth(ref)
  const gridTemplateColumns = getGridTemplateColumns(width)
  const { isModeled } = useFivetran()

  const paginatorProps = {
    onPrevPage: onPrevPage,
    onNextPage: onNextPage,
    showNextPage: showNextPageButton,
    showPrevPage: showPreviousPageButton,
  }

  return (
    <>
      <SourcePaginator {...paginatorProps} />

      <SourceContainer ref={ref} gridTemplateColumns={gridTemplateColumns}>
        {sources.map(connector => (
          <SourceCard
            key={connector.id}
            source={connector}
            onSelectSource={onSelectSource}
            modeled={isModeled(connector.id)}
          />
        ))}
      </SourceContainer>

      <SourcePaginator {...paginatorProps} />
    </>
  )
}

const SourceContainer = styled.div<{ gridTemplateColumns: string }>`
  display: grid;
  grid-template-columns: ${p => p.gridTemplateColumns};
  grid-gap: 12px;
`
