import React, { cloneElement, useRef, useState } from 'react'
import styled from 'styled-components'
import { SelectableDropdownInput } from './SelectableDropdownInput'
import { OptionProps } from '../dropdown'
import { useOutsideClick } from '../hooks'
import { ChipColor } from '../controls'

interface Props {
  control: any
  filters: {
    id: number
    label: string
    chipColor?: ChipColor
    placeholder?: string
    options: OptionProps[]
    selectedIds: (string | number)[]
    setSelectedIds: Function
  }[]
}

export const Filter: React.FC<Props> = ({ control, filters }) => {
  const [open, setOpen]: [boolean, any] = useState(false)
  const controlRef = useRef(null)

  const containerRef = useRef(null)
  useOutsideClick(open, containerRef, () => setOpen(false))

  return (
    <RelativeContainer>
      <ControlContainer ref={controlRef}>
        {cloneElement(control, {
          onClick: () => setOpen(!open),
          selected: open,
        })}
      </ControlContainer>
      {open && (
        <Container ref={containerRef}>
          {filters.map(
            ({
              id,
              label,
              options,
              selectedIds,
              setSelectedIds,
              chipColor,
            }) => (
              <SelectableContainer key={id}>
                <Label>{label}</Label>
                <SelectableDropdownInput
                  options={options}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                  chipColor={chipColor}
                />
              </SelectableContainer>
            )
          )}
        </Container>
      )}
    </RelativeContainer>
  )
}

const RelativeContainer = styled.div`
  position: relative;
`

const ControlContainer = styled.div`
  width: fit-content;
`

const Container = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  box-sizing: border-box;
  width: 480px;
  z-index: 1;
  background: ${p => p.theme.WHITE};
  border-radius: 8px;
  padding: 16px 16px 16px 24px;
`

const SelectableContainer = styled(RelativeContainer)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`

const Label = styled.span`
  margin-top: 14px;
  font-weight: 500;
`
