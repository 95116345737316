import { apiClient, ApiClient } from 'api'
import { combineReducers } from '@reduxjs/toolkit'
import type { AsyncThunk, Reducer } from '@reduxjs/toolkit'
import { createAsyncMethodSlice, MethodState } from 'redux/utils'
import { failureMessages, successMessages } from 'redux/api/messages'

export type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T

export type AwaitedReturnType<T extends (...args: any) => any> = Awaited<
  ReturnType<T>
>

type ReduxApiClient = {
  [Key in keyof ApiClient]: AsyncThunk<
    { data: AwaitedReturnType<ApiClient[Key]> },
    Parameters<ApiClient[Key]>[0],
    {}
  >
}

type Methods = {
  [Key in keyof ApiClient]: Reducer<MethodState>
}

const buildClient = () => {
  return Object.keys(apiClient).reduce<{
    client: ReduxApiClient
    methods: Methods
  }>((acc, key) => {
    const { thunk, slice } = createAsyncMethodSlice(
      'methods',
      apiClient[key],
      successMessages[key],
      failureMessages[key]
    )

    const client = { ...acc.client }
    client[key] = thunk

    const methods = { ...acc.methods }
    methods[key] = slice.reducer

    return { client, methods }
  }, {} as any)
}

const { client, methods } = buildClient()

export const reduxApiClient = client
export const methodsReducer = combineReducers({ ...methods })
