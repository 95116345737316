import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Search: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16667 3.25C5.89898 3.25 3.25 5.89898 3.25 9.16667C3.25 12.4344 5.89898 15.0833 9.16667 15.0833C12.4344 15.0833 15.0833 12.4344 15.0833 9.16667C15.0833 5.89898 12.4344 3.25 9.16667 3.25ZM1.75 9.16667C1.75 5.07055 5.07055 1.75 9.16667 1.75C13.2628 1.75 16.5833 5.07055 16.5833 9.16667C16.5833 13.2628 13.2628 16.5833 9.16667 16.5833C5.07055 16.5833 1.75 13.2628 1.75 9.16667Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3447 13.3447C13.6376 13.0518 14.1124 13.0518 14.4053 13.3447L18.0303 16.9697C18.3232 17.2626 18.3232 17.7374 18.0303 18.0303C17.7374 18.3232 17.2626 18.3232 16.9697 18.0303L13.3447 14.4053C13.0518 14.1124 13.0518 13.6376 13.3447 13.3447Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
