import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const CornerLeftDown: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8333 2.58331C9.75037 2.58331 8.71175 3.01352 7.94598 3.77929C7.18021 4.54507 6.75 5.58368 6.75 6.66665V14.8558L3.86383 11.9697C3.57093 11.6768 3.09606 11.6768 2.80317 11.9697C2.51027 12.2626 2.51027 12.7374 2.80317 13.0303L6.96109 17.1883C7.0889 17.3203 7.2648 17.4055 7.46055 17.4156C7.50152 17.4178 7.54263 17.4166 7.58345 17.4121C7.62055 17.4079 7.65683 17.4011 7.6921 17.3918C7.81607 17.3591 7.9333 17.2942 8.03049 17.197L12.1972 13.0303C12.4901 12.7374 12.4901 12.2626 12.1972 11.9697C11.9043 11.6768 11.4294 11.6768 11.1365 11.9697L8.25 14.8562V6.66665C8.25 5.9815 8.52217 5.32442 9.00664 4.83995C9.49111 4.35548 10.1482 4.08331 10.8333 4.08331H16.6667C17.0809 4.08331 17.4167 3.74753 17.4167 3.33331C17.4167 2.9191 17.0809 2.58331 16.6667 2.58331H10.8333Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
