import { Plan, StoreEnvironment } from './tenants'

export type IntegrationStatus = 'required' | 'enabled' | 'disabled'

export type UpdateStoreSetupRequest = StoreSettings & { tenantId: number }

export type IntegrationType =
  | 'stripe'
  | 'contentful'
  | 'magic'
  | 'stytch'
  | 'segment'
  | 'netlify'
  | 'capacity'
  | 'easypost'
  | 'narvar'
  | 'order_desk'
  | 'ship_hero'
  | 'shippo'
  | 'shipstation'
  | 'referrals'
  | 'shipping'
  | 'subscriptions'
  | 'composer'
  | 'fivetran'
  | 'census'

export type CreateIntegrationRequest<TSettings = PossibleIntegrationSettings> =
  {
    integration: TSettings
    tenantId: number
    storeId: number
    integrationType: IntegrationType
    environment: StoreEnvironment
  }
export type UpdateIntegrationRequest<TSettings = PossibleIntegrationSettings> =
  CreateIntegrationRequest<TSettings>

export type PossibleIntegrationSettings =
  | StripeSettings
  | ContentfulSettings
  | SegmentSettings
  | MagicSettings
  | StytchSettings
  | CapacityLlcSettings
  | EasyPostSettings
  | NarvarSettings
  | OrderDeskSettings
  | ShipheroSettings
  | ShippoSettings
  | ShipstationSettings
  | SolidusSettings
  | ReferralSettings
  | ShippingSettings
  | SubscriptionSettings
  | NetlifySettings
  | ComposerSettings
  | FivetranSettings
  | CensusSettings

export type PossibleFulfillmentIntegrationSettings =
  | CapacityLlcSettings
  | EasyPostSettings
  | NarvarSettings
  | OrderDeskSettings
  | ShipheroSettings
  | ShippoSettings
  | ShipstationSettings

export type PossibleAuthenticationIntegrationSettings =
  | MagicSettings
  | StytchSettings

export type PossiblMultipleIntegrationSettings =
  | PossibleFulfillmentIntegrationSettings
  | PossibleAuthenticationIntegrationSettings

export type IntegrationCategory =
  | 'CMS'
  | 'OMS'
  | 'Fulfillment'
  | 'Payments'
  | 'Data'
  | 'Authentication'
  | 'Front_end_host'
  | 'Autonomy_settings'

export type Integration<TSettings = PossibleIntegrationSettings> = {
  id: number
  environment: StoreEnvironment
  name: string
  url: string
  type: string
  category: IntegrationCategory
  settings: TSettings
  state: string
  active: boolean
  parent?: { id: number }
}

export type StoreSettings = {
  id: number
  name: string
  siteUrl: string
  slug: string
  mailFromAddress: string
  environment: StoreEnvironment
}

export type StoreSetup = {
  chordUuid: string
  id: number
  plan: Plan
  storeSettings: StoreSettings[]
  integrations: Integration<any>[]
}

export type StripeSettings = {
  allowedCallbackUrls: string
  apiKey: string
  pkKey: string
  statementDescriptor: string
  allowedCountries: string
  processingFee: number
  webhook: string
  webhookSecret?: string
  stripeAutomaticTaxesEnabled?: boolean
}

export type ContentfulSettings = {
  accessToken: string
  host: string
  contentfulEnvironment: string
  spaceId: string
  webhookSecret: string
}

export type SegmentSettings = {
  writeKey: string
}

export type MagicSettings = {
  apiKey: string
  pkKey: string
}

export type StytchSettings = {
  env: string
  projectId: string
  secret: string
  token: string
}

export type NetlifySettings = {
  apiKey: string
  siteId: string
  nextStarter: boolean
}

export type ComposerSettings = {
  stackId: number
}

export type CapacityLlcSettings = {
  clientCode: string
  ownerId: string
  password: string
  ftpUsername: string
  ftpPassword: string
  ftpUrl: string
  ftpPort: string
  httpTimeout: string
}

export type EasyPostSettings = {
  key: string
}

export type NarvarSettings = {
  host: string
}

export type OrderDeskSettings = {
  storeId: string
  apiKey: string
}

export type ShipheroSettings = {
  authToken: string
  refreshToken: string
}

export type ShippoSettings = {
  key: string
}

export type ShipstationSettings = {
  apiKey: string
  secretKey: string
}

export type SolidusSettings = {
  apiKey: string
  apiHost: string
  solidusStoreId: number
}

export type ProviderOption = {
  key: string
  name: string
  logo: string
  form: React.FC<{
    onChangeProvider: () => void
    onClose: () => void
    integration?: Integration<any>
  }>
}

// The below are treated on the backend as integrations that map to Solidus
export type ReferralSettings = {
  referralHost: string
  referralCreditAmount: string
  referralAnnualLimit: string
  referralMailer: string
  referralMailerDeliveryDelay: string
}

export type ShippingSettings = {
  shippingRate: string
  freeShippingThreshold: string
  freeShippingUnit: string
  holdShipmentWindowInMinutes: string
}

export type SubscriptionSettings = {
  freeShippingForSubscriptions: string
  subscriptionDefaultReminderDays: string
  subscriptionLastChanceReminderDays: string
  resetSubscriptions: string
}

export type ChordDataConnector = {
  name: string
  slug: string
  modeled: boolean
  active: boolean
  provider: string
}

export type ChordConfigSheet = {
  name: string
  slug: string
  description: string
  category: string
}

export type FivetranSettings = {
  apiKey: string
  apiSecret: string
  destinationGroupId: string
  filterConnectors?: string
  fivetranConnectors: ChordDataConnector[]
}

export type CensusSettings = {
  apiKey: string
}

export type GetFulfillmentServices = {
  fulfillmentServices: FulfillmentService[]
}

export type FulfillmentService = {
  id: number
  name: string
}

export type StoreSetupState = {
  integrations: Integration<any>[]
  fulfillmentServices: FulfillmentService[]
  store?: StoreSetup
}

export enum TenantPlanEnum {
  DataOnly = 'data_only',
  Autonomy = 'autonomy',
  Performance = 'performance',
}

export interface CreateTenantPayload {
  tenant: {
    name: string
    chordUuid: string
  }
  store: {
    name: string
  }
  environment: StoreEnvironment
  adminEmails?: string[]
  census?: {
    apiKey: string
  }
  fivetran?: {
    destinationGroupId: string
    filterConnectors: string
  }
  metadata?: {
    lookerClientId: string
    lookerClientSecret: string
    lookerSharedFolderId: string
    lookerPermissions: {
      groupIds: number[]
      models: string[]
    }
  }
  provisionMetrics?: boolean
  solidus?: {
    apiKey: string
    apiHost: string
    solidusStoreId: number
    solidusTenantId: number
  }
}

export interface CreateTenantRequest {
  name: string
  uuid: string
  environment: StoreEnvironment
  adminEmails?: string
  plan: TenantPlanEnum
  fivetranGroupId?: string
  filterConnectors?: string
  censusApiKey?: string
  lookerClientId?: string
  lookerClientSecret?: string
  lookerGroupIds?: string
  lookerSharedFolderId?: string
  lookerModels?: string
  solidusApiKey?: string
  solidusApiHost?: string
  solidusStoreId?: number
  solidusTenantId?: number
}
