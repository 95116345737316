import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { BannerContainer } from '@chordco/component-library'

export const Notifications: React.FC = () => {
  const dispatch = useDispatch()
  const notifications = useSelector(
    (state: RootState) => state.notifications.notifications
  )
  if (!notifications.length) return null

  const onClear = (id: string) =>
    dispatch({ type: 'notifications/clearNotification', payload: { id } })

  const onUndo = undoAction => dispatch(undoAction)

  return (
    <BannerContainer
      notifications={notifications}
      onClear={onClear}
      onUndo={onUndo}
    />
  )
}
