import React, { ChangeEvent, ComponentProps, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { standardIcons } from '../icons'
import { useTheme } from '@chordco/component-library'

interface Props extends ComponentProps<'input'> {
  label: string
  value: number | string | string[]
  errorMessage?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  hint?: string
  icon?: any
  width?: number
  chevron?: boolean
  inputRef?: any
}

export const FloatingLabelInput: React.FC<Props> = ({
  errorMessage,
  hint,
  icon: Icon,
  chevron = false,
  inputRef,
  ...props
}) => {
  const theme = useTheme()

  useEffect(() => {
    inputRef?.current?.focus()
  }, [inputRef])

  const { ChevronDown } = standardIcons

  return (
    <Container hint={hint} errorMessage={errorMessage}>
      <Input
        {...props}
        type="text"
        ref={inputRef}
        onKeyDown={(e: any) => e.stopPropagation()}
      />
      <Label showSmallLabel={!!props.value} errorMessage={errorMessage}>
        {props.label}
      </Label>

      {Icon && (
        <IconContainer>
          <Icon />
        </IconContainer>
      )}

      {hint && !errorMessage && <Hint>{hint}</Hint>}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {chevron && (
        <ChevronContainer>
          <ChevronDown scale={16} fill={theme.GREY_60} />
        </ChevronContainer>
      )}
    </Container>
  )
}

const Container = styled.label<{ hint?: string; errorMessage?: string }>`
  position: relative;
  display: block;
  margin-bottom: ${({ hint, errorMessage }) =>
    hint || errorMessage ? '22px' : '16px'};
  width: fit-content;
`

const Label = styled.span<{ showSmallLabel?: boolean; errorMessage?: string }>`
  position: absolute;
  top: 20px;
  left: 17px;
  font-weight: 500;
  color: ${({ theme, errorMessage }) =>
    errorMessage ? theme.RED_60_BASE : theme.GREY_60};
  transition: 100ms;

  ${({ showSmallLabel }) =>
    showSmallLabel &&
    css`
      top: 9px;
      font-size: 12px;
    `}
`

const Input = styled.input<Props>`
  box-sizing: border-box;
  width: ${p => p.width || 292}px;
  height: 56px;
  color: ${p => p.theme.WHITE};
  background-color: ${p => p.theme.BLACK};
  border: solid 1px
    ${p => (p.errorMessage ? p.theme.RED_60_BASE : p.theme.GREY_4)};
  border-radius: 8px;
  font-size: 14px;
  padding: 14px 16px 0;
  transition: 100ms;

  ::placeholder {
    color: ${p => p.theme.GREY_3};
  }

  :hover {
    border-color: ${p => p.theme.OFF_TEXT_BASE};
  }

  :focus {
    border: solid 1px
      ${p => (p.errorMessage ? p.theme.RED_60_BASE : p.theme.PRIMARY_50)};
    outline: none;
  }

  :focus + ${Label} {
    top: 9px;
    font-size: 12px;
    color: ${({ theme, errorMessage }) =>
    errorMessage ? theme.GREY_60 : theme.PRIMARY_50};
  }

  :disabled {
    border-color: ${p => p.theme.GREY_4};
    background-color: ${p => p.theme.OFF_BLACK};
    color: ${p => p.theme.GREY_3};
    box-shadow: none;
  }

  ${({ errorMessage, theme }) =>
    errorMessage &&
    css`
      color: ${theme.RED_60_BASE};
      border-color: ${theme.RED_60_BASE};
      padding-left: 15px;
    `}

  ${({ icon }) =>
    icon &&
    css`
      padding-right: 38px;
    `}
`

const Hint = styled.div`
  position: absolute;
  bottom: -16px;
  left: 0;
  white-space: nowrap;
  font-size: 12px;
  color: ${p => p.theme.GREY_2};
`

const ErrorMessage = styled(Hint)`
  color: ${p => p.theme.RED_60_BASE};
`

const IconContainer = styled.span`
  position: absolute;
  bottom: 15px;
  left: 260px;
  path {
    fill: ${p => p.theme.OFF_TEXT_BASE};
  }
`

const ChevronContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 22px;
`
