import { valTypes } from 'validationSchemas'
import { StoreEnvironment } from 'types'

const createTenantSchema = (environment: StoreEnvironment) => ({
  name: {
    label: 'Name',
    type: valTypes.string,
    initialValue: '',
    required: true,
  },
  uuid: {
    label: 'UUID',
    type: valTypes.string,
    initialValue: '',
    required: true,
    hint: 'Enter the unique tenant identifier generated by Steward',
  },
  environment: {
    label: 'Environment',
    type: valTypes.string,
    initialValue: environment,
    required: true,
  },
  plan: {
    label: 'Plan',
    type: valTypes.string,
    initialValue: 'data_only',
    required: true,
  },
  adminEmails: {
    label: 'Team Admins (optional)',
    type: valTypes.string,
    initialValue: '',
    required: false,
    hint: 'Enter a comma-separated list of email addresses (if more than one)',
  },
  fivetranGroupId: {
    label: 'Destination Group ID',
    type: valTypes.string,
    initialValue: '',
    required: false,
  },
  filterConnectors: {
    label: 'Available Connectors',
    type: valTypes.string,
    initialValue: '',
  },
  censusApiKey: {
    label: 'Census API Key',
    type: valTypes.string,
    initialValue: '',
    required: false,
  },
  lookerClientId: {
    label: 'Client ID',
    type: valTypes.string,
    initialValue: '',
    required: false,
  },
  lookerClientSecret: {
    label: 'Client Secret',
    type: valTypes.string,
    initialValue: '',
    required: false,
  },
  lookerGroupIds: {
    label: 'Group IDs',
    type: valTypes.string,
    initialValue: '',
    required: false,
    hint: 'Enter a comma-separated list of group IDs (if more than one)',
  },
  lookerSharedFolderId: {
    label: 'Shared Folder ID',
    type: valTypes.string,
    initialValue: '',
    required: false,
    hint: 'Enter the Looker tenant shared folder ID (e.g. 123)',
  },
  lookerModels: {
    label: 'Models',
    type: valTypes.string,
    initialValue: '',
    required: false,
    hint: 'Enter a comma-separated list of model names (if more than one)',
  },
  solidusApiKey: {
    label: 'API Key',
    type: valTypes.string,
    initialValue: '',
  },
  solidusApiHost: {
    label: 'API Host',
    type: valTypes.string,
    initialValue: '',
    hint: 'e.g. https://plant-staging.assembly-api.com',
  },
  solidusStoreId: {
    label: 'Store ID',
    type: valTypes.string,
    initialValue: '',
    hint: 'e.g. 1',
  },
  solidusTenantId: {
    label: 'Tenant ID',
    type: valTypes.string,
    initialValue: '',
    hint: 'e.g. 1',
  },
})

export default createTenantSchema
