import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Trash: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 5.23264C2.25 4.81842 2.58579 4.48264 3 4.48264H17C17.4142 4.48264 17.75 4.81842 17.75 5.23264C17.75 5.64685 17.4142 5.98264 17 5.98264H3C2.58579 5.98264 2.25 5.64685 2.25 5.23264Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.28571 2.6493C8.02326 2.6493 7.77566 2.75087 7.59634 2.9252C7.41774 3.09885 7.32143 3.32978 7.32143 3.56597V4.48264H12.6786V3.56597C12.6786 3.32978 12.5823 3.09885 12.4037 2.9252C12.2243 2.75087 11.9767 2.6493 11.7143 2.6493H8.28571ZM14.1786 4.48264V3.56597C14.1786 2.9181 13.9137 2.30119 13.4493 1.84971C12.9856 1.39893 12.3611 1.1493 11.7143 1.1493H8.28571C7.63886 1.1493 7.01438 1.39893 6.55072 1.84971C6.08634 2.30119 5.82143 2.9181 5.82143 3.56597V4.48264H4C3.58579 4.48264 3.25 4.81842 3.25 5.23264V16.8993C3.25 17.5472 3.51491 18.1641 3.97929 18.6156C4.44296 19.0663 5.06743 19.316 5.71429 19.316H14.2857C14.9326 19.316 15.557 19.0663 16.0207 18.6156C16.4851 18.1641 16.75 17.5472 16.75 16.8993V5.23264C16.75 4.81842 16.4142 4.48264 16 4.48264H14.1786ZM4.75 5.98264V16.8993C4.75 17.1355 4.84631 17.3664 5.02491 17.5401C5.20423 17.7144 5.45183 17.816 5.71429 17.816H14.2857C14.5482 17.816 14.7958 17.7144 14.9751 17.5401C15.1537 17.3664 15.25 17.1355 15.25 16.8993V5.98264H4.75Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9.25C8.41421 9.25 8.75 9.58579 8.75 10V14C8.75 14.4142 8.41421 14.75 8 14.75C7.58579 14.75 7.25 14.4142 7.25 14V10C7.25 9.58579 7.58579 9.25 8 9.25Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.25C12.4142 9.25 12.75 9.58579 12.75 10V14C12.75 14.4142 12.4142 14.75 12 14.75C11.5858 14.75 11.25 14.4142 11.25 14V10C11.25 9.58579 11.5858 9.25 12 9.25Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
