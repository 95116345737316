import { useLayoutEffect, useRef, useState } from 'react'

export const useExpandCollapseAnimation = (heightOffset = 0) => {
  const ref = useRef() as any
  const [isOpen, setIsOpen] = useState(true) // we default isOpen to true to measure the height
  const [height, setHeight] = useState<number>()

  const currentHeight = `${isOpen ? (height as number) + heightOffset : 0}px`

  useLayoutEffect(() => {
    setHeight(ref.current?.getBoundingClientRect().height || 0)
    setIsOpen(false)
  }, [])

  return { isOpen, setIsOpen, ref, height: currentHeight }
}

export const useExpandCollapseAnimationNav = () => {
  const ref = useRef() as any
  const [isOpen, setIsOpen] = useState(true) // we default isOpen to true to measure the height
  const [height, setHeight] = useState<number>()
  const [maxHeight, setMaxHeight] = useState<number>()

  useLayoutEffect(() => {
    setHeight(ref.current?.getBoundingClientRect().height || 0)
    setMaxHeight(ref.current?.getBoundingClientRect().height || 0)
    setIsOpen(false)
  }, [])

  const adjustHeight = (delta: number) =>
    setHeight(cur => (cur ? cur + delta : cur))

  return {
    isOpen,
    setIsOpen,
    ref,
    height: isOpen ? height : 0,
    maxHeight,
    adjustHeight,
  }
}
