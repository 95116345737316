import React from 'react'
import { ToastContainer, lightTheme as defaultTheme, Theme } from '../'
import { ChordRootThemeProvider } from './ChordThemeProvider'

interface Props {
  theme?: Theme
  children?: React.ReactNode
}

export const ComponentLibraryProvider: React.FC<Props> = ({
  children,
  theme = defaultTheme,
}) => (
  <ChordRootThemeProvider theme={theme}>
    <ToastContainer>{children}</ToastContainer>
  </ChordRootThemeProvider>
)
