import React, { useRef, useState } from 'react'
import { AuthWrapper, Form, SubHeading } from 'components/auth/AuthWrapper'
import {
  Button,
  Checkbox,
  ErrorMessage,
  H1,
  H2,
  Hint,
  standardIcons,
} from '@chordco/component-library'
import { useForm, useIsMobile } from 'hooks'
import { FormInput } from 'components/common'
import { signUpSchema } from 'validationSchemas/auth'
import styled from 'styled-components'
import ReCAPTCHAComponent, {
  ReCAPTCHA as ReCAPTCHAType,
} from 'react-google-recaptcha'
import { useProvisioningData } from 'redux/state/provisioning'
import { useIsLoading } from 'redux/utils'
import { useHistory } from 'react-router'
import { ProvisionTenantResponse } from 'api/clients/provisioningClient'
import { EnvVarConfig } from 'utils/envVarConfig'

type Response = {
  meta: {
    requestStatus: string
  }
  payload: {
    data: ProvisionTenantResponse
  }
}

const siteKey = EnvVarConfig.REACT_APP_RECAPTCHA_SITE_KEY

const { CheckCircle } = standardIcons

export const SignUp: React.FC = () => {
  const captchaRef = useRef<ReCAPTCHAType>(null)

  const {
    state: { isTenantAvailable, validGithubHandle },
    getIsTenantAvailable,
    resetTenantIsAvailable,
    getGithubHandleExists,
    resetGithubHandleExists,
    provisionTenant,
  } = useProvisioningData()

  const [acceptTermsMissing, setAcceptTermsMissing] = useState(false)
  const [tokenUndefined, setTokenUndefined] = useState(false)

  const history = useHistory()

  const submit = async fields => {
    if (!isTenantAvailable) return

    if (!fields.acceptTerms) {
      setAcceptTermsMissing(true)
      return
    }

    const token = captchaRef.current?.getValue()
    if (!token) {
      setTokenUndefined(true)
      return
    } else {
      setTokenUndefined(false)
    }

    const result = (await provisionTenant({
      firstName: fields.firstName,
      lastName: fields.lastName,
      email: fields.email,
      role: fields.role,
      tenantName: fields.tenantName,
      githubHandle: fields.githubHandle,
      code: fields.code,
      token,
    })) as Response

    if (result.payload.data.id) {
      const provisioningId = result.payload.data.id

      history.push(`sign-up-status?id=${provisioningId}`)
    }

    captchaRef.current?.reset()
  }

  const { fields, onSubmit } = useForm(signUpSchema, submit)

  const tenantNameOnBlur = () => {
    const name = fields.tenantName.value as string
    if (name.length > 3) getIsTenantAvailable(name)
    else resetTenantIsAvailable()
  }

  const githubHandleOnBlur = () => {
    const handle = fields.githubHandle.value as string
    if (handle.length) getGithubHandleExists(handle)
    else resetGithubHandleExists()
  }

  const isMobile = useIsMobile()
  const Heading = isMobile ? H2 : H1

  const isLoading = useIsLoading('provisionTenant')

  return (
    <AuthWrapper>
      <Heading>Start building on Chord</Heading>
      <SubHeading isMobile={isMobile}>
        Explore Chord’s commerce platform-as-a-service with a 30-day free trial
        - no credit card required.
      </SubHeading>

      <Form
        onSubmit={onSubmit}
        isMobile={isMobile}
        inputMarginBottom="0"
        noValidate
      >
        <Fields>
          <FormInput field={fields.firstName} customWidth="100%" />
          <FormInput field={fields.lastName} customWidth="100%" />
          <FormInput field={fields.email} customWidth="100%" />
          <FormInput field={fields.role} customWidth="100%" />
          <RelativeDiv>
            <FormInput
              field={{
                ...fields.tenantName,
                errorMessage:
                  isTenantAvailable === false
                    ? 'This name is already in use'
                    : fields.tenantName.errorMessage,
              }}
              onBlur={tenantNameOnBlur}
              customWidth="100%"
            />
            {!!isTenantAvailable && (
              <CheckContainer>
                <CheckCircle />
              </CheckContainer>
            )}
          </RelativeDiv>
          <HandleWrapper isMobile={isMobile}>
            <FormInput
              field={{
                ...fields.githubHandle,
                errorMessage:
                  validGithubHandle === false
                    ? 'This handle does not exist'
                    : fields.githubHandle.errorMessage,
              }}
              onBlur={githubHandleOnBlur}
              customWidth="100%"
            />
            {!!validGithubHandle && (
              <CheckContainer>
                <CheckCircle />
              </CheckContainer>
            )}
          </HandleWrapper>
          <FormInput field={fields.code} customWidth="100%" />
          <div>
            <Checkbox
              name={fields.acceptTerms.name}
              checked={!!fields.acceptTerms.value}
              onChange={e => {
                fields.acceptTerms.setValue(e.target.checked)

                if (e.target.value) setAcceptTermsMissing(false)
              }}
            />
            <AcceptTermsLabel>
              I acknowledge this is a trial environment for evaluation purposes,
              and agree to the Privacy Policy and Terms of Service.
            </AcceptTermsLabel>
            {acceptTermsMissing && (
              <Error>
                Please accept the Privacy Policy and Terms of Service
              </Error>
            )}
            <ReCAPTCHAContainer>
              <ReCAPTCHAComponent
                sitekey={siteKey}
                ref={captchaRef}
                theme="dark"
              />
            </ReCAPTCHAContainer>
            {tokenUndefined && (
              <ReCAPTCHAError>
                Please check &quot;I&apos;m not a robot&quot;
              </ReCAPTCHAError>
            )}
            <Button type="submit" isLoading={isLoading}>
              Create your store
            </Button>
          </div>
        </Fields>
      </Form>
    </AuthWrapper>
  )
}

const HandleWrapper = styled.div<{ isMobile: boolean }>`
  position: relative;

  ${Hint} {
    white-space: normal;
  }
`

const Fields = styled.div`
  > div {
    margin-bottom: 24px;
  }
`

const RelativeDiv = styled.div`
  position: relative;
`

const AcceptTermsLabel = styled.div`
  margin: -26px 0 0 36px;
  text-align: left;
`

const ReCAPTCHAContainer = styled.div`
  margin: 24px 0;

  .captcha {
    margin: 0 auto;
    width: 65%;
    margin-bottom: 1rem;
  }
`

const CheckContainer = styled.div`
  position: absolute;
  top: 31px;
  right: 9px;

  path {
    fill: ${p => p.theme.GREEN_60};
  }
`

const Error = styled(ErrorMessage)`
  text-align: left;
`

const ReCAPTCHAError = styled(Error)`
  margin: -22px 0 24px 0;
`
