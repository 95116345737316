import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const NewCustomers30: React.FC<IconProps> = ({
  scale = 48,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.25 16.8909V14.6409H24.75V16.8909H27V18.3909H24.75V20.6409H23.25V18.3909H21V16.8909H23.25Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.25 17.6409C16.25 13.3607 19.7198 9.89087 24 9.89087C28.2802 9.89087 31.75 13.3607 31.75 17.6409C31.75 21.9211 28.2802 25.3909 24 25.3909C22.0023 25.3909 20.1812 24.6351 18.807 23.3938C15.4445 23.4958 12.75 26.2537 12.75 29.6409V36.6409H11.25V29.6409C11.25 25.8378 13.9893 22.6745 17.6025 22.0165C16.7492 20.7713 16.25 19.2644 16.25 17.6409ZM24 11.3909C20.5482 11.3909 17.75 14.1891 17.75 17.6409C17.75 21.0926 20.5482 23.8909 24 23.8909C27.4518 23.8909 30.25 21.0926 30.25 17.6409C30.25 14.1891 27.4518 11.3909 24 11.3909Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M29.7536 34.2649C29.7536 33.0409 29.0216 32.4529 28.1216 32.2729C28.9496 32.0569 29.5856 31.4569 29.5856 30.4369C29.5856 29.0569 28.4576 28.1689 26.5616 28.1689C24.7256 28.1689 23.4536 29.2369 23.4296 30.8089H25.1216C25.1456 30.0289 25.7216 29.5249 26.5376 29.5249C27.2576 29.5249 27.8336 29.8849 27.8336 30.5689C27.8336 31.2289 27.3656 31.6969 26.4416 31.6969H25.7096V33.0769H26.5376C27.5096 33.0769 28.0016 33.4969 28.0016 34.1569C28.0016 34.9129 27.3896 35.3569 26.6096 35.3569C25.6976 35.3569 25.0256 34.8529 25.0016 33.9049H23.2856C23.2856 35.7049 24.5456 36.7129 26.5256 36.7129C28.5056 36.7129 29.7536 35.7169 29.7536 34.2649Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5683 32.2489V32.6329C30.5683 35.1889 31.9963 36.7129 34.0243 36.7129C36.0643 36.7129 37.4923 35.1889 37.4923 32.6329V32.2489C37.4923 29.7409 36.0643 28.1689 34.0243 28.1689C31.9963 28.1689 30.5683 29.7409 30.5683 32.2489ZM35.7643 32.3089V32.5729C35.7643 34.3129 35.0563 35.2489 34.0243 35.2489C33.0043 35.2489 32.2963 34.3129 32.2963 32.5729V32.3089C32.2963 30.5809 33.0043 29.6329 34.0243 29.6329C35.0563 29.6329 35.7643 30.5809 35.7643 32.3089Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
