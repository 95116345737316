import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const OpenLink: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.75 3.5C10.75 3.08579 11.0858 2.75 11.5 2.75H16.4996H16.5C16.9143 2.75 17.25 3.08579 17.25 3.5V8.5C17.25 8.91421 16.9143 9.25 16.5 9.25C16.0858 9.25 15.75 8.91421 15.75 8.5V5.31045L11.1968 9.86366C10.9039 10.1566 10.4291 10.1566 10.1362 9.86366C9.84328 9.57077 9.84328 9.0959 10.1362 8.803L14.6892 4.25H11.5C11.0858 4.25 10.75 3.91421 10.75 3.5ZM2.75 8C2.75 6.20511 4.20518 4.75 6.00014 4.75H7.4375C7.85171 4.75 8.1875 4.41421 8.1875 4C8.1875 3.58579 7.85171 3.25 7.4375 3.25H6.00014C3.37682 3.25 1.25 5.37661 1.25 8V14C1.25 16.6234 3.37665 18.75 6 18.75H12C14.6234 18.75 16.75 16.6234 16.75 14V12.5625C16.75 12.1483 16.4142 11.8125 16 11.8125C15.5858 11.8125 15.25 12.1483 15.25 12.5625V14C15.25 15.7949 13.7949 17.25 12 17.25H6C4.20507 17.25 2.75 15.7949 2.75 14V8Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
