import { ConnectedSource, FivetranConnector } from 'api/fivetran/interfaces'

export interface State<T> {
  loading: boolean
  error: Error | undefined
  items: T[] | undefined
  cursors: (string | undefined)[]
  prevCursor: string | undefined
  nextCursor: string | undefined
}

const initialState: State<FivetranConnector | ConnectedSource> = {
  loading: false,
  error: undefined,
  items: undefined,
  cursors: [],
  prevCursor: undefined,
  nextCursor: undefined,
}

export { initialState }
