import axios from 'axios'
import humps from 'humps'
import { removeTrailingSlash } from 'utils'

let omsClient

const errorFunction = () => {
  throw Error
}

export const updateOmsClient = (baseUrl: string, apiKey: string) => {
  if (!baseUrl || baseUrl === '') {
    omsClient = errorFunction
    return
  }

  omsClient = axios.create({
    baseURL: `${removeTrailingSlash(baseUrl)}/api/`,
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiKey}`,
    },
  })

  omsClient.interceptors.response.use(response =>
    humps.camelizeKeys(response.data)
  )

  omsClient.interceptors.request.use(request => ({
    ...request,
    data: humps.decamelizeKeys(request.data),
    params: humps.decamelizeKeys(request.params),
  }))
}

export { omsClient }
