export const configSheetState = provisionState => {
  switch (provisionState) {
    case 'inactive':
      return {
        text: 'Template',
        color: 'neutral',
        variant: 'solid-secondary',
      }
    case 'pending':
      return {
        text: 'Activation Pending',
        color: 'orange',
        variant: 'solid-secondary',
      }
    case 'active':
      return {
        text: 'Activated',
        color: 'lightGreen',
        variant: 'solid-secondary',
      }
    default:
      return 'Undefined'
  }
}
