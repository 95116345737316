import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const TrendingUp: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1665 4.25C13.7523 4.25 13.4165 4.58579 13.4165 5C13.4165 5.41421 13.7523 5.75 14.1665 5.75H17.3562L11.2502 11.856L7.61383 8.21967C7.47317 8.07902 7.28241 8 7.0835 8C6.88458 8 6.69382 8.07902 6.55317 8.21967L0.303166 14.4697C0.0102728 14.7626 0.0102728 15.2374 0.303166 15.5303C0.596059 15.8232 1.07093 15.8232 1.36383 15.5303L7.0835 9.81066L10.7198 13.447C10.8605 13.5876 11.0512 13.6667 11.2502 13.6667C11.4491 13.6667 11.6398 13.5876 11.7805 13.447L18.4165 6.81098V10C18.4165 10.4142 18.7523 10.75 19.1665 10.75C19.5807 10.75 19.9165 10.4142 19.9165 10V5.0221C19.9223 4.82286 19.8492 4.62174 19.6972 4.46967C19.59 4.36255 19.4586 4.29461 19.3206 4.26584C19.2927 4.26002 19.2642 4.25574 19.2353 4.25311C19.2133 4.25111 19.1912 4.25007 19.1691 4.25C19.1682 4.25 19.1674 4.25 19.1665 4.25H14.1665Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
