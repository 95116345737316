import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const KeyResultsIcon: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3384 1.25754C14.7484 1.19896 15.1283 1.48389 15.1869 1.89394L15.4192 3.52012L17.0252 1.91412C17.3181 1.62123 17.793 1.62123 18.0859 1.91412C18.3788 2.20701 18.3788 2.68189 18.0859 2.97478L16.4799 4.58079L18.106 4.8131C18.5161 4.87168 18.801 5.25157 18.7424 5.66163C18.6839 6.07168 18.304 6.3566 17.8939 6.29802L15.154 5.90661L11.8417 9.21893C11.9436 9.4589 12 9.72287 12 10C12 11.1046 11.1045 12 9.99998 12C8.89541 12 7.99998 11.1046 7.99998 10C7.99998 8.89543 8.89541 8 9.99998 8C10.2771 8 10.5411 8.05637 10.7811 8.15827L14.0934 4.84595L13.702 2.10607C13.6434 1.69602 13.9283 1.31612 14.3384 1.25754ZM2.75 10C2.75 5.99593 5.99594 2.75 10 2.75C10.3076 2.75 10.6103 2.76911 10.9072 2.80613C11.3182 2.85738 11.693 2.56573 11.7442 2.1547C11.7955 1.74367 11.5038 1.36891 11.0928 1.31765C10.7345 1.27297 10.3698 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 9.63024 18.727 9.2655 18.6823 8.90719C18.6311 8.49616 18.2563 8.20451 17.8453 8.25576C17.4343 8.30702 17.1426 8.68177 17.1939 9.0928C17.2309 9.38968 17.25 9.69244 17.25 10C17.25 14.0041 14.0041 17.25 10 17.25C5.99594 17.25 2.75 14.0041 2.75 10ZM5.74998 10C5.74998 7.65279 7.65277 5.75 9.99998 5.75C10.4142 5.75 10.75 5.41422 10.75 5C10.75 4.58579 10.4142 4.25 9.99998 4.25C6.82434 4.25 4.24998 6.82437 4.24998 10C4.24998 13.1756 6.82434 15.75 9.99998 15.75C13.1756 15.75 15.75 13.1756 15.75 10C15.75 9.58579 15.4142 9.25 15 9.25C14.5858 9.25 14.25 9.58579 14.25 10C14.25 12.3472 12.3472 14.25 9.99998 14.25C7.65277 14.25 5.74998 12.3472 5.74998 10Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
