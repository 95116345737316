import { Action } from './types'
import { State, initialState } from './states'

const reducer = <T>(state: State<T>, action: Action<T>): State<T> => {
  switch (action.type) {
    case 'FETCH_START':
      return { ...state, loading: true, error: undefined }
    case 'FETCH_SUCCESS': {
      const { items, currentPage, nextPage, previousPage } = action.payload
      return {
        ...state,
        loading: false,
        items: items as T[],
        currentPage: currentPage,
        nextPage: nextPage,
        previousPage: previousPage,
      }
    }
    case 'FETCH_ERROR': {
      const error = action.payload as Error
      return { ...state, loading: false, error }
    }
    case 'REFRESH':
      return { ...(initialState as State<T>), loading: true }
    default:
      return state
  }
}

export { initialState, reducer }
