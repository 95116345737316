import React, { ChangeEvent, useRef } from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components'
import {
  Button,
  Checkbox,
  Input,
  Label,
  Required,
  OptionProps,
  PhoneInput,
  SelectableDropdownInput,
  TextArea,
  DatePickerWithInput,
  Tooltip,
  standardIcons,
  useTheme,
  SecureInput,
  RadioButton,
  MultiEntryInput,
  TimeInput,
} from '@chordco/component-library'
import { CreditCardImage } from 'components/common/CreditCardImage'
import { useIsMobile, FormField } from 'hooks'
import {
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
  FormHelperText,
} from '@mui/material'

export type FormFieldProps = {
  field: FormField
  customWidth?: string
  type?: string
  disabled?: boolean
  required?: boolean
  fullWidth?: boolean
  tooltip?: string
  prefix?: string
  subLabel?: string
  className?: string
  hideLabel?: boolean
  placeholder?: string
  maxLength?: number
  suffix?: string
  marginTop?: string
  hasError?: boolean
  isDayBlocked?: (date: moment.Moment) => boolean
  hideIcon?: boolean
  onBlur?: () => void
}

const { AlertInfo } = standardIcons

const createInputOnChange =
  <T extends HTMLInputElement | HTMLTextAreaElement>(field: FormField) =>
  (e: ChangeEvent<T>) => {
    field.setValue(e.target.value)
  }

const createInputOnBlur =
  <T extends HTMLInputElement | HTMLTextAreaElement>(
    field: FormField,
    onBlurCallback?: () => void
  ) =>
  (e: ChangeEvent<T>) => {
    field.setValue(e.target.value.trim())
    if (onBlurCallback) onBlurCallback()
  }

export const FormInputUnstyled: React.FC<FormFieldProps> = ({
  field,
  ...props
}) => (
  <Input
    {...props}
    name={field.name}
    label={field.label}
    value={typeof field.value === 'string' ? field.value : ''}
    onChange={createInputOnChange(field)}
    onBlur={createInputOnBlur(field, props.onBlur)}
    errorMessage={field.errorMessage}
    required={field.isRequired}
  />
)

export const FormInput: React.FC<FormFieldProps> = ({
  field,
  marginTop,
  ...props
}) => {
  const isMobile = useIsMobile()
  const defaultWidth = isMobile ? 'calc(100vw - 32px)' : '368px'
  const width = props.customWidth || defaultWidth

  const theme = useTheme()

  return (
    <InputWrapper marginTop={marginTop} width={width}>
      <Input
        {...props}
        name={field.name}
        label={field.label}
        subLabel={field.subLabel}
        hint={field.hint}
        value={
          typeof field.value === 'string' || typeof field.value === 'number'
            ? field.value
            : ''
        }
        onChange={createInputOnChange(field)}
        onBlur={createInputOnBlur(field, props.onBlur)}
        errorMessage={field.errorMessage}
        required={field.isRequired || props.required}
        customWidth={width}
      />

      {props.tooltip && (
        <AlertInfoContainer>
          <Tooltip text={props.tooltip} direction="left">
            <AlertInfo fill={theme.GREY_4} scale={16} />
          </Tooltip>
        </AlertInfoContainer>
      )}
    </InputWrapper>
  )
}

const AlertInfoContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

export const FormSecureInput: React.FC<FormFieldProps> = ({
  field,
  ...props
}) => {
  const isMobile = useIsMobile()
  const defaultWidth = isMobile ? 'calc(100vw - 32px)' : '368px'

  return (
    <InputWrapper addZIndex>
      <SecureInput
        {...props}
        name={field.name}
        label={field.label}
        value={
          typeof field.value === 'string' || typeof field.value === 'number'
            ? field.value
            : ''
        }
        onChange={createInputOnChange(field)}
        onBlur={createInputOnBlur(field, props.onBlur)}
        errorMessage={field.errorMessage}
        required={field.isRequired || props.required}
        customWidth={props.customWidth || defaultWidth}
      />
    </InputWrapper>
  )
}

export const FormPhoneInput: React.FC<FormFieldProps> = ({
  field,
  marginTop,
  ...props
}) => {
  const isMobile = useIsMobile()
  const defaultWidth = isMobile ? 'calc(100vw - 32px)' : '368px'

  return (
    <InputWrapper marginTop={marginTop}>
      <PhoneInput
        {...props}
        name={field.name}
        label={field.label}
        value={typeof field.value === 'string' ? field.value : ''}
        onChange={val => field.setValue(val)}
        required={field.isRequired}
        errorMessage={field.errorMessage}
        customWidth={props.customWidth || defaultWidth}
      />
    </InputWrapper>
  )
}

export const FormTimeInput: React.FC<FormFieldProps> = ({
  field,
  ...props
}) => {
  const isMobile = useIsMobile()
  const defaultWidth = isMobile ? 'calc(100vw - 32px)' : '368px'

  if (typeof field.value !== 'string') return null

  const date = field.value ? moment(field.value) : undefined

  return (
    <InputWrapper>
      <TimeInput
        {...props}
        type="time"
        name={field.name}
        label={field.label}
        value={date}
        onChange={field.setValue}
        errorMessage={field.errorMessage}
        customWidth={props.customWidth || defaultWidth}
      />
    </InputWrapper>
  )
}

export const FormTextArea: React.FC<FormFieldProps> = ({ field, ...props }) => {
  const isMobile = useIsMobile()
  const defaultWidth = isMobile ? 'calc(100vw - 32px)' : '368px'
  const { customWidth, ...otherProps } = props // passing customWidth to TextArea is not supported
  const width = customWidth || defaultWidth

  return (
    <InputWrapper>
      <TextArea
        {...otherProps}
        name={field.name}
        label={field.label}
        value={typeof field.value === 'string' ? field.value : ''}
        onChange={createInputOnChange(field)}
        onBlur={createInputOnBlur(field, props.onBlur)}
        errorMessage={field.errorMessage}
        width={width}
      />
    </InputWrapper>
  )
}

type DropdownProps = FormFieldProps & {
  options: OptionProps[]
  search?: boolean
  left?: boolean
}

export const FormDropdown: React.FC<DropdownProps> = ({
  field,
  options,
  hideLabel = false,
  className,
  marginTop,
  customWidth,
  ...props
}) => {
  return (
    <InputWrapper className={className} marginTop={marginTop}>
      {!hideLabel && (
        <Label>
          {field.label}
          {!!field.label && field.isRequired && <Required> *</Required>}
        </Label>
      )}
      <FormControl sx={{ width: customWidth }} size="small">
        <Select
          {...props}
          value={field.value.toString()}
          onChange={(event: SelectChangeEvent<string>) => {
            field.setValue(event.target.value)
          }}
        >
          {options.length > 0 &&
            options.map(explore => (
              <MenuItem key={explore.id} value={explore.id}>
                {explore.label}
              </MenuItem>
            ))}

          {options.length === 0 && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
        </Select>
        <FormHelperText>{field.errorMessage}</FormHelperText>
      </FormControl>
    </InputWrapper>
  )
}

const InputWrapper = styled.div<{
  addZIndex?: boolean
  marginTop?: string
  width?: string
}>`
  position: relative;
  margin-top: ${p => p.marginTop || '8px'};

  ${p =>
    p.addZIndex &&
    css`
      z-index: 1;
    `}

  ${p =>
    p.width &&
    css`
      width: ${p.width};
    `}
`

export const GroupedFields = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  input {
    width: ${p => (p.isMobile ? 'calc(50vw - 20px)' : '180px')};
  }
`

type RadioButtonProps = FormFieldProps & { ccType?: string }

export const FormRadioButton: React.FC<RadioButtonProps> = ({
  field,
  fullWidth = false,
  subLabel,
  className,
  ccType,
  marginTop,
  ...props
}) => {
  const isMobile = useIsMobile()
  const ref = useRef() as any

  const onContainerClick = e => {
    e.preventDefault()

    const input = ref?.current?.getElementsByTagName('input')[0]
    if (input) input.click()
  }

  if (typeof field.value !== 'boolean') {
    return null
  }

  return (
    <CheckboxContainer
      isMobile={isMobile}
      onClick={onContainerClick}
      checked={field.value}
      disabled={props.disabled}
      fullWidth={fullWidth}
      className={className}
      hasSubLabel={!!subLabel}
      marginTop={marginTop}
    >
      {ccType && <CCImage type={ccType} />}
      <CheckboxLabel checked={field.value} hasImage={!!ccType}>
        {field.label}
      </CheckboxLabel>
      {subLabel && <SubLabel hasImage={!!ccType}>{subLabel}</SubLabel>}
      <RadioButtonPosition ref={ref}>
        <RadioButton
          {...props}
          label=""
          small={false}
          name={field.name}
          checked={field.value}
          onChange={e => field.setValue(e.target.checked)}
        />
      </RadioButtonPosition>
    </CheckboxContainer>
  )
}

export const RadioButtonPosition = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  right: 10px;
`

export const RadioButtonGroup = (data: {
  field: FormField
  options: OptionProps[]
}) => {
  const { field, options } = data
  return (
    <>
      {options.map(({ id, label }) => {
        return (
          <FormRadioButton
            key={id}
            field={{
              ...field,
              type: 'boolean',
              name: id.toString(),
              value: field.value === id,
              label,
              setValue: () => field.setValue(id),
            }}
            fullWidth
          />
        )
      })}
    </>
  )
}

export const FormCheckbox: React.FC<FormFieldProps> = ({
  field,
  subLabel,
  fullWidth = false,
  className,
  marginTop,
  ...props
}) => {
  const isMobile = useIsMobile()
  const ref = useRef() as any

  const onContainerClick = e => {
    e.preventDefault()

    const input = ref?.current?.getElementsByTagName('input')[0]
    if (input) input.click()
  }

  if (typeof field.value !== 'boolean') {
    return null
  }

  const hasSubLabel = !!subLabel || !!field.subLabel

  return (
    <CheckboxContainer
      isMobile={isMobile}
      onClick={onContainerClick}
      checked={field.value}
      disabled={props.disabled}
      fullWidth={fullWidth}
      className={className}
      marginTop={marginTop}
      hasSubLabel={hasSubLabel}
    >
      <CheckboxLabel checked={field.value}>{field.label}</CheckboxLabel>
      {hasSubLabel && <SubLabel>{subLabel || field.subLabel}</SubLabel>}
      <CheckboxPosition ref={ref}>
        <Checkbox
          {...props}
          name={field.name}
          checked={field.value}
          onChange={e => field.setValue(e.target.checked)}
        />
      </CheckboxPosition>
    </CheckboxContainer>
  )
}

export const CheckboxContainer = styled.div<{
  isMobile: boolean
  fullWidth: boolean
  checked: boolean
  disabled?: boolean
  hasSubLabel?: boolean
  marginTop?: string
}>`
  position: relative;
  box-sizing: border-box;
  width: ${p =>
    p.isMobile ? 'calc(100vw - 32px)' : p.fullWidth ? '100%' : '368px'};
  margin-top: ${p => p.marginTop || '12px'};
  padding: 8px 12px;
  border-radius: 8px;
  background-color: ${p =>
    p.checked
      ? p.theme.BgOverlayPrimaryAccentelEvation1
      : p.theme.ComponentCardBgElevation1};
  cursor: pointer;
  border: solid 1px
    ${p =>
      p.checked
        ? p.theme.BorderAccentPrimary
        : p.theme.ComponentWorkspaceBgPage};
  white-space: pre;

  :hover {
    border-color: ${p => p.theme.BorderAccentPrimary};
  }

  ${p =>
    p.disabled &&
    css`
      border: 1px solid ${p => p.theme.BorderDisabled};
      color: ${p => p.theme.ContentDisabled};
    `}
`

const CheckboxLabel = styled.div<{ checked: boolean; hasImage?: boolean }>`
  line-height: 20px;
  font-weight: 600;
  color: ${p => (p.checked ? p.theme.ContentPrimary : p.theme.ContentPrimary)};

  ${p =>
    p.hasImage &&
    css`
      position: absolute;
      top: 10px;
      left: 64px;
    `}
`

const SubLabel = styled.div<{ hasImage?: boolean }>`
  line-height: 20px;
  margin-top: 4px;
  color: ${p => p.theme.ContentSecondary};
  max-width: calc(100% - 30px);
  white-space: pre-wrap;

  ${p =>
    p.hasImage &&
    css`
      position: absolute;
      bottom: 10px;
      left: 64px;
    `}
`

const CheckboxPosition = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  right: 10px;
`

export const FormButton = props => {
  const isMobile = useIsMobile()

  return (
    <ButtonContainer>
      <StyledButton {...props} isMobile={isMobile} />
    </ButtonContainer>
  )
}

const ButtonContainer = styled.div`
  padding-top: 16px;
  margin-top: 16px;
  border-top: solid 1px ${p => p.theme.BorderHairline};
`

const StyledButton = styled(Button)<{ isMobile: boolean }>`
  width: ${p => (p.isMobile ? 'calc(100vw - 32px)' : '368px')};
`

export const FormSelectableInput = ({
  field,
  options,
  marginTop = '8px',
  ...props
}) => (
  <InputWrapper marginTop={marginTop}>
    <Label>
      {field.label}
      {field.isRequired && <Required> *</Required>}
    </Label>
    <SelectableDropdownInput
      {...props}
      options={options}
      selectedIds={field.value}
      setSelectedIds={field.setValue}
      chipColor="brightBlue"
      useTags
    />
  </InputWrapper>
)

export const FormMultiEntryInput: React.FC<FormFieldProps> = ({
  field,
  ...props
}) => {
  if (!Array.isArray(field.value)) return null

  return (
    <InputWrapper>
      <Label>
        {field.label}
        {field.isRequired && <Required> *</Required>}
      </Label>
      <MultiEntryInput
        {...props}
        items={field.value}
        updateItems={field.setValue}
      />
    </InputWrapper>
  )
}

export const FormDate: React.FC<FormFieldProps> = ({
  field,
  placeholder,
  ...props
}) => {
  const isMobile = useIsMobile()
  if (
    typeof field.value !== 'string' &&
    typeof field.value !== 'undefined' &&
    !moment.isMoment(field.value)
  ) {
    return null
  }
  const width = props.customWidth
    ? props.customWidth
    : isMobile
    ? '100%'
    : '368px'
  return (
    <InputWrapper>
      <Label>
        {field.label}
        {field.isRequired && <Required> *</Required>}
      </Label>
      <DatePickerWithInput
        {...props}
        date={field.value ? moment(field.value) : undefined}
        onChange={field.setValue}
        inputProps={{
          customWidth: width,
          style: { marginTop: 0 },
          errorMessage: field.errorMessage,
          required: field.isRequired,
        }}
        placeholder={placeholder}
      />
    </InputWrapper>
  )
}

export const HalfWidthInput = styled(FormInput)`
  max-width: calc((100vw / 2) - 20px);
`

export const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
`

const CCImage = styled(CreditCardImage)`
  margin: 0 0 -2px -4px;
`
