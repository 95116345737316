import React, { useState } from 'react'
import type { OnboardingTaskProps } from 'components/home/OnboardingTask'
import { OnboardingTask } from 'components/home/OnboardingTask'
import { Icon, standardIcons } from '@chordco/component-library'
import styled from 'styled-components'

interface Props {
  tasks: Pick<
    OnboardingTaskProps,
    'title' | 'description' | 'button' | 'isComplete'
  >[]
  displayAll?: boolean
}

const { ArrowRight, ArrowLeft } = standardIcons

export const OnboardingTasks: React.FC<Props> = ({ tasks, displayAll }) => {
  const [visibleTaskIndex, setVisibleTaskIndex] = useState(0)

  const decrement = () =>
    setVisibleTaskIndex(cur => {
      if (cur === 0) return tasks.length - 1
      return cur - 1
    })

  const increment = () =>
    setVisibleTaskIndex(cur => {
      if (cur === tasks.length - 1) return 0
      return cur + 1
    })

  const { title, description, button, isComplete } = tasks[visibleTaskIndex]

  if (displayAll) {
    return (
      <>
        {tasks.map(({ title, description, button, isComplete }) => (
          <OnboardingTask
            key={title}
            title={title}
            description={description}
            button={button}
            isComplete={isComplete}
            canCycle={false}
          />
        ))}
      </>
    )
  }

  return (
    <Container>
      <OnboardingTask
        title={title}
        description={description}
        button={button}
        isComplete={isComplete}
        canCycle={!displayAll}
      />

      <div>
        {!displayAll && (
          <Arrows>
            <Icon
              onClick={decrement}
              icon={ArrowLeft}
              purpose="tertiary"
              size="small"
            />
            {visibleTaskIndex + 1}/{tasks.length}
            <Icon
              onClick={increment}
              icon={ArrowRight}
              purpose="tertiary"
              size="small"
            />
          </Arrows>
        )}
      </div>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
`

const Arrows = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  grid-column-gap: 8px;
  bottom: 0;
  right: 0;
`
