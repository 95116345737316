import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Team: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.30774 3C4.39598 3 2.84619 4.51645 2.84619 6.3871C2.84619 8.25774 4.39598 9.77419 6.30774 9.77419C8.21951 9.77419 9.7693 8.25774 9.7693 6.3871C9.7693 4.51645 8.21951 3 6.30774 3ZM4.23081 6.3871C4.23081 5.26471 5.16068 4.35484 6.30774 4.35484C7.4548 4.35484 8.38468 5.26471 8.38468 6.3871C8.38468 7.50948 7.4548 8.41935 6.30774 8.41935C5.16068 8.41935 4.23081 7.50948 4.23081 6.3871Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M4.00002 11.129C3.12955 11.129 2.35151 11.5811 1.81437 12.2818C1.28009 12.9789 1 13.8923 1 14.8172V16.3226C1 16.6967 1.30996 17 1.69231 17C2.07466 17 2.38462 16.6967 2.38462 16.3226V14.8172C2.38462 14.1451 2.59079 13.5269 2.92207 13.0947C3.25049 12.6663 3.6464 12.4839 4.00002 12.4839H8.61542C8.96904 12.4839 9.36495 12.6663 9.69337 13.0947C10.0246 13.5269 10.2308 14.1451 10.2308 14.8172V16.3226C10.2308 16.6967 10.5408 17 10.9231 17C11.3055 17 11.6154 16.6967 11.6154 16.3226V14.8172C11.6154 13.8923 11.3353 12.9789 10.8011 12.2818C10.2639 11.5811 9.48589 11.129 8.61542 11.129H4.00002Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M13.2713 11.4555C12.9281 11.2906 12.5133 11.4291 12.3447 11.7649C12.1761 12.1007 12.3176 12.5066 12.6608 12.6716C12.8889 12.7812 13.1032 12.9386 13.2898 13.1414C13.6885 13.5748 13.9232 14.1766 13.9232 14.8172V16.3226C13.9232 16.6967 14.2331 17 14.6155 17C14.9978 17 15.3078 16.6967 15.3078 16.3226V14.8172C15.3078 13.8607 14.959 12.9309 14.319 12.2351C14.0176 11.9074 13.6623 11.6435 13.2713 11.4555Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M16.0369 11.7649C16.2055 11.4291 16.6203 11.2906 16.9635 11.4555C17.3545 11.6435 17.7098 11.9074 18.0112 12.2351C18.6512 12.9309 19 13.8607 19 14.8172V16.3226C19 16.6967 18.69 17 18.3077 17C17.9253 17 17.6154 16.6967 17.6154 16.3226V14.8172C17.6154 14.1766 17.3807 13.5748 16.982 13.1414C16.7954 12.9386 16.5811 12.7812 16.353 12.6716C16.0098 12.5066 15.8683 12.1007 16.0369 11.7649Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M10.7855 3.70074C10.9769 3.37689 11.4005 3.26623 11.7314 3.45357C12.7643 4.03819 13.4617 5.13256 13.4617 6.38711C13.4617 7.64167 12.7643 8.73604 11.7314 9.32066C11.4005 9.508 10.9769 9.39733 10.7855 9.07349C10.594 8.74964 10.7071 8.33524 11.0381 8.14791C11.6607 7.7955 12.0771 7.13848 12.0771 6.38711C12.0771 5.63574 11.6607 4.97873 11.0381 4.62632C10.7071 4.43899 10.594 4.02459 10.7855 3.70074Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M15.4238 3.45357C15.0928 3.26623 14.6693 3.37689 14.4779 3.70074C14.2864 4.02459 14.3995 4.43899 14.7305 4.62632C15.3531 4.97873 15.7694 5.63574 15.7694 6.38711C15.7694 7.13848 15.3531 7.7955 14.7305 8.14791C14.3995 8.33524 14.2864 8.74964 14.4779 9.07349C14.6693 9.39733 15.0928 9.508 15.4238 9.32066C16.4566 8.73604 17.1541 7.64167 17.1541 6.38711C17.1541 5.13256 16.4566 4.03819 15.4238 3.45357Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
