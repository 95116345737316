import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const AlertInfo: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99984 2.41666C5.81168 2.41666 2.4165 5.81183 2.4165 9.99999C2.4165 14.1881 5.81168 17.5833 9.99984 17.5833C14.188 17.5833 17.5832 14.1881 17.5832 9.99999C17.5832 5.81183 14.188 2.41666 9.99984 2.41666ZM0.916504 9.99999C0.916504 4.9834 4.98325 0.916656 9.99984 0.916656C15.0164 0.916656 19.0832 4.9834 19.0832 9.99999C19.0832 15.0166 15.0164 19.0833 9.99984 19.0833C4.98325 19.0833 0.916504 15.0166 0.916504 9.99999ZM10.0083 14.0833C9.59409 14.0833 9.2583 13.7476 9.2583 13.3333V10C9.2583 9.5858 9.59409 9.25001 10.0083 9.25001C10.4225 9.25001 10.7583 9.5858 10.7583 10V13.3333C10.7583 13.7476 10.4225 14.0833 10.0083 14.0833ZM10.0083 7.41666C10.4225 7.41666 10.7583 7.08087 10.7583 6.66666C10.7583 6.25244 10.4225 5.91666 10.0083 5.91666H9.99997C9.58575 5.91666 9.24997 6.25244 9.24997 6.66666C9.24997 7.08087 9.58575 7.41666 9.99997 7.41666H10.0083Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
