import { useSelector } from 'react-redux'
import { createSlice } from '@reduxjs/toolkit'
import {
  AcceptRequest,
  InviteUserRequest,
  SendEmailRequest,
  LoginRequest,
} from 'api/clients/authClient'
import { reduxApiClient } from 'redux/api'
import { AuthState } from 'types'
import { RootState } from '../store'
import { useStateSliceAndDispatch } from '../utils'

const SLICE_NAME = 'auth'

const initialState: AuthState = {
  isAuthenticated: !!localStorage.getItem('token'),
  status: 'idle',
  error: undefined,
  emailSent: false,
  user: undefined,
}

const { sendEmail, login, logout, inviteUser, accept, getAuthenticatedUser } =
  reduxApiClient

const authSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAuthenticatedUser.fulfilled, (state, action) => {
      state.user = action.payload.data
    })

    builder.addCase(logout.fulfilled, state => {
      state.isAuthenticated = false
      state.status = 'idle'
      state.user = undefined
      localStorage.removeItem('token')
      localStorage.removeItem('session_token')
      localStorage.removeItem('b2b')
    })

    builder.addCase(login.fulfilled, (state, action) => {
      state.isAuthenticated = true
      localStorage.setItem('token', action.payload.data.token)
      localStorage.setItem('session_token', action.payload.data.sessionToken)
      localStorage.setItem('b2b', `${action.payload.data.b2b}`)
    })

    builder.addCase(login.rejected, state => {
      state.isAuthenticated = false
    })

    builder.addCase(accept.fulfilled, (state, action) => {
      state.user = action.payload.data
    })
  },
})

export default authSlice.reducer

export const useAuthData = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)
  const { currentTenant } = useSelector((state: RootState) => state.tenants)

  return {
    state,
    sendEmail: (data: SendEmailRequest) => dispatch(sendEmail(data)),
    login: (data: LoginRequest) => dispatch(login(data)),
    logout: () => dispatch(logout()),
    accept: (data: AcceptRequest) => dispatch(accept(data)),
    getAuthenticatedUser: () => dispatch(getAuthenticatedUser()),
    inviteUser: (data: InviteUserRequest) => {
      const tenantId = currentTenant?.id
      const dataWithTenants = tenantId ? { ...data, tenants: [tenantId] } : data
      dispatch(inviteUser(dataWithTenants))
    },
  }
}
