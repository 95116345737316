import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Heading: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.10521 9.76724L6.0002 4.2928L7.8952 9.76724H4.10521ZM9.79146 15.2453L8.41443 11.2672H3.58598L2.20894 15.2453C2.07345 15.6368 1.6463 15.8442 1.25487 15.7087C0.863445 15.5732 0.655971 15.1461 0.791465 14.7547L4.81897 3.11965C5.20718 1.99816 6.79323 1.99817 7.18144 3.11965L11.2089 14.7547C11.3444 15.1461 11.137 15.5732 10.7455 15.7087C10.3541 15.8442 9.92696 15.6368 9.79146 15.2453ZM13.75 12.5C13.75 11.5335 14.5335 10.75 15.5 10.75C16.4665 10.75 17.25 11.5335 17.25 12.5C17.25 13.4665 16.4665 14.25 15.5 14.25C14.5335 14.25 13.75 13.4665 13.75 12.5ZM15.5 9.25C16.1581 9.25 16.7705 9.44559 17.2822 9.78181C17.3757 9.47402 17.6617 9.25 18 9.25C18.4142 9.25 18.75 9.58579 18.75 10V15C18.75 15.4142 18.4142 15.75 18 15.75C17.6617 15.75 17.3757 15.526 17.2822 15.2182C16.7705 15.5544 16.1581 15.75 15.5 15.75C13.7051 15.75 12.25 14.2949 12.25 12.5C12.25 10.7051 13.7051 9.25 15.5 9.25Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
