import React from 'react'
import styled from 'styled-components'

interface Props {
  children: React.ReactNode
  onExit?: Function
  show: boolean
}

export const Overlay: React.FC<Props> = ({ children, onExit, show }) => {
  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      onExit && onExit()
    }
  }

  const onClick = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      onExit && onExit()
    }
  }

  return (
    <Wrapper show={show} onKeyDown={onKeyDown} onClick={onClick} tabIndex={0}>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ show: boolean }>`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${p => p.theme.TRANSPARENT_32};
  backdrop-filter: blur(5px);
  transition: opacity 100ms;
  opacity: ${p => (p.show ? 1 : 0)};
  z-index: 1;
`
