import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const ChevronLeft: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0303 4.96967C13.3232 5.26256 13.3232 5.73744 13.0303 6.03033L8.56066 10.5L13.0303 14.9697C13.3232 15.2626 13.3232 15.7374 13.0303 16.0303C12.7374 16.3232 12.2626 16.3232 11.9697 16.0303L6.96967 11.0303C6.67678 10.7374 6.67678 10.2626 6.96967 9.96967L11.9697 4.96967C12.2626 4.67678 12.7374 4.67678 13.0303 4.96967Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
