import React from 'react'
import styled from 'styled-components'
import { Button, Spinner } from '@chordco/component-library'
import { useTenantsData } from 'redux/state/tenants'
import { useAuthData } from 'redux/state/auth'

interface Props {
  total: number
  remaining?: number
  isLoading?: boolean
}

const capitalize = (word?: string) =>
  word ? word.charAt(0).toUpperCase() + word.slice(1) : ''

const sendEmail = (tenantName: string, userName: string) => {
  const subject = `Extend My Trial - ${tenantName}`
  const body = `Hi! I am interested in discussing an extension for my trial account or permanent account options. My store name is ${tenantName}.%0D%0A%0D%0AThanks,%0D%0A${userName}`

  window.open(`mailto:sales@chord.co?subject=${subject}&body=${body}`)
}

export const Trial: React.FC<Props> = ({ total, remaining, isLoading }) => {
  // we call getUserTenants and getAuthenticatedUser in AuthenticatedRoutes, so the current tenant and user will be populated when we get here
  const {
    state: { currentTenant },
  } = useTenantsData()
  const {
    state: { user },
  } = useAuthData()

  const userName = `${capitalize(user?.firstName)} ${capitalize(
    user?.lastName
  )}`
  const tenantName = capitalize(currentTenant?.name)

  if (typeof remaining === 'undefined' && !isLoading) return null

  return (
    <Container>
      Trial:{' '}
      {isLoading && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
      {typeof remaining !== 'undefined' && (
        <>
          {remaining} days left
          <Slider value={(remaining / total) * 100} />
        </>
      )}
      <ExtendButton
        purpose="ghost"
        size="small"
        onClick={() => sendEmail(tenantName, userName)}
      >
        Extend
      </ExtendButton>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const Slider = styled.div<{ value: number }>`
  width: 100%;
  margin-top: 6px;
  height: 8px;
  background: ${p =>
    `linear-gradient(to right, ${p.theme.BRIGHT_BLUE_50_BASE} 0%, ${p.theme.BRIGHT_BLUE_50_BASE} ${p.value}%, ${p.theme.GREY_3} ${p.value}%, ${p.theme.GREY_3} 100%)`};

  border-radius: 8px;
  outline: none;

  ::after {
    content: none;
    height: 10px;
    width: 10px;
    border: solid 1px black;
    background-color: white;
  }
`

const SpinnerContainer = styled.div`
  position: absolute;
  top: 8px;
  left: 50px;
`

const ExtendButton = styled(Button)`
  position: absolute;
  top: -10px;
  right: 0;
`
