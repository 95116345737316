import { useEffect, useState } from 'react'

type Shortcuts = {
  keys: string[]
  callback: Function
}[]

export const useKeyboardShortcuts = (shortcuts: Shortcuts) => {
  const [pressedKeys, setPressedKeys]: any = useState({})
  const onKeyDown: any = (e: any) => {
    setPressedKeys((cur: any) => ({
      ...cur,
      [e.key?.toLowerCase()]: Date.now(),
    }))
  }
  const onKeyUp: any = (e: any) => {
    setPressedKeys((cur: any) => ({
      ...cur,
      [e.key?.toLowerCase()]: undefined,
    }))
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    window.addEventListener('keyup', onKeyUp)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
      window.removeEventListener('keyup', onKeyUp)
    }
  })

  useEffect(() => {
    shortcuts.forEach(({ keys, callback }) => {
      if (keys.every(key => pressedKeys[key.toLowerCase()])) {
        let correctOrder = true
        for (let i = 0; i < keys.length - 1; i++) {
          if (pressedKeys[keys[i]] > pressedKeys[keys[i + 1]]) {
            correctOrder = false
          }
        }

        if (correctOrder) {
          callback()
          setPressedKeys({})
        }
      }
    })
  }, [pressedKeys, shortcuts])
}
