import React from 'react'
import styled from 'styled-components'
import { standardIcons } from '../icons'

interface Props {
  value: number
}

const { ArrowUp, ArrowDown } = standardIcons

export const UpDown: React.FC<Props> = ({ value, ...props }) => {
  if (value === 0) return null

  return (
    <Container positive={value >= 0} {...props}>
      {value >= 0 ? <ArrowUp /> : <ArrowDown />}
      {Math.abs(+(value * 100).toFixed(1)).toLocaleString()}%
    </Container>
  )
}

const Container = styled.div<{ positive: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  height: 28px;
  width: fit-content;
  background-color: ${p =>
    p.positive
      ? p.theme.BgAccentAdaptiveAAPrimaryContentSuccess
      : p.theme.BgAccentAdaptiveAAPrimaryContentError};
  padding: 4px 8px 4px 6px;
  color: ${p => p.theme.ContentPrimary};
  border-radius: 40px;

  path {
    fill: ${p => p.theme.ContentPrimary};
    transform: rotate(${p => (p.positive ? 0 : 180)});
  }
`
