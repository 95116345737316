import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const VerticalCollapse: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0303 4.03033C13.3232 3.73744 13.3232 3.26256 13.0303 2.96967C12.7374 2.67678 12.2626 2.67678 11.9697 2.96967L10.75 4.18934V1C10.75 0.585786 10.4142 0.25 10 0.25C9.58579 0.25 9.25 0.585786 9.25 1V4.18934L8.03033 2.96967C7.73744 2.67678 7.26256 2.67678 6.96967 2.96967C6.67678 3.26256 6.67678 3.73744 6.96967 4.03033L9.46967 6.53033C9.76256 6.82322 10.2374 6.82322 10.5303 6.53033L13.0303 4.03033ZM2.75 8C2.33579 8 2 8.33579 2 8.75C2 9.16421 2.33579 9.5 2.75 9.5L17.25 9.5C17.6642 9.5 18 9.16422 18 8.75C18 8.33579 17.6642 8 17.25 8L2.75 8ZM2.75 10.5C2.33579 10.5 2 10.8358 2 11.25C2 11.6642 2.33579 12 2.75 12L17.25 12C17.6642 12 18 11.6642 18 11.25C18 10.8358 17.6642 10.5 17.25 10.5L2.75 10.5ZM6.96967 17.0303C6.67678 16.7374 6.67678 16.2626 6.96967 15.9697L9.46967 13.4697C9.76256 13.1768 10.2374 13.1768 10.5303 13.4697L13.0303 15.9697C13.3232 16.2626 13.3232 16.7374 13.0303 17.0303C12.7374 17.3232 12.2626 17.3232 11.9697 17.0303L10.75 15.8107V19C10.75 19.4142 10.4142 19.75 10 19.75C9.58579 19.75 9.25 19.4142 9.25 19V15.8107L8.03033 17.0303C7.73744 17.3232 7.26256 17.3232 6.96967 17.0303Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
