import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Tool: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3565 3.38653C12.9054 3.31052 12.442 3.31271 11.9869 3.39563C11.1939 3.54012 10.4638 3.92285 9.89383 4.49281C9.32386 5.06278 8.94113 5.79291 8.79665 6.5859C8.65216 7.3789 8.75278 8.1971 9.08509 8.93146C9.19933 9.1839 9.14525 9.48071 8.94932 9.67664L3.61598 15.01C3.43377 15.1922 3.33141 15.4393 3.33141 15.697C3.33141 15.9547 3.43377 16.2018 3.61598 16.384C3.79819 16.5662 4.04532 16.6686 4.303 16.6686C4.56068 16.6686 4.80781 16.5662 4.99002 16.384L10.3234 11.0507C10.5193 10.8547 10.8161 10.8007 11.0685 10.9149C11.8029 11.2472 12.6211 11.3478 13.4141 11.2034C14.2071 11.0589 14.9372 10.6761 15.5072 10.1062C16.0772 9.53621 16.4599 8.80608 16.6044 8.01308C16.6873 7.55797 16.6895 7.09456 16.6135 6.64346L14.4786 8.7783C14.2099 9.0417 13.8486 9.18921 13.4723 9.18921C13.0961 9.18921 12.7348 9.04167 12.4661 8.77828L12.4613 8.77362L11.2217 7.53396C10.9583 7.26524 10.8108 6.90394 10.8108 6.52766C10.8108 6.15138 10.9583 5.79011 11.2217 5.52139L11.2264 5.51664L13.3565 3.38653ZM11.7483 2.08579C12.8075 1.89279 13.9004 2.0272 14.8814 2.47109C15.0805 2.56118 15.2227 2.74326 15.2618 2.95826C15.301 3.17325 15.2322 3.39378 15.0776 3.54831L12.1713 6.45468C12.1526 6.47437 12.1422 6.50049 12.1422 6.52766C12.1422 6.55484 12.1526 6.58096 12.1713 6.60066L13.3993 7.82871C13.419 7.84737 13.4452 7.8578 13.4723 7.8578C13.4995 7.8578 13.5256 7.84737 13.5453 7.82871L16.4517 4.92235C16.6062 4.76782 16.8267 4.69898 17.0417 4.73815C17.2567 4.77733 17.4388 4.91952 17.5289 5.11862C17.9728 6.09956 18.1072 7.19248 17.9142 8.25174C17.7212 9.311 17.21 10.2863 16.4486 11.0476C15.6873 11.809 14.712 12.3202 13.6528 12.5132C12.7477 12.6781 11.8182 12.604 10.9549 12.3021L5.93147 17.3255C5.49957 17.7574 4.91379 18 4.303 18C3.69221 18 3.10643 17.7574 2.67453 17.3255C2.24264 16.8936 2 16.3078 2 15.697C2 15.0862 2.24264 14.5004 2.67453 14.0685L7.69792 9.04514C7.39601 8.18183 7.32191 7.25225 7.48681 6.34725C7.67981 5.28799 8.19104 4.31271 8.95238 3.55137C9.71372 2.79003 10.689 2.27879 11.7483 2.08579Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
