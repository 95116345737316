import React from 'react'
import { standardIcons, Spinner, useTheme } from '@chordco/component-library'
import styled, { css } from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'

const { Check, Exit, OpenLink } = standardIcons

export type HealthStatusType = 'enabled' | 'disabled' | 'inProgress' | 'default'

interface Props {
  status: HealthStatusType
  label: string
  customStatuses?: {
    enabled?: string | number
    disabled?: string | number
    inProgress?: string | number
  }
}

export const StatusLine: React.FC<Props> = ({
  status,
  label,
  customStatuses = {
    enabled: 'Enabled',
    disabled: 'Disabled',
    inProgress: 'In Progress',
  },
}) => {
  const theme = useTheme()

  return (
    <Container>
      <IconContainer spinner={status === 'inProgress'}>
        {status === 'enabled' && (
          <Check scale={15} fill={theme.ContentAccentSuccess} />
        )}
        {status === 'disabled' && (
          <Exit scale={16} fill={theme.ContentAccentError} />
        )}
        {status === 'inProgress' && <Spinner />}
      </IconContainer>
      <Label>{label}:</Label>{' '}
      <Status status={status}>{customStatuses[status]}</Status>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const Label = styled.span`
  margin-left: 20px;
`

const IconContainer = styled.div<{ spinner: boolean }>`
  position: absolute;
  top: ${p => (p.spinner ? 10 : 3)}px;
  left: ${p => (p.spinner ? 8 : 0)}px;
`

export const HowToResolvePill: React.FC<{
  href?: string
  onClick?: () => void
  text?: string
  hideIcon?: boolean
}> = ({ href, onClick, text, hideIcon = false }) => (
  <BasePill
    to={{ pathname: href }}
    target={href ? '_blank' : undefined}
    hideIcon={hideIcon}
    onClick={onClick}
  >
    <PillText>{text || 'How to Resolve'}</PillText>
    {!hideIcon && (
      <RightContainer>
        <OpenLink scale={14} />
      </RightContainer>
    )}
  </BasePill>
)

// REDESIGN TODO: We need to create a PillLink component once the ButtonLink is created
export const BasePill = styled(RouterLink)<{ hideIcon?: boolean }>`
  display: flex;
  box-sizing: border-box;
  height: 24px;
  width: ${p => (p.hideIcon ? 114 : 130)}px;
  border: 1px solid ${p => p.theme.ComponentButtonTertiaryBorderDefault};
  background-color: ${p => p.theme.ComponentButtonTertiaryBgHover};
  color: ${p => p.theme.ComponentButtonTertiaryContent};
  border-radius: 12px;
  padding: 0 10px 2px;
  margin-top: 4px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  outline: 0;
  transition: 100ms;
  text-decoration: none;
  color: ${p => p.theme.ContentSecondary};

  path {
    fill: ${p => p.theme.ContentSecondary};
  }

  :hover {
    background-color: rgba(255, 255, 255, 0.15);
  }

  :active {
    transform: scale(0.98);
  }
`

const PillText = styled.div`
  margin-top: -1px;
`

const RightContainer = styled.div`
  margin: 1px 0 0 4px;
`

export const Link = ({ hideIcon = false, ...props }) => {
  return (
    <LinkContainer className={props.className}>
      <BaseLink to={{ pathname: props.href }} {...props} />
      {!hideIcon && (
        <LinkIconContainer>
          <OpenLink scale={16} />
        </LinkIconContainer>
      )}
    </LinkContainer>
  )
}

const LinkContainer = styled.div`
  display: flex;
`

const BaseLink = styled(RouterLink)`
  color: ${p => p.theme.ContentPrimary};
  opacity: 0.7;

  :hover {
    opacity: 0.6;
  }
`

// REDESIGN TODO: This color should be replaced with new variable
const LinkIconContainer = styled.div`
  padding: 3px 2px 0 4px;

  path {
    fill: ${p => p.theme.ContentPrimary}B3; // B3 = 70% opacity
  }
`

export const Status = styled.span<{ status: HealthStatusType }>`
  color: ${p => {
    if (p.status === 'enabled') return p.theme.ContentAccentSuccess
    if (p.status === 'disabled') return p.theme.ContentAccentError
    if (p.status === 'inProgress') return p.theme.ContentAccentWarning
    if (p.status === 'default') return `${p.theme.ContentPrimary}B3`
  }};
`

export const Column = styled.div<{ flex?: number; isSmall: boolean }>`
  flex: ${p => p.flex || 1};

  :not(:last-child) {
    margin-right: 16px;

    ${p =>
      p.isSmall &&
      css`
        margin-bottom: 24px;
      `}
  }
`

export const Bold = styled.span`
  font-weight: 600;
`

// REDESIGN TODO: This color should be replaced with new variable
export const Value = styled.span`
  color: ${p => p.theme.ContentPrimary}B3; // B3 = 70% opacity
`
