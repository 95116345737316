import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Table: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 4C2.75 3.30964 3.30964 2.75 4 2.75H9.25V9.25H2.75V4ZM2.75 10.75V16C2.75 16.6904 3.30964 17.25 4 17.25H9.25V10.75H2.75ZM10.75 10.75V17.25H16C16.6904 17.25 17.25 16.6904 17.25 16V10.75H10.75ZM17.25 9.25V4C17.25 3.30964 16.6904 2.75 16 2.75H10.75V9.25H17.25ZM4 1.25C2.48122 1.25 1.25 2.48122 1.25 4V16C1.25 17.5188 2.48122 18.75 4 18.75H16C17.5188 18.75 18.75 17.5188 18.75 16V4C18.75 2.48122 17.5188 1.25 16 1.25H4Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
