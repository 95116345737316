import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'
import { standardIcons } from '../icons'
import { useTheme } from '@chordco/component-library'

const { Exit } = standardIcons

type Props = {
  text: string
  color: string
  onClose?: () => void
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
}

export const Tag: React.FC<Props> = ({
  text,
  color,
  onClose,
  onClick,
  disabled = false,
  ...props
}) => {
  const theme = useTheme()

  const close = e => {
    e.stopPropagation()
    onClose && onClose()
  }

  return (
    <Container {...props} onClick={onClick} disabled={disabled}>
      <Dot color={color} />
      <Text>{text}</Text>

      {onClose && <Exit scale={16} fill={theme.GREY_3} onClick={close} />}
    </Container>
  )
}

const Container = styled.button<{
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled: boolean
}>`
  padding: 4px 8px;
  border: 1px solid ${p => p.theme.BorderOptimal};
  background-color: transparent;
  border-radius: 16px;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: ${p => (p.onClick ? 'pointer' : 'inherit')};
  opacity: ${p => (p.disabled ? 0.5 : 1)};

  & > svg {
    margin-left: 3px;
    cursor: pointer;
  }

  &:hover {
    background-color: ${p => p.theme.BgOverlayElevation1};
  }
`

const Text = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${p => p.theme.ContentPrimary};
`

const Dot = styled.div<{ color: string }>`
  margin-right: 4px;
  height: 6px;
  width: 6px;
  border-radius: 6px;
  background: ${p => p.color};
`
