import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getDefaultMatchers } from './defaultMatchers'
import { BaseState } from 'types'
import { AxiosError } from 'axios'

export type ValOrCalc<Type, Request, Response, AxiosErrorType = any> =
  | Type
  | ((
      request: Request,
      response?: Response,
      error?: AxiosError<AxiosErrorType>
    ) => Type)

type Notification = { message?: string; undoAction?: {}; type?: string }

export const createAsyncThunkWithMessages = <Request, Response>(
  typePrefix: string,
  callback: (request: Request) => Promise<Response>,
  successMessage?: ValOrCalc<string, Request, Response>,
  errorMessage?: ValOrCalc<string, Request, Response>,
  undoAction?: ValOrCalc<object, Request, Response>
) => {
  const getValue = <T>(
    val?: ValOrCalc<any, Request, Response>,
    req?: Request,
    res?: Response,
    error?: AxiosError
  ): T | undefined => (typeof val === 'function' ? val(req, res, error) : val)

  const payloadCreator = async (
    request: Request,
    thunkAPI: any
  ): Promise<{ data: Response; notification?: Notification }> => {
    try {
      const data = await callback(request)
      if (!successMessage) return { data }

      const message = getValue<string>(successMessage, request, data)
      const undo = getValue<object>(undoAction, request)
      const notification = { message, undoAction: undo, type: 'success' }
      return { data, notification }
    } catch (error: any) {
      const type = 'warning'
      let message = getValue<string | null>(
        errorMessage,
        request,
        undefined,
        error
      )

      // Extract error message from AxiosError response if available:
      // This assumes the Hub Backend returns a JSON response with an error
      // or message field containing the error details.
      if (error.isAxiosError && error.response) {
        const backendMessage =
          error.response.data?.error || error.response.data?.message
        if (backendMessage) {
          message = backendMessage
        }
      }

      if (message === null) return thunkAPI.rejectWithValue()

      return thunkAPI.rejectWithValue({
        notification: {
          message: message || 'An error occurred, please try again.',
          type,
        },
      })
    }
  }

  return createAsyncThunk(typePrefix, payloadCreator)
}

export const createAsyncMethodSlice = <Request = void, Response = void>(
  sliceName: string,
  callback: (request: Request) => Promise<Response>,
  successMessage?: ValOrCalc<string, Request, Response>,
  errorMessage?: ValOrCalc<string, Request, Response>,
  undoAction?: ValOrCalc<object, Request, Response>
) => {
  const typePrefix = `${sliceName}/${callback.name}`

  const thunk = createAsyncThunkWithMessages(
    typePrefix,
    callback,
    successMessage,
    errorMessage,
    undoAction
  )

  const initialState: BaseState & { count: number } = {
    status: 'idle',
    count: 0,
    error: undefined,
  }

  const slice = createSlice({
    name: typePrefix,
    initialState,
    reducers: {},
    extraReducers: builder => getDefaultMatchers(builder, typePrefix),
  })

  return { thunk, slice }
}

export type MethodState = BaseState & { count: number }
