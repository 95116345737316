import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const ArrowDown: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99951 16.5833C9.5853 16.5833 9.24951 16.2476 9.24951 15.8333L9.24951 4.16667C9.24951 3.75246 9.5853 3.41667 9.99951 3.41667C10.4137 3.41668 10.7495 3.75246 10.7495 4.16668L10.7495 15.8333C10.7495 16.2476 10.4137 16.5833 9.99951 16.5833Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.53 16.3637C10.2371 16.6566 9.76224 16.6566 9.46934 16.3637L3.63601 10.5303C3.34312 10.2374 3.34312 9.76257 3.63601 9.46968C3.9289 9.17679 4.40378 9.17679 4.69667 9.46968L9.99967 14.7727L15.3027 9.46968C15.5956 9.17679 16.0704 9.17679 16.3633 9.46968C16.6562 9.76257 16.6562 10.2374 16.3633 10.5303L10.53 16.3637Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
