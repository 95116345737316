import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import EmptySharedReportsImg from 'assets/EmptySharedReports.svg'
import { Button } from '@chordco/component-library'
import {
  CenteredContent,
  ImageContainer,
  StyleBody,
  StyledH1,
} from 'components/settings/integrations/requests/Common'

export default function EmptySharedReports() {
  const history = useHistory()

  return (
    <Wrapper>
      <CenteredContent gap={48}>
        <ImageContainer>
          <img src={EmptySharedReportsImg} alt="Empty Shared Reports" />
        </ImageContainer>

        <TextCtaContainer>
          <StyledH1>Where are my Shared Reports?</StyledH1>
          <StyleBody>
            Your shared reports will appear here after they are published. Go to
            &quot;My Folder&quot; to create and publish a custom report to share
            with your team!
          </StyleBody>

          <Button
            onClick={() => history.push('/analytics/my_folder')}
            purpose="secondary"
          >
            Go to My Folder
          </Button>
        </TextCtaContainer>
      </CenteredContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-top: 64px;
`

const TextCtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`
