import axios, { AxiosInstance } from 'axios'
import humps from 'humps'
import { EnvVarConfig } from 'utils/envVarConfig'

const composerClient: AxiosInstance = axios.create({
  baseURL: EnvVarConfig.REACT_APP_CHORD_COMPOSER_API_URL,
  headers: {
    Authorization: `Bearer ${EnvVarConfig.REACT_APP_CHORD_COMPOSER_API_KEY}`,
    Accept: '*/*',
    'Content-Type': 'application/json',
  },
})

composerClient.interceptors.response.use(response =>
  humps.camelizeKeys(response.data)
)

composerClient.interceptors.request.use(request => ({
  ...request,
  data: humps.decamelizeKeys(request.data),
  params: humps.decamelizeKeys(request.params),
}))

export { composerClient }
