import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Italic: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5835 3.33334C7.5835 2.91912 7.91928 2.58334 8.3335 2.58334H15.8335C16.2477 2.58334 16.5835 2.91912 16.5835 3.33334C16.5835 3.74755 16.2477 4.08334 15.8335 4.08334H8.3335C7.91928 4.08334 7.5835 3.74755 7.5835 3.33334Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.4165 16.6667C3.4165 16.2525 3.75229 15.9167 4.1665 15.9167H11.6665C12.0807 15.9167 12.4165 16.2525 12.4165 16.6667C12.4165 17.0809 12.0807 17.4167 11.6665 17.4167H4.1665C3.75229 17.4167 3.4165 17.0809 3.4165 16.6667Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7633 2.63109C13.1512 2.77653 13.3477 3.20884 13.2022 3.59668L8.20225 16.93C8.05681 17.3179 7.6245 17.5144 7.23666 17.3689C6.84882 17.2235 6.65231 16.7912 6.79775 16.4033L11.7978 3.06999C11.9432 2.68215 12.3755 2.48565 12.7633 2.63109Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
