/* eslint-disable no-console */
import axios, { AxiosInstance, AxiosResponse } from 'axios'
import humps from 'humps'
import { EnvVarConfig } from 'utils/envVarConfig'
import {
  ConnectorConfig,
  ConnectorPauseStateResponse,
  CreateConnectCardResponse,
  CreateConnectorResponse,
  DeleteConnectorResponse,
  FivetranClientOptions,
  FivetranConnector,
  GetConnectedSourcesResponse,
  GetConnectorsResponse,
  GetGroupsResponse,
  SyncConnectorResponse,
  SyncFrequency,
} from './interfaces'

const defaultRedirectUri =
  EnvVarConfig.REACT_APP_FIVETRAN_CONNECT_CARD_REDIRECT_URI

/**
 * The default configuration for the Connect Card.
 * https://fivetran.com/docs/rest-api/getting-started/connect-card
 */
const defaultConnectCardConfig = {
  redirect_uri: defaultRedirectUri,
  hide_setup_guide: false,
}

class FivetranHTTPClient {
  private axiosInstance: AxiosInstance
  private filterConnectors: string[]

  constructor(options: FivetranClientOptions) {
    const { filterConnectors = [] } = options

    this.filterConnectors = filterConnectors

    this.axiosInstance = axios.create({
      baseURL: EnvVarConfig.REACT_APP_FIVETRAN_API_URL,
      headers: {
        Accept: 'application/json;version=2',
        'Content-Type': 'application/json',
      },
      validateStatus: status => status >= 200 && status < 500,
    })

    const apiKey = EnvVarConfig.REACT_APP_FIVETRAN_API_KEY
    const apiSecret = EnvVarConfig.REACT_APP_FIVETRAN_API_SECRET

    if (apiKey && apiSecret) {
      this.axiosInstance.defaults.auth = {
        username: apiKey,
        password: apiSecret,
      }
    } else {
      throw new Error(
        'Invalid Fivetran API credentials. Please check your environment variables.'
      )
    }

    this.axiosInstance.interceptors.response.use(response =>
      humps.camelizeKeys(response.data)
    )

    this.axiosInstance.interceptors.request.use(request => ({
      ...request,
      data: humps.decamelizeKeys(request.data),
      params: humps.decamelizeKeys(request.params),
    }))
  }

  /**
   * Fetches all connectors for the authenticated user.
   * https://fivetran.com/docs/rest-api/connectors#retrieveconnectortypes
   *
   * @param cursor The cursor to use for pagination
   * @param limit The number of items to return per page (default 100)
   */
  async getPaginatedConnectors(
    cursor?: string,
    limit = 100
  ): Promise<GetConnectorsResponse> {
    try {
      const response: AxiosResponse<GetConnectorsResponse> =
        await this.axiosInstance.get('/metadata/connector-types', {
          params: { cursor: cursor, limit },
        })
      return response.data
    } catch (error) {
      console.error('Error fetching connectors:', error)
      throw error
    }
  }

  /**
   * Fetches all allowed connectors for the authenticated user.
   *
   * @param cursor The cursor to use for pagination
   * @param limit The number of items to return per page (default 100)
   */
  async getAllowedOnlyConnectors(
    cursor?: string,
    limit = 100
  ): Promise<GetConnectorsResponse> {
    try {
      let allConnectors: FivetranConnector[] = []
      let currentCursor: string | undefined = cursor

      do {
        const response: AxiosResponse<GetConnectorsResponse> =
          await this.axiosInstance.get('/metadata/connector-types', {
            params: { cursor: currentCursor, limit },
          })

        const connectors = response.data.items.filter(connector =>
          this.filterConnectors.includes(connector.id)
        )

        allConnectors = allConnectors.concat(connectors)

        currentCursor = response.data.nextCursor
      } while (currentCursor)

      return { items: allConnectors, nextCursor: currentCursor }
    } catch (error) {
      console.error('Error fetching connectors:', error)
      throw error
    }
  }

  /**
   * Fetches all connectors for the authenticated user.
   *
   * @param cursor The cursor to use for pagination
   * @param limit The number of items to return per page (default 100)
   */
  async getAllConnectors(
    cursor?: string,
    limit = 100
  ): Promise<GetConnectorsResponse> {
    try {
      return this.filterConnectors.length > 0
        ? await this.getAllowedOnlyConnectors(cursor, limit)
        : await this.getPaginatedConnectors(cursor, limit)
    } catch (error) {
      console.error('Error fetching connectors:', error)
      throw error
    }
  }

  /**
   * Returns metadata of configuration parameters and authorization parameters for a specified connector type.
   * https://fivetran.com/docs/rest-api/connectors#retrieveconnectordetails
   *
   * @param id The unique identifier for the connector type within the Fivetran system
   */
  async getConnector(id: string): Promise<FivetranConnector> {
    try {
      const response: AxiosResponse<FivetranConnector> =
        await this.axiosInstance.get(`/metadata/connector-types/${id}`)
      return response.data
    } catch (error) {
      console.error(`Error fetching connector with id ${id}:`, error)
      throw error
    }
  }

  /**
   * Creates a new connector within a specified group in your Fivetran account
   * https://fivetran.com/docs/rest-api/connectors#createaconnector
   *
   * @param connectorId The connector type name within the Fivetran system (e.g. 'google_analytics')
   * @param groupId The unique identifier for the group within the Fivetran system
   * @param config The configuration parameters for the connector
   */
  async createConnector({
    connectorId,
    groupId,
    config,
  }: {
    connectorId: string
    groupId: string
    config: ConnectorConfig
  }): Promise<CreateConnectorResponse> {
    try {
      const payload = {
        // We get this when a Hub user select a given Connector from our UI
        service: connectorId,

        // Currently configured at provisioning time when adding a new Hub tenant
        group_id: groupId,

        // Since the connector has no authorization parameters, we must set the
        // run_setup_tests parameter value to false. This setting prevents connector
        // setup tests from running until the end user has authorized access via the
        // Fivetran Connect Card UI.
        paused: true,
        run_setup_tests: false,

        // The configuration parameters for the connector
        config: config,

        connect_card_config: defaultConnectCardConfig,
      }

      return await this.axiosInstance.post('/connectors', payload)
    } catch (error) {
      console.error('Error creating connector:', error)
      throw error
    }
  }

  /**
   * Creates a new connect card for a specified connector in your Fivetran account
   * https://fivetran.com/docs/rest-api/getting-started/connect-card#request
   *
   * @param connectorId The unique identifier for the connector within the Fivetran system
   */
  async createConnectCard(
    connectorId: string
  ): Promise<CreateConnectCardResponse> {
    try {
      const payload = {
        connect_card_config: defaultConnectCardConfig,
      }

      return await this.axiosInstance.post(
        `/connectors/${connectorId}/connect-card`,
        payload
      )
    } catch (error) {
      console.error('Error creating connect card:', error)
      throw error
    }
  }

  /**
   * Fetches all groups for the authenticated user.
   * https://fivetran.com/docs/rest-api/groups#listallgroups
   *
   * @param cursor The cursor to use for pagination
   * @param limit The number of items to return per page (default 100)
   */
  async getGroups(cursor?: string, limit = 100): Promise<GetGroupsResponse> {
    try {
      const currentCursor: string | undefined = cursor

      const response: AxiosResponse<GetGroupsResponse> =
        await this.axiosInstance.get('/groups', {
          params: { cursor: currentCursor, limit },
        })
      return response.data
    } catch (error) {
      console.error('Error fetching groups:', error)
      throw error
    }
  }

  /**
   * Fetches all connectors for a specific group.
   * https://fivetran.com/docs/rest-api/groups#listallconnectorswithinagroup
   *
   * @param groupId The unique identifier for the group within the Fivetran system
   * @param cursor The cursor to use for pagination
   * @param limit The number of items to return per page (default 100)
   */
  async getConnectedSourcesInGroup(
    groupId: string,
    cursor?: string,
    limit = 100
  ): Promise<GetConnectedSourcesResponse> {
    try {
      const currentCursor: string | undefined = cursor

      const response: AxiosResponse<GetConnectedSourcesResponse> =
        await this.axiosInstance.get(`/groups/${groupId}/connectors`, {
          params: { cursor: currentCursor, limit },
        })

      const { items, nextCursor } = response.data

      // Use Promise.all to asynchronously fetch additional metadata for each connector
      const metadataPromises = (items ?? []).map(async item => {
        const metadata = await await this.getConnector(item.service)
        return {
          ...item,
          metadata,
        }
      })

      const itemsWithMetadata = await Promise.all(metadataPromises)

      return { items: itemsWithMetadata, nextCursor: nextCursor }
    } catch (error) {
      console.error('Error fetching connectors within group:', error)
      throw error
    }
  }

  /**
   * Toggle the pause state of an existing connector within your Fivetran account.
   * https://fivetran.com/docs/rest-api/connectors#modifyaconnector
   */
  async toggleConnectorPauseState(
    connectorId: string,
    paused: boolean
  ): Promise<ConnectorPauseStateResponse> {
    try {
      return await this.axiosInstance.patch(`/connectors/${connectorId}`, {
        paused,
      })
    } catch (error) {
      console.error(
        `Error toggling pause state for connector ${connectorId}:`,
        error
      )
      throw error
    }
  }

  /**
   * Update the frequency of an existing connector within your Fivetran account.
   * https://fivetran.com/docs/rest-api/connectors#modifyaconnector
   *
   */
  async updateConnectorFrequency(
    connectorId: string,
    syncFrequency: SyncFrequency
  ): Promise<ConnectorPauseStateResponse> {
    try {
      return await this.axiosInstance.patch(`/connectors/${connectorId}`, {
        sync_frequency: syncFrequency,
      })
    } catch (error) {
      console.error(
        `Error updating sync frequency for connector ${connectorId}:`,
        error
      )
      throw error
    }
  }

  /**
   * Deletes a connector within your Fivetran account.
   * https://fivetran.com/docs/rest-api/connectors#deleteaconnector
   */
  async deleteConnector(connectorId: string): Promise<DeleteConnectorResponse> {
    try {
      return await this.axiosInstance.delete(`/connectors/${connectorId}`)
    } catch (error) {
      console.error(`Error deleting connector ${connectorId}:`, error)
      throw error
    }
  }

  /**
   * Syncs a connector within your Fivetran account. If the connector is paused, it won't sync.
   * https://fivetran.com/docs/rest-api/connectors#syncconnectordata
   *
   * @param connectorId The unique identifier for the connector within the Fivetran system
   * @param force If force is true and the connector is currently syncing, it will stop the sync
   * and re-run it. If force is false, the connector will sync only if it isn't currently syncing.
   */
  async syncConnector(
    connectorId: string,
    force = false
  ): Promise<SyncConnectorResponse> {
    try {
      const payload = {
        force,
      }

      return await this.axiosInstance.post(
        `/connectors/${connectorId}/sync`,
        payload
      )
    } catch (error) {
      console.error(`Error syncing connector ${connectorId}:`, error)
      throw error
    }
  }

  getAxiosInstance(): AxiosInstance {
    return this.axiosInstance
  }
}

export default FivetranHTTPClient
