import React, { useState } from 'react'
import { H3, Spinner, standardIcons } from '@chordco/component-library'
import styled, { css } from 'styled-components'
import { useGetSystemHealthData } from 'components/systemHealth/utils'
import { ChevronRight } from '@chordco/component-library/components/icons/standardIcons/ChevronRight'
import { useHistory } from 'react-router'

const { Check, Exit } = standardIcons

export const SystemStatus: React.FC = () => {
  const [uiLoading, setUiLoading] = useState(true)
  const { health, loading } = useGetSystemHealthData()
  const history = useHistory()

  const allHealthy = Object.values(health).every(v => v)
  const anyLoading = Object.values(loading).every(v => v)

  // We delay showing the status otherwise the tile flashes green before showing an error
  if (uiLoading && !anyLoading) {
    setTimeout(() => {
      setUiLoading(false)
    }, 500)
  }

  const isLoading = uiLoading || anyLoading

  return (
    <>
      <H3>System Health</H3>
      <Status
        allHealthy={allHealthy}
        onClick={() => (allHealthy ? null : history.push('/system-health'))}
      >
        {isLoading ? (
          <SpinnerContainer>
            <Spinner scale={20} />
          </SpinnerContainer>
        ) : (
          <Circle allHealthy={allHealthy}>
            {allHealthy ? <Check scale={16} /> : <Exit scale={16} />}
          </Circle>
        )}
        <Text>
          {allHealthy
            ? 'All systems operating as expected'
            : 'View error(s) in systems'}
        </Text>
        {!allHealthy && (
          <Right>
            <ChevronRight />
          </Right>
        )}
      </Status>
    </>
  )
}

const Status = styled.div<{ allHealthy: boolean }>`
  position: relative;
  display: flex;
  padding: 12px 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 16px;
  background-color: ${p => p.theme.ComponentWorkspaceBgPage};
  border: solid 1px ${p => p.theme.BorderOptimal};

  ${p =>
    !p.allHealthy &&
    css`
      cursor: pointer;

      :hover {
        border-color: ${p => p.theme.PRIMARY_50};
      }
    `}
`

const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 20px;
`

const Circle = styled.div<{ allHealthy: boolean }>`
  position: absolute;
  top: calc(50% - 12px);
  left: 8px;
  background-color: ${p =>
    p.allHealthy ? p.theme.GREEN_90 : p.theme.RED_60_BASE};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: ${p => (p.allHealthy ? 3 : 4)}px;

  text-align: center;
  display: table-cell;
  vertical-align: middle;

  path {
    fill: ${p => p.theme.WHITE};
  }
`

const Text = styled.div`
  margin: 0 24px;
`

const Right = styled.div`
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;

  path {
    fill: ${p => p.theme.WHITE};
  }
`
