import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

interface Props extends IconProps {
  isSmall?: boolean
}

export const ChordLogoFull: React.FC<Props> = ({
  fill,
  isSmall = false,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={isSmall ? 92 : 134}
      height={isSmall ? 26 : 39}
      viewBox="0 0 119 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.0617 18.5635C54.7482 20.4177 53.9436 21.8762 52.6478 22.9387C51.352 23.9804 49.6904 24.5013 47.6632 24.5013C46.0748 24.5013 44.6745 24.1679 43.4623 23.5012C42.2501 22.8137 41.3096 21.8449 40.6408 20.5948C39.9929 19.3448 39.6689 17.9072 39.6689 16.2821C39.6689 14.6571 40.0033 13.2299 40.6721 12.0007C41.3409 10.7715 42.2814 9.82351 43.4936 9.15681C44.7267 8.46928 46.1479 8.12551 47.7572 8.12551C49.7636 8.12551 51.4042 8.65678 52.6791 9.71933C53.954 10.761 54.7482 12.1882 55.0617 14.0008H51.5819C51.352 13.0841 50.8922 12.3549 50.2025 11.8132C49.5128 11.2715 48.6663 11.0006 47.6632 11.0006C46.3047 11.0006 45.2074 11.4903 44.3714 12.4695C43.5563 13.4487 43.1488 14.7196 43.1488 16.2821C43.1488 17.8655 43.5563 19.1573 44.3714 20.1573C45.2074 21.1365 46.3047 21.6261 47.6632 21.6261C48.7081 21.6261 49.5755 21.3553 50.2652 20.8136C50.9549 20.2511 51.4042 19.501 51.6132 18.5635H55.0617Z"
        fill={fill || theme.OFF_TEXT_BASE}
      />
      <path
        d="M65.896 8.12551C67.8188 8.12551 69.3758 8.70887 70.5671 9.87559C71.7793 11.0423 72.3854 12.9174 72.3854 15.5009V24.3138H68.9997V15.6884C68.9997 14.1675 68.6653 13.0112 67.9965 12.2195C67.3486 11.4277 66.3976 11.0319 65.1436 11.0319C63.806 11.0319 62.7506 11.5007 61.9773 12.4382C61.2249 13.3549 60.8487 14.6154 60.8487 16.2196V24.3138H57.4629V2.43774H60.8487V10.6256C62.0609 8.95888 63.7433 8.12551 65.896 8.12551Z"
        fill={fill || theme.OFF_TEXT_BASE}
      />
      <path
        d="M82.7377 24.5013C81.1284 24.5013 79.6968 24.1679 78.4428 23.5012C77.2097 22.8137 76.2483 21.8553 75.5586 20.6261C74.8898 19.376 74.5554 17.9385 74.5554 16.3134C74.5554 14.7092 74.8898 13.2924 75.5586 12.0632C76.2483 10.8131 77.2097 9.84434 78.4428 9.15681C79.6968 8.46928 81.1284 8.12551 82.7377 8.12551C84.347 8.12551 85.7682 8.46928 87.0013 9.15681C88.2553 9.84434 89.2167 10.8131 89.8855 12.0632C90.5752 13.2924 90.92 14.7092 90.92 16.3134C90.92 17.9385 90.5752 19.376 89.8855 20.6261C89.2167 21.8553 88.2553 22.8137 87.0013 23.5012C85.7682 24.1679 84.347 24.5013 82.7377 24.5013ZM82.7377 21.6261C84.1589 21.6261 85.298 21.1365 86.1549 20.1573C87.0327 19.1573 87.4716 17.876 87.4716 16.3134C87.4716 14.7508 87.0327 13.4799 86.1549 12.5007C85.298 11.5007 84.1589 11.0006 82.7377 11.0006C81.3165 11.0006 80.1775 11.5007 79.3206 12.5007C78.4637 13.4799 78.0353 14.7508 78.0353 16.3134C78.0353 17.876 78.4637 19.1573 79.3206 20.1573C80.1775 21.1365 81.3165 21.6261 82.7377 21.6261Z"
        fill={fill || theme.OFF_TEXT_BASE}
      />
      <path
        d="M102.201 8.31302V11.4694H100.696C99.296 11.4694 98.2824 11.9278 97.6554 12.8445C97.0284 13.7404 96.7149 14.8862 96.7149 16.2821V24.3138H93.3291V8.31302H96.3387L96.7149 10.7194C97.1747 9.96935 97.7703 9.38599 98.5018 8.9693C99.2333 8.53178 100.216 8.31302 101.449 8.31302H102.201Z"
        fill={fill || theme.OFF_TEXT_BASE}
      />
      <path
        d="M118.852 2.43774V24.3138H115.906L115.498 21.9074C114.223 23.6367 112.478 24.5013 110.263 24.5013C108.8 24.5013 107.493 24.1783 106.344 23.5325C105.194 22.8658 104.296 21.9178 103.648 20.6886C103 19.4385 102.676 17.9801 102.676 16.3134C102.676 14.6883 103 13.2612 103.648 12.0319C104.317 10.7819 105.226 9.82351 106.375 9.15681C107.525 8.46928 108.821 8.12551 110.263 8.12551C111.433 8.12551 112.457 8.34427 113.335 8.78179C114.213 9.19848 114.923 9.78184 115.467 10.5319V2.43774H118.852ZM110.827 21.6574C112.227 21.6574 113.356 21.1678 114.213 20.1886C115.07 19.2094 115.498 17.9385 115.498 16.3759C115.498 14.7717 115.07 13.4799 114.213 12.5007C113.356 11.5007 112.227 11.0006 110.827 11.0006C109.427 11.0006 108.298 11.5007 107.441 12.5007C106.584 13.4799 106.156 14.7508 106.156 16.3134C106.156 17.8968 106.584 19.1885 107.441 20.1886C108.298 21.1678 109.427 21.6574 110.827 21.6574Z"
        fill={fill || theme.OFF_TEXT_BASE}
      />
      <path
        d="M8.52299 8.3731C5.03214 11.8657 5.03038 17.5275 8.52299 21.0184C12.0156 24.5092 17.6774 24.511 21.1683 21.0184M3.76691 13.1292C0.27431 16.6218 0.27431 22.2836 3.76691 25.7744C7.25952 29.2653 12.9213 29.267 16.4122 25.7744L25.9226 16.2641C22.43 19.7567 16.7681 19.7567 13.2773 16.2641C10.627 13.6124 9.98784 9.71047 11.3621 6.45748C14.615 5.08305 18.5167 5.72159 21.1683 8.3731L25.9226 3.61879C22.4317 0.126189 16.7699 0.127951 13.2773 3.61879L3.76691 13.1292Z"
        stroke={fill}
        strokeMiterlimit="10"
      />
    </svg>
  )
}
