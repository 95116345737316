import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import styled, { css } from 'styled-components'
import chordLogo from 'assets/ChordCircleLogo.png'
import { Spinner, standardIcons, useTheme } from '@chordco/component-library'
import { sectionComponents } from 'components/systemHealth/Section'
import { ChevronRight } from '@chordco/component-library/components/icons/standardIcons/ChevronRight'
import { useBusinessErrorsData } from 'redux/state/businessErrors'
import { useIsLoading } from 'redux/utils'
import { useEnvironmentsData } from 'redux/state/environments'

const {
  Container,
  Top,
  Title,
  SubTitle,
  SpinnerContainer,
  StyledAvatar,
  IconContainer,
} = sectionComponents

const { CircleCheck, ExitCircle } = standardIcons

interface Props {
  tenantId?: number
  isSmall?: boolean
}

export const ServicesStatus: React.FC<Props> = ({
  tenantId,
  isSmall = false,
}) => {
  const theme = useTheme()

  const {
    state: { businessErrorList },
    getBusinessErrors,
  } = useBusinessErrorsData()
  const history = useHistory()

  const {
    state: { selectedEnvironment: environment },
  } = useEnvironmentsData()

  useEffect(() => {
    if (tenantId) getBusinessErrors({ tenantId, environment })
  }, [tenantId, environment])

  const isLoading = useIsLoading('getBusinessErrors')
  const errorCount = businessErrorList.reduce(
    (acc, curr) => acc + Number(curr.count),
    0
  )
  const operatingCorrectly = errorCount === 0

  return (
    <StyledContainer
      isClickable={!operatingCorrectly}
      onClick={() => history.push(`business-errors`)}
    >
      <Top>
        <StyledAvatar src={chordLogo} size={48} />
        <Title isSmall={isSmall}>Order Management System (OMS)</Title>
        <SubTitle>Chord</SubTitle>

        {isLoading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          typeof operatingCorrectly !== 'undefined' && (
            <StyledIconContainer>
              {operatingCorrectly ? (
                <CircleCheck fill={theme.ContentAccentSuccess} />
              ) : (
                <>
                  <Errors>
                    {errorCount} Error{errorCount > 1 ? 's' : ''}
                  </Errors>
                  <ExitCircle fill={theme.ContentAccentError} />
                </>
              )}
            </StyledIconContainer>
          )
        )}

        <Right operatingCorrectly={operatingCorrectly}>
          <ChevronRight />
        </Right>
      </Top>
    </StyledContainer>
  )
}

const StyledContainer = styled(Container)<{ isClickable: boolean }>`
  ${p =>
    p.isClickable &&
    css`
      :hover {
        border-color: ${p => p.theme.BorderAccentPrimary};
        cursor: pointer;
      }
    `}
`

const StyledIconContainer = styled(IconContainer)`
  display: flex;
  top: 18px;
`

const Errors = styled.div`
  margin-right: 8px;
  color: ${p => p.theme.ContentAccentError};
  line-height: 1.2;
`

const Right = styled.div<{ operatingCorrectly: boolean }>`
  position: absolute;
  top: 16px;
  right: 32px;

  path {
    opacity: ${p => (p.operatingCorrectly ? 0.5 : 1)};
  }
`
