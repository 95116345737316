export const getDefaultMatchers = (builder, typePrefix: string) => {
  builder.addMatcher(
    action => action.type.includes(typePrefix + '/pending'),
    state => {
      state.status = 'loading'
      state.count = state.count + 1
    }
  )
  builder.addMatcher(
    action => action.type.includes(typePrefix + '/fulfilled'),
    state => {
      const newCount = state.count - 1
      if (newCount === 0) {
        state.status = 'succeeded'
      }

      state.count = newCount
    }
  )
  builder.addMatcher(
    action => action.type.includes(typePrefix + '/rejected'),
    (state, action) => {
      const newCount = state.count - 1
      if (newCount === 0) {
        state.status = 'failed'
      }

      state.error = action.error.message
      state.count = newCount
    }
  )
}
