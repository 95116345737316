import { useEffect, useState } from 'react'
import { Treatment } from 'utils/experiments/treatment'
import { useSplit } from './useSplit'

export const useExperiments = (experimentName: string) => {
  const [treatment, setTreatment] = useState<Treatment>()
  const { manager } = useSplit()

  useEffect(() => {
    manager?.treatment(experimentName).then(t => {
      setTreatment(t)
    })
  }, [manager, experimentName])

  return treatment
}
