import React from 'react'
import styled from 'styled-components'
import { standardIcons, AlertModal, Button } from '@chordco/component-library'

const { Upload } = standardIcons

type Props = {
  uploadCSVSheet: (file: File) => Promise<void>
  onClose: () => void
  processingSheetUpload: boolean
}

export const ImportCSVSheetModal: React.FC<Props> = ({
  onClose,
  uploadCSVSheet,
  processingSheetUpload,
}) => {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
  const [file, setFile] = React.useState<File | null>(null)
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0].size > 10000000) {
      setErrorMessage(
        'File size is too large. Please upload a file smaller than 10MB'
      )
      return
    }

    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0])
      setErrorMessage(null)
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setErrorMessage(null)
    if (!file) {
      setErrorMessage('Please select a file to upload')
      return
    }
    event.preventDefault()
    await uploadCSVSheet(file)
    onClose()
  }

  return (
    <AlertModal
      onClose={onClose}
      icon={Upload}
      title="Import CSV"
      content="Append data to the sheet"
      subContent="Make sure you select a valid CSV file"
      centered
      buttons={[
        {
          id: 1,
          text: 'Cancel',
          onClick: onClose,
          purpose: 'tertiary',
          disabled: false,
        },
        {
          id: 2,
          text: 'Upload',
          onClick: handleSubmit,
          purpose: 'primary',
          disabled: processingSheetUpload,
        },
      ]}
    >
      <Container>
        <ErrorContainer>{errorMessage}</ErrorContainer>
        {file?.name}
        <FileForm onSubmit={handleSubmit}>
          <FileInput onFileSelect={handleFileChange} />
        </FileForm>
      </Container>
    </AlertModal>
  )
}

interface FileInputProps {
  onFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const FileInput: React.FC<FileInputProps> = ({ onFileSelect }) => {
  const fileInputRef = React.createRef<HTMLInputElement>()

  return (
    <Button purpose="secondary" onClick={() => fileInputRef.current?.click()}>
      Browse File
      <HiddenFileInput ref={fileInputRef} type="file" onChange={onFileSelect} />
    </Button>
  )
}

const HiddenFileInput = styled.input.attrs({ type: 'file' })`
  display: none;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const FileForm = styled.form`
  display: block;
  justify-content: center;
  margin: 20px 0;
`

const ErrorContainer = styled.div`
  color: red;
`
