import React from 'react'
import { useTheme } from '@chordco/component-library'
import { useEnvironmentsData } from 'redux/state/environments'
import { StoreEnvironment } from 'types'
import { OptionType, Switcher } from './Switcher'
import { useExperiments } from 'hooks'
import { setCurrentEnvironment } from 'redux/utils/localStorageHelpers'

export const Environment: React.FC = () => {
  const theme = useTheme()

  const COLOR_BY_ENVIRONMENT = {
    production: theme.ContentAccentTertiary,
    staging: theme.ContentAccentWarning,
    testing: theme.ContentAccentSecondary,
  }

  const {
    state: { environments, selectedEnvironment },
    selectEnvironment,
  } = useEnvironmentsData()

  if (!selectedEnvironment) return null

  let options: OptionType[] = []
  let selectedOption: OptionType | undefined

  const selectOption = (option: OptionType) =>
    selectEnvironment(option.name as StoreEnvironment)

  // Use the experiment variation to determine which environments to show. Default to all
  // environments provided by the database in case the experiment is not running, or if
  // the requested variation is not specified.
  const experiment = useExperiments('hub_selected_environment')

  switch (experiment?.variation) {
    case 'staging':
    case 'production':
      selectedOption = { id: 1, name: experiment.variation as StoreEnvironment }
      options = [selectedOption]
      setCurrentEnvironment(selectedOption.name as StoreEnvironment)
      break

    default:
      options = environments.map((environment, index) => ({
        id: index + 1,
        name: environment,
      }))

      selectedOption = options.find(
        option => option.name === selectedEnvironment
      )
  }

  return (
    <Switcher
      options={options}
      selectOption={selectOption}
      selectedOption={selectedOption}
      type="environment"
      dotColors={COLOR_BY_ENVIRONMENT}
    />
  )
}
