import { ConnectorConfig, FivetranConnector } from 'api/fivetran/interfaces'
import { useEnvironmentsData } from 'redux/state/environments'
import { useTenantsData } from 'redux/state/tenants'

/**
 * Returns the configuration for a given Fivetran connector, tenant and environment.
 * See https://www.notion.so/Segment-1326c5d3f2bd45c580bf15201e2beaf8#30b53a378a974686bc7600351f40ca92
 * for more details.
 *
 * Examples:
 * raw_catchco_production_mysterytacklebox_chordoms_fivetran_chordoms_mysterytacklebox
 * raw_monicaandandy_production_monicaandandy_shopify_fivetran_shopify_monicaandandy
 * raw_thesis_production_na_na_fivetran_facebookads_facebookads
 */
const useFivetranConnectorConfig = (
  connector: FivetranConnector
): { config: ConnectorConfig } => {
  const {
    state: { currentTenant, currentStoreIsAutonomy },
  } = useTenantsData()

  const {
    state: { selectedEnvironment },
  } = useEnvironmentsData()

  const tenantName =
    currentTenant?.name
      // Matches any character that is not a word character (\w) or a whitespace character (\s).
      // The `g` flag ensures a global search, replacing all occurrences.
      .replace(/[^\w\s]/g, '')
      // Replaces all non-word and non-space characters with an empty string.
      .replace(/\s+/g, '')
      // converts the string to lowercase, or use 'na' as the tenant name if the tenant name is
      // undefined (which should never happen but since it's an optional, better safe than sorry)
      .toLowerCase() || 'na'

  const isOmsConnector =
    connector.id === 'shopify' || connector.id === 'postgres'

  const omsTenantName = isOmsConnector
    ? currentTenant?.metadata?.omsTenantName
    : 'na'

  const omsType = isOmsConnector
    ? currentStoreIsAutonomy
      ? 'chordoms'
      : 'shopify'
    : 'na'

  const integrationType = connector.id.replace(/_/g, '')

  const integrationName = isOmsConnector ? tenantName : integrationType

  const schema = `raw_${tenantName}_${selectedEnvironment}_${omsTenantName}_${omsType}_fivetran_${integrationType}_${integrationName}`

  /**
   * Destination schema vs destination schema prefix:
   * https://fivetran.com/docs/core-concepts#destinationschemavsdestinationschemaprefix
   *
   * - When setting up a database connector, we must specify a destination schema prefix.
   *   The schema prefix that we create is appended to the names of the source schemas.
   *
   * - For non-database connectors we specify the destination schema name.
   *   These types of connectors replicate a single schema to our destination.
   *
   * - For webhooks and file connectors, we choose the table name in addition to choosing the
   *   schema name.
   *
   * - For all connectors except webhooks and file connectors, Fivetran auto-generates the table
   *   and column names from the names of corresponding objects.
   */
  let config: ConnectorConfig = {}

  switch (connector.type) {
    // Database connectors require a schema prefix.
    case 'Database':
      config = {
        schema_prefix: schema,
      }
      break

    // Webhooks and file connectors require a table name in addition to the schema name.
    case 'File':
    case 'Events':
      config = {
        schema: schema,
        table: tenantName,
      }
      break

    default:
      // For all other connectors, we only need to specify the schema name.
      config = {
        schema: schema,
      }
      break
  }

  return { config }
}

export default useFivetranConnectorConfig
