import React from 'react'
import { Chip, useTheme } from '@chordco/component-library'

export const ComingSoonChip: React.FC = () => {
  const theme = useTheme()

  return (
    <Chip
      leftIconFill={theme.ContentPrimary}
      leftIconSize={12}
      text="Coming Soon"
      color="warning"
      variant="solid-secondary"
    />
  )
}
