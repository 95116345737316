import React, { useCallback, useEffect, useReducer, useState } from 'react'
import styled from 'styled-components'
import {
  useTheme,
  standardIcons,
  Sheet,
  Spinner,
  Button,
  H5,
} from '@chordco/component-library'
import { useIsMobile, useExperiments } from 'hooks'
import { ConnectCard, FivetranConnector } from 'api/fivetran/interfaces'
import { useFivetran } from '../FivetranContext'

import { ConnectSourceSheet } from './ConnectSourceSheet'
import { SourceBrowser } from '../components'

import { useNotifications } from 'redux/state/notifications'
import { v4 as generateUUID } from 'uuid'

import { actions, reducer, initialState } from '../store'
import { SourceRequestSheet } from './SourceRequestSheet'

const { CloseX, Plus } = standardIcons

type Props = {
  onClose: () => void
}

export const AddSourceSheet: React.FC<Props> = ({ onClose }) => {
  const theme = useTheme()
  const isMobile = useIsMobile()

  const sourceRequestTreatment = useExperiments(
    'hub_fivetran_data_source_request'
  )

  const { addNotification } = useNotifications()

  const { fivetranClient, isModeled } = useFivetran()

  // Use useReducer hook to manage all our pagination state
  const [state, dispatch] = useReducer(reducer, initialState)

  const [selectedConnector, setSelectedConnector] =
    useState<FivetranConnector>()

  const [sourceRequestSheetOpen, setSourceRequestSheetOpen] = useState(false)

  /**
   * Fetches connectors from the Fivetran API
   */
  const fetchConnectors = useCallback(
    async (cursor?: string) => {
      dispatch(actions.fetchStart())

      try {
        const response = await fivetranClient?.getAllConnectors(cursor)

        const connectors = response?.items ?? []
        const nextCursor = response?.nextCursor ?? undefined
        const prevCursor = cursor

        dispatch(actions.fetchSuccess(connectors, nextCursor, prevCursor))
      } catch (error: any) {
        dispatch(actions.fetchError(error))
      }
    },
    [fivetranClient]
  )

  /**
   * Fetch initial batch of connectors on mount
   */
  useEffect(() => {
    fetchConnectors()
  }, [fetchConnectors])

  /**
   * Fetch connectors for the next page
   */
  const handleNextPage = useCallback(() => {
    if (state.nextCursor) {
      fetchConnectors(state.nextCursor)
    }
  }, [state.nextCursor, fetchConnectors])

  /**
   * Fetch connectors for the previous page
   */
  const handlePrevPage = useCallback(() => {
    // Get the cursor in state.cursors that comes before state.prevCursor
    const currentIndex = state.cursors.indexOf(state.prevCursor)

    if (currentIndex >= 0) {
      const prevCursor = state.cursors[currentIndex - 1]
      fetchConnectors(prevCursor)
    }
  }, [state.prevCursor, fetchConnectors])

  const handleConnectSuccess = (connectCard: ConnectCard) => {
    // Start the Connect with Fivetran external flow
    window.open(connectCard.uri, '_blank', 'noreferrer')
  }

  const handleConnectError = (message: string) => {
    addNotification({
      id: generateUUID(),
      type: 'warning',
      message: message,
    })
  }

  return (
    <Sheet
      title="Add Data Sources"
      onClose={onClose}
      customWidth="70%"
      isLarge={false}
    >
      <Container isMobile={isMobile}>
        {state.error && (
          <ErrorContainer>
            <div>
              An error occurred while fetching data sources. Please try again.
            </div>
            <span onClick={() => dispatch(actions.fetchError(undefined))}>
              <CloseX fill={theme.GREY_80} />
            </span>
          </ErrorContainer>
        )}

        {selectedConnector && (
          <ConnectSourceSheet
            source={selectedConnector}
            modeled={isModeled(selectedConnector.id)}
            onClose={() => setSelectedConnector(undefined)}
            onConnectSuccess={handleConnectSuccess}
            onConnectError={handleConnectError}
          />
        )}

        {sourceRequestSheetOpen && (
          <SourceRequestSheet
            onClose={() => setSourceRequestSheetOpen(false)}
          />
        )}

        {state.loading && (
          <LoaderContainer>
            <Spinner scale={30} />
          </LoaderContainer>
        )}

        {!state.loading && (
          <>
            {state.items && state.items.length > 0 ? (
              <SourceBrowser
                sources={state.items as FivetranConnector[]}
                onSelectSource={setSelectedConnector}
                onPrevPage={handlePrevPage}
                onNextPage={handleNextPage}
                showNextPageButton={state.nextCursor !== undefined}
                showPreviousPageButton={state.prevCursor !== undefined}
              />
            ) : (
              <Warning>No data sources found</Warning>
            )}
          </>
        )}
      </Container>

      {sourceRequestTreatment?.enabled && (
        <Bottom>
          <H5>Missing your desired data source?</H5>
          <Button
            icon={Plus}
            purpose="tertiary"
            variant="outlined"
            onClick={() => setSourceRequestSheetOpen(!sourceRequestSheetOpen)}
          >
            Request
          </Button>
        </Bottom>
      )}
    </Sheet>
  )
}

const Container = styled.div<{ isMobile: boolean }>`
  padding: ${p => (p.isMobile ? 12 : 24)}px;
  min-height: calc(100vh - 76px);
  padding: ${p =>
    `8px ${p.isMobile ? 12 : 24}px 100px ${p.isMobile ? 12 : 24}px`};
  position: relative;

  & > div {
    margin-bottom: 16px;

    :last-child {
      margin-bottom: 0;
    }
  }
`

const LoaderContainer = styled.div`
  position: relative;
  height: 200px;
  width: 100%;
`

const ErrorContainer = styled.div`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${p => p.theme.RED_30};
  border-radius: 8px;
  color: ${p => p.theme.GREY_80};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 12px;

  & > span {
    cursor: pointer;
  }
`

const Warning = styled.div`
  width: 100%;
  border-radius: 8px;
  background: ${p => p.theme.AMBER_30};
  padding: 12px;
  font-size: 12px;
  color: ${p => p.theme.GREY_80};
`

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 16px 12px;
  width: 100%;
  height: 72px;
  position: fixed;
  bottom: 0;
  border-top: 1px solid ${p => p.theme.BorderHairline};
  background-color: ${p => p.theme.ComponentWorkspaceBgSheet};
  border-radius: 0 0 0 16px;
`
