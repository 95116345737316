import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { debounce } from 'lodash'
import { standardIcons } from '@chordco/component-library'

type SearchQuickstartsProps = {
  onSearchChange: (searchTerm: string) => void
}

const SearchQuickstarts: React.FC<SearchQuickstartsProps> = ({
  onSearchChange,
}) => {
  const [searchTerm, setSearchTerm] = useState('')

  const debouncedSearch = debounce((value: string) => {
    onSearchChange(value)
  }, 300)

  useEffect(() => {
    debouncedSearch(searchTerm)

    return () => {
      debouncedSearch.cancel()
    }
  }, [searchTerm])

  return (
    <SearchBarContainer>
      <SearchInputWrapper>
        <StyledSearchIcon />
        <StyledInput
          type="text"
          placeholder="Search quickstarts..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </SearchInputWrapper>
    </SearchBarContainer>
  )
}

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
`

const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
`

const StyledSearchIcon = styled(standardIcons.Search)`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: ${p => p.theme.ComponentFieldBorderDefault};
`

const StyledInput = styled.input`
  width: 300px;
  padding: 8px 8px 8px 40px;
  border: 1px solid ${p => p.theme.ComponentFieldBorderDefault};
  border-radius: 8px;
  font-size: 16px;
  outline: none;
`

export default SearchQuickstarts
