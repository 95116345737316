import React, { useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { Button, standardIcons } from '@chordco/component-library'
import { UserDashboardEmbed } from './UserDashboardEmbed'
import { UserLookEmbed } from './UserLookEmbed'
import { CreateDashboardModal } from './CreateDashboardModal'
import { AvailableUserReport, LookerReportType } from 'types'
import { ShareReportModal } from './ShareReportModal'
import { ImportReportModal } from './ImportReportModal'
import { useAuthData } from 'redux/state/auth'
import { PublishDashboardModal } from './PublishDashboardModal'
import { PublishConfirmationModal } from './PublishConfirmationModal'
import { SelectChangeEvent } from '@mui/material'
import { MyFolderDropdown } from './MyFolderDropDown'
import { AddNewReportButton } from './AddNewReportButton'
import {
  QuickstartCategory,
  QuickstartType,
  useQuickstartsFilter,
} from 'redux/state/quickstartsFilter'
import { CreateLookAlert } from './CreateLookAlert'
import { PublishLookModal } from './PublishLookModal'
import { LookEvent } from '@looker/embed-sdk'

type MyFolderProps = {
  slug?: string
  dashboards: AvailableUserReport[]
  looks: AvailableUserReport[]
  onDeletedDashboard: () => void
  onDeletedLook: (event: LookEvent) => void
  onSavedLook: (event: LookEvent) => void
  onPublishedDashboard: () => void
  onPublishedLook: () => void
}

export const MyFolder: React.FC<MyFolderProps> = ({
  slug,
  dashboards,
  looks,
  onDeletedDashboard,
  onDeletedLook,
  onSavedLook,
  onPublishedDashboard,
  onPublishedLook,
}) => {
  const selectedOption =
    dashboards.find(d => d.slug === slug) || looks.find(l => l.slug === slug)

  if (!selectedOption) return null

  const history = useHistory()

  const [showCreateLookModal, setShowCreateLookModal] = useState(false)
  const [showCreateDashboard, setShowCreateDashboard] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const [showImportModal, setShowImportModal] = useState(false)
  const [showPublishDashboardModal, setShowPublishDashboardModal] =
    useState(false)
  const [showPublishLookModal, setShowPublishLookModal] = useState(false)
  const [showNameAlreadyTaken, setShowNameAlreadyTaken] = useState<
    | {
        name: string
        description: string
        slug: string
        type: LookerReportType
      }
    | undefined
  >(undefined)

  const { setFilters } = useQuickstartsFilter()

  const redirectToReport = (report: AvailableUserReport) => {
    history.push(`/analytics/my_folder/${report.slug}`)
  }

  const handleSelectChanged = (event: SelectChangeEvent<string>) => {
    const report = [...dashboards, ...looks].find(
      d => d.id === event.target.value
    )
    if (report) redirectToReport(report)
  }

  const handleCreateLook = () => {
    // There is no way to create a completely "blank" look without defining some query upfront.
    // For that reason, we are redirecting users to our Quickstarts page to create a new look from
    // an existing template.
    setFilters({
      selectedType: QuickstartType.LOOKS,
      selectedCategory: QuickstartCategory.ALL_CATEGORIES,
      searchTerm: '',
    })

    history.push('/analytics/quickstarts')
  }

  const handleShare = () => {
    setShowShareModal(true)
  }

  const handleImport = () => {
    setShowImportModal(true)
  }

  const handlePublishReport = () => {
    if (selectedOption.metricType === 'dashboard') {
      setShowPublishDashboardModal(true)
    } else if (selectedOption.metricType === 'look') {
      setShowPublishLookModal(true)
    }
  }

  const handleNameAlreadyTaken = (data: {
    name: string
    description: string
    slug: string
    type: LookerReportType
  }) => {
    setShowNameAlreadyTaken(data)
  }

  const handleCreateDashboard = (dashboard?: AvailableUserReport) => {
    setShowCreateDashboard(false)
    if (dashboard) redirectToReport(dashboard)
  }

  const handleImportedReport = (report?: AvailableUserReport) => {
    setShowImportModal(false)
    if (report) redirectToReport(report)
  }

  const {
    state: { user },
  } = useAuthData()

  const isAdmin = user?.role === 'admin' || user?.role === 'superuser'

  return (
    <Wrapper>
      <Top>
        <MyFolderDropdown
          dashboards={dashboards}
          looks={looks}
          onChange={handleSelectChanged}
          defaultValue={selectedOption.id}
        />

        <RightButtons>
          <Button
            purpose="tertiary"
            icon={standardIcons.Send}
            onClick={handleShare}
          >
            Share
          </Button>

          <Button
            purpose="tertiary"
            icon={standardIcons.Import}
            onClick={handleImport}
          >
            Import
          </Button>

          {isAdmin && (
            <Button
              purpose="tertiary"
              icon={standardIcons.Publish}
              onClick={handlePublishReport}
            >
              Publish
            </Button>
          )}

          <AddNewReportButton
            onCreateNewDashboard={() => setShowCreateDashboard(true)}
            onCreateNewLook={() => setShowCreateLookModal(true)}
          />
        </RightButtons>
      </Top>
      <EmbedContainer>
        {selectedOption.metricType === 'dashboard' && (
          <UserDashboardEmbed
            dashboardId={selectedOption.slug}
            onDeletedDashboard={onDeletedDashboard}
          />
        )}

        {selectedOption.metricType === 'look' && (
          <UserLookEmbed
            lookId={selectedOption.slug}
            onDeletedLook={onDeletedLook}
            onSavedLook={onSavedLook}
          />
        )}
      </EmbedContainer>

      {showCreateDashboard && (
        <CreateDashboardModal
          onClose={() => setShowCreateDashboard(false)}
          onCreateDashboard={handleCreateDashboard}
        />
      )}

      {showCreateLookModal && (
        <CreateLookAlert
          onClose={() => setShowCreateLookModal(false)}
          onCreateLook={handleCreateLook}
        />
      )}

      {showShareModal && (
        <ShareReportModal
          link={`${window.location.origin}/my_folder/${selectedOption.slug}?type=${selectedOption.metricType}`}
          onClose={() => setShowShareModal(false)}
        />
      )}

      {showImportModal && selectedOption.folderId && (
        <ImportReportModal
          folderId={selectedOption.folderId}
          onClose={() => setShowImportModal(false)}
          onImported={handleImportedReport}
        />
      )}

      {showPublishDashboardModal && (
        <PublishDashboardModal
          dashboardName={selectedOption.label}
          dashboardSlug={selectedOption.slug}
          onClose={() => setShowPublishDashboardModal(false)}
          onPublish={onPublishedDashboard}
          onNameAlreadyTaken={handleNameAlreadyTaken}
        />
      )}

      {showPublishLookModal && (
        <PublishLookModal
          lookName={selectedOption.label}
          lookSlug={selectedOption.slug}
          onClose={() => setShowPublishLookModal(false)}
          onPublish={onPublishedLook}
          onNameAlreadyTaken={handleNameAlreadyTaken}
        />
      )}

      {showNameAlreadyTaken && (
        <PublishConfirmationModal
          name={showNameAlreadyTaken.name}
          description={showNameAlreadyTaken.description}
          slug={showNameAlreadyTaken.slug}
          type={showNameAlreadyTaken.type}
          onCancel={() => setShowNameAlreadyTaken(undefined)}
          onPublish={onPublishedDashboard}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100vh;
  padding: 0 64px 0 64px;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
`

const RightButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const EmbedContainer = styled.div`
  border-radius: 0 0 0 ${p => p.theme.PageRadius};
  border-top: 1px solid ${p => p.theme.BorderOptimal};
  height: 100%;
`
