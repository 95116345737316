import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const MountainFlag: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4 19.45C13.6486 19.7814 14.1187 19.8485 14.45 19.6C14.7814 19.3515 14.8486 18.8814 14.6 18.55L12.6251 15.9167L13.5258 14.7158L17.4196 19.4749C17.6819 19.7955 18.1544 19.8428 18.475 19.5805C18.7956 19.3182 18.8429 18.8457 18.5806 18.5251L14.0806 13.0251C13.9346 12.8467 13.7147 12.7453 13.4843 12.7502C13.2538 12.755 13.0384 12.8656 12.9001 13.05L11.6876 14.6667L8.75004 10.75V8.75H17C17.2766 8.75 17.5308 8.59776 17.6613 8.35389C17.7918 8.11003 17.7775 7.81412 17.6241 7.58397L15.9014 5L17.6241 2.41603C17.7775 2.18588 17.7918 1.88997 17.6613 1.64611C17.5308 1.40224 17.2766 1.25 17 1.25H8.00004C7.58583 1.25 7.25004 1.58579 7.25004 2V10.75L1.40004 18.55C1.15152 18.8814 1.21867 19.3515 1.55004 19.6C1.88142 19.8485 2.35152 19.7814 2.60005 19.45L8.00004 12.25L13.4 19.45ZM15.5987 7.25H8.75004V2.75H15.5987L14.376 4.58397C14.2081 4.8359 14.2081 5.1641 14.376 5.41603L15.5987 7.25Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
