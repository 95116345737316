import React from 'react'
import styled, { keyframes } from 'styled-components'
import { useTheme } from '@chordco/component-library'

export type PulseStatusType = 'active' | 'success' | 'failure'

interface Props {
  status: PulseStatusType
}

export const Pulse: React.FC<Props> = ({ status }) => {
  const theme = useTheme()

  const colors = {
    active: {
      backgroundColor: theme.GREY_50,
      shadowColor: theme.GREY_10,
    },
    success: {
      backgroundColor: theme.PRIMARY_50,
      shadowColor: theme.PRIMARY_10,
    },
    failure: { backgroundColor: theme.RED_50, shadowColor: theme.RED_10 },
  }

  const color = colors[status]
  return <Circle {...color} />
}

const getPulse = (shadowColor: string) => keyframes`
 0%{
  box-shadow: 0 0 0 0 ${shadowColor};
  transform: scale(1, 1);
}
50% {
  box-shadow: 0 0 4px 7px ${shadowColor};
  transform: scale(1.2, 1.2);
}
100%{
  box-shadow: 0 0 0 0 ${shadowColor};
  transform: scale(1, 1);
}
`

const Circle = styled.div<{ backgroundColor: string; shadowColor: string }>`
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background-color: ${p => p.backgroundColor};
  animation: ${p => getPulse(p.shadowColor)} 2s linear infinite;
`
