import React from 'react'
import { AlertModal, standardIcons } from '@chordco/component-library'

type CreateLookAlertProps = {
  onClose: () => void
  onCreateLook: () => void
}

export const CreateLookAlert: React.FC<CreateLookAlertProps> = ({
  onClose,
  onCreateLook,
}) => {
  return (
    <AlertModal
      title="Starting a New Look"
      content="To create a new Look, begin by selecting one of our quickstarts. This will give you a starting point that you can customize as needed. Would you like to continue?"
      icon={standardIcons.AlertInfo}
      onClose={onClose}
      centered
      buttons={[
        {
          id: 1,
          text: 'Cancel',
          onClick: onClose,
          purpose: 'tertiary',
        },
        {
          id: 2,
          text: 'Continue',
          onClick: onCreateLook,
          purpose: 'primary',
        },
      ]}
    />
  )
}
