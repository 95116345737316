const getSanitizedVariable = (key: string, isOptional = false): string => {
  const unsanitizedVar = process.env[key]
  if (!unsanitizedVar) {
    if (isOptional) return ''

    const message = `Missing environment variable ${key}`
    // eslint-disable-next-line no-console
    console.error(message)
    throw new Error(message)
  }

  return unsanitizedVar
}

export const EnvVarConfig = {
  REACT_APP_CHORD_OMS_API: getSanitizedVariable(
    'REACT_APP_CHORD_OMS_API',
    true
  ),
  REACT_APP_CHORD_HUB_API: getSanitizedVariable('REACT_APP_CHORD_HUB_API'),
  REACT_APP_CHORD_COMPOSER_API_URL: getSanitizedVariable(
    'REACT_APP_CHORD_COMPOSER_API_URL'
  ),
  REACT_APP_CHORD_COMPOSER_API_KEY: getSanitizedVariable(
    'REACT_APP_CHORD_COMPOSER_API_KEY'
  ),
  REACT_APP_SPLIT_KEY: getSanitizedVariable('REACT_APP_SPLIT_KEY'),
  REACT_APP_RECAPTCHA_SITE_KEY: getSanitizedVariable(
    'REACT_APP_RECAPTCHA_SITE_KEY'
  ),
  REACT_APP_SIGN_UP_CODE: getSanitizedVariable('REACT_APP_SIGN_UP_CODE'),
  REACT_APP_DEMO_NEXT_PUBLIC_STYTCH_API_KEY: getSanitizedVariable(
    'REACT_APP_DEMO_NEXT_PUBLIC_STYTCH_API_KEY'
  ),
  REACT_APP_PROVISIONING_STATUS_TIMEOUT_LIMIT: getSanitizedVariable(
    'REACT_APP_PROVISIONING_STATUS_TIMEOUT_LIMIT',
    true
  ),
  REACT_APP_PROVISIONING_STATUS_WAIT_BETWEEN_CALLS: getSanitizedVariable(
    'REACT_APP_PROVISIONING_STATUS_WAIT_BETWEEN_CALLS',
    true
  ),
  REACT_APP_ENABLE_ONBOARDING: getSanitizedVariable(
    'REACT_APP_ENABLE_ONBOARDING',
    true
  ),
  JEST_WORKER_ID: getSanitizedVariable('JEST_WORKER_ID', true),
  REACT_APP_SENTRY_DSN: getSanitizedVariable('REACT_APP_SENTRY_DSN', true),
  REACT_APP_OMS_SESSION_KEEP_ALIVE_MS: getSanitizedVariable(
    'REACT_APP_OMS_SESSION_KEEP_ALIVE_MS',
    true
  ),
  REACT_APP_FIVETRAN_API_URL: getSanitizedVariable(
    'REACT_APP_FIVETRAN_API_URL',
    true
  ),
  REACT_APP_FIVETRAN_CONNECT_CARD_REDIRECT_URI: getSanitizedVariable(
    'REACT_APP_FIVETRAN_CONNECT_CARD_REDIRECT_URI',
    true
  ),
  REACT_APP_FIVETRAN_SLACK_WEBHOOK: getSanitizedVariable(
    'REACT_APP_FIVETRAN_SLACK_WEBHOOK',
    true
  ),
  REACT_APP_FIVETRAN_API_KEY: getSanitizedVariable(
    'REACT_APP_FIVETRAN_API_KEY',
    true
  ),
  REACT_APP_FIVETRAN_API_SECRET: getSanitizedVariable(
    'REACT_APP_FIVETRAN_API_SECRET',
    true
  ),
  REACT_APP_CENSUS_CONNECT_CARD_REDIRECT_URI: getSanitizedVariable(
    'REACT_APP_CENSUS_CONNECT_CARD_REDIRECT_URI',
    true
  ),
  REACT_APP_CENSUS_SLACK_WEBHOOK: getSanitizedVariable(
    'REACT_APP_CENSUS_SLACK_WEBHOOK',
    true
  ),
  REACT_APP_MIXPANEL_TOKEN: getSanitizedVariable(
    'REACT_APP_MIXPANEL_TOKEN',
    true
  ),
  REACT_APP_LOOKER_HOST: getSanitizedVariable('REACT_APP_LOOKER_HOST', true),
  REACT_APP_STYTCH_GOOGLE_CLIENT_URL: getSanitizedVariable(
    'REACT_APP_STYTCH_GOOGLE_CLIENT_URL',
    true
  ),
  REACT_APP_CENSUS_BASE_URL: getSanitizedVariable(
    'REACT_APP_CENSUS_BASE_URL',
    true
  ),
  REACT_APP_CENSUS_EMBED_REDIRECT_URI: getSanitizedVariable(
    'REACT_APP_CENSUS_EMBED_REDIRECT_URI',
    true
  ),
  REACT_APP_NEW_DASHBOARD_DAYS_THRESHOLD: getSanitizedVariable(
    'REACT_APP_NEW_DASHBOARD_DAYS_THRESHOLD',
    true
  ),
}
