import React from 'react'
import netlifyLogo from 'assets/NetlifyLogo.png'
import { useForm } from 'hooks'
import { FormInput, FormRadioButton, FormSecureInput } from '../../../common'
import { ValSchemaGeneric } from 'validationSchemas'
import { Fields } from './common'
import { Integration, NetlifySettings } from 'types'
import { IntegrationForm } from './IntegrationForm'
import { Label } from '@chordco/component-library'
import { useIntegrationForm } from 'redux/hooks'

type Props = {
  onClose: () => void
  integration?: Integration<NetlifySettings>
}

const NETLIFY_LOGIN_LINK = ''

const getSchema = (
  settings?: NetlifySettings
): ValSchemaGeneric<['apiKey', 'siteId', 'nextStarter']> => ({
  apiKey: {
    label: 'API Key',
    type: 'string',
    initialValue: settings?.apiKey,
  },
  siteId: {
    label: 'Store ID',
    type: 'string',
    initialValue: settings?.siteId,
  },
  nextStarter: {
    label: 'ignored',
    type: 'string',
    initialValue: settings?.nextStarter,
  },
})

export const NetlifyForm: React.FC<Props> = ({ onClose, integration }) => {
  const { submit, isLoading, isEdit } = useIntegrationForm(
    'netlify',
    integration,
    onClose
  )

  const schema = getSchema(integration?.settings)
  const { fields, onSubmit } = useForm(schema, submit)

  return (
    <IntegrationForm
      title="Authentication"
      name="Netlify"
      logo={netlifyLogo}
      loginLink={NETLIFY_LOGIN_LINK}
      isLoading={isLoading}
      isEdit={isEdit}
      submit={onSubmit}
      onClose={onClose}
    >
      <Fields>
        <FormSecureInput field={fields.apiKey} customWidth="100%" />
        <FormInput field={fields.siteId} customWidth="100%" />
        <Label>Frontend Starter Kit</Label>
        <FormRadioButton
          field={{
            ...fields.nextStarter,
            name: 'gatsby',
            label: 'Gatsby',
            setValue: val => fields.nextStarter.setValue(!val),
            value: !fields.nextStarter.value,
          }}
          fullWidth
        />
        <FormRadioButton
          field={{
            ...fields.nextStarter,
            name: 'nextjs',
            label: 'Next JS',
            setValue: val => fields.nextStarter.setValue(val),
            value: !!fields.nextStarter.value,
          }}
          fullWidth
        />
      </Fields>
    </IntegrationForm>
  )
}
