import React, { useRef } from 'react'
import styled from 'styled-components'
import { useResponsiveWidth } from '../../hooks'
import { MetricType } from '../../types'
import {
  Container,
  Metric,
  Label,
  Value,
  Comparison,
  Sign,
  UpDownContainer,
  getGridTemplateColumns,
} from './styles'
import { ArrowUp } from '@chordco/component-library/components/icons/standardIcons/ArrowUp'
import { formatValue } from './Metrics'

/**
 * Metrics being displayed when no data is available.
 */
const emptyMetrics = [
  {
    id: 1,
    label: 'Visitors',
    value: 0.0,
    change: 0.0,
    type: 'number',
    previous: 0.0,
  },
  {
    id: 2,
    label: 'Conversion Rate',
    value: 0.0,
    change: 0.0,
    type: 'percent',
    previous: 0.0,
  },
  {
    id: 3,
    label: 'Average Order Value',
    value: 0.0,
    change: 0.0,
    type: 'currency',
    previous: 0.0,
  },
  {
    id: 4,
    label: 'Gross Revenue',
    value: 0.0,
    change: 0.0,
    type: 'currency',
    previous: 0.0,
  },
  {
    id: 5,
    label: 'Net Revenue',
    value: 0.0,
    change: 0.0,
    type: 'currency',
    previous: 0.0,
  },
  {
    id: 6,
    label: 'Orders',
    value: 0.0,
    change: 0.0,
    type: 'number',
    previous: 0.0,
  },
]

export const EmptyMetrics: React.FC = () => {
  const ref = useRef() as any
  const width = useResponsiveWidth(ref)
  const gridTemplateColumns = getGridTemplateColumns(width)

  return (
    <Container ref={ref} gridTemplateColumns={gridTemplateColumns}>
      {emptyMetrics.map(({ id, label, value, type }) => (
        <EmptyMetric key={id}>
          <Label>{label}</Label>
          <EmptyValue>
            {type === 'currency' && <EmptySign>$ </EmptySign>}
            {formatValue(value, type as MetricType)}
            {type === 'percent' && <EmptySign> %</EmptySign>}
          </EmptyValue>
          <EmptyComparison>{`Up from ${type === 'currency' ? '$' : ''}0${
            type === 'percent' ? '%' : ''
          }`}</EmptyComparison>
          <UpDownContainer>
            <EmptyArrowContainer>
              <ArrowUp />
              0%
            </EmptyArrowContainer>
          </UpDownContainer>
        </EmptyMetric>
      ))}
    </Container>
  )
}

const EmptyMetric = styled(Metric)`
  color: ${p => p.theme.ContentDisabled};
  border: solid 1px ${p => p.theme.BorderDisabled};
`

const EmptyValue = styled(Value)`
  color: ${p => p.theme.ContentDisabled};
`
export const EmptySign = styled(Sign)`
  color: ${p => p.theme.ContentDisabled};
`

export const EmptyComparison = styled(Comparison)`
  color: ${p => p.theme.ContentDisabled};
`

const EmptyArrowContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 28px;
  width: fit-content;
  background-color: ${p => p.theme.BgDisabled};
  padding: 4px 8px 4px 6px;
  color: ${p => p.theme.ContentDisabled};
  border-radius: 40px;

  path {
    fill: ${p => p.theme.ContentDisabled};
    transform: rotate(0);
  }
`
