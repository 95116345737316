import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, TextArea } from '@chordco/component-library'

interface NotesProps {
  notes: string
  editFunction: (notes: string) => void
  pageWidth: number
  isSmall: boolean
  isLoading?: boolean
}

export const Notes: React.FC<NotesProps> = ({
  notes = '',
  editFunction,
  pageWidth,
  isSmall,
  isLoading,
}) => {
  const [editedNotes, setEditedNotes] = useState(notes)

  useEffect(() => {
    setEditedNotes(notes)
  }, [notes])

  const isDirty = notes !== editedNotes

  const onSave = () => {
    if (!isDirty) return

    editFunction(editedNotes)
  }

  return (
    <Container width={`${pageWidth - (isSmall ? 0 : 352)}px`}>
      <StyledTextArea
        placeholder="Add a note..."
        width={`${pageWidth - (isSmall ? 0 : 352)}px`}
        isSmall={isSmall}
        value={editedNotes}
        onChange={e => setEditedNotes(e.target.value)}
      />
      {isDirty && (
        <StyledButton
          onClick={() => onSave()}
          isSmall={isSmall}
          isLoading={isLoading}
        >
          Save note
        </StyledButton>
      )}
    </Container>
  )
}

const Container = styled.div<{ width: string }>`
  position: relative;
  width: ${p => p.width};
`

const StyledTextArea = styled(TextArea) <{ isSmall: boolean }>`
  margin-top: 12px;
  min-height: 62px;
  min-width: 300px;
  padding-right: 122px;
`

const StyledButton = styled(Button) <{ isSmall: boolean }>`
  position: absolute;
  top: 24px;
  right: 12px;
`
