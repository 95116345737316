import React from 'react'
import { IconProps } from '../types'
import { useTheme } from '@chordco/component-library'

export const Refresh: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9089 0.242142C11.1432 0.00782761 11.5231 0.00782757 11.7574 0.242142L14.4241 2.90881C14.5366 3.02133 14.5998 3.17394 14.5998 3.33307C14.5998 3.4922 14.5366 3.64482 14.4241 3.75734L11.7574 6.424C11.5231 6.65832 11.1432 6.65832 10.9089 6.424C10.6746 6.18969 10.6746 5.80979 10.9089 5.57548L13.1513 3.33307L10.9089 1.09067C10.6746 0.856356 10.6746 0.476457 10.9089 0.242142Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66662 3.93306C4.11851 3.93306 3.59284 4.15079 3.20527 4.53837C2.81769 4.92594 2.59996 5.45161 2.59996 5.99972V7.33306C2.59996 7.66443 2.33133 7.93306 1.99996 7.93306C1.66859 7.93306 1.39996 7.66443 1.39996 7.33306V5.99972C1.39996 5.13335 1.74412 4.30246 2.35674 3.68984C2.96936 3.07722 3.80025 2.73306 4.66662 2.73306H14C14.3313 2.73306 14.5998 3.0017 14.5998 3.33307C14.5998 3.66444 14.3313 3.93306 14 3.93306H4.66662Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.49089 9.57547C4.7252 9.80978 4.7252 10.1897 4.49089 10.424L2.24849 12.6664L4.49089 14.9088C4.7252 15.1431 4.7252 15.523 4.49089 15.7573C4.25657 15.9916 3.87667 15.9916 3.64236 15.7573L0.975693 13.0907C0.741379 12.8563 0.741379 12.4764 0.975693 12.2421L3.64236 9.57547C3.87667 9.34115 4.25657 9.34115 4.49089 9.57547Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 8.06641C14.3313 8.06641 14.6 8.33504 14.6 8.66641V9.99974C14.6 10.8661 14.2558 11.697 13.6432 12.3096C13.0306 12.9222 12.1997 13.2664 11.3333 13.2664H1.99996C1.66859 13.2664 1.39996 12.9978 1.39996 12.6664C1.39996 12.335 1.66859 12.0664 1.99996 12.0664H11.3333C11.8814 12.0664 12.4071 11.8487 12.7946 11.4611C13.1822 11.0735 13.4 10.5479 13.4 9.99974V8.66641C13.4 8.33504 13.6686 8.06641 14 8.06641Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
