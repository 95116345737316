import React from 'react'
import segmentLogo from 'assets/SegmentLogo.png'
import {
  Column,
  HowToResolvePill,
  Link,
  Status,
  StatusLine,
} from 'components/systemHealth/common'
import { Section } from 'components/systemHealth/Section'
import {
  DataStoreInsights,
  DeliveryMetric,
  DeliveryMetrics,
  EventVolume,
} from 'types/systemHealth'
import styled from 'styled-components'
import { useSystemHealthData } from 'redux/state/systemHealth'

interface Props {
  dataStoreInsights?: DataStoreInsights
  deliveryMetrics?: DeliveryMetrics
  eventVolume?: EventVolume
  numberOfEventsBySourceId: { [key: number]: number }
  isLoading: boolean
  hasFailed: boolean
  isSmall: boolean
  retryFunction: () => void
}

const getSegmentDestinationLink = (deliveryMetric: DeliveryMetric) =>
  `https://app.segment.com/chord-salesdemo/destinations/${deliveryMetric.destinationMetadata.slug}/sources/${deliveryMetric.sourceMetadata.slug}/instances/${deliveryMetric.destinationMetadata.instanceId}/configuration`

export const Data: React.FC<Props> = ({
  dataStoreInsights,
  deliveryMetrics,
  eventVolume,
  numberOfEventsBySourceId,
  isLoading,
  hasFailed,
  retryFunction,
  isSmall,
}) => {
  const {
    state: { health },
  } = useSystemHealthData()

  const segmentNotProvisioned =
    dataStoreInsights && Object.keys(dataStoreInsights).length === 0
  const eventsTotal =
    eventVolume?.results.reduce((acc, cur) => acc + cur.total, 0) || 0

  const hideContent = !dataStoreInsights && !deliveryMetrics && !eventVolume

  return (
    <Section
      title="Data"
      subTitle="Segment"
      logo={segmentLogo}
      link={dataStoreInsights?.url}
      operatingCorrectly={health.data}
      isSmall={isSmall}
      isLoading={isLoading}
      hasFailed={hasFailed}
      retryFunction={retryFunction}
      headerMessage={
        segmentNotProvisioned ? (
          <>
            Note that this is a trial account with limited functionality. To
            test out a production-ready experience, get in touch at{' '}
            <StyledLink href="mailto:sales@chord.co" target="_blank">
              sales@chord.co
            </StyledLink>
            .
          </>
        ) : undefined
      }
    >
      {!hideContent && !segmentNotProvisioned && (
        <>
          {eventVolume && (
            <Column isSmall={isSmall}>
              Total events (24h):{' '}
              <Status status={eventsTotal > 0 ? 'default' : 'disabled'}>
                {eventsTotal}
              </Status>
              {eventsTotal === 0 && (
                <HowToResolvePill href="https://docs.chord.co/adding-tracking#QfyuV" />
              )}
              <br />
              Failed events:
              {deliveryMetrics
                ?.filter(d => d.destinationMetadata.enabled)
                .map(d => (
                  <>
                    <StatusLine
                      status={
                        d.undeliveredEventCount === 0 ? 'enabled' : 'disabled'
                      }
                      label={d.destinationMetadata.name}
                      customStatuses={{
                        enabled: d.undeliveredEventCount,
                        disabled: d.undeliveredEventCount,
                      }}
                    />
                    {d.undeliveredEventCount > 0 && (
                      <HowToResolvePill href={getSegmentDestinationLink(d)} />
                    )}
                  </>
                ))}
            </Column>
          )}

          {dataStoreInsights?.sources.map(s => {
            const eventsCount = numberOfEventsBySourceId[s.id]
            return (
              <Column key={s.id} isSmall={isSmall}>
                <StatusLine
                  status={s.enabled ? 'enabled' : 'disabled'}
                  label={s.name}
                />
                <StatusLine
                  status={eventsCount > 0 ? 'enabled' : 'disabled'}
                  label="Number of events (24h)"
                  customStatuses={{
                    enabled: eventsCount,
                    disabled: eventsCount,
                  }}
                />
                {(!s.enabled || eventsCount === 0) && (
                  <HowToResolvePill href="https://docs.chord.co/adding-tracking#QfyuV" />
                )}
              </Column>
            )
          })}
        </>
      )}
    </Section>
  )
}

const StyledLink = styled(Link).attrs({ hideIcon: true })`
  display: inline;
`
