import { createSlice } from '@reduxjs/toolkit'

import { UserNotificationsState, UpdateUserNotificationPayload } from 'types'
import { reduxApiClient } from 'redux/api'
import { useStateSliceAndDispatch } from '../utils'
import { useTenantsData } from 'redux/state/tenants'

const SLICE_NAME = 'userNotifications'
const initialState: UserNotificationsState = {
  notifications: {},
}

const { getUserNotifications, updateUserNotification } = reduxApiClient

export const userNotificationsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: { resetUserNotifications: () => initialState },
  extraReducers: builder => {
    builder.addCase(getUserNotifications.fulfilled, (state, action) => {
      action.payload.data.forEach(notification => {
        state.notifications[notification.id] = notification
      })
    })

    builder.addCase(updateUserNotification.fulfilled, (state, action) => {
      state.notifications[action.meta.arg.notificationId] = action.payload.data
    })
  },
})

export default userNotificationsSlice.reducer

export const useUserNotifications = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)
  const {
    state: { currentTenant, currentStore },
  } = useTenantsData()
  const notifications = Object.values(state.notifications)

  const tenantId = currentTenant?.id
  const storeId = currentStore?.id

  if (!tenantId || !storeId) {
    return {
      notifications,
      getUserNotifications: async () => {
        throw new Error('tenantId or storeId is undefined')
      },
      updateUserNotification: async (_payload: {
        notificationId: number
        payload: UpdateUserNotificationPayload
      }) => {
        throw new Error('tenantId or storeId is undefined')
      },
    }
  }

  return {
    notifications,
    getUserNotifications: () =>
      dispatch(getUserNotifications({ tenantId, storeId })),
    updateUserNotification: ({
      notificationId,
      payload,
    }: {
      notificationId: number
      payload: UpdateUserNotificationPayload
    }) =>
      dispatch(
        updateUserNotification({ tenantId, storeId, notificationId, payload })
      ),
  }
}
