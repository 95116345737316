import React, { FormEventHandler } from 'react'
import { Sheet } from '@chordco/component-library'
import styled from 'styled-components'
import SimpleBar from 'simplebar-react'

interface Props {
  children?: React.ReactNode
  onClose: Function
  title: string
  isLarge?: boolean
  useScrollBar?: boolean
  customWidth?: string
}

const Container: React.FC<{
  children?: React.ReactNode
  useScrollBar: boolean
}> = ({ useScrollBar, children }) =>
  useScrollBar ? (
    <SimpleBar
      style={{
        height: 'calc(100vh - 172px)',
        transition: '300ms',
        overflowX: 'hidden',
      }}
    >
      <Content>{children}</Content>
    </SimpleBar>
  ) : (
    <Content>{children}</Content>
  )

export const StaticFormSheet: React.FC<Props> = ({
  onClose,
  title,
  children,
  isLarge = false,
  useScrollBar = false,
  customWidth,
}) => {
  return (
    <>
      <Sheet
        title={title}
        onClose={onClose}
        isLoading={false}
        isLarge={isLarge}
        customWidth={customWidth}
      >
        <Form isLarge={isLarge} noValidate>
          <Container useScrollBar={useScrollBar}>{children}</Container>
        </Form>
      </Sheet>
    </>
  )
}

export const Form = styled.form<{
  isLarge?: boolean
  onSubmit?: FormEventHandler<HTMLFormElement>
}>`
  box-sizing: border-box;
  position: relative;
  min-height: calc(100vh - 76px);
  padding: ${p => `0px ${p.isLarge ? 24 : 16}px 0 ${p.isLarge ? 24 : 16}px`};
  overflow: auto;

  & > div:not(:first-child) {
    margin-top: 20px;
  }
`

const Content = styled.div`
  margin: 8px 0;
`
