import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Trophy: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 7.44112V7.75C5.25 10.1182 6.98301 12.0815 9.25 12.4411V15H6C5.58579 15 5.25 15.3358 5.25 15.75C5.25 16.1642 5.58579 16.5 6 16.5H10H14C14.4142 16.5 14.75 16.1642 14.75 15.75C14.75 15.3358 14.4142 15 14 15H10.75V12.4411C13.017 12.0815 14.75 10.1182 14.75 7.75V7.44112C17.017 7.08154 18.75 5.11815 18.75 2.75V1.9881C18.75 1.44238 18.3076 1 17.7619 1H14H6H2.2381C1.69239 1 1.25 1.44238 1.25 1.9881V2.75C1.25 5.11815 2.98301 7.08154 5.25 7.44112ZM5.25 5.91303V2.5H2.75V2.75C2.75 4.28679 3.81665 5.57445 5.25 5.91303ZM6.75 2.5V6.75V7.75C6.75 9.54493 8.20507 11 10 11C11.7949 11 13.25 9.54493 13.25 7.75V6.75V2.5H6.75ZM17.25 2.75C17.25 4.28679 16.1833 5.57445 14.75 5.91303V2.5H17.25V2.75ZM3.25 18.75C3.25 18.3358 3.58579 18 4 18H16C16.4142 18 16.75 18.3358 16.75 18.75C16.75 19.1642 16.4142 19.5 16 19.5H4C3.58579 19.5 3.25 19.1642 3.25 18.75Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
