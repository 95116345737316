import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Dashboard: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.78363 10.25H3.5C3.91421 10.25 4.25 10.5858 4.25 11C4.25 11.4142 3.91421 11.75 3.5 11.75H1.78359C1.87725 12.7906 2.16392 13.7743 2.60716 14.6662C2.79149 15.0372 2.64023 15.4873 2.26929 15.6716C1.89836 15.856 1.44822 15.7047 1.26388 15.3338C0.614683 14.0274 0.25 12.5552 0.25 11C0.25 8.30784 1.34112 5.87053 3.10525 4.10617L3.10571 4.10571L3.10657 4.10485C4.8709 2.34095 7.30805 1.25 10 1.25C15.3848 1.25 19.75 5.61522 19.75 11C19.75 12.5552 19.3853 14.0274 18.7361 15.3338C18.5518 15.7047 18.1016 15.856 17.7307 15.6716C17.3598 15.4873 17.2085 15.0372 17.3928 14.6662C17.8361 13.7743 18.1227 12.7906 18.2164 11.75H17C16.5858 11.75 16.25 11.4142 16.25 11C16.25 10.5858 16.5858 10.25 17 10.25H18.2164C17.8597 6.29215 14.7079 3.14029 10.75 2.78363V4C10.75 4.41421 10.4142 4.75 10 4.75C9.58579 4.75 9.25 4.41421 9.25 4V2.78363C7.53493 2.93819 5.97122 3.61761 4.72071 4.66005L5.53033 5.46967C5.82322 5.76256 5.82322 6.23744 5.53033 6.53033C5.23744 6.82322 4.76256 6.82322 4.46967 6.53033L3.66005 5.72071C2.61761 6.97122 1.93819 8.53493 1.78363 10.25ZM10 9.75C9.30964 9.75 8.75 10.3096 8.75 11C8.75 11.6904 9.30964 12.25 10 12.25C10.6904 12.25 11.25 11.6904 11.25 11C11.25 10.3096 10.6904 9.75 10 9.75ZM7.25 11C7.25 9.48122 8.48122 8.25 10 8.25C10.4946 8.25 10.9587 8.38057 11.3597 8.60912C11.3901 8.55949 11.4267 8.51262 11.4697 8.46967L13.4697 6.46967C13.7626 6.17678 14.2374 6.17678 14.5303 6.46967C14.8232 6.76256 14.8232 7.23744 14.5303 7.53033L12.5303 9.53033C12.4874 9.57328 12.4405 9.60993 12.3909 9.64029C12.6194 10.0413 12.75 10.5054 12.75 11C12.75 12.5188 11.5188 13.75 10 13.75C8.48122 13.75 7.25 12.5188 7.25 11Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
