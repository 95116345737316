import moment from 'moment'

type DateRange = {
  start: Date
  end: Date
}

export const daysBetweenDates = (start: Date, end: Date) => {
  const milliseconds = end.getTime() - start.getTime()
  return Math.floor(milliseconds / (1000 * 3600 * 24))
}

export const formatDateRange = ({ start, end }: DateRange) =>
  daysBetweenDates(start, end) + ' days'

export const getAcronym = (name: string) => {
  if (!name) return ''

  const wordsInName = name.split(' ')
  return (
    wordsInName.length === 1
      ? name.substring(0, 2)
      : `${wordsInName[0][0]}${wordsInName[1][0]}`
  ).toUpperCase()
}

export const getQueryParam = (val: string, search = window.location.search) => {
  const urlParams = new URLSearchParams(search)
  return urlParams.get(val)
}

export const formatCurrency = (
  val: number,
  decimals = 2,
  showFreeIfZero = false
) => {
  if (val === 0 && showFreeIfZero) return 'Free'

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })

  return formatter.format(val)
}

export const formatCurrencyNumber = (val: number, decimals = 2) =>
  formatCurrency(val, decimals).substring(1)

export const formatPercent = (val: number, decimals = 0) => {
  return (val * 100).toFixed(decimals)
}

export const formatDate = (date: Date | string | moment.Moment) =>
  moment(date).format('MMM DD, YYYY')

export const formatDateForDisplay = (
  date?: Date | string | moment.Moment | number
) => {
  if (!date) return '-'
  const momentDate = moment(date)
  if (momentDate.year() !== moment().year()) {
    return momentDate.format('MMM DD, YYYY')
  }

  return momentDate.format('MMM DD')
}

export const formatUtcDateForDisplay = (utc?: number) => {
  if (!utc) return '-'

  const date = new Date(0)
  date.setUTCSeconds(utc || 0)
  return formatDateForDisplay(date)
}

export const formatDateTime = (jsDate: Date | moment.Moment | string) => {
  const date = moment(jsDate)
  const today = moment()
  const yesterday = moment().subtract(1, 'days')

  const formattedTime = date.format('h:mm a')

  if (date.isSame(today, 'd')) {
    return `Today, ${formattedTime}`
  } else if (date.isSame(yesterday, 'd')) {
    return `Yesterday, ${formattedTime}`
  }
  return date.format('MMM DD, h:mm a')
}

export const formatFromSnakeCase = text => {
  if (!text) return ''

  const updatedText = text.split('_').join(' ')
  return capitalizeFirstLetter(updatedText)
}

export const lowerCaseAndSnakeCase = (text: string) =>
  text.trim().toLowerCase().replace(/ /g, '_')

export const formatCurrencyToNumber = (currency: string) =>
  Number(currency.replace(/[^0-9.-]+/g, ''))

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const formatSnakeCaseForDisplay = (text: string) => {
  if (!text) return ''
  return capitalizeFirstLetter(text.trim().replace(/_/g, ' '))
}

export type GetHourAndMinutesIn24Hour = (time: string) => {
  hour: number
  minutes: number
}
export const getHourAndMinutesIn24Hour: GetHourAndMinutesIn24Hour = time => {
  if (!time.length) return { hour: 0, minutes: 0 }

  const formattedTime = moment(time, ['h:mm A']).format('HH:mm')
  const [hour, minutes] = formattedTime.split(':')

  return { hour: +hour, minutes: +minutes }
}
