import { Chip, H5 } from '@chordco/component-library'
import { FormField } from 'hooks'
import React from 'react'
import styled from 'styled-components'
import { ChordConfigSheet } from 'types'
import { obfuscateString } from 'utils/strings'

type Props = {
  fields: Record<string, FormField>
}

export const CreateConfigConfirmation: React.FC<Props> = ({ fields }) => {
  const obfuscateValue = (value: string) => {
    const maxVisibleChars = 4
    const maxLength = 20
    return obfuscateString(value, maxVisibleChars, maxLength)
  }

  return (
    <Wrapper>
      <Label>Please confirm the following information:</Label>
      <Row>
        <H5>Spreadsheet ID</H5>
      </Row>
      <Row>
        <Label>{obfuscateValue(fields.spreadsheetId.value as string)}</Label>
      </Row>
      <H5>Selected Sheets</H5>
      <Row>
        <Label>
          <ChipContainer>
            {(fields.sheets.value as Array<ChordConfigSheet>).map(sheet => {
              return (
                <Chip
                  key={sheet.slug}
                  text={sheet.name}
                  variant="solid-secondary"
                  color="primary"
                />
              )
            })}
          </ChipContainer>
        </Label>
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

const Label = styled.div`
  margin-bottom: 4px;
  color: ${p => p.theme.ContentSecondary};
  white-space: pre-wrap;
`

const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`
