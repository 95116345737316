import React from 'react'
import { standardIcons, AlertModal } from '@chordco/component-library'

const { Trash } = standardIcons

type Props = {
  clearSheet: () => Promise<void>
  onClose: () => void
}

export const ClearSheetDataModal: React.FC<Props> = ({
  onClose,
  clearSheet,
}) => {
  const [processing, setProcessing] = React.useState(false)

  const submit = async () => {
    setProcessing(true)
    await clearSheet()
    onClose()
  }

  return (
    <AlertModal
      onClose={onClose}
      icon={Trash}
      title="Clear Data"
      content="Are you sure you want to clear this data? This action cannot be undone, and all related information will be removed from this amplification."
      centered
      buttons={[
        {
          id: 1,
          text: 'Cancel',
          onClick: onClose,
          purpose: 'tertiary',
          disabled: false,
        },
        {
          id: 2,
          text: 'Yes, Clear Data',
          onClick: submit,
          purpose: 'destructive',
          disabled: processing,
        },
      ]}
    ></AlertModal>
  )
}
