import {
  Chip,
  ChipColor,
  H2,
  Icon,
  standardIcons,
} from '@chordco/component-library'
import React, { useState } from 'react'
import { Section } from 'components/common/Section'
import styled from 'styled-components'

const { ChevronDown } = standardIcons

type CollapsibleSectionProps = {
  children?: React.ReactNode
  isMobile: boolean
  isSmall: boolean
  containerWidth: number
  title: string
  number?: number
  chipText?: string
  chipColor?: ChipColor
  hasBorder?: boolean
}

export const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  isMobile,
  containerWidth,
  isSmall,
  title,
  number,
  chipText,
  chipColor,
  children,
  hasBorder = true,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Section isMobile={isMobile} hasBorder={hasBorder}>
      <Heading
        containerWidth={containerWidth}
        isSmall={isSmall}
        noBottomMargin={!isOpen}
      >
        <TitleContainer>
          <H2>{title}</H2>
          {number !== undefined && <GreyH2>{number}</GreyH2>}
          {chipText && <Chip text={chipText} color={chipColor} />}
        </TitleContainer>

        <ToggleCollapseButton
          isMobile={isMobile}
          isOpen={isOpen}
          icon={ChevronDown}
          size="medium"
          onClick={() => setIsOpen(cur => !cur)}
        />
      </Heading>

      {isOpen && (
        <ContentContainer width={containerWidth}>{children}</ContentContainer>
      )}
    </Section>
  )
}

export const Heading = styled.div<{
  containerWidth: number
  isSmall: boolean
  noBottomMargin?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ containerWidth }) => containerWidth}px;
  margin-bottom: ${p => (p.noBottomMargin ? '0' : '16px')};
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  & > :not(:first-child) {
    margin-left: 12px;
  }
`

const GreyH2 = styled(H2)`
  color: ${p => p.theme.GREY_3};
  font-weight: 400;
`

const ContentContainer = styled.div<{ width: number }>`
  width: ${p => p.width}px;
`

const ToggleCollapseButton = styled(Icon)<{
  isOpen: boolean
  isMobile: boolean
}>`
  background-color: ${p => p.theme.TRANSPARENT_24};
  transition: 300ms;

  :hover {
    background-color: ${p => p.theme.TRANSPARENT_32};
  }

  svg {
    transform: rotate(${p => (p.isOpen ? 180 : 0)}deg);
    transition: 300ms;

    path {
      fill: ${p => (p.isOpen ? p.theme.WHITE : p.theme.PRIMARY_50)};
      transition: 300ms;
    }
  }
`
