import styled from 'styled-components'

const ErrorContainer = styled.div`
  margin: 12px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${p => p.theme.RED_30};
  border-radius: 8px;
  color: ${p => p.theme.GREY_80};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 12px;

  & > span {
    cursor: pointer;
  }
`

export { ErrorContainer }
