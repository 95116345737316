import styled from 'styled-components'
import { Icon } from '../controls'

const NavBase = styled(Icon)`
  position: absolute;
  border-radius: ${p => p.theme.ButtonRadius};
  top: 16px;

  path {
    fill: ${p => p.theme.ContentAccentPrimary};
  }

  :hover {
    background-color: ${p => p.theme.BgOverlayElevation1};
  }
`

export const NavPrev: any = styled(NavBase)`
  left: 24px;
`

export const NavNext: any = styled(NavBase)`
  right: 24px;
`

export const BaseContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;

  * {
    box-sizing: inherit;
    font-family: 'Inter', sans-serif;
  }

  .DateInput_input {
    padding: 0 11px;
  }

  .DateInput_fang {
    display: none;
  }

  .CalendarDay,
  .CalendarDay_1,
  .CalendarDay__default,
  .CalendarDay__default_2,
  .CalendarDay__lastDayOfWeek,
  .CalendarDay__lastDayOfWeek_3 {
    background-color: transparent;
    border-color: transparent;
    color: ${p => p.theme.ContentPrimary};
    border-radius: ${p => p.theme.ButtonRadius};

    :hover {
      background-color: ${p => p.theme.BgOverlayElevation3};
    }
  }

  .CalendarDay__today {
    color: ${p => p.theme.ContentAccentPrimary};
    position: relative;

    :after {
      position: absolute;
      bottom: 5px;
      left: 17px;
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background-color: ${p => p.theme.ContentAccentPrimary};
    }

    :hover {
      background-color: ${p => p.theme.BgOverlayElevation3};
    }
  }

  .CalendarDay__blocked_calendar {
    background: transaprent;
    color: ${p => p.theme.ContentDisabled};
  }

  .CalendarDay__blocked_calendar:hover {
    background: transparent;
  }

  .CalendarDay__blocked_calendar .CalendarDay__selected {
    background-color: ${p => p.theme.BgAccentDarkAALightPrimary};
    color: ${p => p.theme.WHITE};
    border-radius: 8px;

    :hover {
      color: ${p => p.theme.BgAccentDarkAALightPrimary};
    }
  }

  .CalendarDay__selected {
    background-color: ${p => p.theme.ComponentButtonPrimaryBgDefault};
    color: ${p => p.theme.WHITE};
    border-radius: ${p => p.theme.ButtonRadius};

    :hover {
      background-color: ${p => p.theme.ComponentButtonPrimaryBgHover};
    }
  }

  .DayPicker {
    z-index: 1;
  }

  .CalendarMonth,
  .CalendarMonth_1,
  .CalendarMonthGrid,
  .CalendarMonthGrid_1,
  .CalendarMonthGrid__horizontal,
  .CalendarMonthGrid__horizontal_2,
  .DayPicker_transitionContainer,
  .DayPicker_transitionContainer_1,
  .DayPicker_transitionContainer__horizontal,
  .DayPicker_transitionContainer__horizontal_2,
  .DayPicker__horizontal {
    background-color: ${p => p.theme.BgBase2};
    border-radius: ${p => p.theme.CardSmallRadius};
  }

  .DayPicker_weekHeader {
    color: ${p => p.theme.ContentSecondary};
  }

  .CalendarMonth_caption {
    color: ${p => p.theme.ContentPrimary};
    padding-top: 20px;
    padding-bottom: 41px;
  }

  .DayPicker__withBorder {
    box-shadow: none;
  }
`
