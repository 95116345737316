import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { EnvVarConfig } from 'utils/envVarConfig'
import { CensusEmbedSyncResult } from 'api/census/interfaces'

interface SyncIframeProps {
  connectLink: string
  onExit: (data: CensusEmbedSyncResult) => void
}

const SyncIframe: React.FC<SyncIframeProps> = ({ connectLink, onExit }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== EnvVarConfig.REACT_APP_CENSUS_BASE_URL) {
        return
      }

      switch (event.data.message) {
        case 'LOADED':
          setLoaded(true)
          break
        case 'EXITED_CONNECT_FLOW':
        case 'EXITED_SYNC_FLOW':
          onExit(event.data.data)
          break

        default:
          break
      }
    }

    window.addEventListener('message', handleMessage, false)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [onExit])

  return (
    <IFrameWrapper>
      <IFrame
        ref={iframeRef}
        loaded={loaded}
        src={connectLink}
        allow="clipboard-read; clipboard-write"
      />
    </IFrameWrapper>
  )
}

const IFrameWrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

const IFrame = styled.iframe<{ loaded: boolean }>`
  width: 100%;
  border: none;
  height: 100%;
  overflow: hidden;
  visibility: ${p => (p.loaded ? 'visible' : 'hidden')};
`

export default SyncIframe
