import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const BrandChampions: React.FC<IconProps> = ({
  scale = 48,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 12.3909H31.25V15.3909H35.25V20.6409C35.25 21.8835 34.2426 22.8909 33 22.8909H32.7465C32.7488 22.8078 32.75 22.7245 32.75 22.6409V19.1409H31.25V22.6409C31.25 26.6449 28.0041 29.8909 24 29.8909C19.9959 29.8909 16.75 26.6449 16.75 22.6409V19.1409H15.25V22.6409C15.25 22.7245 15.2512 22.8078 15.2535 22.8909H15C13.7574 22.8909 12.75 21.8835 12.75 20.6409V15.3909H14.4V13.8909H11.25V20.6409C11.25 22.7119 12.9289 24.3909 15 24.3909H15.425C16.0518 27.4784 18.3042 29.9756 21.25 30.95V38.8909H18V40.3909H30V38.8909H26.75V34.6409H25.25V38.8909H22.75V31.3023C23.1583 31.3607 23.5756 31.3909 24 31.3909C28.2332 31.3909 31.7642 28.3848 32.575 24.3909H33C35.0711 24.3909 36.75 22.7119 36.75 20.6409V13.8909H32.75V10.8909H16V12.3909Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.145 18.4407C25.9002 18.4152 25.6868 18.2631 25.583 18.04L24.6347 16.0036C24.3836 15.4643 23.6167 15.4643 23.3656 16.0036L22.4173 18.04C22.3134 18.2631 22.1001 18.4152 21.8553 18.4407L19.5985 18.6759C18.9903 18.7393 18.7501 19.4973 19.2107 19.8995L20.8567 21.3365C21.0488 21.5043 21.1345 21.7632 21.0803 22.0124L20.6195 24.1334C20.4918 24.7211 21.1157 25.1856 21.6421 24.8947L23.6616 23.7787C23.8723 23.6623 24.128 23.6623 24.3387 23.7787L26.3582 24.8947C26.8845 25.1856 27.5085 24.7211 27.3808 24.1334L26.9199 22.0124C26.8658 21.7632 26.9515 21.5043 27.1436 21.3365L28.7895 19.8995C29.2502 19.4973 29.0099 18.7393 28.4017 18.6759L26.145 18.4407ZM23.7771 18.6732L24.0001 18.1943L24.2232 18.6732C24.5496 19.3743 25.2203 19.8525 25.9895 19.9326L26.4195 19.9775L26.1571 20.2066C25.5532 20.7338 25.2839 21.5476 25.4541 22.3309L25.5406 22.7291L25.0642 22.4658C24.402 22.0999 23.5982 22.0999 22.936 22.4658L22.4596 22.7291L22.5461 22.3309C22.7163 21.5476 22.447 20.7338 21.8432 20.2066L21.5808 19.9775L22.0108 19.9326C22.78 19.8525 23.4506 19.3743 23.7771 18.6732Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
