import moment from 'moment'
import cronstrue from 'cronstrue'
import {
  CensusScheduleDay,
  CensusScheduleFrequency,
} from 'api/census/interfaces'
import { capitalizeFirstLetter } from '@chordco/component-library'

function formatSchedule(
  scheduleDay: CensusScheduleDay,
  scheduleFrequency: CensusScheduleFrequency,
  scheduleHour: number,
  scheduleMinute: number,
  cronExpression: string
): string {
  if (scheduleFrequency === 'expression') {
    return formatCronExpression(cronExpression)
  }

  const timeUTC = moment
    .utc({ hour: scheduleHour, minute: scheduleMinute })
    .format('HH:mm [UTC]')

  const timeLocal = formatLocalTime(scheduleHour, scheduleMinute)

  const scheduleStrings = {
    never: 'Never',
    continuous: 'Continuously',
    quarter_hourly: 'Every 15 minutes',
    hourly: 'Hourly',
    daily: `Daily at ${timeUTC} (${timeLocal})`,
    weekly: `Weekly on ${scheduleDay} at ${timeUTC} (${timeLocal})`,
  }

  return scheduleStrings[scheduleFrequency] || 'Unknown schedule'
}

function formatLocalTime(hour: number, minute: number): string {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return moment
    .utc({ hour: hour, minute: minute })
    .tz(timeZone)
    .format('h:mm A z')
}

function formatCronExpression(cronExpression: string): string {
  try {
    const readable = cronstrue.toString(cronExpression, {
      verbose: true,
    })
    return shortenCronString(readable)
  } catch (e) {
    return 'Invalid cron expression'
  }
}

function shortenCronString(cronString: string): string {
  const patterns: { [key: string]: string } = {
    'Every minute': 'minutely',
    'Every hour': 'hourly',
    'Every day': 'daily',
    'Every week': 'weekly',
    'Every month': 'monthly',
    'Every year': 'yearly',
    'only on Monday, Tuesday, Wednesday, Thursday, and Friday': 'on weekdays',
    'only on Saturday and Sunday': 'on weekends',
    'At 0 minutes past the hour,': '',
    'At 0 minutes past the hour, every hour': 'hourly',
    'At 0 minutes past the hour, every day': 'daily',
    'At 0 minutes past the hour, every week': 'weekly',
    'Monday through Friday': 'on weekdays',
  }

  let shortened = cronString

  for (const [pattern, replacement] of Object.entries(patterns)) {
    const regex = new RegExp(pattern, 'gi')
    shortened = shortened.replace(regex, replacement)
  }

  return capitalizeFirstLetter(shortened.trim())
}

export { formatSchedule, formatCronExpression, formatLocalTime }
