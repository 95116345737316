import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Burger: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.75 4C18.75 4.41421 18.4142 4.75 18 4.75L2 4.75C1.58579 4.75 1.25 4.41421 1.25 4C1.25 3.58579 1.58579 3.25 2 3.25H18C18.4142 3.25 18.75 3.58579 18.75 4ZM18.75 10C18.75 10.4142 18.4142 10.75 18 10.75L2 10.75C1.58579 10.75 1.25 10.4142 1.25 10C1.25 9.58579 1.58579 9.25 2 9.25L18 9.25C18.4142 9.25 18.75 9.58579 18.75 10ZM18 16.75C18.4142 16.75 18.75 16.4142 18.75 16C18.75 15.5858 18.4142 15.25 18 15.25L2 15.25C1.58579 15.25 1.25 15.5858 1.25 16C1.25 16.4142 1.58579 16.75 2 16.75L18 16.75Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
