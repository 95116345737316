import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Send: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6415 2.61164C14.7259 2.35852 14.66 2.07946 14.4714 1.8908C14.2827 1.70214 14.0037 1.63627 13.7505 1.72064L1.71353 5.73297C1.4276 5.82828 1.23399 6.09494 1.2319 6.39633C1.22981 6.69772 1.41969 6.96704 1.70427 7.06631L7.33252 9.02966L9.29586 14.6579C9.39513 14.9425 9.66445 15.1324 9.96584 15.1303C10.2672 15.1282 10.5339 14.9346 10.6292 14.6486L14.6415 2.61164ZM7.70001 7.6658L4.11832 6.41637L12.8595 3.50263L9.9458 12.2439L8.69627 8.66187L10.0438 7.31433C10.3189 7.03925 10.3189 6.59325 10.0438 6.31816C9.76873 6.04308 9.32273 6.04308 9.04765 6.31816L7.70001 7.6658Z"
          fill={fill || theme.ContentPrimary}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
