import type { Middleware } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'
import { setCurrentTenant } from 'redux/utils/localStorageHelpers'

export const tenantMiddleware: Middleware = store => next => action => {
  if (action?.type === 'tenants/selectTenant') {
    const state: RootState = store.getState()
    const tenants = state.tenants.tenants

    const newTenant = tenants.find(t => t.id === action.payload.tenantId)
    if (newTenant) {
      setCurrentTenant(newTenant)
      window.location.href = '/'
      return
    }
  }

  return next(action)
}
