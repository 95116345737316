import React from 'react'
import styled from 'styled-components'

class ErrorBoundary extends React.Component<
  {
    children?: React.ReactNode
  },
  {
    children?: React.ReactNode
    hasError: boolean
  }
> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidCatch(error, errorInfo) {
    // log error here
  }

  render() {
    if (this.state.hasError) {
      return (
        <Wrapper>
          <h1>
            Something went wrong. Please, refresh the page or contact support.
          </h1>
        </Wrapper>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  padding: 36px;
  background-color: ${p => p.theme.OFF_BLACK};
  color: ${p => p.theme.PRIMARY_50};
`
