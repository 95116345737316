import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useExperiments, useIsMobile } from 'hooks'
import { Col, TableHeader, TableRow, Header } from '../common'
import {
  Avatar,
  Chip,
  H5,
  Spinner,
  standardIcons,
  Button,
  Tooltip,
} from '@chordco/component-library'
import { InviteUser } from './InviteUser'
import moment from 'moment'
import { EditUser } from './EditUser'
import { Sort, SortCol, sortUsers } from './sortUtils'
import { useUsersData } from 'redux/state/users'
import { useIsLoading } from 'redux/utils'
import { useTenantsData } from 'redux/state/tenants'
import { useAuthData } from 'redux/state/auth'
import { useUserRoleStateDisplay } from 'hooks/useUserRoleHelper'
import { InfoOutlined } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import { User } from 'types'

export const Users: React.FC = () => {
  const isMobile = useIsMobile()
  const [showInviteUser, setShowInviteUser] = useState<boolean>(false)
  const [editUser, setEditUser] = useState<User>()
  const [sort, setSort] = useState<Sort>({ column: 'name', isAscending: true })

  const {
    state: { currentTenant },
  } = useTenantsData()

  const {
    getUsers,
    state: { users },
  } = useUsersData()

  const { state } = useAuthData()

  useEffect(() => {
    if (!users.length) {
      getUsers()
    }
  }, [])

  const isLoading = useIsLoading('getUsers')

  const { Plus } = standardIcons

  const getFormattedDate = (date: string) => {
    const thisYear = moment(date).isSame(new Date(), 'year')
    const format = thisYear ? 'MMM DD' : 'MMM DD, YYYY'
    return moment(date).format(format)
  }

  const sortedUsers = sortUsers(users, sort.column, sort.isAscending)
  const ssoEnabled = currentTenant?.metadata?.ssoEnabled
  const isSuperAdmin = state.user && state.user.role == 'superuser'
  const isTeamAdmin = state.user && state.user.role == 'admin'
  const canEdit = isTeamAdmin || isSuperAdmin

  const nonChordUsers = users.filter(u => !u.email?.match(/chord.co.*/))
  const hasTenantConfigAccess = useExperiments('hub_tenant_configuration')

  return (
    <div>
      <Header title="Team">
        {hasTenantConfigAccess?.enabled && (
          <>
            <TooltipWrapper>
              <Tooltip
                text={`Your current contract allows for ${currentTenant?.userSeatLimit} seats. For more information, please contact us at help@chord.co`}
                direction="left"
                customWidth="400px"
              >
                <IconButton size="small">
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            </TooltipWrapper>
            <SeatUsageWrapper>
              <SeatUsageTextPrimary>Seats Usage: </SeatUsageTextPrimary>
              <SeatUsageTextSecondary>
                {nonChordUsers.length} out of {currentTenant?.userSeatLimit}
              </SeatUsageTextSecondary>
            </SeatUsageWrapper>
          </>
        )}
        {canEdit && (
          <>
            <SubHeading>Add, edit, and remove Chord users</SubHeading>
            <InviteButtonWrapper>
              <Button
                name="Invite User"
                location="Team"
                purpose="primary"
                icon={Plus}
                onClick={() => setShowInviteUser(true)}
                disabled={ssoEnabled}
                tooltip={
                  ssoEnabled
                    ? 'Add new users via your SSO provider dashboard. Once they log into Chord, you can manage their permissions.'
                    : undefined
                }
                tooltipDirection={isMobile ? 'input-bottom-right' : 'left'}
                tooltipWidth={isMobile ? '360px' : '460px'}
              >
                Invite User
              </Button>
            </InviteButtonWrapper>
          </>
        )}
      </Header>

      <Container>
        <TableHeader>
          <SortCol flex={3} sort={sort} column="name" setSort={setSort}>
            Name
          </SortCol>
          <SortCol flex={3} sort={sort} column="email" setSort={setSort}>
            Email
          </SortCol>
          <SortCol flex={2} sort={sort} column="lastLogin" setSort={setSort}>
            Last Login
          </SortCol>
          <SortCol flex={1} sort={sort} column="permissions" setSort={setSort}>
            Permissions
          </SortCol>
        </TableHeader>

        {sortedUsers.length ? (
          <UsersList data-testid="users-list">
            {sortedUsers.map(user => {
              const { text, color, variant } = useUserRoleStateDisplay(
                user.role
              )
              return (
                <TableRow key={user.id} onClick={() => setEditUser(user)}>
                  <Col flex={isMobile ? 1 : 3}>
                    <Name>
                      <StyledAvatar
                        userName={[user.firstName, user.lastName].join(' ')}
                      />
                    </Name>
                    {!isMobile && (
                      <Name>{[user.firstName, user.lastName].join(' ')}</Name>
                    )}
                  </Col>
                  {!isMobile ? (
                    <>
                      <Col flex={3}>
                        <Email>{user.email}</Email>
                      </Col>
                      <Col flex={2}>
                        {user.lastSignInAt
                          ? getFormattedDate(user.lastSignInAt)
                          : 'Pending'}
                      </Col>
                    </>
                  ) : (
                    <Col flex={3}>
                      <H5>{[user.firstName, user.lastName].join(' ')}</H5>
                      <Email>{user.email}</Email>
                    </Col>
                  )}
                  <Col flex={1}>
                    <Chip text={text} color={color} variant={variant} />
                  </Col>
                </TableRow>
              )
            })}
          </UsersList>
        ) : (
          isLoading && (
            <SpinnerContainer>
              <Spinner scale={30} />
            </SpinnerContainer>
          )
        )}
      </Container>

      {showInviteUser && (
        <InviteUser onClose={() => setShowInviteUser(false)} />
      )}
      {editUser && (
        <EditUser
          onClose={() => setEditUser(undefined)}
          user={editUser}
          tenant={currentTenant}
          canEdit={canEdit || state.user?.id === editUser.id}
          isSuperAdmin={isSuperAdmin}
          isTeamAdmin={isTeamAdmin}
        />
      )}
    </div>
  )
}

const Container = styled.div``

const UsersList = styled.div`
  overflow: scroll;
  height: calc(100vh - 145px);
  padding: 0 0 64px 0;
`

const Name = styled.span`
  margin-left: 12px;
  color: ${p => p.theme.ContentPrimary};
  font-weight: 500;
`
const Email = styled.span`
  color: ${p => p.theme.ContentSecondary};
`

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100px;
  margin-top: 64px;
  display: flex;

  & > div {
    position: relative;
  }
`

const InviteButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`
const SubHeading = styled.div`
  color: ${p => p.theme.ContentSecondary};
  margin-top: 5px;
`

const StyledAvatar = styled(Avatar)`
  color: ${p => p.theme.WHITE};
`

const TooltipWrapper = styled.div`
  position: absolute;
  top: 3px;
  right: 325px;
`
const SeatUsageWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 160px;
`
const SeatUsageTextPrimary = styled.span`
  color: ${p => p.theme.ContentPrimary};
  font-weight: 500;
`

const SeatUsageTextSecondary = styled.span`
  color: ${p => p.theme.ContentSecondary};
`
