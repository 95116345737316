import { useEffect, useCallback, RefObject } from 'react'

export const useClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: Function
) => {
  const memoizedHandler = useCallback(handler, [handler])

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      // Do nothing if the ref is null or the event target is the ref
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return
      }

      memoizedHandler(event)
    }

    document.addEventListener('mousedown', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [memoizedHandler, ref])
}
