import { hubClient } from '../http'
import {
  CreateIntegrationRequest,
  GetFulfillmentServices,
  Integration,
  StoreEnvironment,
  StoreSetup,
  UpdateIntegrationRequest,
  UpdateStoreSetupRequest,
} from 'types'
import { omsClient } from '../omsHttp'

export interface StoreSetupClient {
  getStoreSetup: (data: {
    tenantId: number
    storeId: number
    environment?: StoreEnvironment
  }) => Promise<StoreSetup>
  updateStoreSetup: (data: UpdateStoreSetupRequest) => Promise<StoreSetup>
  deleteStoreSetup: (id: number) => Promise<void>
  createIntegration: <TSettings>(
    data: CreateIntegrationRequest<TSettings>
  ) => Promise<Integration<TSettings>>
  updateIntegration: <TSettings>(
    data: UpdateIntegrationRequest<TSettings>
  ) => Promise<Integration<TSettings>>
  deleteIntegration: (id: number) => Promise<void>
  getFulfillmentServices: () => GetFulfillmentServices
}

export const storeSetupClient: StoreSetupClient = {
  getStoreSetup: ({ tenantId, storeId, environment }) => {
    let url = `tenants/${tenantId}/stores/${storeId}`
    if (environment) url += `?env=${environment}`

    return hubClient.get(url)
  },
  updateStoreSetup: data =>
    hubClient.put(
      `tenants/${data.tenantId}/stores/${data.id}?env=${data.environment}`,
      { store: data }
    ),
  deleteStoreSetup: id => omsClient.delete(`storeSetup/${id}`),
  createIntegration: data =>
    hubClient.post(
      `tenants/${data.tenantId}/stores/${data.storeId}/integrations/${data.integrationType}`,
      { integration: { ...data.integration, environment: data.environment } }
    ),
  updateIntegration: data =>
    hubClient.put(
      `tenants/${data.tenantId}/stores/${data.storeId}/integrations/${data.integrationType}`,
      { integration: { ...data.integration, environment: data.environment } }
    ),
  deleteIntegration: id => omsClient.delete(`storeSetup/integrations/${id}`),
  getFulfillmentServices: () => omsClient.get('hub/fulfillment_services'),
}
