import styled, { css } from 'styled-components'
import { Caption } from '../type'

export const RelativeContainer = styled.div`
  position: relative;
`

export const Select = styled.div<{
  show: boolean
  left: boolean
  customWidth?: string
  searchable?: boolean
  hideScrollbar?: boolean
}>`
  position: absolute;
  box-sizing: border-box;
  min-width: ${p => p.customWidth || '240px'};
  width: ${p => p.customWidth || ''};
  color: ${p => p.theme.ContentPrimary};
  border: solid 1px ${p => p.theme.BorderMinimal};
  border-radius: ${p => p.theme.CardSmallRadius};
  margin-top: 8px;
  padding: 4px 0;
  z-index: 1;
  background-color: ${p => p.theme.BgBase1};
  opacity: ${p => (p.show ? 1 : 0)};
  transition: 100ms;
  max-height: calc((32px * 10) + ${p => (p.searchable ? '76px' : 0)});
  overflow-y: scroll;

  ${p =>
    p.hideScrollbar &&
    css`
      scrollbar-width: none;
      -ms-overflow-style: none;
      ::-webkit-scrollbar {
        display: none;
      }
    `}

  ${p =>
    p.left
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `};
`

export const NestedSelect = styled(Select)<{
  hasParent: boolean
  renderChildLeft: boolean
  isNested?: boolean
}>`
  overflow-y: ${p => (p.isNested ? 'visible' : 'scroll')};

  ${p =>
    p.hasParent &&
    css`
      margin: 0;
      top: -5px;
      left: 240px;

      ${p.renderChildLeft &&
      css`
        left: -240px;
      `}
    `}
`

export const Title = styled(Caption)`
  box-sizing: border-box;
  height: 28px;
  padding: 8px 16px;
  color: ${p => p.theme.ContentSecondary};
`

export const Clear = styled.div`
  float: right;
  padding: 0 16px 8px 0;
`
