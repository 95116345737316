import { useIntegrationsByEnvironment } from 'redux/hooks'
import { useSystemHealthData } from 'redux/state/systemHealth'
import { useEffect, useMemo } from 'react'
import { useHasFailed, useIsIdle, useIsLoading } from 'redux/utils'
import { HealthStatusType } from 'components/systemHealth/common'
import { useTenantsData } from 'redux/state/tenants'

export const useGetSystemHealthData = () => {
  const { integrations } = useIntegrationsByEnvironment()
  const composerIntegration = integrations.find(i => i.name === 'Composer')
  const stackId = +composerIntegration?.settings?.stackId

  const {
    state: { currentTenant },
  } = useTenantsData()
  const tenantId = currentTenant?.id
  const tenantName = currentTenant?.metadata?.omsTenantName

  const {
    state: {
      contentStoreInsights,
      dataStoreInsights,
      onlineStoreInsights,
      // orderStoreInsights,
      buildStatus,
      deliveryMetrics,
      eventVolume,
      stripeWebhooks,
      health,
    },
    getContentStoreInsights,
    getDataStoreInsights,
    getOnlineStoreInsights,
    // getOrderStoreInsights,
    getBuildStatus,
    getDeliveryMetrics,
    getEventVolume,
    getStripeWebhooks,
    updateHealth,
  } = useSystemHealthData()

  const getContent = () => {
    if (!contentStoreInsights && stackId) getContentStoreInsights(stackId)
  }
  useEffect(() => {
    getContent()
  }, [stackId])

  const getData = () => {
    if (!dataStoreInsights && stackId) getDataStoreInsights(stackId)
    if (!deliveryMetrics && stackId) getDeliveryMetrics(stackId)
    if (!eventVolume && stackId) getEventVolume(stackId)
  }
  useEffect(() => {
    getData()
  }, [stackId])

  const numberOfEventsBySourceId = useMemo(() => {
    if (!dataStoreInsights?.sources || !eventVolume) return {}

    const events = {}
    dataStoreInsights.sources.forEach(s => {
      const result = eventVolume?.results.find(r => r.source.id === s.id)
      if (!result) {
        if (health.data) {
          updateHealth({ data: false })
        }

        events[s.id] = 0
      } else {
        events[s.id] = result.total
      }
    })

    return events
  }, [dataStoreInsights?.sources, eventVolume, updateHealth])

  const getOnlineStore = () => {
    if (!buildStatus && stackId) getBuildStatus(stackId)
  }
  useEffect(() => {
    getOnlineStore()
  }, [stackId])

  const getRepo = () => {
    if (!onlineStoreInsights && stackId) getOnlineStoreInsights(stackId)
  }
  useEffect(() => {
    getRepo()
  }, [stackId])

  // useEffect(() => {
  //   if (!orderStoreInsights && stackId) getOrderStoreInsights(stackId)
  // }, [stackId])

  const getPayment = () => {
    if (!stripeWebhooks && tenantId && tenantName)
      getStripeWebhooks(tenantId, tenantName)
  }
  useEffect(() => {
    getPayment()
  }, [tenantId])

  const isStoreSetupIdle = useIsIdle('getStoreSetup')
  const isStoreSetupLoading = useIsLoading('getStoreSetup')
  const areIntegrationsLoadingOrIdle = isStoreSetupIdle || isStoreSetupLoading

  const isGetContentStoreInsightsLoading =
    useIsLoading('getContentStoreInsights') || areIntegrationsLoadingOrIdle
  const isGetOnlineStoreInsightsLoading =
    useIsLoading('getOnlineStoreInsights') || areIntegrationsLoadingOrIdle
  const isGetOrderStoreInsightsLoading =
    useIsLoading('getOrderStoreInsights') || areIntegrationsLoadingOrIdle
  const isGetBuildStatusLoading =
    useIsLoading('getBuildStatus') || areIntegrationsLoadingOrIdle
  const isDataLoading =
    useIsLoading(
      'getDataStoreInsights',
      'getDeliveryMetrics',
      'getEventVolume'
    ) || areIntegrationsLoadingOrIdle
  const isGetStripeWebhooksLoading =
    useIsLoading('getStripeWebhooks') || areIntegrationsLoadingOrIdle

  const isGetContentStoreInsightsFailed =
    useHasFailed('getContentStoreInsights') || !stackId
  const isGetOnlineStoreInsightsFailed =
    useHasFailed('getOnlineStoreInsights') || !stackId
  const isGetOrderStoreInsightsFailed =
    useHasFailed('getOrderStoreInsights') || !stackId
  const isGetBuildStatusFailed = useHasFailed('getBuildStatus') || !stackId
  const isDataFailed =
    useHasFailed(
      'getDataStoreInsights',
      'getDeliveryMetrics',
      'getEventVolume'
    ) || !stackId
  const isGetStripeWebhooksFailed =
    useHasFailed('getStripeWebhooks') || !tenantId

  return {
    tenantId,
    contentStoreInsights,
    dataStoreInsights,
    onlineStoreInsights,
    // orderStoreInsights,
    buildStatus,
    deliveryMetrics,
    eventVolume,
    stripeWebhooks,
    numberOfEventsBySourceId,
    health,
    loading: {
      content: isGetContentStoreInsightsLoading,
      data: isDataLoading,
      onlineStore: isGetOnlineStoreInsightsLoading,
      orderStore: isGetOrderStoreInsightsLoading,
      buildStatus: isGetBuildStatusLoading,
      payment: isGetStripeWebhooksLoading,
    },
    failed: {
      content: isGetContentStoreInsightsFailed,
      data: isDataFailed,
      onlineStore: isGetOnlineStoreInsightsFailed,
      orderStore: isGetOrderStoreInsightsFailed,
      buildStatus: isGetBuildStatusFailed,
      payment: isGetStripeWebhooksFailed,
    },
    retryFunctions: {
      getContent,
      getData,
      getOnlineStore,
      getRepo,
      getPayment,
    },
  }
}

export const getHealthStatus = (state?: string): HealthStatusType => {
  switch (state) {
    case 'building':
      return 'inProgress'

    case 'ready':
      return 'enabled'

    case 'error':
      return 'disabled'

    default:
      return 'inProgress'
  }
}
