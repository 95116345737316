import React, { useState } from 'react'
import { styled, alpha } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Add } from '@mui/icons-material'
import { ListItemIcon, ListItemText } from '@mui/material'

import { standardIcons } from '@chordco/component-library/components/icons/standardIcons'

type AddNewReportButtonProps = {
  onCreateNewDashboard: () => void
  onCreateNewLook: () => void
}

export const AddNewReportButton: React.FC<AddNewReportButtonProps> = ({
  onCreateNewDashboard,
  onCreateNewLook,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleAddDashboard = () => {
    handleClose()
    onCreateNewDashboard()
  }

  const handleAddLook = () => {
    handleClose()
    onCreateNewLook()
  }

  return (
    <>
      <Button
        variant="contained"
        disableElevation
        onClick={handleClick}
        startIcon={<Add />}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          borderRadius: '32px',
          height: '40px',
          textTransform: 'none',
          fontSize: '14px',
          fontWeight: 500,
        }}
      >
        New Report
      </Button>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleAddDashboard} disableRipple>
          <ListItemIcon>
            <standardIcons.Reports />
          </ListItemIcon>
          <ListItemText>New Dashboard</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleAddLook} disableRipple>
          <ListItemIcon>
            <standardIcons.Metrics />
          </ListItemIcon>
          <ListItemText>New Look</ListItemText>
        </MenuItem>
      </StyledMenu>
    </>
  )
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[300],
    }),
  },
}))
