import React, { useState } from 'react'
import { OptionType, Switcher } from './Switcher'
import { Tenant as TenantType } from 'types'
import styled from 'styled-components'
import { Button } from '@chordco/component-library'
import { Plus } from '@chordco/component-library/components/icons/standardIcons/Plus'
import { useAuthData } from 'redux/state/auth'
import { CreateTenantForm } from 'components/provisioning/CreateTenantForm'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { useExperiments } from 'hooks'

interface Props {
  tenants: TenantType[]
  currentTenant: TenantType | undefined
  selectTenant: (tenantId: number) => void
}

export const Tenant: React.FC<Props> = ({
  tenants,
  currentTenant,
  selectTenant,
}) => {
  const [showCreateTenant, setShowCreateTenant] = useState(false)

  const selectedEnvironment = useSelector(
    (state: RootState) => state.environments.selectedEnvironment
  )

  const selectOption = (option: OptionType) => selectTenant(option.id)

  const { state } = useAuthData()

  const isCurrentUserSuperUser = state.user?.role === 'superuser'

  const treatment = useExperiments('hub_create_tenant')

  const canShowCreateTenant =
    isCurrentUserSuperUser && treatment?.variation === 'on'

  const toggleCreateTenantForm = () => setShowCreateTenant(!showCreateTenant)

  return (
    <>
      {canShowCreateTenant && (
        <ButtonContainer>
          <Button
            icon={Plus}
            variant="tertiary"
            onClick={toggleCreateTenantForm}
          >
            Add New Tenant
          </Button>
        </ButtonContainer>
      )}

      <Switcher
        options={tenants}
        selectOption={selectOption}
        selectedOption={currentTenant}
        type="tenant"
        enableSearch
        sortAlphabetically
      />

      {showCreateTenant && selectedEnvironment && canShowCreateTenant && (
        <CreateTenantForm
          onClose={toggleCreateTenantForm}
          selectedEnvironment={selectedEnvironment}
        />
      )}
    </>
  )
}

const ButtonContainer = styled.div`
  padding-left: 16px;
  padding-bottom: 16px;
  padding-right: 16px;

  button {
    width: 100%;
  }
`
