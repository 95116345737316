import React from 'react'
import styled from 'styled-components'
import { standardIcons } from '@chordco/component-library'
import { ForceThemeProvider } from 'themes/ThemeSwitcherProvider'

const { BarChart } = standardIcons

type Props = {
  message: string
  status: 'off' | 'under_review'
}

export const AnalyticsBlurbBanner: React.FC<Props> = ({ message, status }) => (
  <ForceThemeProvider theme="light">
    <Container status={status}>
      <Text>{message}</Text>
      <BarChart scale={15} />
    </Container>
  </ForceThemeProvider>
)

const Container = styled.div<{ status: 'off' | 'under_review' }>`
  height: 40px;
  width: 100%;
  background: ${p =>
    p.status === 'off'
      ? p.theme.BgAccentLightAADarkInfo
      : p.theme.BgAccentLightAADarkWarning};
  border-radius: 8px;
  margin-top: 32px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
`

const Text = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${p => p.theme.BLACK};
`
