import React from 'react'
import styled, { css } from 'styled-components'

import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

type Props = IconProps & {
  hoverFill?: string
}

export const ChevronRightDouble: React.FC<Props> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <StyledSvg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.37569 12.2243C3.14137 11.99 3.14137 11.6101 3.37569 11.3758L6.95142 7.80002L3.37569 4.22429C3.14137 3.98997 3.14137 3.61008 3.37569 3.37576C3.61 3.14145 3.9899 3.14145 4.22422 3.37576L8.22422 7.37576C8.45853 7.61007 8.45853 7.98997 8.22422 8.22429L4.22422 12.2243C3.9899 12.4586 3.61 12.4586 3.37569 12.2243Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.17574 12.2243C7.94142 11.99 7.94142 11.6101 8.17574 11.3758L11.7515 7.80002L8.17574 4.22429C7.94142 3.98997 7.94142 3.61008 8.17574 3.37576C8.41005 3.14145 8.78995 3.14145 9.02426 3.37576L13.0243 7.37576C13.2586 7.61007 13.2586 7.98997 13.0243 8.22429L9.02426 12.2243C8.78995 12.4586 8.41005 12.4586 8.17574 12.2243Z"
        fill={fill || theme.ContentPrimary}
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg<{
  hoverFill?: string
}>`
  ${({ hoverFill }) =>
    hoverFill &&
    css`
      :hover {
        path {
          fill: ${hoverFill};
        }
      }
    `}
`
