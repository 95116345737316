import React, { useLayoutEffect } from 'react'
import styled, { css } from 'styled-components'
import { standardIcons } from '../icons'
import { SubNavItem } from './SubNavItem'
import { useExpandCollapseAnimationNav } from '../../hooks'
import { Plan } from 'types'

export type SubNavItemType = {
  id: number
  slug: string
  title: string
  link: string
  showForPlans?: Plan[]
  beta?: boolean
  subItems?: SubNavItemType[]
  folderId?: string
}

export type NavItemType = {
  id: number
  slug: string
  title: string
  link?: string
  icon: any
  selectedId?: number
  open?: boolean
  setOpen?: Function
  navigate?: Function
  onClick?: any
  subItems?: SubNavItemType[]
  openOutsideLink?: boolean
  showForPlans?: Plan[]
  productionOnly?: boolean
}

const { ChevronUp, OpenLink } = standardIcons

export const Item: React.FC<NavItemType & { comingSoon?: boolean }> = ({
  id,
  title,
  link,
  icon: Icon,
  selectedId,
  navigate,
  onClick,
  subItems,
  comingSoon,
  openOutsideLink,
}) => {
  const { isOpen, setIsOpen, height, adjustHeight, ref } =
    useExpandCollapseAnimationNav()

  useLayoutEffect(() => {
    if (!subItems) return

    setIsOpen(
      selectedId
        ? subItems?.some(
            item =>
              item.id === selectedId ||
              item?.subItems?.some(
                ({ id, subItems }) =>
                  id === selectedId ||
                  subItems?.some(ssi => ssi.id === selectedId)
              )
          )
        : false
    )
  }, [selectedId])

  const hasSubItems = !!subItems?.length

  const selectItem = (_id, link) => {
    navigate && navigate(link)
  }

  const onSelect = () => {
    setIsOpen && setIsOpen((curVal: boolean) => !curVal)
    onClick && onClick()

    if (subItems?.length) return

    selectItem(id, link)
  }

  return (
    <>
      <Container
        selected={id === selectedId}
        onClick={comingSoon ? undefined : onSelect}
        data-testid={title}
        comingSoon={comingSoon}
      >
        <IconContainer>
          <Icon scale={20} />
        </IconContainer>
        <Title isOpenCloseItem={hasSubItems}>
          {title}
          {comingSoon && (
            <div>
              <ComingSoon>Coming soon</ComingSoon>
            </div>
          )}
        </Title>

        {hasSubItems && (
          <ChevronContainer open={isOpen}>
            <ChevronUp />
          </ChevronContainer>
        )}

        <TrailingIconContainer>
          {openOutsideLink && <OpenLink />}
        </TrailingIconContainer>
      </Container>

      {!!subItems?.length && (
        <SubItems ref={ref} height={`${height}px`}>
          {subItems?.map(({ id, title, link, beta, subItems, slug }) => (
            <SubNavItem
              key={id}
              id={id}
              selectedId={selectedId}
              title={title}
              link={link}
              beta={beta}
              subItems={subItems}
              selectItem={selectItem}
              adjustHeight={adjustHeight}
              parentIsOpen={isOpen}
              slug={slug}
            />
          ))}
        </SubItems>
      )}
    </>
  )
}

const Base = styled.div<{ selected: boolean; comingSoon?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  height: 40px;
  padding: 0 12px;
  color: ${p => p.theme.ContentPrimary};
  border-radius: 8px;
  cursor: pointer;
  opacity: ${p => (p.comingSoon ? 0.5 : 1)};

  :hover {
    background-color: ${p => p.theme.BgOverlayElevation1};
  }

  & svg path {
    fill: ${p => p.theme.ContentPrimary};
  }

  ${p =>
    p.selected &&
    css`
      color: ${p => p.theme.ContentAccentPrimary};
      & svg path {
        fill: ${p => p.theme.ContentAccentPrimary};
      }
    `}
`

const Container = styled(Base)`
  // margin-bottom: 4px;
  display: flex;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

const Title = styled.div<{ isOpenCloseItem: boolean }>`
  display: flex;
  align-items: center;
  margin-left: ${p => (p.isOpenCloseItem ? 12 : 12)}px;
  size: 16px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 20;
`
const TrailingIconContainer = styled.div`
  & svg path {
    fill: ${p => p.theme.ContentTertiary};
  }
  :hover {
    & svg path {
      fill: ${p => p.theme.ContentAccentPrimary};
    }
  }
`

export const ChevronContainer = styled.div<{ open: boolean }>`
  top: 8px;
  right: 8px;
  transform: rotate(${p => (p.open ? '0deg' : '180deg')});
  transition: 100ms;

  & svg path {
    fill: ${p => (p.open ? p.theme.ContentTertiary : p.theme.ContentTertiary)};
  }

  :hover {
    & svg path {
      fill: ${p => p.theme.ContentAccentPrimary};
    }
  }
`

export const SubItems = styled.div<{ height: string }>`
  overflow: hidden;
  transition: height 100ms;
  height: ${p => p.height};
  min-height: auto;

  :last-child {
    margin-bottom: 12px;
  }
`

export const SubItem = styled(Base)`
  height: 36px;
  color: ${p => p.theme.ContentSecondary};
  display: flex;
  align-items: center;
  justify-content: space-between;

  :hover {
    color: ${p => p.theme.ContentPrimary};
  }

  ${p =>
    p.selected &&
    css`
      color: ${p => p.theme.ContentAccentPrimary};
      & svg path {
        fill: ${p => p.theme.ContentAccentPrimary};
      }
    `}
`

export const SubSubItem = styled(SubItem)`
  margin-left: 20px;
`

const ComingSoon = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${p => p.theme.ContentPrimary};
  padding: 4px 8px;
  border: 1px solid ${p => p.theme.BorderAccentInfo};
  border-radius: 8px;
  margin-left: 8px;
`
