import styled from 'styled-components'

export const H1 = styled.div`
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
`

export const H2 = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
`

export const H3 = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
`

export const H4 = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`

export const H5 = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
`

export const H6 = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
`

export const LargeBody = styled.div`
  font-size: 16px;
  line-height: 24px;
`

export const Body = styled.div`
  font-size: 14px;
  line-height: 20px;
`

export const Caption = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${p => p.theme.ContentSecondary};
`

export const Tiny = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: ${p => p.theme.ContentSecondary};
`

export const Caps = styled.div`
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-weight: 500;
`
