import React from 'react'
import styled from 'styled-components'

interface Props {
  value: number
}

export const GainedLost: React.FC<Props> = ({ value }) => (
  <Container positive={value >= 0}>
    {value >= 0 ? '↗ Gained' : '↘  Lost'} {Math.abs(value).toLocaleString()}
  </Container>
)

const Container = styled.div<{ positive: boolean }>`
  display: inline-block;
  font-weight: 600;
  color: ${p => (p.positive ? p.theme.PRIMARY_50 : p.theme.RED_60_BASE)};
`
