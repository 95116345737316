import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Company: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1236 3.50207C11.957 3.40726 11.75 3.52763 11.75 3.71937V7V17.25H18.25V7.71431C18.25 7.26488 18.0087 6.85005 17.6181 6.62782L12.1236 3.50207ZM10.25 3.71937V5.26772C10.1683 5.25604 10.0849 5.25 10 5.25H2C1.0335 5.25 0.25 6.0335 0.25 7V17C0.25 17.9665 1.0335 18.75 2 18.75H11.75L19 18.75C19.4142 18.75 19.75 18.4142 19.75 18V7.71431C19.75 6.72557 19.2192 5.81294 18.3598 5.32403L12.8653 2.19829C11.6987 1.5346 10.25 2.37717 10.25 3.71937ZM10 6.75C10.1381 6.75 10.25 6.86193 10.25 7V17.25H2C1.86193 17.25 1.75 17.1381 1.75 17V7C1.75 6.86193 1.86193 6.75 2 6.75H10ZM4.25 10C4.66421 10 5 9.66421 5 9.25C5 8.83579 4.66421 8.5 4.25 8.5C3.83579 8.5 3.5 8.83579 3.5 9.25C3.5 9.66421 3.83579 10 4.25 10ZM5 12C5 12.4142 4.66421 12.75 4.25 12.75C3.83579 12.75 3.5 12.4142 3.5 12C3.5 11.5858 3.83579 11.25 4.25 11.25C4.66421 11.25 5 11.5858 5 12ZM4.25 15.5C4.66421 15.5 5 15.1642 5 14.75C5 14.3358 4.66421 14 4.25 14C3.83579 14 3.5 14.3358 3.5 14.75C3.5 15.1642 3.83579 15.5 4.25 15.5ZM8.5 9.25C8.5 9.66421 8.16421 10 7.75 10C7.33579 10 7 9.66421 7 9.25C7 8.83579 7.33579 8.5 7.75 8.5C8.16421 8.5 8.5 8.83579 8.5 9.25ZM15 10C15.4142 10 15.75 9.66421 15.75 9.25C15.75 8.83579 15.4142 8.5 15 8.5C14.5858 8.5 14.25 8.83579 14.25 9.25C14.25 9.66421 14.5858 10 15 10ZM8.5 12C8.5 12.4142 8.16421 12.75 7.75 12.75C7.33579 12.75 7 12.4142 7 12C7 11.5858 7.33579 11.25 7.75 11.25C8.16421 11.25 8.5 11.5858 8.5 12ZM15 12.75C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25C14.5858 11.25 14.25 11.5858 14.25 12C14.25 12.4142 14.5858 12.75 15 12.75ZM8.5 14.75C8.5 15.1642 8.16421 15.5 7.75 15.5C7.33579 15.5 7 15.1642 7 14.75C7 14.3358 7.33579 14 7.75 14C8.16421 14 8.5 14.3358 8.5 14.75ZM15 15.5C15.4142 15.5 15.75 15.1642 15.75 14.75C15.75 14.3358 15.4142 14 15 14C14.5858 14 14.25 14.3358 14.25 14.75C14.25 15.1642 14.5858 15.5 15 15.5Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
