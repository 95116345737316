import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { Input, Spinner, standardIcons } from '@chordco/component-library'
import { useIsMobile } from 'hooks'
import { InverseThemeProvider } from 'themes/ThemeSwitcherProvider'

type Props = {
  value: string
  setValue: (s: string) => void
  suggestionsLoading: boolean
  searchableCategories: string[]
  results: JSX.Element[]
  onKeyUp?: React.KeyboardEventHandler
  onKeyDown?: React.KeyboardEventHandler
}

export const SearchInput: React.FC<Props> = ({
  value,
  setValue,
  suggestionsLoading,
  searchableCategories,
  results,
  ...props
}) => {
  const [hasFocus, setHasFocus] = useState<boolean>(false)

  const isMobile = useIsMobile()

  return (
    <Container>
      <StyledInput
        leftIcon={standardIcons.Search}
        placeholder={hasFocus ? '' : 'Search...'}
        isSmall={isMobile}
        value={value}
        onChange={e => setValue(e.target.value)}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setTimeout(() => setHasFocus(false), 200)}
        onKeyUp={props.onKeyUp}
        onKeyDown={props.onKeyDown}
        id="search-input"
        {...props}
      />
      {hasFocus && (
        <InverseThemeProvider>
          <ResultsContainer isSmall={isMobile}>
            {value.length < 3 && (
              <>
                <StartTyping>
                  {value.length === 0 ? 'Start' : 'Continue'} typing to search
                  by...
                </StartTyping>
                {searchableCategories.map(c => (
                  <ResultCategory key={c}>{c}</ResultCategory>
                ))}
              </>
            )}

            {suggestionsLoading && (
              <SpinnerContainer>
                <StyledSpinner />
              </SpinnerContainer>
            )}

            {!suggestionsLoading && value.length >= 3 && !results.length && (
              <ResultRow>No results...</ResultRow>
            )}

            {value.length >= 3 && !!results.length && results}
          </ResultsContainer>
        </InverseThemeProvider>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

export const StyledInput = styled(Input)<{ isSmall: boolean }>`
  margin-right: 20px;
  margin-top: 0;
  width: ${p => (p.isSmall ? '100%' : '280px')};
  transition: 0ms;
`

export const ResultsContainer = styled.div<{ isSmall: boolean }>`
  width: ${p => (p.isSmall ? '100%' : '280px')};
  border: 1px solid ${p => p.theme.ComponentFieldBorderDefault};
  border-radius: 8px;
  background: ${p => p.theme.ComponentMenuBg};
  color: ${p => p.theme.ContentPrimary};
  box-sizing: border-box;

  position: absolute;
  top: 46px;
  z-index: 1;

  & > div:first-child {
    margin-top: 4px;
  }

  & > div {
    border-bottom: 1px solid ${p => p.theme.BorderHairline};
  }

  min-height: 30px;
`

export const ResultRow = styled.div`
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
`

export const StartTyping = styled(ResultRow)``

export const ResultGroup = styled.div``

export const ResultCategory = styled(ResultRow)<{ hasMatches?: boolean }>`
  color: ${p => p.theme.ContentPrimary};
  padding: 6px 16px;
`

export const ResultOption = styled.div<{ height?: string; selected?: boolean }>`
  padding: 4px 16px;
  font-size: 14px;
  min-height: ${p => p.height || '32px'};
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.ComponentFieldBackgroundDefault};
    border-color: ${p => p.theme.ComponentFieldBorderHover};
  }

  ${p =>
    p.selected &&
    css`
      background-color: ${p => p.theme.ComponentFieldBackgroundActive};
      border-color: ${p => p.theme.ComponentFieldBorderActive};
      color: ${p => p.theme.ContentPrimary};
    `}
`

const SpinnerContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 30px;
  margin: 0 !important;
`

const StyledSpinner = styled(Spinner)`
  top: 0;
  right: 0;
`
