import React from 'react'
import { Chip, ChipColor } from '@chordco/component-library'

type SyncChipProps = {
  status: string
}

export const SyncChip: React.FC<SyncChipProps> = ({ status }) => {
  const getChipSetupText = (status: string): string => {
    switch (status.toLowerCase()) {
      case 'not run yet':
        return 'Not run yet'

      case 'working':
      case 'queued':
      case 'skipped':
        return 'Running...'

      case 'completed':
        return 'Up to date'

      case 'failed':
        return 'Failed'

      default:
        throw new Error('Unknown sync run state: ' + status)
    }
  }

  const getChipSetupColor = (status: string): ChipColor => {
    switch (status.toLowerCase()) {
      case 'not run yet':
        return 'neutral'

      case 'working':
      case 'queued':
      case 'skipped':
      case 'completed':
        return 'green'

      case 'failed':
        return 'red'

      default:
        throw new Error('Unknown sync run state: ' + status)
    }
  }

  return (
    <Chip
      text={getChipSetupText(status)}
      color={getChipSetupColor(status)}
      variant="solid-secondary"
    />
  )
}
