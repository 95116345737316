import React, { useRef, useEffect, useState } from 'react'
import { LookerEmbedSDK, LookSaveEvent } from '@looker/embed-sdk'
import { useTheme } from '@chordco/component-library'
import { CloseX } from '@chordco/component-library/components/icons/standardIcons/CloseX'
import { EnvVarConfig } from 'utils/envVarConfig'
import { Loading } from 'components/common'
import useEmbedUserAudienceUrl from 'components/looker/useEmbedUserAudienceUrl'
import { EmbedContainer } from 'components/looker/EmbedContainer'
import { ErrorContainer } from 'components/looker/ErrorContainer'
import styled from 'styled-components'

export interface AudienceEmbedProps {
  slug: string
  onDeleteAudience: (event: LookSaveEvent) => void
}

export const UserAudienceEmbed = ({
  slug,
  onDeleteAudience,
}: AudienceEmbedProps) => {
  const theme = useTheme()

  const ref = useRef<HTMLDivElement>(null)

  const [error, setError] = useState<string | null>(null)

  const lookerHost = EnvVarConfig.REACT_APP_LOOKER_HOST

  const { embedUserAudienceUrls, isLoading } = useEmbedUserAudienceUrl(
    slug,
    'audience'
  )

  useEffect(() => {
    const el = ref.current
    if (el && lookerHost && slug) {
      el.innerHTML = ''

      LookerEmbedSDK.createLookWithUrl(embedUserAudienceUrls[slug])
        .withApiHost(lookerHost)
        .withNext()
        .withDialogScroll(true)
        .withDynamicIFrameHeight(true)
        .withScrollMonitor(true)
        .withAllowAttr('fullscreen')
        .appendTo(el)
        .on('look:delete:complete', onDeleteAudience)
        .build()
        .connect()
        .catch((error: Error) => {
          // eslint-disable-next-line no-console
          console.error('Error loading Looker user audience:', error)
          setError(
            'We are unable to load the user audience. Please try again later.'
          )
        })
    }
  }, [slug, lookerHost, embedUserAudienceUrls])

  return (
    <Wrapper>
      {error && (
        <ErrorContainer>
          <div>{error}</div>
          <span onClick={() => setError(null)}>
            <CloseX fill={theme.GREY_80} />
          </span>
        </ErrorContainer>
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <EmbedContainer id="embedcontainer" ref={ref} verticalOffset={200} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 16px 0;
`
