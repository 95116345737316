import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Target: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6.5H15C16.933 6.5 18.5 8.067 18.5 10C18.5 11.933 16.933 13.5 15 13.5H5C3.067 13.5 1.5 11.933 1.5 10C1.5 8.067 3.067 6.5 5 6.5ZM0 10C0 7.23858 2.23858 5 5 5H15C17.7614 5 20 7.23858 20 10C20 12.7614 17.7614 15 15 15H5C2.23858 15 0 12.7614 0 10ZM5 8C3.89543 8 3 8.89543 3 10C3 11.1046 3.89543 12 5 12H11C12.1046 12 13 11.1046 13 10C13 8.89543 12.1046 8 11 8H5Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
