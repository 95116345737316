import React, { FormEventHandler, useState } from 'react'
import { AvatarSize, Button } from '@chordco/component-library'
import { useIsMobile } from 'hooks'
import { Bottom, LoginLink, Form } from './common'
import { Integration, IntegrationType } from 'types'
import { ConfirmationModal } from '../ConfirmationModal'
import { Loading } from 'components/common'

type Props = {
  children?: React.ReactNode
  integrationType: IntegrationType
  integration?: Integration
  integrationTitle: string
  logo: string
  loginLink: string
  onChangeProvider: () => void
  onSubmit: FormEventHandler<HTMLFormElement>
  isLoading: boolean
  confirmationMessage: string
}

export const MultipleProviderForm: React.FC<Props> = ({
  integrationTitle,
  logo,
  loginLink,
  onChangeProvider,
  onSubmit,
  isLoading,
  children,
  confirmationMessage,
}) => {
  const isMobile = useIsMobile()
  const size: AvatarSize = isMobile ? 32 : 48

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)

  const onFormSubmit = e => {
    e?.preventDefault()
    setShowConfirmation(true)
  }

  const onConfirm = e => {
    setShowConfirmation(false)
    onSubmit(e)
  }

  const onCancel = () => setShowConfirmation(false)

  return (
    <Form onSubmit={onFormSubmit}>
      {isLoading && <Loading />}
      <LoginLink
        name={integrationTitle}
        logo={logo}
        logoSize={size}
        loginLink={loginLink}
        onChangeProvider={onChangeProvider}
      />
      {children}
      <Bottom>
        <Button type="submit">Save</Button>
      </Bottom>
      {showConfirmation && (
        <ConfirmationModal
          onConfirm={onConfirm}
          onCancel={onCancel}
          message={confirmationMessage}
        />
      )}
    </Form>
  )
}
