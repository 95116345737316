import React, { useEffect, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { DayPickerRangeController } from 'react-dates'
import { DateRangePickerProps as DateRangePickerProps } from './DateRangePicker'
import { H3 } from '../type'
import { Button, RadioButton } from '../controls'
import { commonProps } from './commonProps'
import { Input } from '../inputs'

type Props = DateRangePickerProps & {
  mobileDatePickerRef: any
  setOpen: (open: boolean) => void
  focusedRangeInput: string
  onDatesChange: (arg: {
    startDate: moment.Moment | null
    endDate: moment.Moment | null
  }) => void
  onFocusRangeChange: (input: any) => void
}

const endDate = moment().endOf('day')
const lastNDays = (n: number) => moment().subtract(n, 'days').startOf('day')
const presets = [
  { id: 1, label: 'Today', startDate: moment().startOf('day'), endDate },
  {
    id: 2,
    label: 'Last 7 days',
    startDate: lastNDays(7),
    endDate,
  },
  {
    id: 3,
    label: 'Last 30 days',
    startDate: lastNDays(30),
    endDate,
  },
  {
    id: 4,
    label: 'Last 90 days',
    startDate: lastNDays(90),
    endDate,
  },
  {
    id: 5,
    label: 'Last 12 months',
    startDate: moment().subtract(12, 'months').startOf('day'),
    endDate,
  },
  {
    id: 6,
    label: 'Custom',
    startDate: null,
    endDate: null,
  },
]

export const MobileDateRangePicker: React.FC<Props> = ({
  startDate,
  endDate,
  mobileDatePickerRef,
  setOpen,
  focusedRangeInput,
  onDatesChange,
  onFocusRangeChange,
}) => {
  const [stagedStartDate, setStagedStartDate] = useState<moment.Moment | null>(
    startDate || null
  )
  const [stagedEndDate, setStagedEndDate] = useState<moment.Moment | null>(
    endDate || null
  )
  const [selectedPresetId, setSelectedPresetId] = useState<number | null>(null)

  useEffect(() => {
    if (!stagedStartDate && !stagedEndDate) return

    const matchingPreset = presets.find(p => {
      const startMatches = p.startDate
        ?.startOf('day')
        .isSame(stagedStartDate?.startOf('day'))
      const endMatches = p.endDate
        ?.endOf('day')
        .isSame(stagedEndDate?.endOf('day'))
      return startMatches && endMatches
    })

    if (matchingPreset) {
      setSelectedPresetId(matchingPreset.id)
    } else {
      setSelectedPresetId(presets[presets.length - 1].id)
    }
  }, [stagedStartDate, stagedEndDate])

  const onStagedDatesChange = (props: {
    startDate: moment.Moment | null
    endDate: moment.Moment | null
  }) => {
    setStagedStartDate(props.startDate)
    setStagedEndDate(props.endDate)
  }

  const onApply = () => {
    if (!stagedStartDate || !stagedEndDate) return
    onDatesChange({
      startDate: stagedStartDate,
      endDate: stagedEndDate,
    })
    setOpen(false)
  }

  const onClear = () => {
    setStagedStartDate(null)
    setStagedEndDate(null)
    setSelectedPresetId(null)
  }

  return (
    <MobileDateContainer ref={mobileDatePickerRef}>
      <H3>Date</H3>
      <MobileCancel>
        <Button onClick={() => setOpen(false)} purpose={'secondary'}>
          Cancel
        </Button>
      </MobileCancel>
      <RadioButtonContainer>
        {presets.map(({ id, label, startDate, endDate }) => {
          const onSelect = () => {
            setSelectedPresetId(id)
            setStagedStartDate(startDate)
            setStagedEndDate(endDate)
          }
          return (
            <RadioButton
              key={id}
              label={label}
              small={false}
              onChange={onSelect}
              checked={selectedPresetId === id}
            />
          )
        })}
      </RadioButtonContainer>
      <FlexContainer>
        <div>
          <StyledInput
            label="From"
            value={stagedStartDate?.format('MM/DD/YYYY') || ''}
            placeholder="MM/DD/YYYY"
            readOnly
            disabled
          />
        </div>
        <div>
          <StyledInput
            label="To"
            value={stagedEndDate?.format('MM/DD/YYYY') || ''}
            placeholder="MM/DD/YYYY"
            readOnly
            disabled
          />
        </div>
      </FlexContainer>
      <PickerStyling>
        <DayPickerRangeController
          startDate={stagedStartDate}
          endDate={stagedEndDate}
          onDatesChange={onStagedDatesChange}
          focusedInput={focusedRangeInput}
          onFocusChange={onFocusRangeChange}
          initialVisibleMonth={null}
          noBorder
          {...commonProps}
        />
      </PickerStyling>
      <Hr />
      <MobileFooter>
        <Button purpose="ghost" onClick={onClear}>
          Clear form
        </Button>
        <Button purpose="primary" onClick={onApply}>
          Apply
        </Button>
      </MobileFooter>
    </MobileDateContainer>
  )
}

const MobileDateContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  z-index: 1;
  background-color: ${p => p.theme.OFF_BLACK};
  border: 1px solid ${p => p.theme.GREY_4};
  border-radius: 16px 0 0 16px;
  padding: 30px 20px 0;
`

const RadioButtonContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
`

const MobileCancel = styled.div`
  position: absolute;
  top: 24px;
  right: 20px;
`

const MobileFooter = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-around;
`
const StyledInput = styled(Input)`
  min-width: 100px;
  max-width: 172px;
  width: calc((100vw - 40px) / 2 - 10px);
  border-color: ${p => p.theme.PRIMARY_50};
`

const Hr = styled.hr`
  border: 1px solid #373737;
  margin: 16px -20px;
`

const PickerStyling = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
