import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { H1, H2, Spinner, TabBar } from '@chordco/component-library'
import { Explore } from 'components/looker/Explore'
import { useIsMobile, useAnalyticsTabsHelper } from 'hooks'
import { useLookerData } from 'redux/state/looker'
import { Hr } from '../common'
import { BrowserWarning } from './BrowserWarning'
import { NavigationContext } from 'components/AuthenticatedRoutes'
import { MyFolder } from 'components/looker/MyFolder'
import { useIsLoading } from 'redux/utils'
import { MyEmptyFolder } from 'components/looker/MyEmptyFolder'
import useMetricSlug from 'hooks/useMetricSlug'
import { Reports } from './reports/Reports'
import { getReportTabs } from './tabs'
import LookerStatus, { LookerStatusType } from './LookerStatus'
import { Quickstarts } from './reports/Quickstarts'
import { useNotifications } from 'redux/state/notifications'
import { v4 as generateUUID } from 'uuid'
import { LookEvent } from '@looker/embed-sdk'

export interface AnalyticsFeatureFlags {
  showMyFolder: boolean
  showLookerUnderReview: boolean
  showLookerIsSample: boolean
  showLookerUnconfigured: boolean
}

interface AnalyticsProps {
  featureFlags: AnalyticsFeatureFlags
}

export const Analytics: React.FC<AnalyticsProps> = ({ featureFlags }) => {
  const {
    showMyFolder,
    showLookerUnderReview,
    showLookerIsSample,
    showLookerUnconfigured,
  } = featureFlags

  const { addNotification } = useNotifications()

  const history = useHistory()
  const isMobile = useIsMobile()
  const Heading = isMobile ? H2 : H1

  const {
    getAvailableUserReports,
    getAvailableReports,
    getAvailableQuickstarts,
    state: { availableReports, availableUserReports, availableQuickstarts },
  } = useLookerData()

  const tabs = useMemo(() => getReportTabs(showMyFolder), [showMyFolder])

  const [selectedTabId, setSelectedTabId] = useAnalyticsTabsHelper(tabs)

  // This hook will retrieve the slug for the current metric. If the slug cannot be found
  // in the path, it will default to the first available metric for the current active tab.
  const { slug, isMyFolder, isDashboards, isExplores, isQuickstarts } =
    useMetricSlug(availableReports, availableUserReports, availableQuickstarts)

  const isRefreshingUserReports = useIsLoading('getAvailableUserReports')
  const isLoadingQuickstarts = useIsLoading('getAvailableQuickstarts')

  const refreshMyFolderAndRedirect = async () => {
    if (getAvailableUserReports) {
      await getAvailableUserReports()
      history.push('/analytics/my_folder')
    }
  }

  const notifyMyFolderEvent = (message: string) => {
    const id = generateUUID() as string
    addNotification({
      id,
      type: 'success',
      message,
    })
  }

  const handleSavedLook = async (_event: LookEvent) => {
    notifyMyFolderEvent('Look saved successfully')
    await refreshMyFolderAndRedirect()
  }

  const handleDeletedLook = async () => {
    notifyMyFolderEvent('Look deleted successfully')
    await refreshMyFolderAndRedirect()
  }

  const handleDeletedDashboard = async () => {
    notifyMyFolderEvent('Dashboard deleted successfully')
    await refreshMyFolderAndRedirect()
  }

  const handlePublishedReport = async () => {
    if (getAvailableReports) {
      getAvailableReports().then(() => {
        history.push('/analytics/reports')
      })
    }
  }

  const handleRefreshQuickstarts = async () => {
    if (getAvailableQuickstarts) {
      getAvailableQuickstarts(true)
    }
  }

  const isEmptyUserReports = useMemo(
    () =>
      availableUserReports.userDashboards.length === 0 &&
      availableUserReports.userLooks.length === 0 &&
      !isRefreshingUserReports,
    [availableUserReports, isRefreshingUserReports]
  )

  return (
    <NavigationContext.Consumer>
      {({ navOpen }) => (
        <Wrapper navOpen={navOpen}>
          <Header>
            <Heading>Analytics</Heading>
            <TabBar
              tabs={tabs}
              selectedId={selectedTabId}
              setSelectedId={setSelectedTabId}
              resetOnInvalidSelection
            />
            <Hr isMobile={isMobile} />

            <BrowserWarning />
          </Header>

          {showLookerUnconfigured && (
            <LookerStatus status={LookerStatusType.Unconfigured} />
          )}

          {showLookerUnderReview && (
            <LookerStatus status={LookerStatusType.UnderReview} />
          )}

          {showLookerIsSample && (
            <LookerStatus status={LookerStatusType.IsSample} />
          )}

          {!showLookerUnconfigured && !showLookerUnderReview && (
            <EmbedContainer>
              {isExplores && (
                <Explore slug={slug} options={availableReports.explores} />
              )}
              {isDashboards && (
                <Reports
                  reports={[
                    ...availableReports.dashboards,
                    ...availableReports.looks,
                  ]}
                />
              )}
              {isQuickstarts && isLoadingQuickstarts && <Spinner scale={30} />}

              {isQuickstarts && !isLoadingQuickstarts && (
                <Quickstarts
                  dashboards={availableQuickstarts.dashboards}
                  looks={availableQuickstarts.looks}
                  onRefresh={handleRefreshQuickstarts}
                />
              )}

              {isMyFolder && !isEmptyUserReports && slug && (
                <MyFolder
                  slug={slug}
                  dashboards={availableUserReports.userDashboards}
                  looks={availableUserReports.userLooks}
                  onDeletedDashboard={handleDeletedDashboard}
                  onDeletedLook={handleDeletedLook}
                  onSavedLook={handleSavedLook}
                  onPublishedDashboard={handlePublishedReport}
                  onPublishedLook={handlePublishedReport}
                />
              )}

              {isMyFolder && isEmptyUserReports && <MyEmptyFolder />}
            </EmbedContainer>
          )}
        </Wrapper>
      )}
    </NavigationContext.Consumer>
  )
}

const Wrapper = styled.div<{ navOpen: boolean }>`
  color: ${p => p.theme.ContentPrimary};
  height: 100vh;
  width: ${p => (p.navOpen ? 'calc(100vw - 280px)' : '100vw')};
  position: fixed;
  top: 0;
  right: 0;
`

const Header = styled.div`
  padding: 24px 32px 0px 64px;
`

const EmbedContainer = styled.div`
  width: 100%;
  height: calc(100vh - 90px);
`
