import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Share: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5396 1.14575C10.4032 1.00452 10.2118 0.916687 10 0.916687C9.92231 0.916687 9.84737 0.928501 9.77689 0.950431C9.66443 0.985332 9.55856 1.04731 9.46951 1.13636L6.13617 4.46969C5.84328 4.76258 5.84328 5.23746 6.13617 5.53035C6.42907 5.82324 6.90394 5.82324 7.19683 5.53035L9.25 3.47718V12.5C9.25 12.9142 9.58579 13.25 10 13.25C10.4142 13.25 10.75 12.9142 10.75 12.5V3.47751L12.8028 5.53035C13.0957 5.82324 13.5706 5.82324 13.8635 5.53035C14.1564 5.23746 14.1564 4.76258 13.8635 4.46969L10.5396 1.14575ZM3.3335 9.25C3.74771 9.25 4.0835 9.58579 4.0835 10V16.6667C4.0835 16.9098 4.18007 17.1429 4.35198 17.3148C4.52389 17.4868 4.75705 17.5833 5.00016 17.5833H15.0002C15.2433 17.5833 15.4764 17.4868 15.6483 17.3148C15.8203 17.1429 15.9168 16.9098 15.9168 16.6667V10C15.9168 9.58579 16.2526 9.25 16.6668 9.25C17.081 9.25 17.4168 9.58579 17.4168 10V16.6667C17.4168 17.3076 17.1622 17.9223 16.709 18.3755C16.2558 18.8287 15.6411 19.0833 15.0002 19.0833H5.00016C4.35922 19.0833 3.74453 18.8287 3.29132 18.3755C2.83811 17.9223 2.5835 17.3076 2.5835 16.6667V10C2.5835 9.58579 2.91928 9.25 3.3335 9.25Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
