import React, { useState } from 'react'
import styled from 'styled-components'
import { standardIcons } from '@chordco/component-library'

const { CloseX } = standardIcons

export const BrowserWarning: React.FC = () => {
  const [isOpen, setIsOpen] = useState(
    () => !(localStorage.getItem('dontShowBrowserWarning') === 'true')
  )

  const dontShowAgain = () => {
    localStorage.setItem('dontShowBrowserWarning', 'true')
    setIsOpen(false)
  }

  const isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)

  if (isOpen && !isChrome) {
    return (
      <Container>
        <Text>
          Some browsers don&apos;t support Analytics. Please use the most recent
          version of Chrome if you&apos;re having problems.{' '}
          <DontShowAgain onClick={dontShowAgain}>
            Don&apos;t show again
          </DontShowAgain>
        </Text>
        <CloseIcon onClick={() => setIsOpen(false)} />
      </Container>
    )
  }

  return null
}

const CloseIcon = styled(CloseX)`
  cursor: pointer;
`

const Container = styled.div`
  height: 40px;
  width: 100%;
  background: ${p => p.theme.AMBER_30};
  border-radius: 8px;
  margin-top: 32px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DontShowAgain = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

const Text = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${p => p.theme.GREY_80};
`
