import React, {
  cloneElement,
  Dispatch,
  SetStateAction,
  useRef,
  useState,
} from 'react'
import moment from 'moment'
import styled from 'styled-components'

import { BaseContainer } from './common'
import { useOutsideClickMultiple } from '../hooks'
import { DateRangeOptions } from './DateRangeOptions'
import { getOptionsByPhrase } from './utils'
import { Input } from '../inputs'
import { MobileDateRangePicker } from './MobileDateRangePicker'
import { Calendar } from './'
import { useIsMobile } from '../../hooks'

export interface DateRangePickerProps {
  startDate?: moment.Moment
  endDate?: moment.Moment
  setStartDate: Dispatch<SetStateAction<moment.Moment | undefined>>
  setEndDate: Dispatch<SetStateAction<moment.Moment | undefined>>
  control: any
  left?: boolean
  isMobile?: boolean
}

const START_DATE = 'startDate'
const END_DATE = 'endDate'

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  control,
  left = false,
  isMobile: baseIsMobile,
}) => {
  const [open, setOpen]: [boolean, any] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const datePickerRef = useRef() as any
  const controlRef = useRef() as any
  const inputRef = useRef() as any
  const mobileDatePickerRef = useRef() as any

  useOutsideClickMultiple(
    open,
    [datePickerRef, controlRef, inputRef, mobileDatePickerRef],
    () => setOpen(false)
  )

  const onDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const [focusedRangeInput, setFocusedInput] = useState(START_DATE)

  const onFocusRangeChange = focusedInput => {
    setFocusedInput(!focusedInput ? START_DATE : END_DATE)
  }

  const rangeOptions = getOptionsByPhrase(inputValue)

  const isMobileWidth = useIsMobile()
  const isMobile =
    typeof baseIsMobile !== 'undefined' ? baseIsMobile : isMobileWidth

  return (
    <Container>
      <div ref={controlRef}>
        {cloneElement(control, {
          onClick: () => setOpen(open => !open),
          selected: open,
        })}
      </div>
      {open && !isMobile && (
        <DateContainer left={left} ref={datePickerRef}>
          <DateInputContainer ref={inputRef}>
            <StyledInput
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              customWidth="287px"
              placeholder='Try "Last quarter"'
            />
          </DateInputContainer>
          {rangeOptions.length ? (
            <DateRangeOptions
              options={rangeOptions}
              onDatesChange={onDatesChange}
              clearInputValue={() => setInputValue('')}
            />
          ) : (
            <Calendar
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate}
              endDate={endDate}
            />
          )}
        </DateContainer>
      )}

      {open && isMobile && (
        <MobileDateRangePicker
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          control={control}
          mobileDatePickerRef={mobileDatePickerRef}
          onFocusRangeChange={onFocusRangeChange}
          focusedRangeInput={focusedRangeInput}
          onDatesChange={onDatesChange}
          startDate={startDate}
          endDate={endDate}
          setOpen={setOpen}
          left={left}
        />
      )}
    </Container>
  )
}

const Container = styled(BaseContainer as any)`
  position: relative;
`

export const DateContainer = styled.div<{ left: boolean }>`
  position: absolute;
  top: 48px;
  right: ${p => (p.left ? 0 : 'auto')};
  z-index: 1;
  background-color: ${p => p.theme.BgBase2};
  border-radius: ${p => p.theme.CardSmallRadius};
  border: solid 1px ${p => p.theme.BorderMinimal};
`

export const DateInputContainer = styled.div`
  padding: 12px 16px 8px;
`

const StyledInput = styled(Input)``
