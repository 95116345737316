import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Publish: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 10.0003L1.3048 9.71891L1.19088 10.0003L1.3048 10.2817L2 10.0003ZM17.9999 10.0003L18.6952 10.2817L18.8091 10.0003L18.6952 9.71891L17.9999 10.0003ZM2.6952 10.2817C3.8643 7.39357 6.69537 5.3584 9.99997 5.3584V3.8584C6.0635 3.8584 2.6953 6.28379 1.3048 9.71891L2.6952 10.2817ZM9.99997 5.3584C13.3046 5.3584 16.1356 7.39357 17.3047 10.2817L18.6952 9.71891C17.3047 6.28379 13.9365 3.8584 9.99997 3.8584V5.3584ZM17.3047 9.71891C16.1356 12.6071 13.3046 14.6422 9.99997 14.6422V16.1422C13.9365 16.1422 17.3047 13.7168 18.6952 10.2817L17.3047 9.71891ZM9.99997 14.6422C6.69537 14.6422 3.8643 12.6071 2.6952 9.71891L1.3048 10.2817C2.6953 13.7168 6.0635 16.1422 9.99997 16.1422V14.6422Z"
        fill={fill || theme.ContentPrimary}
      />
      <circle
        cx="10"
        cy="10"
        r="3"
        stroke={theme.ContentPrimary}
        strokeWidth="1.5"
      />
    </svg>
  )
}
