import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Encyclopedia: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.25 18.3333H6.25C4.20628 18.3333 2.5 16.5951 2.5 14.5455V5.45455C2.5 3.36256 4.17893 1.66667 6.25 1.66667H15.25C16.4926 1.66667 17.5 2.68421 17.5 3.9394V16.0606C17.5 17.3158 16.4926 18.3333 15.25 18.3333ZM6.25 3.18182C5.00736 3.18182 4 4.19936 4 5.45455V12.2725C4.62675 11.7969 5.4058 11.5152 6.25 11.5152H15.25C15.513 11.5152 15.7654 11.5607 16 11.6445V3.9394C16 3.521 15.6642 3.18182 15.25 3.18182H14.5V7.72728C14.5 8.00667 14.3478 8.26338 14.1039 8.39521C13.86 8.52704 13.5641 8.5126 13.334 8.35762L11.5 7.12262L9.66602 8.35762C9.43588 8.5126 9.13997 8.52704 8.89611 8.39521C8.65224 8.26338 8.5 8.00667 8.5 7.72728V3.18182H6.25ZM10 3.18182H13V6.31173L11.916 5.58178C11.6641 5.41214 11.3359 5.41214 11.084 5.58178L10 6.31173V3.18182ZM15.25 13.0303C15.6642 13.0303 16 13.3695 16 13.7879V16.0606C16 16.479 15.6642 16.8182 15.25 16.8182H6.25C5.13513 16.8182 4.03111 15.9971 4.03111 14.9242C4.03111 13.8281 5.13513 13.0303 6.25 13.0303H15.25Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
