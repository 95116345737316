import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const ModelAmplifications: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.53032 10C7.11611 10 6.78033 10.3358 6.78033 10.75C6.78033 11.1642 7.11611 11.5 7.53032 11.5H9.71944L6.21967 14.9998C5.92678 15.2927 5.92678 15.7676 6.21967 16.0605C6.51256 16.3534 6.98743 16.3534 7.28033 16.0605L10.7803 12.5604V14.75C10.7803 15.1642 11.1161 15.5 11.5303 15.5C11.9445 15.5 12.2803 15.1642 12.2803 14.75V10.75C12.2803 10.3358 11.944 10 11.5298 10H7.53032Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6716 1H7C4.79086 1 3 2.79086 3 5V15C3 17.2091 4.79086 19 7 19H13C15.2091 19 17 17.2091 17 15V6.32843C17 6.03985 16.9376 5.7577 16.8204 5.5C16.7221 5.28403 16.5852 5.08523 16.4142 4.91421L13.0858 1.58579C12.9148 1.41477 12.716 1.27792 12.5 1.17964C12.2423 1.06237 11.9602 1 11.6716 1ZM12 7H15.5V15C15.5 16.3807 14.3807 17.5 13 17.5H7C5.61929 17.5 4.5 16.3807 4.5 15V5C4.5 3.61929 5.61929 2.5 7 2.5H11V6C11 6.55228 11.4477 7 12 7ZM12.5 5.5H14.8787L12.5 3.12132V5.5Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
