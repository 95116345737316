import React from 'react'
import {
  Route,
  Switch,
  RouteComponentProps,
  useRouteMatch,
} from 'react-router-dom'
import {
  Audiences,
  AudiencesFeatureFlags,
} from 'components/analytics/Audiences'
import Audience from 'components/analytics/audiences/Audience'

interface AudiencesRoutesProps extends RouteComponentProps {
  featureFlags: AudiencesFeatureFlags
}

const AudiencesRoutes: React.FC<AudiencesRoutesProps> = ({
  match,
  featureFlags,
}) => {
  // Prebuilt Audiences are rendered fullpage, so we don't want to show the main
  // Audiences component with the My Audiences/Prebuilt Audiences Tab controller
  const isAudienceRoute = useRouteMatch(
    `${match.path}/prebuilt-audiences/:slug`
  )

  return (
    <div>
      {!isAudienceRoute && <Audiences featureFlags={featureFlags} />}
      <Switch>
        <Route
          path={`${match.path}/prebuilt-audiences/:slug`}
          component={Audience}
        />
      </Switch>
    </div>
  )
}

export default AudiencesRoutes
