import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Alert: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.75 4C10.75 3.58579 10.4142 3.25 10 3.25C9.58579 3.25 9.25 3.58579 9.25 4L9.25 11C9.25 11.4142 9.58579 11.75 10 11.75C10.4142 11.75 10.75 11.4142 10.75 11L10.75 4ZM10 13.3333C9.44772 13.3333 9 13.7811 9 14.3333C9 14.8856 9.44772 15.3333 10 15.3333H10.0083C10.5606 15.3333 11.0083 14.8856 11.0083 14.3333C11.0083 13.7811 10.5606 13.3333 10.0083 13.3333H10Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
