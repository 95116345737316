import React from 'react'
import { Item, NavItemType } from './Item'
import styled from 'styled-components'

export interface NavItemProps {
  items: (NavItemType & { comingSoon?: boolean })[]
  selectedId?: number
  navigate?: Function
  text?: string
}

export const NavSection: React.FC<NavItemProps> = ({
  items,
  selectedId,
  navigate,
  text,
}) => (
  <>
    <Content>
      {text && (
        <Top>
          <Text>{text}</Text>
          <Line />
        </Top>
      )}
      {items.map(props => (
        <Item
          {...props}
          selectedId={selectedId}
          key={props.id}
          navigate={navigate}
        />
      ))}
    </Content>
  </>
)

const Content = styled.div`
  position: relative;
  overflow: auto;
`

const Top = styled.div`
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
`

const Text = styled.div`
  flex: 0;
  font-size: 10px;
  color: ${p => p.theme.ContentTertiary};
  text-transform: uppercase;
  white-space: pre;
`

const Line = styled.div`
  flex: 1;
  width: calc(100% - 36px);
  margin: 6px 0 0 10px;
  border-top: solid 1px ${p => p.theme.BorderMinimal};
`
