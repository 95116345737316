import React from 'react'
import { standardIcons, AlertModal } from '@chordco/component-library'
import { ConnectedSource } from 'api/fivetran/interfaces'

const { Refresh } = standardIcons

type Props = {
  source: ConnectedSource
  onToggleSyncNow: () => void
  onClose: () => void
}

export const ToggleSyncNowModal: React.FC<Props> = ({
  source,
  onToggleSyncNow,
  onClose,
}) => {
  return (
    <AlertModal
      onClose={onClose}
      icon={Refresh}
      content={`Are you sure you want to sync ${source.metadata.name}?`}
      subContent="This will trigger a data sync for your connector without waiting for the next scheduled sync. Only use if you know what you're doing."
      centered
      buttons={[
        {
          id: 1,
          text: 'No',
          onClick: onClose,
          purpose: 'tertiary',
          disabled: false,
        },
        {
          id: 2,
          text: `Yes, sync now`,
          onClick: onToggleSyncNow,
          purpose: 'primary',
          isLoading: false,
          disabled: false,
        },
      ]}
    />
  )
}
