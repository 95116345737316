import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Drag: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6C8.55228 6 9 5.55229 9 5C9 4.44772 8.55228 4 8 4C7.44772 4 7 4.44772 7 5C7 5.55229 7.44772 6 8 6ZM8 11C8.55228 11 9 10.5523 9 10C9 9.44772 8.55228 9 8 9C7.44772 9 7 9.44772 7 10C7 10.5523 7.44772 11 8 11ZM9 15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15C7 14.4477 7.44772 14 8 14C8.55228 14 9 14.4477 9 15ZM12.0001 6C12.5524 6 13.0001 5.55229 13.0001 5C13.0001 4.44772 12.5524 4 12.0001 4C11.4478 4 11.0001 4.44772 11.0001 5C11.0001 5.55229 11.4478 6 12.0001 6ZM13.0001 10C13.0001 10.5523 12.5524 11 12.0001 11C11.4478 11 11.0001 10.5523 11.0001 10C11.0001 9.44772 11.4478 9 12.0001 9C12.5524 9 13.0001 9.44772 13.0001 10ZM12.0001 16C12.5524 16 13.0001 15.5523 13.0001 15C13.0001 14.4477 12.5524 14 12.0001 14C11.4478 14 11.0001 14.4477 11.0001 15C11.0001 15.5523 11.4478 16 12.0001 16Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
