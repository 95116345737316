import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useHeightAndWidthHelpers } from 'hooks'

import {
  Col,
  ItemHeader,
  Loading,
  TableHeader,
  TableRow,
} from 'components/common'
import { formatDateTime } from '@chordco/component-library'
import { BusinessErrorsSheet } from './BusinessErrorsSheet'
import { BusinessError } from 'types/businessError'

import { useTenantsData } from 'redux/state/tenants'
import styled from 'styled-components'
import { useBusinessErrorsData } from 'redux/state/businessErrors'
import { useIsLoading } from 'redux/utils'
import { useEnvironmentsData } from 'redux/state/environments'

export const BusinessErrors: React.FC = () => {
  const {
    state: { selectedEnvironment: environment },
  } = useEnvironmentsData()

  const {
    getUserTenants,
    state: { currentTenant },
  } = useTenantsData()

  const {
    state: { businessErrorList, businessErrorEvents },
    getBusinessErrors,
    getBusinessErrorEvents,
  } = useBusinessErrorsData()

  const { isMobile } = useHeightAndWidthHelpers(false, 740)
  const history = useHistory()

  useEffect(() => {
    if (currentTenant?.id)
      getBusinessErrors({ tenantId: currentTenant.id, environment })
  }, [currentTenant?.id, environment])

  useEffect(() => {
    if (getUserTenants && !currentTenant) getUserTenants()
  }, [getUserTenants])

  const isLoading = useIsLoading('getBusinessErrors', 'getUserTenants')
  const [sheetState, setSheetOpen] = useState<BusinessError>()

  return (
    <>
      {isLoading && <Loading />}
      <ItemHeader
        title={'Chord OMS Errors'}
        isMobile={isMobile}
        backName="System Health"
        onBack={() => history.goBack()}
      />

      <Container>
        <TableHeader>
          <Col flex={10}>
            <Text>Message</Text>
          </Col>
          <Col flex={3}>
            <Text>Culprit</Text>
          </Col>
          <Col flex={2}>
            <Text>Last Seen</Text>
          </Col>
        </TableHeader>

        {businessErrorList.length && (
          <ErrorList>
            {businessErrorList.map((error, i) => {
              return (
                <>
                  <TableRow onClick={() => setSheetOpen(error)} key={i}>
                    <Col flex={10}>
                      <Text>{error.title}</Text>
                    </Col>

                    <Col flex={3}>
                      <Text>{error.culprit}</Text>
                    </Col>

                    <Col flex={2}>
                      <Text>{formatDateTime(error.lastSeen)}</Text>
                    </Col>
                  </TableRow>
                </>
              )
            })}
          </ErrorList>
        )}
      </Container>

      {sheetState && (
        <BusinessErrorsSheet
          onClose={() => setSheetOpen(undefined)}
          title={sheetState.culprit}
          tenantId={currentTenant?.id}
          issueId={sheetState.id}
          events={businessErrorEvents}
          getBusinessErrorEvents={getBusinessErrorEvents}
        ></BusinessErrorsSheet>
      )}
    </>
  )
}

const Container = styled.div`
  // margin: 36px;
`

const ErrorList = styled.div`
  overflow: scroll;
  height: calc(100vh - 160px);
  padding: 0 0 64px 0;
`

const Text = styled.div`
  margin: 0 0 0 12px;
`
