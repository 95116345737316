import { useRef, useState } from 'react'
import { useIsMobile, useResponsiveWidth } from './mobile'

export const useHeightAndWidthHelpers = (
  hasFilters?: boolean,
  customWidth?: number
) => {
  const [filtersHeight, setFiltersHeight] = useState(0)

  const containerRef = useRef() as any
  const width = useResponsiveWidth(containerRef)
  const isSmall = !!width && width < (customWidth || 650)
  const isMobile = useIsMobile()

  const getContentHeight = () => {
    const pageHeight = document.documentElement.scrollHeight - 8
    const filterHeight = hasFilters ? filtersHeight + 32 : 0
    const headerHeight = isSmall ? 180 : 256
    const mobileHeight = isMobile ? 8 : 0

    return pageHeight - filterHeight - headerHeight - mobileHeight + 14
  }

  return {
    getContentHeight,
    isMobile,
    isSmall,
    setFiltersHeight,
    containerRef,
    width,
  }
}
