import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Star: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99986 0.916656C10.2853 0.916656 10.546 1.07871 10.6724 1.33469L13.0731 6.19817L18.4417 6.98287C18.7241 7.02416 18.9586 7.22216 19.0466 7.49366C19.1347 7.76517 19.061 8.06311 18.8565 8.26226L14.9724 12.0453L15.8891 17.3899C15.9373 17.6713 15.8216 17.9556 15.5907 18.1234C15.3597 18.2912 15.0535 18.3133 14.8008 18.1805L9.99986 15.6557L5.19895 18.1805C4.94626 18.3133 4.64004 18.2912 4.40906 18.1234C4.17808 17.9556 4.06239 17.6713 4.11065 17.3899L5.0273 12.0453L1.14323 8.26226C0.938763 8.06311 0.865058 7.76517 0.953091 7.49366C1.04112 7.22216 1.27563 7.02416 1.55805 6.98287L6.92666 6.19817L9.32733 1.33469C9.45368 1.07871 9.7144 0.916656 9.99986 0.916656ZM9.99986 3.3611L8.09739 7.21529C7.98823 7.43643 7.77735 7.58977 7.53333 7.62544L3.27783 8.24744L6.35649 11.2461C6.5334 11.4184 6.61414 11.6667 6.5724 11.9101L5.846 16.1454L9.65077 14.1445C9.86931 14.0296 10.1304 14.0296 10.3489 14.1445L14.1537 16.1454L13.4273 11.9101C13.3856 11.6667 13.4663 11.4184 13.6432 11.2461L16.7219 8.24744L12.4664 7.62544C12.2224 7.58977 12.0115 7.43643 11.9023 7.21529L9.99986 3.3611Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
