import React from 'react'
import styled from 'styled-components'
import { daysBetweenDates } from '../../utils'

interface Props {
  start: Date
  end: Date
}
export const Thermometer: React.FC<Props> = ({ start, end }) => {
  let text
  let inProgressWidth = 0
  let completed = false
  const today = new Date()

  if (today.getTime() < start.getTime()) {
    text = `Starts in ${daysBetweenDates(today, start)} days`
  } else if (end.getTime() < today.getTime()) {
    text = `✓ ${daysBetweenDates(end, today)} days ago`
    completed = true
  } else {
    const numOfDays = daysBetweenDates(start, end)
    const daysSoFar = daysBetweenDates(start, today)
    text = `${daysSoFar} of ${numOfDays} days`
    inProgressWidth = (daysSoFar / numOfDays) * 152
  }
  return (
    <Container completed={completed}>
      {!!inProgressWidth && <Bar width={inProgressWidth} />}
      <Text>{text}</Text>
    </Container>
  )
}

const Container = styled.div<{ completed: boolean }>`
  position: relative;
  box-sizing: border-box;
  height: 24px;
  width: 152px;
  background-color: ${p =>
    p.completed ? p.theme.PRIMARY_20 : p.theme.GREY_20};
  color: ${p => (p.completed ? p.theme.PRIMARY_60 : p.theme.GREY_80)};
  border-radius: 8px;
  text-align: center;
  padding: 5px;
`

const Bar = styled.div<{ width: number }>`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${p => p.theme.GREY_40};
  border-radius: 8px;
  height: 24px;
  width: ${p => p.width}px;
  min-width: 16px;
  max-width: 152px;
`

const Text = styled.div`
  position: relative;
`
