import React, { useEffect, ComponentProps, useState } from 'react'
import styled from 'styled-components'
import { standardIcons } from '../icons'
import { Tooltip } from '../information'
import {
  Container,
  Label,
  LabelContainer,
  StyledInput,
  Required,
  ErrorMessage,
  InputContainer,
} from './Input'

interface Props extends ComponentProps<'input'> {
  label?: string
  hasError?: boolean
  errorMessage?: string
  leftIcon?: any
  rightIcon?: any
  prefix?: string
  suffix?: string
  hint?: string
  selected?: boolean
  inputRef?: any
  focus?: boolean
  customWidth?: string
}

const { Eye, Copy } = standardIcons

export const SecureInput: React.FC<Props> = ({
  label,
  errorMessage,
  inputRef,
  focus = false,
  customWidth,
  ...props
}) => {
  const hasError = !!errorMessage

  useEffect(() => {
    focus && inputRef?.current?.focus()
  }, [inputRef])

  const [show, setShow] = useState(false)
  const toggleShow = () => setShow(s => !s)

  const [copied, setCopied] = useState(false)
  const copyValueToClipboard = () => {
    navigator.clipboard.writeText(props?.value?.toString() || '')
    setCopied(true)
  }

  const empty = !props.value

  return (
    <Container>
      <LabelContainer hasError={hasError}>
        {label && (
          <Label>
            {label}
            {props.required && <Required> *</Required>}
          </Label>
        )}

        <InputContainer>
          <LeftIconContainer show={show} empty={empty}>
            <Tooltip
              text={show ? 'Hide' : 'Show'}
              direction="input-bottom-left"
            >
              <Eye scale={16} onClick={toggleShow} />
            </Tooltip>
          </LeftIconContainer>
          <RightIconContainer empty={empty}>
            <Tooltip
              text={copied ? 'Copied!' : 'Copy to clipboard'}
              direction="input-bottom-right"
            >
              <Copy scale={16} onClick={copyValueToClipboard} />
            </Tooltip>
          </RightIconContainer>

          <Input
            {...props}
            label={label}
            hasError={hasError}
            type={show ? 'text' : 'password'}
            ref={inputRef}
            onKeyDown={e =>
              props.onKeyDown ? props.onKeyDown(e) : e.stopPropagation()
            }
            onKeyUp={e =>
              props.onKeyUp ? props.onKeyUp(e) : e.stopPropagation()
            }
            customWidth={customWidth}
          />

          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </InputContainer>
      </LabelContainer>
    </Container>
  )
}

const Input = styled(StyledInput)`
  padding: 6px 36px;
  color: ${p => p.theme.GREY_3};
`

const IconContainer = styled.div<{ empty: boolean }>`
  position: absolute;
  cursor: pointer;

  path {
    fill: ${p => (p.empty ? p.theme.OFF_TEXT_BASE : p.theme.PRIMARY_50)};
  }

  :hover {
    path {
      fill: ${p => p.theme.PRIMARY_70};
    }
  }

  :active {
    path {
      fill: ${p => p.theme.PRIMARY_80};
    }
  }
`

const LeftIconContainer = styled(IconContainer)<{ show: boolean }>`
  top: 12px;
  left: 10px;

  path {
    fill: ${p =>
      p.show || p.empty ? p.theme.OFF_TEXT_BASE : p.theme.PRIMARY_50};
  }
`

const RightIconContainer = styled(IconContainer)`
  top: 12px;
  right: 10px;
`
