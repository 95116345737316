import React from 'react'
import styled from 'styled-components'
import { Spinner, standardIcons } from '@chordco/component-library'
import { ProvisioningStatus } from 'api/clients/provisioningClient'

const { CheckCircle, AlertWarning, AlertInfo } = standardIcons

interface Props {
  status: ProvisioningStatus
  primaryText: string
  secondaryText?: React.ReactNode
  info: string
}

const getIcon = (status: ProvisioningStatus) => {
  const scale = status === 'IN_PROGRESS' ? 20 : 16

  switch (status) {
    case 'IN_PROGRESS':
      return (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )
    case 'SUCCEEDED':
      return <CheckCircle scale={scale} />
    case 'FAILED':
      return <AlertWarning scale={scale} />
    case 'NOT_STARTED':
    default:
      return null
  }
}

export const ConfirmationStep: React.FC<Props> = ({
  status,
  primaryText,
  secondaryText,
  info,
}) => {
  return (
    <Container>
      <Primary status={status}>{primaryText}</Primary>
      {status !== 'NOT_STARTED' && status !== 'FAILED' && (
        <>
          <IconContainer status={status}>{getIcon(status)}</IconContainer>
          {!!secondaryText && (
            <Secondary status={status}>{secondaryText}</Secondary>
          )}
          <Info status={status}>
            <InfoIconContainer status={status}>
              <AlertInfo scale={14} />
            </InfoIconContainer>
            {info}
          </Info>
        </>
      )}
      {status === 'FAILED' && (
        <Info status={status}>
          <InfoIconContainer status={status}>
            <AlertInfo scale={14} />
          </InfoIconContainer>
          Oops, something went wrong on our end. Please email{' '}
          <Link href="mailto:support@chord.co" target="_blank">
            support@chord.co
          </Link>{' '}
          for help on next steps.
        </Info>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  text-align: left;
  margin-bottom: 32px;
`

const IconContainer = styled.div<{ status: ProvisioningStatus }>`
  position: absolute;
  top: ${p => (p.status === 'IN_PROGRESS' ? 12 : 2)}px;
  left: ${p => (p.status === 'IN_PROGRESS' ? -16 : -24)}px;

  path {
    fill: ${p => (p.status === 'FAILED' ? p.theme.RED_60_BASE : p.theme.WHITE)};
  }
`

const Primary = styled.div<{ status: ProvisioningStatus }>`
  margin-bottom: 12px;
  font-size: ${p => (p.status === 'IN_PROGRESS' ? 20 : 16)}px;
  color: ${p => {
    if (p.status === 'FAILED') return p.theme.RED_60_BASE
    if (p.status === 'NOT_STARTED') return p.theme.GREY_3
    return p.theme.WHITE
  }};
`

const Secondary = styled.div<{ status: ProvisioningStatus }>`
  margin-bottom: 8px;
  color: ${p => (p.status === 'FAILED' ? p.theme.RED_60_BASE : p.theme.WHITE)};
`

const Info = styled.div<{ status: ProvisioningStatus }>`
  font-style: italic;
  color: ${p =>
    p.status === 'FAILED' ? p.theme.RED_60_BASE : p.theme.PRIMARY_50};
`

const InfoIconContainer = styled.div<{ status: ProvisioningStatus }>`
  margin: 2px 3px 0 0;
  float: left;

  path {
    fill: ${p =>
      p.status === 'FAILED' ? p.theme.RED_60_BASE : p.theme.PRIMARY_50};
  }
`

const Link = styled.a`
  color: inherit;
`

const SpinnerContainer = styled.div`
  position: absolute;
`
