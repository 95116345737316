import { useEffect } from 'react'
import { useLookerData } from 'redux/state/looker'
import { useParentThemeKey } from 'themes/ThemeSwitcherProvider'
import { LookerReportType } from 'types'
import { useIsLoading } from 'redux/utils'

interface EmbedQuickstartUrls {
  [key: string]: string
}

interface UseEmbedQuickstartUrlResult {
  embedQuickstartUrls: EmbedQuickstartUrls
  isLoading: boolean
}

const useEmbedQuickstartUrl = (
  slug: string,
  type: LookerReportType
): UseEmbedQuickstartUrlResult => {
  const currentThemeKey = useParentThemeKey()

  const {
    state: { embedQuickstartUrls },
    getEmbedQuickstartUrl,
  } = useLookerData()

  const isLoading = useIsLoading('getEmbedQuickstartUrl')

  useEffect(() => {
    if (getEmbedQuickstartUrl) {
      getEmbedQuickstartUrl(
        slug,
        type,
        currentThemeKey === 'dark'
          ? 'ChordDark2Templates'
          : 'ChordLight2Templates' // uses a special theme for quickstarts that hides entire header including all controls
      )
    }
  }, [slug, type, currentThemeKey])

  return { embedQuickstartUrls, isLoading }
}

export default useEmbedQuickstartUrl
