import React from 'react'
import { standardIcons, AlertModal } from '@chordco/component-library'
import { ConnectedSource } from 'api/fivetran/interfaces'

const { Trash } = standardIcons

type Props = {
  source: ConnectedSource
  onDelete: () => void
  onClose: () => void
}

export const DeleteSourceModal: React.FC<Props> = ({
  source,
  onDelete,
  onClose,
}) => {
  return (
    <AlertModal
      onClose={onClose}
      icon={Trash}
      content={`Are you sure you want to disconnect ${source.metadata.name}?`}
      centered
      buttons={[
        {
          id: 1,
          text: 'Cancel',
          onClick: onClose,
          purpose: 'tertiary',
          disabled: false,
        },
        {
          id: 2,
          text: 'Disconnect',
          onClick: onDelete,
          purpose: 'destructive',
          isLoading: false,
          disabled: false,
        },
      ]}
    />
  )
}
