import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Col, TableRow } from 'components/common/table'
import { CensusConnector, CensusDestination } from 'api/census/interfaces'
import { useIsMobile } from 'hooks'
import { useCensus } from '../CensusContext'
import { HealthChip } from './HealthChip'
import { formatRelativeTime } from 'utils/dateTime'

type Props = {
  destination: CensusDestination
  onSelectDestination: (connector: CensusDestination) => void
}

export const DestinationRow: React.FC<Props> = ({
  destination,
  onSelectDestination,
}) => {
  const isMobile = useIsMobile()
  const { censusClient } = useCensus()

  const [connector, seConnector] = useState<CensusConnector | undefined>()

  const handleSelectDestination = () => {
    onSelectDestination(destination)
  }

  useEffect(() => {
    const fetchConnector = async () => {
      const response = await censusClient?.getConnector(destination.type)

      const { data, status } = response ?? {}

      if (status === 'success' && data) {
        seConnector(data)
      }
    }
    fetchConnector()
  }, [censusClient, destination.type])

  return (
    <Container onClick={handleSelectDestination}>
      <StyledCol flex={4}>
        {connector?.logoSmall && (
          <Logo src={connector?.logoSmall} alt={destination.name} />
        )}
        <Title small={isMobile}>{destination.name}</Title>
      </StyledCol>
      <StyledCol>{formatRelativeTime(destination.createdAt)}</StyledCol>
      <HealthState>
        <HealthChip
          healthy={destination.lastTestSucceeded}
          iconOnly={isMobile}
        />
      </HealthState>
      <StyledCol>
        <Title small={isMobile}>
          {destination?.lastTestedAt
            ? formatRelativeTime(destination.lastTestedAt)
            : 'Not available yet'}
        </Title>
      </StyledCol>
    </Container>
  )
}

const Container = styled(TableRow)`
  height: 56px;
  border-bottom: 1px solid ${p => p.theme.ComponentCardBorderElevation0};

  :first-child {
    border-top-right-radius: ${p => p.theme.CardMediumRadius};
    border-top-left-radius: ${p => p.theme.CardMediumRadius};
  }

  :last-child {
    border-bottom-right-radius: ${p => p.theme.CardMediumRadius};
    border-bottom-left-radius: ${p => p.theme.CardMediumRadius};
    border-bottom: none;
  }
`

const Title = styled.span<{ small: boolean }>`
  font-size: ${p => (p.small ? '12px' : '14px')};
  font-weight: 500;
  padding-left: 8px;
`

const StyledCol = styled(Col)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`

const HealthState = styled(Col)`
  max-width: 33%;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
  text-transform: capitalize;
`

const Logo = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 2px;
`
