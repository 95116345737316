import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const AlertWarning: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.01951 1.13633C6.16016 0.995674 6.35093 0.916656 6.54984 0.916656H13.4498C13.6487 0.916656 13.8395 0.995674 13.9802 1.13633L18.8635 6.01966C19.0042 6.16031 19.0832 6.35108 19.0832 6.54999V13.45C19.0832 13.6489 19.0042 13.8397 18.8635 13.9803L13.9802 18.8637C13.8395 19.0043 13.6487 19.0833 13.4498 19.0833H6.54984C6.35092 19.0833 6.16016 19.0043 6.01951 18.8637L1.13617 13.9803C0.995522 13.8397 0.916504 13.6489 0.916504 13.45V6.54999C0.916504 6.35108 0.995522 6.16031 1.13617 6.01966L6.01951 1.13633ZM6.8605 2.41666L2.4165 6.86065V13.1393L6.8605 17.5833H13.1392L17.5832 13.1393V6.86065L13.1392 2.41666H6.8605ZM10 5.91666C10.4142 5.91666 10.75 6.25244 10.75 6.66666V9.99999C10.75 10.4142 10.4142 10.75 10 10.75C9.58579 10.75 9.25 10.4142 9.25 9.99999V6.66666C9.25 6.25244 9.58579 5.91666 10 5.91666ZM10 12.5833C9.58579 12.5833 9.25 12.9191 9.25 13.3333C9.25 13.7476 9.58579 14.0833 10 14.0833H10.0083C10.4225 14.0833 10.7583 13.7476 10.7583 13.3333C10.7583 12.9191 10.4225 12.5833 10.0083 12.5833H10Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
