import { MetricType } from './store'
import { DateDropdownPeriod } from '@chordco/component-library'

export type AnalyticsDashboardSlug =
  | 'business_overview'
  | 'customer'
  | 'ecommerce'
  | 'marketing'
  | 'supply_chain'
  | 'products'
  | 'subscriptions'

export type ReportCreator = {
  firstName: string
  lastName: string
}

export type AvailableReport = {
  label: string
  slug: string
  metricType: string
  url: string
  description: string
  createdBy: ReportCreator
  createdAt: string
  updatedAt: string
}

export type AudienceCategory = {
  name: string
  slug: string
}

export type AvailableAudience = {
  name: string
  slug: string
  url: string
  description: string
  enabled: boolean
  plan: string
  categories: AudienceCategory[]
}

export type AvailableUserReport = AvailableReport & {
  id: string
  folderId: string
  folderName?: string
}

export type GetAvailableReportsResponse = {
  dashboards: AvailableUserReport[]
  explores: AvailableUserReport[]
  looks: AvailableUserReport[]
}

export type GetAvailableUserReportsResponse = {
  userDashboards: AvailableUserReport[]
  userLooks: AvailableUserReport[]
}

export type GetAvailableAudiencesResponse = {
  audiences: AvailableAudience[]
}

export type AvailableUserAudience = AvailableAudience & {
  id: string
  label: string
  folderId: string
}

export type GetAvailableUserAudiencesResponse = {
  userAudiences: AvailableUserAudience[]
}

export type GetTokenResponse = {
  accessToken: string
}

export type GetEmbedUrlResponse = {
  url: string
}

export type PublishReportResponse = {
  status?: string
  error?: string
  metric?: AvailableReport
}

export type ImportReportResponse = {
  status?: string
  error?: string
  metric?: AvailableReport
}

export type GetSharedDashboardsResponse = {
  status?: string
  error?: string
  dashboards?: AvailableUserReport[]
}

export type GetSharedLooksResponse = {
  status?: string
  error?: string
  looks?: AvailableUserReport[]
}

export type GetSharedQuickstartResponse = {
  dashboards: AvailableUserReport[]
  looks: AvailableUserReport[]
}

export type LookerReportType = 'dashboard' | 'explore' | 'audience' | 'look'

export type GetTilesRequest = {
  tenantId: number
  storeId: number
  timePeriod: DateDropdownPeriod
}

export type GetTilesResponse = {
  id: number
  label: string
  value: number
  change: number
  previous: number
  type: MetricType
}

export type LookerState = {
  token?: string
  looks: { [id: number]: any }
  tiles: GetTilesResponse[]
  metrics: Record<number, GetTilesResponse>
  availableReports: GetAvailableReportsResponse
  availableUserReports: GetAvailableUserReportsResponse
  availableAudiences: GetAvailableAudiencesResponse
  availableUserAudiences: GetAvailableUserAudiencesResponse
  availableQuickstarts: GetSharedQuickstartResponse
  embedUrls: { [slug: string]: string }
  embedUserUrls: { [slug: string]: string }
  embedAudienceUrls: { [slug: string]: string }
  embedUserAudienceUrls: { [slug: string]: string }
  embedQuickstartUrls: { [slug: string]: string }
  embedUser?: LookerEmbedUser
}

export enum MetricsLookupIds {
  LifetimeNetRevenue = 1,
  ActiveSubscriptions = 2,
  Products = 3,
}

export type LookerEmbedUser = {
  id: string
  firstName: string
  lastName: string
  email: string
  personalFolderId: string
}
