import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const CircleCheck: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.603 5.58745C10.8633 5.3271 11.2854 5.3271 11.5458 5.58745C11.8061 5.8478 11.8061 6.26991 11.5458 6.53026L8.03592 10.0393C7.38581 10.6893 6.3322 10.6901 5.68105 10.0412L4.47124 8.8355C4.21089 8.57515 4.21089 8.15304 4.47124 7.89269C4.73159 7.63234 5.1537 7.63234 5.41405 7.89269L6.38449 8.86313C6.64484 9.12348 7.06695 9.12348 7.3273 8.86313L10.603 5.58745Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99984 15.3332C12.0499 15.3332 15.3332 12.0499 15.3332 7.99984C15.3332 3.94975 12.0499 0.666504 7.99984 0.666504C3.94975 0.666504 0.666504 3.94975 0.666504 7.99984C0.666504 12.0499 3.94975 15.3332 7.99984 15.3332ZM7.99984 13.9953C4.68864 13.9953 2.00439 11.311 2.00439 7.99984C2.00439 4.68864 4.68864 2.00439 7.99984 2.00439C11.311 2.00439 13.9953 4.68864 13.9953 7.99984C13.9953 11.311 11.311 13.9953 7.99984 13.9953Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
