import React, { useState } from 'react'
import { Chip } from '../controls'
import styled from 'styled-components'
import { Hint } from './Input'

interface Props {
  items: string[]
  updateItems: (items: string[]) => void
  hint?: string
}

export const MultiEntryInput: React.FC<Props> = ({
  items,
  updateItems,
  hint,
  ...props
}) => {
  const [entry, setEntry] = useState('')

  const addItem = item => {
    updateItems([...items, item])
    setEntry('')
  }

  const onChange = e => {
    const { value } = e.target

    if (value.length > 1 && value.substring(value.length - 1) === ',') {
      addItem(value.substring(0, value.length - 1))
    } else {
      setEntry(value)
    }
  }

  const onKeyDown = e => {
    e.stopPropagation()

    if (e.key === 'Enter') {
      addItem(entry)
    }
  }

  return (
    <Container hasHint={!!hint}>
      <Chips>
        {items.map((item, index) => {
          return (
            <Chip
              key={item}
              text={item}
              onClose={() => {
                const updatedItems = [...items]
                updatedItems.splice(index, 1)
                updateItems(updatedItems)
              }}
            />
          )
        })}
        <Input
          {...props}
          value={entry}
          onChange={onChange}
          width={items.length === 0 ? '100%' : 'unset'}
          onKeyDown={onKeyDown}
        />
        {hint && <StyledHint>{hint}</StyledHint>}
      </Chips>
    </Container>
  )
}

const Container = styled.div<{ hasHint: boolean }>`
  position: relative;
  display: block;
  box-sizing: border-box;
  min-height: 40px;
  background-color: ${p => p.theme.BLACK};
  color: ${p => p.theme.WHITE};
  border: solid 1px ${p => p.theme.GREY_4};
  border-radius: 8px;
  padding: 4px 6px;
  transition: 100ms;

  :hover {
    border-color: ${p => p.theme.WHITE};
  }

  :focus-within {
    border: solid 1px ${p => p.theme.PRIMARY_50};
  }
`

const Chips = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  button {
    margin-right: 8px;
  }
`

const Input = styled.input`
  float: left;
  border: none;
  box-sizing: border-box;
  height: 30px;
  background: inherit;
  border-radius: 8px;
  padding: 0;
  width: 24px;
  flex: 1 0 24px;
  color: ${p => p.theme.WHITE};

  ::placeholder {
    color: ${({ theme }) => theme.GREY_3};
  }

  :focus {
    outline: none;
  }
`

const StyledHint = styled(Hint)`
  bottom: -16px;
`
