import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const AtSymbol: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.75C5.99594 2.75 2.75 5.99594 2.75 10C2.75 14.0041 5.99594 17.25 10 17.25C11.6942 17.25 13.2508 16.6698 14.4849 15.6968C14.8102 15.4403 15.2817 15.4961 15.5382 15.8213C15.7947 16.1466 15.7389 16.6182 15.4136 16.8747C13.9244 18.0489 12.043 18.75 10 18.75C5.16751 18.75 1.25 14.8325 1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10C18.75 11.6766 17.3909 13.0357 15.7143 13.0357C14.8817 13.0357 14.1275 12.7006 13.579 12.1578C12.8473 13.3689 11.5182 14.1786 10 14.1786C7.69224 14.1786 5.82143 12.3078 5.82143 10C5.82143 7.69224 7.69224 5.82143 10 5.82143C12.3078 5.82143 14.1786 7.69224 14.1786 10C14.1786 10.8482 14.8661 11.5357 15.7143 11.5357C16.5624 11.5357 17.25 10.8482 17.25 10C17.25 5.99594 14.0041 2.75 10 2.75ZM12.6786 10C12.6786 8.52067 11.4793 7.32143 10 7.32143C8.52067 7.32143 7.32143 8.52067 7.32143 10C7.32143 11.4793 8.52067 12.6786 10 12.6786C11.4793 12.6786 12.6786 11.4793 12.6786 10Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
