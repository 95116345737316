import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import { standardIcons } from '../icons'
import { useTheme } from '@chordco/component-library'
import { ErrorMessage } from '../inputs'
import { useIsMobile } from '../../hooks'

interface Props {
  value?: string
  selected?: boolean
  errorMessage?: string
  placeholder?: string
  customWidth?: string
}

export const DefaultControl: React.FC<Props> = ({
  value,
  selected,
  errorMessage,
  placeholder,
  customWidth,
  ...props
}) => {
  const theme = useTheme()

  const ref = useRef() as any
  const onKeyDown = (e: any) => {
    if (
      e.key === 'Enter' ||
      e.key === ' ' ||
      (!selected && e.key === 'ArrowDown')
    ) {
      ref.current.click()
    }
  }

  const { ChevronDown } = standardIcons
  const isMobile = useIsMobile()

  return (
    <>
      <Container
        tabIndex={0}
        ref={ref}
        onKeyDown={onKeyDown}
        hasError={!!errorMessage}
        hasPlaceholder={!value && !!placeholder}
        isMobile={isMobile}
        customWidth={customWidth}
        {...props}
      >
        <SelectionContainer>{value || placeholder}</SelectionContainer>
        <RightIconContainer>
          <ChevronDown fill={theme.ContentAccentPrimary} scale={16} />
        </RightIconContainer>
      </Container>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  )
}

const Container = styled.div<{
  hasError: boolean
  hasPlaceholder: boolean
  isMobile?: boolean
  customWidth?: string
}>`
  position: relative;
  box-sizing: border-box;
  width: ${p => p.customWidth || (p.isMobile ? 'calc(100vw - 32px)' : '368px')};
  height: 40px;
  background-color: ${p => p.theme.ComponentFieldBackgroundDefault};
  border: solid 1px ${p => p.theme.ComponentFieldBorderDefault};
  color: ${p => p.theme.ContentPrimary};
  border-radius: 8px;
  padding: 10px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: 100ms;
  outline: none;

  :active,
  :focus {
    border: solid 1px
      ${p =>
        p.hasError
          ? p.theme.ComponentFieldBorderError
          : p.theme.ComponentFieldBorderActive};
    background-color: ${p => p.theme.ComponentFieldBackgroundActive};
    outline: none;
  }

  ${({ hasError, theme }) =>
    hasError &&
    css`
      border: solid 1px ${theme.ComponentFieldBorderError};
    `}

  :disabled {
    border-color: ${p => p.theme.BorderDisabled};
    color: ${p => p.theme.ContentDisabled};
    box-shadow: none;
  }
`

const SelectionContainer = styled.div`
  max-width: calc(100% - 15px);
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
`

const RightIconContainer = styled.span`
  position: absolute;
  top: 12px;
  right: 10px;
`
