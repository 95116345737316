import React from 'react'
import censusLogo from 'assets/CensusLogo.png'
import { useForm } from 'hooks'
import { FormSecureInput } from '../../../common'
import { ValSchemaGeneric } from 'validationSchemas'
import { Fields } from './common'
import { CensusSettings, Integration } from 'types'
import { IntegrationForm } from './IntegrationForm'
import { useIntegrationForm } from 'redux/hooks'

type Props = {
  onClose: () => void
  integration?: Integration<CensusSettings>
}

const CENSUS_LOGIN_LINK = 'https://login.getcensus.com'

const getSchema = (
  settings?: CensusSettings
): ValSchemaGeneric<['apiKey']> => ({
  apiKey: {
    label: 'API Key',
    type: 'string',
    initialValue: settings?.apiKey,
  },
})

export const CensusForm: React.FC<Props> = ({ onClose, integration }) => {
  const { submit, isLoading, isEdit } = useIntegrationForm(
    'census',
    integration,
    onClose
  )

  const schema = getSchema(integration?.settings)
  const { fields, onSubmit } = useForm(schema, submit)

  return (
    <IntegrationForm
      title="Data Activations Settings"
      name="Census"
      logo={censusLogo}
      loginLink={CENSUS_LOGIN_LINK}
      isLoading={isLoading}
      isEdit={isEdit}
      submit={onSubmit}
      onClose={onClose}
    >
      <Fields>
        <FormSecureInput field={fields.apiKey} customWidth="100%" />
      </Fields>
    </IntegrationForm>
  )
}
