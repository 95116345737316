import React, { FormEventHandler, useState } from 'react'
import {
  Button,
  Sheet,
  standardIcons,
  AlertModal,
} from '@chordco/component-library'
import styled, { css } from 'styled-components'
import SimpleBar from 'simplebar-react'

interface Props {
  children?: React.ReactNode
  onSubmit: FormEventHandler<HTMLFormElement>
  onClose: Function
  title?: string
  isEdit?: boolean
  customTitle?: string
  isLoading?: boolean
  isLarge?: boolean
  deleteButtonAtBottom?: boolean
  onDelete?: () => void
  showDeleteAlert?: boolean
  submitText?: string
  submitDisabled?: boolean
  useScrollBar?: boolean
  customWidth?: string
}

const { Trash } = standardIcons

const Container: React.FC<{
  children?: React.ReactNode
  useScrollBar: boolean
}> = ({ useScrollBar, children }) =>
  useScrollBar ? (
    <SimpleBar
      style={{
        height: 'calc(100vh - 172px)',
        transition: '300ms',
        overflowX: 'hidden',
      }}
    >
      <Content>{children}</Content>
    </SimpleBar>
  ) : (
    <Content>{children}</Content>
  )

export const FormSheet: React.FC<Props> = ({
  onSubmit,
  onClose,
  title,
  customTitle,
  isLoading,
  isEdit = false,
  onDelete,
  children,
  submitText,
  isLarge = false,
  submitDisabled = false,
  useScrollBar = false,
  deleteButtonAtBottom = false,
  showDeleteAlert = false,
  customWidth,
}) => {
  const [showDeleteAlertModal, setShowDeleteAlertModal] = useState(false)

  const showDeleteButton = onDelete && isEdit

  return (
    <>
      <Sheet
        title={customTitle || `${isEdit ? 'Edit' : 'New'} ${title}`}
        onClose={onClose}
        isLoading={isLoading}
        isLarge={isLarge}
        customWidth={customWidth}
      >
        <Form
          onSubmit={e => {
            e.preventDefault()
            onSubmit(e)
          }}
          isLarge={isLarge}
          noValidate
        >
          <Container useScrollBar={useScrollBar}>
            {children}

            {showDeleteButton && (
              <DeleteButton
                deleteButtonAtBottom={deleteButtonAtBottom}
                icon={Trash}
                purpose="destructive"
                onClick={() =>
                  showDeleteAlert ? setShowDeleteAlertModal(true) : onDelete()
                }
              >
                Delete
              </DeleteButton>
            )}
          </Container>

          <Bottom>
            <Button
              type="submit"
              isLoading={isLoading}
              disabled={submitDisabled}
            >
              {submitText || (isEdit ? 'Save Changes' : 'Create')}
            </Button>
          </Bottom>
        </Form>
      </Sheet>
      {showDeleteAlert && showDeleteAlertModal && showDeleteButton && (
        <AlertModal
          onClose={() => setShowDeleteAlertModal(false)}
          icon={Trash}
          content={`Are you sure you want to delete ${
            title ? `the ${title}` : 'it'
          }?`}
          centered
          buttons={[
            {
              id: 1,
              text: 'No',
              onClick: () => setShowDeleteAlertModal(false),
              purpose: 'secondary',
              disabled: isLoading,
            },
            {
              id: 2,
              text: 'Yes, delete it',
              onClick: onDelete,
              purpose: 'primary',
              isLoading: isLoading,
              disabled: isLoading,
            },
          ]}
        />
      )}
    </>
  )
}

export const Form = styled.form<{
  isLarge?: boolean
  onSubmit?: FormEventHandler<HTMLFormElement>
}>`
  box-sizing: border-box;
  position: relative;
  min-height: calc(100vh - 76px);
  padding: ${p => `0px ${p.isLarge ? 24 : 16}px 96px ${p.isLarge ? 24 : 16}px`};
  overflow: auto;

  & > div:not(:first-child) {
    margin-top: 20px;
  }
`

const DeleteButton = styled(Button)<{ deleteButtonAtBottom?: boolean }>`
  margin-top: 36px;

  ${p =>
    p.deleteButtonAtBottom &&
    css`
      position: absolute;
      bottom: 90px;
    `}
`

const Content = styled.div`
  margin: 8px 0;
`

export const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${p => p.theme.ComponentWorkspaceBgPage};
  border-top: 1px solid ${p => p.theme.BorderHairline};
  padding: 16px;

  button {
    width: 100%;
  }
`
