import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const IconSvg: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10ZM10 5.91663C10.4142 5.91663 10.75 6.25241 10.75 6.66663V9.25H13.3337C13.7479 9.25 14.0837 9.58579 14.0837 10C14.0837 10.4142 13.7479 10.75 13.3337 10.75H10.75V13.3333C10.75 13.7475 10.4142 14.0833 10 14.0833C9.58579 14.0833 9.25 13.7475 9.25 13.3333V10.75H6.66699C6.25278 10.75 5.91699 10.4142 5.91699 10C5.91699 9.58579 6.25278 9.25 6.66699 9.25H9.25V6.66663C9.25 6.25241 9.58579 5.91663 10 5.91663Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
