import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const PieChart: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25 1.78363C5.04513 2.16255 1.75 5.69647 1.75 10C1.75 14.5564 5.44365 18.25 10 18.25C13.1503 18.25 15.8896 16.4841 17.2795 13.8859L10.3196 10.9489C9.67138 10.6754 9.25 10.0402 9.25 9.3366V1.78363ZM10.75 1.78363V9.3366C10.75 9.43711 10.8102 9.52785 10.9028 9.56693L17.8633 12.5042C18.1144 11.7151 18.25 10.8739 18.25 10C18.25 5.69647 14.9549 2.16255 10.75 1.78363ZM0.25 10C0.25 4.61522 4.61522 0.25 10 0.25C15.3848 0.25 19.75 4.61522 19.75 10C19.75 11.3435 19.4778 12.6253 18.985 13.7919C17.5063 17.2917 14.0411 19.75 10 19.75C4.61522 19.75 0.25 15.3848 0.25 10Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
