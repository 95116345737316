import SplitIO from '@splitsoftware/splitio/types/splitio'

import { Treatment } from '../treatment'
import { ActorData, Adapter, EventData } from '../../../types'

export class SplitAdapter implements Adapter {
  private readonly factory: SplitIO.ISDK

  constructor(factory: SplitIO.ISDK) {
    this.factory = factory
  }

  async treatment(
    experimentName: string,
    { actorType, actorId, actorAttributes }: ActorData
  ) {
    const client = await this.buildClient(actorType, actorId.toString())

    const treatmentWithConfig = client.getTreatmentWithConfig(
      experimentName,
      actorAttributes
    )

    return new Treatment(
      treatmentWithConfig.treatment,
      this.parseConfig(treatmentWithConfig.config)
    )
  }

  async track(
    eventName: string,
    { eventValue, eventProperties }: EventData,
    { actorType, actorId }: ActorData
  ) {
    const client = await this.buildClient(actorType, actorId.toString())

    client.track(eventName, eventValue, eventProperties)
  }

  private async buildClient(
    trafficType: string,
    key: string
  ): Promise<SplitIO.IClient> {
    const client = this.factory.client(key, trafficType)
    await client.ready()

    return client
  }

  private parseConfig(config: string | null): Object {
    if (config === null) {
      return {}
    }

    return JSON.parse(config)
  }
}
