import React from 'react'
import styled from 'styled-components'
import { standardIcons } from '../icons'
import { Avatar } from '../information'
import { useTheme } from '@chordco/component-library'

interface Props {
  imgUrl?: string
  label: string
  subLabel?: string
  onRemove?: () => void
}

const { Exit } = standardIcons

export const TagItem: React.FC<Props> = ({
  imgUrl,
  label,
  subLabel,
  onRemove,
}) => {
  const theme = useTheme()

  return (
    <FlexContainer>
      <Container>
        {imgUrl && <Image src={imgUrl} size={24} />}
        <Name>{label}</Name>
        {subLabel && <SubLabel>{subLabel}</SubLabel>}
      </Container>
      {onRemove && (
        <RemoveButton onClick={onRemove}>
          <Exit fill={theme.PRIMARY_50} scale={15} />
        </RemoveButton>
      )}
    </FlexContainer>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  height: 40px;
  padding: 8px 12px;
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  width: fit-content;
  box-sizing: border-box;
  background-color: ${p => p.theme.BLACK};
  border: solid 1px ${p => p.theme.OFF_BLACK};
  border-radius: 8px;

  :not(:last-child) {
    margin-right: 8px;
  }
`

const Image = styled(Avatar)`
  margin: -1px 12px 0 0;
`

const Name = styled.div`
  font-weight: 600;
  margin: 2px 12px 0 0;
`

const SubLabel = styled.div`
  color: ${p => p.theme.GREY_3};
  margin-top: 2px;
`

const RemoveButton = styled.div`
  cursor: pointer;
  padding: 4px;
  margin-right: 12px;
`
