import React, { useEffect, ComponentProps } from 'react'
import styled, { css } from 'styled-components'
import { standardIcons } from '../icons'

export interface InputProps extends ComponentProps<'input'> {
  label?: string
  subLabel?: string
  hasError?: boolean
  errorMessage?: string
  leftIcon?: any
  rightIcon?: any
  prefix?: string
  suffix?: string
  hint?: string
  selected?: boolean
  inputRef?: any
  focus?: boolean
  customWidth?: string
  required?: boolean
  wrapError?: boolean
}

export const Input: React.FC<InputProps> = props => {
  const {
    label,
    subLabel,
    errorMessage,
    hint,
    type = 'text',
    inputRef,
    focus = false,
    customWidth = '280px',
    required,
    prefix,
    suffix,
    leftIcon: LeftIcon,
    rightIcon: RightIcon,
    wrapError,
  } = props

  const hasError = !!errorMessage || props.hasError

  useEffect(() => {
    focus && inputRef?.current?.focus()
  }, [inputRef])

  const { AlertTriangle } = standardIcons

  return (
    <Container customWidth={customWidth}>
      <LabelContainer hasError={hasError}>
        {label && (
          <Label>
            {label}
            {subLabel && <SubLabel> ({subLabel})</SubLabel>}
            {required && <Required> *</Required>}
          </Label>
        )}

        <InputContainer>
          {prefix && <Prefix>{prefix}</Prefix>}
          {suffix && <Suffix hasError={hasError}>{suffix}</Suffix>}
          {LeftIcon && (
            <LeftIconContainer>
              <LeftIcon scale={20} />
            </LeftIconContainer>
          )}
          {RightIcon && (
            <RightIconContainer>
              <RightIcon scale={20} />
            </RightIconContainer>
          )}
          {hasError && (
            <ErrorIconContainer>
              <AlertTriangle />
            </ErrorIconContainer>
          )}

          <StyledInput
            {...props}
            hasError={hasError}
            type={type}
            ref={inputRef}
            onKeyDown={e =>
              props.onKeyDown ? props.onKeyDown(e) : e.stopPropagation()
            }
            onKeyUp={e =>
              props.onKeyUp ? props.onKeyUp(e) : e.stopPropagation()
            }
            customWidth={customWidth}
          />
        </InputContainer>

        {hint && !errorMessage && <Hint>{hint}</Hint>}
        {errorMessage && (
          <ErrorMessage wrap={wrapError}>{errorMessage}</ErrorMessage>
        )}
      </LabelContainer>
    </Container>
  )
}

export const Container = styled.div<{ customWidth?: string }>`
  position: relative;
  border-radius: 8px;
  color: ${p => p.theme.ContentSecondary};
  text-align: left;
  width: ${p => p.customWidth ?? 'auto'};
`

export const InputContainer = styled.div`
  position: relative;
`

export const LabelContainer = styled.label<{ hasError?: boolean }>`
  position: relative;
  z-index: ${({ hasError }) => (hasError ? '1' : '0')};
`

export const Label = styled.div`
  margin-bottom: 4px;
  color: ${p => p.theme.ContentSecondary};
  white-space: pre;
`

export const Required = styled.span`
  color: ${p => p.theme.ContentAccentError};
`

export const SubLabel = styled.span`
  color: ${p => p.theme.ContentTertiary};
`

export const StyledInput = styled.input<InputProps>`
  box-sizing: border-box;
  width: ${p => p.customWidth || 'auto'};
  height: 40px;
  background-color: ${p => p.theme.ComponentFieldBackgroundDefault};
  border: solid 1px ${p => p.theme.ComponentFieldBorderDefault};
  border-radius: 8px;
  font-size: 14px;
  padding: 6px 12px;
  margin-top: ${p => (!p.label ? 4 : 0)}px;
  color: ${p => p.theme.ContentPrimary};
  transition: 100ms;

  ::placeholder {
    color: ${p => p.theme.ContentTertiary};
  }

  :hover {
    border-color: ${p => p.theme.ComponentFieldBorderHover};
  }

  :active,
  :focus {
    border: solid 1px
      ${p =>
        p.hasError
          ? p.theme.ComponentFieldBorderError
          : p.theme.ComponentFieldBorderActive};
    background-color: ${p => p.theme.ComponentFieldBackgroundActive};
    outline: none;
  }

  :disabled {
    border-color: ${p => p.theme.BorderDisabled};
    color: ${p => p.theme.ContentDisabled};
    box-shadow: none;
  }

  ${({ hasError, theme }) =>
    hasError &&
    css`
      border: solid 1px ${theme.ComponentFieldBorderError};
    `}

  ${({ prefix }) =>
    prefix &&
    css`
      padding-left: 28px;
    `}

  ${({ suffix }) =>
    suffix &&
    css`
      padding-right: 28px;
    `}

  ${({ leftIcon }) =>
    leftIcon &&
    css`
      padding-left: 36px;
    `}

  ${({ rightIcon, hasError, suffix }) =>
    (rightIcon || hasError) &&
    css`
      padding-right: ${suffix ? '50px' : '36px'};
    `}

    /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type='number'] {
    -moz-appearance: textfield;
  }
`

export const Prefix = styled.span`
  position: absolute;
  bottom: 11px;
  left: 12px;
`

export const Suffix = styled.span<{ hasError?: boolean }>`
  position: absolute;
  bottom: 11px;
  right: ${({ hasError }) => (hasError ? '34px' : '12px')};
`

export const LeftIconContainer = styled.span`
  position: absolute;
  bottom: 6px;
  left: 10px;
  path {
    fill: ${p => p.theme.ContentPrimary};
  }
`

export const RightIconContainer = styled.span`
  position: absolute;
  bottom: 6px;
  right: 10px;
  path {
    fill: ${p => p.theme.ContentPrimary};
  }
`

export const ErrorIconContainer = styled(RightIconContainer)`
  path {
    color: ${p => p.theme.ContentAccentError};
  }
`

export const Hint = styled.div<{ wrap?: boolean }>`
  width: inherit;
  margin-top: 4px;
  white-space: ${p => (p.wrap ? 'initial' : 'nowrap')};
  text-overflow: ellipsis;
  font-size: 12px;
  color: ${p => p.theme.ContentTertiary};
`

export const ErrorMessage = styled(Hint)`
  color: ${p => p.theme.ContentAccentError};
`
