import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const LogOut: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 17.5C8.25 17.9142 7.91421 18.25 7.5 18.25H4.16667C3.52573 18.25 2.91104 17.9954 2.45783 17.5422C2.00461 17.089 1.75 16.4743 1.75 15.8333V4.16667C1.75 3.52573 2.00461 2.91104 2.45783 2.45783C2.91104 2.00461 3.52573 1.75 4.16667 1.75L7.5 1.75C7.91422 1.75 8.25 2.08579 8.25 2.5C8.25 2.91421 7.91422 3.25 7.5 3.25L4.16667 3.25C3.92355 3.25 3.6904 3.34658 3.51849 3.51849C3.34658 3.69039 3.25 3.92355 3.25 4.16667L3.25 15.8333C3.25 16.0765 3.34658 16.3096 3.51849 16.4815C3.69039 16.6534 3.92355 16.75 4.16667 16.75L7.5 16.75C7.91421 16.75 8.25 17.0858 8.25 17.5ZM12.1967 14.697C11.9039 14.9899 11.429 14.9899 11.1361 14.697L6.96942 10.5304C6.82877 10.3897 6.74975 10.1989 6.74975 10C6.74975 9.80111 6.82877 9.61035 6.96942 9.46969L11.1361 5.30303C11.429 5.01013 11.9039 5.01013 12.1967 5.30303C12.4896 5.59592 12.4896 6.0708 12.1967 6.36369L9.31043 9.25001L17.5 9.25001C17.9142 9.25001 18.25 9.58579 18.25 10C18.25 10.4142 17.9142 10.75 17.5 10.75L9.31039 10.75L12.1967 13.6364C12.4896 13.9293 12.4896 14.4041 12.1967 14.697Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
