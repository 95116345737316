import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const ChevronUp: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5303 13.5303C15.2374 13.8232 14.7626 13.8232 14.4697 13.5303L10 9.06066L5.53033 13.5303C5.23744 13.8232 4.76256 13.8232 4.46967 13.5303C4.17678 13.2374 4.17678 12.7626 4.46967 12.4697L9.46967 7.46967C9.76256 7.17678 10.2374 7.17678 10.5303 7.46967L15.5303 12.4697C15.8232 12.7626 15.8232 13.2374 15.5303 13.5303Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
