import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const ArrowRightCircle: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.25 10C18.25 5.44365 14.5563 1.75 10 1.75C5.44365 1.75 1.75 5.44365 1.75 10C1.75 14.5563 5.44365 18.25 10 18.25C14.5563 18.25 18.25 14.5563 18.25 10ZM10 0.25C15.3848 0.25 19.75 4.61522 19.75 10C19.75 15.3848 15.3848 19.75 10 19.75C4.61522 19.75 0.249999 15.3848 0.25 10C0.25 4.61522 4.61522 0.249999 10 0.25Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5303 9.46967C14.8232 9.76256 14.8232 10.2374 14.5303 10.5303L10.5303 14.5303C10.2374 14.8232 9.76256 14.8232 9.46967 14.5303C9.17678 14.2374 9.17678 13.7626 9.46967 13.4697L12.9393 10L9.46967 6.53033C9.17678 6.23744 9.17678 5.76256 9.46967 5.46967C9.76256 5.17678 10.2374 5.17678 10.5303 5.46967L14.5303 9.46967Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.75 10C14.75 10.4142 14.4142 10.75 14 10.75L6 10.75C5.58579 10.75 5.25 10.4142 5.25 10C5.25 9.58579 5.58579 9.25 6 9.25L14 9.25C14.4142 9.25 14.75 9.58579 14.75 10Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
