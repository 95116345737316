import React from 'react'
import {
  standardIcons,
  AlertModal,
  PercentBar,
} from '@chordco/component-library'

const { CheckCircle } = standardIcons

type Props = {
  progress: number
  onRunIntegrityCheck: () => void
  onClose: () => void
}

export const IntegrityCheckModal: React.FC<Props> = ({
  progress,
  onRunIntegrityCheck,
  onClose,
}) => {
  return (
    <AlertModal
      onClose={onClose}
      icon={CheckCircle}
      title="Integrity Check"
      content="Scan rows for any inconsistencies."
      subContent="Note: this may take a while if you have a large number of rows."
      centered
      buttons={[
        {
          id: 1,
          text: 'Cancel',
          onClick: onClose,
          purpose: 'tertiary',
          disabled: false,
        },
        {
          id: 2,
          text: 'Start Integrity Check',
          onClick: onRunIntegrityCheck,
          purpose: 'primary',
          isLoading: progress > 0,
          disabled: progress > 0,
        },
      ]}
    >
      <PercentBar percent={progress} width={280} />
    </AlertModal>
  )
}
