import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const File: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.29132 1.62448C3.74453 1.17127 4.35922 0.916656 5.00016 0.916656H10.8335C11.0324 0.916656 11.2232 0.995674 11.3638 1.13633L17.1972 6.96966C17.3378 7.11031 17.4168 7.30108 17.4168 7.49999V16.6667C17.4168 17.3076 17.1622 17.9223 16.709 18.3755C16.2558 18.8287 15.6411 19.0833 15.0002 19.0833H5.00016C4.35922 19.0833 3.74453 18.8287 3.29132 18.3755C2.83811 17.9223 2.5835 17.3076 2.5835 16.6667V3.33332C2.5835 2.69238 2.83811 2.07769 3.29132 1.62448ZM5.00016 2.41666C4.75705 2.41666 4.52389 2.51323 4.35198 2.68514C4.18007 2.85705 4.0835 3.09021 4.0835 3.33332V16.6667C4.0835 16.9098 4.18007 17.1429 4.35198 17.3148C4.52389 17.4867 4.75705 17.5833 5.00016 17.5833H15.0002C15.2433 17.5833 15.4764 17.4867 15.6483 17.3148C15.8203 17.1429 15.9168 16.9098 15.9168 16.6667V8.24999H10.8335C10.4193 8.24999 10.0835 7.9142 10.0835 7.49999V2.41666H5.00016ZM11.5835 3.47732L14.8562 6.74999H11.5835V3.47732Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
