import React from 'react'
import netlifyLogo from 'assets/NetlifyLogo.png'
import { Column, Link, StatusLine, Value } from 'components/systemHealth/common'
import { formatDateTime } from '@chordco/component-library'
import { Section } from 'components/systemHealth/Section'
import { getHealthStatus } from 'components/systemHealth/utils'
import { BuildStatus, Health } from 'types/systemHealth'

interface Props {
  buildStatus?: BuildStatus
  health: Health
  isLoading: boolean
  hasFailed: boolean
  isSmall: boolean
  retryFunction: () => void
}

export const FrontendWebHost: React.FC<Props> = ({
  buildStatus,
  health,
  isLoading,
  hasFailed,
  retryFunction,
  isSmall,
}) => {
  const deployLogURL = `https://app.netlify.com/sites/${buildStatus?.name}/deploys/${buildStatus?.id}`
  const deployHealthStatus = getHealthStatus(buildStatus?.state)

  const hasBuildStatus = buildStatus && Object.keys(buildStatus).length

  return (
    <Section
      title="Hosting"
      subTitle="Netlify"
      logo={netlifyLogo}
      operatingCorrectly={health.buildStatus}
      isSmall={isSmall}
      isLoading={isLoading}
      hasFailed={hasFailed}
      retryFunction={retryFunction}
    >
      {hasBuildStatus && (
        <>
          <Column isSmall={isSmall}>
            <StatusLine
              status={deployHealthStatus}
              label="Build Status"
              customStatuses={{
                enabled: 'Successful',
                disabled: 'Failed',
                inProgress: 'Building',
              }}
            />
          </Column>

          {deployHealthStatus === 'disabled' ? (
            <>
              <Column isSmall={isSmall}>
                <Link href="mailto:support@chord.co" target="_blank">
                  Contact Chord Support
                </Link>
              </Column>
              <Column isSmall={isSmall} />
            </>
          ) : (
            <>
              {buildStatus?.publishedAt && (
                <Column isSmall={isSmall}>
                  Build Time:{' '}
                  <Value>{formatDateTime(buildStatus.publishedAt)}</Value>
                </Column>
              )}

              {buildStatus?.id && (
                <Column isSmall={isSmall}>
                  Build Log:{' '}
                  <Link href={deployLogURL} target="_blank">
                    {buildStatus.id}
                  </Link>
                </Column>
              )}
            </>
          )}
        </>
      )}
    </Section>
  )
}
