import styled from 'styled-components'

const EmbedContainer = styled.div<{ verticalOffset: number }>`
  height: calc(100vh - ${p => p.verticalOffset}px);
  overflow: auto;

  > iframe {
    display: block;
    width: 100%;
    height: 100% !important;
    border: none;
  }
`

export { EmbedContainer }
