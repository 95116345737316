import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const User: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.4165 5.83333C7.4165 4.4066 8.5731 3.25 9.99984 3.25C11.4266 3.25 12.5832 4.4066 12.5832 5.83333C12.5832 7.26007 11.4266 8.41667 9.99984 8.41667C8.5731 8.41667 7.4165 7.26007 7.4165 5.83333ZM9.99984 1.75C7.74467 1.75 5.9165 3.57817 5.9165 5.83333C5.9165 8.0885 7.74467 9.91667 9.99984 9.91667C12.255 9.91667 14.0832 8.0885 14.0832 5.83333C14.0832 3.57817 12.255 1.75 9.99984 1.75ZM6.66683 11.75C5.58386 11.75 4.54525 12.1802 3.77948 12.946C3.0137 13.7118 2.5835 14.7504 2.5835 15.8333V17.5C2.5835 17.9142 2.91928 18.25 3.3335 18.25C3.74771 18.25 4.0835 17.9142 4.0835 17.5V15.8333C4.0835 15.1482 4.35567 14.4911 4.84014 14.0066C5.32461 13.5222 5.98169 13.25 6.66683 13.25H13.3335C14.0186 13.25 14.6757 13.5222 15.1602 14.0066C15.6447 14.4911 15.9168 15.1482 15.9168 15.8333V17.5C15.9168 17.9142 16.2526 18.25 16.6668 18.25C17.081 18.25 17.4168 17.9142 17.4168 17.5V15.8333C17.4168 14.7504 16.9866 13.7118 16.2208 12.946C15.4551 12.1802 14.4165 11.75 13.3335 11.75H6.66683Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
