import React from 'react'
import capacityLlcLogo from 'assets/CapacityLlcLogo.png'
import { useForm } from 'hooks'
import { ValSchemaGeneric } from 'validationSchemas'
import { Fields } from '../common'
import { CapacityLlcSettings, Integration } from 'types'
import { useIntegrationForm } from 'redux/hooks'
import { FormInput, FormSecureInput } from 'components/common'
import { getFulfillmentConfirmationMessage } from 'utils/confirmationMessages'
import { MultipleProviderForm } from '../MultipleProviderForm'

type Props = {
  onChangeProvider: () => void
  onClose: () => void
  integration?: Integration<CapacityLlcSettings>
}

const CAPACITY_LLC_LOGIN_LINK = 'https://www.google.com'
const getSchema = (
  settings?: CapacityLlcSettings
): ValSchemaGeneric<
  [
    'clientCode',
    'ownerId',
    'password',
    'ftpUsername',
    'ftpPassword',
    'ftpUrl',
    'ftpPort',
    'httpTimeout'
  ]
> => ({
  clientCode: {
    label: 'Client Code',
    type: 'string',
    initialValue: settings?.clientCode,
  },
  ownerId: {
    label: 'Owner ID',
    type: 'string',
    initialValue: settings?.ownerId,
  },
  password: {
    label: 'Password',
    type: 'string',
    initialValue: settings?.password,
  },
  ftpUsername: {
    label: 'FTP Username',
    type: 'string',
    initialValue: settings?.ftpUsername,
  },
  ftpPassword: {
    label: 'FTP Password',
    type: 'string',
    initialValue: settings?.ftpPassword,
  },
  ftpUrl: {
    label: 'FTP Url',
    type: 'string',
    initialValue: settings?.ftpUrl,
  },
  ftpPort: {
    label: 'FTP Port',
    type: 'string',
    initialValue: settings?.ftpPort,
  },
  httpTimeout: {
    label: 'HTTP Timeout',
    type: 'string',
    initialValue: settings?.httpTimeout,
  },
})

export const CapacityLlcForm: React.FC<Props> = ({
  onChangeProvider,
  onClose,
  integration,
}) => {
  const { submit, isLoading } = useIntegrationForm(
    'capacity',
    integration,
    onClose
  )
  const schema = getSchema(integration?.settings)
  const { fields, onSubmit } = useForm(schema, submit)

  const integrationTitle = 'Capacity LLC'
  const confirmationMessage =
    getFulfillmentConfirmationMessage(integrationTitle)

  return (
    <MultipleProviderForm
      integrationType={'capacity'}
      integrationTitle={integrationTitle}
      logo={capacityLlcLogo}
      loginLink={CAPACITY_LLC_LOGIN_LINK}
      confirmationMessage={confirmationMessage}
      onChangeProvider={onChangeProvider}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <Fields>
        <FormInput field={fields.clientCode} customWidth="100%" />
        <FormInput field={fields.ownerId} customWidth="100%" />
        <FormSecureInput field={fields.password} customWidth="100%" />
        <FormInput field={fields.ftpUsername} customWidth="100%" />
        <FormSecureInput field={fields.ftpPassword} customWidth="100%" />
        <FormInput field={fields.ftpUrl} customWidth="100%" />
        <FormInput field={fields.ftpPort} customWidth="100%" />
        <FormInput field={fields.httpTimeout} customWidth="100%" />
      </Fields>
    </MultipleProviderForm>
  )
}
