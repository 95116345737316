import {
  FetchStartAction,
  FetchSuccessAction,
  FetchErrorAction,
  RefreshAction,
  ActionTypes,
} from './types'

export const actions = {
  fetchStart: (): FetchStartAction => ({
    type: ActionTypes.FETCH_START,
  }),

  fetchSuccess: <T>(
    items: T[] | undefined,
    nextCursor: string | undefined,
    prevCursor: string | undefined
  ): FetchSuccessAction<T> => ({
    type: ActionTypes.FETCH_SUCCESS,
    payload: {
      items,
      nextCursor,
      prevCursor,
    },
  }),

  fetchError: (error: Error | undefined): FetchErrorAction => ({
    type: ActionTypes.FETCH_ERROR,
    payload: error,
  }),

  refresh: (): RefreshAction => ({
    type: ActionTypes.REFRESH,
  }),
}
