const DISABLED_VARIATION_VALUES = [
  '',
  'off',
  'false',
  '0',
  'disabled',
  'control',
]

export class Treatment {
  public readonly variation: string
  public readonly config: Object

  constructor(variation: string, config: Object = {}) {
    this.variation = variation
    this.config = config
  }

  public get enabled(): Boolean {
    return !DISABLED_VARIATION_VALUES.includes(this.variation)
  }
}
