import React from 'react'
import { IconProps } from '../types'

export const GoogleLogo: React.FC<IconProps> = () => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1122_11)">
        <path
          d="M1.12696 5.28101C2.47895 2.59529 5.25605 0.75 8.47166 0.75C10.6915 0.75 12.546 1.56303 13.971 2.88763L11.6507 5.15317C11.0569 4.59589 10.0155 3.92902 8.47166 3.92902C6.2975 3.92902 4.45221 5.36326 3.78528 7.34559C3.62085 7.85716 3.5204 8.40524 3.5204 8.97166C3.5204 9.53801 3.62085 10.0862 3.79441 10.5977C4.45221 12.5801 6.2975 14.0142 8.47166 14.0142C9.69575 14.0142 10.6276 13.6762 11.3218 13.1921C12.4181 12.4248 12.9114 11.2829 13.0027 10.5246H8.47166V7.4735H16.1909C16.3096 7.98506 16.3645 8.47836 16.3645 9.15436C16.3645 11.6574 15.4692 13.7676 13.9162 15.2019C12.555 16.4625 10.6915 17.1933 8.47166 17.1933C5.25605 17.1933 2.47895 15.348 1.12696 12.6622C0.569735 11.5477 0.25 10.2963 0.25 8.97166C0.25 7.64706 0.569735 6.39552 1.12696 5.28101Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1122_11">
          <rect
            width="16.5"
            height="16.5"
            fill="white"
            transform="translate(0.25 0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
