import React from 'react'
import contentfulLogo from 'assets/ContentfulLogo.png'
import { useForm } from 'hooks'
import { FormInput, FormSecureInput } from '../../../common'
import { ValSchemaGeneric } from 'validationSchemas'
import { Fields } from './common'
import { ContentfulSettings, Integration } from 'types'
import { IntegrationForm } from './IntegrationForm'
import { useIntegrationForm } from 'redux/hooks'

type Props = {
  onClose: () => void
  integration?: Integration<ContentfulSettings>
}

const CONTENTFUL_LOGIN_LINK = 'https://be.contentful.com/login'

const getSchema = (
  settings?: ContentfulSettings
): ValSchemaGeneric<
  ['accessToken', 'environment', 'host', 'spaceId', 'webhookSecret']
> => ({
  accessToken: {
    label: 'Access Token',
    type: 'string',
    initialValue: settings?.accessToken,
  },
  environment: {
    label: 'Environment',
    type: 'string',
    initialValue: settings?.contentfulEnvironment,
  },
  host: {
    label: 'Host',
    type: 'string',
    initialValue: settings?.host,
  },
  spaceId: {
    label: 'Space ID',
    type: 'string',
    initialValue: settings?.spaceId,
  },
  webhookSecret: {
    label: 'Webhook Secret',
    type: 'string',
    initialValue: settings?.webhookSecret,
  },
})

export const ContentfulForm: React.FC<Props> = ({ onClose, integration }) => {
  const { submit, isLoading, isEdit } = useIntegrationForm(
    'contentful',
    integration,
    onClose
  )

  const schema = getSchema(integration?.settings)
  const { fields, onSubmit } = useForm(schema, submit)

  return (
    <IntegrationForm
      title="Content Management"
      name="Contentful"
      logo={contentfulLogo}
      loginLink={CONTENTFUL_LOGIN_LINK}
      isLoading={isLoading}
      isEdit={isEdit}
      submit={onSubmit}
      onClose={onClose}
    >
      <Fields>
        <FormInput field={fields.accessToken} customWidth="100%" />
        <FormInput field={fields.environment} customWidth="100%" />
        <FormInput field={fields.host} customWidth="100%" />
        <FormInput field={fields.spaceId} customWidth="100%" />
        <FormSecureInput field={fields.webhookSecret} customWidth="100%" />
      </Fields>
    </IntegrationForm>
  )
}
