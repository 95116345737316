import { composerClient } from 'api/composerHttp'
import { hubClient } from 'api/http'
import { ChordConfigSheet, ChordDataConnector } from 'types'

export type GetIsTenantAvailableResponse = {
  availableTenantName: boolean
  availableStackSlug: boolean
}

export type GetGithubHandleExistsResponse = {
  validGithubHandle: boolean
}

export type ProvisionTenantRequest = {
  firstName: string
  lastName: string
  email: string
  role: string
  tenantName: string
  githubHandle: string
  token: string
  code: string
}

export type ProvisionTenantResponse = {
  message: string
  id: string
}

export type ProvisioningStatus =
  | 'NOT_STARTED'
  | 'IN_PROGRESS'
  | 'SUCCEEDED'
  | 'FAILED'

export type ProvisioningStatuses = {
  firstName?: string
  requestEmail?: string
  githubHandle?: string
  contentStoreStatus: ProvisioningStatus
  githubRepoStatus: ProvisioningStatus
  chordTenantStatus: ProvisioningStatus
  embedLink?: string
}

export type GetOnboardingStatusResponse = {
  storeUrl: string
  githubRepoLink: string
  hasAcceptedGithubInvitation: boolean
  hasCommittedCode: boolean
  hasCompletedOrder: boolean
  hasAcceptedContentfulInvitation: boolean
}

export type GetTrialExpirationResponse = {
  expirationDate: Date
}

export interface ProvisioningClient {
  getIsTenantAvailable: (name: string) => Promise<GetIsTenantAvailableResponse>
  getGithubHandleExists: (
    handle: string
  ) => Promise<GetGithubHandleExistsResponse>
  provisionTenant: (
    request: ProvisionTenantRequest
  ) => Promise<ProvisionTenantResponse>
  getProvisioningStatus: (id: string) => Promise<ProvisioningStatuses>
  getOnboardingStatus: (stackId: number) => Promise<GetOnboardingStatusResponse>
  getTrialExpiration: (stackId: number) => Promise<GetTrialExpirationResponse>
  getDataConnectors: () => Promise<ChordDataConnector[]>
  getConfigSheets: () => Promise<ChordConfigSheet[]>
}

export const provisioningClient: ProvisioningClient = {
  getIsTenantAvailable: name =>
    composerClient.get(
      `provisioning/validate-tenant?tenantName=${name}&githubHandle=""`
    ),
  getGithubHandleExists: handle =>
    composerClient.get(`provisioning/validate-github?githubHandle=${handle}`),
  provisionTenant: request => composerClient.post('provisioning', request),
  getProvisioningStatus: id =>
    composerClient.get(`provisioning/requests/${id}`),
  getOnboardingStatus: stackId =>
    composerClient.get(`onboarding/stacks/${stackId}/status`),
  getTrialExpiration: (stackId: number) =>
    composerClient.get(`stacks/${stackId}/trial-expiration`),
  getDataConnectors: () => hubClient.get('provisioning/data_connectors'),
  getConfigSheets: () => hubClient.get('provisioning/config_sheets'),
}
