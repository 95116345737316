import React, { useState, useEffect } from 'react'
import { useCensus } from '../CensusContext'
import { EnvVarConfig } from 'utils/envVarConfig'
import { Loading } from 'components/common'
import SyncIframe from './SyncIframe'
import { CensusEmbedSyncResult } from 'api/census/interfaces'

interface SyncCreateWizardProps {
  onSyncCreated: (data: CensusEmbedSyncResult) => void
}

const SyncCreateWizard: React.FC<SyncCreateWizardProps> = ({
  onSyncCreated,
}) => {
  const { censusClient } = useCensus()

  const [isLoading, setIsLoading] = useState(false)
  const [syncManagementLink, setSyncManagementLink] = useState<string | null>(
    null
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)

        const sources = await censusClient?.getSources()

        if (!sources?.data?.length) {
          throw new Error('No sources found')
        }

        // We currently only support one source
        const sourceId = sources?.data[0]?.id

        const syncConnectLink = await censusClient?.createSyncManagementLink(
          EnvVarConfig.REACT_APP_CENSUS_EMBED_REDIRECT_URI
        )

        if (!syncConnectLink?.data?.uri) {
          throw new Error('No sync management URL found')
        }

        const linkWithDestination = `${syncConnectLink.data.uri}&form_connection_id=${sourceId}`

        setSyncManagementLink(linkWithDestination)

        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        // eslint-disable-next-line no-console
        console.error('Error fetching sync management link:', error)
      }
    }

    fetchData()
  }, [censusClient])

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && syncManagementLink && (
        <SyncIframe connectLink={syncManagementLink} onExit={onSyncCreated} />
      )}
    </>
  )
}

export default SyncCreateWizard
