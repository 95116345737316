import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const BurgerArrow: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 4.75C18.4142 4.75 18.75 4.41421 18.75 4C18.75 3.58579 18.4142 3.25 18 3.25H8C7.58579 3.25 7.25 3.58579 7.25 4C7.25 4.41421 7.58579 4.75 8 4.75L18 4.75ZM18 10.75C18.4142 10.75 18.75 10.4142 18.75 10C18.75 9.58579 18.4142 9.25 18 9.25L8 9.25C7.58579 9.25 7.25 9.58579 7.25 10C7.25 10.4142 7.58579 10.75 8 10.75L18 10.75ZM18.75 16C18.75 16.4142 18.4142 16.75 18 16.75L8 16.75C7.58579 16.75 7.25 16.4142 7.25 16C7.25 15.5858 7.58579 15.25 8 15.25L18 15.25C18.4142 15.25 18.75 15.5858 18.75 16ZM5.03033 8.03033C5.32322 7.73744 5.32322 7.26256 5.03033 6.96967C4.73744 6.67678 4.26256 6.67678 3.96967 6.96967L1.46967 9.46967C1.17678 9.76256 1.17678 10.2374 1.46967 10.5303L3.96967 13.0303C4.26256 13.3232 4.73744 13.3232 5.03033 13.0303C5.32322 12.7374 5.32322 12.2626 5.03033 11.9697L3.06066 10L5.03033 8.03033Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
