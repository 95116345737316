import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const AlmostPurchasers: React.FC<IconProps> = ({
  scale = 48,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25 18.6409C7.25 16.5698 8.92893 14.8909 11 14.8909H38C40.0711 14.8909 41.75 16.5698 41.75 18.6409V25.6409C41.75 27.7119 40.0711 29.3909 38 29.3909H32.9012L31.8746 41.4039L28.364 37.0378L25.7118 41.6316L24.4128 40.8816L28.1603 34.3906L30.6986 37.5476L31.6008 26.991L23.4164 33.1019L26.5578 33.2938L24.1299 37.0482L22.8703 36.2336L23.9044 34.6345L19.2382 34.3495L33.3798 23.7908L33.0294 27.8909H38C39.2426 27.8909 40.25 26.8835 40.25 25.6409V18.6409C40.25 17.3982 39.2426 16.3909 38 16.3909H11C9.75736 16.3909 8.75 17.3982 8.75 18.6409V25.6409C8.75 26.8835 9.75736 27.8909 11 27.8909H21V29.3909H11C8.92893 29.3909 7.25 27.7119 7.25 25.6409V18.6409Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4985 19.741V24.641H19.4515C20.6555 24.641 21.3275 24.102 21.3275 23.248C21.3275 22.548 20.8795 22.205 20.2285 22.121C20.7885 22.002 21.1525 21.652 21.1525 21.036C21.1525 20.217 20.4945 19.741 19.3605 19.741H17.4985ZM18.5485 20.567H19.3885C19.8435 20.567 20.1025 20.798 20.1025 21.162C20.1025 21.526 19.8365 21.757 19.3885 21.757H18.5485V20.567ZM18.5485 22.569H19.4585C19.9555 22.569 20.2495 22.8 20.2495 23.199C20.2495 23.577 19.9625 23.808 19.4585 23.808H18.5485V22.569Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M21.8659 19.741V22.527C21.8659 23.92 22.5589 24.683 23.8749 24.683C25.1979 24.683 25.8909 23.92 25.8909 22.527V19.741H24.8409V22.583C24.8409 23.36 24.5539 23.78 23.8749 23.78C23.2029 23.78 22.9159 23.36 22.9159 22.583V19.741H21.8659Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M28.0719 22.821L26.2729 19.741H27.4979L28.5969 21.75L29.7519 19.741H30.9209L29.1219 22.821V24.641H28.0719V22.821Z"
        fill="black"
      />
    </svg>
  )
}
