import React from 'react'
import styled from 'styled-components'

interface Props {
  percent: number
  width?: number
}

export const PercentBar: React.FC<Props> = ({ percent, width = 192 }) => (
  <BaseBar width={width}>
    <Percent width={(percent < 1 ? percent : 1) * width} />
  </BaseBar>
)

const BaseBar = styled.div<{ width: number }>`
  position: relative;
  height: 4px;
  width: ${p => p.width}px;
  background-color: ${p => p.theme.GREY_30};
  border-radius: 100px;
`

const Percent = styled.div<{ width: number }>`
  position: absolute;
  height: 4px;
  width: ${p => p.width}px;
  background-color: ${p => p.theme.PRIMARY_60};
  border-radius: 100px;
`
