import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { methodsReducer } from './api'
import { notificationsMiddleware } from './middleware/notificationsMiddleware'
import { tenantMiddleware } from './middleware/tenantMiddleware'
import auth from './state/auth'
import businessErrors from './state/businessErrors'
import environments from './state/environments'
import looker from './state/looker'
import notifications from './state/notifications'
import provisioning from './state/provisioning'
import stores from './state/stores'
import storeSetup from './state/storeSetup'
import systemHealth from 'redux/state/systemHealth'
import tenants from './state/tenants'
import users from './state/users'
import userNotifications from './state/userNotifications'
import configSheets from './state/configSheets'
import rolePermissionDescriptions from './state/rolePermissionDescriptions'
import reportsFilter from './state/reportsFilter'
import quickstartsFilter from './state/quickstartsFilter'

const reduxStore = configureStore({
  reducer: {
    auth,
    businessErrors,
    environments,
    looker,
    notifications,
    provisioning,
    rolePermissionDescriptions,
    stores,
    storeSetup,
    systemHealth,
    tenants,
    users,
    userNotifications,
    methods: methodsReducer,
    configSheets,
    reportsFilter,
    quickstartsFilter,
  },
  middleware: getDefaultMiddleware().concat(
    notificationsMiddleware,
    tenantMiddleware
  ),
})

export type RootState = ReturnType<typeof reduxStore.getState>
export type AppDispatch = typeof reduxStore.dispatch

export default reduxStore
