import styled from 'styled-components'
import { standardIcons, useTheme } from '@chordco/component-library'
import React from 'react'
import { Sort } from 'types'

const BaseTableItem = styled.div<{ gap?: string }>`
  display: flex;
  margin-bottom: 2px;
  padding: 12px 16px;
  min-width: 60px;
  align-items: center;
  background-color: ${p => p.theme.ComponentCardBgElevation1};
  color: ${p => p.theme.ContentPrimary};
  border: 1px solid transparent;
  gap: ${({ gap }) => gap || 0};

  //Comment out the properties below for the flat table style
  margin-bottom: 0px;
  background-color: ${p => p.theme.ComponentCardBgElevation0};
  border-bottom: 1px solid ${p => p.theme.BorderMinimal};
  border-radius: 0px;
`

export const TableHeader = styled(BaseTableItem)`
  background-color: ${p => p.theme.ComponentWorkspaceBgPage};
  height: 36px;
  padding: 8px 16px;
  margin-bottom: 0;
  font-weight: 600;

  //Flat STyle
  border-bottom: 1px solid ${p => p.theme.BorderMinimal};
  // border-bottom: 1px solid transparent;
  // background-color: ${p => p.theme.BgBase2};
  // border-radius: ${p => p.theme.CardSmallRadius};
`

export const TableRow = styled(BaseTableItem)<{
  height?: string
}>`
  position: relative;
  height: ${p => p.height || 'auto'};
  color: ${p => p.theme.ContentPrimary};
  cursor: pointer;
  transition: 100ms;

  :first-child {
    // margin-top: 2px;
  }

  :hover {
    background-color: ${p => p.theme.BgBase3};
    border-bottom: 1px solid transparent;
    // border-color: ${p => p.theme.BorderAccentPrimary};

    svg {
      path {
        // todo why was this here? need to solve another way
        /* fill: ${p => p.theme.ContentAccentPrimary}; */
      }
    }
  }
`

export const MobileRow = styled(TableRow)<{ height?: string }>`
  display: block;
  height: ${p => p.height || '88px'};
`

export const Col = styled.div<{ flex?: number; align?: string }>`
  flex: ${p => p.flex || 1};
  position: relative;
  text-align: ${p => p.align || 'left'};
`

type RowWithChevronProps = {
  text: string
  flex?: number
  rowSortId: string
  sort: Sort
  toggleIsAsc: (selectedId: string) => void | Function
}
export const RowWithChevron: React.FC<RowWithChevronProps> = ({
  text,
  flex = 1,
  rowSortId,
  sort,
  toggleIsAsc,
}) => {
  const theme = useTheme()

  const { ChevronDown } = standardIcons

  return (
    <Col
      flex={flex}
      onClick={() => toggleIsAsc(rowSortId)}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <RowText>{text}</RowText>
      {rowSortId === sort.selectedId && (
        <ChevronContainer isAsc={sort.isAsc} data-testid="sortChevron">
          <ChevronDown fill={theme.ContentAccentPrimary} />
        </ChevronContainer>
      )}
    </Col>
  )
}

const ChevronContainer = styled.div<{ isAsc: boolean }>`
  transform: rotate(${p => (p.isAsc ? 0 : 180)}deg);
  cursor: pointer;
  display: inline-block;
  height: 17px;
  margin-right: 6px;
  transition: transform 150ms;
`

const RowText = styled.span`
  cursor: pointer;
  margin-right: 3px;

  :hover {
    color: ${p => p.theme.ContentAccentPrimary};
  }
`

const { Check, Exit } = standardIcons

export const Yep = () => {
  const theme = useTheme()

  return (
    <IconColor color={theme.ContentAccentSuccess}>
      <Check size="large" />
    </IconColor>
  )
}

export const Nope = () => {
  const theme = useTheme()

  return (
    <IconColor color={theme.ContentAccentError}>
      <Exit />
    </IconColor>
  )
}

// We use important here so that the icon colors can't be changed by parent elements
const IconColor = styled.span<{ color: string }>`
  path {
    fill: ${p => p.color} !important;
  }
`
