import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const ArrowUpCircle: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.75C5.44365 1.75 1.75 5.44365 1.75 10C1.75 14.5563 5.44365 18.25 10 18.25C14.5563 18.25 18.25 14.5563 18.25 10C18.25 5.44365 14.5563 1.75 10 1.75ZM0.25 10C0.25 4.61522 4.61522 0.25 10 0.25C15.3848 0.25 19.75 4.61522 19.75 10C19.75 15.3848 15.3848 19.75 10 19.75C4.61522 19.75 0.25 15.3848 0.25 10Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.46967 5.46967C9.76256 5.17678 10.2374 5.17678 10.5303 5.46967L14.5303 9.46967C14.8232 9.76256 14.8232 10.2374 14.5303 10.5303C14.2374 10.8232 13.7626 10.8232 13.4697 10.5303L10 7.06066L6.53033 10.5303C6.23744 10.8232 5.76256 10.8232 5.46967 10.5303C5.17678 10.2374 5.17678 9.76256 5.46967 9.46967L9.46967 5.46967Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 5.25C10.4142 5.25 10.75 5.58579 10.75 6V14C10.75 14.4142 10.4142 14.75 10 14.75C9.58579 14.75 9.25 14.4142 9.25 14V6C9.25 5.58579 9.58579 5.25 10 5.25Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
