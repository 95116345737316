import React, { useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'

interface CardProps {
  id: number | string
  icon?: any
  title?: string
  customHeader?: React.ReactNode
  content: React.ReactNode
}
interface Props {
  cards: CardProps[]
}

const renderColumn = (colCards: CardProps[], setHeights) =>
  colCards.map(({ id, icon, title, content, customHeader }) => {
    const ref = useRef() as any

    useLayoutEffect(() => {
      setHeights((currentHeights: any[]) => {
        const height = ref.current?.getBoundingClientRect().height || 0
        return [...currentHeights, { id, height }]
      })
    }, [])

    return (
      <Card key={id} ref={ref}>
        {customHeader || (
          <Header>
            {icon}
            <Title>{title}</Title>
          </Header>
        )}
        <Content>{content}</Content>
      </Card>
    )
  })

export const Cards: React.FC<Props> = ({ cards }) => {
  let col1Height = 0
  let col2Height = 0

  const [heights, setHeights] = useState([])
  const column1 = [] as CardProps[]
  const column2 = [] as CardProps[]

  if (!heights.length) {
    column1.push(...cards) // this is only used to calculate heights on the initial render
  } else {
    cards.forEach((card: CardProps) => {
      const cardHeight = heights.find(({ id }) => id === card.id) as any
      if (col1Height <= col2Height) {
        col1Height += cardHeight.height
        column1.push(card)
      } else {
        col2Height += cardHeight.height
        column2.push(card)
      }
    })
  }

  return (
    <Container>
      <Col>{renderColumn(column1, setHeights)}</Col>
      <Col>{renderColumn(column2, setHeights)}</Col>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  max-width: 816px;
  margin: 0 auto;
`

const Col = styled.div`
  flex: 1;
  width: 400px;

  :first-child {
    margin-right: 16px;
  }
`

const Card = styled.div`
  box-sizing: border-box;
  width: 400px;
  background-color: ${p => p.theme.WHITE};
  border: solid 1px ${p => p.theme.GREY_20};
  border-radius: 16px;
  margin-bottom: 16px;

  :nth-child(2n + 1) {
    order: 1;
  }
  :nth-child(2n) {
    order: 2;
  }
`

export const Header = styled.div`
  display: flex;
  padding: 16px;
  border-radius: 16px 16px 0 0;
`

export const Title = styled.title`
  display: inline-block;
  margin: 4px 0 0 8px;
  font-weight: 500;
`

const Content = styled.div`
  padding: 16px;
`
