import React, { ReactNode, useContext } from 'react'
import styled, {
  css,
  ThemeProvider,
  createGlobalStyle,
} from 'styled-components'
import type { Theme } from '@chordco/component-library'

interface Props {
  theme: Theme
  children: ReactNode
}

// @ts-ignore
const ThemeContext = React.createContext<Theme>()

export const useTheme = () => useContext(ThemeContext)

export const ChordRootThemeProvider: React.FC<Props> = props => {
  const parentTheme = useContext(ThemeContext) ?? props.theme
  const providedTheme = { ...parentTheme, ...props.theme }

  return (
    <ThemeContext.Provider value={providedTheme}>
      <ThemeProvider theme={props.theme}>
        <RootCssVariables chordTheme={props.theme} />
        {props.children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

const RootCssVariables = createGlobalStyle<{ chordTheme: Theme }>`
  :root {
  ${p =>
    Object.entries(p.chordTheme).map(
      ([key, value]) => css`--${key}: ${value};`
    )}
  }

  html, body {
    color: ${p => p.theme.ContentPrimary};
    background-color: ${p => p.theme.ReverseBackgroundBase1};
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin: 0;
  }

  button, input {
    font-family: Inter, Helvetica, Arial, sans-serif;
  }

  .simplebar-scrollbar:before {
    background-color: ${p => p.theme.BgAccentBrightAADarkTertiary};
  }

  div {
    box-sizing: border-box;
  }
`

export const ChordThemeProvider: React.FC<Props> = props => {
  const parentTheme = useContext(ThemeContext) ?? props.theme
  const providedTheme = { ...parentTheme, ...props.theme }

  return (
    <ThemeContext.Provider value={providedTheme}>
      <ThemeProvider theme={props.theme}>
        <CssVariables chordTheme={props.theme}>{props.children}</CssVariables>
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

const CssVariables = styled.div<{ chordTheme: Theme }>`
  ${p =>
    Object.entries(p.chordTheme).map(
      ([key, value]) => css`--${key}: ${value};`
    )}
`
