import React from 'react'
import magicLogo from 'assets/MagicLogo.png'
import stytchLogo from 'assets/StytchLogo.png'
import { SelectProviderForm } from './SelectProviderForm'
import { StytchForm } from './authentication/StytchForm'
import { MagicForm } from './authentication/MagicForm'
import {
  Integration,
  PossibleAuthenticationIntegrationSettings,
  ProviderOption,
} from 'types'

type Props = {
  onClose: () => void
  integration?: Integration<PossibleAuthenticationIntegrationSettings>
}

export const authenticationProviders: ProviderOption[] = [
  {
    key: 'Hub::Magic',
    name: 'Magic',
    logo: magicLogo,
    form: MagicForm,
  },
  {
    key: 'Hub::Stytch',
    name: 'Stytch',
    logo: stytchLogo,
    form: StytchForm,
  },
]

export const AuthenticationProviderForm: React.FC<Props> = ({
  onClose,
  integration,
}) => {
  return (
    <SelectProviderForm
      name={'Authentication'}
      providers={authenticationProviders}
      integration={integration}
      onClose={onClose}
    />
  )
}
