import React from 'react'
import { Button, Modal, Spinner } from '@chordco/component-library'
import { FormInput } from 'components/common'
import { useForm } from 'hooks'
import styled from 'styled-components'
import { useEmbedUser } from 'redux/hooks'
import { useCreateDashboard } from './useCreateDashboard'
import { AvailableUserReport } from 'types'

interface CreateDashboardModalProps {
  onCreateDashboard: (dashboard?: AvailableUserReport) => void
  onClose: () => void
}

export const CreateDashboardModal: React.FC<CreateDashboardModalProps> = ({
  onCreateDashboard,
  onClose,
}) => {
  const { isLoadingEmbedUser, embedUser } = useEmbedUser()

  const { create, isCreating } = useCreateDashboard(onCreateDashboard)

  const { fields, onSubmit } = useForm(
    {
      title: {
        label: 'Title',
        type: 'string',
        initialValue: '',
        required: true,
      },
      folderId: {
        label: 'Folder ID',
        type: 'string',
        initialValue: embedUser?.personalFolderId,
        required: true,
      },
    },
    create
  )

  return (
    <Modal onClose={onClose} title="Create New Dashboard" width="480px">
      <FormContainer onSubmit={onSubmit} noValidate>
        {isLoadingEmbedUser && (
          <SpinnerContainer>
            <Spinner scale={30} />
          </SpinnerContainer>
        )}

        {!isLoadingEmbedUser && embedUser && (
          <>
            <Section>
              <FormInput field={fields.title} customWidth="100%" required />
            </Section>

            <BottomSection>
              <Button purpose="tertiary" onClick={onClose}>
                Cancel
              </Button>

              <Button
                type="submit"
                purpose="primary"
                isLoading={isCreating}
                disabled={isCreating}
              >
                Create Dashboard
              </Button>
            </BottomSection>
          </>
        )}
      </FormContainer>
    </Modal>
  )
}

export const FormContainer = styled.form`
  overflow: auto;
`

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const BottomSection = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`
