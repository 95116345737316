import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../icons/types'

export const Spinner: React.FC<IconProps> = ({ scale = 16, fill }) => {
  const theme = useTheme()

  return (
    <StyledSpinner scale={scale}>
      <svg
        width={scale}
        height={scale}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 1.04936C8 0.469813 7.52818 -0.00708422 6.95362 0.0687186C5.65535 0.24 4.41329 0.728246 3.34018 1.49723C1.98147 2.47087 0.96186 3.84565 0.424559 5.42848C-0.112743 7.01132 -0.140748 8.72271 0.344477 10.3223C0.829702 11.9218 1.80378 13.3293 3.12991 14.3468C4.45604 15.3644 6.06759 15.941 7.73825 15.9957C9.4089 16.0504 11.0547 15.5804 12.4446 14.6518C13.8344 13.7231 14.8984 12.3824 15.4872 10.818C15.9523 9.58243 16.1024 8.25632 15.9319 6.95796C15.8564 6.38335 15.2736 6.05104 14.7138 6.20104C14.154 6.35104 13.8319 6.9275 13.8805 7.50501C13.9537 8.3745 13.8335 9.25396 13.523 10.0787C13.0887 11.2327 12.3038 12.2217 11.2786 12.9067C10.2533 13.5918 9.03929 13.9385 7.80692 13.8981C6.57454 13.8578 5.38576 13.4324 4.40753 12.6818C3.42929 11.9312 2.71075 10.893 2.35282 9.71305C1.99489 8.53311 2.01555 7.27069 2.4119 6.10309C2.80824 4.9355 3.56037 3.92138 4.56263 3.20316C5.27896 2.68985 6.09734 2.34612 6.95614 2.19177C7.52655 2.08925 8 1.6289 8 1.04936Z"
          fill={fill || theme.PRIMARY_50}
        />
      </svg>
    </StyledSpinner>
  )
}

const StyledSpinner = styled.div<{ scale: number }>`
  position: absolute;
  top: calc(50% - ${p => p.scale / 2}px);
  left: calc(50% - ${p => p.scale / 2}px);

  svg {
    transform: rotate(-90deg);
    animation: spin 1s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`
