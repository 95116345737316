import * as moment from 'moment-timezone'

/**
 * Format a UTC date string to a relative time string based on the user's timezone.
 */
function formatRelativeTime(utcDateString: string): string {
  const utcMoment = moment.utc(utcDateString)
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const userLocalMoment = utcMoment.tz(userTimeZone)
  return userLocalMoment.fromNow()
}

export { formatRelativeTime }
