import React, { useRef } from 'react'
import styled from 'styled-components'
import { CensusConnector } from 'api/census/interfaces'
import { Paginator } from './Paginator'
import { ConnectorCard } from './ConnectorCard'

type Props = {
  connectors: CensusConnector[]
  onSelectConnector: (connector: CensusConnector) => void
  onPrevPage: () => void
  onNextPage: () => void
  showNextPageButton: boolean
  showPreviousPageButton: boolean
}

export const ConnectorBrowser: React.FC<Props> = ({
  connectors,
  onSelectConnector,
  onPrevPage,
  onNextPage,
  showNextPageButton,
  showPreviousPageButton,
}) => {
  const ref = useRef() as any

  const paginatorProps = {
    onPrevPage: onPrevPage,
    onNextPage: onNextPage,
    showNextPage: showNextPageButton,
    showPrevPage: showPreviousPageButton,
  }

  return (
    <>
      <Paginator {...paginatorProps} />

      <SourceContainer ref={ref}>
        {connectors.map(connector => (
          <ConnectorCard
            key={connector.serviceName}
            connector={connector}
            onSelectConnector={onSelectConnector}
          />
        ))}
      </SourceContainer>

      <Paginator {...paginatorProps} />
    </>
  )
}

const SourceContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: minmax(100px, auto);
  grid-auto-flow: dense;
  grid-gap: 12px;
`
