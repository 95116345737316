import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Button, H1, H2, useIsMobile } from '@chordco/component-library'
import { Rocket } from './Rocket'
import { useProvisioningData } from 'redux/state/provisioning'
import { useHistory, useLocation } from 'react-router'
import { getQueryStringValues } from 'utils'
import { Loading } from 'components/common'
import { AuthWrapper, CentralContent } from 'components/auth/AuthWrapper'
import { ConfirmationStep } from 'components/provisioning/ConfirmationStep'
import { EnvVarConfig } from 'utils/envVarConfig'

const timeoutLimit = EnvVarConfig.REACT_APP_PROVISIONING_STATUS_TIMEOUT_LIMIT
  ? +EnvVarConfig.REACT_APP_PROVISIONING_STATUS_TIMEOUT_LIMIT
  : 600000
const waitBetweenCalls =
  EnvVarConfig.REACT_APP_PROVISIONING_STATUS_WAIT_BETWEEN_CALLS
    ? +EnvVarConfig.REACT_APP_PROVISIONING_STATUS_WAIT_BETWEEN_CALLS
    : 5000

export const SignUpStatus: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const values = getQueryStringValues(location.search)

  const {
    state: { provisioningStatuses },
    getProvisioningStatus,
  } = useProvisioningData()

  const refreshProvisioningStatus = (timeoutTime?: number) => {
    const timeout = timeoutTime || Date.now() + timeoutLimit
    if (Date.now() > timeout) return

    if (
      !provisioningStatuses ||
      (provisioningStatuses &&
        provisioningStatuses.contentStoreStatus !== 'SUCCEEDED' &&
        provisioningStatuses.contentStoreStatus !== 'FAILED' &&
        provisioningStatuses.githubRepoStatus !== 'SUCCEEDED' &&
        provisioningStatuses.githubRepoStatus !== 'FAILED' &&
        provisioningStatuses.chordTenantStatus !== 'SUCCEEDED' &&
        provisioningStatuses.chordTenantStatus !== 'FAILED')
    ) {
      getProvisioningStatus(values.id)

      setTimeout(() => refreshProvisioningStatus(timeout), waitBetweenCalls)
    }
  }

  const showEmbedLink =
    provisioningStatuses?.contentStoreStatus === 'SUCCEEDED' &&
    provisioningStatuses?.githubRepoStatus === 'SUCCEEDED' &&
    provisioningStatuses?.chordTenantStatus === 'SUCCEEDED' &&
    provisioningStatuses?.embedLink

  useEffect(() => {
    if (values.id) refreshProvisioningStatus()
  }, [])

  const isMobile = useIsMobile()
  const Heading = isMobile ? H2 : H1

  return (
    <AuthWrapper>
      <Heading>Thanks {provisioningStatuses?.firstName}!</Heading>
      <br />
      <div>
        <Rocket />
      </div>
      <br />
      <ConfirmationSubHeading isMobile={isMobile}>
        Your Chord trial environment is being created; this process usually
        takes a few minutes.
        <Bold>We&apos;ll email you as soon as it&apos;s ready.</Bold>
      </ConfirmationSubHeading>
      <br />
      <CentralContent isMobile={isMobile} isConfirmation>
        {provisioningStatuses ? (
          <div>
            <ConfirmationStep
              status={provisioningStatuses.contentStoreStatus}
              primaryText="Setting up Contentful space"
              secondaryText={
                <div>
                  Chord uses{' '}
                  <Link href="https://be.contentful.com/login" target="_blank">
                    Contentful
                  </Link>{' '}
                  for content and product management on your store.
                </div>
              }
              info={`Check your email (${provisioningStatuses.requestEmail}) for an invitation to Contentful.`}
            />
            <ConfirmationStep
              status={provisioningStatuses.githubRepoStatus}
              primaryText="Setting up Github access"
              secondaryText={
                <div>
                  We&apos;re provising access to Chord&apos;s starter code.
                </div>
              }
              info={`Check the email associated with you Github handle (@${provisioningStatuses.githubHandle}) for an invitation to the repository.`}
            />
            <ConfirmationStep
              status={provisioningStatuses.chordTenantStatus}
              primaryText="Finalizing Chord environment"
              info={`Check your email (${provisioningStatuses.requestEmail}) for your invitation to Chord.`}
            />
          </div>
        ) : (
          <Loading />
        )}
      </CentralContent>
      {showEmbedLink && (
        <>
          <Meantime>Your trial environment is ready!</Meantime>
          <ButtonContainer>
            <Button
              onClick={() =>
                history.push(provisioningStatuses?.embedLink || '')
              }
            >
              Log in
            </Button>
          </ButtonContainer>
        </>
      )}
      <Meantime>
        {showEmbedLink ? 'G' : 'In the meantime, g'}
        et to know Chord by getting familiar with our documentation:
      </Meantime>
      <ButtonContainer>
        <Button onClick={() => window.open('https://docs.chord.co/')}>
          Check out the developer docs
        </Button>
      </ButtonContainer>
    </AuthWrapper>
  )
}

const ConfirmationSubHeading = styled.div<{ isMobile: boolean }>`
  line-height: 24px;
  font-size: 16px;
  font-weight: 200;
  margin: 0 auto;
  padding-left: ${p => (p.isMobile ? 24 : 56)}px;
  padding-right: ${p => (p.isMobile ? 24 : 56)}px;
  width: ${p => (p.isMobile ? 'calc(100vw - 24px)' : '480px')};
`

const Bold = styled.div`
  font-weight: 600;
`

const Meantime = styled.div`
  margin: 36px auto 16px;
  max-width: 368px;
  font-size: 16px;
  font-weight: 300;
`

const ButtonContainer = styled.div`
  margin: 0 auto;

  button {
    width: 336px;
  }
`

const Link = styled.a`
  color: inherit;
`
