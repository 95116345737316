import React from 'react'
import { Chip, ChipColor, standardIcons } from '@chordco/component-library'
import { SetupState } from 'api/fivetran/interfaces'
import styled from 'styled-components'

type Props = {
  setupState: SetupState
  iconOnly?: boolean
}

export const SetupChip: React.FC<Props> = ({
  setupState,
  iconOnly = false,
}) => {
  const getChipSetupText = (setupState: SetupState): string => {
    return setupState.charAt(0).toUpperCase() + setupState.slice(1)
  }

  const getChipSetupColor = (setupState: SetupState): ChipColor => {
    switch (setupState) {
      case 'incomplete':
        return 'warning'
      case 'connected':
        return 'success'
      case 'broken':
        return 'error'
      default:
        throw new Error('Unknown Connector setup state: ' + setupState)
    }
  }

  const getChipIcon = (setupState: SetupState) => {
    switch (setupState) {
      case 'incomplete':
        return standardIcons.AlertTriangle
      case 'connected':
        return standardIcons.Check
      case 'broken':
        return standardIcons.CloseX
      default:
        throw new Error('Unknown Connector setup state: ' + setupState)
    }
  }

  return (
    <ChipContainer>
      <Chip
        leftIcon={getChipIcon(setupState)}
        leftIconSize={12}
        text={iconOnly ? '' : getChipSetupText(setupState)}
        color={getChipSetupColor(setupState)}
        variant="solid-secondary"
      />
    </ChipContainer>
  )
}

const ChipContainer = styled.div`
  svg {
    path {
      fill: ${p => p.theme.BLACK};
    }
  }
`
