import { ChordConfigSheet } from 'types'
import { valTypes } from 'validationSchemas'

const updateConfigSchema = (
  spreadsheetId: string,
  sheetSlugs: ChordConfigSheet[]
) => ({
  spreadsheetId: {
    label: 'Spreadsheet ID',
    type: valTypes.string,
    initialValue: spreadsheetId,
    required: true,
  },
  sheets: {
    label: 'Sheets',
    type: valTypes.array,
    initialValue: sheetSlugs,
    required: true,
  },
})

export default updateConfigSchema
