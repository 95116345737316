import { useAuthData } from 'redux/state/auth'

const useUserRole = () => {
  const {
    state: { user },
  } = useAuthData()
  return user?.role || null
}

export default useUserRole
