import { createContext, useContext } from 'react'
import {
  DataSourceRequestProps,
  FeatureAccessRequestProps,
} from 'utils/notion/service'

type NotionContextValue = {
  createDataSourceRequest: (props: DataSourceRequestProps) => Promise<any>
  createFeatureAccessRequest: (props: FeatureAccessRequestProps) => Promise<any>
  fetchFeatureAccessRequests: (
    tenantId: number,
    feature: string
  ) => Promise<any>
}

const NotionContext = createContext<NotionContextValue | null>(null)

const useNotion = () => {
  const contextValue = useContext(NotionContext)
  if (!contextValue) {
    throw new Error('useNotion must be used within a NotionProvider')
  }
  return contextValue
}

export { NotionContext, useNotion }
