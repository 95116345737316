import React from 'react'
import easyPostLogo from 'assets/EasyPostLogo.png'
import { useForm } from 'hooks'
import { ValSchemaGeneric } from 'validationSchemas'
import { Fields } from '../common'
import { EasyPostSettings, Integration } from 'types'
import { FormSecureInput } from 'components/common'
import { useIntegrationForm } from 'redux/hooks'
import { getFulfillmentConfirmationMessage } from 'utils/confirmationMessages'
import { MultipleProviderForm } from '../MultipleProviderForm'

type Props = {
  onChangeProvider: () => void
  onClose: () => void
  integration?: Integration<EasyPostSettings>
}

const EASYPOST_LOGIN_LINK = 'https://www.easypost.com/login'
const getSchema = (settings?: EasyPostSettings): ValSchemaGeneric<['key']> => ({
  key: {
    label: 'Easypost Key',
    type: 'string',
    initialValue: settings?.key,
  },
})

export const EasyPostForm: React.FC<Props> = ({
  onChangeProvider,
  onClose,
  integration,
}) => {
  const { submit, isLoading } = useIntegrationForm(
    'easypost',
    integration,
    onClose
  )

  const schema = getSchema(integration?.settings)
  const { fields, onSubmit } = useForm(schema, submit)

  const integrationTitle = 'EasyPost'
  const confirmationMessage =
    getFulfillmentConfirmationMessage(integrationTitle)

  return (
    <MultipleProviderForm
      integrationType="easypost"
      integrationTitle={integrationTitle}
      logo={easyPostLogo}
      loginLink={EASYPOST_LOGIN_LINK}
      confirmationMessage={confirmationMessage}
      onChangeProvider={onChangeProvider}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <Fields>
        <FormSecureInput field={fields.key} customWidth="100%" />
      </Fields>
    </MultipleProviderForm>
  )
}
