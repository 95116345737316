import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Copy: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 0.833313H3.33329C2.41663 0.833313 1.66663 1.58331 1.66663 2.49998V14.1666H3.33329V2.49998H13.3333V0.833313ZM15.8333 4.16665H6.66663C5.74996 4.16665 4.99996 4.91665 4.99996 5.83331V17.5C4.99996 18.4166 5.74996 19.1666 6.66663 19.1666H15.8333C16.75 19.1666 17.5 18.4166 17.5 17.5V5.83331C17.5 4.91665 16.75 4.16665 15.8333 4.16665ZM15.8333 17.5H6.66663V5.83331H15.8333V17.5Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
