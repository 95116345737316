import { useSelector } from 'react-redux'
import { createSlice } from '@reduxjs/toolkit'
import { omsClient, updateOmsClient } from 'api/omsHttp'
import { reduxApiClient } from 'redux/api'
import { RootState } from 'redux/store'
import { DateRange, Integration, SolidusSettings, StoreState } from 'types'
import { useStateSliceAndDispatch } from '../utils'

const SLICE_NAME = 'stores'

const initialState: StoreState = {
  stepsComplete: undefined,
  metrics: [],
  solidusInfo: {},
}

const { getStoreOverview, getStores } = reduxApiClient

export const storesSlice = createSlice({
  name: 'store',
  initialState: initialState,
  reducers: {
    resetStore: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getStoreOverview.fulfilled, (state, action) => {
      state.stepsComplete = action.payload.data.stepsComplete
      state.metrics = action.payload.data.metrics
    })

    builder.addCase(getStores.fulfilled, (state, action) => {
      const storeWithIntegrations = action.payload.data.find(
        s => s.integrations.length
      )

      if (!storeWithIntegrations) return
      state.solidusInfo = storeWithIntegrations.integrations.reduce(
        (acc, cur) => {
          if (cur.name !== 'Solidus') return acc

          const solidusIntegration = cur as Integration<SolidusSettings>
          if (
            action.meta.arg.environment === solidusIntegration.environment &&
            !omsClient
          ) {
            updateOmsClient(
              solidusIntegration.settings.apiHost,
              solidusIntegration.settings.apiKey
            )
          }

          return {
            ...acc,
            [solidusIntegration.environment]: {
              ...solidusIntegration.settings,
            },
          }
        },
        {}
      )
    })
  },
})

export default storesSlice.reducer

export const useStoresData = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)

  const environment = useSelector(
    (state: RootState) => state.environments.selectedEnvironment
  )

  return {
    state,
    getStoreOverview: (dateRange: DateRange) =>
      dispatch(getStoreOverview(dateRange)),
    fetchStores: (tenantId: number) =>
      dispatch(getStores({ tenantId, environment })),
  }
}
