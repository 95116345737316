import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const TreeViewIcon: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 4C1.25 3.0335 2.0335 2.25 3 2.25H17C17.9665 2.25 18.75 3.0335 18.75 4V7V11V14C18.75 14.9665 17.9665 15.75 17 15.75H13C12.0335 15.75 11.25 14.9665 11.25 14V11.75H7C6.0335 11.75 5.25 10.9665 5.25 10V7.75H3C2.0335 7.75 1.25 6.9665 1.25 6V4ZM12 10.25H17.25V7.75H6.75V10C6.75 10.1381 6.86193 10.25 7 10.25H12ZM12.75 11.75V14C12.75 14.1381 12.8619 14.25 13 14.25H17C17.1381 14.25 17.25 14.1381 17.25 14V11.75H12.75ZM17.25 4V6.25H6H3C2.86193 6.25 2.75 6.13807 2.75 6V4C2.75 3.86193 2.86193 3.75 3 3.75H17C17.1381 3.75 17.25 3.86193 17.25 4Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
