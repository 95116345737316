import { useIsMobile } from 'hooks'
import React from 'react'
import styled from 'styled-components'

interface Props {
  children?: React.ReactNode
  innerCircleScale?: number
  outerCircleScale?: number
}

export const Circle: React.FC<Props> = ({
  children,
  innerCircleScale = 356,
  outerCircleScale = 484,
}) => {
  const isMobile = useIsMobile()

  return (
    <OuterCircle scale={isMobile ? outerCircleScale * 0.73 : outerCircleScale}>
      <InnerCircle
        scale={isMobile ? innerCircleScale * 0.73 : innerCircleScale}
      >
        {children}
      </InnerCircle>
    </OuterCircle>
  )
}

const CenterFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const InnerCircle = styled(CenterFlex)<{ scale: number }>``

const OuterCircle = styled(CenterFlex)<{ scale: number }>`
  background-color: ${p => p.theme.BgOverlayElevation1};
  border-radius: 50%;
  width: ${p => p.scale + 'px'};
  height: ${p => p.scale + 'px'};
`
