import React, { useState, useEffect } from 'react'
import { useCensus } from '../CensusContext'
import { EnvVarConfig } from 'utils/envVarConfig'
import { Loading } from 'components/common'
import SyncIframe from './SyncIframe'
import { CensusEmbedSyncResult } from 'api/census/interfaces'

interface SyncEditWizardProps {
  syncId: number
  onSyncEdited: (data: CensusEmbedSyncResult) => void
}

const SyncEditWizard: React.FC<SyncEditWizardProps> = ({
  syncId,
  onSyncEdited,
}) => {
  const { censusClient } = useCensus()

  const [isLoading, setIsLoading] = useState(false)
  const [syncEditManagementLink, setSyncEditManagementLink] = useState<
    string | null
  >(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)

        const response = await censusClient?.createEditSyncManagementLink({
          syncId,
          redirectUri: EnvVarConfig.REACT_APP_CENSUS_EMBED_REDIRECT_URI,
        })

        if (!response?.data.uri) {
          throw new Error('No edit sync management URL found')
        }

        setSyncEditManagementLink(response.data.uri)

        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        // eslint-disable-next-line no-console
        console.error('Error fetching sync edit management link:', error)
      }
    }

    fetchData()
  }, [censusClient])

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && syncEditManagementLink && (
        <SyncIframe
          connectLink={syncEditManagementLink}
          onExit={onSyncEdited}
        />
      )}
    </>
  )
}

export default SyncEditWizard
