import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Compare: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.53033 2.46967C6.23744 2.17678 5.76256 2.17678 5.46967 2.46967L1.46967 6.46967C1.17678 6.76256 1.17678 7.23744 1.46967 7.53033C1.76256 7.82322 2.23744 7.82322 2.53033 7.53033L5.25005 4.81061V14C5.25005 14.4142 5.58583 14.75 6.00005 14.75C6.41426 14.75 6.75005 14.4142 6.75005 14V4.81071L9.46967 7.53033C9.76256 7.82322 10.2374 7.82322 10.5303 7.53033C10.8232 7.23744 10.8232 6.76256 10.5303 6.46967L6.53033 2.46967ZM14.5303 17.5303C14.2374 17.8232 13.7626 17.8232 13.4697 17.5303L9.46967 13.5303C9.17678 13.2374 9.17678 12.7626 9.46967 12.4697C9.76257 12.1768 10.2374 12.1768 10.5303 12.4697L13.25 15.1893L13.25 6C13.25 5.58579 13.5857 5.25 14 5.25C14.4142 5.25 14.75 5.58579 14.75 6L14.7499 15.1894L17.4697 12.4697C17.7626 12.1768 18.2374 12.1768 18.5303 12.4697C18.8232 12.7626 18.8232 13.2374 18.5303 13.5303L14.5303 17.5303Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
