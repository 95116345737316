import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { standardIcons } from '../icons'
import { useTheme } from '@chordco/component-library'
import { Button } from '../controls'

export interface ToastProps {
  id: number | string
  title: string
  description?: string
  type?: 'alert' | 'success' | 'caution' | 'error'
  onClose: any
  buttons?: {
    id: number
    text: string
    onClick: Function
  }[]
  bottom: number
}

export const Toast: React.FC<ToastProps> = ({
  title,
  description,
  type = 'alert',
  onClose,
  buttons,
  bottom,
}) => {
  const theme = useTheme()

  const types = {
    alert: {
      icon: standardIcons.AlertInfo,
      backgroundColor: theme.GREY_20,
    },
    success: {
      icon: standardIcons.CheckCircle,
      backgroundColor: theme.PRIMARY_20,
    },
    caution: {
      icon: standardIcons.AlertTriangle,
      backgroundColor: theme.RED_20,
    },
    error: {
      icon: standardIcons.AlertWarning,
      backgroundColor: theme.RED_20,
    },
  }

  const [show, setShow]: [boolean, any] = useState(false)

  const onExit = () => {
    setShow(false)
    setTimeout(() => onClose(), 200)
  }

  useEffect(() => {
    setTimeout(() => setShow(true))
    setTimeout(() => onExit(), 5000)
  }, [])

  const { icon: Icon, backgroundColor } = types[type]

  const Exit = standardIcons.Exit

  return (
    <Container show={show} bottom={bottom}>
      <Type backgroundColor={backgroundColor}>
        <Icon />
      </Type>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
      <ExitContainer onClick={onExit}>
        <Exit />
      </ExitContainer>
      {buttons && (
        <ButtonContainer>
          {buttons.map(({ id, text, onClick }: any) => (
            <Button key={id} onClick={onClick} size="small" purpose="ghost">
              {text}
            </Button>
          ))}
        </ButtonContainer>
      )}
    </Container>
  )
}

const Container = styled.div<{ show: boolean; bottom: number }>`
  position: absolute;
  right: ${p => (p.show ? '24px' : '-432px')};
  bottom: ${p => p.bottom}px;
  box-sizing: border-box;
  width: 432px;
  border-radius: 16px;
  margin-bottom: 15px;
  padding: 16px 44px 20px 88px;
  background-color: ${p => p.theme.WHITE};

  transition: 200ms;
  transition-timing-function: cubic-bezier(0.5, 0, 0.25, 0.99);
`

const Type = styled.div<{
  backgroundColor: string
}>`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 16px 0 0 16px;
  width: 72px;
  height: 100%;
  background-color: ${p => p.backgroundColor};

  svg {
    position: absolute;
    top: calc(50% - 10px);
    right: calc(50% - 10px);
  }
`

const Title = styled.div`
  font-weight: 600;
  line-height: 20px;
`

const Description = styled.div`
  color: ${p => p.theme.GREY_60};
  line-height: 20px;
`

const ButtonContainer = styled.div`
  margin-top: 15px;
  display: flex;
`

const ExitContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 24px;
  width: 24px;
  padding: 2px;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;

  a {
    color: ${p => p.theme.GREY_80};
  }

  :hover {
    background-color: ${p => p.theme.GREY_80}14;
  }

  :active {
    background-color: ${p => p.theme.GREY_80}28;
  }
`
