import { ChipColor } from '@chordco/component-library'
import { Role } from 'types'

interface UserRoleDisplay {
  text: string
  color: ChipColor
  variant: 'outlined' | 'solid' | 'solid-secondary'
}

const userRoleDisplayMap: Record<Role, UserRoleDisplay> = {
  admin: {
    text: 'Team Admin',
    color: 'secondary',
    variant: 'solid-secondary',
  },
  oms_admin: {
    text: 'Order Admin',
    color: 'lightLimeGreen',
    variant: 'solid-secondary',
  },
  no_role: {
    text: 'Member',
    color: 'neutral',
    variant: 'solid-secondary',
  },
  superuser: {
    text: 'Super Admin',
    color: 'lightpink',
    variant: 'solid-secondary',
  },
  data_admin: {
    text: 'Data Admin',
    color: 'orange',
    variant: 'solid-secondary',
  },
}

export const useUserRoleStateDisplay = (userRole: Role): UserRoleDisplay => {
  return (
    // Default in case of any undefined roles
    userRoleDisplayMap[userRole] || {
      text: 'Undefined',
      color: 'neutral',
      variant: 'solid-secondary',
    }
  )
}