import semanticDark from './variables/semantic/dark.json'
import semanticLight from './variables/semantic/light.json'
import corners from './variables/corners.json'
import { v1Theme } from './v1'

export { semanticDark, semanticLight }

export const lightTheme = {
  ...semanticLight,
  ...v1Theme,
  ...corners,
}

export const darkTheme = {
  ...semanticDark,
  ...v1Theme,
  ...corners,
}

export type Palette =
  | 'neutral'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'info'
  | 'success'
  | 'error'
  | 'warning'
export type Theme = typeof lightTheme
