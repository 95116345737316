import React from 'react'
import { DashboardEmbed } from './DashboardEmbed'
import useEmbedUrl from './useEmbedUrl'
import styled from 'styled-components'

/**
 * This component silently initializes a Looker embed user by loading an invisible iframe that
 * triggers the creation of the embed user and associated resources, such as their personal folder.
 * It is used to ensure the embed user is properly set up without requiring user interaction.
 */
export const EmbedUserAutoCreate = () => {
  // All plans use the same dashboard slug for the business overview dashboard
  // This is the dashboard that is used to create the embed user.
  const dashboardSlug = 'business_overview'

  const { embedUrls, isLoading } = useEmbedUrl(dashboardSlug, 'dashboard')

  return (
    <HiddenContainer>
      {!isLoading && embedUrls[dashboardSlug] && (
        <DashboardEmbed url={embedUrls[dashboardSlug]} />
      )}
    </HiddenContainer>
  )
}

const HiddenContainer = styled.div`
  display: none;
`
