import { useSelector } from 'react-redux'
import { createSlice } from '@reduxjs/toolkit'
import { reduxApiClient } from 'redux/api'
import { RootState } from 'redux/store'
import { User, UsersState } from 'types'
import { useStateSliceAndDispatch } from '../utils'

const SLICE_NAME = 'users'

const initialState: UsersState = { users: [] }

const { inviteUser, getUsers, updateUser, deleteUser } = reduxApiClient

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: { resetUsers: () => initialState },
  extraReducers: builder => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users = action.payload.data
    })

    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.users = state.users.filter(u => u.id !== action.meta.arg)
    })

    builder.addCase(inviteUser.fulfilled, (state, action) => {
      const { id } = action.payload.data
      const { role, email } = action.meta.arg
      const newUser: User = {
        id,
        role,
        email,
        firstName: '',
        lastName: '',
        photo: '',
        lastSignInAt: null,
      }
      state.users = [...state.users, newUser]
    })

    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.users = state.users.map(u => {
        if (u.id !== action.meta.arg.id) return u

        return { ...u, ...action.meta.arg }
      })
    })
  },
})

export default usersSlice.reducer

export const useUsersData = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)

  const { currentTenant } = useSelector((state: RootState) => state.tenants)

  return {
    state,
    getUsers: () => {
      const tenantId = currentTenant?.id
      if (!tenantId) return

      dispatch(getUsers(tenantId))
    },
    deleteUser: (id: number) => dispatch(deleteUser(id)),
    updateUser: (user: User) => dispatch(updateUser(user)),
  }
}
