import { createSlice } from '@reduxjs/toolkit'
import { useStateSliceAndDispatch } from '../utils'

const SLICE_NAME = 'reportsFilter'

const initialState = {
  selectedFilter: 'all_reports',
}

const reportsFilterSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setFilter(state, action) {
      state.selectedFilter = action.payload
    },
  },
})

export const { setFilter } = reportsFilterSlice.actions

export default reportsFilterSlice.reducer

export const useReportsFilter = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)

  return {
    state,
    setFilter: (filter: string) => {
      dispatch(setFilter(filter))
    },
  }
}
