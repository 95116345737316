import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@chordco/component-library'

export type FeatureIconColor =
  | 'pink'
  | 'limeGreen'
  | 'brightBlue'
  | 'blue'
  | 'green'
  | 'red'
  | 'black'

interface Props {
  size?: 16 | 24 | 32 | 40 | 80
  icon: any
  color?: FeatureIconColor
  active?: boolean
  style?: {}
  className?: string
}

const iconScale = {
  80: '40px',
  40: '20px',
  32: '20px',
  24: '16px',
  16: '10px',
}

export const FeatureIcon: React.FC<Props> = ({
  size = 32,
  icon: Icon,
  color = 'maui',
  active = true,
  style,
  className,
}) => {
  const theme = useTheme()

  const colors = {
    pink: theme.PRIMARY_50,
    limeGreen: theme.LIME_GREEN_60,
    brightBlue: theme.BRIGHT_BLUE_60,
    blue: theme.BLUE_50,
    green: theme.GREEN_70_BASE,
    red: theme.RED_70,
    black: theme.BLACK,
  }

  return (
    <Container
      size={size}
      backgroundColor={active ? colors[color] : 'inherit'}
      data-testid="featureIcon"
      style={style}
      className={className}
    >
      <Icon light={active} scale={iconScale[size]} />
    </Container>
  )
}

const Container = styled.div<{
  size: 16 | 24 | 32 | 40 | 80
  backgroundColor: string
}>`
  display: flex;
  align-items: center;
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  border-radius: 24%;
  background-color: ${p => p.backgroundColor};

  svg {
    margin: 0 auto;
  }
`
