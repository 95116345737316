import styled, { keyframes } from 'styled-components'

const swipe = keyframes`
 0%{
    background-position: -400px 0
}
  100%{
    background-position: 400px 0
}
`

export const Base = styled.div`
  background-color: ${p => p.theme.GREY_20};
  animation-name: ${swipe};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, ${p => p.theme.GREY_30}),
    color-stop(18%, ${p => p.theme.GREY_20}),
    color-stop(33%, ${p => p.theme.GREY_30})
  );
  background: -webkit-linear-gradient(
    left,
    ${p => p.theme.GREY_30} 8%,
    ${p => p.theme.GREY_20} 18%,
    ${p => p.theme.GREY_30} 33%
  );
  background: linear-gradient(
    to right,
    ${p => p.theme.GREY_30} 8%,
    ${p => p.theme.GREY_20} 18%,
    ${p => p.theme.GREY_30} 33%
  );
  -webkit-background-size: 800px 104px;
`
