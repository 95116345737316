import useMixpanel from 'hooks/useMixpanel'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

interface PageViewTrackerProps {
  children?: React.ReactNode
}

const PageViewTracker: React.FC<PageViewTrackerProps> = ({ children }) => {
  const history = useHistory()

  const { trackPageView } = useMixpanel()

  useEffect(() => {
    // To track the first pageview upon load
    trackPageView()

    // To track the subsequent pageviews
    const unlisten = history.listen(() => {
      trackPageView()
    })

    // Cleanup the listener on unmount or when history changes
    return () => {
      unlisten()
    }
  }, [history, trackPageView])

  return <React.Fragment>{children}</React.Fragment>
}

export default PageViewTracker
