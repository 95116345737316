import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Loading } from '../common'
import { useSelectedTheme } from 'themes/ThemeSwitcherProvider'
import { useSolidusData } from 'redux/hooks'
import { useHistory } from 'react-router-dom'
import { getEmbeddedOmsUrl } from 'utils/embeddedOmsUrls'
import { startPingInterval, stopPingInterval } from 'utils/ping'

export const EmbeddedOmsPage = () => {
  const theme = useSelectedTheme()
  const history = useHistory()
  const { baseUrl } = useSolidusData()

  const iframeRef = useRef<HTMLIFrameElement | null>(null)

  const [iframeLoaded, setIframeLoaded] = useState(false)

  // Shortcut function to get the embedded iframe URL.
  const getUrl = () => getEmbeddedOmsUrl(history.location, baseUrl)

  const [embeddedUrl, setEmbeddedUrl] = useState(() => getUrl())

  // Used to determine when the iframe has been loaded.
  useEffect(() => {
    const handleIframeLoad = () => setIframeLoaded(true)

    const iframeElement = iframeRef.current
    if (iframeElement) {
      iframeElement.addEventListener('load', handleIframeLoad)

      // start ping requests when the component mounts, to keep the OMS user session alive
      const intervalId = startPingInterval(embeddedUrl.href)

      return () => {
        // Remove the event listener on unmount
        iframeElement.removeEventListener('load', handleIframeLoad)

        // stop ping requests when the component unmounts
        stopPingInterval(intervalId)
      }
    }
  }, [])

  // Used to send the theme to the iframe when it has been loaded.
  useEffect(() => {
    if (!iframeLoaded || !theme || !embeddedUrl) return

    const iframe = iframeRef.current?.contentWindow
    const themeKey = theme.key

    if (!iframe || !themeKey) return

    const message = {
      type: 'theme',
      value: themeKey,
    }

    iframe.postMessage(message, embeddedUrl.origin)
  }, [iframeLoaded, theme, embeddedUrl])

  // Watch for changes in the baseUrl. It happens when users switch tenants
  // or store environments.
  useEffect(() => setEmbeddedUrl(getUrl()), [baseUrl])

  // Watch for changes in the embeddedUrl, and reload the iframe src accordingly.
  useEffect(() => {
    if (embeddedUrl) {
      const iframeElement = iframeRef.current
      if (iframeElement) {
        iframeElement.src = embeddedUrl.href

        // Reset the iframeLoaded state since we are loading a new URL.
        setIframeLoaded(false)
      }
    }
  }, [embeddedUrl])

  return (
    <React.Fragment>
      {!iframeLoaded && <Loading />}
      <IFrame
        name="oms-iframe"
        ref={iframeRef}
        src={embeddedUrl?.href}
        style={{
          visibility: iframeLoaded ? 'visible' : 'hidden',
        }}
      />
    </React.Fragment>
  )
}

const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`
