function obfuscateString(
  input: string,
  numVisibleChars = 4,
  totalLength = 8,
  obfuscationChar = '*'
): string {
  const visiblePart = input.slice(-numVisibleChars)
  const obfuscationLength = totalLength - visiblePart.length
  const obfuscation = obfuscationChar.repeat(obfuscationLength)
  return obfuscation + visiblePart
}

export { obfuscateString }
