import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Bullet: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 4C7.25 3.58579 7.58579 3.25 8 3.25H17C17.4142 3.25 17.75 3.58579 17.75 4C17.75 4.41421 17.4142 4.75 17 4.75H8C7.58579 4.75 7.25 4.41421 7.25 4Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M5 4C5 4.55228 4.55228 5 4 5C3.44772 5 3 4.55228 3 4C3 3.44772 3.44772 3 4 3C4.55228 3 5 3.44772 5 4Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 10C7.25 9.58579 7.58579 9.25 8 9.25H17C17.4142 9.25 17.75 9.58579 17.75 10C17.75 10.4142 17.4142 10.75 17 10.75H8C7.58579 10.75 7.25 10.4142 7.25 10Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M5 10C5 10.5523 4.55228 11 4 11C3.44772 11 3 10.5523 3 10C3 9.44772 3.44772 9 4 9C4.55228 9 5 9.44772 5 10Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 16C7.25 15.5858 7.58579 15.25 8 15.25H17C17.4142 15.25 17.75 15.5858 17.75 16C17.75 16.4142 17.4142 16.75 17 16.75H8C7.58579 16.75 7.25 16.4142 7.25 16Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M5 16C5 16.5523 4.55228 17 4 17C3.44772 17 3 16.5523 3 16C3 15.4477 3.44772 15 4 15C4.55228 15 5 15.4477 5 16Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
