import * as yup from 'yup'
import { Message } from 'yup/lib/types'

const lowerCaseRegex = /(?=.*[a-z])/
const upperCaseRegex = /(?=.*[A-Z])/
const numberRegex = /(?=.*\d)/
const phoneRegExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/

export const valTypes: { [key: string]: ValueType } = {
  string: 'string',
  number: 'number',
  email: 'email',
  password: 'password',
  phone: 'phone',
  boolean: 'boolean',
  array: 'array',
  url: 'url',
}

export type ValueType =
  | 'string'
  | 'number'
  | 'email'
  | 'password'
  | 'phone'
  | 'boolean'
  | 'array'
  | 'url'

export type ValSchemaField = {
  type: ValueType
  initialValue?: number | string | boolean | (number | string | object)[]
  required?: boolean
  requiredMessage?: string
  typeMessage?: Message
  min?: number
  max?: number
  label?: string
  subLabel?: string
  hint?: string
  customVal?: (fields: any) => string | undefined
}

export type ValSchema = { [key: string]: ValSchemaField }

export type ValSchemaGeneric<T extends string[]> = {
  [key in T[number]]: ValSchemaField
}

export const generateYupChain = (valSchema: ValSchema) => {
  const validationObjects: any = {}

  Object.keys(valSchema).forEach((key: string) => {
    const field = valSchema[key]
    let yupChain

    switch (field.type) {
      case valTypes.email:
        yupChain = yup
          .string()
          .email()
          .typeError(field.typeMessage || 'Please enter a valid email')
        break

      case valTypes.url:
        yupChain = yup
          .string()
          .matches(urlRegex, {
            message: 'Please enter a valid URL',
            excludeEmptyString: true,
          })
          .typeError(field.typeMessage || 'Please enter a valid URL')
        break

      case valTypes.password:
        yupChain = yup
          .string()
          .required()
          .min(6)
          .matches(lowerCaseRegex, {
            message: 'Password must contain a lowercase character',
          })
          .matches(upperCaseRegex, {
            message: 'Password must contain an uppercase character',
          })
          .matches(numberRegex, { message: 'Password must contain a number' })
          .typeError(field.typeMessage || 'Please enter a valid password')
        break

      case valTypes.number:
        yupChain = yup
          .number()
          .typeError(field.typeMessage || 'This is required')
        break

      case valTypes.phone:
        // only checks for phone regex if field is not empty
        yupChain = yup.lazy(value =>
          value
            ? yup.string().matches(phoneRegExp, 'Phone number is not valid')
            : yup.string()
        )
        break

      case valTypes.array:
        yupChain = yup.array()

        if (!field.required) {
          yupChain = yupChain.nullable()
        }
        break

      case valTypes.string:
      default:
        yupChain = yup
          .string()
          .typeError(field.typeMessage || 'This is required')
    }

    if (field.required) {
      yupChain = yupChain.required(`${field.label} is required`)
    }

    if (field.min) {
      yupChain = yupChain.min(
        field.min,
        `${field.label} must be at least ${field.min}`
      )
    }

    if (field.max) {
      yupChain = yupChain.max(
        field.max,
        `${field.label} cannot be more than ${field.max} characters`
      )
    }

    if (field.customVal) {
      yupChain = yupChain.customValidation(field.customVal)
    }

    validationObjects[key] = yupChain
  })

  return yup.object().shape(validationObjects)
}

yup.addMethod(yup.mixed, 'customValidation', function (customVal) {
  return this.test('customValidation', 'an error occurred', function () {
    const fields = this.parent

    const message = customVal(fields)
    if (message) return this.createError({ message })

    return true
  })
})
