import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Help: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99984 2.41667C5.81168 2.41667 2.4165 5.81185 2.4165 10C2.4165 14.1882 5.81168 17.5833 9.99984 17.5833C14.188 17.5833 17.5832 14.1882 17.5832 10C17.5832 5.81185 14.188 2.41667 9.99984 2.41667ZM0.916504 10C0.916504 4.98342 4.98325 0.916672 9.99984 0.916672C15.0164 0.916672 19.0832 4.98342 19.0832 10C19.0832 15.0166 15.0164 19.0833 9.99984 19.0833C4.98325 19.0833 0.916504 15.0166 0.916504 10Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2294 6.6048C9.82205 6.53493 9.40314 6.61148 9.04684 6.82088C8.69053 7.03028 8.41984 7.35902 8.2827 7.74888C8.14524 8.13963 7.71705 8.34496 7.32631 8.2075C6.93557 8.07005 6.73024 7.64186 6.86769 7.25112C7.12239 6.52709 7.62511 5.91657 8.28681 5.52768C8.94851 5.13879 9.7265 4.99664 10.483 5.12639C11.2394 5.25615 11.9256 5.64944 12.4199 6.23661C12.914 6.82361 13.1845 7.56651 13.1835 8.3338C13.1833 9.56502 12.2698 10.3856 11.5996 10.8324C11.2416 11.071 10.8889 11.2468 10.6288 11.3624C10.4976 11.4207 10.3873 11.4649 10.3079 11.4951C10.2682 11.5103 10.2361 11.522 10.2129 11.5302L10.1847 11.5401L10.1759 11.5431L10.1728 11.5441L10.1716 11.5445C10.1716 11.5445 10.1707 11.5448 9.93353 10.8333L10.1707 11.5448C9.77774 11.6758 9.353 11.4635 9.22202 11.0705C9.09119 10.678 9.30288 10.2538 9.69492 10.1223L9.69636 10.1218L9.92429 10.8056C9.69635 10.1218 9.69636 10.1218 9.69636 10.1218L9.71035 10.1169C9.72371 10.1122 9.74531 10.1043 9.77395 10.0934C9.83136 10.0715 9.91633 10.0376 10.0195 9.99173C10.2281 9.89903 10.5005 9.76231 10.7675 9.5843C11.3472 9.19783 11.6835 8.76852 11.6835 8.33334L11.6835 8.33222C11.6841 7.91894 11.5385 7.51878 11.2723 7.20261C11.0062 6.88644 10.6367 6.67467 10.2294 6.6048Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25 14.1667C9.25 13.7525 9.58579 13.4167 10 13.4167H10.0083C10.4225 13.4167 10.7583 13.7525 10.7583 14.1667C10.7583 14.5809 10.4225 14.9167 10.0083 14.9167H10C9.58579 14.9167 9.25 14.5809 9.25 14.1667Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
