import React from 'react'
import shipheroLogo from 'assets/ShipHeroLogo.png'
import { useForm } from 'hooks'
import { FormInput } from 'components/common'
import { ValSchemaGeneric } from 'validationSchemas'
import { Fields } from '../common'
import { Integration, ShipheroSettings } from 'types'
import { useIntegrationForm } from 'redux/hooks'
import { getFulfillmentConfirmationMessage } from 'utils/confirmationMessages'
import { MultipleProviderForm } from '../MultipleProviderForm'

type Props = {
  onChangeProvider: () => void
  onClose: () => void
  integration?: Integration<ShipheroSettings>
}

const SHIPHERO_LOGIN_LINK = 'https://app.shiphero.com/account/login'
const getSchema = (
  settings?: ShipheroSettings
): ValSchemaGeneric<['authToken', 'refreshToken']> => ({
  authToken: {
    label: 'Auth Token',
    type: 'string',
    initialValue: settings?.authToken,
  },
  refreshToken: {
    label: 'Refresh Token',
    type: 'string',
    initialValue: settings?.refreshToken,
  },
})

export const ShipheroForm: React.FC<Props> = ({
  onChangeProvider,
  onClose,
  integration,
}) => {
  const { submit, isLoading } = useIntegrationForm(
    'ship_hero',
    integration,
    onClose
  )

  const schema = getSchema(integration?.settings)
  const { fields, onSubmit } = useForm(schema, submit)

  const integrationTitle = 'Shiphero'
  const confirmationMessage =
    getFulfillmentConfirmationMessage(integrationTitle)

  return (
    <MultipleProviderForm
      integrationType="ship_hero"
      integrationTitle={integrationTitle}
      logo={shipheroLogo}
      loginLink={SHIPHERO_LOGIN_LINK}
      confirmationMessage={confirmationMessage}
      onChangeProvider={onChangeProvider}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <Fields>
        <FormInput field={fields.authToken} customWidth="100%" />
        <FormInput field={fields.refreshToken} customWidth="100%" />
      </Fields>
    </MultipleProviderForm>
  )
}
