import React from 'react'
import { standardIcons, AlertModal } from '@chordco/component-library'

const { AlertWarning } = standardIcons

type ConfirmModalProps = {
  title: string
  subtitle?: string
  closeText?: string
  confirmText?: string
  onConfirm: () => void
  onClose: () => void
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title,
  subtitle,
  closeText,
  confirmText,
  onConfirm,
  onClose,
}) => {
  return (
    <AlertModal
      onClose={onClose}
      icon={AlertWarning}
      content={title}
      subContent={subtitle}
      centered
      buttons={[
        {
          id: 1,
          text: closeText || 'Cancel',
          onClick: onClose,
          purpose: 'tertiary',
          disabled: false,
        },
        {
          id: 2,
          text: confirmText || 'Confirm',
          onClick: onConfirm,
          purpose: 'destructive',
          isLoading: false,
          disabled: false,
        },
      ]}
    />
  )
}
