import styled, { css } from 'styled-components'
import { Size, ButtonProps } from '../Button'

export const IconContainer = styled.span<{
  size?: Size
}>`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Trailing = styled(IconContainer)<{
  scale: number
  flipTrailingIcon?: boolean
}>`
  height: ${p => p.scale || 16}px;
  width: ${p => p.scale || 16}px;
  right: ${p => {
    if (p.size === 'tiny' || p.size === 'small') return '8px'
    else if (p.size === 'medium') return '14px'
    else if (p.size === 'large') return '16px'
  }};
  color: black;
  transition: 200ms;
  transform: rotate(${p => (p.flipTrailingIcon ? '180deg' : '0deg')});
`

export const SpinnerContainer = styled.span<{
  size?: Size
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

export const Text = styled.span<{
  size?: Size
  hasIcon?: boolean
  hasText?: boolean
  isRight?: boolean
  centreContent?: boolean
}>`
  ${p => (p.centreContent ? '' : 'flex: 1;')}
  ${p => (!p.hasText ? 'display: none;' : 'padding: 8px;')}
`

export const ButtonBase = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  border-radius: 32px;
  border: none;
  font-weight: 500;
  line-height: initial;
  cursor: pointer;
  outline: 0;
  border: 1px solid transparent;

  path {
    fill: ${p => p.theme.ContentPrimary};
  }

  :focus {
    outline: 4px solid ${p => p.theme.ComponentButtonBorderFocused};
  }

  :active {
    transform: scale(0.98);
  }

  :disabled {
    cursor: not-allowed;
    color: ${p => p.theme.ComponentButtonContentDisabled};
  }

  ${p =>
    p.isLoading &&
    css`
      color: transparent;
    `}

  /* Size */
  ${p => {
    if (p.size === 'tiny') {
      return css`
        height: 24px;
        font-size: 12px;
        padding-top: 1px;
      `
    } else if (p.size === 'small') {
      return css`
        height: 32px;
        font-size: 12px;
        padding: ${p.purpose === 'secondary' ? '0 7px' : '0 7px'};
      `
    } else if (p.size === 'medium') {
      return css`
        height: 40px;
        font-size: 14px;
        padding: ${p.purpose === 'secondary' ? '0 11px' : '0 11px'};
      `
    } else if (p.size === 'large') {
      return css`
        height: 56px;
        font-size: 14px;
        padding: ${p.purpose === 'secondary' ? '0 16px' : '0 16px'};
      `
    }
  }}

  /* Colors */
  ${p => {
    switch (p.color) {
      case 'primary':
        return css`
          color: ${p.theme.ComponentButtonPrimaryContent};
          background-color: ${p.theme.ComponentButtonPrimaryBgDefault};
          border-color: ${p.theme.ComponentButtonPrimaryBgDefault};

          :hover {
            background-color: ${p.theme.ComponentButtonPrimaryBgHover};
            border-color: ${p.theme.ComponentButtonPrimaryBgHover};
          }

          :active {
            background-color: ${p.theme.ComponentButtonPrimaryBgPressed};
            border-color: ${p.theme.ComponentButtonPrimaryBgPressed};
          }
        `
      case 'secondary':
        return css``
      case 'tertiary':
        return css``
      case 'info':
        return css``
      case 'success':
        return css``
      case 'error':
        return css`
          color: ${p.theme.ComponentButtonPrimaryContent};
          background-color: ${p.theme.ComponentButtonDeleteBgDefault};
          border-color: ${p.theme.ComponentButtonDeleteBgDefault};

          :hover {
            background-color: ${p.theme.ComponentButtonDeleteBgHover};
            border-color: ${p.theme.ComponentButtonDeleteBgHover};
          }

          :focus {
            outline-color: ${p.theme.ComponentButtonDeleteBorderFocused};
          }
        `
      case 'warning':
        return css``
      case 'neutral':
        return css`
          color: ${p.theme.ContentPrimary};
          background-color: ${p.theme.BgOverlayElevation1};
          border-color: ${p.theme.BorderOptimal};

          :hover {
            background-color: ${p.theme.BgOverlayElevation2};
            border-color: ${p.theme.BorderOptimal};
          }

          :active {
            background-color: ${p.theme.BgOverlayElevation3};
            border-color: ${p.theme.BorderOptimal};
          }
        `
    }
  }}

  /* Variant */
  ${p => {
    // @TODO Add hover and focus and whatnot
    switch (p.variant) {
      case 'solid':
        return css`
          path {
            fill: ${p => p.theme.ComponentButtonPrimaryContent};
          }

          :disabled {
            background-color: ${p.theme.ComponentButtonBgDisabled};
          }

          ${p.disabled &&
          css`
            background-color: ${p.theme.ComponentButtonBgDisabled};
          `}
        `
      case 'outlined':
        return css`
          color: ${p.theme.ComponentButtonSecondaryContent};
          background-color: transparent;

          :hover {
            background-color: ${p.theme.ComponentButtonTertiaryBgHover};
          }

          :active {
          }

          :focus {
            border-color: transparent;
          }
        `
      case 'ghost':
        return css`
          color: ${p.theme.ComponentButtonGhostContentPrimary};
          background-color: transparent;
          border-color: transparent;

          path {
            fill: ${p => p.theme.ComponentButtonGhostContentPrimary};
          }

          :hover {
            background-color: ${p.theme.ComponentButtonTertiaryBgHover};
            border-color: transparent;

            path {
              fill: ${p => p.theme.ComponentButtonGhostContentPrimary};
            }
          }
        `

      case 'tertiary':
        return css`
          color: ${p.theme.ComponentButtonTertiaryContent};
          background-color: transparent;
          border-color: ${p.theme.ComponentButtonTertiaryBorderDefault};

          path {
            fill: ${p => p.theme.ComponentButtonTertiaryContent};
          }

          :hover {
            background-color: ${p.theme.ComponentButtonTertiaryBgHover};
            border-color: ${p.theme.ComponentButtonTertiaryBgHover};

            path {
              fill: ${p => p.theme.ComponentButtonGhostContentPrimary};
            }
          }
        `
    }
  }}

  /* Disabled state should be last to override other color styles */
  ${p =>
    p.disabled &&
    css`
      cursor: not-allowed;
      color: ${p.theme.ComponentButtonContentDisabled};
      border-color: ${p.theme.ComponentButtonContentDisabled};

      path {
        fill: ${p => p.theme.ComponentButtonContentDisabled};
      }

      :hover {
        color: ${p.theme.ComponentButtonContentDisabled};
        border-color: ${p.theme.ComponentButtonContentDisabled};

        path {
          fill: ${p => p.theme.ComponentButtonContentDisabled};
        }
      }
    `}
`

// @TODO: Remove this once we are all in on v2
const Button = styled.button<any>`
  position: relative;
  box-sizing: border-box;
  border-radius: 32px;
  border: none;
  font-weight: 500;
  line-height: initial;
  cursor: pointer;
  outline: 0;
  padding: ${p => (p.purpose === 'secondary' ? '0 7px' : '0 8px')};

  path {
    fill: ${p => p.theme.ContentPrimary};
  }

  :focus {
    box-shadow: ${({ tabbed, theme }) => (tabbed ? theme.FOCUS : 'none')};
  }

  :active {
    transform: scale(0.98);
  }

  ${p => {
    if (p.size === 'tiny') {
      return css`
        height: 24px;
        font-size: 12px;
        padding-top: 1px;
      `
    } else if (p.size === 'small') {
      return css`
        height: 32px;
        font-size: 12px;
        padding: ${p.purpose === 'secondary' ? '0 8px' : '0 8px'};
      `
    } else if (p.size === 'medium') {
      return css`
        height: 40px;
        font-size: 14px;
        padding: ${p.purpose === 'secondary' ? '0 12px' : '0 12px'};
      `
    } else if (p.size === 'large') {
      return css`
        height: 56px;
        font-size: 14px;
        padding: ${p.purpose === 'secondary' ? '0 16px' : '0 16px'};
      `
    }
  }}
`

const disabledStyles = ({ disabled }: { disabled?: boolean }) => {
  if (disabled) {
    return css`
      opacity: 0.5;
      cursor: not-allowed;
    `
  }
}

const loadingStyles = ({ isLoading }: { isLoading?: boolean }) => {
  if (isLoading) {
    return css`
      color: transparent;
    `
  }
}

// @TODO: Remove this once we are all in on v2
export const PrimaryButton = styled(Button as any)`
  color: ${p => p.theme.ComponentButtonPrimaryContent};
  background-color: ${p => p.theme.ComponentButtonPrimaryBgDefault};

  path {
    fill: ${p => p.theme.ComponentButtonPrimaryContent};
  }

  :hover {
    background-color: ${p => p.theme.ComponentButtonPrimaryBgHover};
  }

  :active {
    background-color: ${p => p.theme.ComponentButtonPrimaryBgPressed};
  }

  ${p =>
    p.selected &&
    css`
      background-color: ${p => p.theme.ComponentButtonPrimaryBgPressed};
    `}

  ${props => disabledStyles(props)}
  ${props => loadingStyles(props)}
`

export const SecondaryButton = styled(Button)`
  color: ${p => p.theme.ComponentButtonSecondaryContent};
  border: 1px solid ${p => p.theme.ComponentButtonSecondaryBorderDefault};
  background-color: inherit;

  :hover {
    background-color: ${p => p.theme.ComponentButtonSecondaryBgHover};
  }

  :active {
    border-color: ${p => p.theme.ComponentButtonSecondaryBorderDefault};
    background-color: ${p => p.theme.ComponentButtonSecondaryBgHover};
  }

  ${p =>
    p.selected &&
    css`
      border-color: ${p => p.theme.ComponentButtonSecondaryBgHover};
      background-color: ${p => p.theme.ComponentButtonSecondaryBgHover};
    `}

  ${props => disabledStyles(props)}
  ${props => loadingStyles(props)}
`

// @TODO: Remove this once we are all in on v2
export const GhostButton = styled(Button)`
  color: ${p => p.theme.ComponentButtonGhostContentPrimary};
  background-color: inherit;

  path {
    fill: ${p => p.theme.ComponentButtonGhostContentPrimary};
  }

  :hover {
    background-color: ${p => p.theme.ComponentButtonTertiaryBgHover};

    path {
      fill: ${p => p.theme.ComponentButtonGhostContentPrimary};
    }
  }

  :active {
    background-color: ${p => p.theme.ComponentButtonTertiaryBgPressed};

    path {
      fill: ${p => p.theme.ComponentButtonGhostContentPrimary};
    }
  }

  ${p =>
    p.selected &&
    css`
      color: ${p => p.theme.ComponentButtonGhostContentPrimary};

      path {
        fill: ${p => p.theme.ComponentButtonGhostContentPrimary};
      }
    `}

  ${props => disabledStyles(props)}
  ${props => loadingStyles(props)}
`

// @TODO: Remove this once we are all in on v2
export const DarkGhostButton = styled(GhostButton)`
  path {
    fill: ${p => p.theme.GREY_80};
  }

  :hover {
    path {
      fill: ${p => p.theme.GREY_80};
    }
  }
`

// @TODO: Remove this once we are all in on v2
export const LightGhostButton = styled(GhostButton)`
  path {
    fill: ${p => p.theme.GREY_1};
  }

  :hover {
    path {
      fill: ${p => p.theme.GREY_3};
    }
  }
`

// @TODO: Remove this once we are all in on v2
export const DestructiveButton = styled(Button)`
  color: ${p => p.theme.ContentPrimary};
  border: 1px solid ${p => p.theme.ComponentButtonDeleteBorder};
  background-color: inherit;

  :hover {
    background-color: ${p => p.theme.ComponentButtonTertiaryBgHover};
  }

  :active {
    background-color: ${p => p.theme.ComponentButtonTertiaryBgPressed};
  }

  ${p =>
    p.selected &&
    css`
      border-color: ${p => p.theme.ComponentButtonDeleteBorder};
    `}

  ${props => disabledStyles(props)}
  ${props => loadingStyles(props)}
`
