import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Compass: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4165 9.99999C2.4165 5.81183 5.81168 2.41666 9.99984 2.41666C14.188 2.41666 17.5832 5.81183 17.5832 9.99999C17.5832 14.1881 14.188 17.5833 9.99984 17.5833C5.81168 17.5833 2.4165 14.1881 2.4165 9.99999ZM9.99984 0.916656C4.98325 0.916656 0.916504 4.9834 0.916504 9.99999C0.916504 15.0166 4.98325 19.0833 9.99984 19.0833C15.0164 19.0833 19.0832 15.0166 19.0832 9.99999C19.0832 4.9834 15.0164 0.916656 9.99984 0.916656ZM14.245 6.70384C14.3348 6.43433 14.2647 6.13721 14.0638 5.93633C13.8629 5.73546 13.5658 5.66532 13.2963 5.75515L7.99631 7.52182C7.77236 7.59647 7.59662 7.77221 7.52197 7.99616L5.7553 13.2962C5.66547 13.5657 5.73561 13.8628 5.93649 14.0637C6.13736 14.2645 6.43449 14.3347 6.70399 14.2448L12.004 12.4782C12.2279 12.4035 12.4037 12.2278 12.4783 12.0038L14.245 6.70384ZM7.65267 12.3475L8.82641 8.82626L12.3476 7.65252L11.1739 11.1737L7.65267 12.3475Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
