import { useContext } from 'react'
import { MixpanelContext } from 'utils/mixpanel/provider'

const useMixpanel = () => {
  const context = useContext(MixpanelContext)

  if (context === null)
    throw new Error('useMixpanel must be used within a MixpanelProvider')

  const { trackEvent, trackPageView, identify, resetUser } = context

  return { trackEvent, trackPageView, identify, resetUser }
}

export default useMixpanel
