import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Play: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6742 9.15632C15.2917 9.54928 15.2917 10.4507 14.6742 10.8436L6.53688 16.022C5.87115 16.4456 5 15.9674 5 15.1783L5 4.82166C5 4.03257 5.87115 3.55435 6.53688 3.978L14.6742 9.15632Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
