import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Future: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9697 1.53033C13.6768 1.23744 13.6768 0.762563 13.9697 0.46967C14.2626 0.176777 14.7374 0.176777 15.0303 0.46967L17.0303 2.46967C17.3232 2.76256 17.3232 3.23744 17.0303 3.53033L15.0303 5.53033C14.7374 5.82322 14.2626 5.82322 13.9697 5.53033C13.6768 5.23744 13.6768 4.76256 13.9697 4.46967L14.6893 3.75H10C5.99594 3.75 2.75 6.99594 2.75 11C2.75 15.0041 5.99594 18.25 10 18.25C14.0041 18.25 17.25 15.0041 17.25 11C17.25 10.5858 17.5858 10.25 18 10.25C18.4142 10.25 18.75 10.5858 18.75 11C18.75 15.8325 14.8325 19.75 10 19.75C5.16751 19.75 1.25 15.8325 1.25 11C1.25 6.16751 5.16751 2.25 10 2.25H14.6893L13.9697 1.53033ZM9.25006 6.5C9.25006 6.08578 9.58585 5.75 10.0001 5.75C10.4143 5.75 10.7501 6.08578 10.7501 6.5V11C10.7501 11.4142 10.4143 11.75 10.0001 11.75H7.00006C6.58585 11.75 6.25007 11.4142 6.25007 11C6.25007 10.5858 6.58585 10.25 7.00006 10.25H9.25006V6.5Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
