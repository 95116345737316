import React, { ComponentProps, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { standardIcons } from '../icons'

type Size = 'small' | 'medium' | 'large'

interface Props extends Omit<ComponentProps<'input'>, 'size'> {
  checked: boolean
  size?: Size
}

const iconScale = {
  small: 8,
  medium: 12,
  large: 16,
}

const { ThickCheck } = standardIcons

export const Toggle: React.FC<Props> = ({ size = 'medium', ...props }) => {
  const [tabbed, setTabbed] = useState(false)
  const ref = useRef() as any

  const onChange = (e: any) => {
    setTabbed(false)
    // @ts-ignore
    props.onChange(e)
  }

  const onKeyDown = () => {
    setTabbed(true)
  }

  return (
    <Label size={size}>
      <Input
        type="checkbox"
        {...props}
        ref={ref}
        onChange={onChange}
        tabbed={tabbed}
        onKeyDown={onKeyDown}
        size={size}
      />
      <Slider disabled={props.disabled}>
        <CheckMark size={size}>
          <Margin size={size}>
            <ThickCheck scale={iconScale[size]} />
          </Margin>
        </CheckMark>
      </Slider>
    </Label>
  )
}

const Label = styled.label<{ size: Size }>`
  position: relative;
  display: inline-block;

  ${p => {
    if (p.size === 'small')
      return css`
        width: 24px;
        height: 16px;
      `
    if (p.size === 'medium')
      return css`
        width: 40px;
        height: 24px;
      `
    if (p.size === 'large')
      return css`
        width: 48px;
        height: 32px;
      `
  }};
`

const Slider = styled.span<{ disabled?: boolean }>`
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: ${p => p.theme.BLACK};
  border: solid 1px ${p => p.theme.WHITE};
  border-radius: 32px;
  transition: 100ms;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.GREY_4};
      border: solid 1px ${p => p.theme.GREY_3};
      cursor: auto;
    `}
`

const CheckMark = styled.div<{ size: 'small' | 'medium' | 'large' }>`
  box-sizing: border-box;
  position: absolute;
  content: '';

  border-radius: 50%;
  background-color: ${p => p.theme.WHITE};
  transition: 200ms;
  transform: rotate(-45deg);

  ${p => {
    if (p.size === 'small')
      return css`
        height: 12px;
        width: 12px;
        left: 1.5px;
        top: 1.5px;
      `
    if (p.size === 'medium')
      return css`
        padding: 1px 3px;
        height: 18px;
        width: 18px;
        left: 3px;
        bottom: 3px;
      `
    if (p.size === 'large')
      return css`
        padding: 5px;
        height: 24px;
        width: 24px;
        left: 4px;
        bottom: 4px;
      `
  }};

  svg {
    opacity: 0;
  }

  path {
    fill: ${p => p.theme.PRIMARY_50};
  }
`

const Input = styled.input<any>`
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;

  :checked + ${Slider} {
    background-color: ${p => p.theme.PRIMARY_50};
    border-color: ${p => p.theme.PRIMARY_50};

    ${CheckMark} {
      transform: translateX(${p => (p.size === 'small' ? 8 : 16)}px)
        rotate(0deg);

      svg {
        opacity: 1;
      }

      path {
        fill: ${p => p.theme.PRIMARY_50};
      }
    }
  }

  :focus + ${Slider} {
    outline: none;
    box-shadow: ${({ tabbed, theme }) => (tabbed ? theme.FOCUS : 'none')};
  }
`

const Margin = styled.div<{ size: 'small' | 'medium' | 'large' }>`
  margin: ${p => {
    if (p.size === 'small') return '-5px 0 0 2px;'
    if (p.size === 'medium') return '2.5px 0 0 0'
    if (p.size === 'large') return 'initial'
  }};
`
