import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const GenericAudience: React.FC<IconProps> = ({
  scale = 48,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4019 12.0458C17.7258 10.4594 20.4285 9.55635 23.2061 9.41155L23.2133 11.9152C23.1484 11.9192 23.0834 11.9237 23.0185 11.9287C19.9174 12.1682 17.0108 13.5329 14.8458 15.766C12.6808 17.9991 11.4068 20.9466 11.2635 24.0536C11.1203 27.1606 12.1177 30.2128 14.068 32.6357C16.0182 35.0586 18.7869 36.685 21.8528 37.2088C24.9187 37.7326 28.0703 37.1176 30.7144 35.4797L29.9244 34.2046C27.5914 35.6498 24.8106 36.1924 22.1054 35.7302C19.4002 35.268 16.9573 33.833 15.2364 31.6951C13.5156 29.5573 12.6355 26.8642 12.762 24.1227C12.8884 21.3812 14.0125 18.7805 15.9228 16.8101C17.8331 14.8397 20.3977 13.6355 23.1339 13.4243C25.8702 13.213 28.5893 14.0092 30.7794 15.663C32.9695 17.3168 34.4796 19.7141 35.0253 22.4037C35.5711 25.0933 35.1151 27.8892 33.7428 30.2659L33.3678 30.9154L36.8159 32.9062C36.5002 33.3958 36.1553 33.869 35.7821 34.3231C33.4516 37.1591 30.1763 39.0593 26.5576 39.6749C22.9388 40.2905 19.2195 39.5802 16.0823 37.6744C12.9451 35.7686 10.6005 32.7951 9.47911 29.2999C8.35768 25.8047 8.53459 22.0222 9.97736 18.6469L8.59809 18.0573C7.0134 21.7646 6.81909 25.9191 8.05082 29.7581C9.28256 33.5972 11.8577 36.8631 15.3035 38.9564C18.7492 41.0497 22.8344 41.8298 26.8091 41.1537C30.7838 40.4775 34.3812 38.3904 36.941 35.2754C39.5008 32.1605 40.8511 28.2268 40.7441 24.1964C40.6371 20.1661 39.08 16.3095 36.3586 13.3348C33.6371 10.36 29.9341 8.4667 25.9291 8.00236C21.9241 7.53802 17.8861 8.53383 14.5562 10.807L15.4019 12.0458ZM24.7133 11.9108L24.7061 9.40724C25.0561 9.42347 25.4063 9.4518 25.7563 9.49238C29.4026 9.91514 32.7741 11.6389 35.2518 14.3473C37.4366 16.7354 38.7971 19.7476 39.1551 22.9422L36.6564 23.0984C36.6159 22.7665 36.5623 22.4352 36.4954 22.1054C35.8768 19.0572 34.1654 16.3403 31.6833 14.4659C29.6548 12.9342 27.226 12.0516 24.7133 11.9108ZM35.3977 30.3553C36.2975 28.5609 36.7571 26.5867 36.75 24.5955L39.2487 24.4393C39.2818 26.9454 38.6969 29.4047 37.5659 31.6072L35.3977 30.3553Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3463 24.3898C26.906 23.8059 27.25 23.0136 27.25 22.1409C27.25 20.3459 25.7949 18.8909 24 18.8909C22.2051 18.8909 20.75 20.3459 20.75 22.1409C20.75 23.0136 21.094 23.8059 21.6537 24.3898C19.6477 25.2875 18.25 27.3009 18.25 29.6409H19.75C19.75 27.2937 21.6528 25.3909 24 25.3909C26.3472 25.3909 28.25 27.2937 28.25 29.6409H29.75C29.75 27.3009 28.3523 25.2875 26.3463 24.3898ZM22.25 22.1409C22.25 21.1744 23.0335 20.3909 24 20.3909C24.9665 20.3909 25.75 21.1744 25.75 22.1409C25.75 23.1074 24.9665 23.8909 24 23.8909C23.0335 23.8909 22.25 23.1074 22.25 22.1409Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
