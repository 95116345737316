import React from 'react'
import styled, { css } from 'styled-components'
import { Button, ButtonProps } from '../controls'
import { Trailing } from '../controls/styles/Button'
import { standardIcons } from '../icons'

interface Props extends ButtonProps {
  selected?: boolean
}

const { ChevronDown } = standardIcons

export const SelectableButton: React.FC<Props> = ({
  selected,
  purpose = 'primary',
  ...props
}) => (
  <StyledButton
    {...props}
    selected={selected}
    purpose={purpose}
    trailingIcon={ChevronDown}
  />
)

const StyledButton = styled(Button) <{ selected?: boolean; purpose?: string }>`
  ${Trailing} {
    transition: 100ms;
    transform-origin: 50% 40%;
    transform: rotate(${p => (p.selected ? '180deg' : '360deg')});
  }

  ${({ selected, purpose, theme }) => {
    if (selected) {
      if (purpose === 'primary') {
        return css`
          background-color: ${theme.PRIMARY_70};
        `
      } else if (purpose === 'secondary') {
        return css`
          background-color: ${({ theme }) => theme.GREY_30};
          border: 2px solid ${({ theme }) => theme.GREY_30};
        `
      } else if (purpose === 'ghost') {
        return css`color: ${({ theme }) => theme.GREY_80}
    background-color: ${({ theme }) => theme.GREY_30};

    path {
      fill: ${({ theme }) => theme.GREY_80}
    }`
      }
    }
  }}
`
