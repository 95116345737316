import React from 'react'
import {
  Route,
  Switch,
  RouteComponentProps,
  useRouteMatch,
} from 'react-router-dom'
import {
  Analytics,
  AnalyticsFeatureFlags,
} from 'components/analytics/Analytics'
import { Report } from 'components/looker/Report'
import { Quickstart } from 'components/looker/Quickstart'

interface AnalyticsRoutesProps extends RouteComponentProps {
  featureFlags: AnalyticsFeatureFlags
}

const AnalyticsRoutes: React.FC<AnalyticsRoutesProps> = ({
  match,
  featureFlags,
}) => {
  // Reports are rendered fullpage, so we don't want to show the Analytics component
  const isReportRoute = useRouteMatch(`${match.path}/reports/:slug`)

  // Quickstarts are rendered fullpage, so we don't want to show the Analytics component
  const isQuickstartsRoute = useRouteMatch(`${match.path}/quickstarts/:slug`)

  return (
    <div>
      {!isReportRoute && !isQuickstartsRoute && (
        <Analytics featureFlags={featureFlags} />
      )}
      <Switch>
        <Route path={`${match.path}/reports/:slug`} component={Report} />
        <Route
          path={`${match.path}/quickstarts/:slug`}
          component={Quickstart}
        />
      </Switch>
    </div>
  )
}

export default AnalyticsRoutes
