import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Integration: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 2H6.5C7.88071 2 9 3.11929 9 4.5V6.5C9 7.88071 7.88071 9 6.5 9H4.5C3.11929 9 2 7.88071 2 6.5V4.5C2 3.11929 3.11929 2 4.5 2ZM4.5 3.5H6.5C7.05229 3.5 7.5 3.94772 7.5 4.5V6.5C7.5 7.05229 7.05229 7.5 6.5 7.5H4.5C3.94772 7.5 3.5 7.05229 3.5 6.5V4.5C3.5 3.94772 3.94772 3.5 4.5 3.5ZM13.5 2H15.5C16.8807 2 18 3.11929 18 4.5V6.5C18 7.88071 16.8807 9 15.5 9H13.5C12.1193 9 11 7.88071 11 6.5V4.5C11 3.11929 12.1193 2 13.5 2ZM13.5 3.5H15.5C16.0523 3.5 16.5 3.94772 16.5 4.5V6.5C16.5 7.05229 16.0523 7.5 15.5 7.5H13.5C12.9477 7.5 12.5 7.05229 12.5 6.5V4.5C12.5 3.94772 12.9477 3.5 13.5 3.5ZM6.5 11H4.5C3.11929 11 2 12.1193 2 13.5V15.5C2 16.8807 3.11929 18 4.5 18H6.5C7.88071 18 9 16.8807 9 15.5V13.5C9 12.1193 7.88071 11 6.5 11ZM6.5 12.5H4.5C3.94772 12.5 3.5 12.9477 3.5 13.5V15.5C3.5 16.0523 3.94772 16.5 4.5 16.5H6.5C7.05229 16.5 7.5 16.0523 7.5 15.5V13.5C7.5 12.9477 7.05229 12.5 6.5 12.5ZM11.25 14.5C11.25 14.0858 11.5858 13.75 12 13.75H13.75V12C13.75 11.5858 14.0858 11.25 14.5 11.25C14.9142 11.25 15.25 11.5858 15.25 12V13.75H17C17.4142 13.75 17.75 14.0858 17.75 14.5C17.75 14.9142 17.4142 15.25 17 15.25H15.25V17C15.25 17.4142 14.9142 17.75 14.5 17.75C14.0858 17.75 13.75 17.4142 13.75 17V15.25H12C11.5858 15.25 11.25 14.9142 11.25 14.5Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
