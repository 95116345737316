import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const PlusCircle: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.48624C5.85026 2.48624 2.48624 5.85026 2.48624 10C2.48624 14.1497 5.85026 17.5138 10 17.5138C14.1497 17.5138 17.5138 14.1497 17.5138 10C17.5138 5.85026 14.1497 2.48624 10 2.48624ZM1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0002 5.95413C10.4106 5.95413 10.7433 6.28683 10.7433 6.69725V13.3028C10.7433 13.7132 10.4106 14.0459 10.0002 14.0459C9.58975 14.0459 9.25704 13.7132 9.25704 13.3028V6.69725C9.25704 6.28683 9.58975 5.95413 10.0002 5.95413Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.95413 10C5.95413 9.5896 6.28683 9.25689 6.69725 9.25689H13.3028C13.7132 9.25689 14.0459 9.5896 14.0459 10C14.0459 10.4104 13.7132 10.7431 13.3028 10.7431H6.69725C6.28683 10.7431 5.95413 10.4104 5.95413 10Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
