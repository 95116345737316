import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  Button,
  H1,
  H2,
  Loading,
  standardIcons,
} from '@chordco/component-library'
import { useExperiments, useIsMobile } from 'hooks'
import { LookEmbed } from 'components/looker/LookEmbed'
import useEmbedAudienceUrl from 'components/looker/useEmbedAudienceUrl'
import { CopyAudienceModal } from 'components/looker/CopyAudienceModal'
import { AvailableUserAudience } from 'types'

interface RouteParams {
  slug: string
}

interface LocationState {
  id: string
  title: string
  description: string
}

const Audience: React.FC = () => {
  const { slug } = useParams<RouteParams>()
  const location = useLocation<LocationState>()

  const myAudiencesTreatment = useExperiments('hub_my_audiences_nav_link')

  // If the title is not passed in the location state, use the slug as the title,
  // that's better than nothing...
  const { id, title, description } = location.state

  const { embedAudienceUrls, isLoading } = useEmbedAudienceUrl(slug, 'audience')

  const isMobile = useIsMobile()
  const Heading = isMobile ? H2 : H1

  const history = useHistory()

  const [showCopyModal, setShowCopyModal] = useState(false)

  const handleOnAudienceCopied = (audience?: AvailableUserAudience) => {
    setShowCopyModal(false)

    if (audience) {
      history.push(`/audiences/my-audiences/${audience.slug}`)
    } else {
      // eslint-disable-next-line no-console
      console.error('Cannot find the copied audience')

      history.push(`/audiences/my-audiences`)
    }
  }

  return (
    <>
      <BackButton
        onClick={() => history.push('/audiences/prebuilt-audiences')}
        icon={standardIcons.ArrowLeft}
        variant="ghost"
      >
        Back
      </BackButton>
      <Header>
        <Heading>{title}</Heading>
        <ButtonsContainer>
          <Button
            purpose="primary"
            icon={standardIcons.DataActivations}
            onClick={() => history.push('/data-activations')}
          >
            Activate Audience
          </Button>

          {myAudiencesTreatment?.variation === 'on' && (
            <Button
              purpose="secondary"
              icon={standardIcons.Plus}
              onClick={() => setShowCopyModal(true)}
            >
              Use As Template
            </Button>
          )}
        </ButtonsContainer>
      </Header>

      <Container>
        {isLoading ? <Loading /> : <LookEmbed url={embedAudienceUrls[slug]} />}
      </Container>

      {showCopyModal && (
        <CopyAudienceModal
          title={title}
          description={description}
          audienceId={id}
          onClose={() => setShowCopyModal(false)}
          onCopied={handleOnAudienceCopied}
        />
      )}
    </>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 1rem;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`

const BackButton = styled(Button)`
  padding-left: 0;
`

export default Audience
