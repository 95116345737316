import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Objective: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.12174 2.5H13.8783C14.149 2.5 14.3625 2.73048 14.3417 3.00043L13.8879 8.89968C13.7316 10.9312 12.0376 12.5 10 12.5C7.96245 12.5 6.26839 10.9313 6.11212 8.89968L5.65833 3.00043C5.63756 2.73048 5.851 2.5 6.12174 2.5ZM4.2909 2.25C4.57565 1.52232 5.28376 1 6.12174 1H13.8783C14.7163 1 15.4244 1.52232 15.7091 2.25H17C18.4677 2.25 19.869 3.48833 19.5177 5.14056C19.301 6.15941 18.9007 7.28754 18.1962 8.17685C17.5203 9.03023 16.5611 9.66154 15.278 9.74144C14.8412 11.7722 13.2724 13.3604 11.2844 13.846C11.4551 15.4607 11.8717 16.5324 12.4379 17.2028C13.0232 17.8958 13.8451 18.25 15 18.25C15.4142 18.25 15.75 18.5858 15.75 19C15.75 19.4142 15.4142 19.75 15 19.75H10H5.00002C4.5858 19.75 4.25002 19.4142 4.25002 19C4.25002 18.5858 4.5858 18.25 5.00002 18.25C6.13767 18.25 6.96144 17.8792 7.55343 17.1681C8.12318 16.4837 8.53962 15.4101 8.71368 13.8455C6.72662 13.3593 5.15872 11.7715 4.722 9.74144C3.43889 9.66154 2.47974 9.03023 1.80379 8.17685C1.09936 7.28754 0.699011 6.15941 0.482382 5.14056C0.131083 3.48833 1.53231 2.25 3.00002 2.25H4.2909ZM4.21156 3.75H3.00002C2.25859 3.75 1.84144 4.31999 1.94958 4.8286C2.14055 5.72677 2.47335 6.60635 2.97961 7.24549C3.38151 7.75288 3.88753 8.1072 4.55504 8.21532L4.21156 3.75ZM15.445 8.21532L15.7885 3.75H17C17.7414 3.75 18.1586 4.31999 18.0504 4.8286C17.8595 5.72677 17.5267 6.60635 17.0204 7.24549C16.6185 7.75288 16.1125 8.1072 15.445 8.21532ZM10 18.25H11.3605C11.3375 18.2239 11.3146 18.1975 11.292 18.1707C10.6719 17.4365 10.2483 16.471 9.99479 15.2924C9.73865 16.4403 9.31685 17.3943 8.70625 18.1278C8.67164 18.1694 8.63653 18.2101 8.60093 18.25H10Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
