import React from 'react'
import { AnalyticsBlurbBanner } from '../home/AnalyticsBlurbBanner'
import { standardIcons } from '@chordco/component-library'
import { Circle } from '../common'
import styled from 'styled-components'

const { Plug } = standardIcons

export enum LookerStatusType {
  Unconfigured,
  UnderReview,
  IsSample,
  None,
}

type LookerStatusProps = {
  status: LookerStatusType
}

const LookerStatus: React.FC<LookerStatusProps> = ({ status }) => {
  switch (status) {
    case LookerStatusType.Unconfigured:
      return (
        <ContentWrapper>
          <AnalyticsBlurbBanner
            message="Your data will show up once Looker has been configured."
            status="off"
          />
          <NoDataContainer>
            <Circle innerCircleScale={286} outerCircleScale={414}>
              <Plug />
            </Circle>
          </NoDataContainer>
        </ContentWrapper>
      )
    case LookerStatusType.UnderReview:
      return (
        <ContentWrapper>
          <AnalyticsBlurbBanner
            message="We’re still reviewing your data to confirm it’s accurate. This usually takes 2 weeks."
            status="under_review"
          />
          <NoDataContainer>
            <Circle innerCircleScale={286} outerCircleScale={414}>
              <Plug />
            </Circle>
          </NoDataContainer>
        </ContentWrapper>
      )
    case LookerStatusType.IsSample:
      return (
        <ContentWrapper>
          <AnalyticsBlurbBanner
            message="These dashboards are using sample data. Your data will show up once it is available."
            status="under_review"
          />
        </ContentWrapper>
      )
    case LookerStatusType.None:
    default:
      return null
  }
}

export default LookerStatus

const ContentWrapper = styled.div`
  padding: 0 64px 0 64px;
`

const NoDataContainer = styled.div`
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
`
