import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export interface CloseXIconProps extends IconProps {
  size?: 'small' | 'medium' | 'large'
}

const widths = { small: 6, medium: 10, large: 20 }

export const CloseX: React.FC<CloseXIconProps> = ({
  size = 'medium',
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={widths[size]}
      height={widths[size]}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.42465 0.575638C9.65897 0.809953 9.65897 1.18985 9.42465 1.42417L1.42465 9.42417C1.19034 9.65848 0.810441 9.65848 0.576127 9.42417C0.341812 9.18985 0.341812 8.80995 0.576127 8.57564L8.57613 0.575638C8.81044 0.341324 9.19034 0.341324 9.42465 0.575638Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.576127 0.575638C0.810441 0.341324 1.19034 0.341324 1.42465 0.575638L9.42465 8.57564C9.65897 8.80995 9.65897 9.18985 9.42465 9.42417C9.19034 9.65848 8.81044 9.65848 8.57613 9.42417L0.576127 1.42417C0.341812 1.18985 0.341812 0.809953 0.576127 0.575638Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
