import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Cube: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.79324 1.24124C9.16024 1.02972 9.57639 0.918375 10 0.918375C10.4236 0.918375 10.8397 1.02971 11.2067 1.24121L11.2083 1.24215L17.0417 4.57547C17.4087 4.78737 17.7135 5.09206 17.9256 5.45897C18.1377 5.82588 18.2496 6.2421 18.25 6.6659V13.3333C18.2496 13.7571 18.1377 14.1741 17.9256 14.541C17.7135 14.9079 17.4087 15.2126 17.0417 15.4245L17.0388 15.4262L11.2083 18.7579L11.2067 18.7588C10.8397 18.9703 10.4236 19.0816 10 19.0816C9.57638 19.0816 9.16022 18.9703 8.79322 18.7588L8.79167 18.7579L2.96123 15.4262L2.95833 15.4245C2.59132 15.2126 2.28648 14.9079 2.07439 14.541C1.86231 14.1741 1.75044 13.7579 1.75 13.3341L1.75 6.66667C1.75043 6.24287 1.86231 5.82588 2.07439 5.45897C2.28648 5.09206 2.59132 4.78738 2.95833 4.57548L2.96123 4.57381L8.79324 1.24124ZM10 2.41838C9.83909 2.41838 9.68102 2.46073 9.54167 2.54118L9.53877 2.54285L3.70833 5.87452L3.70714 5.87521C3.56846 5.95555 3.45326 6.07086 3.37304 6.20963C3.29266 6.34871 3.25023 6.50646 3.25 6.66709V13.3329C3.25023 13.4935 3.29266 13.6513 3.37304 13.7904C3.45325 13.9291 3.56843 14.0444 3.70708 14.1248L3.70833 14.1255L9.54167 17.4588C9.68102 17.5393 9.83909 17.5816 10 17.5816C10.1609 17.5816 10.319 17.5393 10.4583 17.4588L10.4612 17.4571L16.2917 14.1255L16.2929 14.1248C16.4316 14.0444 16.5468 13.9291 16.627 13.7904C16.7074 13.6512 16.7498 13.4933 16.75 13.3326V6.66744C16.7498 6.50669 16.7074 6.34881 16.627 6.20963C16.5467 6.07086 16.4315 5.95555 16.2929 5.87521L16.2917 5.87452L10.4583 2.54119C10.319 2.46074 10.1609 2.41838 10 2.41838Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.07579 5.42446C2.2832 5.06591 2.74199 4.94339 3.10054 5.15079L10 9.14189L16.8995 5.15079C17.258 4.94339 17.7168 5.06591 17.9242 5.42446C18.1316 5.783 18.0091 6.2418 17.6505 6.4492L10.3755 10.6575C10.1432 10.7919 9.85678 10.7919 9.62445 10.6575L2.34945 6.4492C1.99091 6.2418 1.86838 5.783 2.07579 5.42446Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 9.25C10.4142 9.25 10.75 9.58579 10.75 10V18.4C10.75 18.8142 10.4142 19.15 10 19.15C9.58579 19.15 9.25 18.8142 9.25 18.4V10C9.25 9.58579 9.58579 9.25 10 9.25Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
