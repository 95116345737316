import React from 'react'
import styled, { css } from 'styled-components'
import { Button, ButtonProps } from './Button'

export const Icon: React.FC<ButtonProps> = ({
  purpose = 'primary',
  icon,
  ...props
}) => {
  return <IconButton {...props} icon={icon} purpose={purpose} />
}

const IconButton = styled(Button)`
  ${({ size }: any) => {
    if (size === 'tiny') {
      return css`
        padding: 4px;
      `
    } else if (size === 'small') {
      return css`
        padding: 8px 7px;
      `
    } else if (size === 'medium') {
      return css`
        padding: 10px;
      `
    }
  }}
  :active {
    transform: scale(1);
  }
`
