import React from 'react'
import styled, { css } from 'styled-components'

import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

type Props = IconProps & {
  hoverFill?: string
}

export const Pause: React.FC<Props> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <StyledSvg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 12.9999C5 13.3313 5.26863 13.5999 5.6 13.5999C5.93137 13.5999 6.2 13.3313 6.2 12.9999L6.2 2.9999C6.2 2.66853 5.93137 2.3999 5.6 2.3999C5.26863 2.3999 5 2.66853 5 2.9999L5 12.9999ZM9.8 12.9999C9.8 13.3313 10.0686 13.5999 10.4 13.5999C10.7314 13.5999 11 13.3313 11 12.9999L11 2.9999C11 2.66853 10.7314 2.3999 10.4 2.3999C10.0686 2.3999 9.8 2.66853 9.8 2.9999L9.8 12.9999Z"
        fill={fill || theme.ContentPrimary}
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg<{
  hoverFill?: string
}>`
  ${({ hoverFill }) =>
    hoverFill &&
    css`
      :hover {
        path {
          fill: ${hoverFill};
        }
      }
    `}
`
