import React from 'react'
import styled, { css } from 'styled-components'

import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

type Props = IconProps & {
  hoverFill?: string
}

export const Square: React.FC<Props> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <StyledSvg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.7999 3.5999C3.68945 3.5999 3.5999 3.68945 3.5999 3.7999V12.1999C3.5999 12.3104 3.68945 12.3999 3.7999 12.3999H12.1999C12.3104 12.3999 12.3999 12.3104 12.3999 12.1999V3.7999C12.3999 3.68945 12.3104 3.5999 12.1999 3.5999H3.7999ZM2.3999 3.7999C2.3999 3.0267 3.0267 2.3999 3.7999 2.3999H12.1999C12.9731 2.3999 13.5999 3.0267 13.5999 3.7999V12.1999C13.5999 12.9731 12.9731 13.5999 12.1999 13.5999H3.7999C3.0267 13.5999 2.3999 12.9731 2.3999 12.1999V3.7999Z"
        fill={fill || theme.ContentPrimary}
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg<{
  hoverFill?: string
}>`
  ${({ hoverFill }) =>
    hoverFill &&
    css`
      :hover {
        path {
          fill: ${hoverFill};
        }
      }
    `}
`
