import { AuthClient, authClient } from './clients/authClient'
import {
  BusinessErrorClient,
  businessErrorClient,
} from './clients/businessErrorsClient'
import { LookerClient, lookerClient } from './clients/lookerClient'
import {
  SystemHealthClient,
  systemHealthClient,
} from 'api/clients/systemHealth'
import {
  provisioningClient,
  ProvisioningClient,
} from './clients/provisioningClient'
import { StoreClient, storeClient } from './clients/storeClient'
import { StoreSetupClient, storeSetupClient } from './clients/storeSetupClient'
import { TenantsClient, tenantsClient } from './clients/tenantsClient'
import { UserClient, userClient } from './clients/userClient'
import {
  UserNotificationsClient,
  userNotificationsClient,
} from './clients/userNotificationsClient'
import {
  ConfigSheetsClient,
  configSheetsClient,
} from './clients/configSheetsClient'
import { RoleClient, roleClient } from './clients/roleClient'

export type ApiClient = AuthClient &
  BusinessErrorClient &
  LookerClient &
  SystemHealthClient &
  ProvisioningClient &
  StoreClient &
  StoreSetupClient &
  TenantsClient &
  UserClient &
  UserNotificationsClient &
  ConfigSheetsClient &
  RoleClient

export const apiClient: ApiClient = {
  ...authClient,
  ...businessErrorClient,
  ...lookerClient,
  ...systemHealthClient,
  ...provisioningClient,
  ...storeClient,
  ...storeSetupClient,
  ...tenantsClient,
  ...userClient,
  ...userNotificationsClient,
  ...configSheetsClient,
  ...roleClient,
}
