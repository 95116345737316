import React, { useEffect, useState } from 'react'
import { Header, Loading } from 'components/common'
import { useStoreSetupData } from 'redux/state/storeSetup'
import { useTenantsData } from 'redux/state/tenants'
import styled from 'styled-components'
import { HiddenVariable } from 'components/developers/HiddenVariable'
import { useMediaQuery } from 'react-responsive'
import { Button } from '@chordco/component-library'
import { useEnvironmentsData } from 'redux/state/environments'
import { EnvVarConfig } from 'utils/envVarConfig'

const nextPublicStytchApiKey =
  EnvVarConfig.REACT_APP_DEMO_NEXT_PUBLIC_STYTCH_API_KEY

const settings = {
  CONTENTFUL_SPACE_ID: '',
  CONTENTFUL_ACCESS_TOKEN: '',
  CONTENTFUL_API_URL: 'cdn.contentful.com',
  CONTENTFUL_ENVIRONMENT: 'master',

  STRIPE_PK_KEY: '',
  STRIPE_SK_KEY: '',

  CHORD_OMS_API_URL: '',
  CHORD_OMS_API_KEY: '',
  CHORD_OMS_STORE_NAME: '',
  CHORD_OMS_BRAND_NAME: '',

  FREE_SHIPPING_THRESHOLD: '25',
  NEXT_PUBLIC_STYTCH_API_KEY: nextPublicStytchApiKey,
}

export const EnvironmentVariables: React.FC = () => {
  const {
    getStoreSetup,
    state: { integrations },
  } = useStoreSetupData()

  useEffect(() => {
    if (!integrations.length && getStoreSetup) getStoreSetup()
  }, [getStoreSetup])

  const {
    getUserTenants,
    state: { currentTenant },
  } = useTenantsData()

  useEffect(() => {
    if (getUserTenants && !currentTenant) getUserTenants()
  }, [getUserTenants])

  const {
    state: { selectedEnvironment },
  } = useEnvironmentsData()

  settings.CHORD_OMS_BRAND_NAME = currentTenant?.metadata?.omsTenantId || ''

  integrations.forEach(i => {
    if (i.environment !== selectedEnvironment) return

    switch (i.type) {
      case 'Hub::Solidus':
        settings.CHORD_OMS_API_KEY = i.settings.apiKey || ''
        settings.CHORD_OMS_API_URL = i.settings.apiHost || ''
        break
      case 'Hub::Contentful':
        settings.CONTENTFUL_SPACE_ID = i.settings.spaceId || ''
        settings.CONTENTFUL_ACCESS_TOKEN = i.settings.accessToken || ''
        break
      case 'Hub::Stripe':
        settings.STRIPE_PK_KEY = i.settings.pkKey || ''
        settings.STRIPE_SK_KEY = i.settings.apiKey || ''
        break
    }
  }, [])

  const [copied, setCopied] = useState(false)

  const hasBeenCopied = () => {
    setCopied(true)

    setTimeout(() => setCopied(false), 2000)
  }

  const copyAll = () => {
    let settingsString = ''

    Object.entries(settings).forEach(
      ([key, value]) => (settingsString += `${key}=${value}\n`)
    )

    navigator.clipboard.writeText(settingsString || '')

    hasBeenCopied()
  }

  const isLoading = !integrations.length || !currentTenant
  const isSmall = useMediaQuery({ query: '(max-width: 700px)' })

  return (
    <>
      {isLoading && <Loading />}
      <Header title="Environment Variables" />
      <Container>
        <Button purpose="secondary" onClick={copyAll}>
          {copied ? 'Copied!' : 'Copy All'}
        </Button>
        {Object.keys(settings).map(key => (
          <HiddenVariable
            key={key}
            name={key}
            value={settings[key]}
            isSmall={isSmall}
          />
        ))}
      </Container>
    </>
  )
}

const Container = styled.div`
  margin: 36px 0;
`
