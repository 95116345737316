import React from 'react'
import narvarLogo from 'assets/NarvarLogo.png'
import { Fields } from '../common'
import { Integration, NarvarSettings } from 'types'
import { useForm } from 'hooks'
import { ValSchemaGeneric } from 'validationSchemas'
import { useIntegrationForm } from 'redux/hooks'
import { FormInput } from 'components/common'
import { getFulfillmentConfirmationMessage } from 'utils/confirmationMessages'
import { MultipleProviderForm } from '../MultipleProviderForm'

type Props = {
  onChangeProvider: () => void
  onClose: () => void
  integration?: Integration<NarvarSettings>
}

const NARVAR_LOGIN_LINK = 'https://hub.narvar.com/login'
const getSchema = (settings?: NarvarSettings): ValSchemaGeneric<['host']> => ({
  host: {
    label: 'Host',
    type: 'string',
    initialValue: settings?.host,
  },
})

export const NarvarForm: React.FC<Props> = ({
  onChangeProvider,
  onClose,
  integration,
}) => {
  const { submit, isLoading } = useIntegrationForm(
    'narvar',
    integration,
    onClose
  )

  const schema = getSchema(integration?.settings)
  const { fields, onSubmit } = useForm(schema, submit)

  const integrationTitle = 'Narvar'
  const confirmationMessage =
    getFulfillmentConfirmationMessage(integrationTitle)

  return (
    <MultipleProviderForm
      integrationType="narvar"
      integrationTitle={integrationTitle}
      logo={narvarLogo}
      loginLink={NARVAR_LOGIN_LINK}
      confirmationMessage={confirmationMessage}
      onChangeProvider={onChangeProvider}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <Fields>
        <FormInput field={fields.host} customWidth="100%" />
      </Fields>
    </MultipleProviderForm>
  )
}
