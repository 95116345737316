import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Unpublish: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 10.0003L1.3048 9.71891L1.19088 10.0003L1.3048 10.2817L2 10.0003ZM17.9999 10.0003L18.6952 10.2817L18.8091 10.0003L18.6952 9.71891L17.9999 10.0003ZM2.6952 10.2817C3.8643 7.39357 6.69537 5.3584 9.99997 5.3584V3.8584C6.0635 3.8584 2.6953 6.28379 1.3048 9.71891L2.6952 10.2817ZM9.99997 5.3584C13.3046 5.3584 16.1356 7.39357 17.3047 10.2817L18.6952 9.71891C17.3047 6.28379 13.9365 3.8584 9.99997 3.8584V5.3584ZM17.3047 9.71891C16.1356 12.6071 13.3046 14.6422 9.99997 14.6422V16.1422C13.9365 16.1422 17.3047 13.7168 18.6952 10.2817L17.3047 9.71891ZM9.99997 14.6422C6.69537 14.6422 3.8643 12.6071 2.6952 9.71891L1.3048 10.2817C2.6953 13.7168 6.0635 16.1422 9.99997 16.1422V14.6422Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.52789 17.7458L14.4722 2.25391L15.7712 3.00391L6.82693 18.4958L5.52789 17.7458Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.81197 13.5576C10.4681 14.1126 12.3404 13.4462 13.2475 11.875C14.1547 10.3039 13.7956 8.34913 12.487 7.19238L11.7105 8.53733C12.3128 9.24178 12.4383 10.2766 11.9485 11.125C11.4587 11.9734 10.4997 12.3821 9.58848 12.2127L8.81197 13.5576ZM10.4114 7.78733C9.50016 7.61788 8.54122 8.0266 8.05139 8.87501C7.56156 9.72343 7.68707 10.7583 8.28944 11.4627L7.51293 12.8076C6.2043 11.6509 5.84524 9.69617 6.75235 8.12501C7.65946 6.55386 9.53183 5.88745 11.1879 6.44238L10.4114 7.78733Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
