import React, { ComponentProps, useRef } from 'react'
import styled from 'styled-components'

interface Props extends ComponentProps<'input'> {
  label: string
  small?: boolean
}

export const RadioButton: React.FC<Props> = ({
  label,
  small = false,
  ...props
}) => {
  const ref = useRef() as any
  const onMouseUp = () => {
    setTimeout(() => ref?.current?.blur(), 0)
  }

  return (
    <Label disabled={props.disabled} onMouseUp={onMouseUp} small={small}>
      <Input {...props} type="radio" ref={ref} small={small} />
      <Radio small={small} />
      <div>{label}</div>
    </Label>
  )
}

const Label = styled.label<{ disabled?: boolean; small: boolean }>`
  display: block;
  position: relative;
  height: 36px;
  width: fit-content;
  color: ${p =>
    p.disabled ? p.theme.ContentDisabled : p.theme.ContentPrimary};
  cursor: ${p => (p.disabled ? 'auto' : 'pointer')};
  white-space: nowrap;

  div {
    position: absolute;
    top: ${p => (p.small ? 1 : 5)}px;
    left: 30px;
  }
`

const Radio = styled.span<{ small: boolean }>`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: ${p => (p.small ? 16 : 24)}px;
  height: ${p => (p.small ? 16 : 24)}px;
  border-radius: 50%;
  background-color: ${p => p.theme.BgBase1};
  border: 1px solid ${p => p.theme.BorderStrong};
  transition: 100ms;
`

const Input = styled.input<{ small: boolean }>`
  opacity: 0;

  :hover + ${Radio} {
    border: 2px solid ${p => p.theme.BorderAccentPrimary};
  }

  :checked + ${Radio} {
    border: ${p => `${p.small ? 5 : 8}px solid ${p.theme.BorderAccentPrimary}`};
  }

  :focus + ${Radio} {
    outline: none;
    box-shadow: ${p => p.theme.ComponentButtonBorderFocused};
  }

  :disabled + ${Radio} {
    border-color: ${p => p.theme.BorderDisabled};
    background: ${p => p.theme.BgDisabled};
  }
`
