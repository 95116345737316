import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Download: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9831 10.25C16.9831 10.6642 17.3227 11 17.7416 11C18.1604 11 18.5 10.6642 18.5 10.25V4.41667C18.5 3.77573 18.2425 3.16104 17.7842 2.70783C17.3259 2.25461 16.7043 2 16.0562 2L4.94382 2C4.29568 2 3.67408 2.25461 3.21578 2.70783C2.75747 3.16104 2.5 3.77573 2.5 4.41667L2.5 10.25C2.5 10.6642 2.83956 11 3.25843 11C3.67729 11 4.01685 10.6642 4.01685 10.25L4.01685 4.41667C4.01685 4.17356 4.11452 3.9404 4.28836 3.76849C4.4622 3.59658 4.69797 3.5 4.94382 3.5H16.0562C16.302 3.5 16.5378 3.59658 16.7116 3.76849C16.8855 3.9404 16.9831 4.17356 16.9831 4.41667L16.9831 10.25Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M9.95842 17.7753C9.97656 17.7936 9.99564 17.8109 10.0156 17.8273C10.0685 17.8708 10.1259 17.906 10.1862 17.9331C10.2817 17.9761 10.3879 18 10.4998 18C10.5021 18 10.5045 18 10.5068 18C10.5961 17.9992 10.6852 17.9829 10.7698 17.9511C10.8669 17.9146 10.9579 17.8577 11.0361 17.7803L14.4069 14.447C14.7031 14.1541 14.7031 13.6792 14.4069 13.3863C14.1107 13.0934 13.6305 13.0934 13.3343 13.3863L11.2582 15.4394L11.2582 7.75001C11.2582 7.33579 10.9186 7 10.4998 7C10.0809 7 9.74134 7.33579 9.74134 7.75001L9.74134 15.4393L7.66532 13.3863C7.36914 13.0934 6.88893 13.0934 6.59274 13.3863C6.29656 13.6792 6.29656 14.1541 6.59274 14.447L9.95842 17.7753Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
