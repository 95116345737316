import React, { useRef } from 'react'
import { Header } from 'components/common'
import { useResponsiveWidth } from 'hooks'
import styled from 'styled-components'
import { ServicesStatus } from './ServicesStatus'
import { Section } from 'components/systemHealth/Section'
import githubLogo from 'assets/GithubLogo.png'
import stripeLogo from 'assets/StripeLogo.png'
import { useGetSystemHealthData } from './utils'
import { FrontendWebHost } from './FrontendWebHost'
import { ContentManagement } from 'components/systemHealth/ContentManagement'
import { Data } from 'components/systemHealth/Data'
import {
  Column,
  HowToResolvePill,
  StatusLine,
} from 'components/systemHealth/common'

export const SystemHealth: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null)
  const width = useResponsiveWidth(ref)
  const isSmall = width < 925

  const {
    contentStoreInsights,
    dataStoreInsights,
    onlineStoreInsights,
    // orderStoreInsights,
    buildStatus,
    deliveryMetrics,
    eventVolume,
    stripeWebhooks,
    numberOfEventsBySourceId,
    health,
    tenantId,
    loading,
    failed,
    retryFunctions,
  } = useGetSystemHealthData()

  return (
    <>
      <Header title="System Health" />
      <Content ref={ref}>
        <ServicesStatus tenantId={tenantId} isSmall={isSmall} />

        <ContentManagement
          contentStoreInsights={contentStoreInsights}
          health={health}
          isLoading={loading.content}
          hasFailed={failed.content}
          isSmall={isSmall}
          retryFunction={retryFunctions.getContent}
        />

        <Data
          dataStoreInsights={dataStoreInsights}
          deliveryMetrics={deliveryMetrics}
          eventVolume={eventVolume}
          numberOfEventsBySourceId={numberOfEventsBySourceId}
          isLoading={loading.data}
          hasFailed={failed.data}
          isSmall={isSmall}
          retryFunction={retryFunctions.getData}
        />

        <FrontendWebHost
          buildStatus={buildStatus}
          health={health}
          isLoading={loading.buildStatus}
          hasFailed={failed.buildStatus}
          isSmall={isSmall}
          retryFunction={retryFunctions.getOnlineStore}
        />

        <Section
          title="Repository"
          subTitle="Github"
          logo={githubLogo}
          link={onlineStoreInsights?.repository?.url}
          isSmall={isSmall}
          isLoading={loading.onlineStore}
          hasFailed={failed.onlineStore}
          retryFunction={retryFunctions.getRepo}
        />

        <Section
          title="Payments"
          subTitle="Stripe"
          logo={stripeLogo}
          link={stripeWebhooks?.length ? stripeWebhooks[0].url : undefined}
          operatingCorrectly={health.payment}
          isSmall={isSmall}
          isLoading={loading.payment}
          hasFailed={failed.payment}
          retryFunction={retryFunctions.getPayment}
        >
          {stripeWebhooks?.length && (
            <Column isSmall={isSmall}>
              {stripeWebhooks.map(w => (
                <StatusLine
                  key={w.id}
                  status={w.status === 'enabled' ? 'enabled' : 'disabled'}
                  label="Events Webhook"
                />
              ))}
              {!health.payment && (
                <HowToResolvePill href="https://app.gitbook.com/o/-MZD-8ejv0l5kuSCuMCS/s/-ManTxOCOZxZsL5DmR5X/provisioning/provisioning-handbook/stripe-webhooks-autonomy" />
              )}
            </Column>
          )}
        </Section>
      </Content>
    </>
  )
}

const Content = styled.div`
  padding-top: 24px;
  padding-bottom: 64px;
  line-height: 1.75;
  white-space: nowrap;
  overflow: scroll;
  height: calc(100% - 72px);
`
