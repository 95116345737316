import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { Chip, getRangeDisplayValue } from '@chordco/component-library'

export type FilterType = 'search' | 'date' | 'facet' | 'category'

export type Filter = {
  type: FilterType
  value: any
  label: string
}

interface Props {
  filters: Filter[]
  startDate?: moment.Moment
  endDate?: moment.Moment
  clearDates?: () => void
  removeFilter: (value: string) => void
  setHeight?: Function
  totalCount: number
  dateFilterType?: string
}

const getChipText = (text: string) => {
  return <Text>{text}</Text>
}

const getDateChipText = (
  startDate: moment.Moment,
  endDate?: moment.Moment,
  dateFilterType?: string
) =>
  dateFilterType ? (
    <Text>
      <Text grey>
        {dateFilterType === 'actionable_date' ? 'Shipment Due' : 'Created'}:
      </Text>{' '}
      {getRangeDisplayValue(startDate, endDate)}
    </Text>
  ) : (
    getRangeDisplayValue(startDate, endDate)
  )

export const Filters: React.FC<Props> = ({
  filters,
  startDate,
  endDate,
  clearDates,
  removeFilter,
  setHeight,
  totalCount,
  dateFilterType,
}) => {
  const ref = useRef() as any
  useLayoutEffect(() => {
    setHeight && setHeight(ref.current?.getBoundingClientRect().height || 0)
  }, [ref.current?.getBoundingClientRect().height])

  return (
    <Wrapper ref={ref}>
      <FiltersContainer>
        {filters.map(filter => (
          <Chip
            key={filter.value}
            text={getChipText(filter.label)}
            color="brightBlue"
            onClose={() => removeFilter(filter.value)}
            aria-label={`${filter.label} Filter`}
          />
        ))}
        {startDate && (
          <Chip
            text={getDateChipText(startDate, endDate, dateFilterType)}
            color="brightBlue"
            onClose={clearDates}
            aria-label="Date filter"
          />
        )}
      </FiltersContainer>
      <TotalCount>{totalCount} results</TotalCount>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  margin: 16px 0;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

const FiltersContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-wrap: wrap;

  & > button {
    margin-right: 8px;
    margin-bottom: 2px;
  }
`

const TotalCount = styled.div`
  white-space: nowrap;
  font-size: 12px;
`

const Text = styled.span<{ grey?: boolean }>`
  margin: 0;
  font-size: 12px;
  line-height: 16px;
`
