import React from 'react'
import styled from 'styled-components'
import { Col, TableRow } from 'components/common/table'
import { ConnectedSource } from 'api/fivetran/interfaces'
import { formatRelativeTime } from 'utils/dateTime'
import { SetupChip } from './SetupChip'
import { SyncChip } from './SyncChip'
import { useIsMobile } from 'hooks'
import { ModeledChip } from './ModeledChip'

type Props = {
  source: ConnectedSource
  isModeled: boolean
  onSelectSource: (connector: ConnectedSource) => void
}

export const SourceRow: React.FC<Props> = ({
  source,
  isModeled,
  onSelectSource,
}) => {
  const isMobile = useIsMobile()

  const handleSelectSource = () => {
    onSelectSource(source)
  }

  const lastSyncedAt = source.succeededAt
    ? formatRelativeTime(source.succeededAt)
    : 'Never'

  return (
    <Container onClick={handleSelectSource}>
      <SourceCol flex={1}>
        <Logo src={source.metadata.iconUrl} />
        <Title small={isMobile}>{source.metadata.name}</Title>
      </SourceCol>
      <ChipCol flex={1}>
        <ModeledChipContainer>
          <ModeledChip modeled={isModeled} tooltipDirection="right" />
        </ModeledChipContainer>
      </ChipCol>
      <ChipCol flex={1}>
        <SetupChip setupState={source.status.setupState} iconOnly={isMobile} />
      </ChipCol>
      <ChipCol flex={1}>
        <SyncChip syncState={source.status.syncState} iconOnly={isMobile} />
      </ChipCol>
      <Col>{lastSyncedAt}</Col>
    </Container>
  )
}

const Container = styled(TableRow)`
  height: 56px;
  border-bottom: 1px solid ${p => p.theme.ComponentCardBorderElevation0};

  :first-child {
    border-top-right-radius: ${p => p.theme.CardMediumRadius};
    border-top-left-radius: ${p => p.theme.CardMediumRadius};
  }

  :last-child {
    border-bottom-right-radius: ${p => p.theme.CardMediumRadius};
    border-bottom-left-radius: ${p => p.theme.CardMediumRadius};
    border-bottom: none;
  }
`

const Title = styled.span<{ small: boolean }>`
  font-size: ${p => (p.small ? '12px' : '14px')};
  font-weight: 500;
  padding-left: 8px;
`

const Logo = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 2px;
`

const SourceCol = styled(Col)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  height: 26px;
`

const ChipCol = styled(Col)`
  max-width: 33%;
  height: 26px;
`

const ModeledChipContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`
