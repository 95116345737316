export enum MixpanelEventType {
  ButtonClicked = 'Button Clicked',
  SheetOpened = 'Sidesheet Opened',
  SheetClosed = 'Sidesheet Closed',
}

export type MixpanelEventData = {
  [MixpanelEventType.SheetOpened]: {
    'Sidesheet Name': string
    'Page Name': string
  }
  [MixpanelEventType.SheetClosed]: {
    'Sidesheet Name': string
    'Page Name': string
  }
  [MixpanelEventType.ButtonClicked]: {
    'Button Name': string
    'Button Purpose': string
    'Page Name': string
  }
}
