import React from 'react'
import styled from 'styled-components'
import { Body, Button, H5, standardIcons } from '@chordco/component-library'
import { useEnvironmentsData } from 'redux/state/environments'
import { InverseThemeProvider } from 'themes/ThemeSwitcherProvider'

const { Refresh } = standardIcons

type Props = {
  onConfirm: (e?: any) => void
  onCancel: (e?: any) => void
  message?: string
}

export const ConfirmationModal: React.FC<Props> = ({
  onConfirm,
  onCancel,
  message,
}) => {
  const {
    state: { selectedEnvironment },
  } = useEnvironmentsData()

  return (
    <Mask>
      <InverseThemeProvider>
        <Container>
          <IconContainer>
            <Refresh scale={16} />
          </IconContainer>
          <Heading>Are you sure?</Heading>
          <Blurb>
            {message ||
              `This will affect your ${selectedEnvironment} environment.`}
          </Blurb>
          <ButtonGroup>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="solid" onClick={onConfirm}>
              Yes
            </Button>
          </ButtonGroup>
        </Container>
      </InverseThemeProvider>
    </Mask>
  )
}

const Mask = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
`

const Container = styled.div`
  width: 372px;
  height: auto;
  position: fixed;
  z-index: 2;
  top: 40vh;
  left: calc(50vw - 186px);
  padding: 24px;
  border-radius: 16px;
  background: ${p => p.theme.BgBase1};
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${p => p.theme.ContentPrimary};
`

const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${p => p.theme.BgBase3};
  border-radius: 24px;
`

const Heading = styled(H5)`
  margin-top: 24px;
`

const Blurb = styled(Body)`
  // color: ${p => p.theme.GREY_4};
  margin-top: 8px;
  text-align: center;
  white-space: pre-line;
`

const ButtonGroup = styled.div`
  margin-top: 34px;
  display: flex;

  & > button {
    margin-left: 8px;
  }
`
