import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Image: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16667 3.25C3.66041 3.25 3.25 3.66041 3.25 4.16667V15.8333C3.25 16.2455 3.52204 16.5941 3.89636 16.7095L12.8028 7.80301C13.0957 7.51012 13.5706 7.51012 13.8635 7.80301L16.75 10.6895V4.16667C16.75 3.66041 16.3396 3.25 15.8333 3.25H4.16667ZM16.75 12.8108L13.3332 9.394L5.97718 16.75H15.8333C16.3396 16.75 16.75 16.3396 16.75 15.8333V12.8108ZM4.17066 18.25H15.8333C17.168 18.25 18.25 17.168 18.25 15.8333V4.16667C18.25 2.83198 17.168 1.75 15.8333 1.75H4.16667C2.83198 1.75 1.75 2.83198 1.75 4.16667V15.8333C1.75 17.1663 2.82916 18.2472 4.16144 18.25C4.16318 18.25 4.16492 18.25 4.16667 18.25H4.17066ZM7.0835 6.58334C6.80735 6.58334 6.5835 6.8072 6.5835 7.08334C6.5835 7.35949 6.80735 7.58334 7.0835 7.58334C7.35964 7.58334 7.5835 7.35949 7.5835 7.08334C7.5835 6.8072 7.35964 6.58334 7.0835 6.58334ZM5.0835 7.08334C5.0835 5.97877 5.97893 5.08334 7.0835 5.08334C8.18807 5.08334 9.0835 5.97877 9.0835 7.08334C9.0835 8.18791 8.18807 9.08334 7.0835 9.08334C5.97893 9.08334 5.0835 8.18791 5.0835 7.08334Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
