import React from 'react'
import stytchLogo from 'assets/StytchLogo.png'
import { useForm } from 'hooks'
import { FormInput, FormSecureInput, FormDropdown } from '../../../../common'
import { ValSchemaGeneric } from 'validationSchemas'
import { Fields } from '../common'
import { Integration, StytchSettings } from 'types'
import { useIntegrationForm } from 'redux/hooks'
import { getAuthenticationConfirmationMessage } from 'utils/confirmationMessages'
import { MultipleProviderForm } from '../MultipleProviderForm'

type Props = {
  onChangeProvider: () => void
  onClose: () => void
  integration?: Integration<StytchSettings>
}

const STYTCH_LOGIN_LINK = 'https://stytch.com/dashboard/home'

const getSchema = (
  settings?: StytchSettings
): ValSchemaGeneric<['env', 'projectId', 'secret', 'token']> => ({
  env: {
    label: 'Environment',
    type: 'string',
    initialValue: settings?.env,
  },
  projectId: {
    label: 'Project ID',
    type: 'string',
    initialValue: settings?.projectId,
  },
  secret: {
    label: 'Secret',
    type: 'string',
    initialValue: settings?.secret,
  },
  token: {
    label: 'Public Token',
    type: 'string',
    initialValue: settings?.token,
  },
})

export const StytchForm: React.FC<Props> = ({
  onChangeProvider,
  onClose,
  integration,
}) => {
  const { submit, isLoading } = useIntegrationForm(
    'stytch',
    integration,
    onClose
  )

  const schema = getSchema(integration?.settings)
  const { fields, onSubmit } = useForm(schema, submit)

  const integrationTitle = 'Stytch'
  const confirmationMessage = getAuthenticationConfirmationMessage(integrationTitle)
  const environments = [
    { id: 'test', label: 'Test' },
    { id: 'live', label: 'Live' },
  ]

  return (
    <MultipleProviderForm
      integrationType={'stytch'}
      integrationTitle={integrationTitle}
      logo={stytchLogo}
      loginLink={STYTCH_LOGIN_LINK}
      confirmationMessage={confirmationMessage}
      onChangeProvider={onChangeProvider}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <Fields>
        <FormDropdown
          field={fields.env}
          customWidth="100%"
          options={environments}
        />
        <FormInput field={fields.projectId} customWidth="100%" />
        <FormSecureInput field={fields.secret} customWidth="100%" />
        <FormInput field={fields.token} customWidth="100%" />
      </Fields>
    </MultipleProviderForm>
  )
}
