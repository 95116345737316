import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const BarChart: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75 4C1.75 3.0335 2.5335 2.25 3.5 2.25H6.5C7.4665 2.25 8.25 3.0335 8.25 4V9.25H11.75V8C11.75 7.0335 12.5335 6.25 13.5 6.25H16.5C17.4665 6.25 18.25 7.0335 18.25 8V16C18.25 16.9665 17.4665 17.75 16.5 17.75H13.25H11.75H8.25H6.75H3.5C2.5335 17.75 1.75 16.9665 1.75 16V4ZM11.75 16.25V10.75H8.25V16.25H11.75ZM13.25 16.25H16.5C16.6381 16.25 16.75 16.1381 16.75 16V8C16.75 7.86193 16.6381 7.75 16.5 7.75H13.5C13.3619 7.75 13.25 7.86193 13.25 8V9.25V16.25ZM6.75 4V9.25V16.25H3.5C3.36193 16.25 3.25 16.1381 3.25 16V4C3.25 3.86193 3.36193 3.75 3.5 3.75H6.5C6.63807 3.75 6.75 3.86193 6.75 4Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
