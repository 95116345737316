import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Duplicate: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.68499 2.68517C2.8569 2.51326 3.09006 2.41669 3.33317 2.41669H10.8332C11.0763 2.41669 11.3094 2.51326 11.4814 2.68517C11.6533 2.85708 11.7498 3.09024 11.7498 3.33335V4.16669C11.7498 4.5809 12.0856 4.91669 12.4998 4.91669C12.9141 4.91669 13.2498 4.5809 13.2498 4.16669V3.33335C13.2498 2.69241 12.9952 2.07772 12.542 1.62451C12.0888 1.1713 11.4741 0.916687 10.8332 0.916687H3.33317C2.69223 0.916687 2.07754 1.1713 1.62433 1.62451C1.17112 2.07773 0.916504 2.69241 0.916504 3.33335V10.8334C0.916504 11.4743 1.17112 12.089 1.62433 12.5422C2.07754 12.9954 2.69223 13.25 3.33317 13.25H4.1665C4.58072 13.25 4.9165 12.9142 4.9165 12.5C4.9165 12.0858 4.58072 11.75 4.1665 11.75H3.33317C3.09006 11.75 2.8569 11.6534 2.68499 11.4815C2.51308 11.3096 2.4165 11.0765 2.4165 10.8334V3.33335C2.4165 3.09024 2.51308 2.85708 2.68499 2.68517ZM8.25 9.16667C8.25 8.66041 8.66041 8.25 9.16667 8.25H16.6667C17.1729 8.25 17.5833 8.66041 17.5833 9.16667V16.6667C17.5833 17.1729 17.1729 17.5833 16.6667 17.5833H9.16667C8.66041 17.5833 8.25 17.1729 8.25 16.6667V9.16667ZM9.16667 6.75C7.83198 6.75 6.75 7.83198 6.75 9.16667V16.6667C6.75 18.0014 7.83198 19.0833 9.16667 19.0833H16.6667C18.0014 19.0833 19.0833 18.0014 19.0833 16.6667V9.16667C19.0833 7.83198 18.0014 6.75 16.6667 6.75H9.16667Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
