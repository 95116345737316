import React from 'react'
import { Link as BaseLink } from 'react-router-dom'
import type { LinkProps } from 'react-router-dom'
import styled from 'styled-components'

export const Link: React.FC<LinkProps> = ({ ...props }) => (
  <StyledLink {...props} />
)

const StyledLink = styled(BaseLink)`
  color: ${p => p.theme.ContentPrimary};
`
