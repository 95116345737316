import { useTheme } from '@chordco/component-library'

export const generateColorFromText = (text: string, isName = true) => {
  const theme = useTheme()

  const nameColors = [
    theme.ContentAccentPrimary,
    theme.ContentAccentSuccess,
    theme.ContentAccentTertiary,
    theme.ContentAccentInfo,
    theme.ContentAccentSuccess,
    theme.ContentAccentError,
    theme.ContentAccentWarning,
  ]

  const tagColors = [
    theme.BgAccentBrightAADarkNeutral,
    theme.BgAccentBrightAADarkPrimary,
    theme.BgAccentBrightAADarkTertiary,
    theme.BgAccentBrightAADarkSecondary,
    theme.BgAccentBrightAADarkInfo,
    theme.BgAccentBrightAADarkSuccess,
    theme.BgAccentBrightAADarkError,
    theme.BgAccentBrightAADarkWarning,
  ]

  const charCodeSum = text
    .split('')
    .reduce((acc, char) => acc + char.charCodeAt(0), 0)

  const colors = isName ? nameColors : tagColors

  const colorIndex = charCodeSum % colors.length
  return colors[colorIndex]
}
