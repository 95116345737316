import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const BodySvg: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 4.25C2.08579 4.25 1.75 4.58579 1.75 5C1.75 5.41421 2.08579 5.75 2.5 5.75H17.5C17.9142 5.75 18.25 5.41421 18.25 5C18.25 4.58579 17.9142 4.25 17.5 4.25H2.5ZM2.5 7.58334C2.08579 7.58334 1.75 7.91913 1.75 8.33334C1.75 8.74756 2.08579 9.08334 2.5 9.08334H14.1667C14.5809 9.08334 14.9167 8.74756 14.9167 8.33334C14.9167 7.91913 14.5809 7.58334 14.1667 7.58334H2.5ZM1.75 11.6667C1.75 11.2524 2.08579 10.9167 2.5 10.9167H17.5C17.9142 10.9167 18.25 11.2524 18.25 11.6667C18.25 12.0809 17.9142 12.4167 17.5 12.4167H2.5C2.08579 12.4167 1.75 12.0809 1.75 11.6667ZM2.5 14.25C2.08579 14.25 1.75 14.5858 1.75 15C1.75 15.4142 2.08579 15.75 2.5 15.75H14.1667C14.5809 15.75 14.9167 15.4142 14.9167 15C14.9167 14.5858 14.5809 14.25 14.1667 14.25H2.5Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
