import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const VerticalExpand: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 1.75C2 1.33579 2.33579 1 2.75 1H17.25C17.6642 1 18 1.33579 18 1.75C18 2.16421 17.6642 2.5 17.25 2.5L2.75 2.5C2.33579 2.5 2 2.16421 2 1.75ZM2 18.25C2 17.8358 2.33579 17.5 2.75 17.5L17.25 17.5C17.6642 17.5 18 17.8358 18 18.25C18 18.6642 17.6642 19 17.25 19L2.75 19C2.33579 19 2 18.6642 2 18.25ZM11.9697 8.03033C12.2626 8.32322 12.7374 8.32322 13.0303 8.03033C13.3232 7.73744 13.3232 7.26256 13.0303 6.96967L10.5303 4.46967C10.2374 4.17678 9.76256 4.17678 9.46967 4.46967L6.96967 6.96967C6.67678 7.26256 6.67678 7.73744 6.96967 8.03033C7.26256 8.32322 7.73744 8.32322 8.03033 8.03033L9.25 6.81066V13.1893L8.03033 11.9697C7.73744 11.6768 7.26256 11.6768 6.96967 11.9697C6.67678 12.2626 6.67678 12.7374 6.96967 13.0303L9.46967 15.5303C9.76256 15.8232 10.2374 15.8232 10.5303 15.5303L13.0303 13.0303C13.3232 12.7374 13.3232 12.2626 13.0303 11.9697C12.7374 11.6768 12.2626 11.6768 11.9697 11.9697L10.75 13.1893V6.81066L11.9697 8.03033Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
