import React, { useMemo } from 'react'
import moment from 'moment'
import { Chip } from '@chordco/component-library'
import { AvailableReport } from 'types'
import { getDescriptionForReport, getIconForReport } from './metadata'
import { EnvVarConfig } from 'utils/envVarConfig'
import {
  Card,
  CardContent,
  Header,
  StyledIcon,
  TextContainer,
  TitleBadgesContainer,
  Title,
  ChipContainer,
  Description,
} from 'components/analytics/reports/common'

type ReportCardProps = {
  report: AvailableReport
  onSelectReport: (report: AvailableReport) => void
}

const IS_NEW_THRESHOLD =
  parseInt(EnvVarConfig.REACT_APP_NEW_DASHBOARD_DAYS_THRESHOLD) || 3

export const ReportCard: React.FC<ReportCardProps> = ({
  report,
  onSelectReport,
}) => {
  const { isNew, description } = useMemo(() => {
    return {
      isNew:
        moment().diff(moment(report.createdAt), 'days') <= IS_NEW_THRESHOLD,
      description: report.description ?? getDescriptionForReport(report),
    }
  }, [report.createdAt, report.description])

  return (
    <Card onClick={() => onSelectReport(report)}>
      <CardContent>
        <Header>
          <StyledIcon
            icon={getIconForReport(report)}
            purpose="ghost"
            size="large"
          />
          <TextContainer>
            <TitleBadgesContainer>
              <Title>{report.label}</Title>
              <ChipContainer>
                {report.createdBy && (
                  <Chip
                    text={`By ${
                      report.createdBy.firstName
                    } ${report.createdBy.lastName.slice(0, 1)}.`}
                    color="neutral"
                    variant="solid-secondary"
                  />
                )}
                {isNew && <Chip text="New" color="limeGreen" variant="solid" />}
              </ChipContainer>
            </TitleBadgesContainer>

            {description && <Description>{description}</Description>}
          </TextContainer>
        </Header>
      </CardContent>
    </Card>
  )
}
