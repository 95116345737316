import React, { ReactNode, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

export type TooltipDirection =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'input-bottom-left'
  | 'input-bottom-right'
  | 'auto'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  text?: string
  renderMethod?: Function
  customWidth?: string
  direction?: TooltipDirection
  children: ReactNode
  light?: boolean
  contentCutOffHeight?: number
  className?: string
}

export const Tooltip: React.FC<Props> = ({
  text,
  renderMethod,
  customWidth,
  direction = 'top',
  light = true,
  contentCutOffHeight = 250,
  children,
  className,
}) => {
  const [selectedDirection, setSelectedDirection] = useState(() =>
    direction === 'auto' ? 'top' : direction
  )
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (
      ref.current &&
      ref.current.getBoundingClientRect().top < contentCutOffHeight &&
      direction === 'auto'
    )
      setSelectedDirection('bottom')
  }, [ref])

  return (
    <div className={className}>
      <Container>
        {children}
        <Tip
          ref={ref}
          direction={selectedDirection}
          light={light}
          customWidth={customWidth}
        >
          {renderMethod ? renderMethod() : text}
        </Tip>
      </Container>
    </div>
  )
}

const Tip = styled.div<{
  direction: string
  light: boolean
  customWidth?: string
}>`
  position: absolute;
  box-sizing: border-box;
  width: max-content;
  max-width: ${p => p.customWidth || '240px'};
  padding: 12px;
  border-radius: 8px;
  background-color: ${p => p.theme.ReverseBackgroundBase1};
  color: ${p => p.theme.ReverseContentSecondary};
  white-space: pre-wrap;
  opacity: 0;
  z-index: -1;
  transition: opacity ease-out 200ms;
  text-transform: none;
  font-size: 12px;

  ${({ direction }) => {
    if (direction === 'top') {
      return css`
        top: -12px;
        left: 50%;
        transform: translate(-50%, -100%);
      `
    } else if (direction === 'right') {
      return css`
        top: 50%;
        right: -12px;
        transform: translate(100%, -50%);
      `
    } else if (direction === 'bottom') {
      return css`
        bottom: -12px;
        left: 50%;
        transform: translate(-50%, 100%);
      `
    } else if (direction === 'left') {
      return css`
        top: 50%;
        left: -12px;
        transform: translate(-100%, -50%);
      `
    } else if (direction === 'input-bottom-left') {
      return css`
        bottom: -12px;
        left: 0;
        transform: translate(-10px, 100%);
      `
    } else if (direction === 'input-bottom-right') {
      return css`
        bottom: -12px;
        left: calc(100% + 4px);
        transform: translate(-100%, 100%);
      `
    }
  }}

  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

const Container = styled.div`
  position: relative;
  display: inline-block;

  :hover {
    ${Tip} {
      opacity: 1;
      z-index: 1;
    }
  }
`
