import React, { useEffect } from 'react'
import styled from 'styled-components'
import {
  Sheet,
  H4,
  Avatar,
  AvatarSize,
  standardIcons,
} from '@chordco/component-library'
import { useIsMobile } from 'hooks'
import { TableRow } from '../../../common'
import {
  Integration,
  PossiblMultipleIntegrationSettings,
  ProviderOption,
} from 'types'
import { useStoreSetupData } from 'redux/state/storeSetup'

const { ChevronRight } = standardIcons

type Props = {
  onClose: () => void
  integration?: Integration<PossiblMultipleIntegrationSettings>
  providers: ProviderOption[]
  name: string
}

export const SelectProviderForm: React.FC<Props> = ({
  name,
  providers,
  integration,
  onClose,
}) => {
  const isMobile = useIsMobile()
  const {
    state: { integrations },
  } = useStoreSetupData()

  const size: AvatarSize = isMobile ? 32 : 48

  const [selectedProvider, setSelectedProvider] =
    React.useState<ProviderOption>()

  useEffect(() => {
    const provider = providers.find(p => p.key === integration?.type)
    if (provider) {
      setSelectedProvider(provider)
    }
  }, [integration])

  const onChangeProvider = () => setSelectedProvider(undefined)

  return (
    <Sheet title={name} onClose={onClose} isLarge>
      <Container isMobile={isMobile}>
        {selectedProvider ? (
          <selectedProvider.form
            onChangeProvider={onChangeProvider}
            onClose={onClose}
            integration={integrations.find(
              i => i.type === selectedProvider.key
            )}
          />
        ) : (
          <>
            <H4>Select your provider</H4>
            {providers.map(provider => (
              <Row
                isMobile={isMobile}
                key={provider.key}
                onClick={() => setSelectedProvider(provider)}
              >
                <Avatar src={provider.logo} size={size} />
                <H4>{provider.name}</H4>
                <ChevronContainer isMobile={isMobile}>
                  <ChevronRight />
                </ChevronContainer>
              </Row>
            ))}
          </>
        )}
      </Container>
    </Sheet>
  )
}

const Container = styled.div<{ isMobile: boolean }>`
  padding: ${p => (p.isMobile ? 12 : 24)}px;
  min-height: calc(100vh - 76px);
  overflow: auto;
  padding: ${p =>
    `8px ${p.isMobile ? 12 : 24}px 100px ${p.isMobile ? 12 : 24}px`};
  position: relative;

  & > div {
    margin-bottom: 16px;

    :last-child {
      margin-bottom: 0;
    }
  }
`

const Row = styled(TableRow)<{ isMobile: boolean }>`
  height: ${p => (p.isMobile ? 60 : 72)}px;
  margin-bottom: ${p => (p.isMobile ? 12 : 16)}px;
  border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
  border-radius: ${p => p.theme.CardMediumRadius};

  display: flex;
  align-content: center;

  img {
    margin-right: 16px;
  }

  :hover {
    border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
    background-color: ${p => p.theme.ComponentCardBgElevation1};
  }
`

const ChevronContainer = styled.div<{ isMobile: boolean }>`
  position: absolute;
  top: ${p => (p.isMobile ? 20 : 26)}px;
  right: ${p => (p.isMobile ? 14 : 28)}px;

  path {
    fill: ${p => p.theme.ContentPrimary};
  }
`
