import React from 'react'
import styled from 'styled-components'

type Props = {
  processingUpload: boolean
  sheetUpload: {
    processing?: boolean
    completed?: boolean
    failed?: boolean
    message?: string | null
  }
  refreshViaWarning: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

export const SheetUploadFeedback: React.FC<Props> = ({
  processingUpload,
  sheetUpload,
  refreshViaWarning,
}) => {
  const isProcessing = processingUpload || sheetUpload.processing
  const hasFailed = sheetUpload.failed
  const hasSucceeded = sheetUpload.completed

  return (
    <>
      {isProcessing && (
        <WarningWrapper>
          Theres a file processing in background, please{' '}
          <WarningLink href="" onClick={refreshViaWarning}>
            refresh
          </WarningLink>{' '}
          in a minute to verify the result.
        </WarningWrapper>
      )}

      {!isProcessing && hasFailed && (
        <ErrorWrapper>
          The uploaded csv failed to process. {sheetUpload.message}
        </ErrorWrapper>
      )}

      {!isProcessing && hasSucceeded && (
        <SuccessWrapper>
          The uploaded csv has been processed successfully.{' '}
          {sheetUpload.message}
        </SuccessWrapper>
      )}
    </>
  )
}

const WarningWrapper = styled.div`
  padding: 0 20px 10px 20px;
  color: ${p => p.theme.ContentAccentWarning};
`

const WarningLink = styled.a`
  color: ${p => p.theme.ContentPrimary};
  text-decoration: none;
  cursor: pointer;
`

const ErrorWrapper = styled.div`
  padding: 0 20px 10px 20px;
  color: ${p => p.theme.ContentAccentError};
`

const SuccessWrapper = styled.div`
  padding: 0 20px 10px 20px;
  color: ${p => p.theme.ContentAccentSuccess};
`
