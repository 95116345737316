import React, { useMemo } from 'react'
import { Chip, standardIcons, useTheme } from '@chordco/component-library'
import { AudienceCategory } from 'types'

type Props = {
  category: AudienceCategory
}

export const AudienceChip: React.FC<Props> = ({ category }) => {
  const theme = useTheme()

  const leftIcon = useMemo(() => {
    switch (category.slug) {
      case 'customers':
      case 'first_time_customers':
      case 'repeat_customers':
        return standardIcons.PieChart

      case 'predicted':
        return standardIcons.TrendingUp

      default:
        return standardIcons.PieChart
    }
  }, [category])

  const color = useMemo(() => {
    switch (category.slug) {
      case 'customers':
      case 'first_time_customers':
      case 'repeat_customers':
        return 'neutral'

      case 'predicted':
        return 'primary'

      default:
        return 'neutral'
    }
  }, [category])

  return (
    <Chip
      leftIcon={leftIcon}
      leftIconFill={theme.ContentPrimary}
      leftIconSize={12}
      text={category.name}
      color={color}
      variant="solid-secondary"
    />
  )
}
