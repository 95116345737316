import React from 'react'
import styled from 'styled-components'
import { Button, Label, Modal } from '@chordco/component-library'
import { FormInput, FormTextArea } from 'components/common'
import { useForm } from 'hooks'
import { valTypes } from 'validationSchemas'
import { useValidateLookName } from './useValidateLookName'
import { usePublishLook } from './usePublishLook'
import { LookerReportType } from 'types'

interface PublishLookModalProps {
  lookName: string
  lookSlug: string
  onClose: () => void
  onPublish: () => void
  onNameAlreadyTaken: (data: {
    name: string
    description: string
    slug: string
    type: LookerReportType
  }) => void
}

export const PublishLookModal: React.FC<PublishLookModalProps> = ({
  lookName,
  lookSlug,
  onClose,
  onPublish,
  onNameAlreadyTaken,
}) => {
  const { validate } = useValidateLookName()
  const { publish, isLoading } = usePublishLook(onPublish)

  const submit = async () => {
    const lookData = {
      name: fields.lookName.value.toString(),
      description: fields.lookDescription.value.toString(),
      slug: fields.lookSlug.value.toString(),
      type: 'look' as LookerReportType,
    }

    const isValid = await validate(lookData.name)

    return isValid
      ? await publish({
          lookName: lookData.name,
          lookDescription: lookData.description,
          lookSlug: lookData.slug,
          replaceExisting: false,
        })
      : onNameAlreadyTaken(lookData)
  }

  const schema = {
    lookName: {
      label: 'Report Name',
      type: valTypes.string,
      initialValue: lookName,
      required: true,
    },
    lookDescription: {
      label: 'Description',
      type: valTypes.string,
      initialValue: '',
      required: false,
    },
    lookSlug: {
      label: 'Slug',
      type: valTypes.string,
      initialValue: lookSlug,
      required: true,
    },
  }

  const { fields, onSubmit } = useForm(schema, submit)

  return (
    <Modal onClose={onClose} title="Publish to Reports" width="480px">
      <form onSubmit={onSubmit} noValidate>
        <Section>
          <StyledLabel>
            Publish your custom look to make it accessible to your entire team.
            Once published, the look will appear in the Analytics &gt; Reports
            section.
          </StyledLabel>
          <FormInput field={fields.lookName} customWidth="100%" required />
          <FormTextArea field={fields.lookDescription} customWidth="100%" />
        </Section>

        <BottomSection>
          <Button purpose="tertiary" onClick={onClose}>
            Cancel
          </Button>

          <Button type="submit" purpose="primary" isLoading={isLoading}>
            Publish
          </Button>
        </BottomSection>
      </form>
    </Modal>
  )
}

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledLabel = styled(Label)`
  white-space: pre-wrap;
`

const BottomSection = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`
