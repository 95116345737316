import { Treatment } from './treatment'
import { ActorData, Adapter, EventData, User } from '../../types'

export interface ExperimentManagerConfig {
  user: User
  tenantId: number
  tenantName: string
  storeId: number
  adapter: Adapter
}

export default class ExperimentManager {
  private adapter: Adapter
  private user: User
  private tenantId: number
  private tenantName: string
  private storeId: number

  constructor(options: ExperimentManagerConfig) {
    this.adapter = options.adapter
    this.user = options.user
    this.tenantId = options.tenantId
    this.tenantName = options.tenantName
    this.storeId = options.storeId
  }

  async treatment(experimentName: string): Promise<Treatment> {
    return this.adapter.treatment(experimentName, this.getActorData())
  }

  async track(eventName: string, eventData: EventData): Promise<void> {
    this.adapter.track(eventName, eventData, this.getActorData())
  }

  private getActorData(): ActorData {
    return {
      actorType: 'hub/user',
      actorId: this.user.email || '',
      actorAttributes: {
        email: this.user.email || '',
        userId: this.user.id,
        tenantId: this.tenantId,
        tenantName: this.tenantName,
        storeId: this.storeId,
      },
    }
  }
}
