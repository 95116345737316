import { CreateTenantRequest } from 'types'
import { useTenantsData } from 'redux/state/tenants'
import { useIsLoading } from 'redux/utils'

export const useCreateTenantForm = (onClose?: () => void) => {
  const { createTenant } = useTenantsData()

  const isLoading = useIsLoading('createTenant')

  const submit = async (data: CreateTenantRequest) => {
    if (!createTenant) return

    await createTenant(data)

    if (onClose) onClose()
  }

  return { submit, isLoading }
}
