import React from 'react'
import styled from 'styled-components'
import { TableRow } from 'components/common/table'
import { H5 } from '@chordco/component-library'
import { useIsMobile } from 'hooks'
import { FivetranConnector } from 'api/fivetran/interfaces'
import { ModeledChip } from './ModeledChip'

type Props = {
  source: FivetranConnector
  onSelectSource: (source: FivetranConnector) => void
  modeled: boolean
}

export const SourceCard: React.FC<Props> = ({
  source,
  onSelectSource,
  modeled,
}) => {
  const isMobile = useIsMobile()

  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text
  }

  const maxLength = isMobile ? 200 : 130
  const truncatedDescription = truncateText(source.description ?? '', maxLength)

  return (
    <Card key={source.id} onClick={() => onSelectSource(source)}>
      <CardContent>
        <Header>
          <ModeledChipContainer>
            <ModeledChip
              modeled={modeled}
              tooltipWidth="180px"
              tooltipDirection="top"
            />
          </ModeledChipContainer>
        </Header>
        <TitleContainer>
          <Logo src={source.iconUrl} alt={source.name} />
          <Title>{source.name}</Title>
        </TitleContainer>
        <Description>{truncatedDescription}</Description>
      </CardContent>
    </Card>
  )
}

const Card = styled(TableRow)`
  height: 150px;
  border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
  border-radius: ${p => p.theme.CardMediumRadius};

  display: flex;
  align-content: center;

  img {
    margin-right: 16px;
  }

  :hover {
    border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
    background-color: ${p => p.theme.ComponentCardBgElevation1};
  }
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  height: 36px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  height: 36px;
`

const Title = styled(H5)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  display: inline-block;
  height: 24px;
`

const Description = styled.p`
  color: ${p => p.theme.GREY_3};
  font-size: 12px;
  text-overflow: ellipsis;
  margin: 0;
  padding-top: 8px;
`

const Logo = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 2px;
`

const ModeledChipContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 14px;
`
