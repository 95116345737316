import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Lock: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16667 9.91665C3.66041 9.91665 3.25 10.3271 3.25 10.8333V16.6666C3.25 17.1729 3.66041 17.5833 4.16667 17.5833H15.8333C16.3396 17.5833 16.75 17.1729 16.75 16.6666V10.8333C16.75 10.3271 16.3396 9.91665 15.8333 9.91665H4.16667ZM1.75 10.8333C1.75 9.49863 2.83198 8.41665 4.16667 8.41665H15.8333C17.168 8.41665 18.25 9.49863 18.25 10.8333V16.6666C18.25 18.0013 17.168 19.0833 15.8333 19.0833H4.16667C2.83198 19.0833 1.75 18.0013 1.75 16.6666V10.8333Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99985 2.41667C9.09369 2.41667 8.22465 2.77664 7.5839 3.41739C6.94315 4.05814 6.58318 4.92718 6.58318 5.83334V9.16667C6.58318 9.58088 6.2474 9.91667 5.83318 9.91667C5.41897 9.91667 5.08318 9.58088 5.08318 9.16667V5.83334C5.08318 4.52936 5.60119 3.27878 6.52324 2.35673C7.4453 1.43468 8.69587 0.916672 9.99985 0.916672C11.3038 0.916672 12.5544 1.43468 13.4765 2.35673C14.3985 3.27878 14.9165 4.52936 14.9165 5.83334V9.16667C14.9165 9.58088 14.5807 9.91667 14.1665 9.91667C13.7523 9.91667 13.4165 9.58088 13.4165 9.16667V5.83334C13.4165 4.92718 13.0566 4.05814 12.4158 3.41739C11.7751 2.77664 10.906 2.41667 9.99985 2.41667Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
