import { CensusConnectLink, CensusDestination } from 'api/census/interfaces'

export interface State<T> {
  loading: boolean
  error: Error | undefined
  items: T[]
  currentPage: number
  nextPage: number | null
  previousPage: number | null
}

const initialState: State<CensusConnectLink | CensusDestination> = {
  loading: false,
  error: undefined,
  items: [],
  currentPage: 1,
  nextPage: null,
  previousPage: null,
}

export { initialState }
