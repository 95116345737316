import { createSlice } from '@reduxjs/toolkit'
import { reduxApiClient } from 'redux/api'
import { useStateSliceAndDispatch } from 'redux/utils'
import type { BusinessError, BusinessErrorEvent } from 'types/businessError'

const SLICE_NAME = 'businessErrors'

type BusinessErrorsState = {
  businessErrorList: BusinessError[]
  businessErrorEvents: BusinessErrorEvent[]
}

const initialState: BusinessErrorsState = {
  businessErrorList: [],
  businessErrorEvents: [],
}

const { getBusinessErrors, getBusinessErrorEvents } = reduxApiClient

const businessErrorsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getBusinessErrors.fulfilled, (state, action) => {
      state.businessErrorList = action.payload.data
    })

    builder.addCase(getBusinessErrorEvents.fulfilled, (state, action) => {
      state.businessErrorEvents = action.payload.data
    })
  },
})

export default businessErrorsSlice.reducer

export const useBusinessErrorsData = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)

  return {
    state,
    getBusinessErrors: ({ environment, tenantId }) =>
      dispatch(getBusinessErrors({ tenantId, environment })),
    getBusinessErrorEvents: ({ tenantId, issueId }) =>
      dispatch(getBusinessErrorEvents({ tenantId, issueId })),
  }
}
