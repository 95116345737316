import React from 'react'
import { Loading, Page } from '@chordco/component-library'

export const LoadingPage: React.FC = () => {
  return (
    <Page navOpen={false}>
      <Loading />
    </Page>
  )
}
