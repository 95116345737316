import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Button, standardIcons } from '@chordco/component-library'

import { useUserNotifications } from 'redux/state/userNotifications'
import { UserNotification } from 'types'
import { InverseThemeProvider } from 'themes/ThemeSwitcherProvider'

const { Minus, CloseX, Bell } = standardIcons

export const Insights: React.FC = () => {
  const scrollToBottomRef = useRef<HTMLDivElement>(null)
  const [expanded, setExpanded] = useState(false)
  const { notifications, getUserNotifications } = useUserNotifications()
  const unreadNotifications = notifications.filter(n => !n.readAt).length

  useEffect(() => {
    getUserNotifications()
  }, [])

  // We want to show the newest notifications first AND at the bottom of the list,
  // so we must scroll to it. This has the added benefit of flashing the scrollbar
  // if there are more items than can be viewed.
  useEffect(() => {
    if (expanded) {
      scrollToBottomRef.current?.scrollTo({
        top: scrollToBottomRef.current.scrollHeight,
        behavior: 'auto',
      })
    }
  }, [expanded])

  if (!notifications.length) {
    return null
  }

  return (
    <>
      {expanded && (
        <InverseThemeProvider>
          <Container
            expanded={expanded}
            onClick={() => {
              if (!expanded) {
                setExpanded(true)
              }
            }}
          >
            <Header>
              <h6>Chord Insights</h6>
              {expanded && (
                <Button
                  color="neutral"
                  variant="ghost"
                  size="small"
                  onClick={() => setExpanded(false)}
                  icon={Minus}
                />
              )}
            </Header>
            {expanded && (
              <>
                <NotificationsContainer ref={scrollToBottomRef}>
                  {notifications
                    .filter(n => !n.dismissedAt)
                    .map(notification => (
                      <Notification
                        key={notification.id}
                        notification={notification}
                      />
                    ))}
                </NotificationsContainer>
              </>
            )}
          </Container>
        </InverseThemeProvider>
      )}

      <LogoButtonContainer>
        <LogoButton onClick={() => setExpanded(e => !e)} expanded={expanded}>
          {expanded ? (
            <CloseX scale={16} />
          ) : (
            <>
              <Bell scale={16} />
              <UnreadNotificationCount>
                {unreadNotifications}
              </UnreadNotificationCount>
            </>
          )}
        </LogoButton>
      </LogoButtonContainer>
    </>
  )
}

const Container = styled.div<{ expanded: boolean }>`
  width: 350px;
  max-height: 520px;
  padding: 1rem;
  position: fixed;
  bottom: 5rem;
  right: 1.5rem;
  background-color: ${p => p.theme.ComponentWorkspaceBgPage};
  color: ${p => p.theme.ContentPrimary};
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  ${p =>
    p.expanded &&
    css`
      & > *:not(:last-child) {
        margin-bottom: 1rem;
      }
    `}

  ${p => !p.expanded && 'cursor: pointer;'}
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > h6 {
    font-size: 20px;
    margin: 0;
  }
`

const NotificationsContainer = styled.div`
  max-height: calc(520px - 1rem - 1rem - 1rem - 25px);
  overflow-y: scroll;
  border-radius: 12px;

  & > * {
    margin-bottom: 1rem;
  }
`

const LogoButton = styled(Button)<{ expanded: boolean }>`
  border-radius: 100%;
  height: 48px;
  padding: 16px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  ${p =>
    p.expanded
      ? css`
          width: 48px;
          border-radius: 100%;
        `
      : css`
          width: 5.3rem;
          border-radius: 24px;

          & > span {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 32px;

            & > svg {
              margin-top: 2px;
            }
          }
        `}

  & path {
    fill: ${p => p.theme.WHITE};
  }
`

const UnreadNotificationCount = styled.span`
  text-align: center;
`

const LogoButtonContainer = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1.5rem;
`

const Notification: React.FC<{ notification: UserNotification }> = ({
  notification,
}) => {
  const time = moment(notification.createdAt).format('MMM D')
  // @TODO Once the backend endpoint exists for updating notification status
  // exists, wire this back up to the CTA click handler.
  // const { updateUserNotification } = useUserNotifications()
  //
  // const markNotificationAsRead = async () => {
  //   if (!notification.readAt) {
  //     updateUserNotification({
  //       notificationId: notification.id,
  //       payload: {
  //         readAt: new Date().toISOString(),
  //         dismissedAt: notification.dismissedAt,
  //       },
  //     })
  //   }
  // }

  return (
    <NotificationContainer>
      <Label level={notification.level}>{notification.label}</Label>
      <NotificationText>{notification.copy}</NotificationText>
      <NotificationFooter>
        {notification.action.startsWith('http') ? (
          <NotificationCTA target="_blank" href={notification.action}>
            {notification.actionText}
          </NotificationCTA>
        ) : (
          <InternalNotificationCTA to={notification.action}>
            {notification.actionText}
          </InternalNotificationCTA>
        )}
        <Time dateTime={notification.createdAt}>{time}</Time>
      </NotificationFooter>
    </NotificationContainer>
  )
}

const NotificationContainer = styled.div`
  background-color: ${p => p.theme.ComponentCardBgElevation1};
  border-radius: ${p => p.theme.CardSmallRadius};
  padding: 14px 16px;
`

const NotificationText = styled.div`
  margin-bottom: 10px;
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`

const NotificationFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
// const NotificationCTA = styled.button`
//   variant: outlined;
// `
const NotificationCTA = styled.a`
  border: 1px solid ${p => p.theme.BorderOptimal};
  background-color: transparent;
  border-radius: ${p => p.theme.ButtonRadius};
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  padding: 4px 8px;
  cursor: pointer;
  text-decoration: none;
  color: ${p => p.theme.ContentPrimary};

  &:hover,
  &:focus {
    background-color: ${p => p.theme.ComponentButtonTertiaryBgHover};
  }
`

const InternalNotificationCTA = NotificationCTA.withComponent(Link)

const Label = styled.span<{ level?: UserNotification['level'] }>`
  // border: 1px solid ${p => p.theme.GREY_2};
  color: ${p => p.theme.ContentSecondary};
  border-radius: 4px;
  background-color: ${p => {
    switch (p.level) {
      case 'info':
        return p.theme.BgAccentAdaptiveAAPrimaryContentInfo
      case 'success':
        return p.theme.BgAccentAdaptiveAAPrimaryContentSuccess
      case 'warning':
        return p.theme.BgAccentAdaptiveAAPrimaryContentWarning
      case 'error':
        return p.theme.BgAccentAdaptiveAAPrimaryContentError
      // @TODO Grouping all of the non-log leveled colors as pink for now.
      case 'primary':
      case 'secondary':
      case 'accent':
        return p.theme.BgAccentAdaptiveAAPrimaryContentPrimary
      default:
        return p.theme.BgAccentAdaptiveAAPrimaryContentNeutral
    }
  }};
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  padding: 4px 10px;
  margin-bottom: 8px;
`

const Time = styled.time`
  font-size: 10px;
  line-height: 12px;
  color: ${p => p.theme.ContentSecondary};
  vertical-align: middle;
  margin-right: 8px;
`
