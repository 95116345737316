import { CreateSheetRequest } from 'types'
import { useConfigSheetsData } from 'redux/state/configSheets'
import { useIsLoading } from 'redux/utils'

export const useCreateConfigForm = (onClose?: () => void) => {
  const { createSheet } = useConfigSheetsData()

  const isLoading = useIsLoading('createSheet')

  const submit = async (data: CreateSheetRequest) => {
    if (!createSheet) return

    await createSheet(data)

    if (onClose) onClose()
  }

  return { submit, isLoading }
}
