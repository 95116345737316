import {
  Integration,
  IntegrationType,
  PossibleIntegrationSettings,
} from 'types'
import { useStoreSetupData } from 'redux/state/storeSetup'
import { useIsLoading } from 'redux/utils'

export const useIntegrationForm = (
  integrationType: IntegrationType,
  integration?: Integration,
  onClose?: () => void
) => {
  const isEdit = !!integration
  const { createIntegration, updateIntegration } = useStoreSetupData()

  const isLoading = useIsLoading('createIntegration', 'updateIntegration')

  const submit = async (data: PossibleIntegrationSettings) => {
    if (!createIntegration || !updateIntegration) return

    const args = {
      integration: data,
      integrationType: integrationType,
    }

    let res

    if (isEdit) res = await updateIntegration(args)
    else res = await createIntegration(args)

    if (!res.error && onClose) onClose()
  }

  return { submit, isLoading, isEdit }
}
