import React, { useState, InputHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import TextareaAutosize from 'react-autosize-textarea'
import { Icon } from '../controls'
import { standardIcons } from '../icons'

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  hint?: string
  errorMessage?: string
}

export const RichTextArea: React.FC<Props> = ({ errorMessage, ...props }) => {
  const [isBold, toggleIsBold] = useState(false)
  const [isItalic, toggleIsItalic] = useState(false)
  const [isUnderline, toggleIsUnderline] = useState(false)
  const [isStrike, toggleIsStrike] = useState(false)
  const [isBullet, toggleIsBullet] = useState(false)
  const [isNumberedList, toggleIsNumberedList] = useState(false)

  const { label, hint, disabled } = props

  return (
    <>
      <Label>{label}</Label>

      <Container hint={hint} errorMessage={errorMessage} disabled={disabled}>
        <StyledTextArea {...props} errorMessage={errorMessage} type="text" />
        <Controls>
          <StyledIcon
            selected={isBold}
            onClick={() => toggleIsBold(!isBold)}
            size="tiny"
            icon={standardIcons.Bold}
          />
          <StyledIcon
            selected={isItalic}
            onClick={() => toggleIsItalic(!isItalic)}
            size="tiny"
            icon={standardIcons.Italic}
          />
          <StyledIcon
            selected={isUnderline}
            onClick={() => toggleIsUnderline(!isUnderline)}
            size="tiny"
            icon={standardIcons.Underline}
          />
          <StyledIcon
            selected={isStrike}
            onClick={() => toggleIsStrike(!isStrike)}
            size="tiny"
            icon={standardIcons.Strike}
          />
          <StyledIcon
            selected={isBullet}
            onClick={() => toggleIsBullet(!isBullet)}
            size="tiny"
            icon={standardIcons.Bullet}
          />
          <StyledIcon
            selected={isNumberedList}
            onClick={() => toggleIsNumberedList(!isNumberedList)}
            size="tiny"
            icon={standardIcons.NumberedList}
          />
        </Controls>
      </Container>

      {hint && !errorMessage && <Hint>{hint}</Hint>}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  )
}

const StyledIcon = styled(Icon)<{ selected?: boolean }>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.GREY_30 : 'inherit'};
  path {
    fill: ${({ theme, selected }) =>
      selected ? theme.GREY_80 : theme.GREY_50};
  }
`

const Controls = styled.div`
  display: flex;
  height: 24px;
  padding: 6px 8px;
`

const Container = styled.label<{
  hint?: string
  errorMessage?: string
  disabled?: boolean
}>`
  display: block;
  position: relative;
  margin-bottom: ${({ hint, errorMessage }) =>
    hint || errorMessage ? '6px' : '0'};
  box-sizing: border-box;
  width: 280px;
  min-height: 80px;
  max-height: 140px; // Increase this to allow for auto-resizing
  border: solid 1px ${({ theme }) => theme.GREY_20};
  background-color: ${({ theme }) => theme.GREY_20};
  border-radius: 8px;
  transition: 100ms;

  :hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.GREY_30 : theme.WHITE};
  }

  :focus {
    background-color: ${({ theme }) => theme.WHITE};
    border-color: ${({ theme }) => theme.PRIMARY_50};
  }
`

const Label = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
`

const StyledTextArea = styled(TextareaAutosize)<Props>`
  resize: none;
  cursor: text;
  overflow: auto;
  box-sizing: border-box;
  width: 278px;
  min-height: 80px;
  max-height: 100px; // Increase this to allow for auto-resizing
  padding: 10px 12px;
  border: none;
  margin: 1px 0;
  background-color: inherit;
  outline: none;
  font-size: 14px;

  ${({ errorMessage, theme }) =>
    errorMessage &&
    css`
      border-color: ${theme.RED_50};
      color: ${theme.RED_50};

      :hover,
      :focus {
        border-color: ${theme.RED_50};
      }
    `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      border: none;
      background-color: ${theme.GREY_30};
      color: ${theme.GREY_50};
      pointer-events: none;
    `}
`

const Hint = styled.div`
  white-space: nowrap;
  font-size: 12px;
  position: absolute;
  bottom: -16px;
  left: 0;
`

const ErrorMessage = styled(Hint)`
  color: ${({ theme }) => theme.RED_50};
`
