import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AvailableReport } from 'types'
import { ReportCard } from './ReportCard'
import { useHistory } from 'react-router-dom'
import { ReportToolbar } from './ReportToolbar'
import { useReportsFilter } from 'redux/state/reportsFilter'
import { SimpleBar } from '@chordco/component-library'
import EmptySharedReports from './EmptySharedReports'

export type ReportsProps = {
  reports: AvailableReport[]
}

export const Reports: React.FC<ReportsProps> = ({ reports }) => {
  const history = useHistory()

  const {
    state: { selectedFilter },
  } = useReportsFilter()

  const [filteredReports, setFilteredReports] = useState(reports)

  const handleSelectReport = (report: AvailableReport) => {
    history.push({
      pathname: `/analytics/reports/${report.slug}`,
      state: {
        title: report.label,
        createdBy: report.createdBy,
        type: report.metricType,
      },
    })
  }

  useEffect(() => {
    if (selectedFilter === 'all_reports') {
      setFilteredReports(reports)
    } else if (selectedFilter === 'shared_reports') {
      setFilteredReports(reports.filter(report => report.createdBy !== null))
    } else if (selectedFilter === 'chord_reports') {
      setFilteredReports(reports.filter(report => report.createdBy === null))
    }
  }, [selectedFilter, reports])

  return (
    <ScrollableContainer>
      <ReportToolbar />
      <ReportsContainer>
        {selectedFilter === 'shared_reports' && filteredReports.length === 0 ? (
          <EmptySharedReports />
        ) : (
          filteredReports.map((report, index) => (
            <ReportCard
              key={`${report.slug}-${index}`}
              report={report}
              onSelectReport={handleSelectReport}
            />
          ))
        )}
      </ReportsContainer>
    </ScrollableContainer>
  )
}

const ScrollableContainer = styled(SimpleBar)`
  padding: 16px 64px 32px 64px;
  height: calc(100vh - 120px);
  margin-right: 2px;

  .simplebar-scrollbar::before {
    background-color: ${p => p.theme.BorderStrong};
    margin-top: 68px;
  }
`

const ReportsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  grid-gap: 16px;
`
