import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const ArrowLeft: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.4165 9.99985C3.4165 9.58563 3.75229 9.24985 4.1665 9.24985L15.8332 9.24985C16.2474 9.24985 16.5832 9.58563 16.5832 9.99985C16.5832 10.4141 16.2474 10.7498 15.8332 10.7498L4.1665 10.7498C3.75229 10.7498 3.4165 10.4141 3.4165 9.99985Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.63617 10.5303C3.34328 10.2374 3.34328 9.76257 3.63617 9.46968L9.46951 3.63634C9.7624 3.34345 10.2373 3.34345 10.5302 3.63634C10.8231 3.92924 10.8231 4.40411 10.5302 4.697L5.22716 10L10.5302 15.303C10.8231 15.5959 10.8231 16.0708 10.5302 16.3637C10.2373 16.6566 9.7624 16.6566 9.46951 16.3637L3.63617 10.5303Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
