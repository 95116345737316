import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Tree: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 3C6.25 2.0335 7.0335 1.25 8 1.25H12C12.9665 1.25 13.75 2.0335 13.75 3V5C13.75 5.9665 12.9665 6.75 12 6.75H10.75V9.25H14.5C15.4665 9.25 16.25 10.0335 16.25 11V13.25H16.9999C17.9664 13.25 18.7499 14.0335 18.7499 15V17C18.7499 17.9665 17.9664 18.75 16.9999 18.75H14C13.0335 18.75 12.25 17.9665 12.25 17V15C12.25 14.0335 13.0335 13.25 14 13.25H14.75V11C14.75 10.8619 14.6381 10.75 14.5 10.75H5.5C5.36193 10.75 5.25 10.8619 5.25 11V13.25H6C6.9665 13.25 7.75 14.0335 7.75 15V17C7.75 17.9665 6.9665 18.75 6 18.75H3C2.0335 18.75 1.25 17.9665 1.25 17V15C1.25 14.0335 2.0335 13.25 3 13.25H3.75V11C3.75 10.0335 4.5335 9.25 5.5 9.25H9.25V6.75H8C7.0335 6.75 6.25 5.9665 6.25 5V3ZM8 2.75C7.86193 2.75 7.75 2.86193 7.75 3V5C7.75 5.13807 7.86193 5.25 8 5.25H12C12.1381 5.25 12.25 5.13807 12.25 5V3C12.25 2.86193 12.1381 2.75 12 2.75H8ZM3 14.75C2.86193 14.75 2.75 14.8619 2.75 15V17C2.75 17.1381 2.86193 17.25 3 17.25H6C6.13807 17.25 6.25 17.1381 6.25 17V15C6.25 14.8619 6.13807 14.75 6 14.75H3ZM13.75 15C13.75 14.8619 13.8619 14.75 14 14.75H16.9999C17.138 14.75 17.2499 14.8619 17.2499 15V17C17.2499 17.1381 17.138 17.25 16.9999 17.25H14C13.8619 17.25 13.75 17.1381 13.75 17V15Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
