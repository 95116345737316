import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Maximize: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 1.75C12.0858 1.75 11.75 2.08579 11.75 2.5C11.75 2.91421 12.0858 3.25 12.5 3.25H15.6892L11.1362 7.803C10.8433 8.0959 10.8433 8.57077 11.1362 8.86366C11.4291 9.15656 11.9039 9.15656 12.1968 8.86366L16.75 4.3105V7.5C16.75 7.91421 17.0858 8.25 17.5 8.25C17.9142 8.25 18.25 7.91421 18.25 7.5V2.5C18.25 2.08579 17.9142 1.75 17.5 1.75H17.4998H12.5ZM3.25 12.5C3.25 12.0858 2.91421 11.75 2.5 11.75C2.08579 11.75 1.75 12.0858 1.75 12.5V17.5V17.5C1.75 17.6979 1.82665 17.8779 1.95188 18.0119C1.96408 18.025 1.97674 18.0376 1.98983 18.0498C2.05396 18.1094 2.12599 18.1556 2.20239 18.1886C2.29363 18.2281 2.39426 18.25 2.5 18.25H7.5C7.91421 18.25 8.25 17.9142 8.25 17.5C8.25 17.0858 7.91421 16.75 7.5 16.75H4.31065L8.86366 12.197C9.15656 11.9041 9.15656 11.4292 8.86366 11.1363C8.57077 10.8434 8.0959 10.8434 7.803 11.1363L3.25 15.6893V12.5Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
