import React from 'react'
import orderDeskLogo from 'assets/OrderDeskLogo.png'
import { useForm } from 'hooks'
import { ValSchemaGeneric } from 'validationSchemas'
import { Fields } from '../common'
import { Integration, OrderDeskSettings } from 'types'
import { useIntegrationForm } from 'redux/hooks'
import { FormInput, FormSecureInput } from 'components/common'
import { getFulfillmentConfirmationMessage } from 'utils/confirmationMessages'
import { MultipleProviderForm } from '../MultipleProviderForm'

type Props = {
  onChangeProvider: () => void
  onClose: () => void
  integration?: Integration<OrderDeskSettings>
}

const ORDER_DESK_LOGIN_LINK = 'https://app.orderdesk.me/login'
const getSchema = (
  settings?: OrderDeskSettings
): ValSchemaGeneric<['storeId', 'apiKey']> => ({
  storeId: {
    label: 'Store ID',
    type: 'string',
    initialValue: settings?.storeId,
  },
  apiKey: {
    label: 'API Key',
    type: 'string',
    initialValue: settings?.apiKey,
  },
})
export const OrderDeskForm: React.FC<Props> = ({
  onChangeProvider,
  onClose,
  integration,
}) => {
  const { submit, isLoading } = useIntegrationForm(
    'order_desk',
    integration,
    onClose
  )

  const schema = getSchema(integration?.settings)
  const { fields, onSubmit } = useForm(schema, submit)

  const integrationTitle = 'Order Desk'
  const confirmationMessage =
    getFulfillmentConfirmationMessage(integrationTitle)

  return (
    <MultipleProviderForm
      integrationType="order_desk"
      integrationTitle={integrationTitle}
      logo={orderDeskLogo}
      loginLink={ORDER_DESK_LOGIN_LINK}
      confirmationMessage={confirmationMessage}
      onChangeProvider={onChangeProvider}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <Fields>
        <FormInput field={fields.storeId} customWidth="100%" />
        <FormSecureInput field={fields.apiKey} customWidth="100%" />
      </Fields>
    </MultipleProviderForm>
  )
}
