import React from 'react'
import styled from 'styled-components'
import { Avatar, AvatarSize } from '@chordco/component-library'

const ccImages = {
  americanExpress: '/images/American Express.jpg',
  chinaUnionPay: '/images/China UnionPay.jpg',
  discoverDiners: '/images/Discover.jpg',
  japanCreditBureau: '/images/JBC.jpg',
  mastercard: '/images/Mastercard.jpg',
  visa: '/images/Visa.jpg',
}

const defaultCCImage = '/images/Default.jpg'

interface Props {
  type: string
  size?: AvatarSize
}

export const CreditCardImage: React.FC<Props> = ({
  type,
  size = 48,
  ...props
}) => {
  return (
    <StyledAvatar
      {...props}
      size={size}
      src={ccImages[type] || defaultCCImage}
    />
  )
}

const StyledAvatar = styled(Avatar)`
  margin-right: 8px;
`
