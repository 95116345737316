import { Label } from '@chordco/component-library'
import { FormControl, Select, SelectChangeEvent, MenuItem } from '@mui/material'
import { useExperiments } from 'hooks'
import React from 'react'
import { useReportsFilter } from 'redux/state/reportsFilter'
import styled from 'styled-components'

export const ReportToolbar: React.FC = () => {
  const {
    state: { selectedFilter },
    setFilter,
  } = useReportsFilter()

  const myFolderTreatment = useExperiments('hub_my_folder_nav_link')

  const options = React.useMemo(() => {
    return myFolderTreatment?.enabled
      ? [
          { id: 'all_reports', label: 'All Reports' },
          { id: 'shared_reports', label: 'Shared Reports' },
          { id: 'chord_reports', label: 'Chord Reports' },
        ]
      : [{ id: 'all_reports', label: 'All Reports' }]
  }, [myFolderTreatment])

  const [selectedValue, setSelectedValue] = React.useState(selectedFilter)

  const onSelect = (slug: string) => {
    setSelectedValue(slug)
    setFilter(slug)
  }

  return (
    <ReportToolbarContainer>
      <FilterContainer>
        <Label>Select View</Label>
        <FormControl sx={{ minWidth: 200 }} size="small">
          <Select
            value={options.find(o => o.id === selectedValue)?.id}
            onChange={(event: SelectChangeEvent<string>) => {
              onSelect(event.target.value)
            }}
          >
            {options.length > 0 &&
              options.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}

            {options.length === 0 && (
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </FilterContainer>
    </ReportToolbarContainer>
  )
}

const ReportToolbarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 16px;
`

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`
