import { capitalizeFirstLetter } from '@chordco/component-library'
import { useState } from 'react'
import { User } from 'types'
import { EnvVarConfig } from 'utils/envVarConfig'

export type ConnectorEventType = 'CONNECTOR_CREATED' | 'CONNECTOR_DISCONNECTED'

export interface SlackEventHookResult {
  error: string | null
  isSuccess: boolean
  sendSlackEvent: (
    eventType: ConnectorEventType,
    eventData: ConnectorEventData
  ) => void
}

export interface ConnectorEventData {
  tenantName: string
  connectorName: string
  destinationGroupId: string
  hubUser: User
}

const useFivetranSlackEvent = (): SlackEventHookResult => {
  const [error, setError] = useState<string | null>(null)
  const [isSuccess, setIsSuccess] = useState(false)

  const sendSlackEvent = async (
    eventType: ConnectorEventType,
    eventData: ConnectorEventData
  ) => {
    setIsSuccess(false)
    setError(null)

    const { tenantName, connectorName, destinationGroupId, hubUser } = eventData

    let eventText = ''
    let userInfoText = ''

    // Email should be enforced but since it's defined as optional in the User type, we need to
    // handle the case where it's missing for some odd reason. At least we can log the user's ID.
    const hubUserEmail = hubUser.email ?? `Missing email for user ${hubUser.id}`

    if (eventType === 'CONNECTOR_CREATED') {
      eventText = '*Fivetran Connector Created Event*'
      userInfoText = `*Created by:*\n${hubUserEmail}`
    } else if (eventType === 'CONNECTOR_DISCONNECTED') {
      eventText = '*Fivetran Connector Disconnected Event*'
      userInfoText = `*Disconnected by:*\n${hubUserEmail}`
    } else {
      setError('Invalid event type')
      return
    }

    const tenantText = `*Hub Tenant:*\n${capitalizeFirstLetter(tenantName)}`

    const connectorText = `*Connector Name:*\n${capitalizeFirstLetter(
      connectorName
    )}`

    const destinationGroupURL = `https://fivetran.com/dashboard/warehouse?groupId=${destinationGroupId}`
    const destinationGroupText = `*Destination Group:*\n<${destinationGroupURL}|${destinationGroupId}>`

    const alertColor = eventType === 'CONNECTOR_CREATED' ? '#36a64f' : '#ff0000'

    const message = {
      attachments: [
        {
          color: alertColor,
          blocks: [
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: eventText,
              },
            },
            {
              type: 'section',
              fields: [
                {
                  type: 'mrkdwn',
                  text: tenantText,
                },
                {
                  type: 'mrkdwn',
                  text: connectorText,
                },
                {
                  type: 'mrkdwn',
                  text: destinationGroupText,
                },
                {
                  type: 'mrkdwn',
                  text: userInfoText,
                },
              ],
            },
          ],
        },
      ],
    }

    const payload = JSON.stringify(message)

    // Don't send the message to Slack if we're not in production
    if (process.env.NODE_ENV !== 'production') {
      setIsSuccess(true)

      // eslint-disable-next-line no-console
      console.log('Fivetran Slack event: %j', payload)
      return
    }

    try {
      const url = EnvVarConfig.REACT_APP_FIVETRAN_SLACK_WEBHOOK
      const response = await fetch(url, {
        method: 'POST',
        body: payload,
      })

      if (!response.ok) {
        throw new Error(
          `Failed to send Fivetran message to Slack: ${response.statusText}`
        )
      }

      setIsSuccess(true)
    } catch (error: any) {
      setError(
        error.message ||
          'An error occurred while sending a Fivetran event to Slack'
      )
    }
  }

  return { error, isSuccess, sendSlackEvent }
}

export default useFivetranSlackEvent
