import React from 'react'
import styled from 'styled-components'
import logo from './ContentfulLogo2.png'

const Icon = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border ${p => `1px solid ${p.theme.BorderAbsolute}`};
  background-color: ${p => p.theme.ReverseBackgroundBase1};
  border-radius: 100%;
`

export const ContentfulNavIcon: React.FC = () => (
  <Icon src={logo} alt="Contentful logo" />
)

// import React from 'react'
// import { IconProps } from '@chordco/component-library'

// export const ContentfulNavIcon: React.FC<IconProps> = ({
//   scale = 22,
//   ...props
// }) => (
//   <svg
//     {...props}
//     width={scale}
//     height={scale}
//     viewBox="0 0 22 22"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <mask
//       id="mask0_7022_6102"
//       style={{ maskType: 'alpha' }}
//       maskUnits="userSpaceOnUse"
//       x="1"
//       y="1"
//       width="20"
//       height="20"
//     >
//       <circle cx="11" cy="11" r="10" fill="#C4C4C4" />
//     </mask>
//     <g mask="url(#mask0_7022_6102)">
//       <rect width="20" height="20" transform="translate(1 1)" fill="white" />
//       <path
//         d="M8.69282 13.8267C7.99462 13.1695 7.5839 12.2865 7.5839 11.2597C7.5839 10.2329 7.99462 9.34992 8.65175 8.69278C9.22675 8.11779 9.22675 7.23476 8.65175 6.65976C8.07676 6.08477 7.19373 6.08477 6.61873 6.65976C5.46874 7.83029 4.75 9.47313 4.75 11.2597C4.75 13.0463 5.48928 14.6892 6.6598 15.8597C7.2348 16.4347 8.11783 16.4347 8.69282 15.8597C9.22675 15.2847 9.22675 14.4017 8.69282 13.8267Z"
//         fill="#FAE501"
//       />
//       <path
//         d="M8.69278 8.69282C9.34992 7.99461 10.2329 7.5839 11.2597 7.5839C12.2865 7.5839 13.1695 7.99461 13.8267 8.65175C14.4017 9.22675 15.2847 9.22675 15.8597 8.65175C16.4347 8.07676 16.4347 7.19373 15.8597 6.61873C14.6686 5.46874 13.0463 4.75 11.2597 4.75C9.47313 4.75 7.85082 5.46874 6.65976 6.6598C6.08477 7.2348 6.08477 8.11783 6.65976 8.69282C7.23476 9.22675 8.11778 9.22675 8.69278 8.69282Z"
//         fill="#4FB5E1"
//       />
//       <path
//         d="M13.8267 13.8262C13.1695 14.5244 12.2865 14.9351 11.2597 14.9351C10.2329 14.9351 9.34992 14.5244 8.69278 13.8673C8.11778 13.2923 7.23476 13.2923 6.65976 13.8673C6.08477 14.4423 6.08477 15.3253 6.65976 15.9003C7.85082 17.0503 9.47313 17.769 11.2597 17.769C13.0463 17.769 14.6892 17.0298 15.8597 15.8592C16.4347 15.2842 16.4347 14.4012 15.8597 13.8262C15.3052 13.2923 14.4017 13.2923 13.8267 13.8262Z"
//         fill="#F05751"
//       />
//       <path
//         d="M7.68648 9.08391C8.46904 9.08391 9.10344 8.44951 9.10344 7.66695C9.10344 6.88439 8.46904 6.25 7.68648 6.25C6.90392 6.25 6.26953 6.88439 6.26953 7.66695C6.26953 8.44951 6.90392 9.08391 7.68648 9.08391Z"
//         fill="#0681B6"
//       />
//       <path
//         d="M7.68648 16.2714C8.46904 16.2714 9.10344 15.637 9.10344 14.8545C9.10344 14.0719 8.46904 13.4375 7.68648 13.4375C6.90392 13.4375 6.26953 14.0719 6.26953 14.8545C6.26953 15.637 6.90392 16.2714 7.68648 16.2714Z"
//         fill="#CD4739"
//       />
//     </g>
//     <path
//       d="M11 20C6.02944 20 2 15.9706 2 11H0C0 17.0751 4.92487 22 11 22V20ZM20 11C20 15.9706 15.9706 20 11 20V22C17.0751 22 22 17.0751 22 11H20ZM11 2C15.9706 2 20 6.02944 20 11H22C22 4.92487 17.0751 0 11 0V2ZM11 0C4.92487 0 0 4.92487 0 11H2C2 6.02944 6.02944 2 11 2V0Z"
//       fill="#76777A"
//     />
//   </svg>
// )
