import {
  capitalizeFirstLetter,
  Dot,
  Input,
  standardIcons,
  useClickOutside,
  useTheme,
} from '@chordco/component-library'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useIsMobile } from 'hooks'
import { sortStrings } from 'utils'

const { ChevronDown, Check } = standardIcons

export interface OptionType {
  id: number
  name: string
}

interface Props {
  options: OptionType[]
  selectedOption: OptionType | undefined
  selectOption: (option: OptionType) => void
  type: 'environment' | 'tenant'
  dotColors?: Record<string, string>
  enableSearch?: boolean
  sortAlphabetically?: boolean
}

export const Switcher: React.FC<Props> = ({
  options,
  selectedOption,
  selectOption,
  type,
  dotColors,
  enableSearch,
  sortAlphabetically,
}) => {
  const theme = useTheme()

  const isMobile = useIsMobile()
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')

  const title = capitalizeFirstLetter(type)

  let optionsToDisplay = options
  if (enableSearch && search.length) {
    optionsToDisplay = options.filter(o =>
      o.name.toLowerCase().includes(search.toLowerCase())
    )
  }

  if (sortAlphabetically) {
    optionsToDisplay = sortStrings(optionsToDisplay, o => o.name, true)
  }

  const searchInputId = `${type}-search-input`

  const hasMultipleOptions = options.length > 1

  useEffect(() => {
    if (open) {
      document.getElementById(searchInputId)?.focus()
    } else {
      setSearch('')
    }
  }, [open])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const ref = useRef(null)
  useClickOutside(ref, handleClose)

  return (
    <Container isMobile={isMobile} showPointer={hasMultipleOptions} ref={ref}>
      {open ? (
        <Open>
          {enableSearch ? (
            <StyledInput
              leftIcon={standardIcons.Search}
              placeholder={`Search ${type}s`}
              value={search}
              onChange={e => setSearch(e.target.value)}
              id={searchInputId}
            />
          ) : (
            <SwitchEnv onClick={() => setOpen(false)}>Switch {title}</SwitchEnv>
          )}
          {optionsToDisplay.map(option => (
            <Option
              key={option.id}
              onClick={() => {
                selectOption(option)
                setOpen(false)
              }}
            >
              <Current paddingLeft={!!dotColors}>
                {dotColors && <StyledDot color={dotColors[option.name]} />}
                {option.name}
              </Current>
              {option === selectedOption && (
                <SelectedCheck size="large" fill={theme.PRIMARY_50} />
              )}
            </Option>
          ))}
        </Open>
      ) : (
        <Closed
          onClick={() => {
            if (hasMultipleOptions) setOpen(o => !o)
          }}
        >
          <Label>{title}</Label>
          <Current paddingLeft={!!dotColors}>
            {dotColors && (
              <StyledDot color={dotColors[selectedOption?.name || '']} />
            )}
            {selectedOption?.name}
          </Current>
          {hasMultipleOptions && <Chevron fill={theme.ContentPrimary} />}
        </Closed>
      )}
    </Container>
  )
}

const Container = styled.div<{ isMobile: boolean; showPointer: boolean }>`
  position: relative;
  width: ${p => (p.isMobile ? 'calc(100vw - 32px)' : '248px')};
  margin: 0 0 16px 16px;
  background-color: ${p => p.theme.ComponentCardBgElevation2};
  border-radius: ${p => p.theme.CardSmallRadius};
  cursor: ${p => (p.showPointer ? 'pointer' : 'default')};
`

const Closed = styled.div`
  padding: 8px 16px;
  min-height: 52px;
`

const Open = styled.div`
  padding-bottom: 4px;
  border: transparent;
  border-radius: 8px;
  max-height: 313px;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none;
`

const Label = styled.div`
  color: ${p => p.theme.ContentTertiary};
  font-size: 12px;
`

const Current = styled.div<{ paddingLeft?: boolean }>`
  position: relative;
  padding-left: ${p => (p.paddingLeft ? '12px' : 0)};
  color: ${p => p.theme.ContentPrimary};
  text-transform: capitalize;
`

const StyledDot = styled(Dot)`
  position: absolute;
  top: 6px;
  left: 0;
`

const Chevron = styled(ChevronDown)`
  position: absolute;
  top: 16px;
  right: 16px;

  path {
    fill: ${p => p.theme.ContentPrimary};
  }
`

const SwitchEnv = styled(Label)`
  margin-bottom: 4px;
  padding: 16px 6px 16px 10px;
  border-bottom: solid 1px ${p => p.theme.BorderHairline};
`

const Option = styled.div`
  position: relative;
  height: 48px;
  padding: 16px 16px;

  :hover {
    background-color: ${p => p.theme.ComponentCardBgElevation2};
  }

  :active {
    background-color: ${p => p.theme.ComponentCardBgElevation1};
  }
`

const SelectedCheck = styled(Check)`
  position: absolute;
  top: 12px;
  right: 16px;
`

export const StyledInput = styled(Input)`
  margin-right: 20px;
  margin-top: 0px;
  width: 100%;
  border: none;
  border-radius: 0px;
  border-bottom: solid 1px ${p => p.theme.BorderHairline};

  :focus {
    border: none;
    border-bottom: solid 1px ${p => p.theme.BorderHairline};
  }

  :hover {
    border: none;
    border-bottom: solid 1px ${p => p.theme.BorderHairline};
  }
`
