import { createSlice } from '@reduxjs/toolkit'
import { reduxApiClient } from 'redux/api'
import { useStateSliceAndDispatch } from 'redux/utils'
import {
  GetOnboardingStatusResponse,
  GetTrialExpirationResponse,
  ProvisioningStatuses,
  ProvisionTenantRequest,
} from 'api/clients/provisioningClient'
import { ChordConfigSheet, ChordDataConnector } from 'types'

const SLICE_NAME = 'provisioning'

export interface ProvisioningInitialState {
  isTenantAvailable: boolean | null
  validGithubHandle: boolean | null
  onboardingStatus?: GetOnboardingStatusResponse
  provisioningStatuses?: ProvisioningStatuses
  trialExpiration?: GetTrialExpirationResponse
  dataConnectors: ChordDataConnector[]
  configSheets: ChordConfigSheet[]
}

const initialState: ProvisioningInitialState = {
  isTenantAvailable: null,
  validGithubHandle: null,
  onboardingStatus: undefined,
  provisioningStatuses: undefined,
  trialExpiration: undefined,
  dataConnectors: [],
  configSheets: [],
}

const {
  getIsTenantAvailable,
  getGithubHandleExists,
  provisionTenant,
  getProvisioningStatus,
  getOnboardingStatus,
  getTrialExpiration,
  getDataConnectors,
  getConfigSheets,
} = reduxApiClient

const provisioningSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetTenantIsAvailable: state => {
      state.isTenantAvailable = null
    },
    resetGithubHandleExists: state => {
      state.validGithubHandle = null
    },
  },
  extraReducers: builder => {
    builder.addCase(getIsTenantAvailable.fulfilled, (state, action) => {
      state.isTenantAvailable =
        action.payload.data.availableTenantName &&
        action.payload.data.availableStackSlug
    })

    builder.addCase(getGithubHandleExists.fulfilled, (state, action) => {
      state.validGithubHandle = action.payload.data.validGithubHandle
    })

    builder.addCase(getProvisioningStatus.fulfilled, (state, action) => {
      state.provisioningStatuses = action.payload.data
    })

    builder.addCase(getOnboardingStatus.fulfilled, (state, action) => {
      state.onboardingStatus = action.payload.data
    })

    builder.addCase(getTrialExpiration.fulfilled, (state, action) => {
      state.trialExpiration = action.payload.data
    })

    builder.addCase(getDataConnectors.fulfilled, (state, action) => {
      state.dataConnectors = action.payload.data
    })

    builder.addCase(getConfigSheets.fulfilled, (state, action) => {
      state.configSheets = action.payload.data
    })
  },
})

export default provisioningSlice.reducer

export const useProvisioningData = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)

  return {
    state,
    getIsTenantAvailable: (name: string) =>
      dispatch(getIsTenantAvailable(name)),
    resetTenantIsAvailable: () =>
      dispatch({ type: 'provisioning/resetTenantIsAvailable' }),
    getGithubHandleExists: (handle: string) =>
      dispatch(getGithubHandleExists(handle)),
    resetGithubHandleExists: () =>
      dispatch({ type: 'provisioning/resetGithubHandleExists' }),
    provisionTenant: (request: ProvisionTenantRequest) =>
      dispatch(provisionTenant(request)),
    getProvisioningStatus: (id: string) => dispatch(getProvisioningStatus(id)),
    getOnboardingStatus: (stackId: number) =>
      dispatch(getOnboardingStatus(stackId)),
    getTrialExpiration: (stackId: number) =>
      dispatch(getTrialExpiration(stackId)),
    getDataConnectors: () => dispatch(getDataConnectors()),
    getConfigSheets: () => dispatch(getConfigSheets()),
  }
}
