import React, { useState } from 'react'
import styled from 'styled-components'
import { standardIcons, useTheme } from '@chordco/component-library'
import { Tooltip } from '@mui/material'

const { AlertInfo } = standardIcons

interface TableHeaderProps {
  title: string
  note?: string
  required?: boolean
}

export default function TableHeader({
  title,
  note,
  required,
}: TableHeaderProps) {
  const theme = useTheme()
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  return (
    <Tooltip
      placement="top"
      title={note}
      onOpen={() => setIsTooltipVisible(true)}
      onClose={() => setIsTooltipVisible(false)}
    >
      <HeaderWrapper>
        <>
          {title}
          {required && (
            <span style={{ color: theme.ContentAccentPrimary }}>*</span>
          )}
        </>
        {note && (
          <AlertInfoContainer isTooltipVisible={isTooltipVisible}>
            <AlertInfo fill={theme.GREY_4} scale={16} />
          </AlertInfoContainer>
        )}
      </HeaderWrapper>
    </Tooltip>
  )
}

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  height: 16px;
`

const AlertInfoContainer = styled.div<{ isTooltipVisible: boolean }>`
  height: 16px;
  opacity: ${p => (p.isTooltipVisible ? 1 : 0.3)};
  transform: 'scale(0.75)';
  transition: 'all 150ms ease-in-out';
`
