import FivetranHTTPClient from 'api/fivetran/client'
import React, { createContext, useContext, ReactNode, useMemo } from 'react'
import { ChordDataConnector } from 'types'

interface FivetranProviderProps {
  destinationGroupId: string
  activeConnectors?: ChordDataConnector[]
  fivetranConnectors?: ChordDataConnector[]
  children: ReactNode
}

type FivetranContextValue = {
  fivetranClient: FivetranHTTPClient | null
  destinationGroupId: string
  activeConnectors?: ChordDataConnector[]
  fivetranConnectors?: ChordDataConnector[]
  isModeled: (slug: string) => boolean
}

const FivetranContext = createContext<FivetranContextValue | null>(null)

export const useFivetran = () => {
  const contextValue = useContext(FivetranContext)
  if (!contextValue) {
    throw new Error('useFivetran must be used within a FivetranProvider')
  }
  return contextValue
}

export const FivetranProvider: React.FC<FivetranProviderProps> = ({
  destinationGroupId,
  activeConnectors = [],
  fivetranConnectors = [],
  children,
}) => {
  const isModeled = (slug: string) => {
    return fivetranConnectors?.find(ac => ac.slug === slug)?.modeled ?? false
  }

  const fivetranClient = new FivetranHTTPClient({
    filterConnectors: activeConnectors.map(c => c.slug),
  })

  const contextValue: FivetranContextValue = useMemo(() => {
    return {
      fivetranClient,
      destinationGroupId: destinationGroupId,
      activeConnectors,
      fivetranConnectors,
      isModeled,
    }
  }, [fivetranClient, destinationGroupId])

  return (
    <FivetranContext.Provider value={contextValue}>
      {children}
    </FivetranContext.Provider>
  )
}
