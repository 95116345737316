import { useEffect, useState } from 'react'
import { usePrevious } from 'hooks'
import { useEnvironmentsData } from 'redux/state/environments'
import { useStoreSetupData } from 'redux/state/storeSetup'
import { useIsLoading } from 'redux/utils'

export const useIntegrationsByEnvironment = () => {
  const {
    state: { integrations, store },
    getStoreSetup,
  } = useStoreSetupData()
  const {
    state: { selectedEnvironment },
  } = useEnvironmentsData()

  const [hasFetched, setHasFetched] = useState<boolean>(false)
  const prevEnvironment = usePrevious(selectedEnvironment)
  const isLoading = useIsLoading('getStoreSetup')
  useEffect(() => {
    if (!getStoreSetup) return

    if (!integrations.length && !hasFetched && !isLoading) {
      getStoreSetup()
      setHasFetched(true)
    }

    if (
      selectedEnvironment !== prevEnvironment &&
      prevEnvironment !== undefined
    ) {
      getStoreSetup()
    }
  }, [integrations, hasFetched, selectedEnvironment])

  return { integrations, store }
}
