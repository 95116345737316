import { NavigationContext } from 'components/AuthenticatedRoutes'
import React from 'react'
import styled from 'styled-components'
import { Spinner } from '@chordco/component-library'

interface Props {
  hideMask?: boolean
  opacity?: number
}

export const Loading: React.FC<Props> = ({
  hideMask = false,
  opacity = 0.5,
}) => (
  <NavigationContext.Consumer>
    {({ navOpen }) => (
      <Mask hideMask={hideMask} navOpen={navOpen} opacity={opacity}>
        <Spinner scale={30} />
      </Mask>
    )}
  </NavigationContext.Consumer>
)

const Mask = styled.div<{
  hideMask: boolean
  navOpen: boolean
  opacity: number
}>`
  height: 100vh;
  width: ${p => (p.navOpen ? 'calc(100vw - 280px)' : '100vw')};
  position: fixed;
  top: 0;
  right: 0;
  background: ${p => (p.hideMask ? 'none' : p.theme.GREY_5)};
  opacity: ${p => p.opacity};
  z-index: 2;
  display: flex;
`
