import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { TableRow } from 'components/common'
import {
  Avatar,
  Button,
  Icon,
  Spinner,
  standardIcons,
  useTheme,
} from '@chordco/component-library'

interface Props {
  children?: React.ReactNode
  title: string
  subTitle: string
  logo: string
  link?: string
  operatingCorrectly?: boolean
  isSmall: boolean
  isLoading: boolean
  hasFailed: boolean
  headerMessage?: ReactNode
  retryFunction: () => void
}

const { OpenLink, CircleCheck, ExitCircle, AlertTriangle } = standardIcons

export const Section: React.FC<Props> = ({
  children,
  title,
  subTitle,
  link,
  operatingCorrectly,
  isSmall,
  isLoading,
  hasFailed,
  logo,
  headerMessage,
  retryFunction,
}) => {
  const theme = useTheme()

  return (
    <Container>
      <Top>
        <StyledAvatar src={logo} size={48} />
        <Title isSmall={isSmall}>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>

        {headerMessage && (
          <HeaderMessage isSmall={isSmall}>{headerMessage}</HeaderMessage>
        )}

        {!headerMessage && (
          <>
            {isLoading ? (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            ) : (
              <>
                <IconContainer>
                  {hasFailed ? (
                    <AlertTriangle
                      fill={theme.ContentAccentWarning}
                      scale={16}
                    />
                  ) : (
                    typeof operatingCorrectly !== 'undefined' &&
                    (operatingCorrectly ? (
                      <CircleCheck fill={theme.ContentAccentSuccess} />
                    ) : (
                      <ExitCircle fill={theme.ContentAccentError} />
                    ))
                  )}
                </IconContainer>

                <LinkIcon
                  icon={OpenLink}
                  purpose="ghost"
                  color="neutral"
                  onClick={() => window.open(link)}
                  disabled={!link}
                />
              </>
            )}
          </>
        )}
      </Top>

      {!isLoading && !headerMessage && (children || hasFailed) && (
        <Content isSmall={isSmall}>
          {hasFailed ? (
            <>
              <FailedToLoad>
                <span>!</span>Failed to Load
              </FailedToLoad>
              <Flex>
                <Button onClick={retryFunction} size="small" purpose="tertiary">
                  Reload the Data
                </Button>
                <Button
                  trailingIcon={standardIcons.OpenLink}
                  size="small"
                  purpose="tertiary"
                  onClick={() => window.open('mailto:support@chord.co')}
                >
                  Contact Support
                </Button>
              </Flex>
            </>
          ) : (
            children
          )}
        </Content>
      )}
    </Container>
  )
}

const Container = styled(TableRow)<{ isOpen?: boolean }>`
  display: block;
  position: relative;
  border-radius: ${p => p.theme.CardMediumRadius};
  margin-bottom: 16px;
  margin-top: 16px;
  padding: 0;
  border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
  background-color: ${p => p.theme.ComponentCardBgElevation0};

  :hover {
    cursor: auto;
    border: 1px solid ${p => p.theme.BorderOptimal};
    background-color: ${p => p.theme.ComponentCardBgElevation0};
  }
`

const Top = styled.div`
  position: relative;
  min-height: 60px;
`

const Content = styled.div<{ isSmall: boolean }>`
  display: ${p => (p.isSmall ? 'block' : 'flex')};
  border-top: 1px solid ${p => p.theme.BorderHairline};
  padding: 16px 20px;
  line-height: 24px;
`

const Title = styled.div<{ isSmall: boolean }>`
  width: ${p => (p.isSmall ? '194px' : 'auto')};
  margin: 12px 0 0 80px;
  white-space: normal;
  line-height: 1.2;
  font-size: 16px;
  font-weight: 600;
`

const SubTitle = styled.div`
  margin: 0 0 4px 80px;
  color: ${p => p.theme.ContentSecondary};
`

const SpinnerContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 32px;
`

const StyledAvatar = styled(Avatar)`
  position: absolute;
  top: 0;
  left: 16px;
`

const IconContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 84px;
`

const LinkIcon = styled(Icon)`
  position: absolute;
  top: 4px;
  right: 16px;
`

const HeaderMessage = styled.div<{ isSmall: boolean }>`
  position: absolute;
  top: ${p => (p.isSmall ? -4 : 4)}px;
  right: 16px;
  width: ${p => (p.isSmall ? 300 : 500)}px;
  font-size: 14px;
  white-space: normal;
  line-height: 1.4;
`

const FailedToLoad = styled.div`
  color: ${p => p.theme.ContentAccentWarning};
  margin: 4px 16px 0 0;

  > span {
    margin-right: 8px;
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  grid-column-gap: 8px;
  margin-top: 8px;

  > a:first-child {
    margin-right: 8px;
  }
`

export const sectionComponents = {
  Container,
  Top,
  Title,
  SubTitle,
  SpinnerContainer,
  StyledAvatar,
  IconContainer,
}
