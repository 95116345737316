import React, { ComponentProps, useRef } from 'react'
import styled from 'styled-components'
import { RadioButton } from './RadioButton'

interface Props extends ComponentProps<'input'> {
  label: string
  blurb?: string // todo i don't know what to call this
}

export const RadioCard: React.FC<Props> = ({
  label,
  blurb,
  checked,
  ...props
}) => {
  const ref = useRef() as any

  const onContainerClick = e => {
    e.preventDefault()
    // eslint-disable-next-line no-unsafe-optional-chaining
    const [input] = ref?.current?.getElementsByTagName('input')
    if (input) input.click()
  }

  return (
    <Container checked={checked} onClick={onContainerClick}>
      <LabelContainer>
        <Label checked={checked}>{label}</Label>
        {blurb && <Blurb>{blurb}</Blurb>}
      </LabelContainer>
      <RadioButtonContainer ref={ref}>
        <RadioButton {...props} label="" small={false} checked={checked} />
      </RadioButtonContainer>
    </Container>
  )
}

const Container = styled.div<{ checked?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  cursor: pointer;

  background-color: ${p => p.theme.ComponentWorkspaceBgPage};
  border-radius: 8px;
  border: 1px solid
    ${p =>
      p.checked
        ? p.theme.BorderAccentPrimary
        : p.theme.ComponentWorkspaceBgPage};

  // todo see if height and width can be removed once finished styling
  width: 100%;

  margin-top: 10px;
`

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Label = styled.div<{ checked?: boolean }>`
  font-weight: 600;
  line-height: 20px;
`

const Blurb = styled.div`
  line-height: 20px;
`

const RadioButtonContainer = styled.div`
  height: 24px;
  align-self: center;

  label {
    height: 24px;
    width: 24px;
  }
`
