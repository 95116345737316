import React, { ReactNode } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { standardIcons, useTheme } from '@chordco/component-library'
import { User } from 'types'
import { sortDates, sortStrings } from 'utils'
import { Col } from '../common'

export type UserSortColumn = 'name' | 'email' | 'lastLogin' | 'permissions'

export const sortUsers = (
  users: User[],
  sortColumn: UserSortColumn,
  isAscending: boolean
) => {
  const getUserName = (u: User) => `${u.firstName} ${u.lastName}`

  switch (sortColumn) {
    case 'email':
      return sortStrings(users, u => u.email || '', isAscending)
    case 'name':
      return sortStrings(users, getUserName, isAscending)
    case 'lastLogin':
      return sortDates(
        users,
        u => (u.lastSignInAt ? moment(u.lastSignInAt) : null),
        isAscending
      )
    case 'permissions':
      return sortStrings(users, u => u.role, isAscending)
    default:
      return users
  }
}

export type Sort = {
  column: UserSortColumn
  isAscending: boolean
}

type SortColProps = {
  flex: number
  sort: Sort
  column: UserSortColumn
  setSort: (sort: Sort) => void
  children: ReactNode
}

export const SortCol: React.FC<SortColProps> = ({
  flex,
  sort,
  column,
  setSort,
  children,
}) => {
  const theme = useTheme()

  const { ChevronDown } = standardIcons
  const isSorted = column === sort.column

  const onClick = () => {
    const sortProps = isSorted
      ? { ...sort, isAscending: !sort.isAscending }
      : { column, isAscending: true }

    setSort(sortProps)
  }

  return (
    <StyledCol flex={flex} onClick={onClick}>
      {children}
      {column === sort.column && (
        <ChevronContainer isAsc={sort.isAscending}>
          <ChevronDown fill={theme.PRIMARY_50} />
        </ChevronContainer>
      )}
    </StyledCol>
  )
}

const StyledCol = styled(Col)`
  cursor: pointer;
`
const ChevronContainer = styled.span<{ isAsc: boolean }>`
  transform: rotate(${p => (p.isAsc ? 0 : 180)}deg);
  cursor: pointer;
  position: absolute;
  top: -${p => (p.isAsc ? 1 : 4)}px;
`
