import React from 'react'
import styled, { css } from 'styled-components'

import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

type Props = IconProps & {
  hoverFill?: string
}

export const PlayOutline: React.FC<Props> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <StyledSvg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8074 6.80763C13.6957 7.35431 13.6957 8.6456 12.8074 9.19228L4.7338 14.1606C3.80102 14.7346 2.60007 14.0636 2.60007 12.9683L2.60007 3.0316C2.60007 1.93635 3.80103 1.26527 4.7338 1.83928L12.8074 6.80763ZM12.1785 8.17029C12.3054 8.09219 12.3054 7.90772 12.1785 7.82962L4.10489 2.86127C3.97163 2.77927 3.80007 2.87514 3.80007 3.0316L3.80007 12.9683C3.80007 13.1248 3.97163 13.2206 4.10489 13.1386L12.1785 8.17029Z"
        fill={fill || theme.ContentPrimary}
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg<{
  hoverFill?: string
}>`
  ${({ hoverFill }) =>
    hoverFill &&
    css`
      :hover {
        path {
          fill: ${hoverFill};
        }
      }
    `}
`
