import { useTenantsData } from 'redux/state/tenants'
import { useUsersData } from 'redux/state/users'

export const useTenantSeatLimitHelper = () => {
  const {
    state: { users },
  } = useUsersData()

  const {
    state: { currentTenant },
  } = useTenantsData()

  if (!currentTenant) throw new Error('Current Tenant not found!')

  const tenantTeamMembers = users.filter(u => !u.email?.match(/chord.co.*/))

  const numberOfSeatsLeft =
    currentTenant?.userSeatLimit - tenantTeamMembers.length
  const tenantSeatCountLimitReached =
    tenantTeamMembers.length >= currentTenant?.userSeatLimit
  const tenantSeatCountBelowLimit =
    tenantTeamMembers.length < currentTenant?.userSeatLimit
  const hasTenantReachedSeatLimit =
    tenantSeatCountLimitReached &&
    currentTenant?.metadata?.userSeatLimitRequestFulfilled === true

  const hasTenantRequestedSeatLimitIncrease =
    tenantSeatCountLimitReached &&
    currentTenant?.metadata?.userSeatLimitRequestFulfilled === false

  return {
    numberOfSeatsLeft,
    tenantTeamMembers,
    tenantSeatCountLimitReached,
    tenantSeatCountBelowLimit,
    hasTenantReachedSeatLimit,
    hasTenantRequestedSeatLimitIncrease,
  }
}
