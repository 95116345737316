import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export interface CheckIconProps extends IconProps {
  size?: 'small' | 'medium' | 'large'
}

const heights = { small: 6, medium: 10, large: 20 }

const widths = { small: 9, medium: 13, large: 20 }

export const Check: React.FC<CheckIconProps> = ({
  size = 'medium',
  fill,
  scale,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      height={scale || widths[size]}
      width={scale || heights[size]}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1485 4.93549C17.4413 5.22838 17.4413 5.70326 17.1485 5.99615L7.98179 15.1628C7.68889 15.4557 7.21402 15.4557 6.92113 15.1628L2.75446 10.9962C2.46157 10.7033 2.46157 10.2284 2.75446 9.93549C3.04735 9.6426 3.52223 9.6426 3.81512 9.93549L7.45146 13.5718L16.0878 4.93549C16.3807 4.6426 16.8556 4.6426 17.1485 4.93549Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
