import { standardIcons } from '@chordco/component-library'
import { AvailableReport } from 'types'

// TODO: this should be moved to Hub Backend and fetched from there
function getDescriptionForReport(report: AvailableReport) {
  switch (report.slug) {
    case 'business_overview':
      return 'Snapshot of e-commerce performance'
    case 'customer':
      return 'Measures for understanding customers, cohorts, and conversions'
    case 'customer_segmentation':
      return 'Starter segments with predicted and actual value measures'
    case 'ecommerce':
      return 'Storefront measures representing shopping patterns'
    case 'products':
      return 'Sales trends and rankings across product catalog'
    case 'forecasts':
      return 'Revenue forecasting to support business planning'
    case 'subscriptions':
      return 'Subscription health and performance measures'
    case 'supply_chain':
      return 'Operational measures around order fulfillments'
    case 'marketing':
      return 'Performance of marketing connected with storefront and order measures'
    default:
      return ''
  }
}

// TODO: this should be moved to Hub Backend and fetched from there
function getIconForReport(report: AvailableReport) {
  switch (report.slug) {
    case 'business_overview':
      return standardIcons.Metrics
    case 'customer':
      return standardIcons.Team
    case 'customer_segmentation':
      return standardIcons.PieChart
    case 'ecommerce':
      return standardIcons.Cart
    case 'products':
      return standardIcons.Cube
    case 'forecasts':
      return standardIcons.Compass
    case 'subscriptions':
      return standardIcons.Future
    case 'supply_chain':
      return standardIcons.Sort
    case 'marketing':
      return standardIcons.KeyResultsIcon
    default:
      return standardIcons.Reports
  }
}

export { getDescriptionForReport, getIconForReport }
