import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Layers: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66443 0.995836C9.87557 0.890263 10.1241 0.890263 10.3352 0.995836L18.6686 5.1625C18.9227 5.28955 19.0832 5.54924 19.0832 5.83332C19.0832 6.1174 18.9227 6.3771 18.6686 6.50414L10.3352 10.6708C10.1241 10.7764 9.87557 10.7764 9.66443 10.6708L1.33109 6.50414C1.07701 6.3771 0.916504 6.1174 0.916504 5.83332C0.916504 5.54924 1.07701 5.28955 1.33109 5.1625L9.66443 0.995836ZM3.34355 5.83332L9.99984 9.16146L16.6561 5.83332L9.99984 2.50518L3.34355 5.83332ZM0.995848 13.8313C1.18109 13.4608 1.63159 13.3106 2.00208 13.4958L10 17.4948L17.9979 13.4958C18.3684 13.3106 18.8189 13.4608 19.0042 13.8313C19.1894 14.2017 19.0392 14.6522 18.6687 14.8375L10.3354 19.0042C10.1243 19.1097 9.87574 19.1097 9.66459 19.0042L1.33126 14.8375C0.960774 14.6522 0.810606 14.2017 0.995848 13.8313ZM2.00208 9.32919C1.63159 9.14395 1.18109 9.29412 0.995848 9.6646C0.810606 10.0351 0.960774 10.4856 1.33126 10.6708L9.66459 14.8375C9.87574 14.9431 10.1243 14.9431 10.3354 14.8375L18.6687 10.6708C19.0392 10.4856 19.1894 10.0351 19.0042 9.6646C18.8189 9.29412 18.3684 9.14395 17.9979 9.32919L10 13.3282L2.00208 9.32919Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
