import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const ChevronDown: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill={fill || theme.ContentPrimary}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.29289 6.79289C4.68342 6.40237 5.31658 6.40237 5.70711 6.79289L10 11.0858L14.2929 6.79289C14.6834 6.40237 15.3166 6.40237 15.7071 6.79289C16.0976 7.18342 16.0976 7.81658 15.7071 8.20711L10.7071 13.2071C10.3166 13.5976 9.68342 13.5976 9.29289 13.2071L4.29289 8.20711C3.90237 7.81658 3.90237 7.18342 4.29289 6.79289Z"
      />
    </svg>
  )
}
