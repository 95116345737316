import { StoreEnvironment, Tenant } from 'types'

const TENANT_KEY = 'selectedTenantId'
const NO_TENANTS_ERROR = 'No tenants provided to `getCurrentTenant`'

export const getCurrentTenant = (tenants: Tenant[]): Tenant => {
  if (!tenants?.length) throw new Error(NO_TENANTS_ERROR)

  const defaultTenant = tenants[0]

  const selectedTenantId = localStorage.getItem(TENANT_KEY)
  if (!selectedTenantId) {
    setCurrentTenant(defaultTenant)
    return defaultTenant
  }

  const selectedTenant = tenants.find(t => t.id === +selectedTenantId)
  if (!selectedTenant) {
    setCurrentTenant(defaultTenant)
    return defaultTenant
  }

  return selectedTenant
}

export const setCurrentTenant = (tenant: Tenant) => {
  localStorage.setItem(TENANT_KEY, tenant.id.toString())
}

const ENVIRONMENT_KEY = 'selectedEnvironment'
const NO_ENVIRONMENTS_ERROR =
  'No environments provided to `getCurrentEnvironment`'

export const getCurrentEnvironment = (
  environments: StoreEnvironment[]
): StoreEnvironment => {
  if (!environments?.length) throw new Error(NO_ENVIRONMENTS_ERROR)

  const defaultEnvironment =
    environments.find(e => e === 'production') || environments[0]

  const selectedEnvironment = localStorage.getItem(ENVIRONMENT_KEY)
  if (!selectedEnvironment) {
    setCurrentEnvironment(defaultEnvironment)
    return defaultEnvironment
  }

  const matchingEnvironment = environments.find(e => e === selectedEnvironment)
  if (!matchingEnvironment) {
    setCurrentEnvironment(defaultEnvironment)
    return defaultEnvironment
  }

  return matchingEnvironment
}

export const setCurrentEnvironment = (environment: StoreEnvironment) => {
  localStorage.setItem(ENVIRONMENT_KEY, environment)
}
