import React, { useState } from 'react'
import styled from 'styled-components'
import {
  standardIcons,
  StyledInput,
  Tooltip,
  Container,
} from '@chordco/component-library'

interface Props {
  value: string
  isSmall: boolean
}

const { Eye, Copy } = standardIcons

export const SecureField: React.FC<Props> = ({ value, isSmall }) => {
  const [show, setShow] = useState(false)
  const toggleShow = () => setShow(s => !s)

  const [copied, setCopied] = useState(false)
  const copyValueToClipboard = () => {
    navigator.clipboard.writeText(value?.toString() || '')
    setCopied(true)
  }

  return (
    <Container>
      <LeftIconContainer show={show}>
        <Tooltip text={show ? 'Hide' : 'Show'} direction="input-bottom-left">
          <Eye scale={16} onClick={toggleShow} />
        </Tooltip>
      </LeftIconContainer>
      <RightIconContainer>
        <Tooltip
          text={copied ? 'Copied!' : 'Copy to clipboard'}
          direction="input-bottom-right"
        >
          <Copy scale={16} onClick={copyValueToClipboard} />
        </Tooltip>
      </RightIconContainer>

      <Input
        value={show ? value : '************'}
        type={show ? 'text' : 'password'}
        customWidth={isSmall ? '100%' : '280px'}
      />
    </Container>
  )
}

const Input = styled(StyledInput)`
  padding: 6px 36px;
  color: ${p => p.theme.GREY_3};
`

const IconContainer = styled.div`
  position: absolute;
  cursor: pointer;

  path {
    fill: ${p => p.theme.PRIMARY_50};
  }

  :hover {
    path {
      fill: ${p => p.theme.PRIMARY_70};
    }
  }

  :active {
    path {
      fill: ${p => p.theme.PRIMARY_80};
    }
  }
`

const LeftIconContainer = styled(IconContainer)<{ show: boolean }>`
  top: 16px;
  left: 10px;

  path {
    fill: ${p => (p.show ? p.theme.OFF_TEXT_BASE : p.theme.PRIMARY_50)};
  }
`

const RightIconContainer = styled(IconContainer)`
  top: 16px;
  right: 10px;
`
