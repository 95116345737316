/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import {
  CensusDestination,
  CensusSource,
  CensusConnectLink,
  CensusSync,
  GetSourcesResponse,
  GetDestinationsResponse,
  GetDestinationConnectLinksResponse,
  GetCensusSyncsResponse,
  GetCensusSyncResponse,
  CreateDestinationConnectLinkResponse,
  GetLatestSyncRunResponse,
  GetCensusConnectorsResponse,
  DeleteCensusDestinationResponse,
  CensusConnector,
  GetCensusConnectorResponse,
  GetDestinationResponse,
  CreateSyncResponse,
  CreateSyncManagementLinkResponse,
  GetCensusSyncRunsResponse,
  TriggerSyncRunResponse,
  DeleteSyncResponse,
  CancelSyncRunResponse,
  ReauthorizeDestinationConnectLinkResponse,
  UpdateSyncScheduleResponse,
  CensusSchedule,
} from './interfaces'
import { hubClient } from 'api/http'

export interface CensusClientOptions {
  tenantId: number
  storeId: number
  environment: string
  perPage?: number
}

class CensusHTTPClient {
  private tenantId: number
  private storeId: number
  private environment: string
  private perPage: number
  private baseURL: string

  constructor(options: CensusClientOptions) {
    this.tenantId = options.tenantId
    this.storeId = options.storeId
    this.environment = options.environment
    this.perPage = options.perPage || 24
    this.baseURL = `tenants/${this.tenantId}/stores/${this.storeId}/census`
  }

  getPaginatedURL(endpoint: string, page: number): string {
    return `${this.baseURL}/${endpoint}?page=${page}&per_page=${this.perPage}&env=${this.environment}`
  }

  getURL(endpoint: string): string {
    return `${this.baseURL}/${endpoint}?env=${this.environment}`
  }

  async getDestinations(page = 1): Promise<GetDestinationsResponse> {
    try {
      const url = this.getPaginatedURL('destinations', page)
      return await hubClient.get(url)
    } catch (error) {
      console.error('Error fetching destinations:', error)
      throw error
    }
  }

  async getDestination(id: number): Promise<GetDestinationResponse> {
    try {
      const url = this.getURL(`destinations/${id}`)
      return await hubClient.get(url)
    } catch (error) {
      console.error('Error fetching destination:', error)
      throw error
    }
  }

  async getSources(page = 1): Promise<GetSourcesResponse> {
    try {
      const url = this.getPaginatedURL('sources', page)
      return await hubClient.get(url)
    } catch (error) {
      console.error('Error fetching sources:', error)
      throw error
    }
  }

  async createDestinationConnectLink({
    type,
    redirectUri,
  }: {
    type: string
    redirectUri: string
  }): Promise<CreateDestinationConnectLinkResponse> {
    try {
      const url = this.getURL('create_destination_connect_link')
      return await hubClient.post(url, {
        type,
        redirect_uri: redirectUri,
      })
    } catch (error) {
      console.error('Error creating destination connect link:', error)
      throw error
    }
  }

  async createEditSyncManagementLink({
    syncId,
    redirectUri,
  }: {
    syncId: number
    redirectUri: string
  }): Promise<CreateSyncManagementLinkResponse> {
    try {
      const url = this.getURL('create_edit_sync_management_link')
      return await hubClient.post(url, {
        sync_id: syncId,
        redirect_uri: redirectUri,
      })
    } catch (error) {
      console.error('Error creating edit sync management link:', error)
      throw error
    }
  }

  async reauthorizeDestinationConnectLink({
    destinationId,
    redirectUri,
  }: {
    destinationId: number
    redirectUri: string
  }): Promise<ReauthorizeDestinationConnectLinkResponse> {
    try {
      const url = this.getURL('reauthorize_destination_connect_link')
      return await hubClient.post(url, {
        destination_id: destinationId,
        redirect_uri: redirectUri,
      })
    } catch (error) {
      console.error(
        'Error fetching new authorization destination connect link:',
        error
      )
      throw error
    }
  }

  async getSyncs(page): Promise<GetCensusSyncsResponse> {
    try {
      const url = this.getPaginatedURL('syncs', page)
      return await hubClient.get(url)
    } catch (error) {
      console.error('Error fetching syncs:', error)
      throw error
    }
  }

  async getSync(syncId: number): Promise<GetCensusSyncResponse> {
    try {
      const url = this.getURL(`syncs/${syncId}`)
      return await hubClient.get(url)
    } catch (error) {
      console.error('Error fetching sync:', error)
      throw error
    }
  }

  async getSyncRuns(syncId: number): Promise<GetCensusSyncRunsResponse> {
    try {
      const url = this.getURL(`syncs/${syncId}/sync_runs`)
      return await hubClient.get(`${url}&order=desc`)
    } catch (error) {
      console.error('Error fetching sync runs:', error)
      throw error
    }
  }

  async getAllowedConnectors(): Promise<CensusConnector[]> {
    try {
      const url = this.getURL('allowed_connectors')
      return await hubClient.get(url)
    } catch (error) {
      console.error('Error fetching allowed connectors:', error)
      throw error
    }
  }

  async getPaginatedConnectors(page = 1): Promise<GetCensusConnectorsResponse> {
    try {
      const url = this.getPaginatedURL('connectors', page)
      return await hubClient.get(url)
    } catch (error) {
      console.error('Error fetching connectors:', error)
      throw error
    }
  }

  async getConnector(connectorId: string): Promise<GetCensusConnectorResponse> {
    try {
      const url = this.getURL(`connectors/${connectorId}`)
      return await hubClient.get(url)
    } catch (error) {
      console.error('Error fetching connector:', error)
      throw error
    }
  }

  async deleteDestination(
    destinationId: number
  ): Promise<DeleteCensusDestinationResponse> {
    try {
      const url = this.getURL(`destinations/${destinationId}`)
      return await hubClient.delete(url)
    } catch (error) {
      console.error('Error deleting destination:', error)
      throw error
    }
  }

  async createSync(
    destinationId,
    destinationObjectFullName,
    sourceModelName,
    primaryIdentifierFrom,
    primaryIdentifierTo: {
      destinationId: number
      destinationObjectFullName: string
      sourceModelName: string
      primaryIdentifierFrom: string
      primaryIdentifierTo: string
    }
  ): Promise<CreateSyncResponse> {
    try {
      const url = this.getURL('syncs')
      return await hubClient.post(url, {
        destination_id: destinationId,
        destination_object_full_name: destinationObjectFullName,
        source_model_name: sourceModelName,
        mapping_from: primaryIdentifierFrom,
        mappin_to: primaryIdentifierTo,
      })
    } catch (error) {
      console.error('Error creating sync:', error)
      throw error
    }
  }

  async createSyncManagementLink(
    redirectUri: string
  ): Promise<CreateSyncManagementLinkResponse> {
    try {
      const url = this.getURL('create_sync_management_link')
      return await hubClient.post(url, {
        redirect_uri: redirectUri,
      })
    } catch (error) {
      console.error('Error creating sync management link:', error)
      throw error
    }
  }

  async triggerSyncRun(syncId: number): Promise<TriggerSyncRunResponse> {
    try {
      const url = this.getURL('trigger_sync_run')
      return await hubClient.post(url, {
        sync_id: syncId,
      })
    } catch (error) {
      console.error('Error triggering sync run:', error)
      throw error
    }
  }

  async deleteSync(syncId: number): Promise<DeleteSyncResponse> {
    try {
      const url = this.getURL(`delete_syncs/${syncId}`)
      return await hubClient.delete(url)
    } catch (error) {
      console.error('Error deleting sync:', error)
      throw error
    }
  }

  async cancelSyncRun(syncRunId: number): Promise<CancelSyncRunResponse> {
    try {
      const url = this.getURL(`sync_runs/${syncRunId}/cancel`)
      return await hubClient.post(url)
    } catch (error) {
      console.error('Error canceling sync run:', error)
      throw error
    }
  }

  /**
   * Update the sync schedule for a given sync ID
   * https://developers.getcensus.com/api-reference/syncs/update-a-sync
   */
  async updateSyncSchedule(
    syncId: number,
    schedule: CensusSchedule
  ): Promise<UpdateSyncScheduleResponse> {
    try {
      const url = this.getURL(`syncs/${syncId}`)
      return await hubClient.patch(url, {
        sync_params: {
          mode: {
            triggers: {
              schedule,
            },
          },
        },
      })
    } catch (error) {
      console.error('Error updating sync:', error)
      throw error
    }
  }
}

export default CensusHTTPClient
