import { useEverySucceeded, useIsLoading } from 'redux/utils'
import { useLookerData } from 'redux/state/looker'
import { AvailableUserAudience } from 'types'
import { useEffect, useState } from 'react'

export const useCopyAudience = (
  onCopied: (audience?: AvailableUserAudience) => void
) => {
  const { copyAudience } = useLookerData()

  const isLoading = useIsLoading('copyAudience')
  const hasSucceeded = useEverySucceeded('copyAudience')

  const [copiedAudience, setCopiedAudience] = useState<
    AvailableUserAudience | undefined
  >(undefined)

  const submit = async ({
    audienceId,
    audienceTitle,
    audienceDescription,
    folderId,
  }: {
    audienceId: string
    audienceTitle: string
    audienceDescription: string
    folderId: string
  }) => {
    if (!copyAudience) return

    const response = await copyAudience(
      audienceId,
      audienceTitle,
      audienceDescription,
      folderId
    )
    setCopiedAudience(response as unknown as AvailableUserAudience)
  }

  useEffect(() => {
    if (hasSucceeded && copiedAudience) {
      onCopied(copiedAudience)
    }
  }, [hasSucceeded, onCopied, copiedAudience])

  return { submit, isLoading }
}
