import { hubClient } from 'api/http'

export type FeatureType = 'data_sources' | 'data_activations' | 'data_audiences'

export interface FeatureAccessRequestProps {
  tenantId: number
  userName: string
  userEmail: string
  feature: FeatureType
}

export interface DataSourceRequestProps {
  dataSourceName: string
  tenantId: number
  userName: string
  userEmail: string
}

export interface TenantConfigurationServiceRequest {
  tenantId: number
  requestType: string
  userName: string
  userEmail: string
}

export interface TenantModelAmplificationRequest {
  tenantId: number
  slug: string
}

export async function createDataSourceRequest({
  tenantId,
  dataSourceName,
  userName,
  userEmail,
}: DataSourceRequestProps): Promise<any> {
  const url = `tenants/${tenantId}/notion/data_source_request`

  return await hubClient.post(url, {
    data_source_name: dataSourceName,
    user_name: userName,
    user_email: userEmail,
  })
}

export async function createFeatureAccessRequest({
  tenantId,
  userName,
  userEmail,
  feature,
}: FeatureAccessRequestProps): Promise<any> {
  const url = `tenants/${tenantId}/notion/feature_access_request`

  return await hubClient.post(url, {
    user_name: userName,
    user_email: userEmail,
    feature,
  })
}

export async function fetchFeatureAccessRequests(
  tenantId: number,
  feature: string
): Promise<any> {
  const url = `tenants/${tenantId}/notion/feature_access_requests`

  return await hubClient.get(url, {
    params: {
      feature,
    },
  })
}
