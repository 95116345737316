import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const ProductLoyalists: React.FC<IconProps> = ({
  scale = 48,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6271 13.6807L15.29 13.6807C14.0474 13.6807 13.04 14.688 13.04 15.9307L13.04 24.2678C13.04 24.8645 13.2771 25.4368 13.6991 25.8588L16.4871 28.6468L15.4264 29.7075L12.6384 26.9194C11.9351 26.2162 11.54 25.2623 11.54 24.2678V15.9307C11.54 13.8596 13.219 12.1807 15.29 12.1807H23.6271C24.6217 12.1807 25.5755 12.5758 26.2788 13.279L37.431 24.4312C38.8955 25.8957 38.8955 28.2701 37.431 29.7345L29.0939 38.0716C27.6294 39.5361 25.2551 39.5361 23.7906 38.0716L19.9699 34.2509L21.0306 33.1903L24.8513 37.011C25.7299 37.8896 27.1546 37.8896 28.0332 37.011L36.3703 28.6739C37.249 27.7952 37.249 26.3706 36.3703 25.4919L25.2181 14.3397C24.7962 13.9177 24.2239 13.6807 23.6271 13.6807Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2211 16.8619C15.3527 17.7303 15.3527 19.1383 16.2211 20.0068C17.0896 20.8752 18.4976 20.8752 19.3661 20.0068C20.2345 19.1383 20.2345 17.7303 19.3661 16.8619C18.4976 15.9934 17.0896 15.9934 16.2211 16.8619ZM17.0789 19.1491C16.6841 18.7543 16.6841 18.1143 17.0789 17.7196C17.4736 17.3248 18.1136 17.3248 18.5084 17.7196C18.9031 18.1143 18.9031 18.7543 18.5084 19.1491C18.1136 19.5438 17.4736 19.5438 17.0789 19.1491Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0938 30.204L26.5307 35.6409L31.9677 30.2039C33.4691 28.7026 33.469 26.2683 31.9677 24.7669C30.4663 23.2655 28.0321 23.2655 26.5307 24.7669C25.0293 23.2656 22.5952 23.2657 21.0938 24.767C19.5924 26.2684 19.5924 28.7026 21.0938 30.204ZM25.4701 25.8276L26.5308 26.8882L27.5914 25.8276C28.507 24.912 29.9914 24.912 30.907 25.8276C31.8226 26.7432 31.8226 28.2277 30.907 29.1433L26.5307 33.5196L22.1545 29.1433C21.2389 28.2277 21.2389 26.7433 22.1545 25.8277C23.0701 24.9121 24.5545 24.9121 25.4701 25.8276Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
