import axios, { AxiosInstance } from 'axios'
import humps from 'humps'
import { EnvVarConfig } from 'utils/envVarConfig'

const hubClient: AxiosInstance = axios.create({
  baseURL: EnvVarConfig.REACT_APP_CHORD_HUB_API,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
  },
})

hubClient.interceptors.response.use(
  response => humps.camelizeKeys(response.data),
  error => {
    if (axios.isCancel(error)) {
      return Promise.reject(error)
    }

    if (error.response.status === 401) {
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('session_token')
      localStorage.removeItem('b2b')

      window.location.replace('/login')
      return // hide notifications for 401 responses
    }

    return Promise.reject(error)
  }
)

hubClient.interceptors.request.use(
  request => {
    if (
      request.headers &&
      request.headers['Content-Type'] !== 'multipart/form-data'
    ) {
      request.data = humps.decamelizeKeys(request.data)
    }

    const token = localStorage.getItem('token')
    if (token) {
      request.headers = {
        ...request.headers,
        authorization: `Bearer ${token}`,
      }
    }

    request.params = humps.decamelizeKeys(request.params)

    return request
  },
  error => Promise.reject(error)
)

export { hubClient }
