import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const ArrowRight: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.583 10.0002C16.583 10.4144 16.2472 10.7502 15.833 10.7502L4.16634 10.7502C3.75213 10.7502 3.41634 10.4144 3.41634 10.0002C3.41634 9.58595 3.75213 9.25017 4.16634 9.25017L15.833 9.25017C16.2472 9.25017 16.583 9.58595 16.583 10.0002Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3633 9.46968C16.6562 9.76257 16.6562 10.2374 16.3633 10.5303L10.53 16.3637C10.2371 16.6566 9.76224 16.6566 9.46934 16.3637C9.17645 16.0708 9.17645 15.5959 9.46934 15.303L14.7723 10L9.46934 4.697C9.17645 4.40411 9.17645 3.92923 9.46934 3.63634C9.76224 3.34345 10.2371 3.34345 10.53 3.63634L16.3633 9.46968Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
