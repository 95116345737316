import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const CheckCircle: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.32862 2.59835C9.9237 2.23796 11.5925 2.40284 13.0863 3.0684C13.4646 3.23699 13.908 3.06694 14.0766 2.68858C14.2452 2.31023 14.0751 1.86685 13.6968 1.69826C11.9076 0.901051 9.90864 0.703555 7.99804 1.13523C6.08745 1.5669 4.36758 2.60461 3.09494 4.0936C1.8223 5.58259 1.06507 7.44308 0.936194 9.39758C0.807314 11.3521 1.31369 13.2959 2.37979 14.9391C3.4459 16.5823 5.01461 17.8369 6.85198 18.5157C8.68935 19.1945 10.6969 19.2612 12.5753 18.7058C14.4537 18.1504 16.1022 17.0027 17.275 15.4339C18.4478 13.8651 19.0821 11.9588 19.0832 10V9.23333C19.0832 8.81912 18.7474 8.48333 18.3332 8.48333C17.919 8.48333 17.5832 8.81912 17.5832 9.23333V9.99957C17.5822 11.6349 17.0527 13.226 16.0736 14.5358C15.0944 15.8455 13.7182 16.8037 12.15 17.2674C10.5818 17.731 8.90576 17.6753 7.37181 17.1086C5.83786 16.5419 4.5282 15.4945 3.63815 14.1227C2.7481 12.7508 2.32535 11.128 2.43294 9.49628C2.54054 7.86453 3.17272 6.31129 4.2352 5.06818C5.29768 3.82508 6.73353 2.95874 8.32862 2.59835ZM18.8639 3.86341C19.1567 3.57037 19.1564 3.0955 18.8634 2.80275C18.5704 2.51 18.0955 2.51024 17.8027 2.80328L9.99973 10.6141L8.03033 8.64468C7.73744 8.35179 7.26256 8.35179 6.96967 8.64468C6.67678 8.93757 6.67678 9.41245 6.96967 9.70534L9.46967 12.2053C9.61037 12.346 9.80121 12.4251 10.0002 12.425C10.1992 12.425 10.39 12.3458 10.5306 12.2051L18.8639 3.86341Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
