import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Reports: React.FC<IconProps> = ({
  scale = 20,
  fill,
  ...props
}) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.94835 2.74568C9.61005 2.41811 10.3889 2.41811 11.0506 2.74568L17.3041 5.8414C18.4065 6.38714 18.4065 7.94472 17.3041 8.49045L11.0506 11.5862C10.3889 11.9137 9.61005 11.9137 8.94835 11.5862L2.69484 8.49045C1.59243 7.94472 1.59243 6.38714 2.69484 5.8414L8.94835 2.74568ZM10.4773 3.88098C10.1765 3.73209 9.82247 3.73209 9.5217 3.88098L3.26818 6.97671C3.11069 7.05467 3.11069 7.27718 3.26818 7.35514L9.5217 10.4509C9.82247 10.5998 10.1765 10.5998 10.4773 10.4509L16.7308 7.35515C16.8883 7.27718 16.8883 7.05467 16.7308 6.97671L10.4773 3.88098Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M1.74729 9.81946C1.91922 9.51305 2.30947 9.40266 2.61894 9.57288L9.48099 13.3473C9.80366 13.5248 10.196 13.5248 10.5187 13.3473L17.3807 9.57288C17.6902 9.40266 18.0805 9.51305 18.2524 9.81946C18.4243 10.1259 18.3128 10.5122 18.0033 10.6825L11.1413 14.4569C10.4314 14.8473 9.56825 14.8473 8.85838 14.4569L1.99633 10.6825C1.68686 10.5122 1.57536 10.1259 1.74729 9.81946Z"
        fill={fill || theme.ContentPrimary}
      />
      <path
        d="M2.61894 12.3232C2.30947 12.1529 1.91922 12.2633 1.74729 12.5697C1.57536 12.8761 1.68686 13.2625 1.99633 13.4327L8.85838 17.2072C9.56825 17.5976 10.4314 17.5976 11.1413 17.2072L18.0033 13.4327C18.3128 13.2625 18.4243 12.8761 18.2524 12.5697C18.0805 12.2633 17.6902 12.1529 17.3807 12.3232L10.5187 16.0976C10.196 16.2751 9.80366 16.2751 9.48099 16.0976L2.61894 12.3232Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
