import React from 'react'
import { Chip, ChipColor, standardIcons } from '@chordco/component-library'
import { SyncState } from 'api/fivetran/interfaces'
import styled from 'styled-components'

type Props = {
  syncState: SyncState
  iconOnly?: boolean
}

export const SyncChip: React.FC<Props> = ({ syncState, iconOnly = false }) => {
  const getChipSetupText = (syncState: SyncState): string => {
    return syncState.charAt(0).toUpperCase() + syncState.slice(1)
  }

  const getChipSetupColor = (syncState: SyncState): ChipColor => {
    switch (syncState) {
      case 'scheduled':
      case 'rescheduled':
        return 'info'
      case 'syncing':
        return 'success'
      case 'paused':
        return 'neutral'
      default:
        throw new Error('Unknown Connector sync state: ' + syncState)
    }
  }

  const getChipIcon = (syncState: SyncState) => {
    switch (syncState) {
      case 'scheduled':
      case 'rescheduled':
        return standardIcons.Time
      case 'syncing':
        return standardIcons.Refresh
      case 'paused':
        return standardIcons.Pause
      default:
        throw new Error('Unknown Connector sync state: ' + syncState)
    }
  }

  return (
    <ChipContainer>
      <Chip
        leftIcon={getChipIcon(syncState)}
        leftIconSize={12}
        text={iconOnly ? '' : getChipSetupText(syncState)}
        color={getChipSetupColor(syncState)}
        variant="solid-secondary"
      />
    </ChipContainer>
  )
}

const ChipContainer = styled.div`
  svg {
    path {
      fill: ${p => p.theme.BLACK};
    }
  }
`
